import React from 'react'
import Header from './Header/Header'

import { Container, Row } from 'react-bootstrap';

import WeiterleitungPartnerSelection from './WeiterleitungPartnerSelection'


function DateienSendenWeiterleitung() {
  

  return (
    <>
      <Header />

      <Container style={{maxWidth: "800px"}}>
      
        <Row className="mt-5"style={{justifyContent: "center"}} >
          <img
            src={require("../img/BeraterConnect.png")}
            alt=""
            style={{ maxWidth: "160px" }}
          />
          <h5 className="text-center">Dokumente an einen Partner senden - Wähle zunächst aus</h5>
        </Row>
      
        <WeiterleitungPartnerSelection navigateTo={"Dateien senden"} />
      

      </Container>

    </>
  );
}

export default DateienSendenWeiterleitung