import React, { useState, useEffect, useContext } from "react";
import Header from "../Header/Header";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileText,
  faChevronRight,
  faNewspaper,
  faChevronDown,
  faChevronUp
} from "@fortawesome/free-solid-svg-icons";

import { useNavigate } from "react-router-dom";

import { db } from "../../firebase";
import { collection, getDocs, doc, updateDoc } from "firebase/firestore";

import { Container, Row, Col, Button } from "react-bootstrap";

import style from "./UnreadDocs.module.css";

import Swal from "sweetalert2";
import axios from "axios";
import { AuthContext } from "../../store/auth-context";

import DokumentenFeed from "./DokumentenFeed";

//import CardSub from "../Card/CardSub"
//import CardDoppelt from "../Card/CardDoppelt";

function UnreadDocs() {

  let navigate = useNavigate();

  const [newsfeed, setNewsfeed] = useState("");

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  useEffect(() => {
      function handleResize() {
          setWindowDimensions(getWindowDimensions());
      }
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
  }, []);

  function getWindowDimensions() {
    const width = window.innerWidth
    const height = window.innerHeight

    return {
        width,
        height
    };
  }


  const [showFilter, setShowFilter] = useState(true);

  const [unreadDocs, setUnreadDocs] = useState([]);
  const [unreadDocsFiltered, setUnreadDocsFiltered] = useState([]);

  const [readDocs, setReadDocs] = useState([]);
  const [readDocsFiltered, setReadDocsFiltered] = useState([]);

  /*
  const [filter, setFilter] = useState([
    "energie",
    "versicherungen",
    "finanzen",
    "immobilien",
    "neuigkeiten",
    "vorteilspartner"
  ]);
  */

  const [filter, setFilter] = useState([
    "angebote",
    "rechnungen",
    "verträge",
    "news"
  ]);

  /*const [filter, setFilter] = useState({
    energie: true,
    versicherungen: true,
    finanzen: true,
    immobilien: true,
    neuigkeiten: true,
    vorteilspartner: true
  });*/

  const [allChecked, setAllChecked] = useState(true);

  const [showNewDocs, setShowNewDocs] = useState(true);

  const { currentUser } = useContext(AuthContext);


  useEffect(() => {

  
    async function getFirebaseDocs() {
      //const auth = getAuth();
      //const colRef = collection(db, "Users", currentUser.uid, "Dokumente");
      const colRef = collection(db, "Users", currentUser.uid, "Unread");
      const unreadDocuments = await getDocs(colRef);

      let unreadDocumentsArr = [];
      let readDocumentsArr = [];

      unreadDocuments.forEach((doc) => {
        if(!doc.data().gelesen) {
          unreadDocumentsArr.push({id: doc.id, ...doc.data()});
        }
        else
        {
          readDocumentsArr.push({id: doc.id, ...doc.data()})
        } 

      });

      //setUnreadDocs(unreadDocumentsArr);
      return {
        readDocs: readDocumentsArr,
        unreadDocs: unreadDocumentsArr,
      };
    }

    async function loadNewsletter() {
      
      const response = await axios.get(
        "https://api.vin1.eu/newsletterFeed.php?AUTH=bv8FnNYM6JU6Uk7QE8ujvM6S6WMVpg"
      );
      const newsletterAPI = [];
      for (const key in response.data) {
        const newsObj = {
          id: response.data[key].ID,
          name: response.data[key].TITEL,
          navigation: "news",
          date: response.data[key].DATUM,
        };
        newsletterAPI.push(newsObj);
      }

      //setNewsletter(newsletterAPI);
      return newsletterAPI;

      //setUnreadDocs([...unreadDocs], [...newsletterAPI]);
    }

    async function getAllDocs() {
      const { readDocs, unreadDocs } = await getFirebaseDocs();

      const news = await loadNewsletter();
      setUnreadDocs([...unreadDocs, ...news]);
      setUnreadDocsFiltered([...unreadDocs, ...news]);


      // News gelesen fehlt noch. Muss aber auch getrackt werden.
      setReadDocs([...readDocs]);
      setReadDocsFiltered([...readDocs]);
      
      //setReadDocs([...docs, ...news]);
      //setReadDocsFiltered([...docs, ...news]);
    }

    getAllDocs();
  }, [currentUser]);



  // ------ Filter zurücksetzen ------
  useEffect(() => {
    if (
      /*
      filter.energie === false ||
      filter.versicherungen === false ||
      filter.finanzen === false ||
      filter.immobilien === false ||
      filter.neuigkeiten === false ||
      filter.vorteilspartner === false
      */
     filter.length < 4
    ) {
      setAllChecked(false);
    }
    else {
      setAllChecked(true);
    }
  }, [filter]);
  

 
  // ---------- Dynamisches Filtern ----------
  useEffect(() => {


    setUnreadDocsFiltered(unreadDocs.filter(elem => {

        // wenn nicht alle checkboxen ausgefüllt => wenn neue Checkboxen, dann filtern...
        if (filter.length <= filter.length) {

          //check if undefined + if in array
          if(elem.navigation && filter.includes(elem.navigation.toLowerCase())) {
            return true;
          } else {
            return false;
          }   
        } else {
          return true;
        }
    }));

  }, [filter])

  
  // Set Filter onChange Checkbox
  const setCBs = (e) => {

    if (e.target.checked && !filter.includes(e.target.name)) {
      setFilter([...filter, e.target.name]);
    } else {
      setFilter(oldVal => oldVal.filter(obj => obj !== e.target.name));
    }
    /*
    setFilter({
      ...filter,
      [e.target.name]: e.target.checked
    });
    */
  }


  const handleFilterBtn = () => {
    setShowFilter(!showFilter);
  }


  const resetFilter = () => {
    /*
    setFilter({
      energie: true,
      versicherungen: true,
      finanzen: true,
      immobilien: true,
      neuigkeiten: true,
      vorteilspartner: true
    })*/
    /*
    setFilter([
      "energie",
      "versicherungen",
      "finanzen",
      "immobilien",
      "neuigkeiten",
      //"vorteilspartner",
      //"partner"
    ]);
    */
    setFilter([
      "angebote",
      "rechnungen",
      "verträge",
      "news"
      //"vorteilspartner",
      //"partner"
    ]);
  };


  const handleUpdateReadStatusDoc = async(docId) => {

    const docRef = doc(db, "Users", currentUser.uid, "Unread", docId);

    try {
      await updateDoc(docRef, {
        gelesen: true,
      });

      Swal.fire({
        icon: "success",
        title: 'Erfolg',
        html: 'Erfogreiuch geupdated :) '
      });
      
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: 'Fehler',
        html: 'Fehler beim updaten des "gelesen" Status. '+error
      });
    }

  }



  return (
    <>
      <Header url={"/news"}/>
      <Container style={{ maxWidth: "800px" }}>

        <Row className="justify-content-center">
          <Col style={{ textAlign: "center" }}>
            <img
              src={require("../../img/Newsfeed.png")}
              alt=""
              style={{ maxWidth: "160px", margin: "40px 0px" }}
            />
            <h5>Mit einem Klick alles im Blick</h5>
            <div>
              Hier findest du neue Dokumente (Anträge, Angebote, Rechnungen) und aktuelle News und Sonderaktionen. 
              Nutze die Filterfunktion, um Aktuelles schneller aus einem bestimmten Bereich einzusehen.
            </div>
          </Col>
        </Row>


        {/*
        <hr />

        <Row style={{ justifyContent: "center", paddingLeft: "15px" }} className={style.gap}>
          <h5 className="text-center fw-bold">Wähle aus (vorauswahl?)</h5>
          <Col xs={4}>
            <CardSub title={"VIN1"} onClick={() => setNewsfeed("VIN1")} />
          </Col>
          <Col xs={4}>
            <CardSub title={"Partner"} onClick={() => setNewsfeed("Partner")}/>
          </Col>
        </Row>

        <hr />
        */}


        <Row className="mt-5 justify-content-center">

          {windowDimensions.width <= 766  &&
            <div style={{display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer", marginBottom: "30px"}} onClick={handleFilterBtn}>
              <span style={{paddingRight: "10px", fontSize: "120%"}}>Filter anzeigen</span>
              {showFilter && <FontAwesomeIcon className={style.icon} icon={faChevronUp} size="lg" />}
              {!showFilter && <FontAwesomeIcon className={style.icon} icon={faChevronDown} size="lg" />}
            </div>    
          }

          {(windowDimensions.width >= 766 || (windowDimensions.width <= 766 && showFilter)) &&
            <>
              <p className="text-center">Welche Dokumente und News sollen angezeigt werden?</p>

              <div className={style.cbContainer}>

                <label className="form-check-label mt-2">
                  <input
                    id="angebote"
                    className="form-check-input"
                    type="checkbox"
                    name="angebote"
                    checked={filter.includes("angebote") ? true : false}
                    onChange={(e) => setCBs(e)}
                  />
                  <span style={{ paddingLeft: "10px" }}>Angebote</span>
                </label>

                <label className="form-check-label mt-2">
                  <input
                    id="rechnungen"
                    className="form-check-input"
                    type="checkbox"
                    name="rechnungen"
                    checked={filter.includes("rechnungen") ? true : false}
                    onChange={(e) => setCBs(e)}
                  />
                  <span style={{ paddingLeft: "10px" }}>Rechnungen</span>
                </label>

                <label className="form-check-label mt-2">
                  <input
                    id="verträge"
                    className="form-check-input"
                    type="checkbox"
                    name="verträge"
                    checked={filter.includes("verträge") ? true : false}
                    onChange={(e) => setCBs(e)}
                  />
                  <span style={{ paddingLeft: "10px" }}>Verträge</span>
                </label>

                <label className="form-check-label mt-2">
                  <input
                    id="news"
                    className="form-check-input"
                    type="checkbox"
                    name="news"
                    checked={filter.includes("news") ? true : false}
                    onChange={(e) => setCBs(e)}
                  />
                  <span style={{ paddingLeft: "10px" }}>News</span>
                </label>

                {/*
                <label className="form-check-label mt-2">
                  <input
                    id="energie"
                    className="form-check-input"
                    type="checkbox"
                    name="energie"
                    checked={filter.includes("energie") ? true : false}
                    onChange={(e) => setCBs(e)}
                  />
                  <span style={{ paddingLeft: "10px" }}>Energie</span>
                </label>

                <label className="form-check-label mt-2">
                  <input
                    id="versicherungen"
                    className="form-check-input"
                    type="checkbox"
                    name="versicherungen"
                    checked={filter.includes("versicherungen") ? true : false}
                    onChange={(e) => setCBs(e)}
                  />
                  <span style={{ paddingLeft: "10px" }}>Versicherungen</span>
                </label>

                <label className="form-check-label mt-2">
                  <input
                    id="finanzen"
                    className="form-check-input"
                    type="checkbox"
                    name="finanzen"
                    checked={filter.includes("finanzen") ? true : false}
                    onChange={(e) => setCBs(e)}
                  />
                  <span style={{ paddingLeft: "10px" }}>Finanzen</span>
                </label>

                <label className="form-check-label mt-2">
                  <input
                    id="immobilien"
                    className="form-check-input"
                    type="checkbox"
                    name="immobilien"
                    checked={filter.includes("immobilien") ? true : false}
                    onChange={(e) => setCBs(e)}
                  />
                  <span style={{ paddingLeft: "10px" }}>Immobilien</span>
                </label>

                <label className="form-check-label mt-2">
                  <input
                    id="neuigkeiten"
                    className="form-check-input"
                    type="checkbox"
                    name="neuigkeiten"
                    checked={filter.includes("neuigkeiten") ? true : false}
                    onChange={(e) => setCBs(e)}
                  />
                  <span style={{ paddingLeft: "10px" }}>News</span>
                </label>
              */}

                {/*
                  <label className="form-check-label mt-2">
                    <input
                      id="vorteilspartner"
                      className="form-check-input"
                      type="checkbox"
                      name="vorteilspartner"
                      checked={filter.includes("vorteilspartner") ? true : false}
                      onChange={(e) => setCBs(e)}
                    />
                    <span style={{ paddingLeft: "10px" }}>Vorteilspartner</span>
                  </label>
                */}

                {/*<label className="form-check-label mt-2">
                  <input
                    id="partner"
                    className="form-check-input"
                    type="checkbox"
                    name="partner"
                    checked={filter.includes("partner") ? true : false}
                    onChange={(e) => setCBs(e)}
                  />
                  <span style={{ paddingLeft: "10px" }}>Partner</span>
                </label>*/}

                
              </div>

              <Row className="justify-content-center">
                {!allChecked &&
                  <Button onClick={resetFilter} className={[style.resetBtn, "w-auto"]} >
                    Filter zurücksetzen
                  </Button>
                }
              </Row>
            </>
        }

        </Row>



        <Row style={{ justifyContent: "center" }}>
          <Col
            style={{
              marginBottom: "30px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {showNewDocs && (
              <>
                <Button
                  onClick={() => setShowNewDocs(true)}
                  style={{
                    background: "green",
                    border: "none",
                    marginRight: "15px",
                  }}
                >
                  Ungelesene Dokumente
                </Button>
                <Button
                  onClick={() => setShowNewDocs(false)}
                  style={{
                    background: "none",
                    color: "#520ca7aa",
                    border: "2px solid #520ca7aa",
                  }}
                >
                  Archiv anzeigen
                </Button>
              </>
            )}

            {!showNewDocs && (
              <>
                <Button
                  onClick={() => setShowNewDocs(true)}
                  style={{
                    background: "none",
                    color: "#520ca7aa",
                    border: "2px solid #520ca7aa",
                    marginRight: "15px",
                  }}
                >
                  Ungelesene Dokumente
                </Button>
                <Button
                  onClick={() => setShowNewDocs(false)}
                  style={{ background: "green", border: "none" }}
                >
                  Archiv anzeigen
                </Button>
              </>
            )}
          </Col>
        </Row>


        {/* Feed Ungelesene Dokumente + News */}
        {showNewDocs &&
          <DokumentenFeed data={unreadDocsFiltered} status={showNewDocs} handleUpdateGelesen={handleUpdateReadStatusDoc} />
        }


        {/* Feed Ungelesene Dokumente + News */}
        {!showNewDocs &&
          <DokumentenFeed data={readDocsFiltered} status={showNewDocs} />
        }

      </Container>

      <div style={{ marginBottom: "50px" }}></div>
    </>
  );
}

export default UnreadDocs;
