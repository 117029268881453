
// Your web app's Firebase configuration - VIN1 App Live Zugangsdaten!!
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";


//Added because of ContextAPI 
import { createContext, useContext, useState, useEffect } from "react";
import { onAuthStateChanged } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBrsX-NDH_biS8tMHyrf2D2qSNjJEONzEg",
  authDomain: "vin1-app.firebaseapp.com",
  projectId: "vin1-app",
  storageBucket: "vin1-app.appspot.com",
  messagingSenderId: "434911354294",
  appId: "1:434911354294:web:a9ecb926cb97986b51b517"
};

  /*
    // https://github.com/firebase/firebase-js-sdk/issues/1847
    // Import it from your preferred package.
    // expo install eact-native-async-storage/async-storage
    import AsyncStorage from '@react-native-async-storage/async-storage';

    // Provide it to initializeAuth.
    const auth = initializeAuth(app, { persistence: getReactNativePersistence(AsyncStorage) });
  */


    // auskommentiert, nur react native...
    //import AsyncStorage from '@react-native-async-storage/async-storage';

    // Initialisierung Firebase V9
    const app = initializeApp(firebaseConfig);
    
    export const auth = getAuth(app);
    //export const auth = initializeAuth(app, { persistence: getReactNativePersistence(AsyncStorage) });
    export const db = getFirestore(app);
    export const storage = getStorage(app);


// Global Context User State



/*
export const AuthContext = createContext()

export const AuthContextProvider = props => {
  const [user, setUser] = useState()
  const [error, setError] = useState()

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(getAuth(), setUser, setError)
    return () => unsubscribe()
  }, [])
  return <AuthContext.Provider value={{ user, error }} {...props} />
}


export const useAuthState = () => {
  const auth = useContext(AuthContext)
  return { ...auth, isAuthenticated: auth.user != null }
}
*/
