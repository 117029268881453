import React, { useState, useEffect, useContext } from 'react'
import Header from '../../Header/Header'

import { useLocation } from 'react-router-dom'

import CardSmall from "../../Card/CardSmall"
import CardSub from "../../Card/CardSub"
import CardUpload from "../../Card/CardUpload"
import CardDokument from '../../Card/CardDokument'

import axios from 'axios';

import IMGViewModal from '../../Bausteine/IMGViewModal/IMGViewModal'

import { Container, Row, Col } from 'react-bootstrap';

import { ref, uploadBytesResumable, getDownloadURL, deleteObject } from "@firebase/storage";
import { db, storage } from "../../../firebase";
import { collection, doc, addDoc, getDocs, deleteDoc } from "firebase/firestore"; 
import { makeID } from '../../../util/makeID'
import Swal from 'sweetalert2'
import { AuthContext } from '../../../store/auth-context'


function DokuPrivat() {

  const { currentUser } = useContext(AuthContext);

    // Preview Modal JPG + PNG
    const [showModal, setShowModal] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [modalImgSrc, setModalImgSrc] = useState("");

    const location = useLocation();

    const [unterkategorienDefinierte, setunterkategorienDefinierte] = useState([]);

    const [category, setCategory] = useState("");
    const [subcategory, setSubcategory] = useState("");

    const [pickedFiles, setPickedFiles] = useState([]);

    const [progress, setProgress] = useState(0);
    const [privateDocs, setPrivateDocs] = useState([]);

    // -------  In Context packen ------- 
    const [kategorien, setKategorien] = useState([]);

    useEffect(() => {


      async function getSubcategories() {   
        let kategorien = await axios.get("https://api.mariusschulte.com/kategorien.php");
        //console.log(kategorien); 
        
        setKategorien(kategorien.data);
        //console.log(kategorien.data);
      }
      getSubcategories();   
      
      if(location.state) {
        if(location.state.category){
          setCategory(location.state.category);
        }
        if(location.state.category && location.state.subcategory) {
          setSubcategory(location.state.subcategory);
        }
      }


      // Get Dokumente
      async function getPrivateDocs() {

        //const auth = getAuth();
        //const colRef = collection(db, "Users", auth.currentUser.uid, "Privat");
        const colRef = collection(db, "Users", currentUser.uid, "Privat");
        const Documents = await getDocs(colRef);

        let DocumentsArr = [];
        Documents.forEach(doc => {
          //console.log(doc);
          if (doc.data()) {
            DocumentsArr.push({
              id: doc.id,
              data: doc.data()}
            );
          }
      })
      setPrivateDocs(DocumentsArr);
    }
    getPrivateDocs();
      
    }, [currentUser])
    // -------  In Context packen ------- 

    function handleCategory(category) {
        setCategory(category);
        setSubcategory("");
    }

    function handleSubCategory(subcategory) {
      setSubcategory(subcategory);
    }


    function handleFile(e) {
      
        //console.log(e.target.files[0].size);

        setPickedFiles([...pickedFiles, {
          id: makeID(8),
          file: e.target.files[0],
          name: e.target.files[0].name,
          size: e.target.files[0].size,
          type: e.target.files[0].type,
          path: e.target.value
        }
      ]);
      
    }

    function removeFileUploadHandler (id) {

      //console.log("remove: " + name);
      setPickedFiles(pickedFiles.filter(obj => obj.id !== id));

    }

  


    function handleFileUpload() {

      if(pickedFiles.length >=1) {

        for (let i = 0; i < pickedFiles.length; i++) {

        // Upload Resumable....
        let UUID = crypto.randomUUID();
        //const storageRef = ref(storage, "/"+auth.currentUser.uid+"/"+UUID+pickedFiles[i].name);
        const storageRef = ref(storage, "/"+currentUser.uid+"/"+UUID+pickedFiles[i].name);
        const uploadTask = uploadBytesResumable(storageRef, pickedFiles[i].file);

        uploadTask.on("state_changed", (snapshot) => {
            //const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
            //setProgress(progress); 
        },
        (error) => {
          console.log("Error: " +error);
          //https://firebase.google.com/docs/storage/web/upload-files
        },
        () => {
            // Hier kann tein Callback definiert werden, nach erfolgreichem Upload
            // Erstellen des "Meta-Dokuments zum Upload"
            getDownloadURL(uploadTask.snapshot.ref)
            .then((url) => {

       
              //addDoc(collection(db, "Users", auth.currentUser.uid, "Privat"), {     
              addDoc(collection(db, "Users", currentUser.uid, "Privat"), {     
                title: UUID+pickedFiles[i].name,
                description: "",
                uploader: "self",
                category: category,
                sub_category: subcategory,
                created: new Date(),
                mimeType: pickedFiles[i].type,
                size: pickedFiles[i].size,
                url: url
              })
              .then((docRef) => {

                setPrivateDocs([{
                  id: docRef.id,
                  data: {
                    title: UUID+pickedFiles[i].name,
                    description: "",
                    uploader: "self",
                    category: category,
                    sub_category: subcategory,
                    created: new Date(),
                    mimeType: pickedFiles[i].type,
                    size: pickedFiles[i].size,
                    url: url
                  }
                }, ...privateDocs
                ])
              })
              .catch((error) => {
                //console.log(error);
                Swal.fire({
                  title: "Dokument Upload",
                  text: "Es gab einen Fehler beim Upload deiner Dokumente. Bitte versuche es später erneut.",
                  icon: "error"
                });

              });

              });
        });


      } // loop multiple files..
             
          Swal.fire({
            title: 'Erfolg',
            html: 'Alle Dateien wurden erfolgreich hochgeladen.',
            icon: 'success'
          })
          setPickedFiles([]);
      }

    }



    async function deleteFileAndDoc(id, filename) {

      //const storageRef = ref(storage, "/"+auth.currentUser.uid+"/"+filename);
      const storageRef = ref(storage, "/"+currentUser.uid+"/"+filename);
      // Delete the file
      deleteObject(storageRef)
      .then(() => {
        // if file deleted, delete metadata
        //deleteDoc(doc(db, "Users", auth.currentUser.uid, "Privat", id))
        deleteDoc(doc(db, "Users", currentUser.uid, "Privat", id))
        .then (() => {
          setPrivateDocs(privateDocs.filter(docs => docs.id !== id));
        })
        .catch((error) => {
          console.log(error);
        });
      })
      .catch((error) => {
        console.log(error);
      });
      
    }
  


  

  return (
    <>
      <Header url={"/dokumente"}/>
      <Container style={{maxWidth: "800px"}}>
        <Row style={{justifyContent: "center"}}>
          <Col>
            <h1 style={{color: "#520da7"}}>Private Dokumente</h1><br />
            <p className="text-center">
              Dieser Bereich ist dein ganz persönlicher Bereich.
            </p>
            <p className="text-center">
              Hier bewahrst du deine Unterlagen ohne Zugriff durch Dritte datenschutzkonform auf.
            </p>
            <p className="text-center" >
             Damit schützt du deine Dokumente vor Verlust (z.B. im Brandfall, Hochwasser oder bei Diebstahl).
            </p>
            <p className="text-center">
              Finde deine Dokumente und Informationen dank digitaler Akte blitzschnell.
            </p>         
          </Col>
        </Row>
        
        <Row style={{justifyContent: "center", marginTop: "50px"}}>
            <p className="text-center">
              Um deine Dokumente einzusehen oder hochzuladen wähle eine passende Kategorie
            </p>
          <Col xs={6} md={3} style={{marginTop: "20px"}} onClick={() => handleCategory("Ich")}>
            {category == "Ich" && <CardSub title={"Ich"} selected={1} color={"#520da7"} />}
            {category != "Ich" && <CardSub title={"Ich"} />}
          </Col>

          <Col xs={6} md={3} style={{marginTop: "20px"}}  onClick={() => handleCategory("Partner")}>
            {category == "Partner" &&  <CardSub title={"Partner"} selected={1} color={"#520da7"} />}
            {category != "Partner" &&  <CardSub title={"Partner"} />}
          </Col>

          <Col xs={6} md={3} style={{marginTop: "20px"}} onClick={() => handleCategory("Kind")}>
            {category == "Kind" &&  <CardSub title={"Kind"} selected={1} color={"#520da7"} />}
            {category != "Kind" &&  <CardSub title={"Kind"} />}
          </Col>

          <Col xs={6} md={3} style={{marginTop: "20px"}} onClick={() => handleCategory("Haustier")}>
            {category == "Haustier" &&  <CardSub title={"Haustier"} selected={1} color={"#520da7"} />}
            {category != "Haustier" &&  <CardSub title={"Haustier"} />}
          </Col>
        
        </Row>

        <Row style={{justifyContent: "center", marginTop: "20px"}}>

        <Col xs={6} md={3} style={{marginTop: "20px"}} onClick={() => handleCategory("Cashback")}>
            {category == "Cashback" &&  <CardSub title={"Cashback"} selected={1} color={"#520da7"} />}
            {category != "Cashback" &&  <CardSub title={"Cashback"} />}
          </Col>
        
        </Row>


        <Row style={{marginTop: "30px", justifyContent: "center"}} >

          {category &&
            <Col xs={12} md={12} style={{marginBottom: "0"}}>
              <p className="text-center">Bitte wähle eine der folgenden Unterkategorien</p>
            </Col>
          }

          {category && (kategorien.filter((key) => key.Oberkategorie == category)).map((kategorie) => 
            <Col 
              key={kategorie.Unterkategorie} //unique key accross all selections
              xs={6} md={3}
              style={{marginTop: "20px"}}
              onClick={() => handleSubCategory(kategorie.Unterkategorie)}
            >
              {subcategory === kategorie.Unterkategorie &&
                <CardSub key={kategorie.Unterkategorie} title={kategorie.Unterkategorie} selected={1} color={"#520da7"} />
              }
              
              {subcategory !== kategorie.Unterkategorie &&
                <CardSub key={kategorie.Unterkategorie} title={kategorie.Unterkategorie} />
              }
                          
            </Col>
          )}  
        </Row>






        <Row style={{marginTop: "16px", justifyContent: "center", marginBottom: "50px"}} >
        {/* Dokument Upload */}
        {category && subcategory &&
         <Col
            xs={12} md={12}
            className="uploadContainer"
          >
            <p className="text-center" style={{marginTop: "30px"}}>Deine gewählte Kategorie: <span className="fw-bold">{category} &gt; {subcategory}</span></p>

            <h4 className="text-center">Dateiupload</h4>
            <p>1. Wähle deine Dateien aus (Fotos oder PDF)</p>

            <label htmlFor="docUpload" className="uploadBtn" style={{cursor: "pointer"}}>
              Datei auswählen
              <input
                id="docUpload"
                type="file"
                accept='application/pdf, image/png, image/jpeg'
                style={{display: "none"}}
                onChange={handleFile}
              />
            </label>
            

      
            {pickedFiles.length > 0 &&
              <div className="text-center" style={{marginTop: "20px", width: "100%"}}>
                     {/*<p>2. Gib der Datei eine kurze und knackige Beschreibung (z.B. "Perso" oder "Fahrzeugbrief")</p>*/}
                  <p>2. Lade das/die Dokument/-e hoch</p>
                  
                  {pickedFiles.map(file => 
                      <CardUpload key={file.id} data={file} removeFileUploadHandler={removeFileUploadHandler} />
                  )}

                  {/*}p className="text-center">{progress}%</p>*/}
                  <button className="uploadBtn" style={{cursor: "pointer"}} onClick={() => handleFileUpload()}>Dokumente hochladen</button>
              </div>
            }

            </Col>
          }
        </Row>






        <Row style={{ justifyContent: "center", marginBottom: "80px", marginTop: pickedFiles.length > 0 ? "50px" : "0px"}} >
          
          {/* Dokumenten Liste */}
          {category && subcategory &&
            <Col
                xs={12} md={12}
              className="text-center"
              >
                <h4 className="text-center" style={{marginBottom: "20px"}}>Meine Dokumente</h4>

                {(privateDocs.filter((key) => key.data.category == category && key.data.sub_category == subcategory)).length == 0 && <p style={{marginTop: "10px"}}>Noch keine Dokumente vorhanden.</p>}
                
                {(privateDocs.filter((key) => key.data.category == category && key.data.sub_category == subcategory)).map(doc => 
                    <CardDokument key={doc.id} data={doc} deleteFileAndDoc={deleteFileAndDoc} setModalTitle={setModalTitle} setModalImgSrc={setModalImgSrc} setShowModal={setShowModal} />
                )}

            </Col>
          }

        </Row>

        {showModal && <IMGViewModal title={modalTitle} imgSrc={modalImgSrc} visible={setShowModal} />}     
      
      </Container>
    </>
  );
}

export default DokuPrivat
