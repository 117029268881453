import React, { useState } from 'react'

import { useNavigate } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import style from "./Footer.module.css";

import WebViewModal from "../Bausteine/WebViewModal/WebViewModal";

function Footer() {

  let navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);
  const [modalUrl, setModalUrl] = useState(false);

  const handleExternalURL = (url) => {

    setShowModal(false);
    window.scrollTo({
      top: 0,
      behavior: "instant"
    });
    setModalUrl(url);
    setShowModal(true);
  }
  
  return (
    <Container className={style.container}>
      <Row className={style.rowContainer}>
        <Col className={style.column} onClick={() => navigate("/informationen-dokumente")}>Erstinformationen</Col>
        <Col className={style.column} onClick={() => navigate("/informationen-dokumente")}>Maklervertrag/-vollmacht</Col>
      </Row>
      <Row className={style.rowContainer}>
        <Col className={style.column} xs={12} md={3} onClick={() => navigate("/impressum")}>Impressum</Col>
        <Col className={style.column} xs={12} md={3} onClick={() => navigate("/datenschutz")}>Datenschutz</Col>
        <Col className={style.column} xs={12} md={3} onClick={() => navigate("/nutzungsbedingungen")}>Nutzungsbedingungen</Col>
        <Col className={style.column} xs={12} md={3} onClick={() => handleExternalURL("https://vin1.eu/")}>vin1.eu</Col>
      </Row>

      {showModal && (
          <WebViewModal
            url={modalUrl}
            hideModal={setShowModal}
          />
        )}
    </Container>
  )
}

export default Footer