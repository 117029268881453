import React from 'react'

import style from "./VINButton.module.css"

function VINButton(props) {

  return (
    <div className={[style.container, style.containerOne]}>
        <button className={style.vinbutton}>
            {props.text}
        <div className={style.fillOne}></div>
        </button>
    </div>
  )
}

export default VINButton