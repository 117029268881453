import React, { Fragment } from 'react'
import Header from '../Header/Header'

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function Erstinformation() {
  return (
    <Fragment>
      <Header />
      <Container style={{maxWidth: "800px"}}>
        <Row style={{justifyContent: "center"}}>
          <Col>
            <h1>Erstinformation</h1>
            <h5>Gesellschaft</h5>
            <p>
              VIN1 Germany GmbH<br />
              Aspastr.24 | D-59394 Nordkirchen<br />
              Telefon: +49(0) 2596/ 614 8181<br />
              E-Mail: <a href="mailto:hello@vin1.eu" noreferrer noopener nofollow>hello@vin1.eu</a><br />
              <br />
              eingetragen im Handelsregister des Amtsgerichtes Coesfeld<br />
              Handelsregisternummer HRB 20746<br />
              Vertreten durch die Geschäftsführerin Frau Adaleta Dinovic (M.Sc.)
            </p>

            <h5>Vermittlerregister</h5>
            <p>
              (<a href="https://www.vermittlerregister.info" target="_blank" noreferrer noopener nofollow>www.vermittlerregister.info</a>):<br />
              Register-Nr.: D-Y5NZ-Y52VU-48 §34d Abs.1 GewO<br />
              Register-Nr.: Bitte Anfragen (Technische Probleme bei der Vergabe durch die IHK) §34i Abs.1 S.1 GewO
            </p>

            <h5>Die Eintragung im Vermittlerregister kann überprüft werden</h5>
            <p>
            Deutscher Industrie- und Handelskammertag (DIHK) e.V.<br />
            Breite Straße 29<br />
            10178 Berlin<br />
            Telefon: 0180 600 585 0<br />
            (20 Cent/Anruf aus dem dt. Festnetz, höchstens 60 Cent/Anruf aus Mobilfunknetzen)<br />
            <a href="https://www.vermittlerregister.info" target="_blank" noreferrer noopener nofollow>www.vermittlerregister.info</a>
            </p>

            <h5>Berufsbezeichnung und berufsrechtliche Regelungen</h5>
            <p>
              Versicherungsmakler mit Erlaubnis nach § 34d Abs. 1<br />
              Gewerbeordnung; Bundesrepublik Deutschland<br />
              Immobiliardarlehensvermittler nach § 34i Abs. 1 S. 1 Gewerbeordnung;<br />
              Bundesrepublik Deutschland<br />
              Zuständige Kammer: IHK Nord Westfalen, Münster
            </p>

            <h5>Berufsrechtliche Regelungen</h5>
            <p>
              <li>§ 34d Gewerbeordnung (GewO)</li>
              <li> § 59-68 Gesetz über den Versicherungsvertrag (VVG)</li>
              Verordnung über die Versicherungsvermittlung und -beratung (VersVermV)<br />
              Die berufsrechtlichen Regelungen können über die vom Bundesministerium der Justiz und von der juris GmbH betriebenen Homepage <a href="http://www.gesetze-im-internet.de" target="_blank" norefferer noopener nofollow>http://www.gesetze-im-internet.de</a> eingesehen und abgerufen werden.
            </p>

            <h5>Beratungsleistung</h5>
            <p>
            Hinsichtlich der vermittelten Versicherungsprodukte bietet die VIN1 Germany GmbH eine Beratung an.
            </p>

            <h5>Vergütung</h5>
            <p>
              Für die Vermittlung der Versicherungsprodukte erhält die VIN1 Germany GmbH eine Provision, welche in der Versicherungsprämie enthalten ist. Es werden Versicherungsprodukte von einer Vielzahl unterschiedlicher Versicherer vermittelt. Die mit den Versicherungsgesellschaften vereinbarten Provisionen können sich der Höhe nach unterscheiden.
            </p>

            <h5>Unabhängigkeit</h5>
            <p>
              Die VIN1 Germany GmbH hält keine direkten oder indirekten Beteiligungen von über 10 % der Stimmrechte oder des Kapitals an einem Versicherungsunternehmen. Ein Versicherungsunternehmen oder deren Muttergesellschaft hält keine direkten oder indirekten Beteiligungen von über 10 % der Stimmrechte oder des Kapitals an der VIN1 Germany GmbH.
            </p>

            <h5>Angaben zur Berufshaftpflichtversicherung</h5>
            
            <h6>Name und Sitz des Versicherers:</h6>
            <p>
              andsafe Aktiengesellschaft<br />
              Provinzial-Allee 1<br />
              48159 Münster<br />
            </p>

            <h6>Geltungsraum der Versicherung:</h6>
            <p>
              In den geografischen Grenzen Europas sowie den außereuropäischen Gebieten, die zum Geltungsbereich der Europäischen Union gehören<br />
            </p>
            <h6>Schlichtungsstellen, die bei einem Streit angerufen werden können:</h6>
            <p>
              Versicherungsombudsmann e.V.<br />
              Prof. Dr. Günter Hirsch<br />
              Postfach 08 06 32<br />
              10006 Berlin<br />
              Telefon: 0800 3696000 (kostenfrei)<br />
              <br />
              (weitere Informationen unter: <a href="https://www.­versicherungsombudsmann.de" target="_blank" noreferrer noopener nofollow>www.­versicherungsombudsmann.de</a>)<br />
              <br />
              Ombudsmann für die private Kranken-und Pflege­versicherung<br />
              Herr Heinz Lanfermann<br />
              Postfach 060222<br />
              10052 Berlin<br />
              Telefon: 0800 2550444 (kostenfrei aus deutschen Telefonnetzen)<br />
              (weitere Informationen unter: <a href="https://www.pkv-ombudsmann.de" target="_blank" noreferrer noopener nofollow>www.pkv-ombudsmann.de</a>)<br />
              <br />
              Bundesanstalt für Finanzdienstleistungsaufsicht (BAFin)<br />
              Graurheindorfer Straße 108<br />
              53117 Bonn<br />
              Telefon: 0228 4108-0
            </p>
            <h5>Zuständige Erlaubnisbehörde</h5>
            <p>
            Industrie- und Handelskammer Nord Westfalen<br />
            Körperschaft des öffentlichen Rechts<br />
            Sentmaringer Weg 61, 48151 Münster<br />
            Postfach 40 24, 48022 Münster<br />
            Telefon 0251 707-0 | Telefax: 0251 707-368<br />
            Internet <a href="https://www.ihk.de/nordwestfalen" target="_blank" noreferrer noopener nofollow>www.ihk.de/nordwestfalen</a><br />
            <a href="mailto:infocenter@ihk-nordwestfalen.de" noreferrer noopener nofollow>infocenter@ihk-nordwestfalen.de</a><br />
            <a href="mailto:info@ihk-nordwestfalen.de" noreferrer noopener nofollow>info@ihk-nordwestfalen.de</a><br />
            </p>
          </Col>
        </Row>
      </Container>
    </Fragment>

  )
}

export default Erstinformation