import React, { useState } from 'react'
import Header from '../../Header/Header'
import { useNavigate } from 'react-router-dom';

import WebViewModal from "../../Bausteine/WebViewModal/WebViewModal";

import { Container, Row, Col } from 'react-bootstrap';

import style from './Energie.module.css'


function Energie() {
  
  const ColorTheme = '#F56161';

  let navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);
  const [modalUrl, setModalUrl] = useState(false);

  const handleExternalURL = (url) => {

    setShowModal(false);
    window.scrollTo({
      top: 0,
      behavior: "instant"
    });
    setModalUrl(url);
    setShowModal(true);
  }

  return (
    <>
      <Header />
      <Container style={{maxWidth: "800px"}}>
        <Row style={{justifyContent: "center"}}>
          <Col style={{textAlign: "center"}}>
            <h1 style={{color: ColorTheme}}>Übersicht Energie</h1>
          </Col>
        </Row>

        <br />
        <br />

        <Row className={style.Row}>
          <Col xs={{span: 12, order: 2}} md={{span: 5, order: 2}} className={[style.textCard, "shadow p-3 bg-body"]}>
            <h5 className="text-center" style={{ color: ColorTheme }}>Strom</h5>
            <p className="text-center">
              Spielend leicht zum Stromangebot.
              Strom direkt von der Börse.
            </p>
            {/*<button className={style.Btn} onClick={() => handleExternalURL("https://vin1.eu/energie/strom/")}>Mehr Informationen &gt;</button>*/}  
            <button className={style.Btn} onClick={() => navigate("/beratung", { state: { category: "Energie", subcategory: "Strom"}})}>Jetzt beraten lassen &gt;</button>
          </Col>
          <Col xs={{span: 12, order: 1}} md={{span: 5, order: 1}} style={{textAlign: 'center'}}>
            <img src={require("../../../img/Strom.png")} alt="" className={style.ColImg} />
          </Col>
        </Row>

        <Row className={style.Row}>
          <Col xs={{span: 12, order: 1}} md={{span: 5, order: 2}} style={{textAlign: 'center'}}>
            <img src={require("../../../img/Gas.png")} alt="" className={style.ColImg} />
          </Col>
            <Col xs={{span: 12, order: 1}} md={{span: 5, order: 1}} className={[style.textCard, "shadow p-3 bg-body"]}>
            <h5 className="text-center" style={{ color: ColorTheme }}>Gas</h5>
            <p className="text-center">
              Wir schmieden dir deinen Gastarif.
              100% klimaneutrales Ökogas.
            </p>
            {/*<button className={style.Btn} onClick={() => handleExternalURL("https://vin1.eu/energie/gas/")}>Mehr Informationen &gt;</button>*/}
            <button className={style.Btn} onClick={() => navigate("/beratung", { state: { category: "Energie", subcategory: "Gas"}})}>Jetzt beraten lassen &gt;</button>
          </Col>
        </Row>

        

        <Row className={style.Row}>
          <Col xs={{span: 12, order: 2}} md={{span: 5, order: 2}} className={[style.textCard, "shadow p-3 bg-body"]}>
            <h5 className="text-center" style={{ color: ColorTheme }}>Solar / PV</h5>
            <p className="text-center">
              Wir holen die Sonne in dein Zuhause.
              Geprüfte Technologien für dein Solarangebot.
            </p>
            {/*<button className={style.Btn} onClick={() => handleExternalURL("https://vin1.eu/energie/solar/")}>Mehr Informationen &gt;</button>*/}
            <button className={style.Btn} onClick={() => navigate("/beratung", { state: { category: "Energie", subcategory: "Solar / PV"}})}>Jetzt beraten lassen &gt;</button> 
          </Col>
          <Col xs={{span: 12, order: 1}} md={{span: 5, order: 1}} style={{textAlign: 'center'}}>
            <img src={require("../../../img/Solar.png")} alt="" className={style.ColImg} />
          </Col>
        </Row>

      
        <br />
        <br />


        <Row className={style.Row}>
          <div className={style.BottomCard}>
            <h4 className="text-center" style={{width: "85%"}}>Vergleichen und optimieren</h4>
            {/*<p className="text-center" style={{width: "85%"}}>Unkomplizierter Vergleich für das beste Ergebnis!</p>*/}
            {/*<button className={style.BtnNormal} onClick={() => handleExternalURL("https://vin1.eu/energie/")}>Hier mehr erfahren &gt;</button>*/}
            <button className={style.BtnNormal} style={{marginTop: "16px"}} onClick={() => navigate("/beratung", { state: { category: "Energie"}})}>Jetzt Kontakt aufnehmen &gt;</button>
          </div>
        </Row>

        <br />

        <Row style={{marginBottom:"80px"}}>
          <Col style={{textAlign:"center"}}>
            <button className={style.BtnNormal} onClick={() => navigate("/dokumente/energie")}>Zu deiner Dokumentenablage &gt;</button>
          </Col>
        </Row>



        {showModal && (
          <WebViewModal
            url={modalUrl}
            hideModal={setShowModal}
          />
        )}

      </Container>
    </>
  )
}

export default Energie