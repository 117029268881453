import React, { useState, useEffect, useContext } from 'react'
import Header from '../../Header/Header'
import Spinner from '../../Bausteine/Spinner/Spinner';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus, faDownload } from "@fortawesome/free-solid-svg-icons";

import CardGesellschaften from '../../Card/CardGesellschaften';
import { Container, Row, Col, Button, Modal } from 'react-bootstrap';
import style from './BestaendeUebertragen.module.css'

import Select, { createFilter } from 'react-select';
import Swal from 'sweetalert2';

import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "@firebase/storage";
import { db, storage } from "../../../firebase";
import {
  collection,
  doc,
  addDoc,
  setDoc
} from "firebase/firestore";
 
import GesellschaftenData from './Data/GesellschaftenData'
import axios from 'axios';

import VINButton from '../../../VINButton';
import { UserContext } from '../../../store/user-context';
import { AuthContext } from '../../../store/auth-context';


function BestaendeUebertragen() {

  const { user } = useContext(UserContext)
  const { currentUser } = useContext(AuthContext)

  const [spinner, setSpinner] = useState(false);

  const [showModal, setShowModal] = useState(false);

  const [accepted, setAccepted] = useState(false);
  const [datenschutz, setDatenschutz] = useState(false);

  const [submittedOK, setSubmittedOK] = useState(false);

  const [maklervertrag, setMaklervertrag] = useState("0");
  const [options, setOptions] = useState([]);

  const [meineGesellschaften, setMeineGesellschaften] = useState([]);

  const [inputFields, setInputFields] = useState([
    {
      label: '',
      value: ''
    }
  ]);


  const handleAcceptance = async () => {

    if(!accepted || !datenschutz) {
      Swal.fire({
        title: "Pflichtfelder",
        html: "Bitte akzeptiere zunächst den Maklervertrag/-vollmacht und die Datenschutzerklärung und sende das Formular erneut ab.",
        icon: "info",
      });
      return;
    }

    if(user.kundennummer === undefined || user.kundennummer === "") {
      Swal.fire({
        title: "Kundennummer",
        html: "Damit du dieses Formular absenden kannst, benötigst du eine Kundennummer. Wenn du dich nicht gerade registriert hast, wende dich an den Support.",
        icon: "info",
      });
      return;
    }

    if(user.email === undefined || user.email === "") {
      Swal.fire({
        title: "E-Mail",
        html: "Damit du dieses Formular absenden kannst, benötigst du eine hinterlegte E-Mail. Wenn du dich nicht gerade registriert hast, wende dich an den Support.",
        icon: "info",
      });
      return;
    }

    if(user.telefon === undefined || user.telefon === "") {
      Swal.fire({
        title: "Telefon/Mobil",
        html: "Damit du dieses Formular absenden kannst, benötigst du eine hinterlegte Telefonnummer. Wenn du dich nicht gerade registriert hast, wende dich an den Support.",
        icon: "info",
      });
      return;
    }


    if(user.geburtsdatum === undefined || user.geburtsdatum === "" || user.geburtsdatum === "00.00.0000") {
      Swal.fire({
        title: "Geburtsdatum",
        html: "Damit du dieses Formular absenden kannst, benötigst du eine hinterlegtes Geburtsdatum. Wenn du dich nicht gerade registriert hast, wende dich an den Support.",
        icon: "info",
      });
      return;
    }

    //Spinner
    setSpinner(true);

    //Convert List of Objects to Array
    let dataArr = [];
    var values = Object.values(inputFields);
    values.forEach(function(values){
      if(values.value !== "") {
        dataArr.push(values.value);
      }  
    });


    if(dataArr.length === 0) {
      Swal.fire({
        title: "Versicherung",
        html: "Du musst eine Versicherungsgesellschaft auswählen, bevor du das Formular absenden kannst.",
        icon: "info",
      });
      //
      //setSpinner(false);
      return;
    }



    try {
        // Status in CRM Tracken
        await axios({
          method: 'post',
          headers: {
            'Authorization': 'Bearer ' + currentUser.uid
          },
          url: process.env.REACT_APP_BACKEND+"/my-vin1/connect",
          data: {
            kdnr: user.kundennummer,
            gesellschaften: dataArr
          }
        })


        // An Backend senden DB  => Für generieren der CSV-Datei 
        await axios({
            method: 'post',
            url: "https://api.mariusschulte.com/csv/insertConnect.php",
            data: {
              kundennummer: user.kundennummer,
              vorname: user.vorname,
              nachname: user.nachname,
              geburtsdatum: user.geburtsdatum,
              email: user.email,
              telefon: user.telefon,
              data: dataArr
            }
        })
      }
      catch(error) {
        setSpinner(false);
        Swal.fire({
          title: "Versicherung",
          html: "Du musst eine Versicherungsgesellschaft auswählen, bevor du das Formular absenden kannst.",
          icon: "info",
        });

      } 


    // Beide requests ersetzten durch update-status-multi - noch testen!
    /*
    await axios({
      method: 'post',
      headers: {
        'Authorization': 'Bearer ' + currentUser.uid
      },
      url: process.env.REACT_APP_BACKEND+"/my-vin1/update-status-multi",
      data: {
        "update": [
          {
            field: "connect",
            value: "", //aktuell in Backend gesetzt
            googleuid: currentUser.uid,
          },
           {
            field: "maklervollmacht",
            value: "", //aktuell in Backend gesetzt
            googleuid: currentUser.uid,
          },
        ]
      }
    });
    */

    try {
      
      // Set CRM Checkbox Connect
      await axios({
        method: 'post',
        headers: {
          'Authorization': 'Bearer ' + currentUser.uid
        },
        url: process.env.REACT_APP_BACKEND+"/my-vin1/update-status",
        data: {
          "update": "connect",
          "googleuid": currentUser.uid
        }
      });

      // Set CRM Checkbox Maklervollmacht
      await axios({
        method: 'post',
        headers: {
          'Authorization': 'Bearer ' + currentUser.uid
        },
        url: process.env.REACT_APP_BACKEND+"/my-vin1/update-status",
        data: {
          "update": "maklervollmacht",
          "googleuid": currentUser.uid
        }
      });
    } catch (error) {
      setSpinner(false);
      Swal.fire({
        icon: "error",
        title: "Fehler",
        html: "Fehler beim Updaten.",
      });
    }
  

    // Maklervertrag nur beim ersten Prozess in der privaten Dokumentenablage hinterlegen
    if(maklervertrag !== "1") {

      // State anpassen damit direkt geändert
      setDoc(doc(db, "Users", currentUser.uid), {
        maklervertrag: "1"
      }, { merge:true })
      .then(DocRef => {

        setMaklervertrag("1");

        /*
        // Send Mail Maklervertrag
        var bodyFormData2 = new FormData();
        bodyFormData2.append('kundennummer', user.kundennummer);
        bodyFormData2.append('email', user.email);
        bodyFormData2.append('templateId', 4643);

        // keine Mail mehr zur Maklervollmacht
        axios({
            method: 'post',
            url: "https://api.mariusschulte.com/crm/sendMail.php",
            data: bodyFormData2
        });
        */

        //setSpinner(false);
        //console.log("Document has been added successfully");
        Swal.fire({
          title: "Erfolg",
          html: "Ab sofort hast du deine Verträge sicher im Griff. Die akzeptierten Verträge haben wir für dich in deine private Dokumentablage unter Vollmachten hinterlegt.",
          icon: "success",
        });
      })
      .catch(error => {
          //console.log(error.message);
      });

       // Dokument hinterlegen
      handleFileUploadPDF("pdf/Maklervertrag+Vollmacht_VIN1.pdf", "Maklervertrag+Vollmacht_VIN1.pdf");
    }
    else if (maklervertrag === "1")
    {
      //setSpinner(false);
      Swal.fire({
        title: "Erfolg",
        html: "Deine Daten wurden erfolgreich an uns übermittelt. Deine Verträge findest du in Kürze in deiner digitalen Dokumentenablage.",
        icon: "success",
      });

    
    }

    // Update Cards mit übermittelten Daten
    let VersicherungArr = [];
    let VersicherungValues = Object.values(inputFields);
    VersicherungValues.forEach(function(values){
      if(values.value !== "") {
        VersicherungArr.push(values.value);
      }  
    });

    let Gesellschaften  = [];
    Array.from(VersicherungArr).forEach(versicherung => {
      Gesellschaften = [...Gesellschaften, {
            ID: makeid(15),
            Versicherung: versicherung,
            Übermittelt: getUploadDate()
      }
        ]
    });
    setMeineGesellschaften([...Gesellschaften, ...meineGesellschaften]); 
    //setMeineGesellschaften([]);
  }


  const getUploadDate = () => {
    var date=new Date();
    let day=date.getDate();
    let month=date.getMonth();
    month=month+1;

    if((String(day)).length===1)
      day='0'+day;
    if((String(month)).length===1)
      month='0'+month;

    let hours = date.getHours();
    let minutes = date.getMinutes();
    let seconds = date.getSeconds();

    if((String(hours)).length===1)
      hours='0'+hours;
    if((String(minutes)).length===1)
      minutes='0'+minutes;
    if((String(seconds)).length===1)
      seconds='0'+seconds;

    let dateT=day+ '.' + month + '.' + date.getFullYear() + ' '+ hours+':'+minutes+':'+seconds;
    //dateT=String(dateT);
    return dateT;
  }

  const makeid = (length) => {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}



  const addFields = () => {
    //alert("Add new field");
    let newfield = {
      label: '',
      value: ''
    }
    setInputFields([...inputFields, newfield])
  }

  const removeFields = (index) => {
    let data = [...inputFields];
    data.splice(index, 1)
    setInputFields(data)
  }

  const handleFormChange = (index, event) => {

    let data = [...inputFields];
    data[index] = event;
    setInputFields(data);
  }



  function showDownloadModal () {
    setShowModal(!showModal);
  }


  async function downloadPDF(filepath, filename) {

    fetch(filepath).then(response => {
      response.blob().then(blob => {
          // Creating new object of PDF file
          const fileURL = window.URL.createObjectURL(blob);
          // Setting various property values
          let alink = document.createElement('a');
          alink.href = fileURL;
          alink.download = filename;
          alink.click();
      })
    })
  }


  function handleFileUploadPDF(filepath, filename) {

    // Maklervollmacht als Kopie (von dem public Pfad der App) in den Privaten Ordner unter Vollmachten hinterlegen

    let UUID = crypto.randomUUID();
    if (filepath) {
      const storageRef = ref(
        storage,
        "/" + currentUser.uid + "/" + UUID + filename
      );

      var url = "pdf/Maklervertrag+Vollmacht_VIN1.pdf";

      //You may not need this part if you have the PDF data locally already
      var xhr = new XMLHttpRequest();
      xhr.onreadystatechange = function () {
          if (this.readyState === 4 && this.status === 200) {
              //console.log(this.response, typeof this.response);
              //now convert your Blob from the response into a File and give it a name
              var fileOfBlob = new File([this.response], 'your_file.pdf');

        const uploadTask = uploadBytesResumable(storageRef, fileOfBlob);

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            //console.log(snapshot);
          },
          (err) => console.log(err),
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((url) => {

                addDoc(collection(db, "Users", currentUser.uid, "Privat"), {
                  title: UUID + filename,
                  description: "",
                  uploader: "app",
                  category: "Ich",
                  sub_category: "Vollmacht",
                  created: new Date(),
                  mimeType: "application/pdf",
                  size: fileOfBlob.size,
                  url: url,
                });          
            });
          }
        );
      }
    }
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();  

  } //filepath
}

  useEffect(() => {
    //Maklervertrag Zustimmung checken
    if(user) {
      if(user.maklervertrag){
        setMaklervertrag(user.maklervertrag);
      }
    }
  }, [])

  // Alle Gesellschaften hinterlegen + übermittelte Gesellschaften abrufen
  useEffect(() => {
    
    //Alle Gesellschaften hinterlegen
    setOptions(
      GesellschaftenData
    )

    //Übermittelte Gesellschaften abrufen
    axios({
      method: 'post',
      url: "https://api.mariusschulte.com/csv/readInsertConnect.php",
      data: {
        kundennummer: user.kundennummer
      }
    }). then(res => {
      //console.log(res.data);
      setMeineGesellschaften(res.data);
    })
    .catch(error => {
      //console.log(error);‚
    });

  }, [user])

  
  

  
  return (
    <>
     
      <Container style={{maxWidth: "800px"}}>
        <Row className="mt-5" style={{justifyContent: "center"}}>
          <Col style={{textAlign: "center"}}>
            
            {/*<img src={require("../../../img/hands.png")} alt="" style={{maxWidth: "160px", margin: "40px 0px"}} />*/}
      
              <img src={require("../../../img/Bestandsuebertragung.png")} alt="" style={{maxWidth: "160px", margin: "0px 0px 20px 0px"}} />
                <h5>Alle wichtigen Dokumente an einem Ort</h5>
                <p className="text-center">Jederzeit verfügbar und datenschutzkonform gespeichert</p>
                <br />

                <p className="text-center"><span className="fw-bold">1.</span><br />
                Erzähle uns kurz, wo du versichert bist.</p>
                
                <br />
                <p className="text-center">Als Angabe reicht uns der Name deiner Versicherungen.</p>
                <p className="text-center">Klicke in die Felder und suche nach deinen Versicherungen, indem du einen Suchbegriff eingibst.</p>
                  <br />



              <div className={style.SelectInputContainer}>
                {inputFields.map((input, index) => 

                    <div key={index} className={style.InputRow} style={{marginBottom: "50px", width: index >= 1 ? "80%" : "100%", marginLeft: index >= 1 ? "0px" : "20%", display: "flex", alignItems: "center"}}>
                      <div className={style.selectContainer} style={{flex: "8", textAlign: "left"}} >
                        <Select    
                            value={input?.value !== "" ? input : ""}
                            onChange={event => handleFormChange(index, event)}
                            placeholder={"Suche eine Versicherungsgesellschaft"}
                            noOptionsMessage={() => "Kein Eintrag passt zu deiner Suche"}
                            options={options}
                            filterOption={createFilter({ignoreAccents: false})}
                          />
                        </div>
                        <div className={style.removeBtn} style={{flex: "2"}}>
                          {index >= 1 && <button onClick={() => removeFields(index)} style={{marginLeft:"15px"}}className={style.Btn}>Entfernen</button> }
                        </div>
                    </div>
                  
                )}

            </div>

            <div style={{display: "flex", justifyContent: "center", alignItems:"center", flexDirection: "column"}}>
              <FontAwesomeIcon className={style.icon} icon={faCirclePlus} size="2x" onClick={addFields} style={{cursor: "pointer", marginBottom: "10px"}} />
              <p style={{display: "inline-flex", margin: "0px 0px 0px 10px"}}>Weitere Versicherungsgesellschaft hinzufügen</p>
            </div>


            <br />
            <br />

          
            <p className="text-center"><span className="fw-bold">2.</span><br />
                   Stimme folgenden Bedingungen zu.</p>

            <label className="form-check-label" style={{marginTop: "20px"}}>
                    <input
                        id="cbTippgeber"
                        className="form-check-input"
                        type="checkbox"
                        onChange={() => setDatenschutz(!datenschutz)}
                        />   
                    <span style={{paddingLeft: "10px"}}>Ich stimme den <a href="/datenschutz" target="_blank">Datenschutzbedingungen</a> zu.</span>
                </label>

                <label className="form-check-label" style={{marginTop: "20px"}}>
                    <input
                        id="cbTippgeber"
                        className="form-check-input"
                        type="checkbox"
                        onChange={() => setAccepted(!accepted)}
                        />   
                    <span style={{paddingLeft: "10px"}}>Ich habe &nbsp;
                    <span style={{color: "#0d6efd", textDecoration: "underline"}} onClick={showDownloadModal}>Maklervertrag/-vollmacht</span>&nbsp;
                      und die <span style={{color: "#0d6efd", textDecoration: "underline"}} onClick={showDownloadModal}>Erstinformationen</span> gelesen und akzeptiere die Verträge und Bestimmungen.</span>
                </label>

                <br />
                <br />
                <br />


              <div className="pointer" onClick={handleAcceptance}>
                <VINButton text={"Absenden"} />
              </div>

      
                {/*<div style={{display: "flex", justifyContent: "center"}}>
                  <Button
                    style={{ background: "#520da7", padding: "15px", width: "auto", color: "white", fontWeight: "bold", borderRadius: "8px", cursor: "pointer" }}
                    onClick={handleAcceptance}
                  >Absenden</Button>
                </div>*/}

                {submittedOK && <p className="text-center fw-bold" style={{marginTop: "10px", color: "#1f8139"}}>Du hast das Formular erfolgreich an uns übermittelt.</p>}          


          </Col>
        </Row>


        <Row style={{justifyContent: "center", marginBottom: "80px", marginTop: "50px"}} >
          {/* Dokumenten Liste */}
          <Col
              xs={12} md={12}
              className="text-center"
            >
              <h4 className="text-center" style={{marginBottom: "20px"}}>Meine übermittelten Gesellschaften</h4>
              {(meineGesellschaften.length === 0) && <p style={{marginTop: "10px"}}>Du hast noch keine Gesellschaften übermittelt.</p>}
              {(meineGesellschaften.length >= 1) && meineGesellschaften.map((gesellschaft) => 
                  <CardGesellschaften key={gesellschaft.ID} versicherung={gesellschaft.Versicherung} übermittelt={gesellschaft.Übermittelt}  />  
              )} 

            </Col>
            
        </Row>


        <br />
        <br />
        <br />

        <Modal show={showModal} onHide={() => setShowModal(!showModal)}>
        <Modal.Header closeButton>
          <Modal.Title>Download Verträge</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <p style={{marginTop:"0px"}} className="fw-bold">
                Maklervertrag/-vollmacht
              </p>
              <div style={{}}>
                <div style={{cursor: "pointer"}} onClick={() => downloadPDF("pdf/Maklervertrag+Vollmacht_VIN1.pdf", "Maklervertrag+Vollmacht_VIN1.pdf")}>
                  <span style={{fontSize: "16px", textDecoration: "underline" }}>Download Maklervertrag/-vollmacht (PDF)</span>
                  <FontAwesomeIcon icon={faDownload} size="lg" style={{marginLeft: "10px"}} />
                </div>
              </div>


              <p style={{marginTop:"40px"}} className="fw-bold">
                Erstinformationen
              </p>
              <div style={{}}>
                <div style={{cursor: "pointer"}} onClick={() => downloadPDF("pdf/Erstinformationen_VIN1.pdf", "Erstinformationen_VIN1.pdf")}>
                  <span style={{fontSize: "16px", textDecoration: "underline" }}>Download Erstinformationen (PDF)</span>
                  <FontAwesomeIcon icon={faDownload} size="lg" style={{marginLeft: "10px"}} />
                </div>
              </div>
        </Modal.Body>
        <Modal.Footer>
          <p
            style={{ fontSize: "100%", display: "flex", justifyContent: "flex-end", width: "auto !important", background: "#520da7", padding: "15px", color: "white", fontWeight: "bold", borderRadius: "8px", cursor: "pointer" }}
            onClick={() => setShowModal(!showModal)}
            >
            Schließen
          </p>
        </Modal.Footer>
      </Modal>

      </Container>

      {spinner === "asfddfg" &&
        <Spinner
          message={"Bitte warten. Daten werden verarbeitet."}
        />
      }

    </>
  )
}

export default BestaendeUebertragen
