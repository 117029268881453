import React, {
    useState,
    useEffect,
    useContext,
    useRef,
  } from "react";
  
  import { useNavigate, useLocation } from 'react-router-dom';
  

  //import UserContext from "../store/user-context";
  
  import Header from '../Header/Header'
  
  import Card from "../Card/Card";
  
  import WebViewModal from "../Bausteine/WebViewModal/WebViewModal";
  
  import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
  import { faCirclePlus, faDownload } from "@fortawesome/free-solid-svg-icons";
  
  import CardSub from "../Card/CardSub";

  import style from "./VIN1_Partnerseite.module.css";
  
  import { Container, Row, Col, Button, Modal } from 'react-bootstrap';
  
  import SelectInput from "../Bausteine/SelectInput/SelectInput";  
  import Swal from "sweetalert2";
  
  // Optionen
  import Dokumente from "./VIN_Bausteine/Dokumente";
  import DateienSenden from "./VIN_Bausteine/DateienSenden";
  //import PartnerNews from "./VIN_Bausteine/PartnerNews";
  import BestaendeUebertragen from "./VIN_Bausteine/BestaendeUebertragen";


  function VIN1_Partnerseite() {

    const ColorTheme = 'var(--primary-color)';
  
    const navigate = useNavigate();

    const categorys = ["Dateien senden", "Dokumente", "Verträge zusammenführen"]; //, "VIN1-News"]; 
    const [selectedCat, setSelectedCat] = useState("");

    const handleQuickLink = (changedCategory) => {
      setSelectedCat(changedCategory);
      window.scrollTo({
        top: 0,
        behavior: "instant"
      });
    }


    //Subnavigation
    const location = useLocation();
    useEffect(() => {
      if(location.state) {
        if(location.state.category){
          setSelectedCat(location.state.category);
        }
      }
    
      if(location.state?.navigateBack == "/partner/vin1") {
        //zurück nach Home oder damalige Aktion...
      }

    }, [location.state])

    useEffect(() => {
      console.log("state");
      console.log(location.state);
    }, [])



    const [newsletter, setNewsletter] = useState([]);


  
    const [showModal, setShowModal] = useState(false);
  

    useEffect(() => {
    
        if(selectedCat == "News"){
            //axios.get
        }

    }, [setSelectedCat])
  
  
  
    return (
      <>
        <Header />

          <Container style={{maxWidth: "800px"}}>
            <Row style={{justifyContent: "center"}}>
                <Col style={{textAlign: "center"}}>
                    {/*<img src={require("../../img/VIN1_Logo_Kreis.png")} alt="" style={{maxWidth: "160px", margin: "30px 0px"}} />*/}
                    {/*<p className="text-center">In diesem Bereich findest du alle Aktionen die VIN1 bereitstellt.</p>*/}
                </Col>
            </Row>
          </Container>


          <Container>
  

            {selectedCat == "Dateien senden" && <DateienSenden />}
            {selectedCat == "Dokumente" && <Dokumente />} 
            {selectedCat == "Verträge zusammenführen" && <BestaendeUebertragen />}
            {/*selectedCat == "VIN1-News" && <PartnerNews />*/}


            <Container style={{maxWidth: "fit-content"}}>

              <Row className="justify-content-center" style={{marginBottom: "160px"}}>
              
                <p className="text-center">Du willst eine andere Aktion von VIN1 nutzen?
                  <br />Klicke auf eine der nachfolgenden Kacheln.
                </p>

                {categorys.map((kategorie) =>
                    <Col
                      key={kategorie}
                      className="d-flex justify-content-center"
                      style={{padding: "20px", maxWidth: "fit-content"}}
                      onClick={() => handleQuickLink(kategorie)}
                    >
                        {selectedCat === kategorie &&
                          <p style={{maxWidth: "fit-content"}} className="active">{kategorie}</p>
                        }
                        {selectedCat !== kategorie &&
                          <p style={{maxWidth: "fit-content"}}>{kategorie}</p>
                        }
                      
                    </Col>
                  )}
                </Row>
              </Container>

                  {/*<Col
                      key={kategorie}
                      xs={6} md={3}
                      style={{marginTop: "20px"}}
                      onClick={() => setSelectedCat(kategorie)}
                  >
                      {selectedCat === kategorie &&
                          <CardSub key={kategorie} title={kategorie} selected={1} color={ColorTheme} />
                      }
                      {selectedCat !== kategorie &&
                          <CardSub key={kategorie} title={kategorie} />
                      }
                  </Col>
                )
                </Row>*/}
  

  
  
  
          <Modal show={showModal} onHide={() => setShowModal(!showModal)}>
            <Modal.Header closeButton>
              <Modal.Title>Connecte dich mit einem Partner</Modal.Title>
            </Modal.Header>
            <Modal.Body>

            </Modal.Body>
            <Modal.Footer>
              <p
                style={{ fontSize: "100%", display: "flex", justifyContent: "flex-end", width: "auto !important", background: "#520da7", padding: "15px", color: "white", fontWeight: "bold", borderRadius: "8px", cursor: "pointer" }}
                onClick={() => setShowModal(!showModal)}
                >
                Schließen
              </p>
              <p
                style={{ fontSize: "100%", display: "flex", justifyContent: "flex-end", width: "auto !important", background: "#520da7", padding: "15px", color: "white", fontWeight: "bold", borderRadius: "8px", cursor: "pointer" }}
                onClick={() => setShowModal(!showModal)}
                >
                Connecten
              </p>
            </Modal.Footer>
          </Modal>
  
  
  
      </Container>
      </>
    )
  }
  
  export default VIN1_Partnerseite;