import React, { Fragment, useState } from 'react'
import { useNavigate } from 'react-router-dom';

import WebViewModal from "../Bausteine/WebViewModal/WebViewModal";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAlignJustify, faChevronDown, faChevronUp, faCircleCheck } from "@fortawesome/free-solid-svg-icons"

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';


import style from "./GewinnspielCard.module.css"

function GewinnspielCard(props) {

    const [weiterlesen, setWeiterlesen] = useState([]);

    const [showModal, setShowModal] = useState(false);
    const [modalUrl, setModalUrl] = useState(false);

    const handlePDF = (url) => {
        window.open(url, 'noopener nofollow');
    }

    const handleExternalURL = (url) => {

        setShowModal(false);
        window.scrollTo({
            top: 0,
            behavior: "instant"
          });
        setModalUrl(url);
        setShowModal(true);
      }
    

  function downloadPDF(base64pdf) {
    const linkSource = base64pdf;
    const downloadLink = document.createElement("a");
    const fileName = "VIN1-Gewinnspiel.pdf";

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  return (

    <Row className={style.cardContainerNews} style={{marginTop: "20px"}}>
        <Col xs={12} md={2} className={style.newsImageContainer}>
            <img className={style.newsImage} src={props.data.image} alt="" />
        </Col>
        <Col xs={12} md={10} style={{marginTop: "10px", paddingLeft: "16px", paddingRight: "16px"}}>
       
            <div className={style.textContainer}>
                <span style={{color: "#808080", marginBottom: "8px"}}>Aktion endet: {new Date(props.data.enddatum).toLocaleString("de-DE")}</span>
                <h5 className="fw-bold">{props.data.title}</h5>

                {props.data.text.length > 500 && !weiterlesen.includes(props.data.id) && 
                    <Fragment>
                        {<p dangerouslySetInnerHTML={{__html: props.data.text.substring(0,400)+" ..."}}></p>}
                        <div className={style.textBtn} onClick={() => setWeiterlesen([...weiterlesen, props.data.id])}>
                            <FontAwesomeIcon className={style.icon} icon={faChevronDown} size="lg" /><span style={{paddingLeft: "10px"}}>Weiterlesen</span>
                        </div>
                    </Fragment>
                }
                {props.data.text.length > 300 && weiterlesen.includes(props.data.id) && 
                    <Fragment>
                     <>{"Dies ist ein Test <br /> mit einer neuen Zeile... <br /> Lol"}</>
                        <div className={style.textBtn} onClick={() => setWeiterlesen([...weiterlesen.filter((item) => item !== props.data.id)])} >
                            <FontAwesomeIcon className={style.icon} icon={faChevronUp} size="lg" /><span style={{paddingLeft: "10px"}}>Einklappen</span>
                        </div>
                    </Fragment>
                }
                {props.data.text.length <= 300 && <p dangerouslySetInnerHTML={{__html: props.data.text}}></p>}
                
            </div>
        </Col>
        <Col xs={12} md={12} className={props.data.teilgenommen ? style.btnContainerCenter : style.btnContainer}>
            {props.data.pdf && <Button className="fw-bold" style={{background: "rgb(83, 178, 83)", border: "none", marginRight: "10px"}} onClick={() => downloadPDF(props.data.pdf)}>Info PDF</Button>}
            {props.data.url && <Button className="fw-bold" style={{background: "", border: "none", marginRight: "10px"}} onClick={() => handlePDF(props.data.url) }>Teilnahmebedingungen</Button>}         
            {props.data.teilgenommen === 0 && <Button className="fw-bold" style={{background: "#520da7"}} onClick={() => { props.handleGewinnspiel(props.data.id)}}>Jetzt teilnehmen</Button>}
            {props.data.teilgenommen === 1 &&
                <>
                    <FontAwesomeIcon style={{color: "green", paddingRight: "8px"}} icon={faCircleCheck} size="lg" />
                    <span>Du hast erfolgreich teilgenommen.</span>
                </>}         
        </Col>

        {showModal && (
          <WebViewModal
            url={modalUrl}
            hideModal={setShowModal}
          />
        )}
        
    </Row>
  )
}

export default GewinnspielCard