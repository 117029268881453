import React, { useState } from 'react'
import Header from '../../Header/Header'
import { useNavigate } from 'react-router-dom';

import WebViewModal from "../../Bausteine/WebViewModal/WebViewModal";

import { Container, Row, Col } from 'react-bootstrap';

import style from './Versicherungen.module.css'

function Versicherungen() {

  const ColorTheme = "#87BAF6";

  let navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);
  const [modalUrl, setModalUrl] = useState(false);

  const handleExternalURL = (url) => {

    setShowModal(false);
    window.scrollTo({
      top: 0,
      behavior: "instant"
    });
    setModalUrl(url);
    setShowModal(true);
  }

  return (
    <>
      <Header />
      <Container style={{maxWidth: "800px"}}>
        <Row style={{justifyContent: "center"}}>
          <Col style={{textAlign: "center"}}>
            <h1 style={{color: ColorTheme}}>Übersicht Versicherungen</h1>
          </Col>
        </Row>

        <br />
        <br />

        <Row className={style.Row}>
          <Col xs={{span: 12, order: 1}} md={{span: 5, order: 1}} style={{textAlign: 'center'}}>
            <img src={require("../../../img/Sachversicherung.png")} alt="" className={style.ColImg} />
          </Col>
          <Col xs={{span: 12, order: 2}} md={{span: 5, order: 1}} className={[style.textCard, "shadow p-3 bg-body"]}>
            <h5 className="text-center" style={{color: ColorTheme}}>Handy & Reise</h5>
            <p className="text-center">
              Handy kaputt? Die lang geplante Reise muss doch storniert werden? Im Auslandsurlaub krank geworden? Unverhofft kommt oft! Lieber vorher günstig absichern statt später teuer blechen. 
            </p>
            {/*<button className={style.Btn} onClick={() => handleExternalURL("https://vin1.eu/versicherungen/")}>Mehr Informationen &gt;</button>*/}
            <button className={style.Btn} onClick={() => navigate("/beratung", { state: { category: "Versicherung", subcategor: "Sach"}})}>Jetzt beraten lassen &gt;</button>  
          </Col>
      
        </Row>



        <br />
        <br />


        <Row className={style.Row}>
          <Col xs={{span: 12, order: 1}} md={{span: 5, order: 2}} style={{textAlign: 'center'}}>
            <img src={require("../../../img/HaftRecht.png")} alt="" className={style.ColImg} />
          </Col>
            <Col xs={{span: 12, order: 2}} md={{span: 5, order: 1}} className={[style.textCard, "shadow p-3 bg-body"]}>
            <h5 className="text-center" style={{color: ColorTheme}}>Haft & Recht</h5>
            <p className="text-center">
              Privathaftpflicht, Diensthaftpflicht, Rechtsschutzversicherung, Sofortige Rechtshilfe und mehr. Wir sorgen dafür, dass du dein gutes Recht auch durchsetzen kannst. 
            </p>
            {/*<button className={style.Btn} onClick={() => handleExternalURL("https://vin1.eu/versicherungen/")}>Mehr Informationen &gt;</button>*/}
            <button className={style.Btn} onClick={() => navigate("/beratung", { state: { category: "Versicherung", subcategor: "Leben"}})}>Jetzt beraten lassen &gt;</button>  
          </Col>
        </Row>


        <br />
        <br />


        <Row className={style.Row}>
          <Col xs={{span: 12, order: 1}} md={{span: 5, order: 2}} style={{textAlign: 'center'}}>
            <img src={require("../../../img/RisikoVorsorge.png")} alt="" className={style.ColImg} />
          </Col>
            <Col xs={{span: 12, order: 2}} md={{span: 5, order: 1}} className={[style.textCard, "shadow p-3 bg-body"]}>
            <h5 className="text-center" style={{color: ColorTheme}}>Risiko & Vorsorge</h5>
            <p className="text-center">
              Wir beraten dich wie du dich und deine Familie vor finanziellen Folgen aus Unfällen in der Freizeit oder auf der Arbeit schützen kannst. 
            </p>
            {/*<button className={style.Btn} onClick={() => handleExternalURL("https://vin1.eu/versicherungen/")}>Mehr Informationen &gt;</button>*/}
            <button className={style.Btn} onClick={() => navigate("/beratung", { state: { category: "Versicherung", subcategor: "Leben"}})}>Jetzt beraten lassen &gt;</button>  
          </Col>
        </Row>

        <br />
        <br />

        <Row className={style.Row}>
        <Col xs={{span: 12, order: 1}} md={{span: 5, order: 1}} style={{textAlign: 'center'}}>
            <img src={require("../../../img/Mobil.png")} alt="" className={style.ColImg} />
          </Col>
          <Col xs={{span: 12, order: 2}} md={{span: 5, order: 1}} className={[style.textCard, "shadow p-3 bg-body"]}>
            <h5 className="text-center" style={{color: ColorTheme}}>Auto & Fahrzeuge</h5>
            <p className="text-center">
              Ob eine Versicherung mit günstigen Tarifen oder mal schnell eine eVB Nummer beantragen, VIN1 hilft dir mit optimaler und schneller Beratung. 
            </p>
            {/*<button className={style.Btn} onClick={() => handleExternalURL("https://vin1.eu/versicherungen/")}>Mehr Informationen &gt;</button>*/}
            <button className={style.Btn} onClick={() => navigate("/beratung", { state: { category: "Versicherung", subcategor: "KFZ"}})}>Jetzt beraten lassen &gt;</button>  
          </Col>
       
        </Row>

        <br />
        <br />

        <Row className={style.Row}>
          <Col xs={{span: 12, order: 1}} md={{span: 5, order: 2}} style={{textAlign: 'center'}}>
            <img src={require("../../../img/Gesundheit.png")} alt="" className={style.ColImg} />
          </Col>
            <Col xs={{span: 12, order: 2}} md={{span: 5, order: 1}} className={[style.textCard, "shadow p-3 bg-body"]}>
            <h5 className="text-center" style={{color: ColorTheme}}>Kranken</h5>
            <p className="text-center">
              Besser gesetzlich oder privat versichert? Oder reicht auch eine Krankenzusatzversicherung, wie Zahn-, Auge oder Heilpraktikerzusatzversicherung? Tausche dich jetzt mit uns aus und finde deinen passenden Tarif!
            </p>
            {/*<button className={style.Btn} onClick={() => handleExternalURL("https://vin1.eu/versicherungen/")}>Mehr Informationen &gt;</button>*/}
            <button className={style.Btn} onClick={() => navigate("/beratung", { state: { category: "Versicherung", subcategor: "Kranken"}})}>Jetzt beraten lassen &gt;</button>  
          </Col>
        </Row>

        <br />
        <br />

        <Row className={style.Row}>
          <Col xs={{span: 12, order: 1}} md={{span: 5, order: 1}} style={{textAlign: 'center'}}>
            <img src={require("../../../img/AlterPflege.png")} alt="" className={style.ColImg} />
          </Col>
          <Col xs={{span: 12, order: 2}} md={{span: 5, order: 1}} className={[style.textCard, "shadow p-3 bg-body"]}>
            <h5 className="text-center" style={{color: ColorTheme}}>Altersvorsorge & Pflege</h5>
            <p className="text-center">
              Mit einer Privat Rente, einer Pflegeversicherung oder einer geeigneten Lebensversicherung denkst du heute schon an Morgen – auch für deine Familie. Wir beraten dich, was du noch für deine Zukunft tun kannst. 
            </p>
            {/*<button className={style.Btn} onClick={() => handleExternalURL("https://vin1.eu/versicherungen/")}>Mehr Informationen &gt;</button>*/}
            <button className={style.Btn} onClick={() => navigate("/beratung", { state: { category: "Versicherung", subcategor: "Pflege"}})}>Jetzt beraten lassen &gt;</button>  
          </Col>
        </Row>

        <br />
        <br />


        <Row className={style.Row}>
          <Col xs={{span: 12, order: 1}} md={{span: 5, order: 2}} style={{textAlign: 'center'}}>
            <img src={require("../../../img/Tier.png")} alt="" className={style.ColImg} />
          </Col>
          <Col xs={{span: 12, order: 2}} md={{span: 5, order: 1}} className={[style.textCard, "shadow p-3 bg-body"]}>
            <h5 className="text-center" style={{color: ColorTheme}}>Tier</h5>
            <p className="text-center">
              Deine treuen Begleiter verdienen den besten Schutz. Ob Haftpflicht oder Krankenversicherung, VIN1 holt das Beste für deinen Liebling raus. 
            </p>
            {/*<button className={style.Btn} onClick={() => handleExternalURL("https://vin1.eu/versicherungen/")}>Mehr Informationen &gt;</button>*/}
            <button className={style.Btn} onClick={() => navigate("/beratung", { state: { category: "Versicherung", subcategor: "Tier"}})}>Jetzt beraten lassen &gt;</button>  
          </Col>
        </Row>

        <br />
        <br />

        <Row className={style.Row}>
          <Col xs={{span: 12, order: 1}} md={{span: 5, order: 1}} style={{textAlign: 'center'}}>
            <img src={require("../../../img/Immo.png")} alt="" className={style.ColImg} />
          </Col>
          <Col xs={{span: 12, order: 2}} md={{span: 5, order: 2}} className={[style.textCard, "shadow p-3 bg-body"]}>
            <h5 className="text-center" style={{color: ColorTheme}}>Haus & Wohnung</h5>
            <p className="text-center">
              Schütze deinen Besitz durch Sturm, Wasser, Feuer Diebstahl und mehr – auch auf Reisen! Hole dir jetzt optimale Vergleiche ein.
            </p>
            {/*<button className={style.Btn} onClick={() => handleExternalURL("https://vin1.eu/versicherungen/")}>Mehr Informationen &gt;</button>*/}
            <button className={style.Btn} onClick={() => navigate("/beratung", { state: { category: "Versicherung", subcategor: "Gewerbe"}})}>Jetzt beraten lassen &gt;</button>      
          </Col>
        </Row>

      
        <br />
        <br />

        <Row className={style.Row}>
          <Col xs={{span: 12, order: 1}} md={{span: 5, order: 2}} style={{textAlign: 'center'}}>
            <img src={require("../../../img/Gewerbe.png")} alt="" className={style.ColImg} />
          </Col>
          <Col xs={{span: 12, order: 2}} md={{span: 5, order: 1}} className={[style.textCard, "shadow p-3 bg-body"]}>
            <h5 className="text-center" style={{color: ColorTheme}}>Gewerbe</h5>
            <p className="text-center">
              Modernste Beratung für das Gewerbegeschäft. Speziell geschulte Firmenkundenbetreuer. Wir schaffen die beste Absicherung für dein Unternehmen.
            </p>
            {/*<button className={style.Btn} onClick={() => handleExternalURL("https://vin1.eu/versicherungen/")}>Mehr Informationen &gt;</button>*/}
            <button className={style.Btn} onClick={() => navigate("/beratung", { state: { category: "Versicherung", subcategor: "Gewerbe"}})}>Jetzt beraten lassen &gt;</button>      
          </Col>
        </Row>

      
        <br />
        <br />


        <Row className={style.Row}>
          <div className={style.BottomCard}>
            <h4 className="text-center" style={{width: "85%"}}>Vergleichen und optimieren</h4>
            {/*<p className="text-center" style={{width: "85%"}}>Unkomplizierter Vergleich für das beste Ergebnis!</p>*/}
            {/*<button className={style.BtnNormal} onClick={() => handleExternalURL("https://vin1.eu/versicherungen/")}>Hier mehr erfahren &gt;</button>*/}
            <button className={style.BtnNormal} style={{marginTop: "16px"}} onClick={() => navigate("/beratung", { state: { category: "Versicherung"}})}>Jetzt Kontakt aufnehmen &gt;</button>
          </div>
        </Row>

        <br />

        <Row style={{marginBottom:"80px"}}>
          <Col style={{textAlign:"center"}}>
            <button className={style.BtnNormal} onClick={() => navigate("/dokumente/versicherungen")}>Zu deiner Dokumentenablage &gt;</button>
          </Col>
        </Row>

        {showModal && <WebViewModal url={modalUrl} hideModal={setShowModal} />}

      </Container>
    </>
  )
}

export default Versicherungen