import React, { useContext, useState } from 'react'
import Header from '../Header/Header'

import CardUpload from "../Card/CardUpload";

import { Container, Row, Col, Button } from 'react-bootstrap';

import style from './Schaden.module.css'

//import Select, { createFilter } from 'react-select';
import Swal from 'sweetalert2';

/*
import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
  uploadTask,
} from "@firebase/storage";
import { auth, db, storage } from "../../firebase";
import { getAuth, signOut } from "firebase/auth";
import {
  collection,
  doc,
  addDoc,
  getDoc,
  setDoc,
  getDocs,
} from "firebase/firestore";
 */

import axios from 'axios';
import { makeID } from '../../util/makeID';
import VINButton from '../../VINButton';
import { UserContext } from '../../store/user-context';


function Schaden() {

  const { user } = useContext(UserContext);

  const [datenschutz, setDatenschutz] = useState(false);
  const [versicherungsscheinnummer, setVersicherungsscheinnummer] = useState("");
  const [beschreibung, setBeschreibung] = useState("");
  const [pickedFiles, setPickedFiles] = useState([]);


  const [formularWait, setFormularWait] = useState(false);
  const [schaden, setSchaden] = useState(false);

  function removeFileUploadHandler (id) {
    //console.log("remove: " + name);
    setPickedFiles(pickedFiles.filter(obj => obj.id !== id));
  }


  function handleFile(e) {

    //setPickedFiles( [...pickedFiles, e.target.files]);
    let data = [];

    Array.from(e.target.files).forEach(file => {

      data = [...data, {
          id: makeID(8),
          file: file,
          name: file.name,
          size: file.size,
          type: file.type
        }
      ]
    });

    setPickedFiles([...pickedFiles, ...data]);
  }


  const handleSubmit = () => {

    setFormularWait(true);

    if(user.kundennummer === undefined || user.kundennummer === "") {
      Swal.fire({
        title: "Kundennummer",
        html: "Damit du dieses Formular absenden kannst, benötigst du eine Kundennummer. Wenn du dich nicht gerade registriert hast, wende dich an den Support.",
        icon: "info",
      });
      setFormularWait(false);
      return;
    }

    if(versicherungsscheinnummer === "" || beschreibung === "" || pickedFiles.length === 0 || !datenschutz) {
      Swal.fire({
        title: "Versicherungsscheinnummer",
        html: "Fülle alle Felder aus, damit dein Antrag abgesendet werden kann.",
        icon: "info",
      });
      setFormularWait(false);
      return;
    }

    //configure axios header
    const headerConfig = { headers: { 'Content-Type': 'multipart/form-data' } };

    //Formular an uns übertragen
    var FilesData = new FormData();
    FilesData.append('kundennummer', user.kundennummer);
    FilesData.append('email', user.email);
    FilesData.append('versicherungsnummer', versicherungsscheinnummer );
    FilesData.append('beschreibung', beschreibung );

    let i = 0;
    pickedFiles.forEach(file => {
      FilesData.append("dateien["+i+"]", file.file);
      i = i+1;
    })

    axios({
      method: 'post',
      url: "https://api.mariusschulte.com/crm/schaden.php",
      data: FilesData,
      headerConfig
    })
    .then(() => {

      /*
      
      try {
        // Send Mail
        var bodyFormData2 = new FormData();
        bodyFormData2.append('kundennummer', user.kundennummer);
        bodyFormData2.append('email', user.email);
        bodyFormData2.append('templateId', 4655);
  
        axios({
            method: 'post',
            url: "https://api.mariusschulte.com/crm/sendMail.php",
            data: bodyFormData2
        });
        
  
        //console.log("Mail versendet CRM")
      } 
      catch(err) {
      
      }
      
  
      Swal.fire({
        title: "Erfolg",
        html: "Weitere Informationen haben wir dir per E-Mail zugesendet.",
        icon: "success",
      });
      */
      
      Swal.fire({
        title: "Erfolg",
        html: "Deine Daten wurden an uns übertragen. Wir kümmern uns um alles weitere.",
        icon: "success",
      });
      

      setVersicherungsscheinnummer("");
      setBeschreibung("");
      setPickedFiles([]);
      
      setFormularWait(false);

    })
    .catch((err) => {
      Swal.fire({
        title: "Formular",
        html: "Es ist ein Fehler aufgetreten. Bitte erneut versuchen.",
        icon: "error",
      });
      setFormularWait(false);
    });


/*
    try {

      // CSV File
      axios({
          method: 'post',
          url: "https://api.mariusschulte.com/csv-versicherungen/insertData.php",
          data: {
            vorname: user.vorname,
            nachname: user.nachname,
            email: user.email,
            telefon: user.telefon,
            geburtsdatum: user.geburtsdatum,
            data: dataArr
          }
      }). then(data => {
        //console.log(data);
      })
      .catch(error => {
        //console.log(error);
      });
    } 
    catch(err) {

    }
    */


    /*
    try {
      // Send Mail
      var bodyFormData2 = new FormData();
      bodyFormData2.append('kundennummer', user.kundennummer);
      bodyFormData2.append('email', user.email);
      bodyFormData2.append('templateId', 4643);

      axios({
          method: 'post',
          url: "https://api.mariusschulte.com/crm/sendMail.php",
          data: bodyFormData2
      });

      //console.log("Mail versendet CRM")
    } 
    catch(err) {
    
    }
    */
  
  
  }



  

  
  return (
    <>
      <Header />
      <Container style={{maxWidth: "800px"}}>
        
        <Row style={{justifyContent: "center"}}>
          <Col style={{textAlign: "center"}}>
            <img src={require("../../img/Schaden.png")} alt="" style={{maxWidth: "160px", margin: "40px 0px"}} />

                <h5>Du hast einen Schaden?!</h5>
                <p className="text-center">Kein Problem. Wir sind für dich da!</p>
                <br />
                <p className="text-center">Um dich bei deiner Schadensmeldung unterstützen zu können,<br />benötigen wir noch ein paar Infos von dir.</p>
              <br />
                <p className="text-center"><span className="fw-bold">1.</span><br />
                  Teile uns deine Versicherungsscheinnummer mit. Wir benötigen diese, zur Zuordnung deiner Versicherung.</p>


          </Col>
        </Row>

        <Row style={{justifyContent: "center"}}>
          <Col>
                  <div className={style.formInputCustom} style={{display: "flex", flexDirection: "column", justifyContent: "flex-start"}}>
                    <label htmlFor="versicherungsscheinnr" className="form-label">Versicherungsscheinnummer</label>
                    <input
                        type="text"
                        value={versicherungsscheinnummer}
                        id="versicherungsscheinnr"
                        name="versicherungsscheinnr"
                        placeholder="Gib hier deine Versicherungsscheinnummer ein"
                        autoComplete="off"
                        onChange={(e) => setVersicherungsscheinnummer(e.target.value.trim())}
                    />
                  </div>
     
                
                <br />

                  <p className="text-center"><span className="fw-bold">2.</span><br />
                  Beschreibe uns kurz, wie der Schaden entstanden ist</p>

                  <div className={style.formInputCustom} style={{display: "flex", flexDirection: "column", justifyContent: "flex-start"}}>
                    <label htmlFor="beschreibung" className="form-label">Schadensbeschreibung</label>
                    <textarea
                      style={{border: "none", margin: "0px 12px"}}
                      value={beschreibung}
                      placeholder={"Gib hier deine Schadensbeschreibung ein"}
                      cols={40}
                      rows={5}
                      onChange={(e) => setBeschreibung(e.target.value)}
                    />
                       
                    
                  </div>
           
                <br />

                <p className="text-center"><span className="fw-bold">3.</span><br />
                Lade Schadensbilder, Dokumente oder Rechnungen hoch.</p>


          </Col>
        </Row>
        <Row>
          <Col className="text-center">

            {pickedFiles.length === 0 && <p className="text-center">Du hast noch keine Dokumente ausgewählt.</p>}

            {pickedFiles.map(file => 
                <CardUpload key={file.id} data={file} removeFileUploadHandler={removeFileUploadHandler} />   
            )}
            {pickedFiles.length !== 0 && 
                <p className="text-center"> Füge weitere Bilder durch erneutes klicken auf den Button hinzu.</p>
            }


          <label
            htmlFor="fileUpload"
            className="filePicker"
          >
            Dokumente auswählen
            <input
              id="fileUpload"
              type="file"
              multiple
              accept="application/pdf, image/png, image/jpeg"
              style={{ display: "none" }}
              onChange={handleFile}
            />
          </label>

          </Col>
        </Row>

        <Row style={{marginTop: "40px"}}>
          <Col className="text-center">
      
          <label className="form-check-label" style={{marginTop: "20px"}}>
                    <input
                        id="cbTippgeber"
                        className="form-check-input"
                        type="checkbox"
                        onChange={() => setDatenschutz(!datenschutz)}
                        />   
                    <span style={{paddingLeft: "10px"}}>Ich stimme den <a href="/datenschutz" target="_blank">Datenschutzbedingungen</a> zu.</span>
                </label>

<br />
<br />

                {/*<div style={{display: "flex", justifyContent: "center"}}>
                  <Button
                    style={{ background: "#520da7", padding: "15px", width: "auto", color: "white", fontWeight: "bold", borderRadius: "8px", cursor: "pointer" }}
                    onClick={handleSubmit}
                  >Absenden</Button>
                </div>*/}

                <div className="pointer" onClick={handleSubmit}>
                    <VINButton text={"Absenden"} />
                </div>

                {formularWait && <p className="text-center fw-bold" style={{marginTop: "10px", color: "#1f8139"}}>Bitte warten bis das Formular verarbeitet ist.</p>}
                {schaden === "1" && <p className="text-center fw-bold" style={{marginTop: "10px", color: "#1f8139"}}>Du hast das Formular erfolgreich an uns übermittelt.</p>}

          </Col>
        </Row>


        <br />
        <br />
        <br />

      
      </Container>
    </>
  )
}

export default Schaden
