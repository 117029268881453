import React from 'react'
import {Row, Col} from 'react-bootstrap'
import Card from '../../Card/Card'

function Dokumente() {
    
  return (
    <Row className="mt-5 justify-content-center" style={{marginBottom: "160px"}} >

        <Col xs={12} md={12}>
            <p className="text-center">Hier findest du Dokumente wie z.B. Verträge oder Policen</p>
        </Col>

        <Col xs={6} md={4} style={{marginTop: "20px"}}>
            <Card url={"/dokumente/versicherungen"} img={"Versicherungen.png"} title={"Versicherungen"} />
        </Col>

        <Col xs={6} md={4} style={{marginTop: "20px"}}>
            <Card url={"/dokumente/kredite"} img={"Finanzen.png"} title={"Kredite"} />
        </Col>

        <Col xs={6} md={4} style={{marginTop: "20px"}}>
            <Card url={"/dokumente/invest"} img={"Invest.png"} title={"Finanzen & Invest"} />
        </Col>

        <Col xs={6} md={4} style={{marginTop: "20px"}}>
            <Card url={"/dokumente/energie"} img={"Energie.png"} title={"Energie"} />
        </Col>

        <Col xs={6} md={4} style={{marginTop: "20px"}}>
            <Card url={"/dokumente/immobilien"} img={"Immo.png"} title={"Immobilien"} />
        </Col>

    </Row>
        
  )
}

export default Dokumente