import React, { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import Header from "../Header/Header";
import Spinner from "../Bausteine/Spinner/Spinner";

import CardSub from "../Card/CardSub";

import { Container, Row, Col, Button, Modal } from "react-bootstrap";

import style from "./Beratung.module.css";

import axios from 'axios';
import Swal from "sweetalert2";

import { UserContext } from "../../store/user-context";

import VINButton from "../../VINButton";
import TarifTaxiCard from "./TarifTaxiCard";
import { AuthContext } from "../../store/auth-context";



function Beratung() {

  const location = useLocation();

  const ColorTheme = "#0B3499";

  const { user } = useContext(UserContext);
  const { currentUser } = useContext(AuthContext);

  const [uhrzeiten] = useState(["08:00-13:00 Uhr", "13:00-17:00 Uhr", "17:00-19:00 Uhr"]);

  const [category, setCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [subSubCategory, setSubSubCategory] = useState("");


  const [beschreibung, setBeschreibung] = useState("");
  const [erreichbar, setErreichbar] = useState("");

  const [morgens, setMorgens] = useState(false);
  const [mittags, setMittags] = useState(false);
  const [abends, setAbends] = useState(false);

  const [datenschutz, setDatenschutz] = useState(false);

  const [spinner, setSpinner] = useState(false);

  
  //Navigation aus anderen Bereichen direkt vorauswählen
  useEffect(() => {

      if(location.state?.category) {
        setCategory(location.state.category);

        if(location.state?.subcategory) {
          setSubCategory(location.state.subcategory);
        }
      }
  },[location.state])
  

  
  useEffect(() => {

    if(morgens && !mittags && !abends)
      setErreichbar("morgens ("+uhrzeiten[0]+")");
    else if(!morgens && mittags && !abends)
      setErreichbar("mittags ("+uhrzeiten[1]+")");
    else if(!morgens && !mittags && abends)
      setErreichbar("abends ("+uhrzeiten[2]+")");
    else if(morgens && mittags && !abends)
      setErreichbar("morgens ("+uhrzeiten[0]+"), mittags ("+uhrzeiten[1]+")");
    else if(morgens && !mittags && abends)
      setErreichbar("morgens ("+uhrzeiten[0]+"), abends ("+uhrzeiten[2]+")");
    else if(!morgens && mittags && abends)
      setErreichbar("mittags ("+uhrzeiten[1]+"), abends ("+uhrzeiten[2]+")");
    else if(morgens && mittags && abends)
      setErreichbar("ganztägig");

  }, [morgens, mittags, abends, uhrzeiten])


  const handleSubmit = async () => {

    if (category === "" || subCategory === "" || beschreibung === "" || datenschutz === false) {
      Swal.fire({
        title: "Pflichtfelder",
        html: "Bitte fülle zunächst alle Felder aus und sende das Formular erneut ab.",
        icon: "info",
      });
      return;
    }

    if (user.kundennummer === undefined || user.kundennummer === "") {
      Swal.fire({
        title: "Kundennummer",
        html: "Damit du dieses Formular absenden kannst, benötigst du eine Kundennummer. Wenn du dich nicht gerade registriert hast, wende dich an den Support.",
        icon: "info",
      });
      return;
    }


    setSpinner(true);


    try {

      //Create Task CRM FERTIG MACHEN IM BACKEND!!!
      let beratung = await axios({
        method: 'post',
        headers: {
          'Authorization': 'Bearer ' + currentUser.uid
        },
        url: process.env.REACT_APP_BACKEND+"/my-vin1/beratung",
        data: {
          kdnr: user.kundennummer,
          category: category,
          subcategory: subCategory,
          subsubcategory: subSubCategory,
          beschreibung: beschreibung,
          erreichbar: erreichbar
        }
      })
      
      if(beratung.status == 200) {
        setSpinner(false);
        Swal.fire({
          title: "Erfolg",
          html: "Deine Daten wurden erfolgreich an uns übermittelt.<br />Dein VIN1 Berater wird Kontakt zu dir aufnehmen.",
          icon: "success",
        });

      }
    } 
    catch (error) {
      setSpinner(false);
      console.log(error);

      Swal.fire({
        title: "Fehler",
        html: "Es ist ein Fehler bei der Übertragung aufgetreten. Versuche es später erneut.",
        icon: "error"
      });
    }

    //Clean Felder
  
    setSpinner(false);
    
    setCategory("");
    setSubCategory("");
    setSubSubCategory("");
    setBeschreibung("");

    setMorgens(false);
    setMittags(false);
    setAbends(false);

    setErreichbar("");

    setDatenschutz(false);


  }; //handleSubmit Ende


  const kategorieArray = [
    // ================= VERSICHERUNG ===================
    {
        Oberkategorie: "Versicherung",
        Unterkategorie: "Auto & Fahrzeuge"
    },
    {
        Oberkategorie: "Versicherung",
        Unterkategorie: "Haus & Wohnung"
    },
    {
        Oberkategorie: "Versicherung",
        Unterkategorie: "Tier"
    },
    {
        Oberkategorie: "Versicherung",
        Unterkategorie: "Haft & Recht"
    },
    {
        Oberkategorie: "Versicherung",
        Unterkategorie: "Kranken"
    },
    {
        Oberkategorie: "Versicherung",
        Unterkategorie: "Risiko & Vorsorge"
    },
    {
        Oberkategorie: "Versicherung",
        Unterkategorie: "Altersvorsorge & Pflege"
    },
    {
        Oberkategorie: "Versicherung",
        Unterkategorie: "Handy & Reise"
    },
    {
      Oberkategorie: "Versicherung",
      Unterkategorie: "Gewerbe"
    },
    {
      Oberkategorie: "Versicherung",
      Unterkategorie: "Allgemeine Beratung"
    },
    // ================= Kredit ======================
    {
        Oberkategorie: "Kredit",
        Unterkategorie: "Kredit"
    },
    {
        Oberkategorie: "Kredit",
        Unterkategorie: "Baufinanzierung"
    },
    {
        Oberkategorie: "Kredit",
        Unterkategorie: "Karten & Konten"
    },
    {
        Oberkategorie: "Kredit",
        Unterkategorie: "Geldanlage"
    },
    {
      Oberkategorie: "Kredit",
      Unterkategorie: "Allgemeine Beratung"
    },
    // =================== Energie ====================
    {
        Oberkategorie: "Energie",
        Unterkategorie: "Strom"
    },
    {
        Oberkategorie: "Energie",
        Unterkategorie: "Gas"
    },
    {
      Oberkategorie: "Energie",
      Unterkategorie: "Solar / PV"
    },
    {
      Oberkategorie: "Energie",
      Unterkategorie: "Allgemeine Beratung"
    },
     // =================== Immo ====================
     {
        Oberkategorie: "Immo",
        Unterkategorie: "Verkaufen"
    },
    {
        Oberkategorie: "Immo",
        Unterkategorie: "Kaufen"
    },
    {
        Oberkategorie: "Immo",
        Unterkategorie: "Vermietung"
    },
    {
        Oberkategorie: "Immo",
        Unterkategorie: "Finanzierung"
    },
    {
        Oberkategorie: "Immo",
        Unterkategorie: "Marketing"
    },
    {
        Oberkategorie: "Immo",
        Unterkategorie: "Architekt"
    },
    {
      Oberkategorie: "Immo",
      Unterkategorie: "Immobilien-Bewertung"
    },
    {
      Oberkategorie: "Immo",
      Unterkategorie: "Allgemeine Beratung"
    },
    // =================== Invest ====================
    {
        Oberkategorie: "Invest",
        Unterkategorie: "Gold"
    },
    {
        Oberkategorie: "Invest",
        Unterkategorie: "Silber"
    },
    {
      Oberkategorie: "Invest",
      Unterkategorie: "Technische Metalle"
    },
    {
      Oberkategorie: "Invest",
      Unterkategorie: "Seltene Erden"
    },
    {
        Oberkategorie: "Invest",
        Unterkategorie: "Krypto"
    },
    {
        Oberkategorie: "Invest",
        Unterkategorie: "Immobilien"
    },
    {
      Oberkategorie: "Invest",
      Unterkategorie: "Sammlerstücke"
    },
    {
        Oberkategorie: "Invest",
        Unterkategorie: "Asset Protection"
    },
    {
      Oberkategorie: "Invest",
      Unterkategorie: "Allgemeine Beratung"
    },
    // =================== Partner ====================
    {
      Oberkategorie: "Partner",
      Unterkategorie: "Exklusiv Makler"
    },
    {
        Oberkategorie: "Partner",
        Unterkategorie: "Tippgeber / Influencer"
    },
    {
        Oberkategorie: "Partner",
        Unterkategorie: "Female Business"
    },
    {
        Oberkategorie: "Partner",
        Unterkategorie: "Schülerjob"
    },
    {
      Oberkategorie: "Partner",
      Unterkategorie: "Allgemeine Beratung"
    },
]

// ==============================================================================
// ==============================================================================
// ==============================================================================

const subKategorieArray = [
    // ================= VERSICHERUNG ===================
    {
        kategorie: "Auto & Fahrzeuge",
        title: "Welche Art Fahrzeug möchtest du versichern?",
        cards: [
            "KfZ / Sofort eVB",
            "Motorrad",
            "Moped / E-Scooter",
            "Schutzbrief / Automobilclub",
            "Fahrrad"
        ]
    },
    {
        kategorie: "Immobilien",
        title: "Mit welcher Art Versicherung können wir dir weiterhelfen?",
        cards: [
            "Hausrat",
            "Wohngebäude",
            "Nebau",
            "Elementar",
            "Mietkaution",
        ]
    },
    {
        kategorie: "Tierversicherung",
        title: "Mit welcher Art Versicherung sollen wir dein Tier absichern?",
        cards: [
            "Hundehaftpflicht",
            "Hundekranken",
            "Katzenkranken",
            "Pferdehaftpflicht",
            "Pferde OP",
        ]
    },
    {
        kategorie: "Haft & Recht",
        title: "Mit welcher Art Versicherung können wir dir weiterhelfen?",
        cards: [
            "Privathaftpflicht",
            "Diensthaftpflicht",
            "Rechtschutz",
            "Sofortige Rechtshilfe",
        ]
    },
    {
        kategorie: "Kranken",
        title: " Mit welcher Art Versicherung können wir dir weiterhelfen?",
        cards: [
            "Private Kranken",
            "Gesetzliche Krankenkassen",
            "Zahnzusatz",
            "Krankenhauszusatz",
            "Heilpraktiker & Brille"           
        ]
    },
    {
        kategorie: "Risiko & Vorsorge",
        title: "Mit welcher Art Versicherung können wir dir weiterhelfen?",
        cards: [
            "Risikoleben",
            "Unfall",
            "Berufsunfähigkeit",
            "Sterbegeld"
        ]
    },
    {
        kategorie: "Altersvorsorge & Pflege",
        title: "Mit welcher Art Versicherung können wir dir weiterhelfen?",
        cards: [
            "Rente",
            "Sofort-Rente",
            "Rürüp-Rente",
            "Pflege"
        ]
    },
    {
        kategorie: "Handy & Reise",
        title: "Mit welcher Art Versicherung können wir dir weiterhelfen?",
        cards: [
            "Handy",
            "Reise",
            "Auslandskranken",
        ]
    },


   

]


function handleCategory(category) {
    setCategory(category);
    setSubCategory("");
    setSubSubCategory("");
}

function handleSubCategory(subcategory) {
    alert("handle...")
    setSubCategory(subcategory);
    setSubSubCategory("");
}



  return (
    <>
      <Header />
      <Container style={{ maxWidth: "800px" }}>
        <Row style={{ justifyContent: "center" }}>
          <Col style={{ textAlign: "center" }}>
            <img
              src={require("../../img/Berater.png")}
              alt=""
              style={{ maxWidth: "160px", margin: "40px 0px" }}
            />

            <h5>Beratung</h5>
            <h6 style={{ fontSize: "110%" }}>JETZT BERATEN LASSEN</h6>


            {/*
            <div class="form-check">
              <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked />
              <label class="form-check-label" for="flexRadioDefault1">
                Online Beratung
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" />
              <label class="form-check-label" for="flexRadioDefault2">
                Telefon Beratung
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault3" />
              <label class="form-check-label" for="flexRadioDefault3">
                Vor Ort Beratung
              </label>
            </div>
          */}

            {/*
            <p className="mt-5">Rufe uns an unter: <a href="tel:+49(0)2596/614 8181">+49(0)2596/614 8181</a><br />
            oder erhalte einen Rückruf. Schildere uns dazu kurz dein Anliegen:</p>
            */}

            <p className="text-center mt-5">
              {/*<span className="fw-bold">1.</span><br />*/}
              Wähle den Bereich (anklicken) deiner gewünschten Beratung.
            </p>
          </Col>
        </Row>


        <Row style={{ justifyContent: "center" }}>

          <TarifTaxiCard
              img={"Versicherungen.png"}
              title={"Versicherung"}
              category={"Versicherung"}
              selectedCategory={category}
              handleCategory={handleCategory}
              ColorTheme={ColorTheme}
          />

          <TarifTaxiCard
              img={"Finanzen.png"}
              title={"Kredit"}
              category={"Kredit"}
              selectedCategory={category}
              handleCategory={handleCategory}
              ColorTheme={ColorTheme}
          />

          <TarifTaxiCard
              img={"Invest.png"}
              title={"Finanzen & Invest"}
              category={"Invest"}
              selectedCategory={category}
              handleCategory={handleCategory}
              ColorTheme={ColorTheme}
          />



        </Row>

        <Row style={{ justifyContent: "center" }}>
          <TarifTaxiCard
            img={"Energie.png"}
            title={"Energie"}
            category={"Energie"}
            selectedCategory={category}
            handleCategory={handleCategory}
            ColorTheme={ColorTheme}
          />

          <TarifTaxiCard
              img={"Immo.png"}
              title={"Immobilien"}
              category={"Immo"}
              selectedCategory={category}
              handleCategory={handleCategory}
              ColorTheme={ColorTheme}
          />

          <TarifTaxiCard
              img={"Exklusiv_Makler.png"}
              title={"Partner"}
              category={"Partner"}
              selectedCategory={category}
              handleCategory={handleCategory}
              ColorTheme={ColorTheme}
          />
        </Row>





        <Row className="justify-content-center mt-3">
            <Col
                xs={12}
                style={{marginTop: "20px", textAlign: "center"}}
            >
              {category == "Versicherung" &&
                  <p>Mit welcher Art Versicherung können wir dir weiterhelfen?</p>
              }
              {category == "Kredit" &&
                  <p>Mit welcher Art Versicherung können wir dir weiterhelfen?</p>
              }
               {category == "Invest" &&
                  <p>Mit welcher Art Invest können wir dir weiterhelfen?</p>
              }
               {category == "Energie" &&
                  <p>Mit welchem Energie-Thema können wir dir weiterhelfen?</p>
              }
              {category == "Immo" &&
                  <p>Mit welchem Immobilien-Thema können wir dir weiterhelfen?</p>
              }
              {category == "Partner" &&
                  <p>Für welches Partner-Programm willst du mehr Informationen bekommen?</p>
              }
            </Col>

          {category && kategorieArray.filter(key => key.Oberkategorie === category).map(kategorie =>
            
            <Col
                key={kategorie.Unterkategorie}
                xs={12} md={3}
                className=""
                style={{marginBottom: "20px"}}
                onClick={() => (setSubCategory(kategorie.Unterkategorie))}
            >

                {(subCategory === kategorie.Unterkategorie) && 
                    <CardSub onClick={handleSubCategory} key={kategorie.Unterkategorie} title={kategorie.Unterkategorie} selected={1} color={ColorTheme} />
                }
                {(subCategory !== kategorie.Unterkategorie) && 
                    <CardSub onClick={handleSubCategory} key={kategorie.Unterkategorie} title={kategorie.Unterkategorie} />
                }

            </Col>
          )}
        </Row>




        
        {category && subCategory &&
          <>
            <Row style={{ justifyContent: "center" }}>
              <Col style={{ textAlign: "center" }}>

              <br />

              <p className="text-center">
                {/*<span className="fw-bold">2.</span><br />*/}
                Beschreibe uns kurz dein Anliegen.
              </p>

              <div className={style.formInputCustom} style={{display: "flex", flexDirection: "column", justifyContent: "flex-start", textAlign: "left"}}>
                <label htmlFor="beschreibung" className="form-label">Dein Anliegen</label>
                <textarea
                  style={{border: "none", margin: "0px 12px"}}
                  value={beschreibung}
                  placeholder={"Beschreibe uns kurz dein Anliegen"}
                  cols={40}
                  rows={3}
                  onChange={(e) => setBeschreibung(e.target.value)}
                />
                
                
              </div>

              </Col>
            </Row>

            <br />

            <Row style={{ justifyContent: "center" }}>
              <Col style={{ textAlign: "center" }}>

                <p className="text-center">
                  {/*<span className="fw-bold">3.</span><br />*/}
                  Wann bist du für deinen Berater am besten erreichbar?
                </p>
              
              <div style={{display: "flex", flexDirection: "column", justifyContent: "flex-start"}}>

                <label className="form-check-label" style={{ marginTop: "20px" }}>
                  <input
                    id="cbMorgens"
                    className="form-check-input"
                    type="checkbox"
                    checked={morgens}
                    onChange={(e) => setMorgens(e.currentTarget.checked)}
                  />
                  <span style={{ paddingLeft: "10px" }}>
                  {uhrzeiten[0]}
                  </span>
                </label>

                <label className="form-check-label" style={{ marginTop: "20px" }}>
                  <input
                    id="cbMittags"
                    className="form-check-input"
                    type="checkbox"
                    checked={mittags}
                    onChange={(e) => setMittags(e.currentTarget.checked)}
                  />
                  <span style={{ paddingLeft: "10px" }}>
                    {uhrzeiten[1]}
                  </span>
                </label>

                <label className="form-check-label" style={{ marginTop: "20px" }}>
                  <input
                    id="cbAbends"
                    className="form-check-input"
                    type="checkbox"
                    checked={abends}
                    onChange={(e) => setAbends(e.currentTarget.checked)}
                  />
                  <span style={{ paddingLeft: "10px" }}>
                    {uhrzeiten[2]}
                  </span>
                </label>
              
              </div>


          
                </Col>
            </Row>

            <Row style={{ justifyContent: "center" }}>
              <Col style={{ textAlign: "center" }}>

              <br />
              <br />


                <label className="form-check-label" style={{ marginTop: "20px" }}>
                  <input
                    id="cbDatenschutz"
                    className="form-check-input"
                    type="checkbox"
                    checked={datenschutz}
                    onChange={(e) => setDatenschutz(e.currentTarget.checked)}
                  />
                  <span style={{ paddingLeft: "10px" }}>
                    Ich stimme den{" "}
                    <a href="/datenschutz" target="_blank">
                      Datenschutzbedingungen
                    </a>{" "}
                    zu.
                  </span>
                </label>

                <br />
                <br />
                <br />

                {/*<div style={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    style={{
                      background: "#520da7",
                      padding: "15px",
                      width: "auto",
                      color: "white",
                      fontWeight: "bold",
                      borderRadius: "8px",
                      cursor: "pointer",
                    }}
                    disabled={spinner}
                    onClick={!spinner ? handleSubmit : null}
                  >
                    AbsendenF
                  </Button>
                </div>*/}


                <div className="pointer" onClick={!spinner ? handleSubmit : null}>
                  <VINButton text={"Absenden"} />
                </div>


              </Col>
            </Row>

            <br />
            <br />
            <br />
          
          </>
        }


        {spinner &&
          <Spinner
            message={"Bitte warten. Daten werden verarbeitet."}
          />
        }

        <div style={{marginBottom: "160px"}} />


        
      </Container>

    </>
  );
}

export default Beratung;
