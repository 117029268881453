import './App.css';
//import { useState } from 'react'
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";

// Secure Storage for Saving Auth
import  secureLocalStorage  from  "react-secure-storage";

// --------------------------------------------------------------------------
// -------------------- Protected Routes with Firebase ----------------------
import { createContext, useContext, useMemo, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
//import { useLocalStorage } from "./useLocalStorage";


// --------------------------------------------------------------------------
// -------------------------------- PAGES -----------------------------------

import Login from './components/Login/Login';
import Register from './components/Login/Register';

import Home from "./components/Home/Home"

import Neuigkeiten from "./components/Neuigkeiten/Neuigkeiten"

import Energie from "./components/Informationen/Energie/Energie"
import Versicherungen from "./components/Informationen/Versicherungen/Versicherungen"
import Immobilien from "./components/Informationen/Immobilien/Immobilien"
import Kredite from "./components/Informationen/Kredite/Kredite"
import FinanzenInvest from "./components/Informationen/FinanzenInvest/FinanzenInvest"

//import Connect from "./components/Connect/Connect"

import Partnerprogramm from "./components/Partnerprogramm/Partnerprogramm"

import UnreadDocs from "./components/News/UnreadDocs"
import News from "./components/News/News"
import Kundendaten from "./components/Kundendaten/Kundendaten"

import Cashback from "./components/Cashback/Cashback"
import NeuigkeitenVP from './components/NeuigkeitenVP/NeuigkeitenVP';

import Schaden from "./components/Schaden/Schaden"

//import Berater from "./components/Berater/Berater"
import Beratung from "./components/Beratung/Beratung"
//import Tuning from "./components/Tuning/Tuning"

import Gewinnspiel from "./components/Gewinnspiel/Gewinnspiel"

import Informationen from "./components/Informationen/Informationen"

import PartnerDokumente from "./components/Dokumente/PartnerDokumente"
import DokuPrivat from "./components/Dokumente/Privat/Privat"
import DokuEnergie from "./components/Dokumente/Energie/Energie"
import DokuVersicherung from "./components/Dokumente/Versicherung/Versicherung"
import DokuImmobilien from "./components/Dokumente/Immobilien/Immobilien"
import DokuKredite from "./components/Dokumente/Kredite/Kredite"
import DokuInvest from "./components/Dokumente/Invest/Invest"

import Impressum from "./components/Impressum/Impressum"
import Datenschutz from "./components/Datenschutz/Datenschutz"
import Nutzungsbedingungen from "./components/Nutzungsbedingungen/Nutzungsbedingungen"
import Erstinformation from "./components/Erstinformation/Erstinformation"

import Vorteilspartner from "./components/Vorteilspartner/Vorteilspartner"

import VIN1_Partnerseite from './components/VIN1_Partnerseite/VIN1_Partnerseite';
import Gewerbepartner from './components/Gewerbepartner/Gewerbepartner';

import Verbesserungen from './components/Verbesserungen/Verbesserungen';

import InformationenDokumente from "./components/InformationenDokumente/InformationenDokumente"

import { getAuth, onAuthStateChanged } from "firebase/auth";
import 'bootstrap/dist/css/bootstrap.min.css';


//import { AuthProvider } from './Auth'
import { AuthContext, AuthProvider } from './store/auth-context';
import { UserContext, UserProvider } from './store/user-context';
//import GewerbepartnerDaten from './components/Gewerbepartner/GewerbepartnerDaten';


import DokumenteWeiterleitung from "./components/DokumenteWeiterleitung"
import ConnectWeiterleitung from './components/ConnectWeiterleitung';
import DateienSendenWeiterleitung from './components/DateienSendenWeiterleitung';
//import SchadenWeiterleitung from './components/SchadenWeiterleitung'
//import NewsletterCard from './components/Neuigkeiten/NewsletterCard';
//import TarifTaxi from './components/TarifTaxi/TarifTaxi';

// --------------------------------------------------------------------------
// --------------- Implement Protected Routes with Firebase -----------------
/*
const AuthContext = createContext();


export const AuthProvider = ({ children }) => {
  const [user, setUser] = useLocalStorage("user", null);
  const navigate = useNavigate();

  // call this function when you want to authenticate the user
  const login = async (data) => {
    setUser(data);
    navigate("/");
  };

  // call this function to sign out logged in user
  const logout = () => {
    setUser(null);
    navigate("/login", { replace: true });
  };
  
  const value = useMemo(
    () => ({
      user,
      login,
      logout
    }),x
    [user]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};


export const useAuth = () => {
  return useContext(AuthContext);
};
*/

// alt mit useState in App selbst
//export const ProtectedRoute = ({ children, user }) => {

// Neu mit Storage
export const ProtectedRoute = ({ children }) => {

  const { contextValue } = useContext(AuthContext);

  if (!contextValue.isLoggedIn) {
     // user is not authenticated - redirect to Login
    return <Navigate to="/login" />;
  }
  return children;
};


export const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "instant"
    });
  }, [pathname])  
}


function App() {

  const [user, setUser] = useState();

  return (

    <AuthProvider>
      <UserProvider>

        <Router basename="/">
          <ScrollToTop />
          <Routes>
            
            <Route path="/login" element={<Login user={user} setUser={setUser}/>} />

            <Route path="/register" element={<Register user={user} setUser={setUser}/>} />
    

            <Route path="/" element={
                <ProtectedRoute user={user}>
                  <Home user={user} setUser={setUser}/>
                </ProtectedRoute> }
            />


            <Route path="kundendaten" element={
                <ProtectedRoute user={user}>
                  <Kundendaten user={user} setUser={setUser}/>
                </ProtectedRoute> }
            />

            <Route path="beratung" element={
                <ProtectedRoute user={user}>
                  <Beratung user={user} />
                </ProtectedRoute> }
            />

            <Route path="partner/vin1" element={
                <ProtectedRoute user={user}>
                  <VIN1_Partnerseite user={user} />
                </ProtectedRoute> }
            />

            <Route path="partner/:partnerName" element={
                <ProtectedRoute user={user}>
                  <Gewerbepartner user={user} />
                </ProtectedRoute> }
            />

             <Route path="dokumente/:partnerName" element={
              <ProtectedRoute user={user}>
                <PartnerDokumente />
              </ProtectedRoute> }
            />

          


  

            <Route path="informationen/energie" element={
                <ProtectedRoute user={user}>
                  <Energie />
                </ProtectedRoute> }
            />

            <Route path="informationen/versicherungen" element={
                <ProtectedRoute user={user}>
                  <Versicherungen />
                </ProtectedRoute> }
            />

            <Route path="informationen/immobilien" element={
                <ProtectedRoute user={user}>
                  <Immobilien />
                </ProtectedRoute> }
            />


            <Route path="informationen/finanzen" element={
                <ProtectedRoute user={user}>
                  <Kredite />
                </ProtectedRoute> }
            />


            <Route path="informationen/invest" element={
                <ProtectedRoute user={user}>
                  <FinanzenInvest />
                </ProtectedRoute> }
            />





            <Route path="vorteilspartner" element={
                <ProtectedRoute user={user}>
                  <Vorteilspartner />
                </ProtectedRoute> }
            />


            <Route path="news" element={
                <ProtectedRoute user={user}>
                  <News />
                </ProtectedRoute> }
            />
            
            
            {/* ========= MIT EINEM KLICK ALLES IM BLICK ========== */}

            <Route path="news/dokumente-feed" element={
                <ProtectedRoute user={user}>
                  <UnreadDocs />
                </ProtectedRoute> }
            />

            <Route path="dokumente" element={
                <ProtectedRoute user={user}>
                  <DokumenteWeiterleitung />
                </ProtectedRoute> }
            />
                       

                        <Route path="dokumente/privat" element={
                            <ProtectedRoute user={user}>
                              <DokuPrivat />
                            </ProtectedRoute> }
                        />

                        <Route path="dokumente/energie" element={
                            <ProtectedRoute user={user}>
                              <DokuEnergie />
                            </ProtectedRoute> }
                        />

                        <Route path="dokumente/versicherungen" element={
                            <ProtectedRoute user={user}>
                              <DokuVersicherung />
                            </ProtectedRoute> }
                        />

                        <Route path="dokumente/immobilien" element={
                            <ProtectedRoute user={user}>
                              <DokuImmobilien />
                            </ProtectedRoute> }
                        />

                        <Route path="dokumente/kredite" element={
                            <ProtectedRoute user={user}>
                              <DokuKredite />
                            </ProtectedRoute> }
                        />

                        <Route path="dokumente/invest" element={
                            <ProtectedRoute user={user}>
                              <DokuInvest />
                            </ProtectedRoute> }
                        />


              
            <Route path="news/:id" element={
                <ProtectedRoute user={user}>
                  <Neuigkeiten />
                </ProtectedRoute> }
            />


            {/* ================= CONNECT ================== */}

            <Route path="connect" element={
                <ProtectedRoute user={user}>
                  <ConnectWeiterleitung />
                </ProtectedRoute> }
            />

            <Route path="connect/dateien-senden" element={
                <ProtectedRoute user={user}>
                  <DateienSendenWeiterleitung />
                </ProtectedRoute> }
            />

          


            {/* ================= Weitere ================== */}


            <Route path="informationen" element={
                <ProtectedRoute user={user}>
                  <Informationen />
                </ProtectedRoute> }
            />



            {/*<Route path="tarif-taxi" element={
                <ProtectedRoute user={user}>
                  <TarifTaxi />
                </ProtectedRoute> }
            />*/}


            <Route path="cashback" element={
                <ProtectedRoute user={user}>
                  <Cashback />
                </ProtectedRoute> }
            />

                <Route path="vertriebsnews" element={
                    <ProtectedRoute user={user}>
                      <NeuigkeitenVP />
                    </ProtectedRoute> }
                />



            <Route path="schaden" element={
                <ProtectedRoute user={user}>
                  {/*<SchadenWeiterleitung />*/}
                  <Schaden />
                </ProtectedRoute> }
            />

            <Route path="partnerprogramm" element={
                <ProtectedRoute user={user}>
                  <Partnerprogramm />
                </ProtectedRoute> }
            />

            <Route path="gewinnspiel" element={
                <ProtectedRoute user={user}>
                  <Gewinnspiel />
                </ProtectedRoute> }
            />

            <Route path="verbesserungen" element={
                <ProtectedRoute user={user}>
                  <Verbesserungen />
                </ProtectedRoute> }
            />




            {/* 1. Footer (nur mit Login!) */}

            <Route path="erstinformation" element={
                <ProtectedRoute user={user}>
                  <Erstinformation />
                </ProtectedRoute> }
            />


            <Route path="informationen-dokumente" element={
                <ProtectedRoute user={user}>
                  <InformationenDokumente />
                </ProtectedRoute> }
            />



            {/* 2. Footer (ohne Login) */}

            <Route path="impressum" element={<Impressum user={user}/>} />
            <Route path="datenschutz" element={<Datenschutz user={user}/>} />
            <Route path="nutzungsbedingungen" element={<Nutzungsbedingungen user={user}/>} />

            <Route path="*"
              element={<Navigate to="/" replace />}
            />
            {/*<Route path="*" element={<p>There's nothing here: 404!</p>} />*/}
    
        </Routes>
    
      </Router>
    </UserProvider>
  </AuthProvider>

  );
}

export default App;

