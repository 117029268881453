import React, { Fragment, useContext, useState } from "react";
import style from "./VorteilspartnerKachel.module.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBarcode,
  faQrcode,
  faMapLocation,
  faGlobe,
  faCircleCheck,
  faTicket,
  faBookOpen,
} from "@fortawesome/free-solid-svg-icons";

import { Row, Col, Button, Badge } from "react-bootstrap";

import Swal from "sweetalert2";

import WebViewModal from "../WebViewModal/WebViewModal";
import axios from "axios";
import { AuthContext } from "../../../store/auth-context";

function VorteilspartnerKachelOffline(props) {

  const { currentUser } = useContext(AuthContext);

  const [showModal, setShowModal] = useState(false);
  const [modalUrl, setModalUrl] = useState(false);

  const handleExternalURL = (url) => {
    /*
    setShowModal(false);
    window.scrollTo({
      top: 0,
      behavior: "instant"
    });
    setModalUrl(url);
    setShowModal(true);
    */
    window.open(url, '_blank',  'noopener, noreferrer');
  };

  /*
  const handleMehrInfos = (url) => {
    setShowModal(false);
     window.scrollTo({
      top: 0,
      behavior: "instant"
    });
    setModalUrl(url);
    setShowModal(true);
  }
  */

  const handleNavigation = (data) => {
    
    /*let data = {
      plz: 5761,
      stadt: "Maria Alm",
      land: "Österreich",
      anschrift: "Almerwirtsfeld 20",
      name: "Bergleben Maria Alm Haus Panorama",
    };
    */
    window.open(
      "https://www.google.de/maps/search/"+data.name+', '+data.anschrift+', '+data.plz+', '+data.stadt+', '+data.land,
      "_blank",
      "noopener,noreferrer"
    );
  };

  const handleVorteilscode = (id, name, url, vorteilOnline) => {
    // Nur den Vorteilscode nicht direkt sichtbar machen - die Beschreibung ist ok
    axios({
      method: "post",
      headers: {
        'Authorization': 'Bearer ' + currentUser.uid
      },
      url: process.env.REACT_APP_BACKEND+"/vorteilspartner/code",
      data: {
        id: id
      }
    }).then(res => {

      if(res.data.vorteilscode) {
        Swal.fire({
          title: "Vorteilscode für " + name,
          html: "Dein Vorteilscode lautet:<br />"
                +"<b>" + res.data.vorteilscode + "</b><br /><br />"
                +"Der Vorteil beinhaltet: <br />"
                +"<b>" + vorteilOnline + "</b><br />",
                icon: "info",
          confirmButtonText: 'Zur Webseite',
          denyButtonText: 'Schließen',
          showDenyButton: true,
          showConfirmButton: true,
          showCloseButton: true,
          reverseButtons: true,
          preConfirm: () => {
            //Prevent Closing Alert with Code
            handleExternalURL(url)
            return false; // Prevent confirmed
          }
        })
      }
      else 
      {
        Swal.fire({
          title: "Abruf von Vorteilscode",
          html: "Beim Abrufen des Codes ist es zu einem Fehler gekommen.<br />Bitte stelle sicher, dass du mit dem Internet verbunden bist.",
          icon: "info",
        })
      }

    })
  }


  return (
    <div className={style.cardContainer}>

      <Row className="w-100">
        <Col
          className="d-flex align-items-baseline justify-content-between"
        >
          <span style={{ fontSize: "24px" }}>{props.name}</span>
          {(props.online && props.offline) && <Badge>vor Ort Vorteil</Badge>}
        </Col>
      </Row>

      <Row className="w-100">
        <Col xs={12}>
          <span style={{fontSize: "16px"}}>Vorteil</span> <span style={{fontSize: "20px"}}>{props.vorteilOffline}</span>
        </Col>

        <Col xs={12}>
          <span>
            {props.kategorie && (
              <>
                <span style={{fontSize: "16px"}}>Kategorie</span> <span style={{fontSize: "18px"}}>{props.kategorie }</span>
              </>
            )}
          </span>
        </Col>
        <Col xs={12} style={{marginBottom: "3px"}}>
          <div style={{marginBottom: "10px"}}>
            <span>{props.anschrift ?? props.anschrift} - {props.plz ?? props.plz} {props.stadt ?? props.stadt}</span>
          </div>
        </Col>
      </Row>

      {showModal && <WebViewModal url={modalUrl} hideModal={setShowModal} />}

      {/* Nur Aktionen bei verfügbaren Partnern! */ }
      {(props.kategorie !== "In Kürze verfügbar") && (

          <>
            <Row className={style.VorteilspartnerActions}>
              <Col style={{padding: "0px"}}>
                {/*props.details && (
                  <Button
                    style={{
                      background: "#520DA7",
                      padding: "8px 16px",
                    }}
                    onClick={() => handleMehrInfos("hello world")}
                  >
                    <FontAwesomeIcon
                      style={{ width: "auto", marginRight: "5px" }}
                      icon={faBookOpen}
                      size="lg"
                    />
                    Mehr Infos
                  </Button>
                )*/}

                {(props.plz && props.stadt && props.anschrift && props.land) && (
                    <Button
                      style={{
                        background: "#520DA7",
                        padding: "8px 16px",
                        display: "inline-flex"
                      }}
                      onClick={() =>
                        handleNavigation({
                          plz: props.plz,
                          stadt: props.stadt,
                          land: props.land,
                          anschrift: props.anschrift,
                          name: props.name ?? props.name,
                        })
                      }
                    >
                      <FontAwesomeIcon
                        style={{ width: "auto", marginRight: "5px" }}
                        icon={faMapLocation}
                        size="lg"
                      />
                      Navigation
                    </Button>
                )}

                {props.webseite && (
                  <Button
                    style={{
                      background: "#520DA7",
                      padding: "8px 16px",
                      display: "inline-flex"
                    }}
                    onClick={() => handleExternalURL(props.webseite)}
                  >
                    <FontAwesomeIcon
                      style={{ width: "auto", cursor: "pointer", marginRight: "5px" }}
                      icon={faGlobe}
                      size="lg"
                    />
                    Webseite
                  </Button>
                )}

                {props.online && (
                  <Button
                    style={{
                      background: "#520DA7",
                      padding: "8px 16px",
                      display: "inline-flex"
                    }}
                    onClick={() => handleVorteilscode(props.id, props.name, props.webseite, props.vorteilOnline)}
                  >
                    <FontAwesomeIcon
                      style={{ width: "auto", cursor: "pointer", marginRight: "5px" }}
                      icon={faTicket}
                      size="lg"
                    />
                    Vorteilscode
                  </Button>
                )}

              </Col>
            </Row>
          </>
        )
      }

    </div>
  );
}

export default VorteilspartnerKachelOffline;
