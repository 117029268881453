import React, {
  useState,
  useEffect,
  Fragment,
  useContext,
  useRef,
} from "react";

import { useNavigate } from 'react-router-dom';

import UserContext from "../../store/user-context";

import Header from '../Header/Header'

import Card from "../Card/Card";

import WebViewModal from "../Bausteine/WebViewModal/WebViewModal";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus, faDownload } from "@fortawesome/free-solid-svg-icons";


import style from "./Gewerbepartner.module.css";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';

import SelectInput from "../Bausteine/SelectInput/SelectInput";

import Swal from "sweetalert2";

function Gewerbepartner() {

  let navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);
  const [partnerInput, setPartnerInput] = useState("");
  const [kategorieInput, setKategorieInput] = useState("");
 
  
  const [kategorieListe, setKategorieListe] = useState([]);


  const [partnerListe, setPartnerListe] = useState([]);

  useEffect(() => {
    
    setPartnerListe([
      {
        label: 'Steinhoff Architekten',
        value: 'Steinhoff Architekten'
      },{
        label: 'Schreinemacher',
        value: 'Schreinemacher'
      }]);

      setKategorieListe([
        {
          label: 'Architekten',
          value: 'Architekten'
        },{
          label: 'Essen & Trinken',
          value: 'Essen & Trinken'
        }]);

  }, [])


  const handleKategorieChange = (data) => {
    //console.log(data.value);
    setKategorieInput(data.value);

    // Axios set Partner in DB

    // Partner in aktuelle Liste direkt aufnehmen!
  }

  const handlePartnerChange = (data) => {
    //console.log(data.value);
    setPartnerInput(data.value);

    // Axios set Partner in DB

    // Partner in aktuelle Liste direkt aufnehmen!
  }


  const handleSubscribe = () => {
    setShowModal(true);
  }


  return (
    <Fragment>
      <Header />
      <Container style={{maxWidth: "800px"}}>
        <Row style={{justifyContent: "center"}}>
          <Col style={{textAlign: "center"}}>
            <img src={require("../../img/hands.png")} alt="" style={{maxWidth: "160px", margin: "40px 0px"}} />

                <h5>Gewerbepartner</h5>
                <p className="text-center">Erhalte wichtige Informationen und Dokumente aller abonnierten Partner.</p>
                <br />
            </Col>
          </Row>
        </Container>


      <Container className={style.ContainerHeader} style={{marginBottom: "160px"}}>
        <Container className={style.containerKacheln} style={{ padding: 0 }}>
            <Row style={{ marginTop: "16px", justifyContent: "center" }}>


              <Col xs={6} md={4} style={{ marginTop: "20px", cursor: "pointer" }} onClick={handleSubscribe}>
                  <div style={{borderStyle: "dashed", borderRadius: "16px"}} className={style.cardContainer}>
                      <FontAwesomeIcon className={style.icon} icon={faCirclePlus} size="2x" style={{marginBottom: "10px"}} />
                      <p style={{display: "inline-flex", margin: "0px"}}>Partner connecten</p>
                  </div>
              </Col>

            </Row>



            {/* map alle Abbonierten Partner */}

            <Row style={{ marginTop: "16px", justifyContent: "center" }}>

            <Col xs={6} md={4} style={{ marginTop: "20px" }}>
                <Card
                  url={"steinhoff"}
                  img={"Gewerbepartner/Steinhoff.png"}
                  title={"Steinhoff Architekten"}
                  onClick={() => navigate('steinhoff')}
                />
            </Col>

            <Col xs={6} md={4} style={{ marginTop: "20px" }}>
                <Card
                  url={"goldmountains"}
                  img={"Gewerbepartner/GM.png"}
                  title={"Goldmountains Group"}
                  onClick={() => navigate('goldmountains')}
                />
            </Col>

            </Row>
            <Row style={{ marginTop: "16px", justifyContent: "center" }}>
              
            <Col xs={6} md={4} style={{ marginTop: "20px" }}>
                <Card
                  url={"steinhoff"}
                  img={"Gewerbepartner/Steinhoff.png"}
                  title={"Steinhoff Architekten"}
                />
            </Col>

            <Col xs={6} md={4} style={{ marginTop: "20px" }}>
                <Card
                  url={"steinhoff"}
                  img={"Gewerbepartner/Steinhoff.png"}
                  title={"Steinhoff Architekten"}
                />
            </Col>

            <Col xs={6} md={4} style={{ marginTop: "20px" }}>
                <Card
                  url={"steinhoff"}
                  img={"Gewerbepartner/Steinhoff.png"}
                  title={"Steinhoff Architekten"}
                />
            </Col>
      
            </Row>
        </Container>


        <br />
        <br />
        <br />

        <Modal show={showModal} onHide={() => setShowModal(!showModal)}>
        <Modal.Header closeButton>
          <Modal.Title>Connecte dich mit einem Partner</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Wähle zunächst eine Kategorie</p>
          <SelectInput placeholder={"Wähle eine Kategorie"} noOptionsMessage={"Keine Kategorie gefunden."} options={kategorieListe} handleSelectChange={handleKategorieChange} />

          <p>Wähle im folgenden Dropdown einen Partner aus. Du kannst durch Eingaben in dem Feld nach Partnern suchen.</p>
          <SelectInput placeholder={"Suche nach einem Partner..."} noOptionsMessage={"Kein Partner gefunden."} options={partnerListe} handleSelectChange={handlePartnerChange} />
        </Modal.Body>
        <Modal.Footer>
          <p
            style={{ fontSize: "100%", display: "flex", justifyContent: "flex-end", width: "auto !important", background: "#520da7", padding: "15px", color: "white", fontWeight: "bold", borderRadius: "8px", cursor: "pointer" }}
            onClick={() => setShowModal(!showModal)}
            >
            Schließen
          </p>
          <p
            style={{ fontSize: "100%", display: "flex", justifyContent: "flex-end", width: "auto !important", background: "#520da7", padding: "15px", color: "white", fontWeight: "bold", borderRadius: "8px", cursor: "pointer" }}
            onClick={() => setShowModal(!showModal)}
            >
            Connecten
          </p>
        </Modal.Footer>
      </Modal>



    </Container>
    </Fragment>
  )
}

export default Gewerbepartner;