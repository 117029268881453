import React, { useState, useEffect } from 'react'
import Header from '../Header/Header'

import { useNavigate, useLocation } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import axios from "axios";

import GewinnspielCard from './GewinnspielCard';
import GewinnspielCardOld from './GewinnspielCardOld';

import Swal from 'sweetalert2';


function Gewinnspiel() {

  const location = useLocation();

  const [gewinnspiele, setGewinnspiele] = useState([]);
  const [teilgenommen, setTeilgenommen] = useState([]);

  const [countLaufendeSpiele, setCountLaufendeSpiele] = useState(0);


  useEffect(() => {

    // ungelesene Dokumente
    async function loadGewinnspiele() {

      const response = await axios.get("https://api.vin1.eu/gewinnspiele/gewinnspiele.php?AUTH=bv8FnNYM6JU6Uk7QE8ujvM6S6WMVpg");
      
        const gewinnspieleAPI = [];
        for (const key in response.data) {
          const gewinnspielObj = {
              id: response.data[key].ID,
              title: response.data[key].TITEL,
              image: response.data[key].IMG_B64 ? response.data[key].IMG_B64 : null,
              text:  response.data[key].NACHRICHT,
              enddatum: response.data[key].ENDDATUM,
              url: response.data[key].URL,
              pdf: response.data[key].PDF_B64,
              teilgenommen: 0
          };

          
          if(new Date(response.data[key].ENDDATUM) >= new Date().getTime())
            setCountLaufendeSpiele(prevState => (prevState+1));

          gewinnspieleAPI.push(gewinnspielObj);
        }
        return gewinnspieleAPI;
      
    }

    async function loadTeilgenommen() {
      
      var bodyFormData = new FormData();
      bodyFormData.append('kundennummer', location.state.kundennummer);
      bodyFormData.append("AUTH", "bv8FnNYM6JU6Uk7QE8ujvM6S6WMVpg");

      const response = await axios.post("https://api.vin1.eu/gewinnspiele/gewinnspielTeilgenommen.php", bodyFormData);
      return response.data.IDs;
    }
  
    async function getAll() {
      const gewinnspiele = await loadGewinnspiele();
      const teilgenommenIDs = await loadTeilgenommen();
      
      teilgenommenIDs.forEach((item, index) => {

        gewinnspiele.filter( g => {
          if(g.id === item){
            g.teilgenommen = 1;
          }
        }); 
      });
      
      setGewinnspiele(gewinnspiele);
    }

    getAll();
    
  }, [])

 



    async function handleGewinnspiel(id) {

        var bodyFormData = new FormData();
        bodyFormData.append("gewinnspielid", id);
        bodyFormData.append('kundennummer', location.state.kundennummer);
        bodyFormData.append('vorname', location.state.vorname);
        bodyFormData.append('nachname', location.state.nachname);
        bodyFormData.append("AUTH", "bv8FnNYM6JU6Uk7QE8ujvM6S6WMVpg");

        let result = "";
        try {
          
          result = await axios({
            method: "post",
            url: "https://api.vin1.eu/gewinnspiele/teilnehmen.php",
            data: bodyFormData,
          })
        }
        catch (err) {
          Swal.fire({
            title: "Fehler",
            html: "Es ist ein Fehler bei der Übertragung aufgetreten. Versuche es später erneut.",
            icon: "error"
          });
        }

        if(result.data.status === "time-error")
        {
          Swal.fire({
            title: "Teilnahme nicht mehr möglich",
            html: "Die Zeit zur Teilnahme am Gewinnspiel ist leider abgelaufen.",
            icon: "info"
          });
        }
        
        if(result.data.status === "error-update" || result.data.status === "error-insert")
        {
          Swal.fire({
            title: "Gewinnspielteilnahme",
            html: "Es ist ein Fehler bei der Teilnahmeregistrierung aufgetreten.<br />Versuche es später erneut.",
            icon: "error"
          });
        }

        if(result.data.status === "false" || result.data.status === "error-insert")
        {
          Swal.fire({
            title: "Gewinnspielteilnahme",
            html: "Es ist ein Fehler bei der Teilnahmeregistrierung aufgetreten.<br />Versuche es später erneut.",
            icon: "error"
          });
        }

        if(result.data.status === "exists" || result.data.status === "true")
        {
          Swal.fire({
            title: "Gewinnspielteilnahme",
            html: "Du nimmst ab jetzt am Gewinnspiel teil.<br />Viel Glück!",
            icon: "success"
          });
          gewinnspiele.filter( g => {
            if(g.id === id){
              g.teilgenommen = 1;
            }
          }); 
        }

        

        
        setGewinnspiele([...gewinnspiele], gewinnspiele.filter( g => {
          if(g.id === id){
            g.teilgenommen = 1;
          }
        })); 
        
    }
  


  return (
    <>
      <Header />
      <Container style={{maxWidth: "800px"}}>
        <Row style={{justifyContent: "center"}}>
          <Col className="text-center">
          <img
              src={require("../../img/Gewinnspiel.png")}
              alt=""
              style={{ maxWidth: "120px", marginTop: "30px"}}
            />
            <h1>Dein ganz persönlicher Gewinnspielbereich</h1>
            <br />
            <p>
              Nimm kostenlos an unseren Gewinnspielen teil, indem du beim jeweiligen Gewinnspiel auf den Button "Jetzt teilnehmen" klickst.
            </p>
          </Col>
        </Row>
        
        <br />

        <h5 className="text-center">Laufende Gewinnspiele</h5>

        {gewinnspiele.length > 0 && gewinnspiele.map((gewinnspiel) => 
            (new Date(gewinnspiel.enddatum) >= new Date().getTime()) ?
              <GewinnspielCard key={gewinnspiel.id} data={gewinnspiel} text={gewinnspiel.text} handleGewinnspiel={handleGewinnspiel} /> 
            :  null 
        )}
        {countLaufendeSpiele === 0 &&
          <p className='text-center'><i>Momentan findet kein Gewinnspiel statt. Schaue später wieder vorbei.</i></p>
        }

    {/*
          <br /><br />
      

        <h5 className="text-center">Abgelaufene Gewinnspiele</h5>

        {gewinnspiele.map((gewinnspiel) =>  
          (new Date(gewinnspiel.enddatum).getTime() < new Date().getTime()) ?
            (
              <GewinnspielCardOld key={gewinnspiel.id} data={gewinnspiel} text={gewinnspiel.text} />
            )
            : null
        )}
      
      */}

      <Row style={{marginBottom:"80px"}}></Row>
      
      </Container>
    </>
  )
}

export default Gewinnspiel