import React, { useEffect, useState, useContext } from 'react'
import { useParams } from 'react-router-dom';

import { AuthContext } from '../../store/auth-context'

import Header from '../Header/Header';
import CardSub from '../Card/CardSub';
import CardDokumentPartner from '../Card/CardDokumentPartner';
import CardDokumentPartnerConfirm from '../Card/CardDokumentPartnerConfirm';

//import { ref, uploadBytesResumable, getDownloadURL, uploadTask, deleteObject } from "@firebase/storage";
import { db, storage } from "../../firebase";
import { collection, query, where, doc, getDoc, setDoc, getDocs, onSnapshot } from "firebase/firestore"; 

import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import IMGViewModal from '../Bausteine/IMGViewModal/IMGViewModal';

import CustomModal from '../Bausteine/CustomModal';

import { Rating } from 'react-simple-star-rating'
import axios from 'axios';
import Swal from 'sweetalert2';

function PartnerDokumente(props) {

    const { currentUser } = useContext(AuthContext);

    const { partnerName } = useParams();
    const decodedPartnerName = decodeURIComponent(partnerName); // Leerzeichen => %20, ...

    const [data, setData] = useState({});

    const [selectedBereich, setSelectedBereich] = useState("");

    const [partnerDocs, setPartnerDocs] = useState([]);

    // Preview Modal JPG + PNG
    const [showModal, setShowModal] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [modalImgSrc, setModalImgSrc] = useState("");

    //Modal Bewertung
    const [showBewertungModal, setShowBewertungModal] = useState(false);

    //Mitteilung Modal
    const [showMitteilungModal, setShowMitteilungModal] = useState(false);


    useEffect(() => {

        // Get Dokumente
        async function getPartnerDocs() {
            const colRef = collection(db, "Partner", currentUser.uid, decodedPartnerName);

            let q = null;
            q = query(colRef, where("category", "==", selectedBereich));
            /*
            if(selectedBereich == "Bestätigungen") {
                q = query(colRef, where("bestaetigung", "==", true), where("bestaetigt", "==", false));
            } else {
                q = query(colRef, where("category", "==", selectedBereich));
            }
            */

            let DocumentsArr = [];
            // Listen for changes to the query
            const unsubscribe = onSnapshot(q, (querySnapshot) => {
                DocumentsArr = [];
                querySnapshot.forEach((doc) => {
                    DocumentsArr.push({
                        id: doc.id,
                        data: doc.data(),
                    });
                });
                setPartnerDocs(DocumentsArr);
            });
        
            // Return a function to unsubscribe from the query
            // funktioniert nicht bei logout...
            // muss in Firebase/Auth Context
            return () => unsubscribe();  
        }

        async function getCRMDocs (art) {

            alert("Offene Docs..."+ art)

            // get open Partner Docs per API filtered by kdnr verifiziert durch Google UID Middleware

            //setPartnerDocs();
        }

        if(selectedBereich !== "Abgelehnt" && selectedBereich !== "Offene Bestätigungen") {
            getPartnerDocs();
        }
        else if (selectedBereich == "Offene Bestätigungen") {
            getCRMDocs("Offene Bestätigungen");
        }
        else if (selectedBereich == "Abgelehnt") {
            getCRMDocs("Abgelehnt");
        }

      }, [currentUser, selectedBereich]);
      



    useEffect(() => {

        if(decodedPartnerName == "Steinhoff Architekten") {
            setData({
                partner_name: "Steinhoff Architekten",
                items: ["Angebote", "Aufträge", "Rechnungen", "Sonstiges", "News"],
                img: "https://steinhoff-architekten.de/wp-content/uploads/2021/09/STEINHOFFarchitekten@4x-2048x228-1-1024x114.png",
                bewertung: 3.4,
            });
        }

        if(decodedPartnerName == "Goldmountains Group") {
            setData({
                partner_name: "Goldmountains Group",
                items: ["Angebote", "Aufträge", "Rechnungen", "Nutzungsrechte", "Sonstiges"],
                img: "https://goldmountains.eu/wp-content/uploads/2019/03/Goldmountains_Group_Logo@4x-1024x366.png",
                bewertung: 4.3
            });
        }

    },[decodedPartnerName]);


    // =========================== BEWERTUNG ================================

    const [rating, setRating] = useState(0);

    const handleRating = (rate) => {
        setRating(rate)
        // other logic
    }
    //const onPointerEnter = () => console.log('Enter')
    //const onPointerLeave = () => console.log('Leave')
    //const onPointerMove = (value, index) => console.log(value, index)

    const handleSaveBewertung = () => {

        if(rating == 0) {
            Swal.fire({
                icon: "info",
                title: "Bewertung",
                html: "Du musst vor dem Speichern eine Bewertung vornehmen."
            });
            return;
        }

        axios({
            method: "post",
            headers: {
                'Authorization': 'Bearer ' + currentUser.uid
              },
            url: process.env.REACT_APP_BACKEND+"/partner/bewertung", 
            data: {
                partner_name: decodedPartnerName,
                bewertung: rating,
                uid: currentUser.uid
            } 
        })
        .then(res => {
            Swal.fire({
                icon: "success",
                title: "Erfolg",
                html: "Deine Bewertung wurde erfolgreich übermittelt."
            })
            setShowMitteilungModal(false);
        })
        .catch(err => {
            Swal.fire({
                icon: "error",
                title: "Fehler",
                html: "Deine Bewertung konnte nicht gespeichert werden. Bitte versuche es später erneut."
            })
        })

    }

    // =========================== Mitteilung ================================

    const [mitteilung, setMitteilung] = useState("");

    const handleSaveMitteilung = () => {
        axios({
            method: "post",
            headers: {
                'Authorization': 'Bearer ' + currentUser.uid
              },
            url: process.env.REACT_APP_BACKEND+"/partner/mitteilung",
            data: {
                partner_name: decodedPartnerName,
                nachricht: mitteilung,
                uid: currentUser.uid
            }
        })
        .then(res => {
            Swal.fire({
                icon: "success",
                title: "Erfolg",
                html: "Deine Mitteilung wurde erfolgreich übermittelt."
            })
            setShowBewertungModal(false);
        })
        .catch(err => {
            Swal.fire({
                icon: "error",
                title: "Fehler",
                html: "Deine Mitteilung konnte nicht gespeichert werden. Bitte versuche es später erneut. ", err
            })
        })
    }



  
    if(!data) {
        return (
        <div>
            <p>Loading...</p>
        </div>)
    }

    return (
        <>
            <Header url={"/dokumente"}/>
            <Container style={{maxWidth: "800px"}}>
                <Row style={{justifyContent: "center"}}>

                    <Col xs={12} className="d-flex justify-content-center">
                        {data.img && 
                            <img src={data.img} className="mt-5" style={{width: "100%", maxWidth: "300px", objectFit: "contain"}} />
                        }
                    </Col>

                    <Col>
                        <h1 style={{color: "#520da7", margin: "0px"}}>{data.partner_name}</h1><br />
                        <p className="text-center">
                            Dieser Bereich ist dein ganz persönlicher Bereich von Partner {data.partner_name}.
                        </p>      
                    </Col>
                </Row>

                <Row>
                    <Col xs={12} className='mb-2'>
                        <span style={{marginLeft: "8px"}}>Bewertung {data.bewertung ? data.bewertung+"/5" : ""}</span>
                    </Col>
                    <Col style={{}}>
                        <Button className="space-s" style={{width: "auto"}} onClick={() => setShowBewertungModal(true)}>Partner bewerten</Button>
                        <Button className="space-s" style={{width: "auto"}} onClick={() => setShowMitteilungModal(true)}>Mitteilung senden</Button>
                    </Col>
                </Row>


               
                <Row style={{justifyContent: "center"}}>

                    <Col xs={12} className="mt-5">
                        <p>Manche Aufträge benötigen eine aktive Kunden Bestätigung. Diese findest du hier eingruppiert. Falls etwas zu erledigen ist, wirst du über deinen Dokumentenfeed darüber benachrichtigt.</p>
                    </Col>
                    
                    {selectedBereich == "Offene Bestätigungen" &&
                        <Col key={"offen"} xs={12} md={4} style={{marginTop: "20px"}} onClick={() => setSelectedBereich("Offene Bestätigungen")}>
                            <CardSub title={"Offene Bestätigungen"} selected={1} color={"#520da7"} />
                        </Col>
                    }

                    {selectedBereich != "Offene Bestätigungen" &&
                        <Col key={"offen"} xs={12} md={4} style={{marginTop: "20px"}} onClick={() => setSelectedBereich("Offene Bestätigungen")}>
                            <CardSub title={"Offene Bestätigungen"} color={"#520da7"} />
                        </Col>
                    }


                    {selectedBereich == "Abgelehnt" &&
                        <Col key={"abgelehnt"} xs={12} md={4} style={{marginTop: "20px"}} onClick={() => setSelectedBereich("Abgelehnt")}>
                            <CardSub title={"Abgelehnt"} selected={1} color={"#520da7"} />
                        </Col>
                    }

                    {selectedBereich != "Abgelehnt" &&
                        <Col key={"abgelehnt"} xs={12} md={4} style={{marginTop: "20px"}} onClick={() => setSelectedBereich("Abgelehnt")}>
                            <CardSub title={"Abgelehnt"} color={"#520da7"} />
                        </Col>
                    }

                </Row>


                <Row style={{justifyContent: "center"}} className="mt-4">

                    {data.items?.map(item => 

                        item === selectedBereich ? ( 
                            <Col key={item} xs={6} md={3} style={{marginTop: "20px"}} onClick={() => setSelectedBereich(item)}>
                                <CardSub title={item} selected={1} color={"#520da7"} />
                            </Col>
                        ) : (
                            <Col key={item} xs={6} md={3} style={{marginTop: "20px"}} onClick={() => setSelectedBereich(item)}>
                                <CardSub title={item} color={"#520da7"} />
                            </Col>
                        )
                    )}
                </Row>


                <Row style={{justifyContent: "center", marginBottom: "80px", marginTop: "40px"}} >
                
                    {/* Dokumenten Liste */}
                    {!selectedBereich &&
                        <Col xs={12} md={12}>
                            <p className="text-center">Klicke auf eine Kachel, um Dokumente anzuzeigen.</p>
                        </Col>
                    }
                    
                    {selectedBereich && selectedBereich !== "Bestätigungen" &&
                        <Col xs={12} md={12}>
                            <h4 className="text-center" style={{marginBottom: "20px"}}>Meine Dokumente</h4>

                            {partnerDocs.length === 0 && <p style={{marginTop: "10px"}} className="text-center">Noch keine Dokumente in {selectedBereich} vorhanden.</p>}
                            
                            {partnerDocs.length >= 0 && partnerDocs.map(doc =>  
                                <CardDokumentPartner key={doc.id} data={doc} partnerName={decodedPartnerName} setModalTitle={setModalTitle} setModalImgSrc={setModalImgSrc} setShowModal={setShowModal} />
                            )}
                        </Col>
                    }

                    {selectedBereich && selectedBereich == "Bestätigungen" &&
                        <Col xs={12} md={12}>
                            <h4 className="text-center" style={{marginBottom: "20px"}}>Meine Dokumente</h4>

                            {partnerDocs.length === 0 && <p style={{marginTop: "10px"}} className="text-center">Noch keine Dokumente in {selectedBereich} vorhanden.</p>}
                            
                            {partnerDocs.length >= 0 && partnerDocs.map(doc => 
                                <CardDokumentPartnerConfirm key={doc.id} data={doc} partnerName={decodedPartnerName} setModalTitle={setModalTitle} setModalImgSrc={setModalImgSrc} setShowModal={setShowModal} />   
                            )}
                        </Col>
                    }
                </Row>
        
                {showModal && <IMGViewModal title={modalTitle} imgSrc={modalImgSrc} visible={setShowModal} />}     

                {showBewertungModal && 
                    <CustomModal 
                        title={"Bewertung - "+data.name}
                        visible={showBewertungModal}
                        setVisible={setShowBewertungModal}
                        handleSave={handleSaveBewertung}
                    > 
                        <p>Jetzt Partner bewerten. Du kannst halbe und ganze Sterne vergeben.</p>
                        <Rating
                            onClick={handleRating}
                            allowFraction={true} //allow half Star Rating
                            //onPointerEnter={onPointerEnter}
                            //onPointerLeave={onPointerLeave}
                            //onPointerMove={onPointerMove}
                        />
                        {rating !== 0 && <p className="mt-3">Deine Bewertung: {rating}/5</p>}
                    </CustomModal>
                }


                {setShowMitteilungModal && 
                    <CustomModal 
                        title={"Mitteilung - "+data.name}
                        visible={showMitteilungModal}
                        setVisible={setShowMitteilungModal}
                        handleSave={handleSaveMitteilung}
                    > 
                        <p>Du hast ein Problem mit diesem Partner? Schreibe uns eine Mitteilung. Unsere Clearing-Stelle wird sich um dein Problem kümmern.
                            Der Partner erfährt nicht deinen Namen.
                        </p>
                        <Form.Group className="mb-3">
                            <Form.Label>Mitteilung</Form.Label>
                            <Form.Control as="textarea" rows={4}
                                placeholder=" Mitteilung"
                                name="nachricht"
                                value={mitteilung}
                                onChange={(e) => setMitteilung(e.target.value)}
                            />
                        </Form.Group>
                    </CustomModal>
                }

            </Container>    
        </>
    );
}

export default PartnerDokumente