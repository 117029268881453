import React from "react";
import { useNavigate } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileText,
  faChevronRight,
  faNewspaper,
  faChevronDown,
  faChevronUp,
} from "@fortawesome/free-solid-svg-icons";

import { Container, Row, Col, Button } from "react-bootstrap";

import style from "./UnreadDocs.module.css";
import Swal from "sweetalert2";


function DokumentenFeed(props) {

  let navigate = useNavigate();


  const getDate = (timestamp) => {
    var date = new Date(timestamp * 1000);
    let day = date.getDate();
    let month = date.getMonth() + 1;

    if (String(day).length === 1) day = "0" + day;
    if (String(month).length === 1) month = "0" + month;

    /*
      let hours = date.getHours();
      let minutes = date.getMinutes();
      let seconds = date.getSeconds();

      if((String(hours)).length===1)
        hours='0'+hours;
      if((String(minutes)).length===1)
        minutes='0'+minutes;
      if((String(seconds)).length===1)
        seconds='0'+seconds;
    */

    let dateT = day + "." + month + "." + date.getFullYear(); // + ' '+ hours+':'+minutes+':'+seconds;
    //dateT=String(dateT);
    return dateT;
  };



  return (
    <Row style={{ padding: "0px 10px" }}>
      <br />
      <br />
      {props.data.length === 0 && (
        <p className="text-center" style={{ marginBottom: "80px" }}>
          Keine ungelesenen Informationen vorhanden.
        </p>
      )}

      {props.data.length > 0 &&
        props.data
          .sort((a, b) => (b.date > a.date ? 1 : -1))
          .map((doc) => (
            <li key={doc.id} className={style.card}>


              {/* Dokumentenfeed */}
              {isNaN(doc.id) && (
                <div
                  className={style.cardInner}
                  onClick={() => {
                    
                    // Nur Gelesen Aktion, wenn in Feed Ungelesen
                    {props.status &&
                        Swal.fire({
                        icon: "info",
                        title: "Dokument",
                        html:
                            "<b>Willst du das Dokument als gelesen markieren?</b><br />" +
                            "Du wirst unabhängig von deiner Auswahl zur richtigen Stelle geleitet.<br />" +
                            "Gelesene Dokumente findest du, indem du auf Archiv klickst.",
                        confirmButtonColor: "--primary-color",
                        confirmButtonText: "Als gelesen markieren",
                        showDenyButton: true,
                        denyButtonColor: "#888",
                        denyButtonText: "Nur zum Dokument",
                        showCloseButton: true,
                        reverseButtons: true,
                        }).then(async (result) => {
                          if (result.isConfirmed) {
                              //"gelesen" => true, damit Doc in Archiv sichtbar.
                              props.handleUpdateGelesen(doc.id);
                          }
                          
                          if (!result.isDismissed) {
                              // Nur zum Dokument leiten, wenn nicht abgebrochen
                              navigate("/dokumente/" + doc.navigation, {
                                  state: {
                                      navigateBack: '/news/dokumente-feed',
                                      category: doc.category,
                                      subcategory: doc.subcategory,
                                  },
                              });
                          }
                          
                          //if(result.isDismissed) {
                          //  alert("Abbrechen...")
                          //}
                        })
                    }

                    {/* Wenn in Archiv */}
                    {!props.status &&
                        // NEU: navigate("news/dokumente/" + doc.category, {
                        navigate("/dokumente/" + doc.navigation, {
                          state: {
                              navigateBack: '/news/dokumente-feed',
                              category: doc.category,
                              subcategory: doc.subcategory,
                          },
                      });
                    }
                    
                  }}
                >
                  <div className={style.cardContent}>
                    <FontAwesomeIcon className={style.icon} icon={faFileText} />
                    <p className={style.titleWeb}>
                      <span className={style.date}>
                        {getDate(doc.date.seconds)}
                      </span>
                      {doc.name}
                    </p>
                    <p className={style.titleMobil}>
                      <span className={style.date}>
                        {getDate(doc.date.seconds)}
                      </span>
                      {doc.name}
                    </p>
                  </div>
                  <div className={style.NavDocument}>
                    <span>Zum Dokument</span>
                    <FontAwesomeIcon
                      className={style.icon}
                      icon={faChevronRight}
                    />
                  </div>
                </div>
              )}




              {/* NewsletterFeed */}
              {!isNaN(doc.id) && (
                <div
                  className={style.cardInner}
                  onClick={() => navigate("/news/"+doc.id)}
                >
                  <div className={style.cardContent}>
                    <FontAwesomeIcon
                      className={style.icon}
                      icon={faNewspaper}
                    />
                    <p className={style.titleWeb}>
                      <span className={style.date}>{getDate(doc.date)}</span>
                      {doc.name}
                    </p>
                    <p className={style.titleMobil}>
                      <span className={style.date}>{getDate(doc.date)}</span>
                      {doc.name}
                    </p>
                  </div>
                  <div className={style.NavDocument}>
                    <span>Zum Newsblog</span>
                    <FontAwesomeIcon
                      className={style.icon}
                      icon={faChevronRight}
                    />
                  </div>
                </div>
              )}
            </li>
          ))}
    </Row>
  );
}

export default DokumentenFeed;
