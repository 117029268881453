import React, {createContext, useState, useEffect } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from '../firebase'

export const AuthContext = createContext({
  token: "",
  user: {},
  //userId: "",
  //isLoggedIn: false,
  login: (token) => {},
  logout: () => {},
});

export const AuthProvider = ({ children }) => {
  // wenn noch kein Login == undefined, aber sonst wird bei reload der vorhandene Token geladen.
  // useEffect wird nicht benötigt!

  const initialToken = sessionStorage.getItem("token");
  const [token, setToken] = useState(initialToken);
  //const [token, setToken] = useState(null);

  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
   
    const unsubscribe = onAuthStateChanged(auth, (user) => {

        if(user) {
            loginHandler(user, user.accessToken);
        }
        else {
            logoutHandler();
        }
     
    });
    return () => unsubscribe(); // cleanup function
  }, []);

  //if token empty => false, else token
  const userIsLoggedIn = !!token;
  
  const loginHandler = (user, token) => {
    setToken(token);
    setCurrentUser(user);
    sessionStorage.setItem("token", token);
    //sessionStorage.setItem("user", JSON.stringify(user));
  };
 
  const logoutHandler = () => {
    setToken(null);
    setCurrentUser({});
    sessionStorage.removeItem("token");
    //sessionStorage.removeItem("user");
  };

  const contextValue = {
    token: token,
    user: currentUser,
    userId: currentUser?.uid ? currentUser.uid : "",
    isLoggedIn: userIsLoggedIn,
    login: loginHandler,
    logout: logoutHandler,
  };

  return (
    <AuthContext.Provider value={{contextValue, currentUser}}>
      {children}
    </AuthContext.Provider>
  );
};

//export default AuthContext;

/*
// Context in CRM
import React, { useState } from 'react'
const MainContext = React.createContext()
const MainContextProvider = (props) => {
  const localJWT = sessionStorage.getItem('token') || '';
  const employee = sessionStorage.getItem('employee') || '';
  const access = sessionStorage.getItem('access') || '';

  // Daten müssen aus JWT generiert werden
  const [jwt, setJwt] = useState(localJWT);
  const [user, setUser] = useState({});
  const [rechte, setRechte] = useState(access);

  return (
    <MainContext.Provider value={{ jwt, setJwt, user, setUser, rechte, setRechte }}>
      {props.children}
    </MainContext.Provider>
  )
}

export { MainContextProvider, MainContext }
*/
