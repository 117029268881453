import React, { Fragment, useContext, useState } from 'react'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faDownload, faTrash, faEye, faPenNib, faClipboardCheck , faCircleCheck } from "@fortawesome/free-solid-svg-icons";

import style from "./CardSmall.module.css"
import classLister from 'css-module-class-lister';


import {  Row, Col, Button, Form, Modal } from "react-bootstrap";

//import DownloadLink from 'react-download-link'
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { auth, db, storage  } from "../../firebase";
import { doc, updateDoc } from "firebase/firestore"; 

//import Swal from 'sweetalert2'
//import Dokumente from '../DokumenteWeiterleitung';


function CardDokumentPartnerConfirm(props) {

  //const classes = classLister(style);

  //const [url, setUrl] = useState("");


  const [showModal, setShowModal] = useState(false);
  const [modalDetails, setModalDetails] = useState({});

  const [ablehnenText, setAblehnenText] = useState("");

/*
  const getBase64FromUrl = async (url) => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob); 
      reader.onloadend = () => {
        const base64data = reader.result;   
        resolve(base64data);
      }
    });
  }

  function getBase64Image(img) {
    var canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;
    var ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0);
    var dataURL = canvas.toDataURL("image/png");
    return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
  }
*/

/*
  function downloadFile(fileURL, fileName) {
        var save = document.createElement('a');
        save.href = fileURL;
        save.target = '_blank';
        var filename = fileURL.substring(fileURL.lastIndexOf('/')+1);
        save.download = fileName || filename;
           if ( navigator.userAgent.toLowerCase().match(/(ipad|iphone|safari)/) && navigator.userAgent.search("Chrome") < 0) {
                document.location = save.href; 
    // window event not working here
            }else{
                var evt = new MouseEvent('click', {
                    'view': window,
                    'bubbles': true,
                    'cancelable': false
                });
                save.dispatchEvent(evt);
                (window.URL || window.webkitURL).revokeObjectURL(save.href);
            }   
    }
    */


  async function previewPDFFile(url, filename) {


    //UUID+filename = filename!
    const storageRef = ref(storage, "/"+auth.currentUser.uid+"/"+filename);

    getDownloadURL(ref(storage, url))
      .then((url) => {

        //console.log("get Download");

        // `url` is the download URL for 'images/stars.jpg'
        // This can be downloaded directly:
        const xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onload = (event) => {
          const blob = xhr.response;

          //url = window.URL.createObjectURL(blob);

          var file = new Blob([blob], {type: 'application/pdf'});
          var fileURL = URL.createObjectURL(file);
          

          // set object tag fullscreen...
          //setUrl(fileURL);

          //alt
          //window.open(fileURL);

          // Hiermit geht es auch in iOS
          const a = document.createElement("a");
          a.setAttribute("download", fileURL);
          a.setAttribute("href", fileURL);
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);

        };
        
        xhr.open('GET', url);
        xhr.send();
          
      })
      .catch((error) => {
        // Handle any errors
        console.log(error);
      });
  }


  async function previewIMGFile(url, filename, title) {

    //UUID+filename = filename!
    const storageRef = ref(storage, "/"+auth.currentUser.uid+"/"+filename);

    getDownloadURL(ref(storage, url))
      .then((url) => {

        //const img = document.getElementById('myimg');
        //img.setAttribute('src', url);
        props.setModalTitle(title);
        props.setModalImgSrc(url);
        props.setShowModal(true);
        
      })
      .catch((error) => {
        // Handle any errors
        console.log(error);
      });
  }




  async function downloadFile(url, filename) {

    //UUID+filename = filename!
    const storageRef = ref(storage, "/Partner/"+auth.currentUser.uid+"/"+props.partnerName+"/"+filename);

    getDownloadURL(ref(storage, url))
      .then((url) => {

        // `url` is the download URL for 'images/stars.jpg'
        // This can be downloaded directly:
        const xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onload = (event) => {
          const blob = xhr.response;

          url = window.URL.createObjectURL(blob);

          var a = document.createElement("a");
          a.href = url;
          a.download = filename.substr(36);
          a.click();
          window.URL.revokeObjectURL(url);
        };
        
        xhr.open('GET', url);
        xhr.send();
        
        // Or inserted into an <img> element
        //const img = document.getElementById('myimg');
        //img.setAttribute('src', url);
      })
      .catch((error) => {
        // Handle any errors
        console.log(error);
      });
  }

  const handleBestaetigung = () => {
    setModalDetails({
      title: "Tippgebervertrag_VIN1.pdf",
      created: "11.04.2023, 15:42:00"
    })
    setShowModal(true);
  }

  const handleUnterschrift = () => {
    setModalDetails({
      title: "Angebot_VIN1.pdf",
      created: "12.04.2023, 10:42:00"
    })
    setShowModal(true);
  }


  const handleAblehnen = async(docId) => {
    //Update Firebase Doc
    const docRef = doc(db, auth.currentUser.uid, "Partner", props.partnerName, docId);
    await updateDoc(docRef, {
      abgelehnt: ablehnenText,
      bestaetigung: false,
      bestaetigt_am: new Date()
    });

    // Mail an Partner CRM
    // Task in CRM

    setModalDetails({});
    setShowModal(false);
  }

  const handleAnnehmen = async (docId) => {
    //Update Firebase Doc
    const docRef = doc(db, auth.currentUser.uid, "Partner", props.partnerName, docId);
    await updateDoc(docRef, {
      bestaetigung: true,
      bestaetigt_am: new Date()
    });

    // Mail an Partner CRM
    // Task in CRM

    setShowModal(false);
  }



  return (
   
      <div className={style.cardContainerUpload} style={{marginBottom: "20px", background: "#ffffff" }}>
        <Row style={{width:"100%", padding: "0px 0px 0px 8px", fontSize: "90%"}}>
            <Col style={{display: "flex", flex: "7", flexDirection: "column", alignItems: "flex-start", padding: "10px 0px 0px 15px"}}>
                <p className="fw-bold" style={{marginBottom: "5px", textAlign: "left", color: "#000000" }}>{props.data.data.title.substr(36)}</p>
                <p style={{padding: "0", marginBottom: "5px", textAlign: "left"}}>{new Intl.DateTimeFormat('de-DE', {
              month: '2-digit',
              day: '2-digit',
              year: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
              second: 'numeric'
            }).format(new Date(props.data.data.created.seconds *1000))}</p>
            </Col>

            <Col style={{display: "flex", flex: "3", justifyContent: "flex-end"}}>
              {(props.data.data.bestaetigung_art === "bestaetigung" && !props.data.data.bestaetigt) &&
                <Button onClick={handleBestaetigung} className="primCol">
                  <FontAwesomeIcon className={style.iconWhite} icon={faClipboardCheck} size="lg" /> 
                  &nbsp;Bestätigungs Dialog öffnen
                </Button>
              }

              {(props.data.data.bestaetigung_art === "unterschrift" && !props.data.data.bestaetigt) &&
                <Button onClick={handleUnterschrift} className="primCol">
                  <FontAwesomeIcon className={style.iconWhite} icon={faPenNib} size="lg" /> 
                  &nbsp;Unterschrift Dialog öffnen
                </Button>
              }
            </Col>

            <Col style={{display: "flex", flex: "1", justifyContent: "flex-end"}}>

                {props.data.data.mimeType === "application/pdf" && 
                  <FontAwesomeIcon className={style.iconGrey} style={{marginRight: "30px"}} icon={faEye} size="lg" onClick={() => previewPDFFile(props.data.data.url, props.data.data.title, props.data.data.title.substr(36))} /> 
                }
                {(props.data.data.mimeType === "image/jpeg" || props.data.data.mimeType === "image/png")  && 
                  <FontAwesomeIcon className={style.iconGrey} style={{marginRight: "30px"}} icon={faEye} size="lg" onClick={() => previewIMGFile(props.data.data.url, props.data.data.title, props.data.data.title.substr(36))} /> 
                } 

                <FontAwesomeIcon className={style.iconGrey} icon={faDownload} size="lg" onClick={() => downloadFile(props.data.data.url, props.data.data.title)} />    
                
                <p className="" style={{}}>{props.data.data.name}</p>
            </Col>
        </Row>

        <Modal show={showModal} onHide={() => setShowModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title><span style={{fontSize: "70%"}}>Bestätigung von: {modalDetails.title}</span></Modal.Title>
          </Modal.Header>
          <Modal.Body>
              <p>Willst du den Vertrag von {props.partnerName} vom {modalDetails.created} annehmen?</p>
              <p>Die Annahme gilt als verbindlich. Du kannst deine Entscheidung nicht mehr ändern.</p>

              <p>Wenn du das Angebot nicht annehmen willst, schreib dem Partner eine kurze Mitteilung:</p>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Ablehnungsgrund"
                name="ablehnenText"
                value={
                  ablehnenText ? ablehnenText : ""
                }
                onChange={(e) =>
                  setAblehnenText(e.target.value)
                }
              />
          </Modal.Body>
          <Modal.Footer>
            <Button style={{ fontSize: "100%", display: "flex", justifyContent: "flex-end", width: "auto !important", background: "#df4759", padding: "15px", color: "white", fontWeight: "bold", borderRadius: "8px", border: "none", cursor: "pointer" }}
              onClick={() => handleAblehnen(props.data.id)}
              disabled={ablehnenText.length < 10 ? true : false}
            >
              Ablehnen
            </Button>
            <Button style={{ fontSize: "100%", display: "flex", justifyContent: "flex-end", width: "auto !important", background: "#520da7", padding: "15px", color: "white", fontWeight: "bold", borderRadius: "8px", border: "none", cursor: "pointer" }}
              onClick={() => handleAnnehmen(props.data.id)}
            >
              Annehmen/Bestätigen
            </Button>
          </Modal.Footer>
        </Modal>

      </div>
  )
}

export default CardDokumentPartnerConfirm