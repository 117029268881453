import React, {Fragment, useContext, useState } from 'react'
import Header from '../Header/Header'
import style from "./Kundendaten.module.css"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark  } from "@fortawesome/free-solid-svg-icons";

import { Container, Row, Col, Button } from 'react-bootstrap';

import { getAuth, updatePassword, updateEmail } from "firebase/auth";

import Swal from 'sweetalert2'
import VINButton from '../../VINButton';
import { AuthContext } from '../../store/auth-context';
import { UserContext } from '../../store/user-context';

function Kundendaten() {

  const { currentUser } = useContext(AuthContext);
  const { user } = useContext(UserContext);


  // ------------------------- Set New Login E-Mail ------------------------------
  const [statusEmailChange, setStatusEmailChange] = useState(false);
  const [newEmail, setNewEmail] = useState("");

  function handleChangeEmail() {

      const auth = getAuth();

      const userAuth = auth.currentUser;
     // const newPassword = getASecureRandomPassword();
  
      updateEmail(userAuth, newEmail).then(() => {
  
        setStatusEmailChange(!statusEmailChange);
  
        Swal.fire({
          title: 'Login E-Mail Änderung',
          html: 'Dein Login E-Mail Adresse wurde erfolgreich übernommen.<br />Ab dem nächsten Login ist deine neu hinterlegte E-Mail Adresse gültig.',
          icon: 'success',
          confirmButtonText: 'Ok'
        });

      }).catch((error) => {

        if(error.code === "auth/invalid-email")
        {
          Swal.fire({
            title: 'Login Änderung',
            html: 'Deine eingegebene E-Mail ist nicht gültig.<br />Bitte gib eine gültige Login E-Mail Adresse ein.',
            icon: 'error',
            confirmButtonText: 'Ok'
          });
        }
        else if(error.code === "auth/email-already-in-use")
        {
          Swal.fire({
            title: 'Login Änderung',
            text: 'Deine eingegebene E-Mail wird bereits in einem anderen Benutzeraccount verwendet.',
            icon: 'error',
            confirmButtonText: 'Ok'
          });
        }
        else
        {
            Swal.fire({
                title: 'Fehler',
                text: 'Es ist ein unbekannter Fehler aufgetreten. Dein alter Login ist weiterhin gültig.',
                icon: 'error',
                confirmButtonText: 'Ok'
              });
        }

      });
  }


  // ------------------------- Set New Login Password ------------------------------
  const [statusPWDChange, setStatusPWDChange] = useState(false);
  const [newPassword, setNewPassword] = useState("");

  function handleChangePassword() {

    if(newPassword.length < 6)
    {
      Swal.fire({
          title: 'Passwort',
          text: 'Das eingegebene Passwort muss min. 6 Zeichen lang sein.',
          icon: 'info',
          confirmButtonText: 'Ok'
      });
      return;
    }


    const auth = getAuth();

    const userAuth = auth.currentUser;
   // const newPassword = getASecureRandomPassword();

    updatePassword(userAuth, newPassword).then(() => {

      setStatusPWDChange(!statusPWDChange);

      Swal.fire({
        title: 'Passwort-Änderung',
        text: 'Dein Passwort wurde erfolgreich übernommen.',
        icon: 'success',
        confirmButtonText: 'Ok'
    });
    }).catch((error) => {
      Swal.fire({
        title: 'Fehler beim Passwort ändern',
        text: 'Das Passwort konnte nicht übernommen werden. Bitte versuche es später erneut.',
        icon: 'error',
        confirmButtonText: 'Ok'
    });
    });
        
  }



  return (
    <Fragment>
      <Header />
      <Container style={{maxWidth: "800px"}}>
        <Row style={{justifyContent: "center", marginBottom:"80px"}}>
          <Col>
            <h1>Meine Daten</h1>

            {!user.vorname && !user.nachname &&
                <p className="text-center">Deine Daten werden automatisch verarbeitet.<br />In Kürze findest du hier die Informationen aus deinem Kundenkonto.</p>
            }

            {user.vorname && user.nachname &&
              <Fragment>
                <p>Kundennummer</p>
                <h4 style={{color: "#520da7"}}>{user.kundennummer ? user.kundennummer : "in Bearbeitung"}</h4>
                <hr />

                {user.firma  &&
                  <Fragment>
                    <p>Firma</p>
                    <h4 style={{color: "#520da7"}}>{user.firma}</h4>
                    <hr />
                  </Fragment>
                } 


                <p>Vorname + Nachname</p>
                <h4 style={{color: "#520da7"}}>{user.vorname +" "+ user.nachname}</h4>
                <hr />

                <p>Straße + Hausnummer</p>
                <h4 style={{color: "#520da7"}}>{user.strasse}</h4>
                <hr />

                <p>PLZ + Stadt</p>
                <h4 style={{color: "#520da7"}}>{user.plz +" "+ user.ort}</h4>
                <hr />

                <p>E-Mail</p>
                <h4 style={{color: "#520da7"}}>{user.email}</h4>
                <hr />

                <p>Telefon/Mobil</p>
                <h4 style={{color: "#520da7"}}>{user.telefon ? user.telefon : "nicht hinterlegt"}</h4>
                <hr />

                <p>Geburtsdatum</p>
                <h4 style={{color: "#520da7"}}>{new Intl.DateTimeFormat('de-DE', {
                  month: '2-digit',
                  day: '2-digit',
                  year: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric',
                  second: 'numeric'
                }).format(new Date(user.geburtsdatum)).substring(0, 10)}</h4>
                <hr />
              </Fragment>
            }

            {user.sponsor &&
                <Fragment>
                  <p>Sponsor</p>
                  <h4 style={{color: "#520da7"}}>{user.sponsor}</h4>
                  <hr />
              </Fragment>
            }

            {(!statusPWDChange || !statusEmailChange) && 
            <Container>
              <Row className="mt-5">
              
                <Col>
                  <div className="pointer" onClick={() => setStatusEmailChange(!statusEmailChange)}>
                    <VINButton text={"Login E-Mail ändern"} />
                  </div>
   
                  {/*<Button className={style.BtnCustom} onClick={() => setStatusEmailChange(!statusEmailChange)}>Login E-Mail ändern</Button>*/}
                </Col>
                <Col>
                <div className="pointer" onClick={() => setStatusPWDChange(!statusPWDChange)}>
                    <VINButton text={"Passwort ändern"} />
                  </div>
                  {/*<Button className={style.BtnCustom} onClick={() => setStatusPWDChange(!statusPWDChange)}>Passwort ändern</Button>*/}
                </Col>
              </Row>
            </Container>}


            {statusEmailChange &&
              <Container style={{maxWidth: "500px", marginBottom: "60px", marginTop: "60px"}}>
                <Row style={{justifyContent: "flex-end"}}>
                    <FontAwesomeIcon style={{width:"auto", cursor: "pointer", marginBottom: "10px"}} icon={faXmark} size="2x" onClick={() => { setStatusEmailChange(!statusEmailChange) }} />
                </Row>
                <Row style={{justifyContent: "center"}}>
                    <h2 style={{color: '#520da7', fontSize: "140%"}} className="fw-bold text-center">
                        Login E-Mail-Adresse ändern
                    </h2>
                    <p className="text-center">Hier kannst du deine neues Login E-Mail-Adresse festlegen.<br /><br />
                    <span className="fw-bold">Beachte, dass du Zugriff auf diese E-Mail Adresse benötigst, um dein Passwort zurückzusetzen!</span>
                    </p>
                    <div className={style.formInputCustom}>
                        <label htmlFor="email" className="form-label">Neue Login E-Mail-Adresse</label><br />
                        <input
                            type="text"
                            id="email"
                            name="email"
                            placeholder="Login E-Mail-Adresse"
                            autoComplete="off"
                            onChange={(e) => setNewEmail(e.target.value.trim())}
                        />
                    </div>
                    <Button className={style.BtnCustom} onClick={handleChangeEmail}>Login E-Mail übernehmen</Button>
                </Row>
            </Container>
            }

        
            {statusPWDChange &&
              <Container style={{maxWidth: "500px", marginBottom: "60px", marginTop: "60px"}}>
                <Row style={{justifyContent: "flex-end"}}>
                    <FontAwesomeIcon style={{width:"auto", cursor: "pointer", marginBottom: "10px"}} icon={faXmark} size="2x" onClick={() => { setStatusPWDChange(!statusPWDChange) }} />
                </Row>
                <Row style={{justifyContent: "center"}}>
                    <h2 style={{color: '#520da7', fontSize: "140%"}} className="fw-bold text-center">
                        Passwort ändern
                    </h2>
                    <p className="text-center">Hier kannst du dein neues Passwort für deinen Login festlegen.<br />
                    </p>
                    <div className={style.formInputCustom}>
                        <label htmlFor="password" className="form-label">Neues Passwort</label><br />
                        <input
                            type="password"
                            id="password"
                            name="password"
                            placeholder="********"
                            autoComplete="off"
                            onChange={(e) => setNewPassword(e.target.value.trim())}
                        />
                    </div>
                    <Button className={style.BtnCustom} onClick={handleChangePassword}>Passwort übernehmen</Button>
                </Row>
            </Container>
            }
           

            
            <p className="mt-5">
              Solltest du dein Konto löschen wollen oder sollten
              sich Daten geändert haben wie z.B. bei einem Umzug,
              dann kontaktiere bitte den Kundenservice.
            </p>
            <p>
              Telefon: +4925966148181<br />
              E-Mail: <a href="mailto:myvin@vin1.eu" noreferer="true" nofollow="true">myvin@vin1.eu</a>
            </p>
          </Col>
        </Row>
      </Container>
    </Fragment>
  )
}

export default Kundendaten