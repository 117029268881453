import React from 'react'
import Header from '../Header/Header'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

import { Container, Row, Col } from 'react-bootstrap';

function InformationenDokumente() {

  async function downloadPDF(filepath, filename) {


    fetch(filepath).then(response => {
      response.blob().then(blob => {
          // Creating new object of PDF file
          const fileURL = window.URL.createObjectURL(blob);
          // Setting various property values
          let alink = document.createElement('a');
          alink.href = fileURL;
          alink.download = filename;
          alink.click();
      })
    })
  }



  return (
    <>
      <Header />
      <Container style={{maxWidth: "800px"}}>
        <Row style={{justifyContent: "center"}}>
          <Col>
              <h1>Informationen</h1>
              <p className="text-center">Hier findest alle wichtigen Informationsdokumente zum Download</p>
            

              <p style={{marginTop:"60px"}} className="fw-bold">
                Maklervertrag/-vollmacht
              </p>
              <div style={{}}>
                <div style={{cursor: "pointer"}} onClick={() => downloadPDF("pdf/Maklervertrag+Vollmacht_VIN1.pdf", "Maklervertrag+Vollmacht_VIN1.pdf")}>
                  <span style={{fontSize: "16px", textDecoration: "underline" }}>Download Maklervertrag/-vollmacht (PDF)</span>
                  <FontAwesomeIcon icon={faDownload} size="lg" style={{marginLeft: "10px"}} />
                </div>
              </div>


              <p style={{marginTop:"60px"}} className="fw-bold">
                Erstinformationen
              </p>
              <div style={{}}>
                <div style={{cursor: "pointer"}} onClick={() => downloadPDF("pdf/Erstinformationen_VIN1.pdf", "Erstinformationen_VIN1.pdf")}>
                  <span style={{fontSize: "16px", textDecoration: "underline" }}>Download Erstinformationen (PDF)</span>
                  <FontAwesomeIcon icon={faDownload} size="lg" style={{marginLeft: "10px"}} />
                </div>
              </div>

          </Col>
        </Row>
      </Container>
    </>

  )
}

export default InformationenDokumente