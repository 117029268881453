import React, { useContext } from "react";
import Header from "../Header/Header";


import { Container, Row, Col } from "react-bootstrap";

import CardDoppelt from "../Card/CardDoppelt";
import { UserContext } from "../../store/user-context";


function News() {


  const { unreadDocs } = useContext(UserContext);


  return (
    <>
      <Header url={"/"}/>
      <Container style={{ maxWidth: "800px" }}>

          <Row style={{ marginTop: "16px", justifyContent: "center" }}>
            <Col xs={12} md={12} style={{ marginTop: "20px" }}>
              <CardDoppelt
                url={"dokumente-feed"}
                img={"Newsfeed.png"}
                title={"Newsfeed"}
                shake={unreadDocs.length > 0 ? true : false}
                text={
                  <p>
                    {unreadDocs.length === 0 && (
                      <>
                        Für dich liegen aktuell <b>keine neuen Informationen</b> vor!
                      </>
                    )}
                    {unreadDocs.length === 1 && (
                      <>
                        Für dich liegt <b>eine neue Information</b> vor!
                      </>
                    )}
                    {unreadDocs.length > 1 && (
                      <>
                        Für dich liegen <b>{unreadDocs.length} neue Informationen</b> vor!
                      </>
                    )}
                    <br />
                    Hier kannst du nichts verpassen.
                    Im Newsfeed siehst du neue Dokumente, Informationen und News aus allen Bereichen auf einen Blick.
                    <br />
                    <b>Schaue dir jetzt alle Neuigkeiten an!</b>
                  </p>
                }
                />
              </Col>
            </Row>



            <Row style={{ marginTop: "16px", justifyContent: "center" }}>
          <Col xs={12} md={12} style={{ marginTop: "20px" }}>
            <CardDoppelt
              url={"/dokumente"}
              img={"Dokumente.png"}
              title={"Dokumente"}
              text={
                <p>
                  Hier kannst du deine Dokumente und Verträge einsehen oder
                  deine eigenen Dokumente datenschutzkonform hochladen.
                  <br />
                  <b>Jetzt smart und schnell verwalten!</b>
                </p>
              }
            />
          </Col>
        </Row>


        {/*<Row style={{ marginTop: "16px", justifyContent: "center" }}>
          <Col xs={12} md={12} style={{ marginTop: "20px" }}>
            <CardDoppelt
              url={"newsfeed"}
              img={"News.png"}
              title={"News"}
              text={
                <p>
                  Hier findest du aktuelle Informationen, Neuigkeiten und Sonderaktionen
                  rund um die VIN1 Welt.
                  <br />
                  <b>Jetzt keine Neuigkeiten mehr verpassen!</b>
                </p>
              }
            />
          </Col>
        </Row>
        */}



      </Container>

      <div style={{ marginBottom: "50px" }}></div>
    </>
  );
}

export default News;
