import React, { useState, useContext } from 'react'
import { useLocation } from 'react-router-dom';
import Spinner from '../../Bausteine/Spinner/Spinner';
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import { faCirclePlus, faDownload } from "@fortawesome/free-solid-svg-icons";

import Card from '../../Card/Card'
import CardGesellschaften from '../../Card/CardGesellschaften';
import CardUpload from '../../Card/CardUpload';
import CardSub from '../../Card/CardSub';

import { Container, Row, Col, Button } from 'react-bootstrap';


//import Select, { createFilter } from 'react-select';
import Swal from 'sweetalert2';

import {
  ref,
  uploadBytesResumable,
  getDownloadURL
} from "@firebase/storage";
import { storage } from "../../../firebase";
 

import axios from 'axios';
import { AuthContext } from "../../../store/auth-context";

import { makeID } from '../../../util/makeID';

import SelectInput from '../../Bausteine/SelectInput/SelectInput';

import style from '../../Berater/Berater.module.css'
import VINButton from '../../../VINButton';
import { UserContext } from '../../../store/user-context';


function DateienSenden() {

    const ColorTheme = '#520da7'

    const { user } = useContext(UserContext);  
    const { currentUser } = useContext(AuthContext);

    const category =["Energie", "Versicherungen", "Finanzierung", "Immobilien", "Invest"];
    const [bereich, setBereich] = useState("");
    const [pickedFiles, setPickedFiles] = useState([]);

    const [datenschutz, setDatenschutz] = useState(false);
    const [submittedOK, setSubmittedOK] = useState(false);

    const [spinner, setSpinner] = useState(false);


    function handleFile(e) {
  
          let count = pickedFiles.length;
          let data = [];
  
          Array.from(e.target.files).forEach(file => {
          
              if(count < 10) {
                  data = [...data, {
                      id: makeID(8),
                      file: file,
                      name: file.name,
                      size: file.size,
                      type: file.type
                    }]
                  count = count + 1;
              }
              else
              {
                  Swal.fire({
                      "title": "Anzahl Dateien",
                      "html": "Du kannst hier nur bis zu 10 Dateien pro Vorgang übermitteln. Die zu viel ausgewählten Dokumente wurden nicht hinzugefügt.",
                      "icon": "info"
                  });
              }
          });
  
          setPickedFiles([...pickedFiles, ...data]);
      }
  
  
    function removeFileUploadHandler (id) {
      //console.log("remove: " + name);
      setPickedFiles(pickedFiles.filter(obj => obj.id !== id));
    }
  
  
    const handleSubmit = async () => {
      
      //console.log("KdNr: "+user.kundennummer);
      //console.log("Bereich: "+bereich);
      //console.log("Dateien: "+pickedFiles.length);
  
      if(pickedFiles.length === 0 || bereich === "" || datenschutz === false) {
        Swal.fire({
          title: "Pflichtfelder",
          html: "Bitte fülle zunächst alle Felder aus und sende das Formular erneut ab.<br />Beachte, dass du alle Schritte befolgt hast.",
          icon: "info",
        });
        return;
      }
  
  
      if(user.kundennummer === undefined || user.kundennummer === "") {
        Swal.fire({
          title: "Kundennummer",
          html: "Damit du dieses Formular absenden kannst, benötigst du eine Kundennummer. Wenn du dich nicht gerade registriert hast, wende dich an den Support.",
          icon: "info",
        });
        return;
      }
  
      //Spinner
      setSpinner(true);
  
      const uploadPromises = pickedFiles.map((file) => {
        return new Promise((resolve, reject) => {
          let UUID = crypto.randomUUID();
          const storageRef = ref(
            storage,
            "/BeraterConnect/" + currentUser.uid + "/" + UUID + file.name
          );
          const uploadTask = uploadBytesResumable(storageRef, file.file);
      
          uploadTask.on(
            "state_changed",
            (snapshot) => {
              // Track upload progress if needed
            },
            (error) => {
              reject(error);
            },
            () => {
              getDownloadURL(uploadTask.snapshot.ref)
                .then((url) => {
                  const fileDetails = {
                    uid: currentUser.uid,
                    title: UUID + file.name,
                    category: category,
                    created: new Date(),
                    mimeType: file.type,
                    size: file.size,
                    url: url,
                  };
                  resolve(fileDetails);
                })
                .catch((error) => {
                  reject(error);
                });
            }
          );
        });
      });
      
  
      Promise.all(uploadPromises)
        .then((uploadedFiles) => {
          // All files have been uploaded successfully
  
            //Create Task with Public URLs
            axios({
              method: 'post',
              headers: {
                'Authorization': 'Bearer ' + currentUser.uid
              },
              url: process.env.REACT_APP_BACKEND+"/task/berater-connect-dateien",
              data: {
                kdnr: user.kundennummer,
                bereich: bereich,
                dateien: [...uploadedFiles]
              }
            })
            .then(doc => {
              if(doc) {
                Swal.fire({
                  title: "Erfolg",
                  html: "Deine Daten wurden erfolgreich an uns übermittelt.<br />Dein VIN1 Berater wird Kontakt zu dir aufnehmen.",
                  icon: "success",
                });
              }
            })
            .catch(err => {
              console.log("Error -1 uploading files: " + err);
        
              Swal.fire({
                title: "Fehler",
                html: "Es ist ein Fehler bei der Übertragung aufgetreten. Versuche es später erneut.",
                icon: "error"
              })  
            });
  
            //Clean Felder
            setSpinner(false);
  
            setBereich("");
            setPickedFiles([]);
            setDatenschutz(false);
        })
        .catch(err => {
          console.log("Error -2 uploading files: " + err);
          Swal.fire({
            title: "Fehler",
            html: "Es ist ein Fehler bei der Übertragung aufgetreten. Versuche es später erneut.",
            icon: "error"
          })  
  
          setSpinner(false);
  
        });
    }




    return (
        <Row className="mt-5 justify-content-center" style={{marginBottom: "160px"}} >
            <Col style={{textAlign: "center"}}>

                <img src={require("../../../img/BeraterConnect.png")} alt="" style={{maxWidth: "160px"}} />
                <h5>Dateien senden</h5>
                

                <Row style={{ justifyContent: "center" }}>               

                    <p className="text-center mt-4"><span className="fw-bold">1.</span><br />
                    Wähle den Bereich (anklicken) aus, an den du Dokumente übermitteln willst.</p>

                    {category.map((ausgewBereich) => 
                        <Col
                            key={ausgewBereich}
                            xs={12} md={3}
                            style={{marginBottom: "20px"}}
                            onClick={() => (setBereich(ausgewBereich))}
                        >
                            {(bereich === ausgewBereich) && (
                            <CardSub key={ausgewBereich} title={ausgewBereich} selected={1} color={ColorTheme} />
                            )}
                            {(bereich !== ausgewBereich) && (
                            <CardSub key={ausgewBereich} title={ausgewBereich} />
                            )}
                        </Col>
                    )}
            

                </Row>

                

                {bereich &&
                    <>
                    <p className="text-center mt-4"><span className="fw-bold">2.</span><br />
                        Lade deine Daten hoch<br />
                        <span style={{fontSize: "90%"}}>(erlaubte Dateien: .pdf .jpg .png | max. 10 Dateien)</span>
                    </p>



                    {pickedFiles.length === 0 && <p className="text-center">Du hast noch keine Dokumente ausgewählt.</p>}

                        {pickedFiles.map(file => 
                            <CardUpload key={file.id} data={file} removeFileUploadHandler={removeFileUploadHandler} />
                        )}
                        {pickedFiles.length !== 0 && 
                        <p className="text-center"> Füge weitere Dokumente durch erneutes klicken auf den Button hinzu.</p>
                        }


                        <label
                            htmlFor="fileUpload"
                            className="filePicker"
                        >
                            Dokumente auswählen
                        <input
                            id="fileUpload"
                            type="file"
                            multiple
                            accept="application/pdf, image/png, image/jpeg"
                            style={{ display: "none" }}
                            onChange={handleFile}
                        />
                    </label>

                    <br />
                    <br />
                    <br />


                    <label className="form-check-label" style={{marginTop: "20px"}}>
                        <input
                            id="cbTippgeber"
                            className="form-check-input"
                            type="checkbox"
                            checked={datenschutz}
                            onChange={(e) => setDatenschutz(e.currentTarget.checked)}
                        />   
                        <span style={{paddingLeft: "10px"}}>Ich stimme den <a href="/datenschutz" target="_blank">Datenschutzbedingungen</a> zu.</span>
                    </label>


                    <br />
                    <br />
                    <br />

            
                    {/*<div style={{display: "flex", justifyContent: "center"}}>
                        <Button
                        style={{ background: ColorTheme, padding: "15px", width: "auto", color: "white", fontWeight: "bold", borderRadius: "8px", cursor: "pointer" }}
                        onClick={handleSubmit}
                        >Absenden</Button>
                    </div>*/}

                    <div className="pointer" onClick={handleSubmit}>
                      <VINButton text={"Absenden"} />
                    </div>

                    </>
                }

                    {submittedOK && <p className="text-center fw-bold" style={{marginTop: "10px", color: "#1f8139"}}>Du hast das Formular erfolgreich an uns übermittelt.</p>}          

            {spinner &&
                <Spinner message={"Bitte warten. Daten werden verarbeitet."} />
            }
        </Col>
    </Row>
  )
}

export default DateienSenden;


  
    /*
      //configure axios header
      const headerConfig = { headers: { 'Content-Type': 'multipart/form-data' } };

      //Formular an uns übertragen
      var FilesData = new FormData();
      FilesData.append('kundennummer', location.state.kundennummer);
      FilesData.append('bereich', bereich);

      let i = 0;
      pickedFiles.forEach(file => {
        FilesData.append("dateien["+i+"]", file.file);
        i = i+1;
      })

      let res = "";

      try {   
        // CSV File
        res = await axios({
            method: 'post',
            url: "https://api.mariusschulte.com/crm/beraterConnect.php",
            data: FilesData,
            headerConfig
        })

        //Create Task CRM
        await axios({
          method: 'post',
          headers: {
            'Authorization': 'Bearer ' + currentUser.uid
          },
          url: process.env.REACT_APP_BACKEND+"/task/berater-connect",
          data: {
            kdnr: location.state.kundennummer,
            vorname: location.state.vorname,
            nachname: location.state.nachname,
            bereich: bereich
          }
        })
        
          Swal.fire({
            title: "Erfolg",
            html: "Deine Daten wurden erfolgreich an uns übermittelt.<br />Dein VIN1 Berater wird Kontakt zu dir aufnehmen.",
            icon: "success",
          });

        } 
        catch(err) {
          console.log(err);

            Swal.fire({
              title: "Fehler",
              html: "Es ist ein Fehler bei der Übertragung aufgetreten. Versuche es später erneut.",
              icon: "error"
            });
        }
      */

      /*
      try {
        // Send Mail
        var bodyFormData2 = new FormData();
        bodyFormData2.append('kundennummer', location.state.kundennummer);
        bodyFormData2.append('email', location.state.email);
        bodyFormData2.append('templateId', 4643);

        axios({
            method: 'post',
            url: "https://api.mariusschulte.com/crm/sendMail.php",
            data: bodyFormData2
        });

        //console.log("Mail versendet CRM")
      } 
      catch(err) {
      
      }
      */

      /*
      setMeineGesellschaften([
          {
              ID: makeid(15),
              Versicherung: versicherung.value,
              Übermittelt: getUploadDate()
          }, ...meineGesellschaften
      ]); 
      */


   //handleSubmit Ende





  /*
  const getUploadDate = () => {
    var date=new Date();
    let day=date.getDate();
    let month=date.getMonth();
    month=month+1;

    if((String(day)).length===1)
      day='0'+day;
    if((String(month)).length===1)
      month='0'+month;

    let hours = date.getHours();
    let minutes = date.getMinutes();
    let seconds = date.getSeconds();

    if((String(hours)).length===1)
      hours='0'+hours;
    if((String(minutes)).length===1)
      minutes='0'+minutes;
    if((String(seconds)).length===1)
      seconds='0'+seconds;

    let dateT=day+ '.' + month + '.' + date.getFullYear() + ' '+ hours+':'+minutes+':'+seconds;
    //dateT=String(dateT);
    return dateT;
  }

  const makeid = (length) => {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}




  async function downloadPDF(filepath, filename) {

    fetch(filepath).then(response => {
      response.blob().then(blob => {
          // Creating new object of PDF file
          const fileURL = window.URL.createObjectURL(blob);
          // Setting various property values
          let alink = document.createElement('a');
          alink.href = fileURL;
          alink.download = filename;
          alink.click();
      })
    })
  }


  function handleFileUploadPDF(filepath, filename) {


    let UUID = crypto.randomUUID();
    if (filepath) {
      const storageRef = ref(
        storage,
        "/" + auth.currentUser.uid + "/" + UUID + filename
      );

      var url = "pdf/Maklervertrag+Vollmacht_VIN1.pdf";

      //You may not need this part if you have the PDF data locally already
      var xhr = new XMLHttpRequest();
      xhr.onreadystatechange = function () {
          if (this.readyState === 4 && this.status === 200) {
              //console.log(this.response, typeof this.response);
              //now convert your Blob from the response into a File and give it a name
              var fileOfBlob = new File([this.response], 'your_file.pdf');

        const uploadTask = uploadBytesResumable(storageRef, fileOfBlob);

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            //console.log(snapshot);
          },
          (err) => console.log(err),
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((url) => {

                addDoc(collection(db, "Users", auth.currentUser.uid, "Privat"), {
                  title: UUID + filename,
                  description: "",
                  uploader: "app",
                  category: "Ich",
                  sub_category: "Vollmacht",
                  created: new Date(),
                  mimeType: "application/pdf",
                  size: fileOfBlob.size,
                  url: url,
                });          
            });
          }
        );
      }
    }
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();  

  } //filepath
}
*/
  
  

  