import React from "react";
import QRCode from "qrcode.react";
import Button from "react-bootstrap/Button";

class Component extends React.Component {
  constructor(props) {
  super(props);
  this.download = this.download.bind(this);
};

download() {
    const canvas = document.querySelector('.HpQrcode > canvas');
    const link = document.createElement("a");
    link.href = canvas.toDataURL();
    link.download = "QRCode_VIN1.png";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};  

render() {
    return (
         <div className="d-flex align-items-center justify-content-center flex-column">
	        <div className="HpQrcode my-2 position-relative">
                 <QRCode 
                      value={"https://my.vin1.eu/register?partner="+this.props.partner}
                      id="QRCode-svg"
                      size={250} 
                  />
            </div> 
        	<Button 
                  className="btn-success border-0 rounded w-50 py-2 text-center my-2 d-flex align-items-center justify-content-center align-self-center"
                  onClick={this.download}
            >
                 Download QR-Code
             </Button>    
         </div>
    );
};
};
export default Component;