import React, { useState, useEffect } from "react";
import { HeaderWithoutAuth } from "../Header/Header";
import Spinner from "../Bausteine/Spinner/Spinner";
import { useNavigate, useSearchParams, Link } from "react-router-dom";

import axios from "axios";


import { Container, Row, Col, Button } from "react-bootstrap";

import style from "./Login.module.css";

import Swal from "sweetalert2";

import {
  getAuth,
  createUserWithEmailAndPassword
} from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../../firebase";
//import Datenschutz from "../Datenschutz/Datenschutz";

function Register(props) {

  const [searchParams] = useSearchParams();
  let navigate = useNavigate();


  useEffect(() => {

    if (searchParams.get("partner") !== null) {
      setSponsor(searchParams.get("partner"));
    }

    if (searchParams.get("standort") !== null) {
      setStandort(searchParams.get("standort"));
    }

    // noch gebraucht?
    const extractTokenFromURL = () => {
      if (searchParams.get("token") !== null) {
          setToken(searchParams.get("token"));
      }
    }

    extractTokenFromURL();

  },[searchParams])




  const [spinner, setSpinner] = useState(false);

  // Regex
  const regexTelefon =
    "/^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}$/im";

  // Kunde
  const [anrede, setAnrede] = useState("Herr");
  const [firma, setFirma] = useState("");
  const [vorname, setVorname] = useState("");
  const [nachname, setNachname] = useState("");
  const [strasse, setStrasse] = useState("");
  const [hausnr, setHausnr] = useState("");
  const [plz, setPLZ] = useState("");
  const [ort, setOrt] = useState("");
  const [land, setLand] = useState("");
  const [telefon, setTelefon] = useState("");


  //Hilfs-States Problem iOS Kalender DatePicker
  const [tagGeburtstag, setTagGeburtstag] = useState("");
  const [monatGeburtstag, setMonatGeburtstag] = useState("");
  const [jahrGeburtstag, setJahrGeburtstag] = useState("");
  //const [geburtsdatum, setGeburtsdatum] = useState("");

  const [beratung, setBeratung] = useState(false);
  const [erreichbar1, setErreichbar1] = useState(false);
  const [erreichbar2, setErreichbar2] = useState(false);
  const [erreichbar3, setErreichbar3] = useState(false);
  const [sponsor, setSponsor] = useState("");
  const [sponsorDisabled, setSponsorDisabled] = useState(false);
  const [partnerprogramm, setPartnerprogramm] = useState(false);
  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [loginPassword2, setLoginPassword2] = useState("");
  const [cbDatenschutz, setCbDatenschutz] = useState("");

  //"Exklusivmakler"
  const [standort, setStandort] = useState("");

  //Token für Register Bestandskunden
  const [token, setToken] = useState();



/*
  useEffect(() => {
    extractSponsorFromURL();
    extractTokenFromURL();
  }, []);

  const extractSponsorFromURL = () => {
    
        // Partnernummer   .../#partner=108112  
        const url = window.location; // komplette URL '/#partner=123';
        try {
            console.log(new URL(url).hash.split('&'));

            const token = new URL(url).hash.split('&').filter(function(el) {
                if (el.match('partner') !== null) return true;
            })[0].split('=')[1];

            setSponsor(token);
            setSponsorDisabled(!sponsorDisabled);
            
        } catch (e) {}
        */

    //Schema: example.com/register?partner=123456
    //123456
    /*
    if (urlParamSponsor.get("partner") !== null) {
      setSponsor(urlParamSponsor.get("partner"));
      setSponsorDisabled(!sponsorDisabled);
    }

    if (urlParamStandort.get("standort") !== null) {
      setStandort(urlParamStandort.get("standort"));
      //setSponsorDisabled(!sponsorDisabled);
    }
    
  };


  





  // ---------------------------------------------------------------------------------------------------------------------------------------
  // ---------------------------------------------------------------------------------------------------------------------------------------

  const handleLogin = (email, password) => {
    const auth = getAuth();
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;

        //set App.js Prop
        props.setUser(user);
        //console.log(user);
        navigate("/");
      })
      .catch((error) => {
        //const errorCode = error.code;
        //const errorMessage = error.message;
        Swal.fire({
          title: "Fehler beim Login",
          text: "Benutzername oder Passwort falsch.",
          icon: "info",
          confirmButtonText: "Ok",
        });
      });
  };

  /*
// Update CRM
const getSponsor = async (ID) => {
    var SponsorForm = new FormData();
    SponsorForm.append("ID", ID)
    //Kundennummer basiert auf der Tipp_ID abrufen
    let response =  await axios({
        method: 'post',
        url: "https://api.mariusschulte.com/crm/getKdNr.php",
        data: SponsorForm
    });

    return response.data.KdNr
} */


    
 
  // ---------------------------------------------------------------------------------------------------------------------------------------
  // ---------------------------------------------------------------------------------------------------------------------------------------

  const handleNeukundeRegister = async () => {


    if( !isNaN(+tagGeburtstag) || !isNaN(+monatGeburtstag) || !isNaN(+jahrGeburtstag)) {
      Swal.fire({
        title: "Geburtsdatum",
        text: "Das eingegebene Geburtsdatum ist nicht gültig. Du darfst nur Zahlen verwenden.",
        icon: "info",
        confirmButtonText: "Ok",
      });
      return;
    }

    if(jahrGeburtstag.length !== 4) {
      Swal.fire({
        title: "Geburtsdatum",
        text: "Bitte gib das Jahr deines Geburtstages vollständig an und sende das Formular erneut ab.",
        icon: "info",
        confirmButtonText: "Ok",
      });
      return;
    }

    if(tagGeburtstag < 1 || tagGeburtstag > 31 || monatGeburtstag < 1 || monatGeburtstag < 12 || jahrGeburtstag < 1920 || jahrGeburtstag > new Date().getFullYear() - 17) {
      Swal.fire({
        title: "Geburtsdatum",
        text: "Das eingegebene Geburtsdatum ist nicht gültig. Korriere diese und sende das Formular erneut ab. ",
        icon: "info",
        confirmButtonText: "Ok",
      });
      return;
    }


    let geburtsdatum = jahrGeburtstag+"-"+monatGeburtstag+"-"+tagGeburtstag;

    // Pflichtfelder ausgefüllt
    if (
      vorname !== "" &&
      nachname !== "" &&
      strasse !== "" &&
      hausnr !== "" &&
      plz !== "" &&
      ort !== "" &&
      telefon !== "" &&
      geburtsdatum !== "" &&
      cbDatenschutz &&
      loginEmail !== "" &&
      loginPassword !== "" &&
      loginPassword2 !== ""
    ) {
      // App Login erstellen
      if (loginEmail !== "" && loginPassword !== "") {
        if (loginPassword.length < 8) {
          Swal.fire({
            title: "Passwort",
            text: "Das eingegebene Passwort muss min. 8 Zeichen lang sein.",
            icon: "info",
            confirmButtonText: "Ok",
          });
          return;
        }
        if (loginPassword2 !== loginPassword) {
          Swal.fire({
            title: "Passwörter",
            text: "Die eingegbenen Passwörter stimmen nicht überein",
            icon: "error",
            confirmButtonText: "Ok",
          });
          return;
        }

        //Spinner
        setSpinner(true);

        // Alle Bedingungen erfüllt
        async function createAcc() {
          try {
            const auth = getAuth();
            const account = await createUserWithEmailAndPassword(
              auth,
              loginEmail,
              loginPassword
            );

            let erreichbar = [];

            if (erreichbar1 !== "" && erreichbar1 === true) {
              erreichbar.push("morgens");
            }
            if (erreichbar2 !== "" && erreichbar2 === true) {
              erreichbar.push("mittags");
            }
            if (erreichbar3 !== "" && erreichbar3 === true) {
              erreichbar.push("abends");
            }

            //create user db
            // nur db, crm aktuell weg

            // PHP-Teil
            let res2 = await axios.post(
              "https://api.mariusschulte.com/crm/register.php",
              {
                UID: account.user.uid,
                Anrede: anrede,
                Firma: firma,
                Vorname: vorname,
                Nachname: nachname,
                Strasse: strasse,
                Hausnummer: hausnr,
                PLZ: plz,
                Ort: ort,
                EMail: loginEmail,
                Telefon: telefon,
                Geburtsdatum: geburtsdatum,
                Sponsor: sponsor,
                Standort: standort,
                Partnerprogramm: partnerprogramm,
                Beratung: beratung,
                Erreichbar1: erreichbar1,
                Erreichbar2: erreichbar2,
                Erreichbar3: erreichbar3,
              }
            );

            let res = await axios({
              method: "post",
              headers: {
                'Authorization': 'Bearer ' + account.user.uid
              },
              url: process.env.REACT_APP_BACKEND + "/my-vin1/create",
              data: {
                googleuid: account.user.uid, //googleuid
                anrede: anrede, //anrede
                firma: firma, //firma
                vorname: vorname, //vorname
                nachname: nachname, //nachname
                anschrift: strasse + " " + hausnr,
                plz: plz, //plz
                stadt: ort, //stadt
                land: land, //land
                email: loginEmail, //email
                telefon: telefon, //telefon
                geburtsdatum: geburtsdatum, //geburtsdatum
                sponsor: sponsor, //sponsor (number)
                standort: standort, //standort (="betreuuender Makler")
                tippgeber: partnerprogramm, //tippgeber
                //Beratung: beratung, // => Aufgabe erzeugen...
                //erreichbar: erreichbar,
                erreichbar: erreichbar,
              }
            });

            if(beratung) {
              //Create Task CRM
              await axios({
                method: 'post',
                headers: {
                  'Authorization': 'Bearer ' + account.user.uid
                },
                url: process.env.REACT_APP_BACKEND+"/my-vin1/erstberatung",
                data: {
                  kdnr: res.data.kdnr,
                  vorname: vorname,
                  nachname: nachname,
                  erreichbar: erreichbar.toString()
                }
              })
            }

            //Kundendaten anlegen
            const userMeta = {
              kundennummer: res.data.kdnr,
              anrede: anrede,
              firma: firma,
              vorname: vorname,
              nachname: nachname,
              geburtsdatum: geburtsdatum,
              email: loginEmail,
              telefon: telefon,
              strasse: strasse + " " + hausnr,
              plz: plz,
              ort: ort,
              land: land,
              sponsor: sponsor,
              standort: standort,
              verifiziert: "0",
              created: new Date(), //new Date().toISOString() = "YYYY-MM-DDTHH:MM:SS.MMMZ"
            };

            // Set App User Data

            setDoc(doc(db, "Users", account.user.uid), userMeta)
              .then((DocRef) => {
                //console.log("Document has been added successfully");
              })
              .catch((error) => {
                //console.log(error.message);
              });

            setBeratung(false);
            setErreichbar1(false);
            setErreichbar2(false);
            setErreichbar3(false);
            setPartnerprogramm(false);
            setCbDatenschutz(false);

            const user = account.user;
            //set App.js Prop
            props.setUser(user);
            //console.log(user);
            navigate("/");

            setSpinner(false);

            Swal.fire({
              title: "Registrierung",
              text: "Deine Registrierung war erfolgreich. Du bist automatisch in deinen Account eingeloggt worden.",
              icon: "success",
              confirmButtonText: "Ok",
            });
          } catch (error) {
            if (error.code == "auth/email-already-in-use") {
              setSpinner(false);
              Swal.fire({
                title: "Account",
                text: "Die eingegbenen E-Mail Adresse ist bereits mit einem App-Account verknüpft. Verwende die Passwort vergessen Option.",
                icon: "info",
                confirmButtonText: "Ok",
              });
            } else if (error.code == "auth/invalid-email") {
              setSpinner(false);
              Swal.fire({
                title: "Account",
                text: "Die eingegbenen E-Mail Adresse nicht gültig.",
                icon: "info",
                confirmButtonText: "Ok",
              });
            } else {
              setSpinner(false);
              Swal.fire({
                title: "Account",
                text: "Es ist ein unbekannter Fehler aufgetreten. Bitte versuche es später erneut.",
                icon: "info",
                confirmButtonText: "Ok",
              });
            }
          }
        }

        const account = await createAcc();
        setSpinner(false);
      }
    } else {
      Swal.fire({
        title: "Pflichtfelder",
        text: "Bitte fülle alle mit * markierten Pflichtfelder aus.",
        icon: "info",
        confirmButtonText: "Ok",
      });
    }
  };

  // ---------------------------------------------------------------------------------------------------------------------------------------
  // ---------------------------------------------------------------------------------------------------------------------------------------

  return (
    <>
      <HeaderWithoutAuth />

      <Container style={{ maxWidth: "800px" }}>
        <Row style={{ justifyContent: "center" }}>
          <Col>
            <h1 style={{ marginBottom: "30px" }}>Registrierung</h1>
          </Col>
        </Row>
      </Container>
      <Container style={{ maxWidth: "650px", marginBottom: "180px" }}>
        <Row style={{ justifyContent: "center" }}>
          <h2
            style={{ color: "#520da7", fontSize: "140%" }}
            className="fw-bold text-center"
          >
            Jetzt kostenlos registrieren.
          </h2>
          <p className="text-center">
            Vintastisch, dass du dabei sein möchtest.
            <br />
            Für deine Registrierung benötigen wir ein paar Daten von dir.
          </p>
        </Row>


        {/*<p>Standort: {standort}</p>*/}
  
          <Row style={{ justifyContent: "center", marginBottom: "80px", marginTop: "50px" }}>
            <span>* Pflichtfeld</span>

            <div className={style.formInputCustom}>
              <label className="form-label">Anrede</label>
              <br />
              <select
                className={style.DropdownSelection}
                onChange={(e) => setAnrede(e.target.value)}
                value={anrede}
                style={{marginLeft: "10px"}}
              >

                {firma.length == 0 &&
                  <>
                    <option value="Herr">Herr</option>
                    <option value="Frau">Frau</option>
                  </>
                }
                
                <option value="Firma">Firma</option>
              </select>
            </div>



            <div
              className={style.formInputCustom}
              style={{ marginTop: "20px" }}
            >
              <label htmlFor="firma" className="form-label">
                Firma (optional)
              </label>
              <br />
              <input
                type="text"
                id="firma"
                name="firma"
                placeholder="Firma"
                autoComplete="off"
                value={firma}
                onChange={(e) => {
                  setFirma(e.target.value);
                  setAnrede("Firma");
                }}
              />
            </div>

            <div
              className={style.formInputCustom}
              style={{ marginTop: "20px" }}
            >
              <label htmlFor="vorname" className="form-label">
                Vorname*
              </label>
              <br />
              <input
                type="text"
                id="vorname"
                name="vorname"
                placeholder="Vorname"
                autoComplete="on"
                value={vorname}
                onChange={(e) => setVorname(e.target.value.trim())}
              />
            </div>

            <div
              className={style.formInputCustom}
              style={{ marginTop: "20px" }}
            >
              <label htmlFor="nachname" className="form-label">
                Nachname*
              </label>
              <br />
              <input
                type="text"
                id="nachname"
                name="nachname"
                placeholder="Nachname"
                autoComplete="on"
                value={nachname}
                onChange={(e) => setNachname(e.target.value.trim())}
              />
            </div>

            <div
              className={style.formInputCustom}
              style={{ marginTop: "20px" }}
            >
              <label htmlFor="strasse" className="form-label">
                Strasse*
              </label>
              <br />
              <input
                type="text"
                id="strasse"
                name="strasse"
                placeholder="Straße"
                autoComplete="on"
                value={strasse}
                onChange={(e) => setStrasse(e.target.value)}
              />
            </div>

            <div
              className={style.formInputCustom}
              style={{ marginTop: "20px" }}
            >
              <label htmlFor="hausnr" className="form-label">
                Hausnummer*
              </label>
              <br />
              <input
                type="text"
                id="hausnr"
                name="hausnr"
                placeholder="Hausnr"
                inputMode="text"
                autoComplete="on"
                value={hausnr}
                onChange={(e) => setHausnr(e.target.value.trim())}
              />
            </div>

            <div
              className={style.formInputCustom}
              style={{ marginTop: "20px" }}
            >
              <label htmlFor="plz" className="form-label">
                PLZ*
              </label>
              <br />
              <input
                type="text"
                id="plz"
                name="plz"
                placeholder="PLZ"
                autoComplete="on"
                inputMode="numeric"
                value={plz}
                onChange={(e) => {
                  const re = /^[0-9\b]+$/;
                  if (e.target.value === "" || re.test(e.target.value)) {
                    setPLZ(e.target.value.trim());
                  }
                }}
              />
            </div>

            <div
              className={style.formInputCustom}
              style={{ marginTop: "20px" }}
            >
              <label htmlFor="ort" className="form-label">
                Ort*
              </label>
              <br />
              <input
                type="text"
                id="ort"
                name="ort"
                placeholder="Ort"
                autoComplete="on"
                value={ort}
                onChange={(e) => setOrt(e.target.value)}
              />
            </div>

            <div
              className={style.formInputCustom}
              style={{ marginTop: "20px" }}
            >
              <label htmlFor="land" className="form-label">
                Land*
              </label>
              <br />
              <select
                className={style.DropdownSelection}
                onChange={(e) => setLand(e.target.value)}
                value={land}
                style={{marginLeft: "10px"}}
              >
                <option value="Deutschland">Deutschland</option>
                <option value="Österreich">Österreich</option>
                <option value="Schweiz">Schweiz</option>
              </select>
            </div>

            <div
              className={style.formInputCustom}
              style={{ marginTop: "20px" }}
            >
              <label htmlFor="telefon" className="form-label">
                Telefon/Mobil*
              </label>
              <br />
              <input
                type="text"
                id="telefon"
                name="telefon"
                placeholder="Telefon/Mobil"
                inputMode="tel"
                autoComplete="on"
                value={telefon}
                onChange={(e) => setTelefon(e.target.value.trim())}
              />
            </div>

            <div
              className={style.formInputCustom}
              style={{ marginTop: "20px" }}
            >

              <label htmlFor="geburtsdatum" className="form-label">
                Geburtsdatum*
              </label>
              <br />
              {/*
              <input
                type="date"
                id="geburtsdatum"
                name="geburtsdatum"
                placeholder="Geburtsdatum"
                autoComplete="on"
                value={geburtsdatum}
                onChange={(e) => setGeburtsdatum(e.target.value.trim())}
              />*/}

              <div style={{ display: "inline-flex"}}>
                <input
                  type="text"
                  id="tag"
                  name="tag"
                  placeholder="TT"
                  style={{display: "inline-block", width: "3em"}}
                  inputMode="numeric"
                  maxLength={2}
                  required
                  value={tagGeburtstag}
                  onChange={(e) => setTagGeburtstag(e.target.value.replace(/[^\d]/g, ""))}
                />
                . 
                <input
                  type="text" 
                  id="monat"
                  name="monat"
                  placeholder="MM"
                  style={{display: "inline-block", width: "3em"}}
                  inputMode="numeric"
                  maxLength={2}
                  required
                  value={monatGeburtstag}
                  onChange={(e) => setMonatGeburtstag(e.target.value.replace(/[^\d]/g, ""))}
                />
                .
                <input
                  type="text"
                  id="jahr"
                  name="jahr"
                  placeholder="JJJJ"
                  style={{display: "inline-block", width: "4em"}}
                  inputMode="numeric"
                  maxLength={4}
                  required
                  value={jahrGeburtstag}
                  onChange={(e) => setJahrGeburtstag(e.target.value.replace(/[^\d]/g, ""))}
                />
              
              </div>
            </div>

            <label className="form-check-label" style={{ margin: "30px 0px 10px 0px" }}>
              <input
                id="cbBeratung"
                className="form-check-input"
                type="checkbox"
                checked={beratung}
                onChange={() => setBeratung(!beratung)}
              />

              <span style={{ paddingLeft: "10px" }}>
                Ich möchte direkt beraten werden.
              </span>
            </label>

            {beratung && (
              <>
                <p className="mt-4 text-center fw-bold">
                  Wann bist du zu erreichen?*
                </p>
                <label
                  className="form-check-label"
                  style={{ marginTop: "20px" }}
                >
                  <input
                    id="cbErreichbar1"
                    className="form-check-input"
                    type="checkbox"
                    checked={erreichbar1}
                    onChange={() => setErreichbar1(!erreichbar1)}
                  />

                  <span style={{ paddingLeft: "10px" }}>Morgens</span>
                </label>

                <label
                  className="form-check-label"
                  style={{ marginTop: "20px" }}
                >
                  <input
                    id="cbErreichbar2"
                    className="form-check-input"
                    type="checkbox"
                    checked={erreichbar2}
                    onChange={() => setErreichbar2(!erreichbar2)}
                  />

                  <span style={{ paddingLeft: "10px" }}>Mittags</span>
                </label>

                <label
                  className="mb-3 form-check-label"
                  style={{ marginTop: "20px" }}
                >
                  <input
                    id="cbErreichbar3"
                    className="form-check-input"
                    type="checkbox"
                    checked={erreichbar3}
                    onChange={() => setErreichbar3(!erreichbar3)}
                  />

                  <span style={{ paddingLeft: "10px" }}>Abends</span>
                </label>
              </>
            )}

            <div
              className={style.formInputCustom}
              style={{ marginTop: "20px" }}
            >
              <label htmlFor="tippgebernummer" className="form-label">
                Tippgebernummer / Sponsor-ID
              </label>
              <br />
              <input
                type="text"
                id="tippgebernummer"
                name="tippgebernummer"
                placeholder="Tippgebernummer / Sponsor-ID"
                autoComplete="off"
                inputMode="numeric"
                disabled={sponsorDisabled}
                value={sponsor}
                onChange={(e) => setSponsor(e.target.value.trim())}
              />
            </div>

            <label className="form-check-label" style={{ margin: "40px 0px 0px 0px" }}>
              <input
                id="cbPartnerprogramm"
                className="form-check-input"
                type="checkbox"
                checked={partnerprogramm}
                onChange={() => setPartnerprogramm(!partnerprogramm)}
              />

              <span style={{ paddingLeft: "10px" }}>
                Ich habe Interesse an einem Partnerprogramm teilzunehmen.
              </span>
            </label>

            <p
              className="text-center fw-bold"
              style={{ fontSize: "120%", marginTop: "40px" }}
            >
              Login-Daten für dein VIN1-Konto festlegen
            </p>

            <div
              className={style.formInputCustom}
              style={{ marginTop: "20px" }}
            >
              <label htmlFor="EmailLogin" className="form-label">
                E-Mail (für Kundenkonto + Login)*
              </label>
              <br />
              <input
                type="text"
                id="EmailLogin"
                name="EmailLogin"
                inputMode="email"
                placeholder="user@example.com"
                autoComplete="off"
                value={loginEmail}
                onChange={(e) => setLoginEmail(e.target.value.trim())}
              />
            </div>

            <div
              className={style.formInputCustom}
              style={{ marginTop: "20px" }}
            >
              <label htmlFor="password1" className="form-label">
                Passwort (min. 8 Zeichen)*
              </label>
              <br />
              <input
                type="password"
                id="password1"
                name="password1"
                placeholder="********"
                autoComplete="off"
                value={loginPassword}
                onChange={(e) => setLoginPassword(e.target.value.trim())}
              />
            </div>

            <div
              className={style.formInputCustom}
              style={{ marginTop: "20px" }}
            >
              <label htmlFor="password2" className="form-label">
                Passwort widerholen*
              </label>
              <br />
              <input
                type="password"
                id="password2"
                name="password2"
                placeholder="********"
                autoComplete="off"
                value={loginPassword2}
                onChange={(e) => setLoginPassword2(e.target.value.trim())}
              />
            </div>

            <label className="form-check-label" style={{ marginTop: "40px" }}>
              <input
                id="cbDatenschutzKunde"
                className="form-check-input"
                type="checkbox"
                checked={cbDatenschutz}
                onChange={() => setCbDatenschutz(!cbDatenschutz)}
              />

              <span style={{ paddingLeft: "10px" }}>
                Ich stimme den{" "}
                <Link to="/datenschutz" target="_blank">
                  Datenschutzerklärungen
                </Link>{" "}
                und der Speicherung meiner Daten zum Zweck der Kontaktaufnahme
                zu.*
              </span>
            </label>

            <Button
              className={style.BtnCustom}
              onClick={handleNeukundeRegister}
            >
              Senden
            </Button>
          </Row>
        
      </Container>

      {spinner && (
        <Spinner message={"Dein Account wird gerade eingerichtet."} />
      )}
    </>
  );
}

export default Register;
