
import React, { useContext, useEffect, useState } from 'react';
import QrReader from 'react-web-qr-reader';

import { Button }  from "react-bootstrap";
import axios from 'axios';
import Swal from 'sweetalert2';
import { AuthContext } from '../../store/auth-context';

const QR = (props) => {

  const { currentUser } = useContext(AuthContext);
  
  const previewStyle = {
    height: 240,
    width: 320,
  };

  const [result, setResult] = useState('No result');
  const [facing, setFacing] = useState('environment');

  const [delay, setDelay] = useState(500);
  const [scanner, setScanner] = useState(true);

  const handleScan = (result) => {
    
    if (result) {

      //filter complete JS Array of Objects
      //let exists = props.sheetData.some(item => result.chunks[0].text === item.ID);

      //setResult(result);
      //console.log(result);

      axios({
        method: "post",
        headers: {
          'Authorization': 'Bearer ' + currentUser.uid
        },
        url: process.env.REACT_APP_BACKEND+'/vorteilspartner/scancode',
        data: {
          vorteilId: result.chunks[0].text
        }
      }).then(res => {

        Swal.fire({
          icon: "success",
          title: props.vorname +" "+ props.nachname,
          html: "hat Anspruch auf den Vorteil:<br /><br />"
                +"<b>"+res.data.vorteilOffline+"</b>" //Der Offlien Rabatt selbst
                +"<br />"
                +'<b style="font-size:120%">'+res.data.name+'</b><br /><br />' //Vorteilspartner Name
                +"Scanzeit:<b>"+new Date().toLocaleString("de-DE")+"</b>"
        });
        props.setShowQrReader(false);
        setDelay(false);
      })
      .catch(err => {
        Swal.fire({
          icon: "error",
          title: "Fehler",
          html: "Stelle sicher, dass du eine Internetverbindung hast."
        })
      })
         
    }
    
  };

  const handleError = (error) => {
    console.log(error);
  };

  return (
    <>

    {scanner &&
        <>
            {/*<button onClick={() => setFacing('environment')}>environment</button>
            <br />
            <button onClick={() => setFacing('user')}>user</button>
            */}
            <QrReader
                delay={delay}
                style={previewStyle}
                facingMode={facing}
                onError={handleError}
                onScan={handleScan}
            />
            <br />
            <br />
            <br />
            {/*<p>{JSON.stringify(result.data)}</p>*/}
            <br />
            <Button variant="danger" onClick={() => props.setShowQrReader(false)}>
                Scanner beenden
            </Button>
        </>
      }
    {/*
      <br />
      <br />
      <button onClick={() => setScanner(true)}>Scanner starten</button>
      <br />
      <button onClick={() => setScanner(false)}>Scanner stoppen</button> 
    */}
    </>
  );
};

export default QR;
