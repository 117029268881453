export default [
    {
      "label": "Familie in Not Stiftung des Landes Niedersachsen",
      "value": "Familie in Not Stiftung des Landes Niedersachsen"
    },
    {
      "label": "FÜR SIE Handelsgenossenschaft eG Food - Non Food",
      "value": "FÜR SIE Handelsgenossenschaft eG Food - Non Food"
    },
    {
      "label": "FÜR SIE Zentralregulierung GmbH",
      "value": "FÜR SIE Zentralregulierung GmbH"
    },
    {
      "label": "Spar- und Kreditbank Evangelisch-Freikirchlicher Gemeinden eG",
      "value": "Spar- und Kreditbank Evangelisch-Freikirchlicher Gemeinden eG"
    },
    {
      "label": "10x Founders GmbH",
      "value": "10x Founders GmbH"
    },
    {
      "label": "2bX Urban Fund Management GmbH",
      "value": "2bX Urban Fund Management GmbH"
    },
    {
      "label": "2K Capital GmbH",
      "value": "2K Capital GmbH"
    },
    {
      "label": "360 Treasury Systems AG",
      "value": "360 Treasury Systems AG"
    },
    {
      "label": "360X AG",
      "value": "360X AG"
    },
    {
      "label": "3i Investments (Luxembourg) S.A. Zweigniederlassung Deutschland",
      "value": "3i Investments (Luxembourg) S.A. Zweigniederlassung Deutschland"
    },
    {
      "label": "415 Capital Management GmbH",
      "value": "415 Capital Management GmbH"
    },
    {
      "label": "42CAP Manager GmbH",
      "value": "42CAP Manager GmbH"
    },
    {
      "label": "468 Management GmbH",
      "value": "468 Management GmbH"
    },
    {
      "label": "4L Capital AG",
      "value": "4L Capital AG"
    },
    {
      "label": "7ORCA Asset Management AG",
      "value": "7ORCA Asset Management AG"
    },
    {
      "label": "A*LEASE Auto- und Anlagenleasing GmbH",
      "value": "A*LEASE Auto- und Anlagenleasing GmbH"
    },
    {
      "label": "A. Messner Leasing KG",
      "value": "A. Messner Leasing KG"
    },
    {
      "label": "A. O. Bulkers GmbH & Co. KG",
      "value": "A. O. Bulkers GmbH & Co. KG"
    },
    {
      "label": "A.B.S. Global Factoring AG",
      "value": "A.B.S. Global Factoring AG"
    },
    {
      "label": "A/I/M Leasing + Finance GmbH",
      "value": "A/I/M Leasing + Finance GmbH"
    },
    {
      "label": "Aachener Bank eG",
      "value": "Aachener Bank eG"
    },
    {
      "label": "Aachener Grundvermögen Kapitalverwaltungsgesellschaft mit beschränkter Haftung",
      "value": "Aachener Grundvermögen Kapitalverwaltungsgesellschaft mit beschränkter Haftung"
    },
    {
      "label": "Aareal Bank AG",
      "value": "Aareal Bank AG"
    },
    {
      "label": "ABACUS Asset Management GmbH",
      "value": "ABACUS Asset Management GmbH"
    },
    {
      "label": "ABACUS Financial Services AG",
      "value": "ABACUS Financial Services AG"
    },
    {
      "label": "ABANCA Corporación Bancaria, S.A.  Repräsentanz",
      "value": "ABANCA Corporación Bancaria, S.A.  Repräsentanz"
    },
    {
      "label": "abcbank GmbH",
      "value": "abcbank GmbH"
    },
    {
      "label": "abcfinance GmbH",
      "value": "abcfinance GmbH"
    },
    {
      "label": "ABELE Depotverwaltung GmbH",
      "value": "ABELE Depotverwaltung GmbH"
    },
    {
      "label": "ABG Sundal Collier ASA Niederlassung Frankfurt am Main",
      "value": "ABG Sundal Collier ASA Niederlassung Frankfurt am Main"
    },
    {
      "label": "ABK Allgemeine Beamten Bank AG",
      "value": "ABK Allgemeine Beamten Bank AG"
    },
    {
      "label": "ABN AMRO Asset Based Finance N.V., Niederlassung Deutschland",
      "value": "ABN AMRO Asset Based Finance N.V., Niederlassung Deutschland"
    },
    {
      "label": "ABN AMRO Bank N.V. Frankfurt Branch",
      "value": "ABN AMRO Bank N.V. Frankfurt Branch"
    },
    {
      "label": "abrdn Investments Deutschland AG",
      "value": "abrdn Investments Deutschland AG"
    },
    {
      "label": "Abrechnungs-Service Speicher GmbH",
      "value": "Abrechnungs-Service Speicher GmbH"
    },
    {
      "label": "Abrechnungsstelle Mittelrhein für Physikalische Therapie",
      "value": "Abrechnungsstelle Mittelrhein für Physikalische Therapie"
    },
    {
      "label": "Abrechnungsstelle Niedersachsen für Heil- und Hilfsmittel GmbH - ARNI -",
      "value": "Abrechnungsstelle Niedersachsen für Heil- und Hilfsmittel GmbH - ARNI -"
    },
    {
      "label": "Abtsgmünder Bank - Raiffeisen - eG",
      "value": "Abtsgmünder Bank - Raiffeisen - eG"
    },
    {
      "label": "ABV Gesellschaft für Arztabrechnung, betriebswirtschaftliche Beratung u. Verrechnungssysteme mbH",
      "value": "ABV Gesellschaft für Arztabrechnung, betriebswirtschaftliche Beratung u. Verrechnungssysteme mbH"
    },
    {
      "label": "ABZ Zahnärztliches Rechenzentrum für Bayern GmbH",
      "value": "ABZ Zahnärztliches Rechenzentrum für Bayern GmbH"
    },
    {
      "label": "ACATIS Investment Kapitalverwaltungsgesellschaft mbH",
      "value": "ACATIS Investment Kapitalverwaltungsgesellschaft mbH"
    },
    {
      "label": "accaris asset management GmbH",
      "value": "accaris asset management GmbH"
    },
    {
      "label": "Acclivis Investment-AG TGV",
      "value": "Acclivis Investment-AG TGV"
    },
    {
      "label": "Accura Consult GmbH",
      "value": "Accura Consult GmbH"
    },
    {
      "label": "ACOLIN Europe AG",
      "value": "ACOLIN Europe AG"
    },
    {
      "label": "activ factoring AG",
      "value": "activ factoring AG"
    },
    {
      "label": "Acton Capital Partners GmbH",
      "value": "Acton Capital Partners GmbH"
    },
    {
      "label": "ADAC Autoversicherung AG",
      "value": "ADAC Autoversicherung AG"
    },
    {
      "label": "ADAC Versicherung AG",
      "value": "ADAC Versicherung AG"
    },
    {
      "label": "Adams Street (Europe) GmbH",
      "value": "Adams Street (Europe) GmbH"
    },
    {
      "label": "ADANAC Finanz & Consult Aktiengesellschaft",
      "value": "ADANAC Finanz & Consult Aktiengesellschaft"
    },
    {
      "label": "ADCURAM Beteiligungen GmbH & Co. KG",
      "value": "ADCURAM Beteiligungen GmbH & Co. KG"
    },
    {
      "label": "Add-on Advisory GmbH",
      "value": "Add-on Advisory GmbH"
    },
    {
      "label": "ADELTA.FINANZ AG",
      "value": "ADELTA.FINANZ AG"
    },
    {
      "label": "adesion Factoring GmbH",
      "value": "adesion Factoring GmbH"
    },
    {
      "label": "ADH Abrechnungszentrum für Heilmittelerbringer GmbH",
      "value": "ADH Abrechnungszentrum für Heilmittelerbringer GmbH"
    },
    {
      "label": "Adina Fund Management GmbH",
      "value": "Adina Fund Management GmbH"
    },
    {
      "label": "ADLATUS AKTIENGESELLSCHAFT",
      "value": "ADLATUS AKTIENGESELLSCHAFT"
    },
    {
      "label": "ADLER Versicherung AG",
      "value": "ADLER Versicherung AG"
    },
    {
      "label": "ADREALIS Service Kapitalverwaltungs-GmbH",
      "value": "ADREALIS Service Kapitalverwaltungs-GmbH"
    },
    {
      "label": "AdVertum Vermögensmanagement AG",
      "value": "AdVertum Vermögensmanagement AG"
    },
    {
      "label": "ADVOCARD Rechtsschutzversicherung AG",
      "value": "ADVOCARD Rechtsschutzversicherung AG"
    },
    {
      "label": "Adyen N.V. - German Branch",
      "value": "Adyen N.V. - German Branch"
    },
    {
      "label": "AEGIDIUS Rückversicherung Aktiengesellschaft",
      "value": "AEGIDIUS Rückversicherung Aktiengesellschaft"
    },
    {
      "label": "Aegon Investment Management B.V. Branch office Germany",
      "value": "Aegon Investment Management B.V. Branch office Germany"
    },
    {
      "label": "AEM Management GmbH",
      "value": "AEM Management GmbH"
    },
    {
      "label": "AeV Factoring GmbH",
      "value": "AeV Factoring GmbH"
    },
    {
      "label": "AEW Invest GmbH",
      "value": "AEW Invest GmbH"
    },
    {
      "label": "AFG Allgemeine Factoringgesellschaft mbH",
      "value": "AFG Allgemeine Factoringgesellschaft mbH"
    },
    {
      "label": "AfH Abrechnung für Heilberufe GmbH",
      "value": "AfH Abrechnung für Heilberufe GmbH"
    },
    {
      "label": "AFINUM Management GmbH",
      "value": "AFINUM Management GmbH"
    },
    {
      "label": "AFL Mobilien Leasing GmbH",
      "value": "AFL Mobilien Leasing GmbH"
    },
    {
      "label": "Afmo-Arbeitsgemeinschaft freier Molkereiprodukten-Großhändler eG",
      "value": "Afmo-Arbeitsgemeinschaft freier Molkereiprodukten-Großhändler eG"
    },
    {
      "label": "AFS Equity & Derivatives B.V., Zweigniederlassung Frankfurt",
      "value": "AFS Equity & Derivatives B.V., Zweigniederlassung Frankfurt"
    },
    {
      "label": "AFS Financial Service GmbH & Co. KG",
      "value": "AFS Financial Service GmbH & Co. KG"
    },
    {
      "label": "AFS Interest B.V. Zweigniederlassung Frankfurt",
      "value": "AFS Interest B.V. Zweigniederlassung Frankfurt"
    },
    {
      "label": "AGEVIS GmbH",
      "value": "AGEVIS GmbH"
    },
    {
      "label": "AGFA FINANCE N.V. - Zweigniederlassung Deutschland",
      "value": "AGFA FINANCE N.V. - Zweigniederlassung Deutschland"
    },
    {
      "label": "AGILA Haustierversicherung Aktiengesellschaft",
      "value": "AGILA Haustierversicherung Aktiengesellschaft"
    },
    {
      "label": "AGL Activ Services GmbH",
      "value": "AGL Activ Services GmbH"
    },
    {
      "label": "Agora ADVICE GmbH",
      "value": "Agora ADVICE GmbH"
    },
    {
      "label": "Agricultural Bank of China Limited Frankfurt Branch",
      "value": "Agricultural Bank of China Limited Frankfurt Branch"
    },
    {
      "label": "AGw Allgemeine Geldwechsel Förster GmbH Wechselstube am Steindamm",
      "value": "AGw Allgemeine Geldwechsel Förster GmbH Wechselstube am Steindamm"
    },
    {
      "label": "Aheim Capital GmbH",
      "value": "Aheim Capital GmbH"
    },
    {
      "label": "AHP Capital Management GmbH",
      "value": "AHP Capital Management GmbH"
    },
    {
      "label": "AHR Vermögensverwaltung GmbH",
      "value": "AHR Vermögensverwaltung GmbH"
    },
    {
      "label": "AI.Fund Management GmbH",
      "value": "AI.Fund Management GmbH"
    },
    {
      "label": "AIF Kapitalverwaltungs-AG",
      "value": "AIF Kapitalverwaltungs-AG"
    },
    {
      "label": "AIF Komplementär GmbH",
      "value": "AIF Komplementär GmbH"
    },
    {
      "label": "aifinyo finance GmbH",
      "value": "aifinyo finance GmbH"
    },
    {
      "label": "aifinyo payments GmbH",
      "value": "aifinyo payments GmbH"
    },
    {
      "label": "AIG Europe S.A., Direktion für Deutschland",
      "value": "AIG Europe S.A., Direktion für Deutschland"
    },
    {
      "label": "aik Immobilien-Investmentgesellschaft mbH",
      "value": "aik Immobilien-Investmentgesellschaft mbH"
    },
    {
      "label": "AIL Leasing München AG",
      "value": "AIL Leasing München AG"
    },
    {
      "label": "Aioi Nissay Dowa Insurance Company of Europe SE, Niederlassung Deutschland",
      "value": "Aioi Nissay Dowa Insurance Company of Europe SE, Niederlassung Deutschland"
    },
    {
      "label": "Aioi Nissay Dowa Life Insurance of Europe Aktiengesellschaft",
      "value": "Aioi Nissay Dowa Life Insurance of Europe Aktiengesellschaft"
    },
    {
      "label": "AION Bank S.A. German Branch",
      "value": "AION Bank S.A. German Branch"
    },
    {
      "label": "Airbus Bank GmbH",
      "value": "Airbus Bank GmbH"
    },
    {
      "label": "AKA Ausfuhrkredit-Gesellschaft mit beschränkter Haftung",
      "value": "AKA Ausfuhrkredit-Gesellschaft mit beschränkter Haftung"
    },
    {
      "label": "AKBANK AG",
      "value": "AKBANK AG"
    },
    {
      "label": "akf bank GmbH & Co KG",
      "value": "akf bank GmbH & Co KG"
    },
    {
      "label": "akf leasing GmbH & Co KG",
      "value": "akf leasing GmbH & Co KG"
    },
    {
      "label": "akf servicelease GmbH",
      "value": "akf servicelease GmbH"
    },
    {
      "label": "Akos GmbH",
      "value": "Akos GmbH"
    },
    {
      "label": "AkquiVest GmbH",
      "value": "AkquiVest GmbH"
    },
    {
      "label": "Akrida Capital GmbH",
      "value": "Akrida Capital GmbH"
    },
    {
      "label": "AKTIEN - FONDS - VERMÖGENSVERWALTUNG Dipl.-Kaufm. Richter e.Kfm.",
      "value": "AKTIEN - FONDS - VERMÖGENSVERWALTUNG Dipl.-Kaufm. Richter e.Kfm."
    },
    {
      "label": "Aktivbank Aktiengesellschaft",
      "value": "Aktivbank Aktiengesellschaft"
    },
    {
      "label": "AL Konzept GmbH & Co. KG",
      "value": "AL Konzept GmbH & Co. KG"
    },
    {
      "label": "ALBIS Fullservice Leasing GmbH",
      "value": "ALBIS Fullservice Leasing GmbH"
    },
    {
      "label": "ALBIS HiTec Leasing GmbH",
      "value": "ALBIS HiTec Leasing GmbH"
    },
    {
      "label": "Albourne Partners Deutschland AG",
      "value": "Albourne Partners Deutschland AG"
    },
    {
      "label": "Albrech & Cie. Vermögensverwaltung Aktiengesellschaft",
      "value": "Albrech & Cie. Vermögensverwaltung Aktiengesellschaft"
    },
    {
      "label": "Albrecht, Kitta & Co. Vermögensverwaltung GmbH",
      "value": "Albrecht, Kitta & Co. Vermögensverwaltung GmbH"
    },
    {
      "label": "Alchemist Europe Asset Management GmbH",
      "value": "Alchemist Europe Asset Management GmbH"
    },
    {
      "label": "ALD AutoLeasing D GmbH",
      "value": "ALD AutoLeasing D GmbH"
    },
    {
      "label": "ALD Lease Finanz GmbH",
      "value": "ALD Lease Finanz GmbH"
    },
    {
      "label": "ALG Automobil Leasing Gesellschaft mbH & Co. KG",
      "value": "ALG Automobil Leasing Gesellschaft mbH & Co. KG"
    },
    {
      "label": "ALGO Capital Management GmbH",
      "value": "ALGO Capital Management GmbH"
    },
    {
      "label": "All Ventures FQF GmbH",
      "value": "All Ventures FQF GmbH"
    },
    {
      "label": "Allane SE",
      "value": "Allane SE"
    },
    {
      "label": "Allcura Versicherungs-Aktiengesellschaft",
      "value": "Allcura Versicherungs-Aktiengesellschaft"
    },
    {
      "label": "Allgemeine Rentenanstalt Pensionskasse AG",
      "value": "Allgemeine Rentenanstalt Pensionskasse AG"
    },
    {
      "label": "Allgäuer Volksbank eG Kempten-Sonthofen",
      "value": "Allgäuer Volksbank eG Kempten-Sonthofen"
    },
    {
      "label": "AllianceBernstein (Luxembourg) S.à r.l., Munich Branch",
      "value": "AllianceBernstein (Luxembourg) S.à r.l., Munich Branch"
    },
    {
      "label": "Allianz Capital Partners GmbH",
      "value": "Allianz Capital Partners GmbH"
    },
    {
      "label": "Allianz Direct Versicherungs-AG",
      "value": "Allianz Direct Versicherungs-AG"
    },
    {
      "label": "Allianz Global Corporate & Specialty SE",
      "value": "Allianz Global Corporate & Specialty SE"
    },
    {
      "label": "Allianz Global Investors GmbH",
      "value": "Allianz Global Investors GmbH"
    },
    {
      "label": "Allianz Global Life Designated Activity Company Niederlassung für Deutschland",
      "value": "Allianz Global Life Designated Activity Company Niederlassung für Deutschland"
    },
    {
      "label": "Allianz Lebensversicherungs-Aktiengesellschaft",
      "value": "Allianz Lebensversicherungs-Aktiengesellschaft"
    },
    {
      "label": "Allianz Pensionsfonds Aktiengesellschaft",
      "value": "Allianz Pensionsfonds Aktiengesellschaft"
    },
    {
      "label": "Allianz Pensionskasse Aktiengesellschaft",
      "value": "Allianz Pensionskasse Aktiengesellschaft"
    },
    {
      "label": "Allianz Private Krankenversicherungs-Aktiengesellschaft",
      "value": "Allianz Private Krankenversicherungs-Aktiengesellschaft"
    },
    {
      "label": "Allianz SE",
      "value": "Allianz SE"
    },
    {
      "label": "Allianz Versicherungs-Aktiengesellschaft",
      "value": "Allianz Versicherungs-Aktiengesellschaft"
    },
    {
      "label": "Allianz Versorgungskasse Versicherungsverein a.G.",
      "value": "Allianz Versorgungskasse Versicherungsverein a.G."
    },
    {
      "label": "Allington Investment Advisors GmbH",
      "value": "Allington Investment Advisors GmbH"
    },
    {
      "label": "Allington Investors AG",
      "value": "Allington Investors AG"
    },
    {
      "label": "ALLISTRO CAPITAL Fonds 21-1 GmbH & Co. KG",
      "value": "ALLISTRO CAPITAL Fonds 21-1 GmbH & Co. KG"
    },
    {
      "label": "ALLISTRO Co-Invest GmbH & Co. KG",
      "value": "ALLISTRO Co-Invest GmbH & Co. KG"
    },
    {
      "label": "ALLISTRO Fonds 21-2 GmbH & Co. KG",
      "value": "ALLISTRO Fonds 21-2 GmbH & Co. KG"
    },
    {
      "label": "Allspring Global Investments Luxembourg SA, Frankfurt Branch",
      "value": "Allspring Global Investments Luxembourg SA, Frankfurt Branch"
    },
    {
      "label": "Alpen Privatbank AG Niederlassung Deutschland",
      "value": "Alpen Privatbank AG Niederlassung Deutschland"
    },
    {
      "label": "alpha beta asset management GmbH",
      "value": "alpha beta asset management GmbH"
    },
    {
      "label": "alpha connect capital GmbH",
      "value": "alpha connect capital GmbH"
    },
    {
      "label": "Alpha Invest GmbH",
      "value": "Alpha Invest GmbH"
    },
    {
      "label": "ALPHA ORDINATUM GmbH",
      "value": "ALPHA ORDINATUM GmbH"
    },
    {
      "label": "Alpha Wertpapierhandels GmbH",
      "value": "Alpha Wertpapierhandels GmbH"
    },
    {
      "label": "Alpha-Beratungs GmbH",
      "value": "Alpha-Beratungs GmbH"
    },
    {
      "label": "Alphabet Fuhrparkmanagement GmbH",
      "value": "Alphabet Fuhrparkmanagement GmbH"
    },
    {
      "label": "Alphaville Equity GmbH & Co. KG",
      "value": "Alphaville Equity GmbH & Co. KG"
    },
    {
      "label": "Alpina Management GmbH",
      "value": "Alpina Management GmbH"
    },
    {
      "label": "Alpina Partners Fund Feeder GmbH & Co. KG",
      "value": "Alpina Partners Fund Feeder GmbH & Co. KG"
    },
    {
      "label": "Alpine Space Ventures Management GmbH",
      "value": "Alpine Space Ventures Management GmbH"
    },
    {
      "label": "ALPS Family Office AG",
      "value": "ALPS Family Office AG"
    },
    {
      "label": "ALS AutoLeasing-Service GmbH",
      "value": "ALS AutoLeasing-Service GmbH"
    },
    {
      "label": "ALSO Financial Services GmbH",
      "value": "ALSO Financial Services GmbH"
    },
    {
      "label": "ALSTIN II Fonds GmbH & Co. KG",
      "value": "ALSTIN II Fonds GmbH & Co. KG"
    },
    {
      "label": "ALTAPLAN LEASING GmbH",
      "value": "ALTAPLAN LEASING GmbH"
    },
    {
      "label": "ALTE LEIPZIGER Bauspar AG",
      "value": "ALTE LEIPZIGER Bauspar AG"
    },
    {
      "label": "Alte Leipziger Lebensversicherung auf Gegenseitigkeit",
      "value": "Alte Leipziger Lebensversicherung auf Gegenseitigkeit"
    },
    {
      "label": "ALTE LEIPZIGER Pensionsfonds AG",
      "value": "ALTE LEIPZIGER Pensionsfonds AG"
    },
    {
      "label": "ALTE LEIPZIGER Pensionskasse AG",
      "value": "ALTE LEIPZIGER Pensionskasse AG"
    },
    {
      "label": "ALTE LEIPZIGER Treuhand GmbH",
      "value": "ALTE LEIPZIGER Treuhand GmbH"
    },
    {
      "label": "ALTE LEIPZIGER Trust Investment-Gesellschaft mbH",
      "value": "ALTE LEIPZIGER Trust Investment-Gesellschaft mbH"
    },
    {
      "label": "Alte Leipziger Versicherung Aktiengesellschaft",
      "value": "Alte Leipziger Versicherung Aktiengesellschaft"
    },
    {
      "label": "ALTE OLDENBURGER Krankenversicherung AG",
      "value": "ALTE OLDENBURGER Krankenversicherung AG"
    },
    {
      "label": "ALTE OLDENBURGER Krankenversicherung von 1927 Versicherungsverein auf Gegenseitigkeit",
      "value": "ALTE OLDENBURGER Krankenversicherung von 1927 Versicherungsverein auf Gegenseitigkeit"
    },
    {
      "label": "Alters- und Hinterbliebenen- Versicherung der Technischen Überwachungs-Vereine-VVaG-",
      "value": "Alters- und Hinterbliebenen- Versicherung der Technischen Überwachungs-Vereine-VVaG-"
    },
    {
      "label": "Altersversorgungskasse des Kaiserswerther Verbandes deutscher Diakonissen-Mutterhäuser VVaG",
      "value": "Altersversorgungskasse des Kaiserswerther Verbandes deutscher Diakonissen-Mutterhäuser VVaG"
    },
    {
      "label": "Altonaer Spar- und Bauverein eG",
      "value": "Altonaer Spar- und Bauverein eG"
    },
    {
      "label": "ALVG Anlagenvermietung GmbH",
      "value": "ALVG Anlagenvermietung GmbH"
    },
    {
      "label": "Alxing-Brucker Genossenschaftsbank eG",
      "value": "Alxing-Brucker Genossenschaftsbank eG"
    },
    {
      "label": "AM alpha Kapitalverwaltungsgesellschaft mbH",
      "value": "AM alpha Kapitalverwaltungsgesellschaft mbH"
    },
    {
      "label": "AM HAFEN CAPITAL GmbH",
      "value": "AM HAFEN CAPITAL GmbH"
    },
    {
      "label": "AM Ventures Management GmbH",
      "value": "AM Ventures Management GmbH"
    },
    {
      "label": "AMANDEA Vermögensverwaltung AG",
      "value": "AMANDEA Vermögensverwaltung AG"
    },
    {
      "label": "Amathaon Capital Management GmbH",
      "value": "Amathaon Capital Management GmbH"
    },
    {
      "label": "Ambra Versicherung AG",
      "value": "Ambra Versicherung AG"
    },
    {
      "label": "American Century Investments (EU) GmbH",
      "value": "American Century Investments (EU) GmbH"
    },
    {
      "label": "Ametos Invest GmbH",
      "value": "Ametos Invest GmbH"
    },
    {
      "label": "AMF ApoMedFinanz GmbH",
      "value": "AMF ApoMedFinanz GmbH"
    },
    {
      "label": "AMF Capital AG",
      "value": "AMF Capital AG"
    },
    {
      "label": "Amino Collective II Management GmbH",
      "value": "Amino Collective II Management GmbH"
    },
    {
      "label": "AMMERLÄNDER VERSICHERUNG Versicherungsverein a.G. (VVaG)",
      "value": "AMMERLÄNDER VERSICHERUNG Versicherungsverein a.G. (VVaG)"
    },
    {
      "label": "Ampega Investment GmbH",
      "value": "Ampega Investment GmbH"
    },
    {
      "label": "Amplifier Ventures GmbH",
      "value": "Amplifier Ventures GmbH"
    },
    {
      "label": "AMS Fuhrparkmanagement GmbH",
      "value": "AMS Fuhrparkmanagement GmbH"
    },
    {
      "label": "Amundi Deutschland GmbH",
      "value": "Amundi Deutschland GmbH"
    },
    {
      "label": "Ananda Ventures GmbH",
      "value": "Ananda Ventures GmbH"
    },
    {
      "label": "AnCeKa Vermögensbetreuungs Aktiengesellschaft",
      "value": "AnCeKa Vermögensbetreuungs Aktiengesellschaft"
    },
    {
      "label": "Andelskassen Sonderjylland - Repräsentanz",
      "value": "Andelskassen Sonderjylland - Repräsentanz"
    },
    {
      "label": "Andreas Palmowski Vermögensverwaltung e.K.",
      "value": "Andreas Palmowski Vermögensverwaltung e.K."
    },
    {
      "label": "Andreas Winkel Vermögensverwaltungen",
      "value": "Andreas Winkel Vermögensverwaltungen"
    },
    {
      "label": "andsafe Aktiengesellschaft",
      "value": "andsafe Aktiengesellschaft"
    },
    {
      "label": "Angel Invest Fund Management GmbH",
      "value": "Angel Invest Fund Management GmbH"
    },
    {
      "label": "Angelize Management GmbH",
      "value": "Angelize Management GmbH"
    },
    {
      "label": "Angest.-Pensionskasse der dt. Geschäftsbetriebe der Georg Fischer Aktiengesellschaft Schaffhausen (Schweiz)",
      "value": "Angest.-Pensionskasse der dt. Geschäftsbetriebe der Georg Fischer Aktiengesellschaft Schaffhausen (Schweiz)"
    },
    {
      "label": "ansa capital management GmbH",
      "value": "ansa capital management GmbH"
    },
    {
      "label": "ANTEA Vermögensverwaltung GmbH",
      "value": "ANTEA Vermögensverwaltung GmbH"
    },
    {
      "label": "Antecedo Asset Management GmbH",
      "value": "Antecedo Asset Management GmbH"
    },
    {
      "label": "Antler CE I Fund Manager GmbH",
      "value": "Antler CE I Fund Manager GmbH"
    },
    {
      "label": "Anton & Partner Finanzmarkt Info-, Beratungs- und Vermittlungs-GmbH",
      "value": "Anton & Partner Finanzmarkt Info-, Beratungs- und Vermittlungs-GmbH"
    },
    {
      "label": "Anton v. Below GmbH & Co. KG",
      "value": "Anton v. Below GmbH & Co. KG"
    },
    {
      "label": "ANTRA Niederelbe-Trampfahrt Versicherungs-Aktiengesellschaft",
      "value": "ANTRA Niederelbe-Trampfahrt Versicherungs-Aktiengesellschaft"
    },
    {
      "label": "ANVL Leasing- und Vermietungsgesellschaft mbH & Co. KG",
      "value": "ANVL Leasing- und Vermietungsgesellschaft mbH & Co. KG"
    },
    {
      "label": "Aon Trust Germany GmbH",
      "value": "Aon Trust Germany GmbH"
    },
    {
      "label": "apano GmbH",
      "value": "apano GmbH"
    },
    {
      "label": "Apella WertpapierService GmbH",
      "value": "Apella WertpapierService GmbH"
    },
    {
      "label": "Apera Asset Management GmbH",
      "value": "Apera Asset Management GmbH"
    },
    {
      "label": "apetito Leasing GmbH",
      "value": "apetito Leasing GmbH"
    },
    {
      "label": "APO Asset Management GmbH",
      "value": "APO Asset Management GmbH"
    },
    {
      "label": "APOaccount GmbH",
      "value": "APOaccount GmbH"
    },
    {
      "label": "Apollo Health Ventures GmbH",
      "value": "Apollo Health Ventures GmbH"
    },
    {
      "label": "Apollo Health Ventures Management GmbH",
      "value": "Apollo Health Ventures Management GmbH"
    },
    {
      "label": "Apotheken- und Ärzte-Abrechnungszentrum Dr. Güldener GmbH",
      "value": "Apotheken- und Ärzte-Abrechnungszentrum Dr. Güldener GmbH"
    },
    {
      "label": "Apotheken-Rechen-Zentrum Gesellschaft mit beschränkter Haftung",
      "value": "Apotheken-Rechen-Zentrum Gesellschaft mit beschränkter Haftung"
    },
    {
      "label": "AQE Capital Management GmbH",
      "value": "AQE Capital Management GmbH"
    },
    {
      "label": "AQR Capital Management (Germany) GmbH",
      "value": "AQR Capital Management (Germany) GmbH"
    },
    {
      "label": "Aquantum GmbH",
      "value": "Aquantum GmbH"
    },
    {
      "label": "Aquila & Co. AG Repräsentanz Deutschland",
      "value": "Aquila & Co. AG Repräsentanz Deutschland"
    },
    {
      "label": "Aquila Capital Investmentgesellschaft mbH",
      "value": "Aquila Capital Investmentgesellschaft mbH"
    },
    {
      "label": "AQVC Management GmbH",
      "value": "AQVC Management GmbH"
    },
    {
      "label": "Arab Banking Corporation SA, Zweigniederlassung Frankfurt",
      "value": "Arab Banking Corporation SA, Zweigniederlassung Frankfurt"
    },
    {
      "label": "Arabesque (Deutschland) GmbH",
      "value": "Arabesque (Deutschland) GmbH"
    },
    {
      "label": "ARAG Allgemeine Versicherungs-Aktiengesellschaft",
      "value": "ARAG Allgemeine Versicherungs-Aktiengesellschaft"
    },
    {
      "label": "ARAG Krankenversicherungs-Aktiengesellschaft",
      "value": "ARAG Krankenversicherungs-Aktiengesellschaft"
    },
    {
      "label": "ARAG SE",
      "value": "ARAG SE"
    },
    {
      "label": "Aramea Asset Management AG",
      "value": "Aramea Asset Management AG"
    },
    {
      "label": "ARAMIS Factoring GmbH & Co. KG",
      "value": "ARAMIS Factoring GmbH & Co. KG"
    },
    {
      "label": "ARC GmbH & Co KG",
      "value": "ARC GmbH & Co KG"
    },
    {
      "label": "Arca-Leasing GmbH",
      "value": "Arca-Leasing GmbH"
    },
    {
      "label": "Arcaris Management GmbH",
      "value": "Arcaris Management GmbH"
    },
    {
      "label": "archimedes Leasing GmbH",
      "value": "archimedes Leasing GmbH"
    },
    {
      "label": "Arco VerrechnungsSysteme GmbH",
      "value": "Arco VerrechnungsSysteme GmbH"
    },
    {
      "label": "Arctic Securities AS, Branch Germany",
      "value": "Arctic Securities AS, Branch Germany"
    },
    {
      "label": "Arcus Capital AG",
      "value": "Arcus Capital AG"
    },
    {
      "label": "AREM GmbH",
      "value": "AREM GmbH"
    },
    {
      "label": "Ares Management Luxembourg S.à r.l., German Branch",
      "value": "Ares Management Luxembourg S.à r.l., German Branch"
    },
    {
      "label": "Arete Trading GmbH",
      "value": "Arete Trading GmbH"
    },
    {
      "label": "ARI Fleet Leasing Germany GmbH",
      "value": "ARI Fleet Leasing Germany GmbH"
    },
    {
      "label": "ARIAD Asset Management GmbH",
      "value": "ARIAD Asset Management GmbH"
    },
    {
      "label": "Armin Lohberger Vermögensverwaltung GmbH",
      "value": "Armin Lohberger Vermögensverwaltung GmbH"
    },
    {
      "label": "Armira Administration GmbH",
      "value": "Armira Administration GmbH"
    },
    {
      "label": "Ars Pecuniae GmbH",
      "value": "Ars Pecuniae GmbH"
    },
    {
      "label": "Art-Invest Real Estate Funds GmbH",
      "value": "Art-Invest Real Estate Funds GmbH"
    },
    {
      "label": "ARVAL Deutschland GmbH",
      "value": "ARVAL Deutschland GmbH"
    },
    {
      "label": "arvato media GmbH",
      "value": "arvato media GmbH"
    },
    {
      "label": "ARZ Service GmbH",
      "value": "ARZ Service GmbH"
    },
    {
      "label": "AS Abrechnungsstelle für Heil-, Hilfs- und Pflegeberufe AG",
      "value": "AS Abrechnungsstelle für Heil-, Hilfs- und Pflegeberufe AG"
    },
    {
      "label": "AS TRANS-MED GmbH",
      "value": "AS TRANS-MED GmbH"
    },
    {
      "label": "Asante Capital Group Germany GmbH",
      "value": "Asante Capital Group Germany GmbH"
    },
    {
      "label": "Asgard Capital Verwaltung GmbH",
      "value": "Asgard Capital Verwaltung GmbH"
    },
    {
      "label": "Asian Development Bank European Representative Office",
      "value": "Asian Development Bank European Representative Office"
    },
    {
      "label": "Assella GmbH",
      "value": "Assella GmbH"
    },
    {
      "label": "Assenagon Asset Management S.A. Zweigniederlassung München",
      "value": "Assenagon Asset Management S.A. Zweigniederlassung München"
    },
    {
      "label": "Asset Allocation AG Vermögensanlagen - Consulting",
      "value": "Asset Allocation AG Vermögensanlagen - Consulting"
    },
    {
      "label": "Asset Concepts GmbH",
      "value": "Asset Concepts GmbH"
    },
    {
      "label": "ASTAR Fonds Management GmbH",
      "value": "ASTAR Fonds Management GmbH"
    },
    {
      "label": "Astorius Capital GmbH",
      "value": "Astorius Capital GmbH"
    },
    {
      "label": "Astorius Consult GmbH",
      "value": "Astorius Consult GmbH"
    },
    {
      "label": "Astra Versicherung AG",
      "value": "Astra Versicherung AG"
    },
    {
      "label": "Athlon Germany GmbH",
      "value": "Athlon Germany GmbH"
    },
    {
      "label": "Athora Lebensversicherung Aktiengesellschaft",
      "value": "Athora Lebensversicherung Aktiengesellschaft"
    },
    {
      "label": "Athora Pensionskasse Aktiengesellschaft",
      "value": "Athora Pensionskasse Aktiengesellschaft"
    },
    {
      "label": "Atlan Family Office GmbH",
      "value": "Atlan Family Office GmbH"
    },
    {
      "label": "Atlan Management GmbH",
      "value": "Atlan Management GmbH"
    },
    {
      "label": "Atlantic Equities Deutschland GmbH",
      "value": "Atlantic Equities Deutschland GmbH"
    },
    {
      "label": "Atlantic Food Labs Manager GmbH",
      "value": "Atlantic Food Labs Manager GmbH"
    },
    {
      "label": "Atlantic Labs Manager GmbH",
      "value": "Atlantic Labs Manager GmbH"
    },
    {
      "label": "ATLAS AUTO-LEASING GmbH",
      "value": "ATLAS AUTO-LEASING GmbH"
    },
    {
      "label": "Atradius Kreditversicherung, Niederlassung der Atradius Crédito y Caución S.A. de Seguros y Reaseguros",
      "value": "Atradius Kreditversicherung, Niederlassung der Atradius Crédito y Caución S.A. de Seguros y Reaseguros"
    },
    {
      "label": "Atrum Capital GmbH",
      "value": "Atrum Capital GmbH"
    },
    {
      "label": "ATTENTIUM Capital Management AG",
      "value": "ATTENTIUM Capital Management AG"
    },
    {
      "label": "Attijariwafa Bank Europe",
      "value": "Attijariwafa Bank Europe"
    },
    {
      "label": "ATVANTIS Asset Management GmbH",
      "value": "ATVANTIS Asset Management GmbH"
    },
    {
      "label": "Aubilia Vermögensmanagement GmbH",
      "value": "Aubilia Vermögensmanagement GmbH"
    },
    {
      "label": "Auctus Management GmbH & Co. KG",
      "value": "Auctus Management GmbH & Co. KG"
    },
    {
      "label": "Audi Pensionskasse-Altersversorgung der AUTO UNION GmbH, Versicherungsverein auf Gegenseitigk. (VVaG) Ingolst./Donau",
      "value": "Audi Pensionskasse-Altersversorgung der AUTO UNION GmbH, Versicherungsverein auf Gegenseitigk. (VVaG) Ingolst./Donau"
    },
    {
      "label": "Augenoptiker Ausgleichskasse VVaG (AKA)",
      "value": "Augenoptiker Ausgleichskasse VVaG (AKA)"
    },
    {
      "label": "augmentum finanz GmbH",
      "value": "augmentum finanz GmbH"
    },
    {
      "label": "Augsburger Aktienbank Aktiengesellschaft",
      "value": "Augsburger Aktienbank Aktiengesellschaft"
    },
    {
      "label": "Augur Corporate Finance GmbH",
      "value": "Augur Corporate Finance GmbH"
    },
    {
      "label": "Augusta Vermögensverwaltung GmbH",
      "value": "Augusta Vermögensverwaltung GmbH"
    },
    {
      "label": "Aurel BGC - Frankfurt branch",
      "value": "Aurel BGC - Frankfurt branch"
    },
    {
      "label": "AURETAS family trust GmbH",
      "value": "AURETAS family trust GmbH"
    },
    {
      "label": "Auretas Infrastruktur I GmbH & Co. KG",
      "value": "Auretas Infrastruktur I GmbH & Co. KG"
    },
    {
      "label": "Auretas Infrastruktur II GmbH & Co. KG",
      "value": "Auretas Infrastruktur II GmbH & Co. KG"
    },
    {
      "label": "Auretas Private Equity I GmbH & Co. KG",
      "value": "Auretas Private Equity I GmbH & Co. KG"
    },
    {
      "label": "Auricher Werte GmbH",
      "value": "Auricher Werte GmbH"
    },
    {
      "label": "Auricos Asset und Portfolio Management GmbH",
      "value": "Auricos Asset und Portfolio Management GmbH"
    },
    {
      "label": "Australia and New Zealand Banking Group Limited Niederlassung Frankfurt am Main",
      "value": "Australia and New Zealand Banking Group Limited Niederlassung Frankfurt am Main"
    },
    {
      "label": "Austria Leasing GmbH",
      "value": "Austria Leasing GmbH"
    },
    {
      "label": "Auto Pieroth Leasing OHG",
      "value": "Auto Pieroth Leasing OHG"
    },
    {
      "label": "Auto-Müller Gesellschaft mit beschränkter Haftung",
      "value": "Auto-Müller Gesellschaft mit beschränkter Haftung"
    },
    {
      "label": "Automobilclub von Deutschland e.V.",
      "value": "Automobilclub von Deutschland e.V."
    },
    {
      "label": "Autovermietung Hofmann GmbH",
      "value": "Autovermietung Hofmann GmbH"
    },
    {
      "label": "Autovermietung Ritter GmbH & Co. KG",
      "value": "Autovermietung Ritter GmbH & Co. KG"
    },
    {
      "label": "Autovermietung Thiele",
      "value": "Autovermietung Thiele"
    },
    {
      "label": "AUXILIA Rechtsschutz-Versicherungs-Aktiengesellschaft",
      "value": "AUXILIA Rechtsschutz-Versicherungs-Aktiengesellschaft"
    },
    {
      "label": "Auxxo Fund Management GmbH",
      "value": "Auxxo Fund Management GmbH"
    },
    {
      "label": "Avacon AG",
      "value": "Avacon AG"
    },
    {
      "label": "Avala Management GmbH",
      "value": "Avala Management GmbH"
    },
    {
      "label": "Avesco Management GmbH",
      "value": "Avesco Management GmbH"
    },
    {
      "label": "avesco Sustainable Finance AG",
      "value": "avesco Sustainable Finance AG"
    },
    {
      "label": "Aviva Investors Luxembourg - German Branch",
      "value": "Aviva Investors Luxembourg - German Branch"
    },
    {
      "label": "AVN Apotheken-Verrechnungsstelle Dr. Carl Carstens GmbH & Co. KG",
      "value": "AVN Apotheken-Verrechnungsstelle Dr. Carl Carstens GmbH & Co. KG"
    },
    {
      "label": "AVP Gesellschaft für Autovermietung mbH & Co. KG",
      "value": "AVP Gesellschaft für Autovermietung mbH & Co. KG"
    },
    {
      "label": "AVU Aktiengesellschaft für Versorgungs-Unternehmen",
      "value": "AVU Aktiengesellschaft für Versorgungs-Unternehmen"
    },
    {
      "label": "AWG Leasing GmbH",
      "value": "AWG Leasing GmbH"
    },
    {
      "label": "AWP P&C S.A. Niederlassung für Deutschland",
      "value": "AWP P&C S.A. Niederlassung für Deutschland"
    },
    {
      "label": "AWRT Consult GmbH",
      "value": "AWRT Consult GmbH"
    },
    {
      "label": "AXA easy Versicherung AG",
      "value": "AXA easy Versicherung AG"
    },
    {
      "label": "AXA France IARD S.A., Zweigniederlassung Deutschland",
      "value": "AXA France IARD S.A., Zweigniederlassung Deutschland"
    },
    {
      "label": "AXA France Vie S.A., Zweigniederlassung Deutschland",
      "value": "AXA France Vie S.A., Zweigniederlassung Deutschland"
    },
    {
      "label": "AXA Investment Managers Deutschland GmbH",
      "value": "AXA Investment Managers Deutschland GmbH"
    },
    {
      "label": "AXA Krankenversicherung Aktiengesellschaft",
      "value": "AXA Krankenversicherung Aktiengesellschaft"
    },
    {
      "label": "AXA Lebensversicherung Aktiengesellschaft",
      "value": "AXA Lebensversicherung Aktiengesellschaft"
    },
    {
      "label": "AXA Versicherung Aktiengesellschaft",
      "value": "AXA Versicherung Aktiengesellschaft"
    },
    {
      "label": "AXOVISION Capital GmbH",
      "value": "AXOVISION Capital GmbH"
    },
    {
      "label": "azemos vermögensmanagement gmbh",
      "value": "azemos vermögensmanagement gmbh"
    },
    {
      "label": "AZH-Abrechnungszentrale für Hebammen GmbH",
      "value": "AZH-Abrechnungszentrale für Hebammen GmbH"
    },
    {
      "label": "B&K Vermögen GmbH",
      "value": "B&K Vermögen GmbH"
    },
    {
      "label": "B+S Banksysteme Aktiengesellschaft",
      "value": "B+S Banksysteme Aktiengesellschaft"
    },
    {
      "label": "B. Metzler seel. Sohn & Co. Aktiengesellschaft",
      "value": "B. Metzler seel. Sohn & Co. Aktiengesellschaft"
    },
    {
      "label": "B2Mobility GmbH",
      "value": "B2Mobility GmbH"
    },
    {
      "label": "BA die Bayerische Allgemeine Versicherung AG",
      "value": "BA die Bayerische Allgemeine Versicherung AG"
    },
    {
      "label": "BA4V Fondsmanagement GmbH",
      "value": "BA4V Fondsmanagement GmbH"
    },
    {
      "label": "Baader & Heins Capital Management AG",
      "value": "Baader & Heins Capital Management AG"
    },
    {
      "label": "Baader Bank Aktiengesellschaft",
      "value": "Baader Bank Aktiengesellschaft"
    },
    {
      "label": "Babcock Pensionskasse VVaG",
      "value": "Babcock Pensionskasse VVaG"
    },
    {
      "label": "BABY-PLUS Ein- und Verkaufsgenossenschaft eG",
      "value": "BABY-PLUS Ein- und Verkaufsgenossenschaft eG"
    },
    {
      "label": "BACK EUROP Deutschland GmbH & Co. KG",
      "value": "BACK EUROP Deutschland GmbH & Co. KG"
    },
    {
      "label": "Backbone Ventures GmbH",
      "value": "Backbone Ventures GmbH"
    },
    {
      "label": "Baden-Württembergische Wertpapierbörse GmbH",
      "value": "Baden-Württembergische Wertpapierbörse GmbH"
    },
    {
      "label": "Badische Rechtsschutzversicherung Aktiengesellschaft",
      "value": "Badische Rechtsschutzversicherung Aktiengesellschaft"
    },
    {
      "label": "Badischer Gemeinde-Versicherungs-Verband",
      "value": "Badischer Gemeinde-Versicherungs-Verband"
    },
    {
      "label": "BAG Bankaktiengesellschaft",
      "value": "BAG Bankaktiengesellschaft"
    },
    {
      "label": "BALANCE RE AG",
      "value": "BALANCE RE AG"
    },
    {
      "label": "Balcia Insurance SE Niederlassung Deutschland",
      "value": "Balcia Insurance SE Niederlassung Deutschland"
    },
    {
      "label": "Baloise Lebensversicherung Aktiengesellschaft Deutschland",
      "value": "Baloise Lebensversicherung Aktiengesellschaft Deutschland"
    },
    {
      "label": "Baloise Sachversicherung Aktiengesellschaft Deutschland",
      "value": "Baloise Sachversicherung Aktiengesellschaft Deutschland"
    },
    {
      "label": "Banco Bilbao Vizcaya Argentaria, S.A., Niederlassung Deutschland",
      "value": "Banco Bilbao Vizcaya Argentaria, S.A., Niederlassung Deutschland"
    },
    {
      "label": "Banco do Brasil S.A. Zweigniederlassung Frankfurt/Main",
      "value": "Banco do Brasil S.A. Zweigniederlassung Frankfurt/Main"
    },
    {
      "label": "Banco Pastor Repräsentanz",
      "value": "Banco Pastor Repräsentanz"
    },
    {
      "label": "Banco Santander, S.A., Filiale Frankfurt",
      "value": "Banco Santander, S.A., Filiale Frankfurt"
    },
    {
      "label": "Bank 1 Saar eG",
      "value": "Bank 1 Saar eG"
    },
    {
      "label": "Bank Deutsches Kraftfahrzeuggewerbe GmbH",
      "value": "Bank Deutsches Kraftfahrzeuggewerbe GmbH"
    },
    {
      "label": "Bank für Kirche und Caritas eG",
      "value": "Bank für Kirche und Caritas eG"
    },
    {
      "label": "Bank für Kirche und Diakonie eG - KD-Bank",
      "value": "Bank für Kirche und Diakonie eG - KD-Bank"
    },
    {
      "label": "Bank für Sozialwirtschaft Aktiengesellschaft",
      "value": "Bank für Sozialwirtschaft Aktiengesellschaft"
    },
    {
      "label": "Bank für Tirol und Vorarlberg AG, Zweigniederlassung Deutschland",
      "value": "Bank für Tirol und Vorarlberg AG, Zweigniederlassung Deutschland"
    },
    {
      "label": "Bank Gospodarstwa Krajowego \"Repräsentanz",
      "value": "Bank Gospodarstwa Krajowego \"Repräsentanz"
    },
    {
      "label": "BANK IM BISTUM ESSEN eG",
      "value": "BANK IM BISTUM ESSEN eG"
    },
    {
      "label": "Bank Julius Bär Deutschland AG",
      "value": "Bank Julius Bär Deutschland AG"
    },
    {
      "label": "BANK MELLI IRAN",
      "value": "BANK MELLI IRAN"
    },
    {
      "label": "Bank of America Europe Designated Activity Company Zweigniederlassung Frankfurt am Main",
      "value": "Bank of America Europe Designated Activity Company Zweigniederlassung Frankfurt am Main"
    },
    {
      "label": "Bank of America, National Association Frankfurt Branch/ Filiale Frankfurt am Main",
      "value": "Bank of America, National Association Frankfurt Branch/ Filiale Frankfurt am Main"
    },
    {
      "label": "BANK OF CHINA LIMITED Zweigniederlassung Frankfurt am Main Frankfurt Branch",
      "value": "BANK OF CHINA LIMITED Zweigniederlassung Frankfurt am Main Frankfurt Branch"
    },
    {
      "label": "Bank of Communications Co., Ltd. Frankfurt branch",
      "value": "Bank of Communications Co., Ltd. Frankfurt branch"
    },
    {
      "label": "Bank of Ireland, Niederlassung Frankfurt",
      "value": "Bank of Ireland, Niederlassung Frankfurt"
    },
    {
      "label": "Bank of Taiwan Frankfurt Representative Office",
      "value": "Bank of Taiwan Frankfurt Representative Office"
    },
    {
      "label": "Bank Saderat Iran Zweigniederlassung Hamburg",
      "value": "Bank Saderat Iran Zweigniederlassung Hamburg"
    },
    {
      "label": "Bank Sepah-Iran, Filiale Frankfurt",
      "value": "Bank Sepah-Iran, Filiale Frankfurt"
    },
    {
      "label": "Bank Vontobel Europe AG",
      "value": "Bank Vontobel Europe AG"
    },
    {
      "label": "Bank11 für Privatkunden und Handel GmbH",
      "value": "Bank11 für Privatkunden und Handel GmbH"
    },
    {
      "label": "Bankhaus Anton Hafner KG",
      "value": "Bankhaus Anton Hafner KG"
    },
    {
      "label": "Bankhaus August Lenz & Co. Aktiengesellschaft",
      "value": "Bankhaus August Lenz & Co. Aktiengesellschaft"
    },
    {
      "label": "Bankhaus Bauer Aktiengesellschaft",
      "value": "Bankhaus Bauer Aktiengesellschaft"
    },
    {
      "label": "Bankhaus C.L. Seeliger",
      "value": "Bankhaus C.L. Seeliger"
    },
    {
      "label": "Bankhaus E. Mayer Aktiengesellschaft",
      "value": "Bankhaus E. Mayer Aktiengesellschaft"
    },
    {
      "label": "Bankhaus Ellwanger & Geiger AG",
      "value": "Bankhaus Ellwanger & Geiger AG"
    },
    {
      "label": "Bankhaus Gebr. Martin Aktiengesellschaft",
      "value": "Bankhaus Gebr. Martin Aktiengesellschaft"
    },
    {
      "label": "Bankhaus Herzogpark AG",
      "value": "Bankhaus Herzogpark AG"
    },
    {
      "label": "Bankhaus J. Faißt KG",
      "value": "Bankhaus J. Faißt KG"
    },
    {
      "label": "Bankhaus Ludwig Sperrer KG",
      "value": "Bankhaus Ludwig Sperrer KG"
    },
    {
      "label": "Bankhaus Max Flessa KG",
      "value": "Bankhaus Max Flessa KG"
    },
    {
      "label": "Bankhaus Obotritia GmbH",
      "value": "Bankhaus Obotritia GmbH"
    },
    {
      "label": "Bankhaus Rautenschlein AG",
      "value": "Bankhaus Rautenschlein AG"
    },
    {
      "label": "Bankhaus RSA eG",
      "value": "Bankhaus RSA eG"
    },
    {
      "label": "Bankhaus Scheich Wertpapierspezialist AG",
      "value": "Bankhaus Scheich Wertpapierspezialist AG"
    },
    {
      "label": "Bankhaus von der Heydt GmbH & Co. KG",
      "value": "Bankhaus von der Heydt GmbH & Co. KG"
    },
    {
      "label": "Bankhaus Werhahn GmbH",
      "value": "Bankhaus Werhahn GmbH"
    },
    {
      "label": "Banking Circle S.A. - German Branch",
      "value": "Banking Circle S.A. - German Branch"
    },
    {
      "label": "BankM AG",
      "value": "BankM AG"
    },
    {
      "label": "BANKSapi Technology GmbH",
      "value": "BANKSapi Technology GmbH"
    },
    {
      "label": "Banque Chaabi du Maroc S.A. Zweigniederlassung Deutschland",
      "value": "Banque Chaabi du Maroc S.A. Zweigniederlassung Deutschland"
    },
    {
      "label": "BANTLEON Invest GmbH",
      "value": "BANTLEON Invest GmbH"
    },
    {
      "label": "Barclays Bank Ireland PLC Frankfurt Branch",
      "value": "Barclays Bank Ireland PLC Frankfurt Branch"
    },
    {
      "label": "Baring International Fund Managers (Ireland) Limited, Frankfurt Branch",
      "value": "Baring International Fund Managers (Ireland) Limited, Frankfurt Branch"
    },
    {
      "label": "Barmenia Allgemeine Versicherungs-Aktiengesellschaft",
      "value": "Barmenia Allgemeine Versicherungs-Aktiengesellschaft"
    },
    {
      "label": "Barmenia Krankenversicherung AG",
      "value": "Barmenia Krankenversicherung AG"
    },
    {
      "label": "Barmenia Lebensversicherung a.G.",
      "value": "Barmenia Lebensversicherung a.G."
    },
    {
      "label": "Barmenia Versicherungen a.G.",
      "value": "Barmenia Versicherungen a.G."
    },
    {
      "label": "BASF Pensionskasse VVaG",
      "value": "BASF Pensionskasse VVaG"
    },
    {
      "label": "BASF Sterbekasse VVaG",
      "value": "BASF Sterbekasse VVaG"
    },
    {
      "label": "Bau- und Sparverein Geislingen eG",
      "value": "Bau- und Sparverein Geislingen eG"
    },
    {
      "label": "Bau- und Sparverein Göppingen eG",
      "value": "Bau- und Sparverein Göppingen eG"
    },
    {
      "label": "BAUER Vermögensverwaltung GmbH Co. KG",
      "value": "BAUER Vermögensverwaltung GmbH Co. KG"
    },
    {
      "label": "Baugenossenschaft \"Wiederaufbau\" eG",
      "value": "Baugenossenschaft \"Wiederaufbau\" eG"
    },
    {
      "label": "Baugenossenschaft Esslingen eG",
      "value": "Baugenossenschaft Esslingen eG"
    },
    {
      "label": "Baugenossenschaft Freie Scholle, eingetragene Genossenschaft",
      "value": "Baugenossenschaft Freie Scholle, eingetragene Genossenschaft"
    },
    {
      "label": "Baugenossenschaft Haltingen-Weil e.G.",
      "value": "Baugenossenschaft Haltingen-Weil e.G."
    },
    {
      "label": "Baugenossenschaft Münchberg eG",
      "value": "Baugenossenschaft Münchberg eG"
    },
    {
      "label": "Baugenossenschaft Spar- und Bauverein 1895 Mannheim e.G.",
      "value": "Baugenossenschaft Spar- und Bauverein 1895 Mannheim e.G."
    },
    {
      "label": "Baumeister & Borndörfer Finanzberatung GmbH & Co. KG",
      "value": "Baumeister & Borndörfer Finanzberatung GmbH & Co. KG"
    },
    {
      "label": "Bausparkasse Mainz Aktiengesellschaft",
      "value": "Bausparkasse Mainz Aktiengesellschaft"
    },
    {
      "label": "Bausparkasse Schwäbisch Hall Aktiengesellschaft, Bausparkasse der Volksbanken und Raiffeisenbanken",
      "value": "Bausparkasse Schwäbisch Hall Aktiengesellschaft, Bausparkasse der Volksbanken und Raiffeisenbanken"
    },
    {
      "label": "Bauverein Breisgau eG",
      "value": "Bauverein Breisgau eG"
    },
    {
      "label": "Bauverein Schweinfurt eG",
      "value": "Bauverein Schweinfurt eG"
    },
    {
      "label": "Bavaria Investments GmbH",
      "value": "Bavaria Investments GmbH"
    },
    {
      "label": "BavariaDirekt Versicherung AG",
      "value": "BavariaDirekt Versicherung AG"
    },
    {
      "label": "BAY Investment GmbH",
      "value": "BAY Investment GmbH"
    },
    {
      "label": "Bay Leasing e.K.",
      "value": "Bay Leasing e.K."
    },
    {
      "label": "Bayer Beistandskasse",
      "value": "Bayer Beistandskasse"
    },
    {
      "label": "Bayer-Pensionskasse",
      "value": "Bayer-Pensionskasse"
    },
    {
      "label": "Bayerische Beamten Lebensversicherung a.G.",
      "value": "Bayerische Beamten Lebensversicherung a.G."
    },
    {
      "label": "Bayerische Beamtenkrankenkasse Aktiengesellschaft",
      "value": "Bayerische Beamtenkrankenkasse Aktiengesellschaft"
    },
    {
      "label": "Bayerische Bodenseebank -Raiffeisen- eingetragene Genossenschaft",
      "value": "Bayerische Bodenseebank -Raiffeisen- eingetragene Genossenschaft"
    },
    {
      "label": "Bayerische Hausbesitzer-Versicherungs-Gesellschaft a.G.",
      "value": "Bayerische Hausbesitzer-Versicherungs-Gesellschaft a.G."
    },
    {
      "label": "Bayerische Landesbank",
      "value": "Bayerische Landesbank"
    },
    {
      "label": "Bayerische Landesbrandversicherung Aktiengesellschaft",
      "value": "Bayerische Landesbrandversicherung Aktiengesellschaft"
    },
    {
      "label": "Bayerische Vermögen Management AG",
      "value": "Bayerische Vermögen Management AG"
    },
    {
      "label": "Bayerischer Versicherungsverband Versicherungsaktiengesellschaft",
      "value": "Bayerischer Versicherungsverband Versicherungsaktiengesellschaft"
    },
    {
      "label": "Bayern Mezzaninekapital Fonds II GmbH & Co. KG",
      "value": "Bayern Mezzaninekapital Fonds II GmbH & Co. KG"
    },
    {
      "label": "Bayern-Versicherung Lebensversicherung Aktiengesellschaft",
      "value": "Bayern-Versicherung Lebensversicherung Aktiengesellschaft"
    },
    {
      "label": "BayernInvest Kapitalverwaltungsgesellschaft mbH",
      "value": "BayernInvest Kapitalverwaltungsgesellschaft mbH"
    },
    {
      "label": "Bayernwerk AG",
      "value": "Bayernwerk AG"
    },
    {
      "label": "BAZ Vermögensverwaltung AG",
      "value": "BAZ Vermögensverwaltung AG"
    },
    {
      "label": "BB Alternatives GmbH",
      "value": "BB Alternatives GmbH"
    },
    {
      "label": "BB-Wertpapier-Verwaltungsgesellschaft mbH",
      "value": "BB-Wertpapier-Verwaltungsgesellschaft mbH"
    },
    {
      "label": "BBB Bürgschaftsbank zu Berlin-Brandenburg GmbH",
      "value": "BBB Bürgschaftsbank zu Berlin-Brandenburg GmbH"
    },
    {
      "label": "BBBank eG",
      "value": "BBBank eG"
    },
    {
      "label": "BD24 Berlin Direkt Versicherung AG",
      "value": "BD24 Berlin Direkt Versicherung AG"
    },
    {
      "label": "BDK Leasing und Service GmbH",
      "value": "BDK Leasing und Service GmbH"
    },
    {
      "label": "BDT & Company Europe GmbH",
      "value": "BDT & Company Europe GmbH"
    },
    {
      "label": "BE Investment-Partners GmbH",
      "value": "BE Investment-Partners GmbH"
    },
    {
      "label": "Beamten-Wohnungs-Verein zu Hildesheim eG",
      "value": "Beamten-Wohnungs-Verein zu Hildesheim eG"
    },
    {
      "label": "Beamten-Wohnungs-Verein zu Köpenick eG",
      "value": "Beamten-Wohnungs-Verein zu Köpenick eG"
    },
    {
      "label": "beauty alliance FINANCE GmbH",
      "value": "beauty alliance FINANCE GmbH"
    },
    {
      "label": "Beazley Insurance dac",
      "value": "Beazley Insurance dac"
    },
    {
      "label": "BEB Kapitalverwaltungsgesellschaft mbH",
      "value": "BEB Kapitalverwaltungsgesellschaft mbH"
    },
    {
      "label": "Bechtle Financial Services AG",
      "value": "Bechtle Financial Services AG"
    },
    {
      "label": "Beit Capital Advisors GmbH",
      "value": "Beit Capital Advisors GmbH"
    },
    {
      "label": "belk-mobil GmbH",
      "value": "belk-mobil GmbH"
    },
    {
      "label": "Bellevue Asset Management (Deutschland) GmbH",
      "value": "Bellevue Asset Management (Deutschland) GmbH"
    },
    {
      "label": "Bensberger Bank eG",
      "value": "Bensberger Bank eG"
    },
    {
      "label": "BENZ AG",
      "value": "BENZ AG"
    },
    {
      "label": "Beresa Leasing GmbH",
      "value": "Beresa Leasing GmbH"
    },
    {
      "label": "Bergische Brandversicherung Allgemeine Feuerversicherung V.a.G.",
      "value": "Bergische Brandversicherung Allgemeine Feuerversicherung V.a.G."
    },
    {
      "label": "Bergische Licht-,Kraft- und Wasserwerke (BELKAW) Gesellschaft mit beschränkter Haftung",
      "value": "Bergische Licht-,Kraft- und Wasserwerke (BELKAW) Gesellschaft mit beschränkter Haftung"
    },
    {
      "label": "Berkshire Hathaway European Insurance DAC, Deutschland",
      "value": "Berkshire Hathaway European Insurance DAC, Deutschland"
    },
    {
      "label": "Berlin Hyp AG",
      "value": "Berlin Hyp AG"
    },
    {
      "label": "Berlin Innovation Ventures GmbH",
      "value": "Berlin Innovation Ventures GmbH"
    },
    {
      "label": "Berliner Bau- und Wohnungsgenossenschaft von 1892 eG.",
      "value": "Berliner Bau- und Wohnungsgenossenschaft von 1892 eG."
    },
    {
      "label": "Berliner Volksbank eG",
      "value": "Berliner Volksbank eG"
    },
    {
      "label": "Bernhardt Advisory GmbH",
      "value": "Bernhardt Advisory GmbH"
    },
    {
      "label": "Bernhauser Bank eG",
      "value": "Bernhauser Bank eG"
    },
    {
      "label": "Bernstein Bank GmbH",
      "value": "Bernstein Bank GmbH"
    },
    {
      "label": "Bernstein Financial Services GmbH",
      "value": "Bernstein Financial Services GmbH"
    },
    {
      "label": "Berufsgemeinschaft der Angehörigen der Deutschen Bundesbank in Nordrhein-Westfalen e.V.",
      "value": "Berufsgemeinschaft der Angehörigen der Deutschen Bundesbank in Nordrhein-Westfalen e.V."
    },
    {
      "label": "bestadvice Vermögenstreuhand GmbH",
      "value": "bestadvice Vermögenstreuhand GmbH"
    },
    {
      "label": "Bethmann Bank AG",
      "value": "Bethmann Bank AG"
    },
    {
      "label": "Betriebspensionskasse der Firma Carl Schenck AG VVaG Darmstadt",
      "value": "Betriebspensionskasse der Firma Carl Schenck AG VVaG Darmstadt"
    },
    {
      "label": "Bettenring eG",
      "value": "Bettenring eG"
    },
    {
      "label": "Betz Lutz & Kollegen Vermögensverwaltung GmbH",
      "value": "Betz Lutz & Kollegen Vermögensverwaltung GmbH"
    },
    {
      "label": "BEWIDATA Unternehmensberatung und EDV-Service GmbH",
      "value": "BEWIDATA Unternehmensberatung und EDV-Service GmbH"
    },
    {
      "label": "BEYOND CAPITAL Management GmbH",
      "value": "BEYOND CAPITAL Management GmbH"
    },
    {
      "label": "Beyond Capital Partners Fund I GmbH & Co. KG",
      "value": "Beyond Capital Partners Fund I GmbH & Co. KG"
    },
    {
      "label": "Beyond Capital Partners Fund II GmbH & Co. KG",
      "value": "Beyond Capital Partners Fund II GmbH & Co. KG"
    },
    {
      "label": "Beyond Feeder II GmbH & Co. KG",
      "value": "Beyond Feeder II GmbH & Co. KG"
    },
    {
      "label": "Bezirkssparkasse Reichenau",
      "value": "Bezirkssparkasse Reichenau"
    },
    {
      "label": "BFL Leasing GmbH",
      "value": "BFL Leasing GmbH"
    },
    {
      "label": "BFP-Q Management GmbH",
      "value": "BFP-Q Management GmbH"
    },
    {
      "label": "BFS Abrechnungs GmbH",
      "value": "BFS Abrechnungs GmbH"
    },
    {
      "label": "BFS finance GmbH",
      "value": "BFS finance GmbH"
    },
    {
      "label": "BFS health finance GmbH",
      "value": "BFS health finance GmbH"
    },
    {
      "label": "BFS Service GmbH",
      "value": "BFS Service GmbH"
    },
    {
      "label": "BfV Bank für Vermögen AG",
      "value": "BfV Bank für Vermögen AG"
    },
    {
      "label": "BfW - Bank für Wohnungswirtschaft AG",
      "value": "BfW - Bank für Wohnungswirtschaft AG"
    },
    {
      "label": "BGG Bayerische Garantiegesellschaft mit beschränkter Haftung für mittelständische Beteiligungen",
      "value": "BGG Bayerische Garantiegesellschaft mit beschränkter Haftung für mittelständische Beteiligungen"
    },
    {
      "label": "BGL BNP Paribas, Niederlassung Deutschland",
      "value": "BGL BNP Paribas, Niederlassung Deutschland"
    },
    {
      "label": "BGV-Versicherung Aktiengesellschaft",
      "value": "BGV-Versicherung Aktiengesellschaft"
    },
    {
      "label": "BHS-Leasing GmbH & Co. KG",
      "value": "BHS-Leasing GmbH & Co. KG"
    },
    {
      "label": "BHW Bausparkasse Aktiengesellschaft",
      "value": "BHW Bausparkasse Aktiengesellschaft"
    },
    {
      "label": "Bibby Financial Services GmbH",
      "value": "Bibby Financial Services GmbH"
    },
    {
      "label": "BID Equity Investment & Management GmbH",
      "value": "BID Equity Investment & Management GmbH"
    },
    {
      "label": "Billie GmbH",
      "value": "Billie GmbH"
    },
    {
      "label": "BILTON Capital GmbH",
      "value": "BILTON Capital GmbH"
    },
    {
      "label": "Bioventure Club Deal Nine GmbH & Co. KG",
      "value": "Bioventure Club Deal Nine GmbH & Co. KG"
    },
    {
      "label": "Bioventure Management GmbH",
      "value": "Bioventure Management GmbH"
    },
    {
      "label": "BIT Capital GmbH",
      "value": "BIT Capital GmbH"
    },
    {
      "label": "Bitalo Aktiengesellschaft",
      "value": "Bitalo Aktiengesellschaft"
    },
    {
      "label": "BITKRAFT Esports Ventures Management GmbH",
      "value": "BITKRAFT Esports Ventures Management GmbH"
    },
    {
      "label": "BitStone Capital Management GmbH",
      "value": "BitStone Capital Management GmbH"
    },
    {
      "label": "Bizerba Financial Services GmbH",
      "value": "Bizerba Financial Services GmbH"
    },
    {
      "label": "BK Ventures Management GmbH",
      "value": "BK Ventures Management GmbH"
    },
    {
      "label": "BL die Bayerische Lebensversicherung AG",
      "value": "BL die Bayerische Lebensversicherung AG"
    },
    {
      "label": "BlackPoint Asset Management GmbH",
      "value": "BlackPoint Asset Management GmbH"
    },
    {
      "label": "blackprint Booster Fonds GmbH & Co. KG",
      "value": "blackprint Booster Fonds GmbH & Co. KG"
    },
    {
      "label": "blackprint Booster Fonds International GmbH & Co. KG",
      "value": "blackprint Booster Fonds International GmbH & Co. KG"
    },
    {
      "label": "BlackRock Asset Management Deutschland AG",
      "value": "BlackRock Asset Management Deutschland AG"
    },
    {
      "label": "BlackRock B.V. (Netherlands) - Munich Branch",
      "value": "BlackRock B.V. (Netherlands) - Munich Branch"
    },
    {
      "label": "Blackster Management GmbH",
      "value": "Blackster Management GmbH"
    },
    {
      "label": "Blackstone Europe Fund Management S.à r.l. - Germany",
      "value": "Blackstone Europe Fund Management S.à r.l. - Germany"
    },
    {
      "label": "Block Investments GmbH & Co. KG",
      "value": "Block Investments GmbH & Co. KG"
    },
    {
      "label": "Blockchain Founders Capital Management GmbH",
      "value": "Blockchain Founders Capital Management GmbH"
    },
    {
      "label": "Blockwall Management GmbH",
      "value": "Blockwall Management GmbH"
    },
    {
      "label": "BLSW Seedfonds GmbH & Co. KG",
      "value": "BLSW Seedfonds GmbH & Co. KG"
    },
    {
      "label": "Blue Suisse Limited, Repräsentanz Deutschland",
      "value": "Blue Suisse Limited, Repräsentanz Deutschland"
    },
    {
      "label": "BlueBay Funds Management Company S.A. - Zweigniederlassung Deutschland",
      "value": "BlueBay Funds Management Company S.A. - Zweigniederlassung Deutschland"
    },
    {
      "label": "Bluhm Leasing GmbH & Co. KG",
      "value": "Bluhm Leasing GmbH & Co. KG"
    },
    {
      "label": "BMC Leasing GmbH",
      "value": "BMC Leasing GmbH"
    },
    {
      "label": "BMCP GmbH",
      "value": "BMCP GmbH"
    },
    {
      "label": "BMS Finanz Consulting GmbH",
      "value": "BMS Finanz Consulting GmbH"
    },
    {
      "label": "BMW Bank GmbH",
      "value": "BMW Bank GmbH"
    },
    {
      "label": "BN & Partners Capital AG",
      "value": "BN & Partners Capital AG"
    },
    {
      "label": "BNP Paribas Asset Management France, Zweigniederlassung Deutschland",
      "value": "BNP Paribas Asset Management France, Zweigniederlassung Deutschland"
    },
    {
      "label": "BNP Paribas Factor GmbH",
      "value": "BNP Paribas Factor GmbH"
    },
    {
      "label": "BNP PARIBAS LEASE GROUP S.A. Zweigniederlassung Deutschland",
      "value": "BNP PARIBAS LEASE GROUP S.A. Zweigniederlassung Deutschland"
    },
    {
      "label": "BNP Paribas Real Estate Investment Management Germany GmbH",
      "value": "BNP Paribas Real Estate Investment Management Germany GmbH"
    },
    {
      "label": "BNP Paribas S.A. Niederlassung Deutschland",
      "value": "BNP Paribas S.A. Niederlassung Deutschland"
    },
    {
      "label": "BNY Mellon Fund Management (Luxembourg) S.A., Zweigniederlassung, Deutschland",
      "value": "BNY Mellon Fund Management (Luxembourg) S.A., Zweigniederlassung, Deutschland"
    },
    {
      "label": "BNY Mellon Service Kapitalanlage-Gesellschaft mbH",
      "value": "BNY Mellon Service Kapitalanlage-Gesellschaft mbH"
    },
    {
      "label": "Bochumer Versicherungsverein auf Gegenseitigkeit",
      "value": "Bochumer Versicherungsverein auf Gegenseitigkeit"
    },
    {
      "label": "Boerse Stuttgart cats GmbH",
      "value": "Boerse Stuttgart cats GmbH"
    },
    {
      "label": "boerse. de Vermögensverwaltung GmbH",
      "value": "boerse. de Vermögensverwaltung GmbH"
    },
    {
      "label": "Boldt & Co. GmbH",
      "value": "Boldt & Co. GmbH"
    },
    {
      "label": "bon Leasing GmbH",
      "value": "bon Leasing GmbH"
    },
    {
      "label": "BonVenture Management GmbH",
      "value": "BonVenture Management GmbH"
    },
    {
      "label": "BOOOM Management GmbH",
      "value": "BOOOM Management GmbH"
    },
    {
      "label": "Bopfinger Bank Sechta-Ries eG",
      "value": "Bopfinger Bank Sechta-Ries eG"
    },
    {
      "label": "Bordesholmer Sparkasse Aktiengesellschaft",
      "value": "Bordesholmer Sparkasse Aktiengesellschaft"
    },
    {
      "label": "Borromin Capital Management GmbH",
      "value": "Borromin Capital Management GmbH"
    },
    {
      "label": "Bosch Pensionsfonds Aktiengesellschaft",
      "value": "Bosch Pensionsfonds Aktiengesellschaft"
    },
    {
      "label": "BPE 4 Unternehmensbeteiligungen G.m.b.H",
      "value": "BPE 4 Unternehmensbeteiligungen G.m.b.H"
    },
    {
      "label": "BPE Unternehmensbeteiligungen G.m.b.H.",
      "value": "BPE Unternehmensbeteiligungen G.m.b.H."
    },
    {
      "label": "BPI - Gemini Express Gesellschaft mit beschränkter Haftung",
      "value": "BPI - Gemini Express Gesellschaft mit beschränkter Haftung"
    },
    {
      "label": "BPM - Berlin Portfolio Management GmbH",
      "value": "BPM - Berlin Portfolio Management GmbH"
    },
    {
      "label": "Brandenburger Bank Volksbank-Raiffeisenbank eG",
      "value": "Brandenburger Bank Volksbank-Raiffeisenbank eG"
    },
    {
      "label": "Brass Carlease GmbH & Co. KG",
      "value": "Brass Carlease GmbH & Co. KG"
    },
    {
      "label": "Braunschweiger Baugenossenschaft eingetragene Genossenschaft",
      "value": "Braunschweiger Baugenossenschaft eingetragene Genossenschaft"
    },
    {
      "label": "BraWo Capital Management GmbH",
      "value": "BraWo Capital Management GmbH"
    },
    {
      "label": "Brehmer & Cie GmbH",
      "value": "Brehmer & Cie GmbH"
    },
    {
      "label": "Breidenbach von Schlieffen & Co. GmbH",
      "value": "Breidenbach von Schlieffen & Co. GmbH"
    },
    {
      "label": "Breiling, Spohn & Kollegen Vermögensverwaltung GmbH",
      "value": "Breiling, Spohn & Kollegen Vermögensverwaltung GmbH"
    },
    {
      "label": "Bremer Aufbau-Bank GmbH",
      "value": "Bremer Aufbau-Bank GmbH"
    },
    {
      "label": "Bremer Family Office AG",
      "value": "Bremer Family Office AG"
    },
    {
      "label": "Bremische Volksbank eG",
      "value": "Bremische Volksbank eG"
    },
    {
      "label": "BRIGHT Capital SME Debt Fund I GmbH & Co. KG",
      "value": "BRIGHT Capital SME Debt Fund I GmbH & Co. KG"
    },
    {
      "label": "Brightpoint Capital Invest GmbH",
      "value": "Brightpoint Capital Invest GmbH"
    },
    {
      "label": "Brilliant Vermögensverwaltung für den Mittelstand GmbH",
      "value": "Brilliant Vermögensverwaltung für den Mittelstand GmbH"
    },
    {
      "label": "Broad Reach GmbH",
      "value": "Broad Reach GmbH"
    },
    {
      "label": "Brose Trust GmbH",
      "value": "Brose Trust GmbH"
    },
    {
      "label": "Brown Advisory (Ireland) Limited, German Branch",
      "value": "Brown Advisory (Ireland) Limited, German Branch"
    },
    {
      "label": "Bruno Willemsen Vermögensverwaltungen e.K.",
      "value": "Bruno Willemsen Vermögensverwaltungen e.K."
    },
    {
      "label": "BRW Finanz AG",
      "value": "BRW Finanz AG"
    },
    {
      "label": "Brühler Bank eG",
      "value": "Brühler Bank eG"
    },
    {
      "label": "BSQ Bauspar AG",
      "value": "BSQ Bauspar AG"
    },
    {
      "label": "BTG Bridge To Growth GmbH",
      "value": "BTG Bridge To Growth GmbH"
    },
    {
      "label": "BTV Leasing Deutschland GmbH",
      "value": "BTV Leasing Deutschland GmbH"
    },
    {
      "label": "Budenheimer Volksbank eG",
      "value": "Budenheimer Volksbank eG"
    },
    {
      "label": "BUHL-DATA-SERVICE GmbH",
      "value": "BUHL-DATA-SERVICE GmbH"
    },
    {
      "label": "Build & Invest GmbH",
      "value": "Build & Invest GmbH"
    },
    {
      "label": "bunch capital GmbH",
      "value": "bunch capital GmbH"
    },
    {
      "label": "bunq BV Niederlassung Deutschland",
      "value": "bunq BV Niederlassung Deutschland"
    },
    {
      "label": "Burkhardt Vermögensverwaltung GmbH",
      "value": "Burkhardt Vermögensverwaltung GmbH"
    },
    {
      "label": "BV & P Vermögen AG",
      "value": "BV & P Vermögen AG"
    },
    {
      "label": "BV Bayerische Vermögen GmbH",
      "value": "BV Bayerische Vermögen GmbH"
    },
    {
      "label": "BV Transaction Services GmbH",
      "value": "BV Transaction Services GmbH"
    },
    {
      "label": "BVAG Berliner Versicherung Aktiengesellschaft",
      "value": "BVAG Berliner Versicherung Aktiengesellschaft"
    },
    {
      "label": "BVV Pensionsfonds des Bankgewerbes AG",
      "value": "BVV Pensionsfonds des Bankgewerbes AG"
    },
    {
      "label": "BVV Versicherungsverein des Bankgewerbes a.G.",
      "value": "BVV Versicherungsverein des Bankgewerbes a.G."
    },
    {
      "label": "BY Capital Management GmbH",
      "value": "BY Capital Management GmbH"
    },
    {
      "label": "BÄKO-ZENTRALE eG",
      "value": "BÄKO-ZENTRALE eG"
    },
    {
      "label": "Böhmer & Schilcher Vermögensverwaltung GmbH",
      "value": "Böhmer & Schilcher Vermögensverwaltung GmbH"
    },
    {
      "label": "Böker & Paul AG - Kanzlei für Vermögensmanagement",
      "value": "Böker & Paul AG - Kanzlei für Vermögensmanagement"
    },
    {
      "label": "Bürgschaftsbank Baden-Württemberg Gesellschaft mit beschränkter Haftung",
      "value": "Bürgschaftsbank Baden-Württemberg Gesellschaft mit beschränkter Haftung"
    },
    {
      "label": "Bürgschaftsbank Bayern GmbH",
      "value": "Bürgschaftsbank Bayern GmbH"
    },
    {
      "label": "Bürgschaftsbank Brandenburg GmbH",
      "value": "Bürgschaftsbank Brandenburg GmbH"
    },
    {
      "label": "Bürgschaftsbank Bremen GmbH",
      "value": "Bürgschaftsbank Bremen GmbH"
    },
    {
      "label": "Bürgschaftsbank Hamburg GmbH",
      "value": "Bürgschaftsbank Hamburg GmbH"
    },
    {
      "label": "Bürgschaftsbank Hessen GmbH",
      "value": "Bürgschaftsbank Hessen GmbH"
    },
    {
      "label": "Bürgschaftsbank Mecklenburg-Vorpommern GmbH",
      "value": "Bürgschaftsbank Mecklenburg-Vorpommern GmbH"
    },
    {
      "label": "Bürgschaftsbank Nordrhein-Westfalen GmbH Kreditgarantiegemeinschaft",
      "value": "Bürgschaftsbank Nordrhein-Westfalen GmbH Kreditgarantiegemeinschaft"
    },
    {
      "label": "Bürgschaftsbank Rheinland-Pfalz GmbH",
      "value": "Bürgschaftsbank Rheinland-Pfalz GmbH"
    },
    {
      "label": "Bürgschaftsbank Saarland Gesellschaft mit beschränkter Haftung, Kreditgarantiegemeinschaft für Handel, Handwerk und Gewerbe",
      "value": "Bürgschaftsbank Saarland Gesellschaft mit beschränkter Haftung, Kreditgarantiegemeinschaft für Handel, Handwerk und Gewerbe"
    },
    {
      "label": "Bürgschaftsbank Sachsen GmbH",
      "value": "Bürgschaftsbank Sachsen GmbH"
    },
    {
      "label": "Bürgschaftsbank Sachsen-Anhalt GmbH",
      "value": "Bürgschaftsbank Sachsen-Anhalt GmbH"
    },
    {
      "label": "Bürgschaftsbank Schleswig-Holstein Gesellschaft mit beschränkter Haftung",
      "value": "Bürgschaftsbank Schleswig-Holstein Gesellschaft mit beschränkter Haftung"
    },
    {
      "label": "Bürgschaftsbank Thüringen GmbH",
      "value": "Bürgschaftsbank Thüringen GmbH"
    },
    {
      "label": "Büttner, Kolberg & Partner - Vermögensverwalter - GmbH",
      "value": "Büttner, Kolberg & Partner - Vermögensverwalter - GmbH"
    },
    {
      "label": "C24 Bank GmbH",
      "value": "C24 Bank GmbH"
    },
    {
      "label": "C3 EOS VC I GmbH & Co. KG",
      "value": "C3 EOS VC I GmbH & Co. KG"
    },
    {
      "label": "C3 Management GmbH",
      "value": "C3 Management GmbH"
    },
    {
      "label": "CACEIS Bank S.A., Germany Branch",
      "value": "CACEIS Bank S.A., Germany Branch"
    },
    {
      "label": "Cadence Growth Capital GmbH",
      "value": "Cadence Growth Capital GmbH"
    },
    {
      "label": "Caisse d'Épargne et de Prévoyance Grand Est Europe Repräsentanz",
      "value": "Caisse d'Épargne et de Prévoyance Grand Est Europe Repräsentanz"
    },
    {
      "label": "Caixa Económica Montepio Geral - Repräsentanz Frankfurt",
      "value": "Caixa Económica Montepio Geral - Repräsentanz Frankfurt"
    },
    {
      "label": "Caixa Geral de Depositos, S.A. Repräsentanz",
      "value": "Caixa Geral de Depositos, S.A. Repräsentanz"
    },
    {
      "label": "CAIXABANK S.A. Zweigniederlassung Deutschland",
      "value": "CAIXABANK S.A. Zweigniederlassung Deutschland"
    },
    {
      "label": "CALA Kapitalverwaltungsgesellschaft GmbH & Co. KG",
      "value": "CALA Kapitalverwaltungsgesellschaft GmbH & Co. KG"
    },
    {
      "label": "Calenberger Kreditverein Calenberg-Göttingen- Grubenhagen- Hildesheim'scher ritterschaftlicher Kreditverein",
      "value": "Calenberger Kreditverein Calenberg-Göttingen- Grubenhagen- Hildesheim'scher ritterschaftlicher Kreditverein"
    },
    {
      "label": "Caleus European Investment Management GmbH",
      "value": "Caleus European Investment Management GmbH"
    },
    {
      "label": "CAM Alternatives GmbH",
      "value": "CAM Alternatives GmbH"
    },
    {
      "label": "Cambridge Associates GmbH",
      "value": "Cambridge Associates GmbH"
    },
    {
      "label": "Canada Life Assurance Europe plc Niederlassung für Deutschland",
      "value": "Canada Life Assurance Europe plc Niederlassung für Deutschland"
    },
    {
      "label": "CANCOM Financial Services GmbH",
      "value": "CANCOM Financial Services GmbH"
    },
    {
      "label": "Candriam Zweigniederlassung Deutschland",
      "value": "Candriam Zweigniederlassung Deutschland"
    },
    {
      "label": "CAP4CAP GmbH & Co. KG",
      "value": "CAP4CAP GmbH & Co. KG"
    },
    {
      "label": "CAPCELLENCE Verwaltungsgesellschaft mbH",
      "value": "CAPCELLENCE Verwaltungsgesellschaft mbH"
    },
    {
      "label": "Capital Bay Fund Management-Niederlassung Deutschland",
      "value": "Capital Bay Fund Management-Niederlassung Deutschland"
    },
    {
      "label": "Capital International Management Company, Niederlassung Deutschland",
      "value": "Capital International Management Company, Niederlassung Deutschland"
    },
    {
      "label": "Capital Tecnet Investment Group GmbH",
      "value": "Capital Tecnet Investment Group GmbH"
    },
    {
      "label": "Capitell Vermögens-Management AG",
      "value": "Capitell Vermögens-Management AG"
    },
    {
      "label": "capiton AG",
      "value": "capiton AG"
    },
    {
      "label": "caplantic GmbH",
      "value": "caplantic GmbH"
    },
    {
      "label": "Capnamic Ventures Management GmbH",
      "value": "Capnamic Ventures Management GmbH"
    },
    {
      "label": "Capricorn Capital GmbH & Co. KG",
      "value": "Capricorn Capital GmbH & Co. KG"
    },
    {
      "label": "CapSolutions GmbH",
      "value": "CapSolutions GmbH"
    },
    {
      "label": "CapTrader GmbH",
      "value": "CapTrader GmbH"
    },
    {
      "label": "CAPVERIANT GmbH",
      "value": "CAPVERIANT GmbH"
    },
    {
      "label": "Cardif Allgemeine Versicherung Zweigniederlassung für Deutschland der Cardif-Assurances Risques Divers",
      "value": "Cardif Allgemeine Versicherung Zweigniederlassung für Deutschland der Cardif-Assurances Risques Divers"
    },
    {
      "label": "Cardif Lebensversicherung Zweigniederlassung für Deutschland der Cardif Assurance Vie",
      "value": "Cardif Lebensversicherung Zweigniederlassung für Deutschland der Cardif Assurance Vie"
    },
    {
      "label": "Cargobull Finance GmbH",
      "value": "Cargobull Finance GmbH"
    },
    {
      "label": "Carl von Rohrer Vermögensverwaltungs GmbH & Co. KG",
      "value": "Carl von Rohrer Vermögensverwaltungs GmbH & Co. KG"
    },
    {
      "label": "CARMA FUND Management GmbH",
      "value": "CARMA FUND Management GmbH"
    },
    {
      "label": "CARpediem GmbH",
      "value": "CARpediem GmbH"
    },
    {
      "label": "Cash Factory GmbH",
      "value": "Cash Factory GmbH"
    },
    {
      "label": "Caspara GmbH",
      "value": "Caspara GmbH"
    },
    {
      "label": "Catella Real Estate AG",
      "value": "Catella Real Estate AG"
    },
    {
      "label": "Caterpillar Financial Services GmbH",
      "value": "Caterpillar Financial Services GmbH"
    },
    {
      "label": "CATUS AG Vermögensverwaltung",
      "value": "CATUS AG Vermögensverwaltung"
    },
    {
      "label": "CAV Kapitalverwaltungs GmbH",
      "value": "CAV Kapitalverwaltungs GmbH"
    },
    {
      "label": "Cavalry Ventures Management GmbH",
      "value": "Cavalry Ventures Management GmbH"
    },
    {
      "label": "CAVENTES Kapitalverwaltung GmbH & Co. KG",
      "value": "CAVENTES Kapitalverwaltung GmbH & Co. KG"
    },
    {
      "label": "CB Bank GmbH",
      "value": "CB Bank GmbH"
    },
    {
      "label": "CB Christoph Baur Vermögensverwaltung GmbH",
      "value": "CB Christoph Baur Vermögensverwaltung GmbH"
    },
    {
      "label": "CBN GERMANY GmbH",
      "value": "CBN GERMANY GmbH"
    },
    {
      "label": "CBRE Capital Advisors GmbH",
      "value": "CBRE Capital Advisors GmbH"
    },
    {
      "label": "CCAP Management GmbH & Co. KG",
      "value": "CCAP Management GmbH & Co. KG"
    },
    {
      "label": "Cecabank, S.A. Repräsentanz Deutschland",
      "value": "Cecabank, S.A. Repräsentanz Deutschland"
    },
    {
      "label": "CEE Kapitalverwaltungsgesellschaft mbH",
      "value": "CEE Kapitalverwaltungsgesellschaft mbH"
    },
    {
      "label": "Central Asset Management AG",
      "value": "Central Asset Management AG"
    },
    {
      "label": "CEPRES Capital Solutions GmbH",
      "value": "CEPRES Capital Solutions GmbH"
    },
    {
      "label": "CF Commercial Factoring Gesellschaft mit beschränkter Haftung, Factoring für den Mittelstand",
      "value": "CF Commercial Factoring Gesellschaft mit beschränkter Haftung, Factoring für den Mittelstand"
    },
    {
      "label": "cflox GmbH",
      "value": "cflox GmbH"
    },
    {
      "label": "CG Car-Garantie Versicherungs-Aktiengesellschaft",
      "value": "CG Car-Garantie Versicherungs-Aktiengesellschaft"
    },
    {
      "label": "CGPA Europe Underwriting GmbH",
      "value": "CGPA Europe Underwriting GmbH"
    },
    {
      "label": "Chartered Investment Germany GmbH",
      "value": "Chartered Investment Germany GmbH"
    },
    {
      "label": "CHEMIE Pensionsfonds AG",
      "value": "CHEMIE Pensionsfonds AG"
    },
    {
      "label": "Chemnitzer Siedlungsgemeinschaft eG",
      "value": "Chemnitzer Siedlungsgemeinschaft eG"
    },
    {
      "label": "Cherry Ventures Management GmbH",
      "value": "Cherry Ventures Management GmbH"
    },
    {
      "label": "CHG-MERIDIAN AG",
      "value": "CHG-MERIDIAN AG"
    },
    {
      "label": "CHG-MERIDIAN Industrial Solutions GmbH",
      "value": "CHG-MERIDIAN Industrial Solutions GmbH"
    },
    {
      "label": "Chiemgau Vermögen GmbH",
      "value": "Chiemgau Vermögen GmbH"
    },
    {
      "label": "China Construction Bank Corporation Niederlassung Frankfurt",
      "value": "China Construction Bank Corporation Niederlassung Frankfurt"
    },
    {
      "label": "CHOM CAPITAL GmbH",
      "value": "CHOM CAPITAL GmbH"
    },
    {
      "label": "Christian Hintz Vermögensverwaltung GmbH",
      "value": "Christian Hintz Vermögensverwaltung GmbH"
    },
    {
      "label": "CHUBB European Group SE, Direktion für Deutschland",
      "value": "CHUBB European Group SE, Direktion für Deutschland"
    },
    {
      "label": "CHUBB Life Europe SE, Direktion für Deutschland",
      "value": "CHUBB Life Europe SE, Direktion für Deutschland"
    },
    {
      "label": "CIC Repräsentanz für Deutschland",
      "value": "CIC Repräsentanz für Deutschland"
    },
    {
      "label": "CIGNA Life Insurance Company of Europe S.A.-N.V. Direktion Für Deutschland",
      "value": "CIGNA Life Insurance Company of Europe S.A.-N.V. Direktion Für Deutschland"
    },
    {
      "label": "Circle Eleven GmbH",
      "value": "Circle Eleven GmbH"
    },
    {
      "label": "CIS Asset Management (Deutschland) GmbH",
      "value": "CIS Asset Management (Deutschland) GmbH"
    },
    {
      "label": "Cisco Systems Capital GmbH",
      "value": "Cisco Systems Capital GmbH"
    },
    {
      "label": "Citibank Europe plc, Germany Branch",
      "value": "Citibank Europe plc, Germany Branch"
    },
    {
      "label": "Citibank N.A. in New York, Filiale Frankfurt/Main",
      "value": "Citibank N.A. in New York, Filiale Frankfurt/Main"
    },
    {
      "label": "Citigroup Global Markets Europe AG",
      "value": "Citigroup Global Markets Europe AG"
    },
    {
      "label": "CL Capital Management GmbH",
      "value": "CL Capital Management GmbH"
    },
    {
      "label": "CLAAS FINANCIAL SERVICES S.A.S. Zweigniederlassung Deutschland",
      "value": "CLAAS FINANCIAL SERVICES S.A.S. Zweigniederlassung Deutschland"
    },
    {
      "label": "Clearstream Banking Aktiengesellschaft",
      "value": "Clearstream Banking Aktiengesellschaft"
    },
    {
      "label": "cleverbridge Financial Services GmbH",
      "value": "cleverbridge Financial Services GmbH"
    },
    {
      "label": "Cleverle! Management GmbH",
      "value": "Cleverle! Management GmbH"
    },
    {
      "label": "cleverscout Fuhrparkservice GmbH",
      "value": "cleverscout Fuhrparkservice GmbH"
    },
    {
      "label": "Close Brothers Factoring GmbH",
      "value": "Close Brothers Factoring GmbH"
    },
    {
      "label": "CM Leasing GmbH",
      "value": "CM Leasing GmbH"
    },
    {
      "label": "CM-Equity AG",
      "value": "CM-Equity AG"
    },
    {
      "label": "CM-Equity Kapitalverwaltungsgesellschaft mbH",
      "value": "CM-Equity Kapitalverwaltungsgesellschaft mbH"
    },
    {
      "label": "CMC Markets Germany GmbH",
      "value": "CMC Markets Germany GmbH"
    },
    {
      "label": "CMTA AG - Zweigniederlassung Deutschland",
      "value": "CMTA AG - Zweigniederlassung Deutschland"
    },
    {
      "label": "CN Finanzmanagement GmbH",
      "value": "CN Finanzmanagement GmbH"
    },
    {
      "label": "CNA Insurance Company (Europe) S.A.",
      "value": "CNA Insurance Company (Europe) S.A."
    },
    {
      "label": "CNH Industrial Capital Europe S.A.S. Zweigniederlassung Deutschland",
      "value": "CNH Industrial Capital Europe S.A.S. Zweigniederlassung Deutschland"
    },
    {
      "label": "Coba-Baustoffgesellschaft für Dach + Wand GmbH & Co. KG",
      "value": "Coba-Baustoffgesellschaft für Dach + Wand GmbH & Co. KG"
    },
    {
      "label": "Coface Finanz GmbH",
      "value": "Coface Finanz GmbH"
    },
    {
      "label": "COGIUM GmbH & Co. KG",
      "value": "COGIUM GmbH & Co. KG"
    },
    {
      "label": "COI Partners Deutschland GmbH",
      "value": "COI Partners Deutschland GmbH"
    },
    {
      "label": "Coinbase Germany GmbH",
      "value": "Coinbase Germany GmbH"
    },
    {
      "label": "coinIX Capital GmbH",
      "value": "coinIX Capital GmbH"
    },
    {
      "label": "CoInvest Corporate & Investment Broker GmbH",
      "value": "CoInvest Corporate & Investment Broker GmbH"
    },
    {
      "label": "COLLEGIUM Vermögensverwaltungs AG",
      "value": "COLLEGIUM Vermögensverwaltungs AG"
    },
    {
      "label": "Collineo Asset Management GmbH",
      "value": "Collineo Asset Management GmbH"
    },
    {
      "label": "Collineo Investment GmbH",
      "value": "Collineo Investment GmbH"
    },
    {
      "label": "Coloniale AG",
      "value": "Coloniale AG"
    },
    {
      "label": "Columbia Threadneedle Netherlands B.V., Niederlassung Deutschland",
      "value": "Columbia Threadneedle Netherlands B.V., Niederlassung Deutschland"
    },
    {
      "label": "Columbus Leasing GmbH",
      "value": "Columbus Leasing GmbH"
    },
    {
      "label": "COMCO Autoleasing GmbH & Co. KG",
      "value": "COMCO Autoleasing GmbH & Co. KG"
    },
    {
      "label": "COMCO EcoLease GmbH & Co. KG",
      "value": "COMCO EcoLease GmbH & Co. KG"
    },
    {
      "label": "COMCO Leasing GmbH",
      "value": "COMCO Leasing GmbH"
    },
    {
      "label": "COMCO Leasing Service GmbH",
      "value": "COMCO Leasing Service GmbH"
    },
    {
      "label": "COMECO GmbH & Co. KG",
      "value": "COMECO GmbH & Co. KG"
    },
    {
      "label": "ComFlash GmbH",
      "value": "ComFlash GmbH"
    },
    {
      "label": "Comitis Management GmbH",
      "value": "Comitis Management GmbH"
    },
    {
      "label": "Commerz Real AG",
      "value": "Commerz Real AG"
    },
    {
      "label": "Commerz Real Asset Verwaltungsgesellschaft mbH",
      "value": "Commerz Real Asset Verwaltungsgesellschaft mbH"
    },
    {
      "label": "Commerz Real Investmentgesellschaft mbH",
      "value": "Commerz Real Investmentgesellschaft mbH"
    },
    {
      "label": "Commerz Real Kapitalverwaltungsgesellschaft mbH",
      "value": "Commerz Real Kapitalverwaltungsgesellschaft mbH"
    },
    {
      "label": "Commerz Real Mobilienleasing GmbH",
      "value": "Commerz Real Mobilienleasing GmbH"
    },
    {
      "label": "COMMERZBANK Aktiengesellschaft",
      "value": "COMMERZBANK Aktiengesellschaft"
    },
    {
      "label": "CommerzFactoring GmbH",
      "value": "CommerzFactoring GmbH"
    },
    {
      "label": "Compagnie Francaise d Assurance pour le Commerce Extérieur S.A., Niederlassung in Deutschland (Coface)",
      "value": "Compagnie Francaise d Assurance pour le Commerce Extérieur S.A., Niederlassung in Deutschland (Coface)"
    },
    {
      "label": "Companion Unternehmenskapital GmbH",
      "value": "Companion Unternehmenskapital GmbH"
    },
    {
      "label": "COMPASS GmbH Abrechnungsstelle für Privatliquidationen der Ärzte",
      "value": "COMPASS GmbH Abrechnungsstelle für Privatliquidationen der Ärzte"
    },
    {
      "label": "Competo Capital Invest GmbH",
      "value": "Competo Capital Invest GmbH"
    },
    {
      "label": "Concardis GmbH",
      "value": "Concardis GmbH"
    },
    {
      "label": "CONCEDUS GmbH",
      "value": "CONCEDUS GmbH"
    },
    {
      "label": "CONCEPT Vermögensmanagement GmbH & Co. KG",
      "value": "CONCEPT Vermögensmanagement GmbH & Co. KG"
    },
    {
      "label": "Concerta Gesellschaft für Vermögensverwaltung und Wirtschaftsberatung mbH",
      "value": "Concerta Gesellschaft für Vermögensverwaltung und Wirtschaftsberatung mbH"
    },
    {
      "label": "Concordia Krankenversicherungs-Aktiengesellschaft",
      "value": "Concordia Krankenversicherungs-Aktiengesellschaft"
    },
    {
      "label": "Concordia oeco Lebensversicherungs-AG",
      "value": "Concordia oeco Lebensversicherungs-AG"
    },
    {
      "label": "Concordia Versicherungs-Gesellschaft auf Gegenseitigkeit",
      "value": "Concordia Versicherungs-Gesellschaft auf Gegenseitigkeit"
    },
    {
      "label": "Condor Allgemeine Versicherungs-Aktiengesellschaft",
      "value": "Condor Allgemeine Versicherungs-Aktiengesellschaft"
    },
    {
      "label": "Condor Lebensversicherungs-Aktiengesellschaft",
      "value": "Condor Lebensversicherungs-Aktiengesellschaft"
    },
    {
      "label": "conFern-Containerpool AG",
      "value": "conFern-Containerpool AG"
    },
    {
      "label": "CONLINK Fulfillment GmbH",
      "value": "CONLINK Fulfillment GmbH"
    },
    {
      "label": "CONQUEST Investment Advisory AG",
      "value": "CONQUEST Investment Advisory AG"
    },
    {
      "label": "CONREN Land Immobilien Kapitalverwaltungsgesellschaft mbH",
      "value": "CONREN Land Immobilien Kapitalverwaltungsgesellschaft mbH"
    },
    {
      "label": "Consortia Vermögensverwaltung AG",
      "value": "Consortia Vermögensverwaltung AG"
    },
    {
      "label": "CONSTANTIA Versicherungen a.G.",
      "value": "CONSTANTIA Versicherungen a.G."
    },
    {
      "label": "Consulting Team Vermögensverwaltung AG",
      "value": "Consulting Team Vermögensverwaltung AG"
    },
    {
      "label": "CONTEGO FACTORING GmbH & Co. KG",
      "value": "CONTEGO FACTORING GmbH & Co. KG"
    },
    {
      "label": "Contentus Vermögensverwaltungs GmbH",
      "value": "Contentus Vermögensverwaltungs GmbH"
    },
    {
      "label": "Continentale Krankenversicherung a.G.",
      "value": "Continentale Krankenversicherung a.G."
    },
    {
      "label": "Continentale Lebensversicherung AG",
      "value": "Continentale Lebensversicherung AG"
    },
    {
      "label": "Continentale Sachversicherung Aktiengesellschaft",
      "value": "Continentale Sachversicherung Aktiengesellschaft"
    },
    {
      "label": "Conundrum Asset Management GmbH",
      "value": "Conundrum Asset Management GmbH"
    },
    {
      "label": "Convexity GmbH & Co. KG",
      "value": "Convexity GmbH & Co. KG"
    },
    {
      "label": "CoOpera Finanzierungen Deutschland GmbH",
      "value": "CoOpera Finanzierungen Deutschland GmbH"
    },
    {
      "label": "COOX Capital GmbH",
      "value": "COOX Capital GmbH"
    },
    {
      "label": "Copenhagen Infrastructure Partners P/S German Branch",
      "value": "Copenhagen Infrastructure Partners P/S German Branch"
    },
    {
      "label": "CoRE Solutions GmbH",
      "value": "CoRE Solutions GmbH"
    },
    {
      "label": "CORESIS Management GmbH",
      "value": "CORESIS Management GmbH"
    },
    {
      "label": "CORESTATE Bank GmbH",
      "value": "CORESTATE Bank GmbH"
    },
    {
      "label": "Coros Management GmbH",
      "value": "Coros Management GmbH"
    },
    {
      "label": "CORUM Vermögensverwaltung (Deutschland) GmbH",
      "value": "CORUM Vermögensverwaltung (Deutschland) GmbH"
    },
    {
      "label": "Cosmos Lebensversicherungs-Aktiengesellschaft",
      "value": "Cosmos Lebensversicherungs-Aktiengesellschaft"
    },
    {
      "label": "Cosmos Versicherung Aktiengesellschaft",
      "value": "Cosmos Versicherung Aktiengesellschaft"
    },
    {
      "label": "CoV Management GmbH",
      "value": "CoV Management GmbH"
    },
    {
      "label": "Coöperatieve Rabobank U.A., Zweigniederlassung Frankfurt am Main",
      "value": "Coöperatieve Rabobank U.A., Zweigniederlassung Frankfurt am Main"
    },
    {
      "label": "CPP Anlageverwaltungs GmbH",
      "value": "CPP Anlageverwaltungs GmbH"
    },
    {
      "label": "Crailsheimer Auto-Leasing GmbH",
      "value": "Crailsheimer Auto-Leasing GmbH"
    },
    {
      "label": "Creathor Venture Management GmbH",
      "value": "Creathor Venture Management GmbH"
    },
    {
      "label": "CREDENDO - Guarantees & Speciality Risks SA/NV, Zweigniederlassung Deutschland",
      "value": "CREDENDO - Guarantees & Speciality Risks SA/NV, Zweigniederlassung Deutschland"
    },
    {
      "label": "Credendo - Short-Term Non-EU Risks SA/NV Zweigniederlassung Deutschland",
      "value": "Credendo - Short-Term Non-EU Risks SA/NV Zweigniederlassung Deutschland"
    },
    {
      "label": "CREDION Kapitalverwaltungsgesellschaft mbH",
      "value": "CREDION Kapitalverwaltungsgesellschaft mbH"
    },
    {
      "label": "Credit Europe Bank N.V. Niederlassung Deutschland",
      "value": "Credit Europe Bank N.V. Niederlassung Deutschland"
    },
    {
      "label": "Credit Life AG",
      "value": "Credit Life AG"
    },
    {
      "label": "Credit Suisse (Deutschland) Aktiengesellschaft",
      "value": "Credit Suisse (Deutschland) Aktiengesellschaft"
    },
    {
      "label": "CreditPlus Bank Aktiengesellschaft",
      "value": "CreditPlus Bank Aktiengesellschaft"
    },
    {
      "label": "CREDO Vermögensmanagement GmbH",
      "value": "CREDO Vermögensmanagement GmbH"
    },
    {
      "label": "credX AG",
      "value": "credX AG"
    },
    {
      "label": "Crefo Factoring Düsseldorf Neuss GmbH",
      "value": "Crefo Factoring Düsseldorf Neuss GmbH"
    },
    {
      "label": "Crefo Factoring Fulda-Erfurt-Magdeburg GmbH & Co. KG",
      "value": "Crefo Factoring Fulda-Erfurt-Magdeburg GmbH & Co. KG"
    },
    {
      "label": "Crefo Factoring Halle-Leipzig GmbH & Co. KG",
      "value": "Crefo Factoring Halle-Leipzig GmbH & Co. KG"
    },
    {
      "label": "Crefo Factoring Nordwest GmbH",
      "value": "Crefo Factoring Nordwest GmbH"
    },
    {
      "label": "Crefo Factoring Rhein Ruhr GmbH",
      "value": "Crefo Factoring Rhein Ruhr GmbH"
    },
    {
      "label": "Crefo Factoring Rhein-Wupper GmbH",
      "value": "Crefo Factoring Rhein-Wupper GmbH"
    },
    {
      "label": "Crefo Factoring Südost GmbH & Co. KG",
      "value": "Crefo Factoring Südost GmbH & Co. KG"
    },
    {
      "label": "Crefo Factoring Südwest GmbH & Co. KG",
      "value": "Crefo Factoring Südwest GmbH & Co. KG"
    },
    {
      "label": "Crefo Factoring Westfalen GmbH",
      "value": "Crefo Factoring Westfalen GmbH"
    },
    {
      "label": "Crefo-Factoring Berlin-Brandenburg GmbH",
      "value": "Crefo-Factoring Berlin-Brandenburg GmbH"
    },
    {
      "label": "Crefo-Factoring N-M-S GmbH & Co. KG",
      "value": "Crefo-Factoring N-M-S GmbH & Co. KG"
    },
    {
      "label": "Crefo-Factoring Nord GmbH",
      "value": "Crefo-Factoring Nord GmbH"
    },
    {
      "label": "Crefo-Factoring Rheinland GmbH",
      "value": "Crefo-Factoring Rheinland GmbH"
    },
    {
      "label": "Crefo-Factoring Stuttgart GmbH & Co. KG",
      "value": "Crefo-Factoring Stuttgart GmbH & Co. KG"
    },
    {
      "label": "Crocodile Capital Partners GmbH",
      "value": "Crocodile Capital Partners GmbH"
    },
    {
      "label": "CRONBANK Aktiengesellschaft",
      "value": "CRONBANK Aktiengesellschaft"
    },
    {
      "label": "Crossflow Financial Advisors GmbH",
      "value": "Crossflow Financial Advisors GmbH"
    },
    {
      "label": "Crosslantic Capital Management GmbH",
      "value": "Crosslantic Capital Management GmbH"
    },
    {
      "label": "CRX Markets AG",
      "value": "CRX Markets AG"
    },
    {
      "label": "Crédit Agricole Corporate and Investment Bank Deutschland, Niederlassung einer französischen Société Anonyme",
      "value": "Crédit Agricole Corporate and Investment Bank Deutschland, Niederlassung einer französischen Société Anonyme"
    },
    {
      "label": "Crédit Agricole Leasing & Factoring SA - Niederlassung Deutschland",
      "value": "Crédit Agricole Leasing & Factoring SA - Niederlassung Deutschland"
    },
    {
      "label": "Crédit Foncier de France - Representative Office Germany",
      "value": "Crédit Foncier de France - Representative Office Germany"
    },
    {
      "label": "Crédit Mutuel Leasing GmbH",
      "value": "Crédit Mutuel Leasing GmbH"
    },
    {
      "label": "CSI LifeCycle Leasing GmbH",
      "value": "CSI LifeCycle Leasing GmbH"
    },
    {
      "label": "CSI-Club Südamerika International GmbH",
      "value": "CSI-Club Südamerika International GmbH"
    },
    {
      "label": "CSR Beratungsgesellschaft mbH",
      "value": "CSR Beratungsgesellschaft mbH"
    },
    {
      "label": "Cusp Capital Partners GmbH",
      "value": "Cusp Capital Partners GmbH"
    },
    {
      "label": "CVW-Privatbank AG",
      "value": "CVW-Privatbank AG"
    },
    {
      "label": "D & S Vermögensverwaltungen GmbH",
      "value": "D & S Vermögensverwaltungen GmbH"
    },
    {
      "label": "d.i.i. Investment GmbH",
      "value": "d.i.i. Investment GmbH"
    },
    {
      "label": "DA Deutsche Allgemeine Versicherung Aktiengesellschaft",
      "value": "DA Deutsche Allgemeine Versicherung Aktiengesellschaft"
    },
    {
      "label": "DAGEMA eG",
      "value": "DAGEMA eG"
    },
    {
      "label": "Dahm & Jess GmbH",
      "value": "Dahm & Jess GmbH"
    },
    {
      "label": "Daimler Fleet Management GmbH",
      "value": "Daimler Fleet Management GmbH"
    },
    {
      "label": "Daimler Pensionsfonds AG",
      "value": "Daimler Pensionsfonds AG"
    },
    {
      "label": "Daimler Truck Financial Services Deutschland GmbH",
      "value": "Daimler Truck Financial Services Deutschland GmbH"
    },
    {
      "label": "Daiwa Capital Markets Deutschland GmbH",
      "value": "Daiwa Capital Markets Deutschland GmbH"
    },
    {
      "label": "DAL Deutsche Anlagen-Leasing GmbH & Co. KG",
      "value": "DAL Deutsche Anlagen-Leasing GmbH & Co. KG"
    },
    {
      "label": "DAL SH Leasing GmbH & Co. KG",
      "value": "DAL SH Leasing GmbH & Co. KG"
    },
    {
      "label": "Daniels, Gritzka, Kraft & Co. Vermögensverwaltung AG",
      "value": "Daniels, Gritzka, Kraft & Co. Vermögensverwaltung AG"
    },
    {
      "label": "DARAG Deutschland AG",
      "value": "DARAG Deutschland AG"
    },
    {
      "label": "Darlehenskasse der Bayerischen Studentenwerke e.V.",
      "value": "Darlehenskasse der Bayerischen Studentenwerke e.V."
    },
    {
      "label": "Darlehenskasse der Studierendenwerke e.V.",
      "value": "Darlehenskasse der Studierendenwerke e.V."
    },
    {
      "label": "Das Kontor - Leasing und Finanzierung GmbH",
      "value": "Das Kontor - Leasing und Finanzierung GmbH"
    },
    {
      "label": "DAS WERTEHAUS Vermögensverwaltung GmbH",
      "value": "DAS WERTEHAUS Vermögensverwaltung GmbH"
    },
    {
      "label": "DATAPART Factoring GmbH",
      "value": "DATAPART Factoring GmbH"
    },
    {
      "label": "Davidson Capital GmbH",
      "value": "Davidson Capital GmbH"
    },
    {
      "label": "dbde Deutsche Bildung Studienfonds Geschäftsführungs GmbH",
      "value": "dbde Deutsche Bildung Studienfonds Geschäftsführungs GmbH"
    },
    {
      "label": "DBG Managing Partner GmbH & Co. KG",
      "value": "DBG Managing Partner GmbH & Co. KG"
    },
    {
      "label": "DBZ Dienstleistungs-, Beratungs- und Vertriebsgesellschaft für den Getränkefachgroßhandel mbH",
      "value": "DBZ Dienstleistungs-, Beratungs- und Vertriebsgesellschaft für den Getränkefachgroßhandel mbH"
    },
    {
      "label": "DC Placement Advisors GmbH",
      "value": "DC Placement Advisors GmbH"
    },
    {
      "label": "De Lage Landen International B.V. Deutsche Niederlassung",
      "value": "De Lage Landen International B.V. Deutsche Niederlassung"
    },
    {
      "label": "De Lage Landen Leasing GmbH",
      "value": "De Lage Landen Leasing GmbH"
    },
    {
      "label": "Debeka Allgemeine Versicherung Aktiengesellschaft Sitz Koblenz am Rhein",
      "value": "Debeka Allgemeine Versicherung Aktiengesellschaft Sitz Koblenz am Rhein"
    },
    {
      "label": "Debeka Asset Management GmbH",
      "value": "Debeka Asset Management GmbH"
    },
    {
      "label": "Debeka Bausparkasse Aktiengesellschaft Sitz Koblenz am Rhein",
      "value": "Debeka Bausparkasse Aktiengesellschaft Sitz Koblenz am Rhein"
    },
    {
      "label": "Debeka Krankenversicherungsverein auf Gegenseitigkeit Sitz Koblenz am Rhein",
      "value": "Debeka Krankenversicherungsverein auf Gegenseitigkeit Sitz Koblenz am Rhein"
    },
    {
      "label": "Debeka Lebensversicherungsverein auf Gegenseitigkeit Sitz Koblenz am Rhein",
      "value": "Debeka Lebensversicherungsverein auf Gegenseitigkeit Sitz Koblenz am Rhein"
    },
    {
      "label": "Debeka Pensionskasse AG",
      "value": "Debeka Pensionskasse AG"
    },
    {
      "label": "Debeka Zusatzversorgungskasse VaG",
      "value": "Debeka Zusatzversorgungskasse VaG"
    },
    {
      "label": "Deep Blue X GmbH",
      "value": "Deep Blue X GmbH"
    },
    {
      "label": "DeFinVa Deutsche Finanzvertriebsagentur GmbH",
      "value": "DeFinVa Deutsche Finanzvertriebsagentur GmbH"
    },
    {
      "label": "DEG Impact GmbH",
      "value": "DEG Impact GmbH"
    },
    {
      "label": "DEG-Deutsche Investitions- und Entwicklungsgesellschaft m.b.H.",
      "value": "DEG-Deutsche Investitions- und Entwicklungsgesellschaft m.b.H."
    },
    {
      "label": "Degroof Petercam Asset Management S.A. Zweigniederlassung Deutschland",
      "value": "Degroof Petercam Asset Management S.A. Zweigniederlassung Deutschland"
    },
    {
      "label": "Degussa Bank AG",
      "value": "Degussa Bank AG"
    },
    {
      "label": "Deka Immobilien Investment GmbH",
      "value": "Deka Immobilien Investment GmbH"
    },
    {
      "label": "Deka Investment GmbH",
      "value": "Deka Investment GmbH"
    },
    {
      "label": "Deka Vermögensmanagement GmbH",
      "value": "Deka Vermögensmanagement GmbH"
    },
    {
      "label": "DekaBank Deutsche Girozentrale",
      "value": "DekaBank Deutsche Girozentrale"
    },
    {
      "label": "DELA Lebensversicherungen Zweigniederlassung der DELA Natura-en levensverzekeringen N.V. Eindhoven",
      "value": "DELA Lebensversicherungen Zweigniederlassung der DELA Natura-en levensverzekeringen N.V. Eindhoven"
    },
    {
      "label": "DelFactis AG",
      "value": "DelFactis AG"
    },
    {
      "label": "Delta Direkt Lebensversicherung Aktiengesellschaft München",
      "value": "Delta Direkt Lebensversicherung Aktiengesellschaft München"
    },
    {
      "label": "Delvag Versicherungs-AG",
      "value": "Delvag Versicherungs-AG"
    },
    {
      "label": "Demir-Halk Bank (Nederland) N.V. Filiale Düsseldorf",
      "value": "Demir-Halk Bank (Nederland) N.V. Filiale Düsseldorf"
    },
    {
      "label": "DenizBank (Wien) AG, Zweigniederlassung  Frankfurt am Main",
      "value": "DenizBank (Wien) AG, Zweigniederlassung  Frankfurt am Main"
    },
    {
      "label": "DENTAGEN Wirtschaftsverbund eG",
      "value": "DENTAGEN Wirtschaftsverbund eG"
    },
    {
      "label": "Dericon GmbH",
      "value": "Dericon GmbH"
    },
    {
      "label": "derigo GmbH & Co. KG",
      "value": "derigo GmbH & Co. KG"
    },
    {
      "label": "DETER & RASCHE Investmentberatung GmbH",
      "value": "DETER & RASCHE Investmentberatung GmbH"
    },
    {
      "label": "DeTeWe Finance GmbH",
      "value": "DeTeWe Finance GmbH"
    },
    {
      "label": "Dettinger Bank eG Volks- und Raiffeisenbank",
      "value": "Dettinger Bank eG Volks- und Raiffeisenbank"
    },
    {
      "label": "DEURAG Deutsche Rechtsschutz-Versicherung Aktiengesellschaft",
      "value": "DEURAG Deutsche Rechtsschutz-Versicherung Aktiengesellschaft"
    },
    {
      "label": "DEUTSCHE ANLAGEN-LEASING GmbH",
      "value": "DEUTSCHE ANLAGEN-LEASING GmbH"
    },
    {
      "label": "DEUTSCHE APOTHEKER- UND ÄRZTEBANK EG",
      "value": "DEUTSCHE APOTHEKER- UND ÄRZTEBANK EG"
    },
    {
      "label": "Deutsche Assistance Versicherung Aktiengesellschaft",
      "value": "Deutsche Assistance Versicherung Aktiengesellschaft"
    },
    {
      "label": "DEUTSCHE BANK AKTIENGESELLSCHAFT",
      "value": "DEUTSCHE BANK AKTIENGESELLSCHAFT"
    },
    {
      "label": "Deutsche Bank Europe GmbH",
      "value": "Deutsche Bank Europe GmbH"
    },
    {
      "label": "Deutsche Bausparkasse Badenia Aktiengesellschaft",
      "value": "Deutsche Bausparkasse Badenia Aktiengesellschaft"
    },
    {
      "label": "Deutsche Börse Aktiengesellschaft",
      "value": "Deutsche Börse Aktiengesellschaft"
    },
    {
      "label": "Deutsche Factoring Bank GmbH & Co. KG",
      "value": "Deutsche Factoring Bank GmbH & Co. KG"
    },
    {
      "label": "Deutsche Forst Invest GmbH",
      "value": "Deutsche Forst Invest GmbH"
    },
    {
      "label": "Deutsche Gesellschaft für privatärztliche Abrechnung dgpar GmbH",
      "value": "Deutsche Gesellschaft für privatärztliche Abrechnung dgpar GmbH"
    },
    {
      "label": "Deutsche Grundstein Kapital AG",
      "value": "Deutsche Grundstein Kapital AG"
    },
    {
      "label": "Deutsche Handelsbank AG",
      "value": "Deutsche Handelsbank AG"
    },
    {
      "label": "DEUTSCHE IMMOBILIEN BMMG GmbH",
      "value": "DEUTSCHE IMMOBILIEN BMMG GmbH"
    },
    {
      "label": "Deutsche Immobilien Leasing GmbH",
      "value": "Deutsche Immobilien Leasing GmbH"
    },
    {
      "label": "Deutsche Invest Capital Solutions GmbH",
      "value": "Deutsche Invest Capital Solutions GmbH"
    },
    {
      "label": "Deutsche Invest Equity Partners GmbH",
      "value": "Deutsche Invest Equity Partners GmbH"
    },
    {
      "label": "Deutsche Invest Technology GmbH",
      "value": "Deutsche Invest Technology GmbH"
    },
    {
      "label": "Deutsche Invest Venture Capital GmbH",
      "value": "Deutsche Invest Venture Capital GmbH"
    },
    {
      "label": "Deutsche Investment Kapitalverwaltung AG",
      "value": "Deutsche Investment Kapitalverwaltung AG"
    },
    {
      "label": "Deutsche Kreditbank Aktiengesellschaft",
      "value": "Deutsche Kreditbank Aktiengesellschaft"
    },
    {
      "label": "Deutsche Leasing AG",
      "value": "Deutsche Leasing AG"
    },
    {
      "label": "Deutsche Leasing Finance GmbH",
      "value": "Deutsche Leasing Finance GmbH"
    },
    {
      "label": "Deutsche Lebensversicherungs-Aktiengesellschaft",
      "value": "Deutsche Lebensversicherungs-Aktiengesellschaft"
    },
    {
      "label": "Deutsche Niederlassung der FRIDAY Insurance S.A.",
      "value": "Deutsche Niederlassung der FRIDAY Insurance S.A."
    },
    {
      "label": "Deutsche Oppenheim Family Office AG",
      "value": "Deutsche Oppenheim Family Office AG"
    },
    {
      "label": "Deutsche Pfandbriefbank AG",
      "value": "Deutsche Pfandbriefbank AG"
    },
    {
      "label": "Deutsche Post Pensionsfonds AG",
      "value": "Deutsche Post Pensionsfonds AG"
    },
    {
      "label": "Deutsche Rhederei Versicherungs-Aktiengesellschaft i.L.",
      "value": "Deutsche Rhederei Versicherungs-Aktiengesellschaft i.L."
    },
    {
      "label": "Deutsche Rückversicherung Aktiengesellschaft",
      "value": "Deutsche Rückversicherung Aktiengesellschaft"
    },
    {
      "label": "Deutsche Sparkassen Leasing AG & Co. KG",
      "value": "Deutsche Sparkassen Leasing AG & Co. KG"
    },
    {
      "label": "Deutsche Steuerberater-Versicherung - Pensionskasse des steuerberatenden Berufs VVaG",
      "value": "Deutsche Steuerberater-Versicherung - Pensionskasse des steuerberatenden Berufs VVaG"
    },
    {
      "label": "Deutsche Telekom Capital Partners Portfolio Fund Co-Invest I GmbH & Co. KG",
      "value": "Deutsche Telekom Capital Partners Portfolio Fund Co-Invest I GmbH & Co. KG"
    },
    {
      "label": "Deutsche Telekom Capital Partners Venture Fund GmbH & Co. KG",
      "value": "Deutsche Telekom Capital Partners Venture Fund GmbH & Co. KG"
    },
    {
      "label": "Deutsche Telekom Capital Partners Venture Fund II GmbH & Co. KG",
      "value": "Deutsche Telekom Capital Partners Venture Fund II GmbH & Co. KG"
    },
    {
      "label": "Deutsche Telekom Capital Partners Venture Fund II Parallel GmbH & Co. KG",
      "value": "Deutsche Telekom Capital Partners Venture Fund II Parallel GmbH & Co. KG"
    },
    {
      "label": "Deutsche Verrechnungsstelle AG",
      "value": "Deutsche Verrechnungsstelle AG"
    },
    {
      "label": "Deutsche WertpapierService Bank AG",
      "value": "Deutsche WertpapierService Bank AG"
    },
    {
      "label": "Deutsche Ärzteversicherung Aktiengesellschaft",
      "value": "Deutsche Ärzteversicherung Aktiengesellschaft"
    },
    {
      "label": "Deutscher Pensionsfonds Aktiengesellschaft",
      "value": "Deutscher Pensionsfonds Aktiengesellschaft"
    },
    {
      "label": "Deutscher Reisepreis-Sicherungsverein VVaG",
      "value": "Deutscher Reisepreis-Sicherungsverein VVaG"
    },
    {
      "label": "Development Company for Israel (Europe) GmbH",
      "value": "Development Company for Israel (Europe) GmbH"
    },
    {
      "label": "DEVK Allgemeine Lebensversicherungs-Aktiengesellschaft",
      "value": "DEVK Allgemeine Lebensversicherungs-Aktiengesellschaft"
    },
    {
      "label": "DEVK Allgemeine Versicherungs-Aktiengesellschaft",
      "value": "DEVK Allgemeine Versicherungs-Aktiengesellschaft"
    },
    {
      "label": "DEVK Asset Management Gesellschaft mbH",
      "value": "DEVK Asset Management Gesellschaft mbH"
    },
    {
      "label": "DEVK Deutsche Eisenbahn Versicherung Lebensversicherungsverein a.G. Betriebliche Sozialeinrichtung der Deutschen Bahn",
      "value": "DEVK Deutsche Eisenbahn Versicherung Lebensversicherungsverein a.G. Betriebliche Sozialeinrichtung der Deutschen Bahn"
    },
    {
      "label": "DEVK Deutsche Eisenbahn Versicherung Sach- und HUK-Versicherungsverein a.G. Betriebliche Sozialeinrichtung der Deutschen Bahn",
      "value": "DEVK Deutsche Eisenbahn Versicherung Sach- und HUK-Versicherungsverein a.G. Betriebliche Sozialeinrichtung der Deutschen Bahn"
    },
    {
      "label": "DEVK Krankenversicherungs-Aktiengesellschaft",
      "value": "DEVK Krankenversicherungs-Aktiengesellschaft"
    },
    {
      "label": "DEVK Pensionsfonds Aktiengesellschaft",
      "value": "DEVK Pensionsfonds Aktiengesellschaft"
    },
    {
      "label": "DEVK Rechtsschutz-Versicherungs-Aktiengesellschaft",
      "value": "DEVK Rechtsschutz-Versicherungs-Aktiengesellschaft"
    },
    {
      "label": "DEVK Rückversicherungs- und Beteiligungs-Aktiengesellschaft - DEVK RE",
      "value": "DEVK Rückversicherungs- und Beteiligungs-Aktiengesellschaft - DEVK RE"
    },
    {
      "label": "DeWert Deutsche Wertinvestment GmbH",
      "value": "DeWert Deutsche Wertinvestment GmbH"
    },
    {
      "label": "DF Deutsche Finance Capital GmbH",
      "value": "DF Deutsche Finance Capital GmbH"
    },
    {
      "label": "DF Deutsche Finance Investment GmbH",
      "value": "DF Deutsche Finance Investment GmbH"
    },
    {
      "label": "DFI Deutsche Fondsimmobilien Holding AG",
      "value": "DFI Deutsche Fondsimmobilien Holding AG"
    },
    {
      "label": "DFP Deutsche Finanz Portfolioverwaltung GmbH",
      "value": "DFP Deutsche Finanz Portfolioverwaltung GmbH"
    },
    {
      "label": "DFV Deutsche Familienversicherung AG",
      "value": "DFV Deutsche Familienversicherung AG"
    },
    {
      "label": "DHV Geschäftsführungs GmbH",
      "value": "DHV Geschäftsführungs GmbH"
    },
    {
      "label": "Dialog Lebensversicherungs-Aktiengesellschaft",
      "value": "Dialog Lebensversicherungs-Aktiengesellschaft"
    },
    {
      "label": "Dialog Vermögensmanagement GmbH",
      "value": "Dialog Vermögensmanagement GmbH"
    },
    {
      "label": "Dialog Versicherung Aktiengesellschaft",
      "value": "Dialog Versicherung Aktiengesellschaft"
    },
    {
      "label": "Diamant Verwaltungsgesellschaft mbH",
      "value": "Diamant Verwaltungsgesellschaft mbH"
    },
    {
      "label": "Dickemann Capital Aktiengesellschaft",
      "value": "Dickemann Capital Aktiengesellschaft"
    },
    {
      "label": "Didczys Invest GmbH",
      "value": "Didczys Invest GmbH"
    },
    {
      "label": "Die Haftpflichtkasse VVaG",
      "value": "Die Haftpflichtkasse VVaG"
    },
    {
      "label": "Die Sparkasse Bremen AG",
      "value": "Die Sparkasse Bremen AG"
    },
    {
      "label": "Die Vorsorge Sterbekasse der Werksangehörigen der Degussa Aktiengesellschaft Versicherungsverein auf Gegen. i.L.",
      "value": "Die Vorsorge Sterbekasse der Werksangehörigen der Degussa Aktiengesellschaft Versicherungsverein auf Gegen. i.L."
    },
    {
      "label": "DIE6 Promotion Service GmbH",
      "value": "DIE6 Promotion Service GmbH"
    },
    {
      "label": "Dieckvoß Thielemann Vermögensverwaltung GmbH",
      "value": "Dieckvoß Thielemann Vermögensverwaltung GmbH"
    },
    {
      "label": "Diehl Assekuranz Rückversicherungs- und Vermittlungs-AG",
      "value": "Diehl Assekuranz Rückversicherungs- und Vermittlungs-AG"
    },
    {
      "label": "Dietrich & Richter Private Asset Management AG",
      "value": "Dietrich & Richter Private Asset Management AG"
    },
    {
      "label": "Digital Mobility Leasing GmbH",
      "value": "Digital Mobility Leasing GmbH"
    },
    {
      "label": "Digital Transformation Capital Partners GmbH",
      "value": "Digital Transformation Capital Partners GmbH"
    },
    {
      "label": "Digitales Rezept Zentrum GmbH",
      "value": "Digitales Rezept Zentrum GmbH"
    },
    {
      "label": "Digitalplus GmbH",
      "value": "Digitalplus GmbH"
    },
    {
      "label": "Dimensional Ireland Limited, German Branch",
      "value": "Dimensional Ireland Limited, German Branch"
    },
    {
      "label": "DIREKTE LEBEN Versicherung AG",
      "value": "DIREKTE LEBEN Versicherung AG"
    },
    {
      "label": "Discovery Ventures Management GmbH",
      "value": "Discovery Ventures Management GmbH"
    },
    {
      "label": "Dithmarscher Volks- und Raiffeisenbank eG",
      "value": "Dithmarscher Volks- und Raiffeisenbank eG"
    },
    {
      "label": "ditracom GmbH",
      "value": "ditracom GmbH"
    },
    {
      "label": "DJE Kapital AG",
      "value": "DJE Kapital AG"
    },
    {
      "label": "DKM Darlehnskasse Münster eG",
      "value": "DKM Darlehnskasse Münster eG"
    },
    {
      "label": "DKS Dienstleistungs-Kontor Süd GmbH",
      "value": "DKS Dienstleistungs-Kontor Süd GmbH"
    },
    {
      "label": "DKS Vermögensverwalter GmbH",
      "value": "DKS Vermögensverwalter GmbH"
    },
    {
      "label": "DKV Deutsche Krankenversicherung Aktiengesellschaft",
      "value": "DKV Deutsche Krankenversicherung Aktiengesellschaft"
    },
    {
      "label": "DLAZ GmbH",
      "value": "DLAZ GmbH"
    },
    {
      "label": "DLT Subsidiary AG",
      "value": "DLT Subsidiary AG"
    },
    {
      "label": "DMB 3. GmbH & Co. KG",
      "value": "DMB 3. GmbH & Co. KG"
    },
    {
      "label": "DMB Rechtsschutz-Versicherung Aktiengesellschaft",
      "value": "DMB Rechtsschutz-Versicherung Aktiengesellschaft"
    },
    {
      "label": "DMG MORI Finance GmbH",
      "value": "DMG MORI Finance GmbH"
    },
    {
      "label": "DML Düsseldorfer Mobilien Leasing GmbH & Co. KG",
      "value": "DML Düsseldorfer Mobilien Leasing GmbH & Co. KG"
    },
    {
      "label": "DNB Bank ASA Filiale Deutschland",
      "value": "DNB Bank ASA Filiale Deutschland"
    },
    {
      "label": "Do Investment AG",
      "value": "Do Investment AG"
    },
    {
      "label": "DOCURA VVaG",
      "value": "DOCURA VVaG"
    },
    {
      "label": "Doha Bank Representative Office",
      "value": "Doha Bank Representative Office"
    },
    {
      "label": "Dolleruper Freie Brandgilde",
      "value": "Dolleruper Freie Brandgilde"
    },
    {
      "label": "Dolphinvest Consulting GmbH",
      "value": "Dolphinvest Consulting GmbH"
    },
    {
      "label": "Domestic & General Insurance Europe AG",
      "value": "Domestic & General Insurance Europe AG"
    },
    {
      "label": "Donau-Iller Bank eG",
      "value": "Donau-Iller Bank eG"
    },
    {
      "label": "DonauCapital Pure Investment GmbH",
      "value": "DonauCapital Pure Investment GmbH"
    },
    {
      "label": "DonauCapital Wertpapier GmbH",
      "value": "DonauCapital Wertpapier GmbH"
    },
    {
      "label": "Donner & Reuschel Aktiengesellschaft",
      "value": "Donner & Reuschel Aktiengesellschaft"
    },
    {
      "label": "Dortmunder Lebensversicherung AG",
      "value": "Dortmunder Lebensversicherung AG"
    },
    {
      "label": "Dortmunder Volksbank eingetragene Genossenschaft",
      "value": "Dortmunder Volksbank eingetragene Genossenschaft"
    },
    {
      "label": "DPE Deutsche Private Equity Gesellschaft mbH",
      "value": "DPE Deutsche Private Equity Gesellschaft mbH"
    },
    {
      "label": "DPE Investment Gesellschaft mbH",
      "value": "DPE Investment Gesellschaft mbH"
    },
    {
      "label": "DPK Deutsche Pensionskasse AG",
      "value": "DPK Deutsche Pensionskasse AG"
    },
    {
      "label": "DPK Deutsche Privatkapital GmbH",
      "value": "DPK Deutsche Privatkapital GmbH"
    },
    {
      "label": "Dr. Bauer & Co. Vermögensmanagement GmbH",
      "value": "Dr. Bauer & Co. Vermögensmanagement GmbH"
    },
    {
      "label": "Dr. Boss Finanz Management GmbH",
      "value": "Dr. Boss Finanz Management GmbH"
    },
    {
      "label": "Dr. Bost & Compagnon Vermögensverwaltungs GmbH",
      "value": "Dr. Bost & Compagnon Vermögensverwaltungs GmbH"
    },
    {
      "label": "Dr. Kohlhase Vermögensverwaltungsgesellschaft mbH",
      "value": "Dr. Kohlhase Vermögensverwaltungsgesellschaft mbH"
    },
    {
      "label": "Dr. Lux & Präuner GmbH & Co. KG",
      "value": "Dr. Lux & Präuner GmbH & Co. KG"
    },
    {
      "label": "Dr. Löffler & Co. KG",
      "value": "Dr. Löffler & Co. KG"
    },
    {
      "label": "Dr. Meindl u. Partner Verrechnungsstelle GmbH",
      "value": "Dr. Meindl u. Partner Verrechnungsstelle GmbH"
    },
    {
      "label": "Dr. Peterreins Portfolio Consulting GmbH",
      "value": "Dr. Peterreins Portfolio Consulting GmbH"
    },
    {
      "label": "Dr. Peters Asset Finance GmbH & Co. KG Kapitalverwaltungsgesellschaft",
      "value": "Dr. Peters Asset Finance GmbH & Co. KG Kapitalverwaltungsgesellschaft"
    },
    {
      "label": "Dr. Peters Invest GmbH",
      "value": "Dr. Peters Invest GmbH"
    },
    {
      "label": "Dr. Schmitt Leasing GmbH",
      "value": "Dr. Schmitt Leasing GmbH"
    },
    {
      "label": "Dr. Thilenius Management GmbH",
      "value": "Dr. Thilenius Management GmbH"
    },
    {
      "label": "Dr. Thorsten Schmitz Vermögensverwaltung GmbH & Co. KG",
      "value": "Dr. Thorsten Schmitz Vermögensverwaltung GmbH & Co. KG"
    },
    {
      "label": "Dr. Upgang Vermögensverwaltung GmbH",
      "value": "Dr. Upgang Vermögensverwaltung GmbH"
    },
    {
      "label": "Dr. Weber & Partner Leasing Gesellschaft mbH",
      "value": "Dr. Weber & Partner Leasing Gesellschaft mbH"
    },
    {
      "label": "Dresdener Pensionskasse VVaG",
      "value": "Dresdener Pensionskasse VVaG"
    },
    {
      "label": "Drexl & Stähle Vermögensverwaltung GmbH",
      "value": "Drexl & Stähle Vermögensverwaltung GmbH"
    },
    {
      "label": "DRH Vermögensverwaltung GmbH",
      "value": "DRH Vermögensverwaltung GmbH"
    },
    {
      "label": "DSC Deutsche SachCapital GmbH",
      "value": "DSC Deutsche SachCapital GmbH"
    },
    {
      "label": "DSK Hyp AG",
      "value": "DSK Hyp AG"
    },
    {
      "label": "Dte. W. Rechtsanwaltsgesellschaft mbH",
      "value": "Dte. W. Rechtsanwaltsgesellschaft mbH"
    },
    {
      "label": "DvH Fund Management GmbH",
      "value": "DvH Fund Management GmbH"
    },
    {
      "label": "DWPT Deutsche Wertpapiertreuhand GmbH",
      "value": "DWPT Deutsche Wertpapiertreuhand GmbH"
    },
    {
      "label": "DWS Alternatives GmbH",
      "value": "DWS Alternatives GmbH"
    },
    {
      "label": "DWS Grundbesitz GmbH",
      "value": "DWS Grundbesitz GmbH"
    },
    {
      "label": "DWS International GmbH",
      "value": "DWS International GmbH"
    },
    {
      "label": "DWS Investment GmbH",
      "value": "DWS Investment GmbH"
    },
    {
      "label": "DWS Investmentaktiengesellschaft mit Teilgesellschaftsvermögen",
      "value": "DWS Investmentaktiengesellschaft mit Teilgesellschaftsvermögen"
    },
    {
      "label": "Dynamo Retail GmbH",
      "value": "Dynamo Retail GmbH"
    },
    {
      "label": "DZ BANK AG Deutsche Zentral-Genossenschaftsbank, Frankfurt am Main",
      "value": "DZ BANK AG Deutsche Zentral-Genossenschaftsbank, Frankfurt am Main"
    },
    {
      "label": "DZ HYP AG",
      "value": "DZ HYP AG"
    },
    {
      "label": "DZ PRIVATBANK S.A. Niederlassung Stuttgart",
      "value": "DZ PRIVATBANK S.A. Niederlassung Stuttgart"
    },
    {
      "label": "DZB BANK GmbH",
      "value": "DZB BANK GmbH"
    },
    {
      "label": "DZH Dienstleistungszentrale für Heil- und Hilfsmittelanbieter GmbH",
      "value": "DZH Dienstleistungszentrale für Heil- und Hilfsmittelanbieter GmbH"
    },
    {
      "label": "DZR Deutsches Zahnärztliches Rechenzentrum GmbH",
      "value": "DZR Deutsches Zahnärztliches Rechenzentrum GmbH"
    },
    {
      "label": "Dördelmann & Co. Finanzmanagement GmbH",
      "value": "Dördelmann & Co. Finanzmanagement GmbH"
    },
    {
      "label": "E & G Bridge Equity Fonds II GmbH & Co. KG",
      "value": "E & G Bridge Equity Fonds II GmbH & Co. KG"
    },
    {
      "label": "E G E Elektro-Grosshandel Einkauf Gesellschaft mit beschränkter Haftung",
      "value": "E G E Elektro-Grosshandel Einkauf Gesellschaft mit beschränkter Haftung"
    },
    {
      "label": "E+S Rückversicherung AG",
      "value": "E+S Rückversicherung AG"
    },
    {
      "label": "E. W. Kuhlmann-Stiftung",
      "value": "E. W. Kuhlmann-Stiftung"
    },
    {
      "label": "e.ventures Managementgesellschaft mbH",
      "value": "e.ventures Managementgesellschaft mbH"
    },
    {
      "label": "e/r/w Vermögensmanagement GmbH",
      "value": "e/r/w Vermögensmanagement GmbH"
    },
    {
      "label": "Earlybird DWES Management GmbH & Co. KG",
      "value": "Earlybird DWES Management GmbH & Co. KG"
    },
    {
      "label": "Earlybird Growth Management Gmbh & Co. KG",
      "value": "Earlybird Growth Management Gmbh & Co. KG"
    },
    {
      "label": "Earlybird Health Management GmbH & Co. KG",
      "value": "Earlybird Health Management GmbH & Co. KG"
    },
    {
      "label": "Earlybird UNI-X Management GmbH & Co. KG",
      "value": "Earlybird UNI-X Management GmbH & Co. KG"
    },
    {
      "label": "Earlybird VC Management GmbH & Co. KG",
      "value": "Earlybird VC Management GmbH & Co. KG"
    },
    {
      "label": "Earlybird Venture Capital GmbH & Co. KG",
      "value": "Earlybird Venture Capital GmbH & Co. KG"
    },
    {
      "label": "East-West Assekuranz AG",
      "value": "East-West Assekuranz AG"
    },
    {
      "label": "EB - Sustainable Investment Management GmbH",
      "value": "EB - Sustainable Investment Management GmbH"
    },
    {
      "label": "EB 2007 Co-Invest SLP GmbH & Co. KG",
      "value": "EB 2007 Co-Invest SLP GmbH & Co. KG"
    },
    {
      "label": "EBF Edith Boose Finanzmanagement GmbH",
      "value": "EBF Edith Boose Finanzmanagement GmbH"
    },
    {
      "label": "EC Healthcare Management GmbH",
      "value": "EC Healthcare Management GmbH"
    },
    {
      "label": "eCAPITAL Entrepreneurial Partners AG",
      "value": "eCAPITAL Entrepreneurial Partners AG"
    },
    {
      "label": "ECBF Management GmbH",
      "value": "ECBF Management GmbH"
    },
    {
      "label": "Eccelium GP GmbH",
      "value": "Eccelium GP GmbH"
    },
    {
      "label": "Echterdinger Bank eG",
      "value": "Echterdinger Bank eG"
    },
    {
      "label": "ECI Kapitalverwaltungsgesellschaft mbH",
      "value": "ECI Kapitalverwaltungsgesellschaft mbH"
    },
    {
      "label": "Eckernförder Bank eG Volksbank-Raiffeisenbank",
      "value": "Eckernförder Bank eG Volksbank-Raiffeisenbank"
    },
    {
      "label": "Eckert Mittelstand Invest GmbH",
      "value": "Eckert Mittelstand Invest GmbH"
    },
    {
      "label": "ECL Eura Consumer Leasing GmbH & Co. KG",
      "value": "ECL Eura Consumer Leasing GmbH & Co. KG"
    },
    {
      "label": "eClear Aktiengesellschaft",
      "value": "eClear Aktiengesellschaft"
    },
    {
      "label": "ecoblue Asset Management GmbH",
      "value": "ecoblue Asset Management GmbH"
    },
    {
      "label": "ECONA Management GmbH",
      "value": "ECONA Management GmbH"
    },
    {
      "label": "Econocom Deutschland GmbH",
      "value": "Econocom Deutschland GmbH"
    },
    {
      "label": "Econos Fund Management GmbH",
      "value": "Econos Fund Management GmbH"
    },
    {
      "label": "ECP Invest GmbH & Co. KG",
      "value": "ECP Invest GmbH & Co. KG"
    },
    {
      "label": "EDEKA ZENTRALE Stiftung & Co. KG",
      "value": "EDEKA ZENTRALE Stiftung & Co. KG"
    },
    {
      "label": "EDEKABANK Aktiengesellschaft",
      "value": "EDEKABANK Aktiengesellschaft"
    },
    {
      "label": "Edel Schuhbedarf GmbH",
      "value": "Edel Schuhbedarf GmbH"
    },
    {
      "label": "EDG Einkaufsgesellschaft Deutscher Güterkraftverkehrsunternehmer mbH",
      "value": "EDG Einkaufsgesellschaft Deutscher Güterkraftverkehrsunternehmer mbH"
    },
    {
      "label": "Edmond de Rothschild Asset Management SA (France), Niederlassung Deutschland",
      "value": "Edmond de Rothschild Asset Management SA (France), Niederlassung Deutschland"
    },
    {
      "label": "Effecta GmbH",
      "value": "Effecta GmbH"
    },
    {
      "label": "egepack GmbH & Co. KG",
      "value": "egepack GmbH & Co. KG"
    },
    {
      "label": "egesa-zookauf eG Einkaufs-,Werbe- und Marketinggenossenschaft der Gartenfachgeschäfte, Gartencenter und Zoofachgeschäfte",
      "value": "egesa-zookauf eG Einkaufs-,Werbe- und Marketinggenossenschaft der Gartenfachgeschäfte, Gartencenter und Zoofachgeschäfte"
    },
    {
      "label": "Ehrke & Lübberstedt AG",
      "value": "Ehrke & Lübberstedt AG"
    },
    {
      "label": "Eichler & Mehlert Vermögensverwaltung GmbH",
      "value": "Eichler & Mehlert Vermögensverwaltung GmbH"
    },
    {
      "label": "EIG EUROINVESTOR GmbH",
      "value": "EIG EUROINVESTOR GmbH"
    },
    {
      "label": "EIL EURO-INDUSTRIE-LEASING GmbH & Co. KG",
      "value": "EIL EURO-INDUSTRIE-LEASING GmbH & Co. KG"
    },
    {
      "label": "Einkaufsbüro Deutscher Eisenhändler GmbH",
      "value": "Einkaufsbüro Deutscher Eisenhändler GmbH"
    },
    {
      "label": "Einkaufsgesellschaft des Hefegroßhandels mbH & Co. KG",
      "value": "Einkaufsgesellschaft des Hefegroßhandels mbH & Co. KG"
    },
    {
      "label": "Einrichtungspartnerring VME GmbH & Co.KG",
      "value": "Einrichtungspartnerring VME GmbH & Co.KG"
    },
    {
      "label": "EIS Einlagensicherungsbank GmbH",
      "value": "EIS Einlagensicherungsbank GmbH"
    },
    {
      "label": "EK/servicegroup eG",
      "value": "EK/servicegroup eG"
    },
    {
      "label": "EKF Finanz Frankfurt GmbH",
      "value": "EKF Finanz Frankfurt GmbH"
    },
    {
      "label": "el Factoring GmbH",
      "value": "el Factoring GmbH"
    },
    {
      "label": "el Leasing & Service AG",
      "value": "el Leasing & Service AG"
    },
    {
      "label": "ELAN Capital-Partners GmbH",
      "value": "ELAN Capital-Partners GmbH"
    },
    {
      "label": "Elavon Financial Services Designated Activity Company, Niederlassung Deutschland",
      "value": "Elavon Financial Services Designated Activity Company, Niederlassung Deutschland"
    },
    {
      "label": "Elbe-Leasing GmbH",
      "value": "Elbe-Leasing GmbH"
    },
    {
      "label": "ELEATIS Asset Management GmbH",
      "value": "ELEATIS Asset Management GmbH"
    },
    {
      "label": "ElectronicPartner GmbH",
      "value": "ElectronicPartner GmbH"
    },
    {
      "label": "ELEMENT Insurance AG",
      "value": "ELEMENT Insurance AG"
    },
    {
      "label": "elf Leasing GmbH",
      "value": "elf Leasing GmbH"
    },
    {
      "label": "Elinvar GmbH",
      "value": "Elinvar GmbH"
    },
    {
      "label": "Elips Life AG, Triesen, Zweigniederlassung Deutschland",
      "value": "Elips Life AG, Triesen, Zweigniederlassung Deutschland"
    },
    {
      "label": "Elke Petri Büroservice & Factoring",
      "value": "Elke Petri Büroservice & Factoring"
    },
    {
      "label": "Elvaston Capital Management GmbH",
      "value": "Elvaston Capital Management GmbH"
    },
    {
      "label": "EMB Erdgas Mark Brandenburg GmbH",
      "value": "EMB Erdgas Mark Brandenburg GmbH"
    },
    {
      "label": "embedded capital Management GmbH",
      "value": "embedded capital Management GmbH"
    },
    {
      "label": "EMBL Ventures GmbH",
      "value": "EMBL Ventures GmbH"
    },
    {
      "label": "EMERAM B&M Continuation Fund GmbH & Co. KG",
      "value": "EMERAM B&M Continuation Fund GmbH & Co. KG"
    },
    {
      "label": "EMERAM Private Equity Fund I GmbH & Co. KG",
      "value": "EMERAM Private Equity Fund I GmbH & Co. KG"
    },
    {
      "label": "EMH Digital Growth Fund GmbH & Co KG",
      "value": "EMH Digital Growth Fund GmbH & Co KG"
    },
    {
      "label": "EMH Invest I GmbH & Co. KG",
      "value": "EMH Invest I GmbH & Co. KG"
    },
    {
      "label": "Empira Investment Solutions S.A. - Frankfurt Branch",
      "value": "Empira Investment Solutions S.A. - Frankfurt Branch"
    },
    {
      "label": "Emsländische Volksbank eG",
      "value": "Emsländische Volksbank eG"
    },
    {
      "label": "ENCAVIS AM Invest GmbH",
      "value": "ENCAVIS AM Invest GmbH"
    },
    {
      "label": "enercity Aktiengesellschaft",
      "value": "enercity Aktiengesellschaft"
    },
    {
      "label": "EnergieFinanz GmbH",
      "value": "EnergieFinanz GmbH"
    },
    {
      "label": "Energieversorgung Leverkusen Verwaltungs- und Beteiligungsgesellschaft mbH",
      "value": "Energieversorgung Leverkusen Verwaltungs- und Beteiligungsgesellschaft mbH"
    },
    {
      "label": "ENEX Finanzpartner- und Beteiligungsgesellschaft mbH",
      "value": "ENEX Finanzpartner- und Beteiligungsgesellschaft mbH"
    },
    {
      "label": "ENGELHARDT KAUPP KIEFER & Co. Investment GmbH",
      "value": "ENGELHARDT KAUPP KIEFER & Co. Investment GmbH"
    },
    {
      "label": "EnjoyVenture Management GmbH",
      "value": "EnjoyVenture Management GmbH"
    },
    {
      "label": "Enmacc GmbH",
      "value": "Enmacc GmbH"
    },
    {
      "label": "Entis Lebensversicherung AG",
      "value": "Entis Lebensversicherung AG"
    },
    {
      "label": "ENVIVAS Krankenversicherung Aktiengesellschaft",
      "value": "ENVIVAS Krankenversicherung Aktiengesellschaft"
    },
    {
      "label": "EOS Beteiligungs GmbH & Co. KG",
      "value": "EOS Beteiligungs GmbH & Co. KG"
    },
    {
      "label": "EPH Investment GmbH",
      "value": "EPH Investment GmbH"
    },
    {
      "label": "equation Management GmbH",
      "value": "equation Management GmbH"
    },
    {
      "label": "EQUITA GMBH & CO. HOLDING KGAA, BAD HOMBURG",
      "value": "EQUITA GMBH & CO. HOLDING KGAA, BAD HOMBURG"
    },
    {
      "label": "Erbach-Factoring OHG",
      "value": "Erbach-Factoring OHG"
    },
    {
      "label": "ERGO Direkt Versicherung Aktiengesellschaft",
      "value": "ERGO Direkt Versicherung Aktiengesellschaft"
    },
    {
      "label": "ERGO Krankenversicherung AG",
      "value": "ERGO Krankenversicherung AG"
    },
    {
      "label": "ERGO Lebensversicherung Aktiengesellschaft",
      "value": "ERGO Lebensversicherung Aktiengesellschaft"
    },
    {
      "label": "ERGO Pensionsfonds Aktiengesellschaft",
      "value": "ERGO Pensionsfonds Aktiengesellschaft"
    },
    {
      "label": "ERGO Pensionskasse Aktiengesellschaft",
      "value": "ERGO Pensionskasse Aktiengesellschaft"
    },
    {
      "label": "ERGO Reiseversicherung AG",
      "value": "ERGO Reiseversicherung AG"
    },
    {
      "label": "ERGO Versicherung Aktiengesellschaft",
      "value": "ERGO Versicherung Aktiengesellschaft"
    },
    {
      "label": "ERGO Vorsorge Lebensversicherung Aktiengesellschaft",
      "value": "ERGO Vorsorge Lebensversicherung Aktiengesellschaft"
    },
    {
      "label": "ERIC Fund Management GmbH",
      "value": "ERIC Fund Management GmbH"
    },
    {
      "label": "Erlangen Brucklyn 4.0 GmbH & Co. KG",
      "value": "Erlangen Brucklyn 4.0 GmbH & Co. KG"
    },
    {
      "label": "ERMURI GENUSS COMPANY eG",
      "value": "ERMURI GENUSS COMPANY eG"
    },
    {
      "label": "Erste Finanz- und Vermögensberater efv GmbH",
      "value": "Erste Finanz- und Vermögensberater efv GmbH"
    },
    {
      "label": "Erste Group Bank AG, Zweigniederlassung Stuttgart",
      "value": "Erste Group Bank AG, Zweigniederlassung Stuttgart"
    },
    {
      "label": "Erste HTB Schwalmstadt UG (haftungsbeschränkt) & Co. KG",
      "value": "Erste HTB Schwalmstadt UG (haftungsbeschränkt) & Co. KG"
    },
    {
      "label": "Erste Kieler Beerdigungskasse",
      "value": "Erste Kieler Beerdigungskasse"
    },
    {
      "label": "Erzgebirgssparkasse",
      "value": "Erzgebirgssparkasse"
    },
    {
      "label": "Escendo Vermögensverwaltung GmbH",
      "value": "Escendo Vermögensverwaltung GmbH"
    },
    {
      "label": "ESPABAU Eisenbahn Spar- und Bauverein Bremen eG",
      "value": "ESPABAU Eisenbahn Spar- und Bauverein Bremen eG"
    },
    {
      "label": "ESÜDRO Einkaufsgesellschaft Deutscher Drogisten AG",
      "value": "ESÜDRO Einkaufsgesellschaft Deutscher Drogisten AG"
    },
    {
      "label": "ETL Finance GmbH & Co. KG",
      "value": "ETL Finance GmbH & Co. KG"
    },
    {
      "label": "ETRIS Bank GmbH",
      "value": "ETRIS Bank GmbH"
    },
    {
      "label": "Euler Hermes Deutschland Niederlassung der Euler Hermes SA",
      "value": "Euler Hermes Deutschland Niederlassung der Euler Hermes SA"
    },
    {
      "label": "EURAMCO Invest GmbH",
      "value": "EURAMCO Invest GmbH"
    },
    {
      "label": "EUREL Vermögensverwaltung GmbH",
      "value": "EUREL Vermögensverwaltung GmbH"
    },
    {
      "label": "EUREX Clearing Aktiengesellschaft",
      "value": "EUREX Clearing Aktiengesellschaft"
    },
    {
      "label": "Eurex Repo GmbH",
      "value": "Eurex Repo GmbH"
    },
    {
      "label": "Eurizon Capital S.A. - Frankfurt Branch",
      "value": "Eurizon Capital S.A. - Frankfurt Branch"
    },
    {
      "label": "EURO CHANGE WECHSELSTUBEN Aktiengesellschaft",
      "value": "EURO CHANGE WECHSELSTUBEN Aktiengesellschaft"
    },
    {
      "label": "euro delkredere GmbH & Co. KG",
      "value": "euro delkredere GmbH & Co. KG"
    },
    {
      "label": "EURO Kartensysteme GmbH",
      "value": "EURO Kartensysteme GmbH"
    },
    {
      "label": "Euro-Aviation Versicherungs-Aktiengesellschaft",
      "value": "Euro-Aviation Versicherungs-Aktiengesellschaft"
    },
    {
      "label": "EURO-FRIWA GmbH",
      "value": "EURO-FRIWA GmbH"
    },
    {
      "label": "EUROBAUSTOFF Handelsgesellschaft mbH & Co. KG",
      "value": "EUROBAUSTOFF Handelsgesellschaft mbH & Co. KG"
    },
    {
      "label": "Eurobond-Sales AG",
      "value": "Eurobond-Sales AG"
    },
    {
      "label": "Eurocity Bank AG",
      "value": "Eurocity Bank AG"
    },
    {
      "label": "Euroclear Bank SA/NV Repräsentanz",
      "value": "Euroclear Bank SA/NV Repräsentanz"
    },
    {
      "label": "eurodata Zahlungsdienste GmbH",
      "value": "eurodata Zahlungsdienste GmbH"
    },
    {
      "label": "EUROMAF Assurance des Ingénieurs et Architectes Européens Zweigniederlassung Düsseldorf",
      "value": "EUROMAF Assurance des Ingénieurs et Architectes Européens Zweigniederlassung Düsseldorf"
    },
    {
      "label": "EURONICS Deutschland eG",
      "value": "EURONICS Deutschland eG"
    },
    {
      "label": "Europ Assistance SA, Niederlassung für Deutschland",
      "value": "Europ Assistance SA, Niederlassung für Deutschland"
    },
    {
      "label": "EUROPA Lebensversicherung Aktiengesellschaft",
      "value": "EUROPA Lebensversicherung Aktiengesellschaft"
    },
    {
      "label": "EUROPA Versicherung Aktiengesellschaft",
      "value": "EUROPA Versicherung Aktiengesellschaft"
    },
    {
      "label": "Europe Arab Bank SA, Niederlassung Frankfurt",
      "value": "Europe Arab Bank SA, Niederlassung Frankfurt"
    },
    {
      "label": "European Bank for Financial Services GmbH (ebase)",
      "value": "European Bank for Financial Services GmbH (ebase)"
    },
    {
      "label": "European Commodity Clearing AG",
      "value": "European Commodity Clearing AG"
    },
    {
      "label": "Europäisch-Iranische Handelsbank Aktiengesellschaft",
      "value": "Europäisch-Iranische Handelsbank Aktiengesellschaft"
    },
    {
      "label": "EUWAX Aktiengesellschaft",
      "value": "EUWAX Aktiengesellschaft"
    },
    {
      "label": "Evangelische Bank eG",
      "value": "Evangelische Bank eG"
    },
    {
      "label": "EVENORD-BANK eG-KG",
      "value": "EVENORD-BANK eG-KG"
    },
    {
      "label": "Eventus Vermögensverwaltung GmbH",
      "value": "Eventus Vermögensverwaltung GmbH"
    },
    {
      "label": "Evercore GmbH",
      "value": "Evercore GmbH"
    },
    {
      "label": "Everest Insurance (Ireland) DAC Germany Branch",
      "value": "Everest Insurance (Ireland) DAC Germany Branch"
    },
    {
      "label": "Evergreen GmbH",
      "value": "Evergreen GmbH"
    },
    {
      "label": "EVO Payments International GmbH",
      "value": "EVO Payments International GmbH"
    },
    {
      "label": "Exane SA, Paris, Zweigniederlassung Frankfurt am Main",
      "value": "Exane SA, Paris, Zweigniederlassung Frankfurt am Main"
    },
    {
      "label": "eXapital Capital Advisors GmbH",
      "value": "eXapital Capital Advisors GmbH"
    },
    {
      "label": "Exchange AG Deutschland",
      "value": "Exchange AG Deutschland"
    },
    {
      "label": "Exchange Zahlungsdienste GmbH",
      "value": "Exchange Zahlungsdienste GmbH"
    },
    {
      "label": "Extantia Capital Management GmbH",
      "value": "Extantia Capital Management GmbH"
    },
    {
      "label": "EXTREMUS Versicherungs-Aktiengesellschaft",
      "value": "EXTREMUS Versicherungs-Aktiengesellschaft"
    },
    {
      "label": "Eyb & Wallwitz Vermögensmanagement GmbH",
      "value": "Eyb & Wallwitz Vermögensmanagement GmbH"
    },
    {
      "label": "F & P Vermögensmanagement GmbH",
      "value": "F & P Vermögensmanagement GmbH"
    },
    {
      "label": "F. Laeisz Versicherung Aktiengesellschaft",
      "value": "F. Laeisz Versicherung Aktiengesellschaft"
    },
    {
      "label": "F5 Crypto Management GmbH",
      "value": "F5 Crypto Management GmbH"
    },
    {
      "label": "FABIUS medical GmbH",
      "value": "FABIUS medical GmbH"
    },
    {
      "label": "Fabricius Vermögensverwaltung GmbH",
      "value": "Fabricius Vermögensverwaltung GmbH"
    },
    {
      "label": "Fahrlehrerversicherung Verein auf Gegenseitigkeit",
      "value": "Fahrlehrerversicherung Verein auf Gegenseitigkeit"
    },
    {
      "label": "FALCON Vermögensverwaltung AG",
      "value": "FALCON Vermögensverwaltung AG"
    },
    {
      "label": "FAM Frankfurt Asset Management AG",
      "value": "FAM Frankfurt Asset Management AG"
    },
    {
      "label": "Familienheim Freiburg Baugenossenschaft eG",
      "value": "Familienheim Freiburg Baugenossenschaft eG"
    },
    {
      "label": "Family Trust Investor FTI GmbH",
      "value": "Family Trust Investor FTI GmbH"
    },
    {
      "label": "FAROS Fiduciary Management AG",
      "value": "FAROS Fiduciary Management AG"
    },
    {
      "label": "FBBW - Fahrzeugbereitstellung Baden-Württemberg GmbH",
      "value": "FBBW - Fahrzeugbereitstellung Baden-Württemberg GmbH"
    },
    {
      "label": "FCA Bank S.p.A. Niederlassung Deutschland",
      "value": "FCA Bank S.p.A. Niederlassung Deutschland"
    },
    {
      "label": "FCF Fox Corporate Finance GmbH",
      "value": "FCF Fox Corporate Finance GmbH"
    },
    {
      "label": "Feast Ventures Management GmbH",
      "value": "Feast Ventures Management GmbH"
    },
    {
      "label": "FELS wealth GmbH",
      "value": "FELS wealth GmbH"
    },
    {
      "label": "FERI Trust (Luxembourg)S.A. - Niederlassung Deutschland",
      "value": "FERI Trust (Luxembourg)S.A. - Niederlassung Deutschland"
    },
    {
      "label": "Feri Trust GmbH",
      "value": "Feri Trust GmbH"
    },
    {
      "label": "Ferrari Financial Services GmbH",
      "value": "Ferrari Financial Services GmbH"
    },
    {
      "label": "Feuer- und Einbruchschadenkasse der BBBank in Karlsruhe, Versicherungsverein auf Gegenseitigkeit",
      "value": "Feuer- und Einbruchschadenkasse der BBBank in Karlsruhe, Versicherungsverein auf Gegenseitigkeit"
    },
    {
      "label": "Feuerbestattungsverein VVaG",
      "value": "Feuerbestattungsverein VVaG"
    },
    {
      "label": "Feuersozietät Berlin Brandenburg Versicherung Aktiengesellschaft",
      "value": "Feuersozietät Berlin Brandenburg Versicherung Aktiengesellschaft"
    },
    {
      "label": "FHB Kereskedelmi Bank Zrt. Niederlassung Frankfurt",
      "value": "FHB Kereskedelmi Bank Zrt. Niederlassung Frankfurt"
    },
    {
      "label": "FHG Hanseatische Fondshandlung GmbH",
      "value": "FHG Hanseatische Fondshandlung GmbH"
    },
    {
      "label": "ficon Vermögensmanagement GmbH",
      "value": "ficon Vermögensmanagement GmbH"
    },
    {
      "label": "Fidecum AG",
      "value": "Fidecum AG"
    },
    {
      "label": "FIDELIA Kapitalverwaltungsgesellschaft mbH",
      "value": "FIDELIA Kapitalverwaltungsgesellschaft mbH"
    },
    {
      "label": "Fidelium GmbH",
      "value": "Fidelium GmbH"
    },
    {
      "label": "FIDOR Bank AG",
      "value": "FIDOR Bank AG"
    },
    {
      "label": "Fiducia Capital GmbH & Co. KG",
      "value": "Fiducia Capital GmbH & Co. KG"
    },
    {
      "label": "Fiducia Treuhand AG",
      "value": "Fiducia Treuhand AG"
    },
    {
      "label": "Fiduka - Depotverwaltung Gesellschaft mit beschränkter Haftung",
      "value": "Fiduka - Depotverwaltung Gesellschaft mit beschränkter Haftung"
    },
    {
      "label": "FIDUS Finanz AG",
      "value": "FIDUS Finanz AG"
    },
    {
      "label": "Fiera Capital (Germany) GmbH",
      "value": "Fiera Capital (Germany) GmbH"
    },
    {
      "label": "FIL (Luxembourg) S.A. - Germany Branch",
      "value": "FIL (Luxembourg) S.A. - Germany Branch"
    },
    {
      "label": "FIL Fondsbank GmbH",
      "value": "FIL Fondsbank GmbH"
    },
    {
      "label": "fima Vermögensverwaltung GmbH",
      "value": "fima Vermögensverwaltung GmbH"
    },
    {
      "label": "FIMAX Vermögensberatung GmbH",
      "value": "FIMAX Vermögensberatung GmbH"
    },
    {
      "label": "Finacor Deutschland GmbH",
      "value": "Finacor Deutschland GmbH"
    },
    {
      "label": "FINAD GmbH Deutschland",
      "value": "FINAD GmbH Deutschland"
    },
    {
      "label": "Finance in Motion GmbH",
      "value": "Finance in Motion GmbH"
    },
    {
      "label": "FINANCIERE DE L'ECHIQUIER S.A. Frankfurt Branch Zweigniederlassung der FINANCIERE DE L'ECHIQUIER S.A.",
      "value": "FINANCIERE DE L'ECHIQUIER S.A. Frankfurt Branch Zweigniederlassung der FINANCIERE DE L'ECHIQUIER S.A."
    },
    {
      "label": "FinanzAdmin Wertpapierdienstleistungen GmbH Zweigniederlassung Illertissen",
      "value": "FinanzAdmin Wertpapierdienstleistungen GmbH Zweigniederlassung Illertissen"
    },
    {
      "label": "Finanzbüro Vermögensverwaltungs GmbH",
      "value": "Finanzbüro Vermögensverwaltungs GmbH"
    },
    {
      "label": "Finanzcenter Koerner GmbH",
      "value": "Finanzcenter Koerner GmbH"
    },
    {
      "label": "Finanzierungsgesellschaft für öffentliche Vorhaben des Landes Baden-Württemberg mbH",
      "value": "Finanzierungsgesellschaft für öffentliche Vorhaben des Landes Baden-Württemberg mbH"
    },
    {
      "label": "FINANZINVEST CONSULTING GmbH",
      "value": "FINANZINVEST CONSULTING GmbH"
    },
    {
      "label": "Finanzmatrix Vermögensverwaltungs AG",
      "value": "Finanzmatrix Vermögensverwaltungs AG"
    },
    {
      "label": "finAPI GmbH",
      "value": "finAPI GmbH"
    },
    {
      "label": "FINATEM II GmbH & Co. KG",
      "value": "FINATEM II GmbH & Co. KG"
    },
    {
      "label": "Finatem III GmbH & Co. KG",
      "value": "Finatem III GmbH & Co. KG"
    },
    {
      "label": "FINATEM IV GmbH & Co. KG",
      "value": "FINATEM IV GmbH & Co. KG"
    },
    {
      "label": "FINCCAM Investment GmbH",
      "value": "FINCCAM Investment GmbH"
    },
    {
      "label": "Finch Properties Investment Partners GmbH",
      "value": "Finch Properties Investment Partners GmbH"
    },
    {
      "label": "Findependence AG",
      "value": "Findependence AG"
    },
    {
      "label": "Findos Investor GmbH",
      "value": "Findos Investor GmbH"
    },
    {
      "label": "FiNet Asset Management AG",
      "value": "FiNet Asset Management AG"
    },
    {
      "label": "FINEXX GmbH Unternehmensbeteiligungen",
      "value": "FINEXX GmbH Unternehmensbeteiligungen"
    },
    {
      "label": "FINEXX Verwaltung E GmbH",
      "value": "FINEXX Verwaltung E GmbH"
    },
    {
      "label": "finleap connect GmbH",
      "value": "finleap connect GmbH"
    },
    {
      "label": "fino run GmbH",
      "value": "fino run GmbH"
    },
    {
      "label": "FINOVESTA GMBH",
      "value": "FINOVESTA GMBH"
    },
    {
      "label": "FiNUM.Private Finance AG",
      "value": "FiNUM.Private Finance AG"
    },
    {
      "label": "FINVIA Alternative Investments GmbH",
      "value": "FINVIA Alternative Investments GmbH"
    },
    {
      "label": "FINVIA Capital GmbH",
      "value": "FINVIA Capital GmbH"
    },
    {
      "label": "First Capital Management Group GmbH",
      "value": "First Capital Management Group GmbH"
    },
    {
      "label": "First Commercial Bank, Ltd. Frankfurt Branch",
      "value": "First Commercial Bank, Ltd. Frankfurt Branch"
    },
    {
      "label": "First Data GmbH",
      "value": "First Data GmbH"
    },
    {
      "label": "First Private Investment Management KAG mbH",
      "value": "First Private Investment Management KAG mbH"
    },
    {
      "label": "First Quant GmbH",
      "value": "First Quant GmbH"
    },
    {
      "label": "First Sentier Investors (Ireland) Limited Niederlassung Frankfurt",
      "value": "First Sentier Investors (Ireland) Limited Niederlassung Frankfurt"
    },
    {
      "label": "First Trust Global Portfolios Management Limited, German Branch",
      "value": "First Trust Global Portfolios Management Limited, German Branch"
    },
    {
      "label": "Fisco Aktiengesellschaft",
      "value": "Fisco Aktiengesellschaft"
    },
    {
      "label": "Fitnessgeräte Leasing GmbH",
      "value": "Fitnessgeräte Leasing GmbH"
    },
    {
      "label": "Five Quarters Real Estate AG",
      "value": "Five Quarters Real Estate AG"
    },
    {
      "label": "FIVV Finanzinformation & Vermögensverwaltung Aktiengesellschaft",
      "value": "FIVV Finanzinformation & Vermögensverwaltung Aktiengesellschaft"
    },
    {
      "label": "flatexDEGIRO Bank AG",
      "value": "flatexDEGIRO Bank AG"
    },
    {
      "label": "FleetBoston Robertson Stephens International Limited Repräsentanz",
      "value": "FleetBoston Robertson Stephens International Limited Repräsentanz"
    },
    {
      "label": "FleetCompany GmbH",
      "value": "FleetCompany GmbH"
    },
    {
      "label": "FLEX Capital Management GmbH",
      "value": "FLEX Capital Management GmbH"
    },
    {
      "label": "FLG Leasing GmbH",
      "value": "FLG Leasing GmbH"
    },
    {
      "label": "Flossbach von Storch AG",
      "value": "Flossbach von Storch AG"
    },
    {
      "label": "Flossbach von Storch Invest S.A. Niederlassung Deutschland",
      "value": "Flossbach von Storch Invest S.A. Niederlassung Deutschland"
    },
    {
      "label": "Flowerfield Vermögensverwaltung GmbH",
      "value": "Flowerfield Vermögensverwaltung GmbH"
    },
    {
      "label": "Fly Ventures Management GmbH",
      "value": "Fly Ventures Management GmbH"
    },
    {
      "label": "FM Insurance Europe S.A., Niederlassung für Deutschland",
      "value": "FM Insurance Europe S.A., Niederlassung für Deutschland"
    },
    {
      "label": "FM Verwaltung GmbH",
      "value": "FM Verwaltung GmbH"
    },
    {
      "label": "FML Finanzierungs- und Mobilien Leasing GmbH & Co. KG",
      "value": "FML Finanzierungs- und Mobilien Leasing GmbH & Co. KG"
    },
    {
      "label": "FMV First Momentum Ventures Management GmbH",
      "value": "FMV First Momentum Ventures Management GmbH"
    },
    {
      "label": "FO Administration GmbH",
      "value": "FO Administration GmbH"
    },
    {
      "label": "FOCAM AG",
      "value": "FOCAM AG"
    },
    {
      "label": "FOCUS Asset Management GmbH",
      "value": "FOCUS Asset Management GmbH"
    },
    {
      "label": "FOM Invest GmbH",
      "value": "FOM Invest GmbH"
    },
    {
      "label": "Fonds Direkt AG",
      "value": "Fonds Direkt AG"
    },
    {
      "label": "Fondsbörse Deutschland Beteiligungsmakler AG",
      "value": "Fondsbörse Deutschland Beteiligungsmakler AG"
    },
    {
      "label": "Fondsdepot Bank GmbH",
      "value": "Fondsdepot Bank GmbH"
    },
    {
      "label": "Fondshandel Direkt GmbH",
      "value": "Fondshandel Direkt GmbH"
    },
    {
      "label": "FONTIS Advisory Office GmbH",
      "value": "FONTIS Advisory Office GmbH"
    },
    {
      "label": "Ford Bank GmbH",
      "value": "Ford Bank GmbH"
    },
    {
      "label": "FORSA Geld- und Kapitalmarkt GmbH",
      "value": "FORSA Geld- und Kapitalmarkt GmbH"
    },
    {
      "label": "Fortezza Finanz AG",
      "value": "Fortezza Finanz AG"
    },
    {
      "label": "Foundamental GmbH & Co. KG",
      "value": "Foundamental GmbH & Co. KG"
    },
    {
      "label": "Foundamental Management GmbH",
      "value": "Foundamental Management GmbH"
    },
    {
      "label": "FP ASSET MANAGEMENT GmbH",
      "value": "FP ASSET MANAGEMENT GmbH"
    },
    {
      "label": "FPM Frankfurt Performance Management AG",
      "value": "FPM Frankfurt Performance Management AG"
    },
    {
      "label": "FPS Vermögensverwaltung GmbH",
      "value": "FPS Vermögensverwaltung GmbH"
    },
    {
      "label": "Frankenberger Bank, Raiffeisenbank eG",
      "value": "Frankenberger Bank, Raiffeisenbank eG"
    },
    {
      "label": "Frankfurt Financial Solutions GmbH & Co. KG",
      "value": "Frankfurt Financial Solutions GmbH & Co. KG"
    },
    {
      "label": "Frankfurt Münchener Lebensversicherung AG",
      "value": "Frankfurt Münchener Lebensversicherung AG"
    },
    {
      "label": "Frankfurt School Financial Services GmbH",
      "value": "Frankfurt School Financial Services GmbH"
    },
    {
      "label": "Frankfurter Bankgesellschaft (Deutschland) AG",
      "value": "Frankfurter Bankgesellschaft (Deutschland) AG"
    },
    {
      "label": "Frankfurter Lebensversicherung AG",
      "value": "Frankfurter Lebensversicherung AG"
    },
    {
      "label": "Frankfurter Sparkasse",
      "value": "Frankfurter Sparkasse"
    },
    {
      "label": "Frankfurter Volksbank RHEIN-MAIN eG",
      "value": "Frankfurter Volksbank RHEIN-MAIN eG"
    },
    {
      "label": "Franklin Templeton International Services S.A.R.L., Niederlassung Deutschland",
      "value": "Franklin Templeton International Services S.A.R.L., Niederlassung Deutschland"
    },
    {
      "label": "FRANTO Service & Leasing GmbH & Co. KG",
      "value": "FRANTO Service & Leasing GmbH & Co. KG"
    },
    {
      "label": "Franz Martz & Söhne Private Treuhand GmbH",
      "value": "Franz Martz & Söhne Private Treuhand GmbH"
    },
    {
      "label": "Franzen Gerber & Westphalen Asset Management GmbH",
      "value": "Franzen Gerber & Westphalen Asset Management GmbH"
    },
    {
      "label": "Freiburger Vermögensmanagement GmbH",
      "value": "Freiburger Vermögensmanagement GmbH"
    },
    {
      "label": "Freie Arzt- und Medizinkasse der Angehörigen der Berufsfeuerwehr und der Polizei VVaG",
      "value": "Freie Arzt- und Medizinkasse der Angehörigen der Berufsfeuerwehr und der Polizei VVaG"
    },
    {
      "label": "Freisinger Bank eG Volksbank-Raiffeisenbank",
      "value": "Freisinger Bank eG Volksbank-Raiffeisenbank"
    },
    {
      "label": "Fressnapf Tiernahrungs GmbH",
      "value": "Fressnapf Tiernahrungs GmbH"
    },
    {
      "label": "Freudenberg Rückversicherung AG",
      "value": "Freudenberg Rückversicherung AG"
    },
    {
      "label": "FROHE ZUKUNFT Wohnungsgenossenschaft eG",
      "value": "FROHE ZUKUNFT Wohnungsgenossenschaft eG"
    },
    {
      "label": "FundRock Distribution DE Branch",
      "value": "FundRock Distribution DE Branch"
    },
    {
      "label": "FURE Management GmbH",
      "value": "FURE Management GmbH"
    },
    {
      "label": "Furkert & Schneider Private Asset Management KG",
      "value": "Furkert & Schneider Private Asset Management KG"
    },
    {
      "label": "Future Energy Ventures GmbH",
      "value": "Future Energy Ventures GmbH"
    },
    {
      "label": "Futuro Ventures Management GmbH",
      "value": "Futuro Ventures Management GmbH"
    },
    {
      "label": "futurum bank AG",
      "value": "futurum bank AG"
    },
    {
      "label": "Futury Capital GmbH",
      "value": "Futury Capital GmbH"
    },
    {
      "label": "Futury Private Venture GmbH",
      "value": "Futury Private Venture GmbH"
    },
    {
      "label": "FV Frankfurter Vermögen AG",
      "value": "FV Frankfurter Vermögen AG"
    },
    {
      "label": "FWU Factoring GmbH",
      "value": "FWU Factoring GmbH"
    },
    {
      "label": "FWU Life Insurance Lux S.A.",
      "value": "FWU Life Insurance Lux S.A."
    },
    {
      "label": "FWU Payment GmbH",
      "value": "FWU Payment GmbH"
    },
    {
      "label": "FXFlat Bank AG",
      "value": "FXFlat Bank AG"
    },
    {
      "label": "Förde Sparkasse",
      "value": "Förde Sparkasse"
    },
    {
      "label": "Försäkringsaktiebolaget Agria, Germany branch",
      "value": "Försäkringsaktiebolaget Agria, Germany branch"
    },
    {
      "label": "Fürsorgekasse von 1908 vormals Sterbekasse der Neuapostolischen Kirche des Landes Nordrhein-Westfalen",
      "value": "Fürsorgekasse von 1908 vormals Sterbekasse der Neuapostolischen Kirche des Landes Nordrhein-Westfalen"
    },
    {
      "label": "Fürst Fugger Privatbank Aktiengesellschaft",
      "value": "Fürst Fugger Privatbank Aktiengesellschaft"
    },
    {
      "label": "Fürstlich Castell'sche Bank, Credit-Casse Aktiengesellschaft",
      "value": "Fürstlich Castell'sche Bank, Credit-Casse Aktiengesellschaft"
    },
    {
      "label": "G & H Vermögensverwaltung GmbH",
      "value": "G & H Vermögensverwaltung GmbH"
    },
    {
      "label": "g.u.t. Garten- und Heimtierbedarfs GmbH",
      "value": "g.u.t. Garten- und Heimtierbedarfs GmbH"
    },
    {
      "label": "Gabler-Saliter Bankgeschäft AG",
      "value": "Gabler-Saliter Bankgeschäft AG"
    },
    {
      "label": "GAM (Luxembourg) S.A. - Zweigniederlassung Deutschland",
      "value": "GAM (Luxembourg) S.A. - Zweigniederlassung Deutschland"
    },
    {
      "label": "GANÉ Investment-AG mit Teilgesellschaftsvermögen",
      "value": "GANÉ Investment-AG mit Teilgesellschaftsvermögen"
    },
    {
      "label": "GAP Vermögensverwaltung GmbH",
      "value": "GAP Vermögensverwaltung GmbH"
    },
    {
      "label": "Garant Autovermietung GmbH",
      "value": "Garant Autovermietung GmbH"
    },
    {
      "label": "Garant Service GmbH & Co. KG",
      "value": "Garant Service GmbH & Co. KG"
    },
    {
      "label": "GARANTA Versicherungs-Aktiengesellschaft",
      "value": "GARANTA Versicherungs-Aktiengesellschaft"
    },
    {
      "label": "GarantiBank International N.V. Niederlassung Düsseldorf",
      "value": "GarantiBank International N.V. Niederlassung Düsseldorf"
    },
    {
      "label": "Garching SLC 2.2 Fonds GmbH & Co. geschlossene Investment-KG",
      "value": "Garching SLC 2.2 Fonds GmbH & Co. geschlossene Investment-KG"
    },
    {
      "label": "Gartenbau-Versicherung VVaG",
      "value": "Gartenbau-Versicherung VVaG"
    },
    {
      "label": "Gartenstadt Karlsruhe eG",
      "value": "Gartenstadt Karlsruhe eG"
    },
    {
      "label": "Gartenstadt Nürnberg eG",
      "value": "Gartenstadt Nürnberg eG"
    },
    {
      "label": "Gartenstadt-Genossenschaft Mannheim eG",
      "value": "Gartenstadt-Genossenschaft Mannheim eG"
    },
    {
      "label": "GAV Versicherungs-AG",
      "value": "GAV Versicherungs-AG"
    },
    {
      "label": "GBE Brokers Ltd., Zweigniederlassung Hamburg",
      "value": "GBE Brokers Ltd., Zweigniederlassung Hamburg"
    },
    {
      "label": "GBK Beteiligungen Aktiengesellschaft",
      "value": "GBK Beteiligungen Aktiengesellschaft"
    },
    {
      "label": "GE Industrial Finance Germany GmbH",
      "value": "GE Industrial Finance Germany GmbH"
    },
    {
      "label": "GE.BE.IN Versicherungen Versicherungsverein auf Gegenseitigkeit",
      "value": "GE.BE.IN Versicherungen Versicherungsverein auf Gegenseitigkeit"
    },
    {
      "label": "GEB Schuh-Großeinkaufsbund GmbH & Co.KG",
      "value": "GEB Schuh-Großeinkaufsbund GmbH & Co.KG"
    },
    {
      "label": "Gebäudeversicherungsgilde für Föhr,Amrum und Halligen",
      "value": "Gebäudeversicherungsgilde für Föhr,Amrum und Halligen"
    },
    {
      "label": "GEFA BANK GmbH",
      "value": "GEFA BANK GmbH"
    },
    {
      "label": "Gemeinnützige Bau- und Siedlungsgenossenschaft Wiesbaden 1950 eG",
      "value": "Gemeinnützige Bau- und Siedlungsgenossenschaft Wiesbaden 1950 eG"
    },
    {
      "label": "Gemeinnützige Baugenossenschaft Bergedorf - Bille eG",
      "value": "Gemeinnützige Baugenossenschaft Bergedorf - Bille eG"
    },
    {
      "label": "Gemeinnützige Haftpflichtversicherungsanstalt Kassel",
      "value": "Gemeinnützige Haftpflichtversicherungsanstalt Kassel"
    },
    {
      "label": "Gemeinnützige Wohnungsgenossenschaft Weimar e.G.",
      "value": "Gemeinnützige Wohnungsgenossenschaft Weimar e.G."
    },
    {
      "label": "Gemeinsames Kollegenhilfswerk für LZB-Angehörige e.V.",
      "value": "Gemeinsames Kollegenhilfswerk für LZB-Angehörige e.V."
    },
    {
      "label": "Geneon Vermögensmanagement AG",
      "value": "Geneon Vermögensmanagement AG"
    },
    {
      "label": "General Reinsurance AG",
      "value": "General Reinsurance AG"
    },
    {
      "label": "Generali Deutschland AG",
      "value": "Generali Deutschland AG"
    },
    {
      "label": "Generali Deutschland Krankenversicherung AG",
      "value": "Generali Deutschland Krankenversicherung AG"
    },
    {
      "label": "Generali Deutschland Lebensversicherung AG",
      "value": "Generali Deutschland Lebensversicherung AG"
    },
    {
      "label": "Generali Deutschland Pensionskasse AG",
      "value": "Generali Deutschland Pensionskasse AG"
    },
    {
      "label": "Generali Deutschland Versicherung AG",
      "value": "Generali Deutschland Versicherung AG"
    },
    {
      "label": "Generali Insurance Asset Management S.p.A. Società di gestione del risparmio, Zweigniederlassung Deutschland",
      "value": "Generali Insurance Asset Management S.p.A. Società di gestione del risparmio, Zweigniederlassung Deutschland"
    },
    {
      "label": "Generali Investments Partners S.p.A. Società di gestione del Risparmio, Zweigniederlassung Deutschland",
      "value": "Generali Investments Partners S.p.A. Società di gestione del Risparmio, Zweigniederlassung Deutschland"
    },
    {
      "label": "Generali Pensionsfonds AG",
      "value": "Generali Pensionsfonds AG"
    },
    {
      "label": "Generali Treuhand e.V.",
      "value": "Generali Treuhand e.V."
    },
    {
      "label": "Genius Finanzmanufaktur GmbH",
      "value": "Genius Finanzmanufaktur GmbH"
    },
    {
      "label": "GENO BANK ESSEN eG",
      "value": "GENO BANK ESSEN eG"
    },
    {
      "label": "GENO Broker GmbH",
      "value": "GENO Broker GmbH"
    },
    {
      "label": "Geno Pensionskasse VVaG, Karlsruhe",
      "value": "Geno Pensionskasse VVaG, Karlsruhe"
    },
    {
      "label": "Genobank Mainz eG",
      "value": "Genobank Mainz eG"
    },
    {
      "label": "Genossenschaft für Wohnungsbau Karlsruhe 1921 eG",
      "value": "Genossenschaft für Wohnungsbau Karlsruhe 1921 eG"
    },
    {
      "label": "Genossenschaftsbank eG München",
      "value": "Genossenschaftsbank eG München"
    },
    {
      "label": "Genossenschaftsbank Unterallgäu eG",
      "value": "Genossenschaftsbank Unterallgäu eG"
    },
    {
      "label": "Genossenschaftsbank Weil im Schönbuch eG",
      "value": "Genossenschaftsbank Weil im Schönbuch eG"
    },
    {
      "label": "GENTUM Immobilien Kapitalverwaltungsgesellschaft mbH",
      "value": "GENTUM Immobilien Kapitalverwaltungsgesellschaft mbH"
    },
    {
      "label": "GENUI I GmbH & Co. KG",
      "value": "GENUI I GmbH & Co. KG"
    },
    {
      "label": "GENUI II GmbH & Co. KG",
      "value": "GENUI II GmbH & Co. KG"
    },
    {
      "label": "GENUI III GmbH & Co. KG",
      "value": "GENUI III GmbH & Co. KG"
    },
    {
      "label": "Gerd Kommer Invest GmbH",
      "value": "Gerd Kommer Invest GmbH"
    },
    {
      "label": "Gerhard E. Heil Gesellschaft mit beschränkter Haftung",
      "value": "Gerhard E. Heil Gesellschaft mit beschränkter Haftung"
    },
    {
      "label": "Gerhard Friedenberger Vermögensverwaltung und Family Office GmbH",
      "value": "Gerhard Friedenberger Vermögensverwaltung und Family Office GmbH"
    },
    {
      "label": "Gerling Versorgungskasse",
      "value": "Gerling Versorgungskasse"
    },
    {
      "label": "German Capital Management AG",
      "value": "German Capital Management AG"
    },
    {
      "label": "German Equity Partners IV GmbH & Co. KG",
      "value": "German Equity Partners IV GmbH & Co. KG"
    },
    {
      "label": "German Equity Partners V GmbH & Co. KG",
      "value": "German Equity Partners V GmbH & Co. KG"
    },
    {
      "label": "German SmartLease GmbH",
      "value": "German SmartLease GmbH"
    },
    {
      "label": "Gerold Kroker Autoverleih",
      "value": "Gerold Kroker Autoverleih"
    },
    {
      "label": "GES Großeinkaufsring des Süßwaren- u. Getränkehandels eingetragene Genossenschaft",
      "value": "GES Großeinkaufsring des Süßwaren- u. Getränkehandels eingetragene Genossenschaft"
    },
    {
      "label": "GET Capital AG",
      "value": "GET Capital AG"
    },
    {
      "label": "Getsafe Insurance AG",
      "value": "Getsafe Insurance AG"
    },
    {
      "label": "GEVA Gesellschaft für Einkauf, Verkaufsförderung und Absatz von Gütern mbH & Co.KG",
      "value": "GEVA Gesellschaft für Einkauf, Verkaufsförderung und Absatz von Gütern mbH & Co.KG"
    },
    {
      "label": "GEWOBA Nord Baugenossenschaft eG",
      "value": "GEWOBA Nord Baugenossenschaft eG"
    },
    {
      "label": "GFA Vermögensverwaltung GmbH",
      "value": "GFA Vermögensverwaltung GmbH"
    },
    {
      "label": "GFEP Management GmbH",
      "value": "GFEP Management GmbH"
    },
    {
      "label": "GFL Gesellschaft für Finanzierungsberatung und Leasing GmbH",
      "value": "GFL Gesellschaft für Finanzierungsberatung und Leasing GmbH"
    },
    {
      "label": "GFS Global Financial Solutions GmbH",
      "value": "GFS Global Financial Solutions GmbH"
    },
    {
      "label": "GGG Kraftfahrzeug-Reparaturkosten-Versicherungs-Aktiengesellschaft",
      "value": "GGG Kraftfahrzeug-Reparaturkosten-Versicherungs-Aktiengesellschaft"
    },
    {
      "label": "GGV Grundstücksgesellschaft Verwaltungsgebäude Neuenfelder Straße mbH",
      "value": "GGV Grundstücksgesellschaft Verwaltungsgebäude Neuenfelder Straße mbH"
    },
    {
      "label": "Gies und Heimburger GmbH",
      "value": "Gies und Heimburger GmbH"
    },
    {
      "label": "Giesecke+Devrient Ventures Management GmbH",
      "value": "Giesecke+Devrient Ventures Management GmbH"
    },
    {
      "label": "Ginmon Vermögensverwaltung GmbH",
      "value": "Ginmon Vermögensverwaltung GmbH"
    },
    {
      "label": "GLADBACHER BANK Aktiengesellschaft von 1922",
      "value": "GLADBACHER BANK Aktiengesellschaft von 1922"
    },
    {
      "label": "Glatfelter Gernsbach Pensionskasse  VVaG",
      "value": "Glatfelter Gernsbach Pensionskasse  VVaG"
    },
    {
      "label": "GLL Real Estate Partners Kapitalverwaltungsgesellschaft mbH",
      "value": "GLL Real Estate Partners Kapitalverwaltungsgesellschaft mbH"
    },
    {
      "label": "Global Speed Cash GmbH",
      "value": "Global Speed Cash GmbH"
    },
    {
      "label": "GLOBAL Vermögenspartner GmbH",
      "value": "GLOBAL Vermögenspartner GmbH"
    },
    {
      "label": "Globalance Invest GmbH",
      "value": "Globalance Invest GmbH"
    },
    {
      "label": "GLOGGER & PARTNER Vermögensverwaltung GmbH",
      "value": "GLOGGER & PARTNER Vermögensverwaltung GmbH"
    },
    {
      "label": "GLS Gemeinschaftsbank e.G.",
      "value": "GLS Gemeinschaftsbank e.G."
    },
    {
      "label": "GLS Investment Management GmbH",
      "value": "GLS Investment Management GmbH"
    },
    {
      "label": "GML Gesellschaft für Mittelstandsleasing mbH",
      "value": "GML Gesellschaft für Mittelstandsleasing mbH"
    },
    {
      "label": "GMPVC Venture Management GmbH",
      "value": "GMPVC Venture Management GmbH"
    },
    {
      "label": "GNIW Gesellschaft für Nachhaltige Immobilienwirtschaft mbH",
      "value": "GNIW Gesellschaft für Nachhaltige Immobilienwirtschaft mbH"
    },
    {
      "label": "Golding Capital Partners GmbH",
      "value": "Golding Capital Partners GmbH"
    },
    {
      "label": "Goldman Sachs Bank Europe SE",
      "value": "Goldman Sachs Bank Europe SE"
    },
    {
      "label": "Goldman Sachs International Bank Zweigniederlassung Frankfurt",
      "value": "Goldman Sachs International Bank Zweigniederlassung Frankfurt"
    },
    {
      "label": "Gonçalves Vermögensmanagement AG",
      "value": "Gonçalves Vermögensmanagement AG"
    },
    {
      "label": "Gothaer Allgemeine Versicherung Aktiengesellschaft",
      "value": "Gothaer Allgemeine Versicherung Aktiengesellschaft"
    },
    {
      "label": "Gothaer Asset Management AG",
      "value": "Gothaer Asset Management AG"
    },
    {
      "label": "Gothaer Finanzholding Aktiengesellschaft",
      "value": "Gothaer Finanzholding Aktiengesellschaft"
    },
    {
      "label": "Gothaer Krankenversicherung Aktiengesellschaft",
      "value": "Gothaer Krankenversicherung Aktiengesellschaft"
    },
    {
      "label": "Gothaer Lebensversicherung Aktiengesellschaft",
      "value": "Gothaer Lebensversicherung Aktiengesellschaft"
    },
    {
      "label": "Gothaer Pensionskasse AG",
      "value": "Gothaer Pensionskasse AG"
    },
    {
      "label": "Gothaer Versicherungsbank VVaG",
      "value": "Gothaer Versicherungsbank VVaG"
    },
    {
      "label": "Gouda Versicherungs-AG, Gouda/Niederlande, Niederlassung Deutschland c/o Capellmann Consulting",
      "value": "Gouda Versicherungs-AG, Gouda/Niederlande, Niederlassung Deutschland c/o Capellmann Consulting"
    },
    {
      "label": "Goyer & Göppel KG",
      "value": "Goyer & Göppel KG"
    },
    {
      "label": "GR Asset Management GmbH",
      "value": "GR Asset Management GmbH"
    },
    {
      "label": "Grafschafter Volksbank eG",
      "value": "Grafschafter Volksbank eG"
    },
    {
      "label": "Great Lakes Insurance SE",
      "value": "Great Lakes Insurance SE"
    },
    {
      "label": "Green Capital Management GmbH",
      "value": "Green Capital Management GmbH"
    },
    {
      "label": "Green Generation Co-Invest I GmbH & Co. KG",
      "value": "Green Generation Co-Invest I GmbH & Co. KG"
    },
    {
      "label": "Green Generation Co-Invest II GmbH & Co. KG",
      "value": "Green Generation Co-Invest II GmbH & Co. KG"
    },
    {
      "label": "Green Generation Co-Invest III GmbH & Co. KG",
      "value": "Green Generation Co-Invest III GmbH & Co. KG"
    },
    {
      "label": "Green Generation Fund GmbH & Co. KG",
      "value": "Green Generation Fund GmbH & Co. KG"
    },
    {
      "label": "Green.Health Management GmbH",
      "value": "Green.Health Management GmbH"
    },
    {
      "label": "Greenhill Europe GmbH & Co. KG",
      "value": "Greenhill Europe GmbH & Co. KG"
    },
    {
      "label": "GREENPEAK Management GmbH",
      "value": "GREENPEAK Management GmbH"
    },
    {
      "label": "Greiff Capital Management AG",
      "value": "Greiff Capital Management AG"
    },
    {
      "label": "GRENKE AG",
      "value": "GRENKE AG"
    },
    {
      "label": "GRENKE BANK AG",
      "value": "GRENKE BANK AG"
    },
    {
      "label": "Grenke Investitionen Verwaltungs Kommanditgesellschaft auf Aktien",
      "value": "Grenke Investitionen Verwaltungs Kommanditgesellschaft auf Aktien"
    },
    {
      "label": "GRENKEFACTORING GmbH",
      "value": "GRENKEFACTORING GmbH"
    },
    {
      "label": "Grohage Einkaufs- u. Importgem.v.Fachgroßhändlern in Milcherzeugnissen, Fettwaren, Eiern sowie anderen Nahrungs- u. Genußmittel eingetr. Genossenschaft",
      "value": "Grohage Einkaufs- u. Importgem.v.Fachgroßhändlern in Milcherzeugnissen, Fettwaren, Eiern sowie anderen Nahrungs- u. Genußmittel eingetr. Genossenschaft"
    },
    {
      "label": "GROHMANN & WEINRAUTER Institutional Asset Management GmbH",
      "value": "GROHMANN & WEINRAUTER Institutional Asset Management GmbH"
    },
    {
      "label": "Grohmann & Weinrauter VermögensManagement GmbH",
      "value": "Grohmann & Weinrauter VermögensManagement GmbH"
    },
    {
      "label": "Grossbötzl, Schmitz & Partner Vermögensverwaltersozietät GmbH",
      "value": "Grossbötzl, Schmitz & Partner Vermögensverwaltersozietät GmbH"
    },
    {
      "label": "growney GmbH",
      "value": "growney GmbH"
    },
    {
      "label": "GRUNDEIGENTÜMER-VERSICHERUNG Versicherungsverein auf Gegenseitigkeit",
      "value": "GRUNDEIGENTÜMER-VERSICHERUNG Versicherungsverein auf Gegenseitigkeit"
    },
    {
      "label": "Grün + Bilfinger Wohlfahrts-und Pensionskasse a.G.",
      "value": "Grün + Bilfinger Wohlfahrts-und Pensionskasse a.G."
    },
    {
      "label": "Gründerfonds Ruhr GmbH & Co. KG",
      "value": "Gründerfonds Ruhr GmbH & Co. KG"
    },
    {
      "label": "Grüner Fisher Investments GmbH",
      "value": "Grüner Fisher Investments GmbH"
    },
    {
      "label": "GS-Leasing GmbH",
      "value": "GS-Leasing GmbH"
    },
    {
      "label": "GSAM + Spee Asset Management AG",
      "value": "GSAM + Spee Asset Management AG"
    },
    {
      "label": "GSP asset management GmbH",
      "value": "GSP asset management GmbH"
    },
    {
      "label": "GSV GmbH Vermögensmanagement-Finanzdienstleistungen",
      "value": "GSV GmbH Vermögensmanagement-Finanzdienstleistungen"
    },
    {
      "label": "Guarantee Standard Investment Company GmbH",
      "value": "Guarantee Standard Investment Company GmbH"
    },
    {
      "label": "Guliver Anlageberatung GmbH",
      "value": "Guliver Anlageberatung GmbH"
    },
    {
      "label": "Gutmann Finanz Strategien Aktiengesellschaft",
      "value": "Gutmann Finanz Strategien Aktiengesellschaft"
    },
    {
      "label": "GVG Rhein-Erft GmbH",
      "value": "GVG Rhein-Erft GmbH"
    },
    {
      "label": "GVO Gegenseitigkeit Versicherung Oldenburg VVaG",
      "value": "GVO Gegenseitigkeit Versicherung Oldenburg VVaG"
    },
    {
      "label": "GVS Financial Solutions GmbH",
      "value": "GVS Financial Solutions GmbH"
    },
    {
      "label": "GVS-GROSSVERBRAUCHERSPEZIALISTEN eG",
      "value": "GVS-GROSSVERBRAUCHERSPEZIALISTEN eG"
    },
    {
      "label": "GVV Direktversicherung AG",
      "value": "GVV Direktversicherung AG"
    },
    {
      "label": "GVV-Kommunalversicherung, Versicherungsverein auf Gegenseitigkeit",
      "value": "GVV-Kommunalversicherung, Versicherungsverein auf Gegenseitigkeit"
    },
    {
      "label": "H&A Global Investment Management GmbH",
      "value": "H&A Global Investment Management GmbH"
    },
    {
      "label": "H&S Investment GmbH",
      "value": "H&S Investment GmbH"
    },
    {
      "label": "H./G./K. Hotel- und Gastronomie-Kauf eG",
      "value": "H./G./K. Hotel- und Gastronomie-Kauf eG"
    },
    {
      "label": "Habbel, Pohlig & Partner Institut für Bank- und Wirtschaftsberatung GmbH",
      "value": "Habbel, Pohlig & Partner Institut für Bank- und Wirtschaftsberatung GmbH"
    },
    {
      "label": "HAC VermögensManagement AG",
      "value": "HAC VermögensManagement AG"
    },
    {
      "label": "Haftpflichtgemeinschaft Deutscher Nahverkehrs- und Versorgungsunternehmen Allgemein (HDNA) VVaG",
      "value": "Haftpflichtgemeinschaft Deutscher Nahverkehrs- und Versorgungsunternehmen Allgemein (HDNA) VVaG"
    },
    {
      "label": "Hagelgilde Versicherungsverein a.G., gegründet 1811",
      "value": "Hagelgilde Versicherungsverein a.G., gegründet 1811"
    },
    {
      "label": "Hagnauer Volksbank eG",
      "value": "Hagnauer Volksbank eG"
    },
    {
      "label": "Hako Finance GmbH",
      "value": "Hako Finance GmbH"
    },
    {
      "label": "Halder GmbH",
      "value": "Halder GmbH"
    },
    {
      "label": "Halifax plc Repräsentant: Andrew Peat Finanz Consultants GmbH",
      "value": "Halifax plc Repräsentant: Andrew Peat Finanz Consultants GmbH"
    },
    {
      "label": "Hallertauer Vermögensmanagement GmbH",
      "value": "Hallertauer Vermögensmanagement GmbH"
    },
    {
      "label": "Hallesche Krankenversicherung auf Gegenseitigkeit",
      "value": "Hallesche Krankenversicherung auf Gegenseitigkeit"
    },
    {
      "label": "HaLog Leasing GmbH",
      "value": "HaLog Leasing GmbH"
    },
    {
      "label": "HALZLA UG (haftungsbeschränkt)",
      "value": "HALZLA UG (haftungsbeschränkt)"
    },
    {
      "label": "Hamburg Asset Management HAM Kapitalverwaltungsgesellschaft mbH",
      "value": "Hamburg Asset Management HAM Kapitalverwaltungsgesellschaft mbH"
    },
    {
      "label": "Hamburg Commercial Bank AG",
      "value": "Hamburg Commercial Bank AG"
    },
    {
      "label": "Hamburger Beamten-Feuer-und Einbruchskasse",
      "value": "Hamburger Beamten-Feuer-und Einbruchskasse"
    },
    {
      "label": "Hamburger Feuerkasse Versicherungs-Aktiengesellschaft",
      "value": "Hamburger Feuerkasse Versicherungs-Aktiengesellschaft"
    },
    {
      "label": "Hamburger Lehrer-Feuerkasse",
      "value": "Hamburger Lehrer-Feuerkasse"
    },
    {
      "label": "HAMBURGER PENSIONSFONDS Pensionsfondsverein auf Gegenseitigkeit",
      "value": "HAMBURGER PENSIONSFONDS Pensionsfondsverein auf Gegenseitigkeit"
    },
    {
      "label": "HAMBURGER PENSIONSKASSE VON 1905 Versicherungsverein auf Gegenseitigkeit",
      "value": "HAMBURGER PENSIONSKASSE VON 1905 Versicherungsverein auf Gegenseitigkeit"
    },
    {
      "label": "HAMBURGER PENSIONSRÜCKDECKUNGSKASSE  Versicherungsverein auf Gegenseitigkeit",
      "value": "HAMBURGER PENSIONSRÜCKDECKUNGSKASSE  Versicherungsverein auf Gegenseitigkeit"
    },
    {
      "label": "Hamburger Sparkasse AG",
      "value": "Hamburger Sparkasse AG"
    },
    {
      "label": "Hamburger Sportbund e.V.",
      "value": "Hamburger Sportbund e.V."
    },
    {
      "label": "Hamburger Stiftung Rehabilitation und Integration",
      "value": "Hamburger Stiftung Rehabilitation und Integration"
    },
    {
      "label": "Hamburger Volksbank eG",
      "value": "Hamburger Volksbank eG"
    },
    {
      "label": "Hamburgische Investitions- und Förderbank",
      "value": "Hamburgische Investitions- und Förderbank"
    },
    {
      "label": "Hamburgische Investitionshandlung Kapitalverwaltungsgesellschaft mbH & Co. KG",
      "value": "Hamburgische Investitionshandlung Kapitalverwaltungsgesellschaft mbH & Co. KG"
    },
    {
      "label": "Hamilton Lane (Germany) GmbH",
      "value": "Hamilton Lane (Germany) GmbH"
    },
    {
      "label": "HANNOVER Finanz GmbH",
      "value": "HANNOVER Finanz GmbH"
    },
    {
      "label": "HANNOVER LEASING Finance GmbH",
      "value": "HANNOVER LEASING Finance GmbH"
    },
    {
      "label": "HANNOVER LEASING GmbH & Co. KG",
      "value": "HANNOVER LEASING GmbH & Co. KG"
    },
    {
      "label": "HANNOVER LEASING Investment GmbH",
      "value": "HANNOVER LEASING Investment GmbH"
    },
    {
      "label": "Hannover Rück SE",
      "value": "Hannover Rück SE"
    },
    {
      "label": "Hannoversche Alterskasse VVaG",
      "value": "Hannoversche Alterskasse VVaG"
    },
    {
      "label": "Hannoversche Lebensversicherung AG",
      "value": "Hannoversche Lebensversicherung AG"
    },
    {
      "label": "Hannoversche Pensionskasse VVaG",
      "value": "Hannoversche Pensionskasse VVaG"
    },
    {
      "label": "Hannoversche Volksbank eG",
      "value": "Hannoversche Volksbank eG"
    },
    {
      "label": "HANSA Baugenossenschaft eG",
      "value": "HANSA Baugenossenschaft eG"
    },
    {
      "label": "HANSA Terminhandel GmbH",
      "value": "HANSA Terminhandel GmbH"
    },
    {
      "label": "HANSAINVEST Hanseatische Investment-GmbH",
      "value": "HANSAINVEST Hanseatische Investment-GmbH"
    },
    {
      "label": "Hanse-Marine-Versicherung AG",
      "value": "Hanse-Marine-Versicherung AG"
    },
    {
      "label": "Hanseatic Bank GmbH & Co KG",
      "value": "Hanseatic Bank GmbH & Co KG"
    },
    {
      "label": "HanseMerkur Allgemeine Versicherung AG",
      "value": "HanseMerkur Allgemeine Versicherung AG"
    },
    {
      "label": "HanseMerkur Krankenversicherung AG",
      "value": "HanseMerkur Krankenversicherung AG"
    },
    {
      "label": "HanseMerkur Krankenversicherung auf Gegenseitigkeit",
      "value": "HanseMerkur Krankenversicherung auf Gegenseitigkeit"
    },
    {
      "label": "HanseMerkur Lebensversicherung AG",
      "value": "HanseMerkur Lebensversicherung AG"
    },
    {
      "label": "HanseMerkur Reiseversicherung AG",
      "value": "HanseMerkur Reiseversicherung AG"
    },
    {
      "label": "HanseMerkur Speziale Krankenversicherung AG",
      "value": "HanseMerkur Speziale Krankenversicherung AG"
    },
    {
      "label": "HanseMerkur Trust AG",
      "value": "HanseMerkur Trust AG"
    },
    {
      "label": "Hansen & Heinrich AG",
      "value": "Hansen & Heinrich AG"
    },
    {
      "label": "HarbourVest Partners (Ireland) Limited, German Branch",
      "value": "HarbourVest Partners (Ireland) Limited, German Branch"
    },
    {
      "label": "Hardtwaldsiedlung Karlsruhe eG, Baugenossenschaft",
      "value": "Hardtwaldsiedlung Karlsruhe eG, Baugenossenschaft"
    },
    {
      "label": "Harris Williams & Co. Corporate Finance GmbH",
      "value": "Harris Williams & Co. Corporate Finance GmbH"
    },
    {
      "label": "Harsewinkeler Versicherung VaG",
      "value": "Harsewinkeler Versicherung VaG"
    },
    {
      "label": "Hartz Regehr GmbH",
      "value": "Hartz Regehr GmbH"
    },
    {
      "label": "Harvstburg Capital GmbH",
      "value": "Harvstburg Capital GmbH"
    },
    {
      "label": "Harzer Volksbank eG",
      "value": "Harzer Volksbank eG"
    },
    {
      "label": "Harzsparkasse",
      "value": "Harzsparkasse"
    },
    {
      "label": "Haser Vermögensverwaltung GmbH",
      "value": "Haser Vermögensverwaltung GmbH"
    },
    {
      "label": "Hauck & Aufhäuser Innovative Capital GmbH",
      "value": "Hauck & Aufhäuser Innovative Capital GmbH"
    },
    {
      "label": "Hauck Aufhäuser Lampe Privatbank AG",
      "value": "Hauck Aufhäuser Lampe Privatbank AG"
    },
    {
      "label": "Haufe Service Center GmbH",
      "value": "Haufe Service Center GmbH"
    },
    {
      "label": "Hausbank München eG Bank für Haus- und Grundbesitz",
      "value": "Hausbank München eG Bank für Haus- und Grundbesitz"
    },
    {
      "label": "Hausbauverein Unitas e.V.",
      "value": "Hausbauverein Unitas e.V."
    },
    {
      "label": "HB Automobil-Leasing GmbH",
      "value": "HB Automobil-Leasing GmbH"
    },
    {
      "label": "HBG Heimbetriebsgesellschaft mbH & Co. KG",
      "value": "HBG Heimbetriebsgesellschaft mbH & Co. KG"
    },
    {
      "label": "HDI Global Network AG",
      "value": "HDI Global Network AG"
    },
    {
      "label": "HDI Global SE",
      "value": "HDI Global SE"
    },
    {
      "label": "HDI Global Specialty SE",
      "value": "HDI Global Specialty SE"
    },
    {
      "label": "HDI Haftpflichtverband der Deutschen Industrie Versicherungsverein auf Gegenseitigkeit",
      "value": "HDI Haftpflichtverband der Deutschen Industrie Versicherungsverein auf Gegenseitigkeit"
    },
    {
      "label": "HDI Lebensversicherung AG",
      "value": "HDI Lebensversicherung AG"
    },
    {
      "label": "HDI Pensionsfonds Aktiengesellschaft",
      "value": "HDI Pensionsfonds Aktiengesellschaft"
    },
    {
      "label": "HDI Pensionskasse AG",
      "value": "HDI Pensionskasse AG"
    },
    {
      "label": "HDI Versicherung AG",
      "value": "HDI Versicherung AG"
    },
    {
      "label": "Headline Management GmbH",
      "value": "Headline Management GmbH"
    },
    {
      "label": "HEAG Pensionszuschusskasse Versicherungsverein auf Gegenseitigkeit",
      "value": "HEAG Pensionszuschusskasse Versicherungsverein auf Gegenseitigkeit"
    },
    {
      "label": "heal.capital Management GmbH",
      "value": "heal.capital Management GmbH"
    },
    {
      "label": "Health Coevo AG",
      "value": "Health Coevo AG"
    },
    {
      "label": "Heemann Vermögensverwaltung AG",
      "value": "Heemann Vermögensverwaltung AG"
    },
    {
      "label": "Heidelberg Print Finance International Gesellschaft mit beschränkter Haftung",
      "value": "Heidelberg Print Finance International Gesellschaft mit beschränkter Haftung"
    },
    {
      "label": "HeidelbergCapital Asset Management GmbH",
      "value": "HeidelbergCapital Asset Management GmbH"
    },
    {
      "label": "Heidelberger Lebensversicherung AG",
      "value": "Heidelberger Lebensversicherung AG"
    },
    {
      "label": "Heidelberger Vermögensmanagement GmbH",
      "value": "Heidelberger Vermögensmanagement GmbH"
    },
    {
      "label": "HEIDELBERGER VOLKSBANK eingetragene Genossenschaft",
      "value": "HEIDELBERGER VOLKSBANK eingetragene Genossenschaft"
    },
    {
      "label": "Heidenheimer Volksbank eG",
      "value": "Heidenheimer Volksbank eG"
    },
    {
      "label": "Heimberg Holding GmbH",
      "value": "Heimberg Holding GmbH"
    },
    {
      "label": "Helaba Invest Kapitalanlagegesellschaft mbH",
      "value": "Helaba Invest Kapitalanlagegesellschaft mbH"
    },
    {
      "label": "Hella Gutmann Anlagenvermietung GmbH",
      "value": "Hella Gutmann Anlagenvermietung GmbH"
    },
    {
      "label": "Hellenstein Leasing GmbH",
      "value": "Hellenstein Leasing GmbH"
    },
    {
      "label": "HELLERICH GmbH",
      "value": "HELLERICH GmbH"
    },
    {
      "label": "Hellwig Wertpapierhandelsbank GmbH",
      "value": "Hellwig Wertpapierhandelsbank GmbH"
    },
    {
      "label": "HELVETIA schweizerische Lebensversicherungs-Aktiengesellschaft",
      "value": "HELVETIA schweizerische Lebensversicherungs-Aktiengesellschaft"
    },
    {
      "label": "Helvetia Schweizerische Versicherungsgesellschaft AG Direktion für Deutschland",
      "value": "Helvetia Schweizerische Versicherungsgesellschaft AG Direktion für Deutschland"
    },
    {
      "label": "Helvetia Versicherungs-Aktiengesellschaft",
      "value": "Helvetia Versicherungs-Aktiengesellschaft"
    },
    {
      "label": "Henderson Management S.A., German Branch",
      "value": "Henderson Management S.A., German Branch"
    },
    {
      "label": "HEP Kapitalverwaltung AG",
      "value": "HEP Kapitalverwaltung AG"
    },
    {
      "label": "Hermes Fund Managers Ireland (Germany) Limited Niederlassung Frankfurt",
      "value": "Hermes Fund Managers Ireland (Germany) Limited Niederlassung Frankfurt"
    },
    {
      "label": "Herner Sparkasse",
      "value": "Herner Sparkasse"
    },
    {
      "label": "Herzog Leasing AG",
      "value": "Herzog Leasing AG"
    },
    {
      "label": "Hessische Landgesellschaft mbH",
      "value": "Hessische Landgesellschaft mbH"
    },
    {
      "label": "Heun Treuhand GmbH",
      "value": "Heun Treuhand GmbH"
    },
    {
      "label": "Heyer & Hübner Finanzdienstleistungs GmbH",
      "value": "Heyer & Hübner Finanzdienstleistungs GmbH"
    },
    {
      "label": "HEZEL Autovermietung GmbH",
      "value": "HEZEL Autovermietung GmbH"
    },
    {
      "label": "HHL Hamburg Leasing GmbH",
      "value": "HHL Hamburg Leasing GmbH"
    },
    {
      "label": "High-Tech Gründerfonds GmbH & Co. KG",
      "value": "High-Tech Gründerfonds GmbH & Co. KG"
    },
    {
      "label": "High-Tech Gründerfonds II GmbH & Co. KG",
      "value": "High-Tech Gründerfonds II GmbH & Co. KG"
    },
    {
      "label": "High-Tech Gründerfonds III GmbH & Co. KG",
      "value": "High-Tech Gründerfonds III GmbH & Co. KG"
    },
    {
      "label": "High-Tech Gründerfonds IV GmbH & Co. KG",
      "value": "High-Tech Gründerfonds IV GmbH & Co. KG"
    },
    {
      "label": "HIH Invest Real Estate GmbH",
      "value": "HIH Invest Real Estate GmbH"
    },
    {
      "label": "Hilfskasse BVG",
      "value": "Hilfskasse BVG"
    },
    {
      "label": "him leasing GmbH",
      "value": "him leasing GmbH"
    },
    {
      "label": "Hines Luxembourg Investment Management S.á r.l., German Branch",
      "value": "Hines Luxembourg Investment Management S.á r.l., German Branch"
    },
    {
      "label": "HINKEL & Cie. Vermögensverwaltung AG",
      "value": "HINKEL & Cie. Vermögensverwaltung AG"
    },
    {
      "label": "Hinterbliebenenkasse der Heilberufe HDH Versicherungsverein a.G. in München",
      "value": "Hinterbliebenenkasse der Heilberufe HDH Versicherungsverein a.G. in München"
    },
    {
      "label": "HISCOX S.A., Niederlassung für Deutschland",
      "value": "HISCOX S.A., Niederlassung für Deutschland"
    },
    {
      "label": "HIU Hanseatische Schiffsbeteiligungen GmbH & Co. KG",
      "value": "HIU Hanseatische Schiffsbeteiligungen GmbH & Co. KG"
    },
    {
      "label": "HKA Hanseatische Kapitalverwaltung GmbH",
      "value": "HKA Hanseatische Kapitalverwaltung GmbH"
    },
    {
      "label": "HKR Vermögensverwaltungs GmbH",
      "value": "HKR Vermögensverwaltungs GmbH"
    },
    {
      "label": "Hoa Le Finanztransfer GmbH",
      "value": "Hoa Le Finanztransfer GmbH"
    },
    {
      "label": "Hochrhein Internationale Rückversicherung Aktiengesellschaft",
      "value": "Hochrhein Internationale Rückversicherung Aktiengesellschaft"
    },
    {
      "label": "Hoerner-Bank Aktiengesellschaft",
      "value": "Hoerner-Bank Aktiengesellschaft"
    },
    {
      "label": "Hofmann Leasing GmbH",
      "value": "Hofmann Leasing GmbH"
    },
    {
      "label": "Hohenzollerische Landesbank Kreissparkasse Sigmaringen",
      "value": "Hohenzollerische Landesbank Kreissparkasse Sigmaringen"
    },
    {
      "label": "Hohnhaus & Jansenberger Gruppe GmbH & Co. KG",
      "value": "Hohnhaus & Jansenberger Gruppe GmbH & Co. KG"
    },
    {
      "label": "HOIST Finance AB (publ) Niederlassung Deutschland",
      "value": "HOIST Finance AB (publ) Niederlassung Deutschland"
    },
    {
      "label": "Honda Bank GmbH",
      "value": "Honda Bank GmbH"
    },
    {
      "label": "HONESTAS Finanzmanagement GmbH",
      "value": "HONESTAS Finanzmanagement GmbH"
    },
    {
      "label": "Honorarfinanz AG",
      "value": "Honorarfinanz AG"
    },
    {
      "label": "HONORIS Treuhand GmbH",
      "value": "HONORIS Treuhand GmbH"
    },
    {
      "label": "Hoppe und Schultz Aktiengesellschaft",
      "value": "Hoppe und Schultz Aktiengesellschaft"
    },
    {
      "label": "HOPPE VermögensBetreuung GmbH & Co. KG",
      "value": "HOPPE VermögensBetreuung GmbH & Co. KG"
    },
    {
      "label": "Houlihan Lokey (Europe) GmbH",
      "value": "Houlihan Lokey (Europe) GmbH"
    },
    {
      "label": "Howaldt & Co. Investmentaktiengesellschaft TGV",
      "value": "Howaldt & Co. Investmentaktiengesellschaft TGV"
    },
    {
      "label": "Hoya Lens Deutschland GmbH",
      "value": "Hoya Lens Deutschland GmbH"
    },
    {
      "label": "HPG Capital GmbH & Co. KG",
      "value": "HPG Capital GmbH & Co. KG"
    },
    {
      "label": "HPG Immobilien Invest GmbH",
      "value": "HPG Immobilien Invest GmbH"
    },
    {
      "label": "HPM Hanseatische Portfoliomanagement GmbH",
      "value": "HPM Hanseatische Portfoliomanagement GmbH"
    },
    {
      "label": "HPR Capital Management GmbH",
      "value": "HPR Capital Management GmbH"
    },
    {
      "label": "HPR Leasing GmbH",
      "value": "HPR Leasing GmbH"
    },
    {
      "label": "HPS Investment Partners Lux S.à.r.l. (German Branch)",
      "value": "HPS Investment Partners Lux S.à.r.l. (German Branch)"
    },
    {
      "label": "HQ Asset Servicing GmbH",
      "value": "HQ Asset Servicing GmbH"
    },
    {
      "label": "HQ Capital (Deutschland) GmbH",
      "value": "HQ Capital (Deutschland) GmbH"
    },
    {
      "label": "HQ Capital Fondsverwaltungs GmbH",
      "value": "HQ Capital Fondsverwaltungs GmbH"
    },
    {
      "label": "HQ Equita Beteiligungen V GmbH & Co. KG",
      "value": "HQ Equita Beteiligungen V GmbH & Co. KG"
    },
    {
      "label": "HQ Trust GmbH",
      "value": "HQ Trust GmbH"
    },
    {
      "label": "HRS PaySol GmbH",
      "value": "HRS PaySol GmbH"
    },
    {
      "label": "HSBC Global Asset Management (Deutschland) GmbH",
      "value": "HSBC Global Asset Management (Deutschland) GmbH"
    },
    {
      "label": "HSBC Trinkaus & Burkhardt GmbH",
      "value": "HSBC Trinkaus & Burkhardt GmbH"
    },
    {
      "label": "HSL Leasing GmbH",
      "value": "HSL Leasing GmbH"
    },
    {
      "label": "HT Consulting GmbH",
      "value": "HT Consulting GmbH"
    },
    {
      "label": "HT Kapitalverwaltungsgesellschaft mbH",
      "value": "HT Kapitalverwaltungsgesellschaft mbH"
    },
    {
      "label": "HTB Hanseatische Fondshaus GmbH",
      "value": "HTB Hanseatische Fondshaus GmbH"
    },
    {
      "label": "Huber, Reuss & Kollegen Vermögensverwaltung GmbH",
      "value": "Huber, Reuss & Kollegen Vermögensverwaltung GmbH"
    },
    {
      "label": "Huddlestock Systems GmbH",
      "value": "Huddlestock Systems GmbH"
    },
    {
      "label": "HUK-COBURG Asset Management GmbH",
      "value": "HUK-COBURG Asset Management GmbH"
    },
    {
      "label": "HUK-COBURG Haftpflicht-Unterstützungs-Kasse kraftfahrender Beamter Deutschlands a.G. in Coburg",
      "value": "HUK-COBURG Haftpflicht-Unterstützungs-Kasse kraftfahrender Beamter Deutschlands a.G. in Coburg"
    },
    {
      "label": "HUK-COBURG-Allgemeine Versicherung AG",
      "value": "HUK-COBURG-Allgemeine Versicherung AG"
    },
    {
      "label": "HUK-COBURG-Holding AG",
      "value": "HUK-COBURG-Holding AG"
    },
    {
      "label": "HUK-COBURG-Krankenversicherung AG",
      "value": "HUK-COBURG-Krankenversicherung AG"
    },
    {
      "label": "HUK-COBURG-Lebensversicherung AG",
      "value": "HUK-COBURG-Lebensversicherung AG"
    },
    {
      "label": "HUK-COBURG-Rechtsschutzversicherung AG",
      "value": "HUK-COBURG-Rechtsschutzversicherung AG"
    },
    {
      "label": "HUK24 AG",
      "value": "HUK24 AG"
    },
    {
      "label": "Husky Capital GmbH",
      "value": "Husky Capital GmbH"
    },
    {
      "label": "Huth & Lüdicke Finanzdienstleistungs AG",
      "value": "Huth & Lüdicke Finanzdienstleistungs AG"
    },
    {
      "label": "HV Capital Manager GmbH",
      "value": "HV Capital Manager GmbH"
    },
    {
      "label": "HV Fund Management GmbH",
      "value": "HV Fund Management GmbH"
    },
    {
      "label": "HV Holtzbrinck Ventures Co-Investment Fund I GmbH & Co. KG",
      "value": "HV Holtzbrinck Ventures Co-Investment Fund I GmbH & Co. KG"
    },
    {
      "label": "HV Holtzbrinck Ventures Fund V GmbH & Co. KG",
      "value": "HV Holtzbrinck Ventures Fund V GmbH & Co. KG"
    },
    {
      "label": "HVB Trust Pensionsfonds AG",
      "value": "HVB Trust Pensionsfonds AG"
    },
    {
      "label": "HVG Anlageberatung und Vermögensverwaltung GmbH",
      "value": "HVG Anlageberatung und Vermögensverwaltung GmbH"
    },
    {
      "label": "HW Leasing GmbH",
      "value": "HW Leasing GmbH"
    },
    {
      "label": "Hydroport Energy + Infrastructure Verwaltungs GmbH",
      "value": "Hydroport Energy + Infrastructure Verwaltungs GmbH"
    },
    {
      "label": "Hyundai Capital Bank Europe GmbH",
      "value": "Hyundai Capital Bank Europe GmbH"
    },
    {
      "label": "Hywater GmbH & Co. KG",
      "value": "Hywater GmbH & Co. KG"
    },
    {
      "label": "HZG Fund Management GmbH",
      "value": "HZG Fund Management GmbH"
    },
    {
      "label": "Häger Versicherungsverein auf Gegenseitigkeit",
      "value": "Häger Versicherungsverein auf Gegenseitigkeit"
    },
    {
      "label": "Höchster Pensionskasse VVaG",
      "value": "Höchster Pensionskasse VVaG"
    },
    {
      "label": "Höchster Sterbekasse VVaG",
      "value": "Höchster Sterbekasse VVaG"
    },
    {
      "label": "HÖREX Hör-Akustik eG",
      "value": "HÖREX Hör-Akustik eG"
    },
    {
      "label": "Hübener Versicherungs-Aktiengesellschaft",
      "value": "Hübener Versicherungs-Aktiengesellschaft"
    },
    {
      "label": "Hümmlinger Volksbank eG",
      "value": "Hümmlinger Volksbank eG"
    },
    {
      "label": "Hüttenberger Bank eG",
      "value": "Hüttenberger Bank eG"
    },
    {
      "label": "i-vip GmbH",
      "value": "i-vip GmbH"
    },
    {
      "label": "I.C.M. Independent Capital Management Vermögensberatung Mannheim GmbH",
      "value": "I.C.M. Independent Capital Management Vermögensberatung Mannheim GmbH"
    },
    {
      "label": "I.R.M.S. eG Interessengemeinschaft der Rasenmäher- Motorgeräte Spezialwerkstätten",
      "value": "I.R.M.S. eG Interessengemeinschaft der Rasenmäher- Motorgeräte Spezialwerkstätten"
    },
    {
      "label": "IBM Deutschland Kreditbank Gesellschaft mit beschränkter Haftung",
      "value": "IBM Deutschland Kreditbank Gesellschaft mit beschränkter Haftung"
    },
    {
      "label": "IBM Deutschland Pensionsfonds AG",
      "value": "IBM Deutschland Pensionsfonds AG"
    },
    {
      "label": "IBM Deutschland Pensionskasse Versicherungsverein auf Gegenseitigkeit",
      "value": "IBM Deutschland Pensionskasse Versicherungsverein auf Gegenseitigkeit"
    },
    {
      "label": "IBM Global Financing Deutschland GmbH",
      "value": "IBM Global Financing Deutschland GmbH"
    },
    {
      "label": "IC Cash Services GmbH",
      "value": "IC Cash Services GmbH"
    },
    {
      "label": "IC Financial Services S.A. Zweigniederlassung Heilbronn",
      "value": "IC Financial Services S.A. Zweigniederlassung Heilbronn"
    },
    {
      "label": "iCapital Consulting- & Vertriebsgesellschaft mbH",
      "value": "iCapital Consulting- & Vertriebsgesellschaft mbH"
    },
    {
      "label": "ICF BANK AG Wertpapierhandelsbank",
      "value": "ICF BANK AG Wertpapierhandelsbank"
    },
    {
      "label": "ICFB GmbH",
      "value": "ICFB GmbH"
    },
    {
      "label": "ICICI Bank UK PLC, Germany Branch",
      "value": "ICICI Bank UK PLC, Germany Branch"
    },
    {
      "label": "ICM InvestmentBank AG",
      "value": "ICM InvestmentBank AG"
    },
    {
      "label": "Ideal Lebensversicherung a.G.",
      "value": "Ideal Lebensversicherung a.G."
    },
    {
      "label": "IDEAL Sterbekasse Lebensversicherung AG",
      "value": "IDEAL Sterbekasse Lebensversicherung AG"
    },
    {
      "label": "IDEAL Versicherung AG",
      "value": "IDEAL Versicherung AG"
    },
    {
      "label": "If Schadenversicherung AG Direktion für Deutschland",
      "value": "If Schadenversicherung AG Direktion für Deutschland"
    },
    {
      "label": "IFK Institut für Kapitalmarkt GmbH - die Generationen Vermögensverwaltung -",
      "value": "IFK Institut für Kapitalmarkt GmbH - die Generationen Vermögensverwaltung -"
    },
    {
      "label": "IFL Industrie-Leasing GmbH",
      "value": "IFL Industrie-Leasing GmbH"
    },
    {
      "label": "IG Europe GmbH",
      "value": "IG Europe GmbH"
    },
    {
      "label": "IGA Interessengemeinschaft Augenoptik eG",
      "value": "IGA Interessengemeinschaft Augenoptik eG"
    },
    {
      "label": "IHG Industrie- Handel- Gewerbe- Leasing GmbH",
      "value": "IHG Industrie- Handel- Gewerbe- Leasing GmbH"
    },
    {
      "label": "IHT Industrie- und Handels-Treuhand GmbH",
      "value": "IHT Industrie- und Handels-Treuhand GmbH"
    },
    {
      "label": "IIS Asset Management GmbH",
      "value": "IIS Asset Management GmbH"
    },
    {
      "label": "IK Leasing GmbH",
      "value": "IK Leasing GmbH"
    },
    {
      "label": "Ikano Bank AB (publ), Zweigniederlassung Deutschland",
      "value": "Ikano Bank AB (publ), Zweigniederlassung Deutschland"
    },
    {
      "label": "IKB Deutsche Industriebank Aktiengesellschaft",
      "value": "IKB Deutsche Industriebank Aktiengesellschaft"
    },
    {
      "label": "ILG Kapitalverwaltungsgesellschaft mbH",
      "value": "ILG Kapitalverwaltungsgesellschaft mbH"
    },
    {
      "label": "iM Global Partner SAS Zweigniederlassung Deutschland",
      "value": "iM Global Partner SAS Zweigniederlassung Deutschland"
    },
    {
      "label": "IMCap GmbH",
      "value": "IMCap GmbH"
    },
    {
      "label": "Immutable Insight Asset Management GmbH",
      "value": "Immutable Insight Asset Management GmbH"
    },
    {
      "label": "Immutable Insight Capital Management GmbH",
      "value": "Immutable Insight Capital Management GmbH"
    },
    {
      "label": "Impact Asset Management GmbH Zweigniederlassung Frankfurt am Main",
      "value": "Impact Asset Management GmbH Zweigniederlassung Frankfurt am Main"
    },
    {
      "label": "Incomet Capital GmbH",
      "value": "Incomet Capital GmbH"
    },
    {
      "label": "Incura AG",
      "value": "Incura AG"
    },
    {
      "label": "Index Capital GmbH",
      "value": "Index Capital GmbH"
    },
    {
      "label": "Industrial and Commercial Bank of China Limited Frankfurt Branch",
      "value": "Industrial and Commercial Bank of China Limited Frankfurt Branch"
    },
    {
      "label": "Infinigon GmbH",
      "value": "Infinigon GmbH"
    },
    {
      "label": "Infinity Financial Solutions GmbH",
      "value": "Infinity Financial Solutions GmbH"
    },
    {
      "label": "ING-DiBa AG",
      "value": "ING-DiBa AG"
    },
    {
      "label": "Innovative Investment Solutions GmbH",
      "value": "Innovative Investment Solutions GmbH"
    },
    {
      "label": "INP Invest GmbH",
      "value": "INP Invest GmbH"
    },
    {
      "label": "Insight Investment Management (Europe) Limited, Niederlassung Deutschland",
      "value": "Insight Investment Management (Europe) Limited, Niederlassung Deutschland"
    },
    {
      "label": "Instinet Germany GmbH",
      "value": "Instinet Germany GmbH"
    },
    {
      "label": "Institut für Portfoliomanagement GmbH",
      "value": "Institut für Portfoliomanagement GmbH"
    },
    {
      "label": "Institutional Investment-Partners GmbH",
      "value": "Institutional Investment-Partners GmbH"
    },
    {
      "label": "INTENA Leasing GmbH",
      "value": "INTENA Leasing GmbH"
    },
    {
      "label": "INTER Allgemeine Versicherung AG",
      "value": "INTER Allgemeine Versicherung AG"
    },
    {
      "label": "Inter ES Handels- und Dienstleistungs GmbH & Co. KG",
      "value": "Inter ES Handels- und Dienstleistungs GmbH & Co. KG"
    },
    {
      "label": "INTER Krankenversicherung AG",
      "value": "INTER Krankenversicherung AG"
    },
    {
      "label": "INTER Lebensversicherung AG",
      "value": "INTER Lebensversicherung AG"
    },
    {
      "label": "Inter Partner Assistance SA Direktion für Deutschland",
      "value": "Inter Partner Assistance SA Direktion für Deutschland"
    },
    {
      "label": "INTER Versicherungsverein aG",
      "value": "INTER Versicherungsverein aG"
    },
    {
      "label": "InterCard AG",
      "value": "InterCard AG"
    },
    {
      "label": "Interface Capital Management GmbH",
      "value": "Interface Capital Management GmbH"
    },
    {
      "label": "INTERlease GmbH",
      "value": "INTERlease GmbH"
    },
    {
      "label": "Interlloyd Versicherungs-Aktiengesellschaft",
      "value": "Interlloyd Versicherungs-Aktiengesellschaft"
    },
    {
      "label": "International Card Services B.V., Niederlassung Deutschland",
      "value": "International Card Services B.V., Niederlassung Deutschland"
    },
    {
      "label": "Internationale Kapitalanlagegesellschaft mit beschränkter Haftung",
      "value": "Internationale Kapitalanlagegesellschaft mit beschränkter Haftung"
    },
    {
      "label": "Internationales Bankhaus Bodensee Aktiengesellschaft",
      "value": "Internationales Bankhaus Bodensee Aktiengesellschaft"
    },
    {
      "label": "InterRisk Lebensversicherungs-AG Vienna Insurance Group",
      "value": "InterRisk Lebensversicherungs-AG Vienna Insurance Group"
    },
    {
      "label": "InterRisk Versicherungs-AG Vienna Insurance Group",
      "value": "InterRisk Versicherungs-AG Vienna Insurance Group"
    },
    {
      "label": "INTERSPORT Deutschland eG",
      "value": "INTERSPORT Deutschland eG"
    },
    {
      "label": "Intesa Sanpaolo S.p.A. Filiale Frankfurt am Main",
      "value": "Intesa Sanpaolo S.p.A. Filiale Frankfurt am Main"
    },
    {
      "label": "INTL FCStone Ltd. Repräsentanz",
      "value": "INTL FCStone Ltd. Repräsentanz"
    },
    {
      "label": "IntReal International Real Estate Kapitalverwaltungsgesellschaft mbH",
      "value": "IntReal International Real Estate Kapitalverwaltungsgesellschaft mbH"
    },
    {
      "label": "Invesco Asset Management Deutschland GmbH",
      "value": "Invesco Asset Management Deutschland GmbH"
    },
    {
      "label": "Invest in Visions GmbH",
      "value": "Invest in Visions GmbH"
    },
    {
      "label": "Investify S.A.",
      "value": "Investify S.A."
    },
    {
      "label": "Investitions- und Förderbank Niedersachsen (NBank)",
      "value": "Investitions- und Förderbank Niedersachsen (NBank)"
    },
    {
      "label": "Investitions- und Strukturbank Rheinland-Pfalz (ISB)",
      "value": "Investitions- und Strukturbank Rheinland-Pfalz (ISB)"
    },
    {
      "label": "Investitionsbank Berlin",
      "value": "Investitionsbank Berlin"
    },
    {
      "label": "Investitionsbank des Landes Brandenburg",
      "value": "Investitionsbank des Landes Brandenburg"
    },
    {
      "label": "Investitionsbank Schleswig-Holstein",
      "value": "Investitionsbank Schleswig-Holstein"
    },
    {
      "label": "Investmentaktiengesellschaft für langfristige Investoren TGV",
      "value": "Investmentaktiengesellschaft für langfristige Investoren TGV"
    },
    {
      "label": "Inyova Impact Investing GmbH",
      "value": "Inyova Impact Investing GmbH"
    },
    {
      "label": "iptiQ EMEA P&C S.A., Niederlassung Deutschland",
      "value": "iptiQ EMEA P&C S.A., Niederlassung Deutschland"
    },
    {
      "label": "iptiQ Life S.A., Niederlassung Deutschland",
      "value": "iptiQ Life S.A., Niederlassung Deutschland"
    },
    {
      "label": "Isbank AG",
      "value": "Isbank AG"
    },
    {
      "label": "ISF Institut Deutsch-Schweizer Finanzdienstleistungen GmbH",
      "value": "ISF Institut Deutsch-Schweizer Finanzdienstleistungen GmbH"
    },
    {
      "label": "isFINANCE AG",
      "value": "isFINANCE AG"
    },
    {
      "label": "iShares (DE) I Investmentaktiengesellschaft mit Teilgesellschaftsvermögen",
      "value": "iShares (DE) I Investmentaktiengesellschaft mit Teilgesellschaftsvermögen"
    },
    {
      "label": "ISSELHORSTER Versicherung V.a.G.",
      "value": "ISSELHORSTER Versicherung V.a.G."
    },
    {
      "label": "Itzehoer Lebensversicherungs-Aktiengesellschaft",
      "value": "Itzehoer Lebensversicherungs-Aktiengesellschaft"
    },
    {
      "label": "Itzehoer Versicherung/Brandgilde von 1691 Versicherungsverein auf Gegenseitigkeit",
      "value": "Itzehoer Versicherung/Brandgilde von 1691 Versicherungsverein auf Gegenseitigkeit"
    },
    {
      "label": "IWF Fondsconsult GmbH",
      "value": "IWF Fondsconsult GmbH"
    },
    {
      "label": "J&T BANKA, a.s. Zweigniederlassung Deutschland",
      "value": "J&T BANKA, a.s. Zweigniederlassung Deutschland"
    },
    {
      "label": "J.P. Morgan SE",
      "value": "J.P. Morgan SE"
    },
    {
      "label": "JABEWA KVG GmbH & Co. KG",
      "value": "JABEWA KVG GmbH & Co. KG"
    },
    {
      "label": "Jakob Vermögensverwaltung GmbH",
      "value": "Jakob Vermögensverwaltung GmbH"
    },
    {
      "label": "JAMESTOWN US-Immobilien GmbH",
      "value": "JAMESTOWN US-Immobilien GmbH"
    },
    {
      "label": "Janitos Versicherung Aktiengesellschaft",
      "value": "Janitos Versicherung Aktiengesellschaft"
    },
    {
      "label": "JB Vermittlungskontor GmbH & Co. KG",
      "value": "JB Vermittlungskontor GmbH & Co. KG"
    },
    {
      "label": "JCB FINANCE S.A.S. Zweigniederlassung Deutschland",
      "value": "JCB FINANCE S.A.S. Zweigniederlassung Deutschland"
    },
    {
      "label": "Jefferies GmbH",
      "value": "Jefferies GmbH"
    },
    {
      "label": "Jens Ekkehard Krömer",
      "value": "Jens Ekkehard Krömer"
    },
    {
      "label": "JFD Bank AG",
      "value": "JFD Bank AG"
    },
    {
      "label": "JITpay Financial GmbH",
      "value": "JITpay Financial GmbH"
    },
    {
      "label": "JobRad Leasing GmbH",
      "value": "JobRad Leasing GmbH"
    },
    {
      "label": "Joh. Berenberg, Gossler & Co. KG",
      "value": "Joh. Berenberg, Gossler & Co. KG"
    },
    {
      "label": "Johannes Eder",
      "value": "Johannes Eder"
    },
    {
      "label": "JOHCM Funds (Ireland) Limited, Munich Branch",
      "value": "JOHCM Funds (Ireland) Limited, Munich Branch"
    },
    {
      "label": "JOHN DEERE BANK S.A. Zweigniederlassung Deutschland",
      "value": "JOHN DEERE BANK S.A. Zweigniederlassung Deutschland"
    },
    {
      "label": "Join Capital GmbH",
      "value": "Join Capital GmbH"
    },
    {
      "label": "Jolidis Leasing AG",
      "value": "Jolidis Leasing AG"
    },
    {
      "label": "Jordan Capital GmbH",
      "value": "Jordan Capital GmbH"
    },
    {
      "label": "JPMorgan Asset Management (Europe) S.à r.l. Frankfurt Branch",
      "value": "JPMorgan Asset Management (Europe) S.à r.l. Frankfurt Branch"
    },
    {
      "label": "JRC Capital Management Consultancy & Research GmbH",
      "value": "JRC Capital Management Consultancy & Research GmbH"
    },
    {
      "label": "JRS Finanzmandate GmbH",
      "value": "JRS Finanzmandate GmbH"
    },
    {
      "label": "July Fund Management GmbH",
      "value": "July Fund Management GmbH"
    },
    {
      "label": "June Fund 17 GmbH & Co. KG",
      "value": "June Fund 17 GmbH & Co. KG"
    },
    {
      "label": "Jung, DMS & Cie. GmbH, Zweigniederlassung Deutschland",
      "value": "Jung, DMS & Cie. GmbH, Zweigniederlassung Deutschland"
    },
    {
      "label": "Jupiter Asset Management International S.A., Niederlassung Deutschland",
      "value": "Jupiter Asset Management International S.A., Niederlassung Deutschland"
    },
    {
      "label": "Jyske Bank A/S, Filiale Hamburg",
      "value": "Jyske Bank A/S, Filiale Hamburg"
    },
    {
      "label": "Jäderberg & Cie. Investment Management GmbH",
      "value": "Jäderberg & Cie. Investment Management GmbH"
    },
    {
      "label": "Jürgen Schlotz Consulting",
      "value": "Jürgen Schlotz Consulting"
    },
    {
      "label": "K+P Kapitalverwaltungs GmbH",
      "value": "K+P Kapitalverwaltungs GmbH"
    },
    {
      "label": "Kaack Terminhandel Gesellschaft mit beschränkter Haftung",
      "value": "Kaack Terminhandel Gesellschaft mit beschränkter Haftung"
    },
    {
      "label": "Kairos Capital GmbH",
      "value": "Kairos Capital GmbH"
    },
    {
      "label": "KanAm Grund Institutional Kapitalverwaltungsgesellschaft mbH",
      "value": "KanAm Grund Institutional Kapitalverwaltungsgesellschaft mbH"
    },
    {
      "label": "KanAm Grund Kapitalverwaltungsgesellschaft mbH",
      "value": "KanAm Grund Kapitalverwaltungsgesellschaft mbH"
    },
    {
      "label": "KANON AG",
      "value": "KANON AG"
    },
    {
      "label": "Kapilendo Custodian AG",
      "value": "Kapilendo Custodian AG"
    },
    {
      "label": "Kapitalmanagement Lothar Diehl GmbH",
      "value": "Kapitalmanagement Lothar Diehl GmbH"
    },
    {
      "label": "Kartesia Management S.A. (German Branch)",
      "value": "Kartesia Management S.A. (German Branch)"
    },
    {
      "label": "Kasseler Sparkasse",
      "value": "Kasseler Sparkasse"
    },
    {
      "label": "KATAG AG Textileinkaufsverband",
      "value": "KATAG AG Textileinkaufsverband"
    },
    {
      "label": "Kazenmaier Fleetservice GmbH",
      "value": "Kazenmaier Fleetservice GmbH"
    },
    {
      "label": "KB - Vermögensverwaltung GmbH",
      "value": "KB - Vermögensverwaltung GmbH"
    },
    {
      "label": "KBC Bank NV, Niederlassung Deutschland",
      "value": "KBC Bank NV, Niederlassung Deutschland"
    },
    {
      "label": "KEB Hana Bank (D) Aktiengesellschaft",
      "value": "KEB Hana Bank (D) Aktiengesellschaft"
    },
    {
      "label": "KeLo-Leasing GmbH",
      "value": "KeLo-Leasing GmbH"
    },
    {
      "label": "Kepler Cheuvreux, Frankfurt branch",
      "value": "Kepler Cheuvreux, Frankfurt branch"
    },
    {
      "label": "Kerdos Investment-AG TGV",
      "value": "Kerdos Investment-AG TGV"
    },
    {
      "label": "Kersten Anlageberatung GmbH",
      "value": "Kersten Anlageberatung GmbH"
    },
    {
      "label": "Key Partners Capital GmbH",
      "value": "Key Partners Capital GmbH"
    },
    {
      "label": "KFB Leasfinanz GmbH",
      "value": "KFB Leasfinanz GmbH"
    },
    {
      "label": "KfW Capital GmbH & Co. KG",
      "value": "KfW Capital GmbH & Co. KG"
    },
    {
      "label": "KfW IPEX-Bank GmbH",
      "value": "KfW IPEX-Bank GmbH"
    },
    {
      "label": "KG CDL -Leasing GmbH & Co.",
      "value": "KG CDL -Leasing GmbH & Co."
    },
    {
      "label": "KGAL Investment Management GmbH & Co. KG",
      "value": "KGAL Investment Management GmbH & Co. KG"
    },
    {
      "label": "KIDRON Vermögensverwaltung GmbH",
      "value": "KIDRON Vermögensverwaltung GmbH"
    },
    {
      "label": "Kieler Rückversicherungsverein auf Gegenseitigkeit",
      "value": "Kieler Rückversicherungsverein auf Gegenseitigkeit"
    },
    {
      "label": "Kieler Volksbank eG",
      "value": "Kieler Volksbank eG"
    },
    {
      "label": "Kiesel Finance GmbH & Co. KG",
      "value": "Kiesel Finance GmbH & Co. KG"
    },
    {
      "label": "kineo finance GmbH",
      "value": "kineo finance GmbH"
    },
    {
      "label": "KINTO Deutschland GmbH",
      "value": "KINTO Deutschland GmbH"
    },
    {
      "label": "Kirix Vermögensverwaltung AG",
      "value": "Kirix Vermögensverwaltung AG"
    },
    {
      "label": "KKA Management GmbH",
      "value": "KKA Management GmbH"
    },
    {
      "label": "KKR Credit Advisors (Ireland), Frankfurt Branch",
      "value": "KKR Credit Advisors (Ireland), Frankfurt Branch"
    },
    {
      "label": "KKS Leasing GmbH & Co. KG",
      "value": "KKS Leasing GmbH & Co. KG"
    },
    {
      "label": "Klarna Bank AB, German Branch",
      "value": "Klarna Bank AB, German Branch"
    },
    {
      "label": "klarwert GmbH",
      "value": "klarwert GmbH"
    },
    {
      "label": "Klaus Kassner",
      "value": "Klaus Kassner"
    },
    {
      "label": "Klaus Löffler Vermögensverwaltung/Portfoliomanagement e.K.",
      "value": "Klaus Löffler Vermögensverwaltung/Portfoliomanagement e.K."
    },
    {
      "label": "Kleinschmidt  und Partner Vermögensverwaltung GmbH",
      "value": "Kleinschmidt  und Partner Vermögensverwaltung GmbH"
    },
    {
      "label": "KLG - Konzept-Leasing GmbH",
      "value": "KLG - Konzept-Leasing GmbH"
    },
    {
      "label": "Klingenberg & Cie. Investment KG",
      "value": "Klingenberg & Cie. Investment KG"
    },
    {
      "label": "KMS Leasing GmbH & Co. KG",
      "value": "KMS Leasing GmbH & Co. KG"
    },
    {
      "label": "Knoesel & Ronge Vermögensverwaltung GmbH & Co.KG",
      "value": "Knoesel & Ronge Vermögensverwaltung GmbH & Co.KG"
    },
    {
      "label": "Kommunalkredit Austria AG, Zweigstelle Deutschland",
      "value": "Kommunalkredit Austria AG, Zweigstelle Deutschland"
    },
    {
      "label": "Kompetenzcenter Risikosteuerung KC Risk AG",
      "value": "Kompetenzcenter Risikosteuerung KC Risk AG"
    },
    {
      "label": "Kontor Stöwer Asset Management GmbH",
      "value": "Kontor Stöwer Asset Management GmbH"
    },
    {
      "label": "Kontora Kapitalverwaltungs GmbH",
      "value": "Kontora Kapitalverwaltungs GmbH"
    },
    {
      "label": "Kornhaaß & Cie Vermögensverwaltung AG",
      "value": "Kornhaaß & Cie Vermögensverwaltung AG"
    },
    {
      "label": "Kozalla Vermögensverwaltung GmbH",
      "value": "Kozalla Vermögensverwaltung GmbH"
    },
    {
      "label": "KPS Factoring GmbH",
      "value": "KPS Factoring GmbH"
    },
    {
      "label": "KR Leasing GmbH",
      "value": "KR Leasing GmbH"
    },
    {
      "label": "Krankenunterstützungskasse Hannover",
      "value": "Krankenunterstützungskasse Hannover"
    },
    {
      "label": "KRAVAG-ALLGEMEINE Versicherungs-Aktiengesellschaft",
      "value": "KRAVAG-ALLGEMEINE Versicherungs-Aktiengesellschaft"
    },
    {
      "label": "KRAVAG-LOGISTIC Versicherungs-Aktiengesellschaft",
      "value": "KRAVAG-LOGISTIC Versicherungs-Aktiengesellschaft"
    },
    {
      "label": "KRAVAG-SACH Versicherung des Deutschen Kraftverkehrs Versicherungsverein auf Gegenseitigkeit",
      "value": "KRAVAG-SACH Versicherung des Deutschen Kraftverkehrs Versicherungsverein auf Gegenseitigkeit"
    },
    {
      "label": "Kreis- und Stadtsparkasse Erding-Dorfen",
      "value": "Kreis- und Stadtsparkasse Erding-Dorfen"
    },
    {
      "label": "Kreis- und Stadtsparkasse Kaufbeuren",
      "value": "Kreis- und Stadtsparkasse Kaufbeuren"
    },
    {
      "label": "Kreis- und Stadtsparkasse Unna-Kamen, Zweckverbandssparkasse des Kreises Unna, der Kreisstadt Unna, der Stadt Kamen, der Stadt Fröndenberg und der Gemeinde Holzwickede",
      "value": "Kreis- und Stadtsparkasse Unna-Kamen, Zweckverbandssparkasse des Kreises Unna, der Kreisstadt Unna, der Stadt Kamen, der Stadt Fröndenberg und der Gemeinde Holzwickede"
    },
    {
      "label": "Kreis- und Stadtsparkasse Wasserburg am Inn",
      "value": "Kreis- und Stadtsparkasse Wasserburg am Inn"
    },
    {
      "label": "Kreis-Sparkasse Northeim",
      "value": "Kreis-Sparkasse Northeim"
    },
    {
      "label": "Kreissparkasse Ahrweiler",
      "value": "Kreissparkasse Ahrweiler"
    },
    {
      "label": "Kreissparkasse Anhalt-Bitterfeld",
      "value": "Kreissparkasse Anhalt-Bitterfeld"
    },
    {
      "label": "Kreissparkasse Bautzen",
      "value": "Kreissparkasse Bautzen"
    },
    {
      "label": "Kreissparkasse Bersenbrück",
      "value": "Kreissparkasse Bersenbrück"
    },
    {
      "label": "Kreissparkasse Biberach",
      "value": "Kreissparkasse Biberach"
    },
    {
      "label": "Kreissparkasse Birkenfeld (Birkenfelder Landesbank)",
      "value": "Kreissparkasse Birkenfeld (Birkenfelder Landesbank)"
    },
    {
      "label": "Kreissparkasse Bitburg-Prüm",
      "value": "Kreissparkasse Bitburg-Prüm"
    },
    {
      "label": "Kreissparkasse Böblingen",
      "value": "Kreissparkasse Böblingen"
    },
    {
      "label": "Kreissparkasse Börde",
      "value": "Kreissparkasse Börde"
    },
    {
      "label": "Kreissparkasse Döbeln",
      "value": "Kreissparkasse Döbeln"
    },
    {
      "label": "Kreissparkasse Düsseldorf",
      "value": "Kreissparkasse Düsseldorf"
    },
    {
      "label": "Kreissparkasse Eichsfeld",
      "value": "Kreissparkasse Eichsfeld"
    },
    {
      "label": "Kreissparkasse Esslingen-Nürtingen",
      "value": "Kreissparkasse Esslingen-Nürtingen"
    },
    {
      "label": "Kreissparkasse Euskirchen",
      "value": "Kreissparkasse Euskirchen"
    },
    {
      "label": "Kreissparkasse Fallingbostel in Walsrode",
      "value": "Kreissparkasse Fallingbostel in Walsrode"
    },
    {
      "label": "Kreissparkasse Freudenstadt",
      "value": "Kreissparkasse Freudenstadt"
    },
    {
      "label": "Kreissparkasse Gelnhausen",
      "value": "Kreissparkasse Gelnhausen"
    },
    {
      "label": "Kreissparkasse Gotha",
      "value": "Kreissparkasse Gotha"
    },
    {
      "label": "Kreissparkasse Grafschaft Bentheim zu Nordhorn",
      "value": "Kreissparkasse Grafschaft Bentheim zu Nordhorn"
    },
    {
      "label": "Kreissparkasse Grafschaft Diepholz",
      "value": "Kreissparkasse Grafschaft Diepholz"
    },
    {
      "label": "Kreissparkasse Groß-Gerau",
      "value": "Kreissparkasse Groß-Gerau"
    },
    {
      "label": "Kreissparkasse Göppingen",
      "value": "Kreissparkasse Göppingen"
    },
    {
      "label": "Kreissparkasse Halle (Westf.)",
      "value": "Kreissparkasse Halle (Westf.)"
    },
    {
      "label": "Kreissparkasse Heidenheim",
      "value": "Kreissparkasse Heidenheim"
    },
    {
      "label": "Kreissparkasse Heilbronn",
      "value": "Kreissparkasse Heilbronn"
    },
    {
      "label": "Kreissparkasse Heinsberg - Zweckverbandssparkasse des Kreises Heinsberg und der Stadt Erkelenz -",
      "value": "Kreissparkasse Heinsberg - Zweckverbandssparkasse des Kreises Heinsberg und der Stadt Erkelenz -"
    },
    {
      "label": "Kreissparkasse Herzogtum Lauenburg",
      "value": "Kreissparkasse Herzogtum Lauenburg"
    },
    {
      "label": "Kreissparkasse Hildburghausen",
      "value": "Kreissparkasse Hildburghausen"
    },
    {
      "label": "Kreissparkasse Kelheim",
      "value": "Kreissparkasse Kelheim"
    },
    {
      "label": "Kreissparkasse Kusel",
      "value": "Kreissparkasse Kusel"
    },
    {
      "label": "Kreissparkasse Köln",
      "value": "Kreissparkasse Köln"
    },
    {
      "label": "Kreissparkasse Limburg",
      "value": "Kreissparkasse Limburg"
    },
    {
      "label": "Kreissparkasse Ludwigsburg",
      "value": "Kreissparkasse Ludwigsburg"
    },
    {
      "label": "Kreissparkasse Mayen",
      "value": "Kreissparkasse Mayen"
    },
    {
      "label": "Kreissparkasse Melle",
      "value": "Kreissparkasse Melle"
    },
    {
      "label": "Kreissparkasse Miesbach-Tegernsee",
      "value": "Kreissparkasse Miesbach-Tegernsee"
    },
    {
      "label": "Kreissparkasse Märkisch-Oderland",
      "value": "Kreissparkasse Märkisch-Oderland"
    },
    {
      "label": "Kreissparkasse München Starnberg Ebersberg",
      "value": "Kreissparkasse München Starnberg Ebersberg"
    },
    {
      "label": "Kreissparkasse Nordhausen",
      "value": "Kreissparkasse Nordhausen"
    },
    {
      "label": "Kreissparkasse Ostalb",
      "value": "Kreissparkasse Ostalb"
    },
    {
      "label": "Kreissparkasse Ravensburg",
      "value": "Kreissparkasse Ravensburg"
    },
    {
      "label": "Kreissparkasse Reutlingen",
      "value": "Kreissparkasse Reutlingen"
    },
    {
      "label": "Kreissparkasse Rhein-Hunsrück",
      "value": "Kreissparkasse Rhein-Hunsrück"
    },
    {
      "label": "Kreissparkasse Rottweil",
      "value": "Kreissparkasse Rottweil"
    },
    {
      "label": "Kreissparkasse Saale-Orla",
      "value": "Kreissparkasse Saale-Orla"
    },
    {
      "label": "Kreissparkasse Saalfeld-Rudolstadt",
      "value": "Kreissparkasse Saalfeld-Rudolstadt"
    },
    {
      "label": "Kreissparkasse Saarlouis",
      "value": "Kreissparkasse Saarlouis"
    },
    {
      "label": "Kreissparkasse Saarpfalz",
      "value": "Kreissparkasse Saarpfalz"
    },
    {
      "label": "Kreissparkasse Schlüchtern",
      "value": "Kreissparkasse Schlüchtern"
    },
    {
      "label": "Kreissparkasse Schwalm-Eder",
      "value": "Kreissparkasse Schwalm-Eder"
    },
    {
      "label": "Kreissparkasse Soltau",
      "value": "Kreissparkasse Soltau"
    },
    {
      "label": "Kreissparkasse St. Wendel",
      "value": "Kreissparkasse St. Wendel"
    },
    {
      "label": "Kreissparkasse Stade",
      "value": "Kreissparkasse Stade"
    },
    {
      "label": "Kreissparkasse Steinfurt - Zweckverbandssparkasse des Kreises Steinfurt und der Städte und Gemeinden Altenberge, Greven, Hörstel, Hopsten, Horstmar, Ibbenbüren, Ladbergen, Laer, Lienen, Lotte, Metelen, Mettingen, Neuenkirchen, Nordwalde, Recke, Saerbeck, Steinfurt, Tecklenburg, Westerkappeln und Wettringen",
      "value": "Kreissparkasse Steinfurt - Zweckverbandssparkasse des Kreises Steinfurt und der Städte und Gemeinden Altenberge, Greven, Hörstel, Hopsten, Horstmar, Ibbenbüren, Ladbergen, Laer, Lienen, Lotte, Metelen, Mettingen, Neuenkirchen, Nordwalde, Recke, Saerbeck, Steinfurt, Tecklenburg, Westerkappeln und Wettringen"
    },
    {
      "label": "Kreissparkasse Stendal",
      "value": "Kreissparkasse Stendal"
    },
    {
      "label": "Kreissparkasse Syke",
      "value": "Kreissparkasse Syke"
    },
    {
      "label": "Kreissparkasse Traunstein-Trostberg",
      "value": "Kreissparkasse Traunstein-Trostberg"
    },
    {
      "label": "Kreissparkasse Tuttlingen",
      "value": "Kreissparkasse Tuttlingen"
    },
    {
      "label": "Kreissparkasse Tübingen",
      "value": "Kreissparkasse Tübingen"
    },
    {
      "label": "Kreissparkasse Verden",
      "value": "Kreissparkasse Verden"
    },
    {
      "label": "Kreissparkasse Vulkaneifel",
      "value": "Kreissparkasse Vulkaneifel"
    },
    {
      "label": "Kreissparkasse Waiblingen",
      "value": "Kreissparkasse Waiblingen"
    },
    {
      "label": "Kreissparkasse Weilburg",
      "value": "Kreissparkasse Weilburg"
    },
    {
      "label": "Kreissparkasse Wiedenbrück Zweckverbandssparkasse des Kreises Gütersloh und der Stadt Rheda-Wiedenbrück",
      "value": "Kreissparkasse Wiedenbrück Zweckverbandssparkasse des Kreises Gütersloh und der Stadt Rheda-Wiedenbrück"
    },
    {
      "label": "Kroos Vermögensverwaltung AG",
      "value": "Kroos Vermögensverwaltung AG"
    },
    {
      "label": "KRU-Sachwerte I Kapitalverwaltungsgesellschaft GmbH & Co. KG",
      "value": "KRU-Sachwerte I Kapitalverwaltungsgesellschaft GmbH & Co. KG"
    },
    {
      "label": "Kruse & Bock Vermögensverwaltung GmbH",
      "value": "Kruse & Bock Vermögensverwaltung GmbH"
    },
    {
      "label": "KS Versicherungs-Aktiengesellschaft",
      "value": "KS Versicherungs-Aktiengesellschaft"
    },
    {
      "label": "KSV Management GmbH",
      "value": "KSV Management GmbH"
    },
    {
      "label": "KSW Vermögensverwaltung AG",
      "value": "KSW Vermögensverwaltung AG"
    },
    {
      "label": "KT Bank AG",
      "value": "KT Bank AG"
    },
    {
      "label": "Kultur - Denkmal - Stiftung",
      "value": "Kultur - Denkmal - Stiftung"
    },
    {
      "label": "Kurhessische Landbank eG",
      "value": "Kurhessische Landbank eG"
    },
    {
      "label": "KWK Leasing und Factoring GmbH",
      "value": "KWK Leasing und Factoring GmbH"
    },
    {
      "label": "Kyffhäusersparkasse Artern-Sondershausen",
      "value": "Kyffhäusersparkasse Artern-Sondershausen"
    },
    {
      "label": "KÖLNER ABRECHNUNGSDIENST Heiartz & Vogel oHG",
      "value": "KÖLNER ABRECHNUNGSDIENST Heiartz & Vogel oHG"
    },
    {
      "label": "Kölner Pensionskasse Versicherungsverein auf Gegenseitigkeit i.L.",
      "value": "Kölner Pensionskasse Versicherungsverein auf Gegenseitigkeit i.L."
    },
    {
      "label": "KölnVorsorge-Sterbeversicherung VVaG",
      "value": "KölnVorsorge-Sterbeversicherung VVaG"
    },
    {
      "label": "König Leasing GmbH",
      "value": "König Leasing GmbH"
    },
    {
      "label": "Kühnauer MMK Asset Management GmbH",
      "value": "Kühnauer MMK Asset Management GmbH"
    },
    {
      "label": "KümpersFinanz Verwaltungs GmbH",
      "value": "KümpersFinanz Verwaltungs GmbH"
    },
    {
      "label": "L.V.G. Labor-Verrechnungs-Gesellschaft mbH",
      "value": "L.V.G. Labor-Verrechnungs-Gesellschaft mbH"
    },
    {
      "label": "La Famiglia GmbH",
      "value": "La Famiglia GmbH"
    },
    {
      "label": "La Française Systematic Asset Management GmbH",
      "value": "La Française Systematic Asset Management GmbH"
    },
    {
      "label": "Lacuna Investment-AG TGV",
      "value": "Lacuna Investment-AG TGV"
    },
    {
      "label": "Lacuna Vermögen GmbH",
      "value": "Lacuna Vermögen GmbH"
    },
    {
      "label": "LAIC AIF KVG GmbH",
      "value": "LAIC AIF KVG GmbH"
    },
    {
      "label": "LAIC Vermögensverwaltung GmbH",
      "value": "LAIC Vermögensverwaltung GmbH"
    },
    {
      "label": "LAK Leasing für Altenheime und Krankenhäuser GmbH",
      "value": "LAK Leasing für Altenheime und Krankenhäuser GmbH"
    },
    {
      "label": "Lampe Asset Management GmbH",
      "value": "Lampe Asset Management GmbH"
    },
    {
      "label": "Lampe Investment Management GmbH",
      "value": "Lampe Investment Management GmbH"
    },
    {
      "label": "Landbank Horlofftal eingetragene Genossenschaft",
      "value": "Landbank Horlofftal eingetragene Genossenschaft"
    },
    {
      "label": "Landesbank Baden-Württemberg",
      "value": "Landesbank Baden-Württemberg"
    },
    {
      "label": "Landesbank Berlin AG",
      "value": "Landesbank Berlin AG"
    },
    {
      "label": "Landesbank Hessen-Thüringen Girozentrale",
      "value": "Landesbank Hessen-Thüringen Girozentrale"
    },
    {
      "label": "Landesbank Saar",
      "value": "Landesbank Saar"
    },
    {
      "label": "Landesbausparkasse Hessen-Thüringen Abteilung der Landesbank Hessen-Thüringen Girozentrale",
      "value": "Landesbausparkasse Hessen-Thüringen Abteilung der Landesbank Hessen-Thüringen Girozentrale"
    },
    {
      "label": "Landeskrankenhilfe V.V.a.G.",
      "value": "Landeskrankenhilfe V.V.a.G."
    },
    {
      "label": "Landeskreditbank Baden-Württemberg - Förderbank -",
      "value": "Landeskreditbank Baden-Württemberg - Förderbank -"
    },
    {
      "label": "Landeslebenshilfe V.V.a.G.",
      "value": "Landeslebenshilfe V.V.a.G."
    },
    {
      "label": "Landesschadenhilfe Versicherung VaG",
      "value": "Landesschadenhilfe Versicherung VaG"
    },
    {
      "label": "Landessparkasse zu Oldenburg",
      "value": "Landessparkasse zu Oldenburg"
    },
    {
      "label": "Landgesellschaft Schleswig-Holstein mbH",
      "value": "Landgesellschaft Schleswig-Holstein mbH"
    },
    {
      "label": "Landschaftliche Brandkasse Hannover",
      "value": "Landschaftliche Brandkasse Hannover"
    },
    {
      "label": "Landwirtschaftliche Rentenbank",
      "value": "Landwirtschaftliche Rentenbank"
    },
    {
      "label": "Lang & Hink FinanzPartner GmbH",
      "value": "Lang & Hink FinanzPartner GmbH"
    },
    {
      "label": "Lang & Schwarz TradeCenter AG & Co. KG",
      "value": "Lang & Schwarz TradeCenter AG & Co. KG"
    },
    {
      "label": "Lange Assets & Consulting GmbH",
      "value": "Lange Assets & Consulting GmbH"
    },
    {
      "label": "Largamus Financial GmbH",
      "value": "Largamus Financial GmbH"
    },
    {
      "label": "LaRoute GmbH",
      "value": "LaRoute GmbH"
    },
    {
      "label": "LaSalle Investment Management Kapitalverwaltungsgesellschaft mbH",
      "value": "LaSalle Investment Management Kapitalverwaltungsgesellschaft mbH"
    },
    {
      "label": "Lauderdale GmbH & Co. KG",
      "value": "Lauderdale GmbH & Co. KG"
    },
    {
      "label": "LAUREUS AG PRIVAT FINANZ",
      "value": "LAUREUS AG PRIVAT FINANZ"
    },
    {
      "label": "LAVEGO AG",
      "value": "LAVEGO AG"
    },
    {
      "label": "Lazard & Co. GmbH",
      "value": "Lazard & Co. GmbH"
    },
    {
      "label": "Lazard Asset Management (Deutschland) GmbH",
      "value": "Lazard Asset Management (Deutschland) GmbH"
    },
    {
      "label": "LBBW Asset Management Investmentgesellschaft mbH",
      "value": "LBBW Asset Management Investmentgesellschaft mbH"
    },
    {
      "label": "LBBW Immobilien GmbH",
      "value": "LBBW Immobilien GmbH"
    },
    {
      "label": "LBBW Immobilien Kommunalentwicklung GmbH",
      "value": "LBBW Immobilien Kommunalentwicklung GmbH"
    },
    {
      "label": "LBN-Versicherungsverein a.G. (VVaG)",
      "value": "LBN-Versicherungsverein a.G. (VVaG)"
    },
    {
      "label": "LBS Bausparkasse Schleswig-Holstein-Hamburg AG",
      "value": "LBS Bausparkasse Schleswig-Holstein-Hamburg AG"
    },
    {
      "label": "LBS Bayerische Landesbausparkasse",
      "value": "LBS Bayerische Landesbausparkasse"
    },
    {
      "label": "LBS Landesbausparkasse Saar",
      "value": "LBS Landesbausparkasse Saar"
    },
    {
      "label": "LBS Landesbausparkasse Südwest",
      "value": "LBS Landesbausparkasse Südwest"
    },
    {
      "label": "LBS Norddeutsche Landesbausparkasse Berlin-Hannover",
      "value": "LBS Norddeutsche Landesbausparkasse Berlin-Hannover"
    },
    {
      "label": "LBS Ostdeutsche Landesbausparkasse Aktiengesellschaft",
      "value": "LBS Ostdeutsche Landesbausparkasse Aktiengesellschaft"
    },
    {
      "label": "LBS Westdeutsche Landesbausparkasse",
      "value": "LBS Westdeutsche Landesbausparkasse"
    },
    {
      "label": "LEA Brilliant Co-Invest GmbH & Co. KG",
      "value": "LEA Brilliant Co-Invest GmbH & Co. KG"
    },
    {
      "label": "LEA Mittelstandspartner Annex GmbH & Co. KG",
      "value": "LEA Mittelstandspartner Annex GmbH & Co. KG"
    },
    {
      "label": "LEA Mittelstandspartner GmbH & Co. KG",
      "value": "LEA Mittelstandspartner GmbH & Co. KG"
    },
    {
      "label": "LEA Mittelstandspartner II GmbH & Co. KG",
      "value": "LEA Mittelstandspartner II GmbH & Co. KG"
    },
    {
      "label": "LEA Mittelstandspartner II-A GmbH & Co. KG",
      "value": "LEA Mittelstandspartner II-A GmbH & Co. KG"
    },
    {
      "label": "LEA Venturepartner Annex Feeder GmbH & Co. KG",
      "value": "LEA Venturepartner Annex Feeder GmbH & Co. KG"
    },
    {
      "label": "LEA Venturepartner Annex GmbH & Co. KG",
      "value": "LEA Venturepartner Annex GmbH & Co. KG"
    },
    {
      "label": "LEA Venturepartner GmbH & Co. KG",
      "value": "LEA Venturepartner GmbH & Co. KG"
    },
    {
      "label": "LEA Venturepartner II Feeder GmbH & Co. KG",
      "value": "LEA Venturepartner II Feeder GmbH & Co. KG"
    },
    {
      "label": "LEA Venturepartner II GmbH & Co. KG",
      "value": "LEA Venturepartner II GmbH & Co. KG"
    },
    {
      "label": "LeanVal Asset Management AG",
      "value": "LeanVal Asset Management AG"
    },
    {
      "label": "LeasAG Leasing Aktiengesellschaft",
      "value": "LeasAG Leasing Aktiengesellschaft"
    },
    {
      "label": "LEASE.CONSULT GmbH",
      "value": "LEASE.CONSULT GmbH"
    },
    {
      "label": "LeaseForce AG",
      "value": "LeaseForce AG"
    },
    {
      "label": "LeasePlan Deutschland GmbH",
      "value": "LeasePlan Deutschland GmbH"
    },
    {
      "label": "LeaseTec GmbH",
      "value": "LeaseTec GmbH"
    },
    {
      "label": "Leasing Verwaltungsgesellschaft Waltersdorf mbH",
      "value": "Leasing Verwaltungsgesellschaft Waltersdorf mbH"
    },
    {
      "label": "Leasys S.p.A. Zweigstelle Deutschland",
      "value": "Leasys S.p.A. Zweigstelle Deutschland"
    },
    {
      "label": "Lebensversicherung von 1871 auf Gegenseitigkeit München",
      "value": "Lebensversicherung von 1871 auf Gegenseitigkeit München"
    },
    {
      "label": "LEHNER INVESTMENTS MANAGEMENT GmbH",
      "value": "LEHNER INVESTMENTS MANAGEMENT GmbH"
    },
    {
      "label": "Lehrer-Feuerversicherungsverein a.G. für Schleswig-Holstein, Hamburg und Mecklenburg-Vorpommern",
      "value": "Lehrer-Feuerversicherungsverein a.G. für Schleswig-Holstein, Hamburg und Mecklenburg-Vorpommern"
    },
    {
      "label": "Leipziger Volksbank eG",
      "value": "Leipziger Volksbank eG"
    },
    {
      "label": "Lennertz & Co. GmbH",
      "value": "Lennertz & Co. GmbH"
    },
    {
      "label": "Lennertz & Co. Private Equity GmbH",
      "value": "Lennertz & Co. Private Equity GmbH"
    },
    {
      "label": "LEO GP GmbH",
      "value": "LEO GP GmbH"
    },
    {
      "label": "Leonteq Securities (Europe) GmbH",
      "value": "Leonteq Securities (Europe) GmbH"
    },
    {
      "label": "levoBank eG",
      "value": "levoBank eG"
    },
    {
      "label": "LfA Förderbank Bayern",
      "value": "LfA Förderbank Bayern"
    },
    {
      "label": "LFE European Asset Management S.à.r.l., German Branch.",
      "value": "LFE European Asset Management S.à.r.l., German Branch."
    },
    {
      "label": "LfS Leasinggesellschaft für Sozialwirtschaft mbH",
      "value": "LfS Leasinggesellschaft für Sozialwirtschaft mbH"
    },
    {
      "label": "LGH Leasinggesellschaft für den Handel mbH",
      "value": "LGH Leasinggesellschaft für den Handel mbH"
    },
    {
      "label": "LGIM Managers (Europe) Limited, Germany",
      "value": "LGIM Managers (Europe) Limited, Germany"
    },
    {
      "label": "LGT Bank AG, Zweigniederlassung Deutschland",
      "value": "LGT Bank AG, Zweigniederlassung Deutschland"
    },
    {
      "label": "LHI Capital Management GmbH",
      "value": "LHI Capital Management GmbH"
    },
    {
      "label": "LHI Kapitalverwaltungsgesellschaft mbH",
      "value": "LHI Kapitalverwaltungsgesellschaft mbH"
    },
    {
      "label": "LHI Leasing GmbH",
      "value": "LHI Leasing GmbH"
    },
    {
      "label": "Liberta Partners GmbH",
      "value": "Liberta Partners GmbH"
    },
    {
      "label": "Liberty Mutual Insurance Europe SE Direktion für Deutschland",
      "value": "Liberty Mutual Insurance Europe SE Direktion für Deutschland"
    },
    {
      "label": "Life Science Inkubator GmbH",
      "value": "Life Science Inkubator GmbH"
    },
    {
      "label": "Lifestyle Protection AG",
      "value": "Lifestyle Protection AG"
    },
    {
      "label": "Lifestyle Protection Lebensversicherung AG",
      "value": "Lifestyle Protection Lebensversicherung AG"
    },
    {
      "label": "LIGA Bank eG",
      "value": "LIGA Bank eG"
    },
    {
      "label": "LIGA Krankenversicherung katholischer Priester Versicherungsverein auf Gegenseitigkeit Regensburg",
      "value": "LIGA Krankenversicherung katholischer Priester Versicherungsverein auf Gegenseitigkeit Regensburg"
    },
    {
      "label": "LIL Leasing AG",
      "value": "LIL Leasing AG"
    },
    {
      "label": "Lime Ventures Management GmbH",
      "value": "Lime Ventures Management GmbH"
    },
    {
      "label": "Linde Leasing GmbH",
      "value": "Linde Leasing GmbH"
    },
    {
      "label": "Lingohr & Partner Asset Management GmbH",
      "value": "Lingohr & Partner Asset Management GmbH"
    },
    {
      "label": "Linus Capital Management GmbH",
      "value": "Linus Capital Management GmbH"
    },
    {
      "label": "Lippische Landesbrandversicherung AG",
      "value": "Lippische Landesbrandversicherung AG"
    },
    {
      "label": "Lippische Pensionsfonds AG",
      "value": "Lippische Pensionsfonds AG"
    },
    {
      "label": "LIQID Asset Management GmbH",
      "value": "LIQID Asset Management GmbH"
    },
    {
      "label": "LIQUIDE 24 AG",
      "value": "LIQUIDE 24 AG"
    },
    {
      "label": "Lloyd's Insurance Company S.A., Niederlassung für Deutschland",
      "value": "Lloyd's Insurance Company S.A., Niederlassung für Deutschland"
    },
    {
      "label": "Lloyds Bank Corporate Markets Wertpapierhandelsbank GmbH",
      "value": "Lloyds Bank Corporate Markets Wertpapierhandelsbank GmbH"
    },
    {
      "label": "Lloyds Bank GmbH",
      "value": "Lloyds Bank GmbH"
    },
    {
      "label": "Lloyds Bank plc Niederlassung Berlin",
      "value": "Lloyds Bank plc Niederlassung Berlin"
    },
    {
      "label": "LOGPAY Financial Services GmbH",
      "value": "LOGPAY Financial Services GmbH"
    },
    {
      "label": "Lohnfortzahlungskasse Aurich VVaG",
      "value": "Lohnfortzahlungskasse Aurich VVaG"
    },
    {
      "label": "Lohnfortzahlungskasse Leer VVaG",
      "value": "Lohnfortzahlungskasse Leer VVaG"
    },
    {
      "label": "Lombard Odier Funds (Europe) S.A. - German Branch",
      "value": "Lombard Odier Funds (Europe) S.A. - German Branch"
    },
    {
      "label": "LOOM Impact AG",
      "value": "LOOM Impact AG"
    },
    {
      "label": "Lothar K. Eschmann GmbH",
      "value": "Lothar K. Eschmann GmbH"
    },
    {
      "label": "LOYS AG",
      "value": "LOYS AG"
    },
    {
      "label": "LP Capital Management GmbH",
      "value": "LP Capital Management GmbH"
    },
    {
      "label": "LS Leasing & Service GmbH",
      "value": "LS Leasing & Service GmbH"
    },
    {
      "label": "LSB Leasing-Service- und Beteiligungs-GmbH",
      "value": "LSB Leasing-Service- und Beteiligungs-GmbH"
    },
    {
      "label": "Lucura Versicherungs AG",
      "value": "Lucura Versicherungs AG"
    },
    {
      "label": "Lufthansa AirPlus Servicekarten GmbH",
      "value": "Lufthansa AirPlus Servicekarten GmbH"
    },
    {
      "label": "Luko Insurance AG",
      "value": "Luko Insurance AG"
    },
    {
      "label": "LUNADIS GmbH + Co. KG",
      "value": "LUNADIS GmbH + Co. KG"
    },
    {
      "label": "Lunis Vermögensmanagement AG",
      "value": "Lunis Vermögensmanagement AG"
    },
    {
      "label": "Lupus alpha Asset Management AG",
      "value": "Lupus alpha Asset Management AG"
    },
    {
      "label": "Lupus alpha Investment GmbH",
      "value": "Lupus alpha Investment GmbH"
    },
    {
      "label": "LVG Lindhorst Verwaltungs GmbH",
      "value": "LVG Lindhorst Verwaltungs GmbH"
    },
    {
      "label": "LVM Krankenversicherungs-AG",
      "value": "LVM Krankenversicherungs-AG"
    },
    {
      "label": "LVM Landwirtschaftlicher Versicherungsverein Münster a.G.",
      "value": "LVM Landwirtschaftlicher Versicherungsverein Münster a.G."
    },
    {
      "label": "LVM Lebensversicherungs-AG",
      "value": "LVM Lebensversicherungs-AG"
    },
    {
      "label": "LVM Pensionsfonds-AG",
      "value": "LVM Pensionsfonds-AG"
    },
    {
      "label": "Lynx B.V. Germany Branch",
      "value": "Lynx B.V. Germany Branch"
    },
    {
      "label": "LÜBECKER BAUVEREIN eingetragene Genossenschaft",
      "value": "LÜBECKER BAUVEREIN eingetragene Genossenschaft"
    },
    {
      "label": "Lüdke Vermögenstreuhand GmbH",
      "value": "Lüdke Vermögenstreuhand GmbH"
    },
    {
      "label": "m & m Dental-Factoring GmbH & Co. KG",
      "value": "m & m Dental-Factoring GmbH & Co. KG"
    },
    {
      "label": "M Cap Finance Mittelstand GmbH & Co. KG",
      "value": "M Cap Finance Mittelstand GmbH & Co. KG"
    },
    {
      "label": "M Cap Finance Mittelstand III GmbH & Co. KG",
      "value": "M Cap Finance Mittelstand III GmbH & Co. KG"
    },
    {
      "label": "M.I.A. - Management für Industrie und Anlagen Leasing Aktiengesellschaft",
      "value": "M.I.A. - Management für Industrie und Anlagen Leasing Aktiengesellschaft"
    },
    {
      "label": "M.M.Warburg & CO (AG & Co.) Kommanditgesellschaft auf Aktien",
      "value": "M.M.Warburg & CO (AG & Co.) Kommanditgesellschaft auf Aktien"
    },
    {
      "label": "M.M.Warburg & CO Hypothekenbank Aktiengesellschaft",
      "value": "M.M.Warburg & CO Hypothekenbank Aktiengesellschaft"
    },
    {
      "label": "m4 invest GmbH",
      "value": "m4 invest GmbH"
    },
    {
      "label": "MA Transworld GmbH",
      "value": "MA Transworld GmbH"
    },
    {
      "label": "Mack + Weise GmbH Vermögensverwaltung",
      "value": "Mack + Weise GmbH Vermögensverwaltung"
    },
    {
      "label": "Macquarie Asset Management Europe S.à r.l., Niederlassung Deutschland",
      "value": "Macquarie Asset Management Europe S.à r.l., Niederlassung Deutschland"
    },
    {
      "label": "Macquarie Bank Europe Designated Activity Company, Frankfurt Branch",
      "value": "Macquarie Bank Europe Designated Activity Company, Frankfurt Branch"
    },
    {
      "label": "Macquarie Capital France S.A., Niederlassung Deutschland",
      "value": "Macquarie Capital France S.A., Niederlassung Deutschland"
    },
    {
      "label": "MADAUS Capital Partners GmbH",
      "value": "MADAUS Capital Partners GmbH"
    },
    {
      "label": "Mademann & Kollegen GmbH",
      "value": "Mademann & Kollegen GmbH"
    },
    {
      "label": "Magnetic Management GmbH",
      "value": "Magnetic Management GmbH"
    },
    {
      "label": "MAGRAL AG Financial Brokers",
      "value": "MAGRAL AG Financial Brokers"
    },
    {
      "label": "Maguar Capital GmbH & Co. KG",
      "value": "Maguar Capital GmbH & Co. KG"
    },
    {
      "label": "Maguar Capital II GmbH & Co. KG",
      "value": "Maguar Capital II GmbH & Co. KG"
    },
    {
      "label": "Maguar Co-Invest I GmbH & Co. KG",
      "value": "Maguar Co-Invest I GmbH & Co. KG"
    },
    {
      "label": "MAIESTAS Vermögensmanagement AG",
      "value": "MAIESTAS Vermögensmanagement AG"
    },
    {
      "label": "mailo Versicherung AG",
      "value": "mailo Versicherung AG"
    },
    {
      "label": "MAINFIRST Affiliated Fund Managers (Deutschland) GmbH",
      "value": "MAINFIRST Affiliated Fund Managers (Deutschland) GmbH"
    },
    {
      "label": "Mainova Aktiengesellschaft",
      "value": "Mainova Aktiengesellschaft"
    },
    {
      "label": "MainSky Asset Management AG",
      "value": "MainSky Asset Management AG"
    },
    {
      "label": "Mainzer Volksbank e.G.",
      "value": "Mainzer Volksbank e.G."
    },
    {
      "label": "Maneris AG",
      "value": "Maneris AG"
    },
    {
      "label": "Mannheimer Versicherung Aktiengesellschaft",
      "value": "Mannheimer Versicherung Aktiengesellschaft"
    },
    {
      "label": "Manulife Investment Management (Ireland) Limited, German Branch",
      "value": "Manulife Investment Management (Ireland) Limited, German Branch"
    },
    {
      "label": "Mapfre Re, Compania de Reaseguros, S.A. Munich Branch",
      "value": "Mapfre Re, Compania de Reaseguros, S.A. Munich Branch"
    },
    {
      "label": "Marcard & Schaefer Vermögensverwaltung GmbH",
      "value": "Marcard & Schaefer Vermögensverwaltung GmbH"
    },
    {
      "label": "MARCARD, STEIN & CO AG",
      "value": "MARCARD, STEIN & CO AG"
    },
    {
      "label": "Marex Spectron Europe Limited, Zweigniederlassung Deutschland",
      "value": "Marex Spectron Europe Limited, Zweigniederlassung Deutschland"
    },
    {
      "label": "MARIANNE VON WEIZÄCKER Stiftung Integrationshilfe für ehemals Suchtkrank e. V.",
      "value": "MARIANNE VON WEIZÄCKER Stiftung Integrationshilfe für ehemals Suchtkrank e. V."
    },
    {
      "label": "Maritime & Merchant Bank ASA representative office",
      "value": "Maritime & Merchant Bank ASA representative office"
    },
    {
      "label": "Maritime Selection 2 GmbH & Co. KG",
      "value": "Maritime Selection 2 GmbH & Co. KG"
    },
    {
      "label": "MARKANT European Payment Services GmbH",
      "value": "MARKANT European Payment Services GmbH"
    },
    {
      "label": "Markel Insurance SE",
      "value": "Markel Insurance SE"
    },
    {
      "label": "MarketAxess NL B.V., Zweigniederlassung Deutschland",
      "value": "MarketAxess NL B.V., Zweigniederlassung Deutschland"
    },
    {
      "label": "Marketing-Gesellschaft Deutscher Augenoptiker mbH",
      "value": "Marketing-Gesellschaft Deutscher Augenoptiker mbH"
    },
    {
      "label": "Marks & Maxin Vermögensberatungs- und -verwaltungsgesellschaft mbH",
      "value": "Marks & Maxin Vermögensberatungs- und -verwaltungsgesellschaft mbH"
    },
    {
      "label": "marondo capital GmbH",
      "value": "marondo capital GmbH"
    },
    {
      "label": "Martagon Family Office AG",
      "value": "Martagon Family Office AG"
    },
    {
      "label": "MAS Deutsche Beteiligungsgesellschaft mbH",
      "value": "MAS Deutsche Beteiligungsgesellschaft mbH"
    },
    {
      "label": "Matherm Wollwarenfabrik und Handelsgesellschaft mbH",
      "value": "Matherm Wollwarenfabrik und Handelsgesellschaft mbH"
    },
    {
      "label": "Matterwave Ventures Management GmbH",
      "value": "Matterwave Ventures Management GmbH"
    },
    {
      "label": "Maturus Finance GmbH",
      "value": "Maturus Finance GmbH"
    },
    {
      "label": "MAV Vermögensverwaltung GmbH",
      "value": "MAV Vermögensverwaltung GmbH"
    },
    {
      "label": "Max Heinr.Sutor OHG",
      "value": "Max Heinr.Sutor OHG"
    },
    {
      "label": "MaxAlpha Asset Management GmbH",
      "value": "MaxAlpha Asset Management GmbH"
    },
    {
      "label": "Mayfair Vermögensverwaltungs SE",
      "value": "Mayfair Vermögensverwaltungs SE"
    },
    {
      "label": "Mayr Investment Managers GmbH",
      "value": "Mayr Investment Managers GmbH"
    },
    {
      "label": "mb.for GmbH",
      "value": "mb.for GmbH"
    },
    {
      "label": "MBG - MBH Mittelständische Beteiligungsgesellschaft mit beschränkter Haftung",
      "value": "MBG - MBH Mittelständische Beteiligungsgesellschaft mit beschränkter Haftung"
    },
    {
      "label": "MC Immobilienverwaltungs GmbH",
      "value": "MC Immobilienverwaltungs GmbH"
    },
    {
      "label": "MCC Medical CareCapital GmbH",
      "value": "MCC Medical CareCapital GmbH"
    },
    {
      "label": "MCE Bank GmbH",
      "value": "MCE Bank GmbH"
    },
    {
      "label": "MEAG MUNICH ERGO Kapitalanlagegesellschaft mbH",
      "value": "MEAG MUNICH ERGO Kapitalanlagegesellschaft mbH"
    },
    {
      "label": "Mecklenburgische Krankenversicherungs-Aktiengesellschaft",
      "value": "Mecklenburgische Krankenversicherungs-Aktiengesellschaft"
    },
    {
      "label": "Mecklenburgische Lebensversicherungs-Aktiengesellschaft",
      "value": "Mecklenburgische Lebensversicherungs-Aktiengesellschaft"
    },
    {
      "label": "Mecklenburgische Versicherungs-Gesellschaft auf Gegenseitigkeit",
      "value": "Mecklenburgische Versicherungs-Gesellschaft auf Gegenseitigkeit"
    },
    {
      "label": "MEDAS factoring GmbH",
      "value": "MEDAS factoring GmbH"
    },
    {
      "label": "MEDIAN INVEST Aktiengesellschaft",
      "value": "MEDIAN INVEST Aktiengesellschaft"
    },
    {
      "label": "Medical Strategy GmbH",
      "value": "Medical Strategy GmbH"
    },
    {
      "label": "Medical Valley Ventures Management GmbH",
      "value": "Medical Valley Ventures Management GmbH"
    },
    {
      "label": "MEDIEN-VERSICHERUNG a.G. KARLSRUHE vorm. Buchgewerbe-Feuerversicherung, gegr. 1899",
      "value": "MEDIEN-VERSICHERUNG a.G. KARLSRUHE vorm. Buchgewerbe-Feuerversicherung, gegr. 1899"
    },
    {
      "label": "Mediolanum International Life Designated Activity Company- Niederlassung München",
      "value": "Mediolanum International Life Designated Activity Company- Niederlassung München"
    },
    {
      "label": "mediserv Bank GmbH",
      "value": "mediserv Bank GmbH"
    },
    {
      "label": "MedTech Entrepreneurs Management GmbH",
      "value": "MedTech Entrepreneurs Management GmbH"
    },
    {
      "label": "MEDZENTRUM Kapitalverwaltungsgesellschaft mbH",
      "value": "MEDZENTRUM Kapitalverwaltungsgesellschaft mbH"
    },
    {
      "label": "Meeder & Seifer Vermögensverwaltung GmbH",
      "value": "Meeder & Seifer Vermögensverwaltung GmbH"
    },
    {
      "label": "meine Volksbank Raiffeisenbank eG",
      "value": "meine Volksbank Raiffeisenbank eG"
    },
    {
      "label": "MELES Insurance A/S Niederlassung Deutschland",
      "value": "MELES Insurance A/S Niederlassung Deutschland"
    },
    {
      "label": "Mendener Bank eG",
      "value": "Mendener Bank eG"
    },
    {
      "label": "Mennewisch & Co. Capital GmbH",
      "value": "Mennewisch & Co. Capital GmbH"
    },
    {
      "label": "Mensch Cologne Capital GmbH",
      "value": "Mensch Cologne Capital GmbH"
    },
    {
      "label": "Mentzel Autovermietung GmbH",
      "value": "Mentzel Autovermietung GmbH"
    },
    {
      "label": "MER-Pensionskasse VVaG",
      "value": "MER-Pensionskasse VVaG"
    },
    {
      "label": "Merca Leasing GmbH & Co. KG",
      "value": "Merca Leasing GmbH & Co. KG"
    },
    {
      "label": "Merca Vendor Finance GmbH",
      "value": "Merca Vendor Finance GmbH"
    },
    {
      "label": "Mercedes-Benz Bank AG",
      "value": "Mercedes-Benz Bank AG"
    },
    {
      "label": "Mercedes-Benz Leasing Deutschland GmbH",
      "value": "Mercedes-Benz Leasing Deutschland GmbH"
    },
    {
      "label": "Mercedes-Benz Leasing GmbH",
      "value": "Mercedes-Benz Leasing GmbH"
    },
    {
      "label": "Mercedes-Benz Versicherung AG",
      "value": "Mercedes-Benz Versicherung AG"
    },
    {
      "label": "Mercer Global Investments Europe Limited, Niederlassung Deutschland",
      "value": "Mercer Global Investments Europe Limited, Niederlassung Deutschland"
    },
    {
      "label": "Mercer Pensionsfonds AG",
      "value": "Mercer Pensionsfonds AG"
    },
    {
      "label": "Merck Finck, a Quintet Private Bank (Europe) S.A. branch",
      "value": "Merck Finck, a Quintet Private Bank (Europe) S.A. branch"
    },
    {
      "label": "mercurion Asset Management GmbH",
      "value": "mercurion Asset Management GmbH"
    },
    {
      "label": "Meridiem Finanz GmbH",
      "value": "Meridiem Finanz GmbH"
    },
    {
      "label": "Meritum Capital Managers GmbH",
      "value": "Meritum Capital Managers GmbH"
    },
    {
      "label": "Merkur Freizeit Leasing GmbH",
      "value": "Merkur Freizeit Leasing GmbH"
    },
    {
      "label": "MERKUR PRIVATBANK KGaA",
      "value": "MERKUR PRIVATBANK KGaA"
    },
    {
      "label": "Metafina GmbH",
      "value": "Metafina GmbH"
    },
    {
      "label": "METRO Re AG",
      "value": "METRO Re AG"
    },
    {
      "label": "METZLER ASSET MANAGEMENT GMBH",
      "value": "METZLER ASSET MANAGEMENT GMBH"
    },
    {
      "label": "Metzler Pension Management GmbH",
      "value": "Metzler Pension Management GmbH"
    },
    {
      "label": "Metzler Pensionsfonds AG",
      "value": "Metzler Pensionsfonds AG"
    },
    {
      "label": "Metzler Sozialpartner Pensionsfonds AG",
      "value": "Metzler Sozialpartner Pensionsfonds AG"
    },
    {
      "label": "Metzler Treuhand e.V.",
      "value": "Metzler Treuhand e.V."
    },
    {
      "label": "Metzler Trust e. V.",
      "value": "Metzler Trust e. V."
    },
    {
      "label": "MEX Asset Management GmbH",
      "value": "MEX Asset Management GmbH"
    },
    {
      "label": "MF-Capital GmbH",
      "value": "MF-Capital GmbH"
    },
    {
      "label": "MFI Asset Management GmbH",
      "value": "MFI Asset Management GmbH"
    },
    {
      "label": "MFS Investment Management Company (Lux) S.à r.l. Zweigniederlassung Frankfurt",
      "value": "MFS Investment Management Company (Lux) S.à r.l. Zweigniederlassung Frankfurt"
    },
    {
      "label": "MFT Mittelstands-Fonds Thüringen GmbH & Co. KG",
      "value": "MFT Mittelstands-Fonds Thüringen GmbH & Co. KG"
    },
    {
      "label": "MGF S.A.S. Zweigniederlassung Deutschland",
      "value": "MGF S.A.S. Zweigniederlassung Deutschland"
    },
    {
      "label": "MGW Gesellschaft für Geld- und Wertpapiervermittlung mbH",
      "value": "MGW Gesellschaft für Geld- und Wertpapiervermittlung mbH"
    },
    {
      "label": "Michael Pintarelli Finanzdienstleistungen AG",
      "value": "Michael Pintarelli Finanzdienstleistungen AG"
    },
    {
      "label": "Michael Scheidgen, private finance e.K.",
      "value": "Michael Scheidgen, private finance e.K."
    },
    {
      "label": "Middle East Bank, Munich Branch",
      "value": "Middle East Bank, Munich Branch"
    },
    {
      "label": "Mieter- und Bauverein Karlsruhe eG",
      "value": "Mieter- und Bauverein Karlsruhe eG"
    },
    {
      "label": "MIG Verwaltungs AG",
      "value": "MIG Verwaltungs AG"
    },
    {
      "label": "Miller Leasing Miete Gesellschaft mit beschränkter Haftung",
      "value": "Miller Leasing Miete Gesellschaft mit beschränkter Haftung"
    },
    {
      "label": "milon financial services GmbH",
      "value": "milon financial services GmbH"
    },
    {
      "label": "Minerva Versicherungs-Aktiengesellschaft",
      "value": "Minerva Versicherungs-Aktiengesellschaft"
    },
    {
      "label": "Minveo AG",
      "value": "Minveo AG"
    },
    {
      "label": "MIO-Partners (EU) GmbH",
      "value": "MIO-Partners (EU) GmbH"
    },
    {
      "label": "Misr Bank - Europe GmbH",
      "value": "Misr Bank - Europe GmbH"
    },
    {
      "label": "MITGAS Mitteldeutsche Gasversorgung GmbH",
      "value": "MITGAS Mitteldeutsche Gasversorgung GmbH"
    },
    {
      "label": "Mittelbrandenburgische Sparkasse in Potsdam",
      "value": "Mittelbrandenburgische Sparkasse in Potsdam"
    },
    {
      "label": "Mizuho Bank, Ltd. Filiale Düsseldorf",
      "value": "Mizuho Bank, Ltd. Filiale Düsseldorf"
    },
    {
      "label": "Mizuho Securities Europe GmbH",
      "value": "Mizuho Securities Europe GmbH"
    },
    {
      "label": "MKB Mittelstandskreditbank Aktiengesellschaft",
      "value": "MKB Mittelstandskreditbank Aktiengesellschaft"
    },
    {
      "label": "ML Leasing München GmbH",
      "value": "ML Leasing München GmbH"
    },
    {
      "label": "MLF Mercator-Leasing GmbH & Co. Finanz-KG",
      "value": "MLF Mercator-Leasing GmbH & Co. Finanz-KG"
    },
    {
      "label": "MLL Autovermietung und -Leasing GmbH",
      "value": "MLL Autovermietung und -Leasing GmbH"
    },
    {
      "label": "MLP Banking AG",
      "value": "MLP Banking AG"
    },
    {
      "label": "MM Venture Management GmbH",
      "value": "MM Venture Management GmbH"
    },
    {
      "label": "MMV Bank GmbH",
      "value": "MMV Bank GmbH"
    },
    {
      "label": "MMV Leasing Gesellschaft mit beschränkter Haftung",
      "value": "MMV Leasing Gesellschaft mit beschränkter Haftung"
    },
    {
      "label": "MMV-Mobilien Verwaltungs- und Vermietungsgesellschaft mbH",
      "value": "MMV-Mobilien Verwaltungs- und Vermietungsgesellschaft mbH"
    },
    {
      "label": "MNB Nick Beteiligungen GmbH",
      "value": "MNB Nick Beteiligungen GmbH"
    },
    {
      "label": "Mobile Business Engine GmbH",
      "value": "Mobile Business Engine GmbH"
    },
    {
      "label": "Mobility Concept GmbH",
      "value": "Mobility Concept GmbH"
    },
    {
      "label": "MobilityFund Management GmbH",
      "value": "MobilityFund Management GmbH"
    },
    {
      "label": "Mogk GmbH & Co. KG",
      "value": "Mogk GmbH & Co. KG"
    },
    {
      "label": "Moltrecht & Partner Asset Management GmbH",
      "value": "Moltrecht & Partner Asset Management GmbH"
    },
    {
      "label": "mondial kapitalverwaltungsgesellschaft mbH",
      "value": "mondial kapitalverwaltungsgesellschaft mbH"
    },
    {
      "label": "Monega Kapitalanlagegesellschaft mbH",
      "value": "Monega Kapitalanlagegesellschaft mbH"
    },
    {
      "label": "Money Exchange Deutschland GmbH",
      "value": "Money Exchange Deutschland GmbH"
    },
    {
      "label": "Mons-Tabor Immobilien Dritte GmbH & Co. KG",
      "value": "Mons-Tabor Immobilien Dritte GmbH & Co. KG"
    },
    {
      "label": "Mons-Tabor Immobilien Fünfte GmbH & Co. KG",
      "value": "Mons-Tabor Immobilien Fünfte GmbH & Co. KG"
    },
    {
      "label": "Mons-Tabor Immobilien GmbH & Co. KG",
      "value": "Mons-Tabor Immobilien GmbH & Co. KG"
    },
    {
      "label": "Mons-Tabor Immobilien Sechste GmbH & Co. KG",
      "value": "Mons-Tabor Immobilien Sechste GmbH & Co. KG"
    },
    {
      "label": "Mons-Tabor Immobilien Vierte GmbH & Co. KG",
      "value": "Mons-Tabor Immobilien Vierte GmbH & Co. KG"
    },
    {
      "label": "Mons-Tabor Immobilien Zweite GmbH & Co. KG",
      "value": "Mons-Tabor Immobilien Zweite GmbH & Co. KG"
    },
    {
      "label": "Mons-Tabor Wohnungsunternehmen GmbH & Co. KG",
      "value": "Mons-Tabor Wohnungsunternehmen GmbH & Co. KG"
    },
    {
      "label": "Montan Factoring GmbH",
      "value": "Montan Factoring GmbH"
    },
    {
      "label": "Montold Asset Management GmbH",
      "value": "Montold Asset Management GmbH"
    },
    {
      "label": "Monument Assurance Luxembourg S.A. - Niederlassung für Deutschland",
      "value": "Monument Assurance Luxembourg S.A. - Niederlassung für Deutschland"
    },
    {
      "label": "Monuta Versicherungen Zweigniederlassung Deutschland der Monuta Verzekeringen N.V.",
      "value": "Monuta Versicherungen Zweigniederlassung Deutschland der Monuta Verzekeringen N.V."
    },
    {
      "label": "Morgan Stanley Bank Aktiengesellschaft",
      "value": "Morgan Stanley Bank Aktiengesellschaft"
    },
    {
      "label": "Morgan Stanley Europe SE",
      "value": "Morgan Stanley Europe SE"
    },
    {
      "label": "Morgenstern Miet + Leasing GmbH",
      "value": "Morgenstern Miet + Leasing GmbH"
    },
    {
      "label": "Morphais Fund Management GmbH",
      "value": "Morphais Fund Management GmbH"
    },
    {
      "label": "Moss GmbH",
      "value": "Moss GmbH"
    },
    {
      "label": "Motu Ventures Management GmbH",
      "value": "Motu Ventures Management GmbH"
    },
    {
      "label": "Mount Street Portfolio Advisers GmbH",
      "value": "Mount Street Portfolio Advisers GmbH"
    },
    {
      "label": "Moventum S.C.A.  Zweigniederlassung Deutschland",
      "value": "Moventum S.C.A.  Zweigniederlassung Deutschland"
    },
    {
      "label": "moveta r.V.k.V.",
      "value": "moveta r.V.k.V."
    },
    {
      "label": "MPPM GmbH",
      "value": "MPPM GmbH"
    },
    {
      "label": "MS Finance Support GmbH",
      "value": "MS Finance Support GmbH"
    },
    {
      "label": "MS Leasing GmbH",
      "value": "MS Leasing GmbH"
    },
    {
      "label": "MSIG Insurance Europe AG",
      "value": "MSIG Insurance Europe AG"
    },
    {
      "label": "MSIM Fund Management (Ireland) Limited Frankfurt Branch",
      "value": "MSIM Fund Management (Ireland) Limited Frankfurt Branch"
    },
    {
      "label": "MT-PE Equity GmbH & Co. KG",
      "value": "MT-PE Equity GmbH & Co. KG"
    },
    {
      "label": "MUFG Bank (Europe) N.V. Germany Branch",
      "value": "MUFG Bank (Europe) N.V. Germany Branch"
    },
    {
      "label": "Multiple I GmbH Co. KG",
      "value": "Multiple I GmbH Co. KG"
    },
    {
      "label": "Munich Re Capital Markets GmbH",
      "value": "Munich Re Capital Markets GmbH"
    },
    {
      "label": "Munich Re Investment Partners GmbH",
      "value": "Munich Re Investment Partners GmbH"
    },
    {
      "label": "Murphy&Spitz Nachhaltige Vermögensverwaltung AG",
      "value": "Murphy&Spitz Nachhaltige Vermögensverwaltung AG"
    },
    {
      "label": "Muzinich & Co. (Ireland) Limited, Niederlassung Deutschland",
      "value": "Muzinich & Co. (Ireland) Limited, Niederlassung Deutschland"
    },
    {
      "label": "MVP III Management GmbH",
      "value": "MVP III Management GmbH"
    },
    {
      "label": "MVP Management GmbH",
      "value": "MVP Management GmbH"
    },
    {
      "label": "mwb fairtrade Wertpapierhandelsbank AG",
      "value": "mwb fairtrade Wertpapierhandelsbank AG"
    },
    {
      "label": "MWG - Wohnungsgenossenschaft eG Magdeburg",
      "value": "MWG - Wohnungsgenossenschaft eG Magdeburg"
    },
    {
      "label": "MX Fund II GmbH & Co. KG",
      "value": "MX Fund II GmbH & Co. KG"
    },
    {
      "label": "myLife Lebensversicherung AG",
      "value": "myLife Lebensversicherung AG"
    },
    {
      "label": "Märkische Bank eG",
      "value": "Märkische Bank eG"
    },
    {
      "label": "Müllerei-Pensionskasse Versicherungsverein a.G. (MPK)",
      "value": "Müllerei-Pensionskasse Versicherungsverein a.G. (MPK)"
    },
    {
      "label": "Münchener Hypothekenbank eG",
      "value": "Münchener Hypothekenbank eG"
    },
    {
      "label": "Münchener Rück Versorgungskasse",
      "value": "Münchener Rück Versorgungskasse"
    },
    {
      "label": "Münchener Rückversicherungs-Gesellschaft Aktiengesellschaft in München",
      "value": "Münchener Rückversicherungs-Gesellschaft Aktiengesellschaft in München"
    },
    {
      "label": "MÜNCHENER VEREIN Allgemeine Versicherungs-AG",
      "value": "MÜNCHENER VEREIN Allgemeine Versicherungs-AG"
    },
    {
      "label": "MÜNCHENER VEREIN Krankenversicherung a.G.",
      "value": "MÜNCHENER VEREIN Krankenversicherung a.G."
    },
    {
      "label": "MÜNCHENER VEREIN Lebensversicherung AG",
      "value": "MÜNCHENER VEREIN Lebensversicherung AG"
    },
    {
      "label": "Münchner Bank eG",
      "value": "Münchner Bank eG"
    },
    {
      "label": "Münster Stegmaier Rombach Family Office GmbH",
      "value": "Münster Stegmaier Rombach Family Office GmbH"
    },
    {
      "label": "Münsterländische Bank Thie & Co. KG",
      "value": "Münsterländische Bank Thie & Co. KG"
    },
    {
      "label": "Müritz-Sparkasse",
      "value": "Müritz-Sparkasse"
    },
    {
      "label": "N.C. Leasing GmbH",
      "value": "N.C. Leasing GmbH"
    },
    {
      "label": "N.I.C.O. Vermögensberatungs- und Verwaltungs GmbH",
      "value": "N.I.C.O. Vermögensberatungs- und Verwaltungs GmbH"
    },
    {
      "label": "N26 Bank GmbH",
      "value": "N26 Bank GmbH"
    },
    {
      "label": "Nassauische Heimstätte Wohnungs- und Entwicklungsgesellschaft mbH",
      "value": "Nassauische Heimstätte Wohnungs- und Entwicklungsgesellschaft mbH"
    },
    {
      "label": "Nassauische Sparkasse",
      "value": "Nassauische Sparkasse"
    },
    {
      "label": "National Bank of Pakistan Filiale Frankfurt am Main",
      "value": "National Bank of Pakistan Filiale Frankfurt am Main"
    },
    {
      "label": "National Westminster Bank Plc Niederlassung Deutschland",
      "value": "National Westminster Bank Plc Niederlassung Deutschland"
    },
    {
      "label": "National-Bank Aktiengesellschaft",
      "value": "National-Bank Aktiengesellschaft"
    },
    {
      "label": "NATIONAL-BANK Vermögenstreuhand GmbH",
      "value": "NATIONAL-BANK Vermögenstreuhand GmbH"
    },
    {
      "label": "Natixis Investment Managers International, Zweigniederlassung Deutschland",
      "value": "Natixis Investment Managers International, Zweigniederlassung Deutschland"
    },
    {
      "label": "Natixis Investment Managers S.A., Zweigniederlassung Deutschland",
      "value": "Natixis Investment Managers S.A., Zweigniederlassung Deutschland"
    },
    {
      "label": "NATIXIS Pfandbriefbank AG",
      "value": "NATIXIS Pfandbriefbank AG"
    },
    {
      "label": "NATIXIS Zweigniederlassung Deutschland",
      "value": "NATIXIS Zweigniederlassung Deutschland"
    },
    {
      "label": "NatWest Bank Europe GmbH",
      "value": "NatWest Bank Europe GmbH"
    },
    {
      "label": "NatWest Markets N.V. Niederlassung Deutschland",
      "value": "NatWest Markets N.V. Niederlassung Deutschland"
    },
    {
      "label": "NatWest Markets Plc Niederlassung Frankfurt",
      "value": "NatWest Markets Plc Niederlassung Frankfurt"
    },
    {
      "label": "NC Management GmbH",
      "value": "NC Management GmbH"
    },
    {
      "label": "Neodigital Versicherung AG",
      "value": "Neodigital Versicherung AG"
    },
    {
      "label": "neoteq ventures management GmbH",
      "value": "neoteq ventures management GmbH"
    },
    {
      "label": "NEPA Vermögensverwaltung GmbH",
      "value": "NEPA Vermögensverwaltung GmbH"
    },
    {
      "label": "Nephrit Grundstücksverwaltungsgesellschaft mbH & Co. Vermietungs KG",
      "value": "Nephrit Grundstücksverwaltungsgesellschaft mbH & Co. Vermietungs KG"
    },
    {
      "label": "Nestlé Pensionsfonds AG",
      "value": "Nestlé Pensionsfonds AG"
    },
    {
      "label": "NESTLÉ PENSIONSKASSE",
      "value": "NESTLÉ PENSIONSKASSE"
    },
    {
      "label": "NESTLÉ RÜCKDECKUNGSKASSE",
      "value": "NESTLÉ RÜCKDECKUNGSKASSE"
    },
    {
      "label": "NetBid Finance GmbH",
      "value": "NetBid Finance GmbH"
    },
    {
      "label": "Neuberger Berman Asset Management Ireland Limited German Branch",
      "value": "Neuberger Berman Asset Management Ireland Limited German Branch"
    },
    {
      "label": "Neuberger Berman Europe Limited Repräsentanz Deutschland",
      "value": "Neuberger Berman Europe Limited Repräsentanz Deutschland"
    },
    {
      "label": "neue leben Lebensversicherung Aktiengesellschaft",
      "value": "neue leben Lebensversicherung Aktiengesellschaft"
    },
    {
      "label": "neue leben Pensionskasse Aktiengesellschaft",
      "value": "neue leben Pensionskasse Aktiengesellschaft"
    },
    {
      "label": "neue leben Unfallversicherung Aktiengesellschaft",
      "value": "neue leben Unfallversicherung Aktiengesellschaft"
    },
    {
      "label": "Neue Rechtsschutz-Versicherungsgesellschaft Aktiengesellschaft",
      "value": "Neue Rechtsschutz-Versicherungsgesellschaft Aktiengesellschaft"
    },
    {
      "label": "Neuendorfer Brand-Bau-Gilde",
      "value": "Neuendorfer Brand-Bau-Gilde"
    },
    {
      "label": "Newline Europe Versicherung AG",
      "value": "Newline Europe Versicherung AG"
    },
    {
      "label": "nexible Versicherung AG",
      "value": "nexible Versicherung AG"
    },
    {
      "label": "NEXT COMMERCE ACCELERATOR GmbH",
      "value": "NEXT COMMERCE ACCELERATOR GmbH"
    },
    {
      "label": "Next Logistics Accelerator GmbH",
      "value": "Next Logistics Accelerator GmbH"
    },
    {
      "label": "NFS Hamburger Vermögen GmbH",
      "value": "NFS Hamburger Vermögen GmbH"
    },
    {
      "label": "NFS Netfonds Financial Service GmbH",
      "value": "NFS Netfonds Financial Service GmbH"
    },
    {
      "label": "NH EuVECA KV GmbH",
      "value": "NH EuVECA KV GmbH"
    },
    {
      "label": "NIBC BANK N.V. Zweigniederlassung Frankfurt am Main",
      "value": "NIBC BANK N.V. Zweigniederlassung Frankfurt am Main"
    },
    {
      "label": "Niederrheinische Sparkasse RheinLippe",
      "value": "Niederrheinische Sparkasse RheinLippe"
    },
    {
      "label": "Niedersächsische Bürgschaftsbank (NBB) Gesellschaft mit beschränkter Haftung",
      "value": "Niedersächsische Bürgschaftsbank (NBB) Gesellschaft mit beschränkter Haftung"
    },
    {
      "label": "Niedersächsische Landgesellschaft mbH",
      "value": "Niedersächsische Landgesellschaft mbH"
    },
    {
      "label": "Nikko Asset Management Luxembourg S.A. (German Branch)",
      "value": "Nikko Asset Management Luxembourg S.A. (German Branch)"
    },
    {
      "label": "Niklas Berliner Honorarberater GmbH",
      "value": "Niklas Berliner Honorarberater GmbH"
    },
    {
      "label": "Ninety One Luxembourg S.A. - German Branch",
      "value": "Ninety One Luxembourg S.A. - German Branch"
    },
    {
      "label": "NIV Leasing GmbH",
      "value": "NIV Leasing GmbH"
    },
    {
      "label": "NIXDORF Impact Movement Management GmbH",
      "value": "NIXDORF Impact Movement Management GmbH"
    },
    {
      "label": "NLA Komplementär GmbH",
      "value": "NLA Komplementär GmbH"
    },
    {
      "label": "NMA Venture Capital GmbH",
      "value": "NMA Venture Capital GmbH"
    },
    {
      "label": "NN Investment Partners B.V., German Branch",
      "value": "NN Investment Partners B.V., German Branch"
    },
    {
      "label": "NOAH Unternehmensgruppe GmbH",
      "value": "NOAH Unternehmensgruppe GmbH"
    },
    {
      "label": "Nomura Asset Management Europe KVG mbH",
      "value": "Nomura Asset Management Europe KVG mbH"
    },
    {
      "label": "Nomura Financial Products Europe GmbH",
      "value": "Nomura Financial Products Europe GmbH"
    },
    {
      "label": "Norbert Mann Neutrale Vermögensberatung GmbH",
      "value": "Norbert Mann Neutrale Vermögensberatung GmbH"
    },
    {
      "label": "NORD Holding Unternehmensbeteiligungsgesellschaft mit beschränkter Haftung",
      "value": "NORD Holding Unternehmensbeteiligungsgesellschaft mit beschränkter Haftung"
    },
    {
      "label": "Nord Leasing GmbH",
      "value": "Nord Leasing GmbH"
    },
    {
      "label": "Nord-Ostsee Sparkasse",
      "value": "Nord-Ostsee Sparkasse"
    },
    {
      "label": "NORD/LB Leasing GmbH",
      "value": "NORD/LB Leasing GmbH"
    },
    {
      "label": "Norddeutsche Landesbank - Girozentrale -",
      "value": "Norddeutsche Landesbank - Girozentrale -"
    },
    {
      "label": "Norddeutsches Apotheken-Rechenzentrum eingetragener Verein",
      "value": "Norddeutsches Apotheken-Rechenzentrum eingetragener Verein"
    },
    {
      "label": "Nordea Investment Funds S.A. German Branch",
      "value": "Nordea Investment Funds S.A. German Branch"
    },
    {
      "label": "Nordea Investment Management AB, German Branch",
      "value": "Nordea Investment Management AB, German Branch"
    },
    {
      "label": "Nordhemmer Versicherungsverein auf Gegenseitigkeit",
      "value": "Nordhemmer Versicherungsverein auf Gegenseitigkeit"
    },
    {
      "label": "nordIX AG",
      "value": "nordIX AG"
    },
    {
      "label": "nordostsee.leasing GmbH & Co. KG",
      "value": "nordostsee.leasing GmbH & Co. KG"
    },
    {
      "label": "nordostsee.leasing GmbH & Co. Leasingfonds KG",
      "value": "nordostsee.leasing GmbH & Co. Leasingfonds KG"
    },
    {
      "label": "Nordthüringer Volksbank eG",
      "value": "Nordthüringer Volksbank eG"
    },
    {
      "label": "Nordtreuhand GmbH",
      "value": "Nordtreuhand GmbH"
    },
    {
      "label": "Nordwest Factoring und Service GmbH",
      "value": "Nordwest Factoring und Service GmbH"
    },
    {
      "label": "NORDWEST Handel AG",
      "value": "NORDWEST Handel AG"
    },
    {
      "label": "norisbank GmbH",
      "value": "norisbank GmbH"
    },
    {
      "label": "North Channel Bank GmbH & Co. KG",
      "value": "North Channel Bank GmbH & Co. KG"
    },
    {
      "label": "Notarversicherungsverein a.G.",
      "value": "Notarversicherungsverein a.G."
    },
    {
      "label": "Notgemeinschaft Zeche Hugo VVaG",
      "value": "Notgemeinschaft Zeche Hugo VVaG"
    },
    {
      "label": "Notos Invest GmbH",
      "value": "Notos Invest GmbH"
    },
    {
      "label": "Novalease GmbH",
      "value": "Novalease GmbH"
    },
    {
      "label": "NOVALNET AG",
      "value": "NOVALNET AG"
    },
    {
      "label": "NOVENTI HealthCare GmbH",
      "value": "NOVENTI HealthCare GmbH"
    },
    {
      "label": "NOVETHOS Family Office GmbH",
      "value": "NOVETHOS Family Office GmbH"
    },
    {
      "label": "NOVIS Versicherungsgesellschaft Niederlassung Deutschland der NOVIS Insurance Company, NOVIS Versicherungsgesellschaft",
      "value": "NOVIS Versicherungsgesellschaft Niederlassung Deutschland der NOVIS Insurance Company, NOVIS Versicherungsgesellschaft"
    },
    {
      "label": "Novum Capital Management GmbH & Co. KG",
      "value": "Novum Capital Management GmbH & Co. KG"
    },
    {
      "label": "Noweda eG, Apothekergenossenschaft",
      "value": "Noweda eG, Apothekergenossenschaft"
    },
    {
      "label": "Nowinta Vermögensverwaltung GmbH",
      "value": "Nowinta Vermögensverwaltung GmbH"
    },
    {
      "label": "NRW.BANK",
      "value": "NRW.BANK"
    },
    {
      "label": "nuntios Vermögensverwaltung GmbH",
      "value": "nuntios Vermögensverwaltung GmbH"
    },
    {
      "label": "Nuveen Asset Management Europe S.à.r.l., Germany",
      "value": "Nuveen Asset Management Europe S.à.r.l., Germany"
    },
    {
      "label": "NV-Versicherungen VVaG",
      "value": "NV-Versicherungen VVaG"
    },
    {
      "label": "NÜRNBERGER Allgemeine Versicherungs-Aktiengesellschaft",
      "value": "NÜRNBERGER Allgemeine Versicherungs-Aktiengesellschaft"
    },
    {
      "label": "NÜRNBERGER Asset Management GmbH",
      "value": "NÜRNBERGER Asset Management GmbH"
    },
    {
      "label": "NÜRNBERGER BEAMTEN ALLGEMEINE VERSICHERUNG AKTIENGESELLSCHAFT",
      "value": "NÜRNBERGER BEAMTEN ALLGEMEINE VERSICHERUNG AKTIENGESELLSCHAFT"
    },
    {
      "label": "Nürnberger Beamten Lebensversicherung Aktiengesellschaft",
      "value": "Nürnberger Beamten Lebensversicherung Aktiengesellschaft"
    },
    {
      "label": "NÜRNBERGER Krankenversicherung Aktiengesellschaft",
      "value": "NÜRNBERGER Krankenversicherung Aktiengesellschaft"
    },
    {
      "label": "Nürnberger Leasing GmbH",
      "value": "Nürnberger Leasing GmbH"
    },
    {
      "label": "NÜRNBERGER Lebensversicherung Aktiengesellschaft",
      "value": "NÜRNBERGER Lebensversicherung Aktiengesellschaft"
    },
    {
      "label": "NÜRNBERGER Pensionsfonds Aktiengesellschaft",
      "value": "NÜRNBERGER Pensionsfonds Aktiengesellschaft"
    },
    {
      "label": "NÜRNBERGER Pensionskasse Aktiengesellschaft",
      "value": "NÜRNBERGER Pensionskasse Aktiengesellschaft"
    },
    {
      "label": "O. K. Leasing AG",
      "value": "O. K. Leasing AG"
    },
    {
      "label": "Oakley Capital GmbH",
      "value": "Oakley Capital GmbH"
    },
    {
      "label": "Oberbank AG, Niederlassung Deutschland",
      "value": "Oberbank AG, Niederlassung Deutschland"
    },
    {
      "label": "Oberbank Leasing GmbH Bayern",
      "value": "Oberbank Leasing GmbH Bayern"
    },
    {
      "label": "Oberbanscheidt & Cie. Vermögensverwaltungsgesellschaft mbH",
      "value": "Oberbanscheidt & Cie. Vermögensverwaltungsgesellschaft mbH"
    },
    {
      "label": "Oberösterreichische Versicherung AG",
      "value": "Oberösterreichische Versicherung AG"
    },
    {
      "label": "OBI Heimwerker- und Freizeitbedarf Handelsgesellschaft mbH & Co.KG",
      "value": "OBI Heimwerker- und Freizeitbedarf Handelsgesellschaft mbH & Co.KG"
    },
    {
      "label": "ODDO BHF Asset Management GmbH",
      "value": "ODDO BHF Asset Management GmbH"
    },
    {
      "label": "ODDO BHF Corporates & Markets AG",
      "value": "ODDO BHF Corporates & Markets AG"
    },
    {
      "label": "ODDO BHF SE",
      "value": "ODDO BHF SE"
    },
    {
      "label": "ODDO BHF Trust GmbH",
      "value": "ODDO BHF Trust GmbH"
    },
    {
      "label": "Odewald KMU Coinvest GmbH & Co. KG",
      "value": "Odewald KMU Coinvest GmbH & Co. KG"
    },
    {
      "label": "Odewald KMU GmbH & Co. Beteiligungsgesellschaft für Vermögensanlagen KG",
      "value": "Odewald KMU GmbH & Co. Beteiligungsgesellschaft für Vermögensanlagen KG"
    },
    {
      "label": "ODEWALD KMU II GmbH & Co. Beteiligungsgesellschaft für Vermögensanlagen KG",
      "value": "ODEWALD KMU II GmbH & Co. Beteiligungsgesellschaft für Vermögensanlagen KG"
    },
    {
      "label": "ODEWALD KMU III Gesellschaft für Beteiligungen mbH",
      "value": "ODEWALD KMU III Gesellschaft für Beteiligungen mbH"
    },
    {
      "label": "Odörfer & Brandner Vermögensmanagement KG",
      "value": "Odörfer & Brandner Vermögensmanagement KG"
    },
    {
      "label": "ofg Vermögensverwaltung GmbH",
      "value": "ofg Vermögensverwaltung GmbH"
    },
    {
      "label": "OKV - Ostdeutsche Kommunalversicherung auf Gegenseitigkeit",
      "value": "OKV - Ostdeutsche Kommunalversicherung auf Gegenseitigkeit"
    },
    {
      "label": "OL Objekt-Leasing GmbH & Co. KG",
      "value": "OL Objekt-Leasing GmbH & Co. KG"
    },
    {
      "label": "Oldenburger Volksbank eG",
      "value": "Oldenburger Volksbank eG"
    },
    {
      "label": "Oldenburgische Landesbank Aktiengesellschaft",
      "value": "Oldenburgische Landesbank Aktiengesellschaft"
    },
    {
      "label": "One Blue Ocean Trust GmbH",
      "value": "One Blue Ocean Trust GmbH"
    },
    {
      "label": "Onstmettinger Bank eG",
      "value": "Onstmettinger Bank eG"
    },
    {
      "label": "Opalenburg Vermögensverwaltung GmbH",
      "value": "Opalenburg Vermögensverwaltung GmbH"
    },
    {
      "label": "Opel Bank SA, Niederlassung Deutschland",
      "value": "Opel Bank SA, Niederlassung Deutschland"
    },
    {
      "label": "opemo Aktiengesellschaft",
      "value": "opemo Aktiengesellschaft"
    },
    {
      "label": "opta data factoring GmbH",
      "value": "opta data factoring GmbH"
    },
    {
      "label": "opta data Finance GmbH",
      "value": "opta data Finance GmbH"
    },
    {
      "label": "Optica Abrechnungszentrum Dr. Güldener GmbH",
      "value": "Optica Abrechnungszentrum Dr. Güldener GmbH"
    },
    {
      "label": "Optinova Asset Management GmbH",
      "value": "Optinova Asset Management GmbH"
    },
    {
      "label": "Optinova Investmentaktiengesellschaft mit Teilgesellschaftsvermögen",
      "value": "Optinova Investmentaktiengesellschaft mit Teilgesellschaftsvermögen"
    },
    {
      "label": "Orbian Financial Services II Limited Zweigniederlassung Deutschland",
      "value": "Orbian Financial Services II Limited Zweigniederlassung Deutschland"
    },
    {
      "label": "Orbian Financial Services IX LLC Zweigniederlassung Deutschland",
      "value": "Orbian Financial Services IX LLC Zweigniederlassung Deutschland"
    },
    {
      "label": "Orbian Financial Services VI Limited Zweigniederlassung Deutschland",
      "value": "Orbian Financial Services VI Limited Zweigniederlassung Deutschland"
    },
    {
      "label": "Orbian Financial Services XI Limited Zweigniederlassung Deutschland",
      "value": "Orbian Financial Services XI Limited Zweigniederlassung Deutschland"
    },
    {
      "label": "Orbian Financial Services XV Limited Zweigniederlassung Deutschland",
      "value": "Orbian Financial Services XV Limited Zweigniederlassung Deutschland"
    },
    {
      "label": "Orbian Financial Services XXV Limited Zweigniederlassung Deutschland",
      "value": "Orbian Financial Services XXV Limited Zweigniederlassung Deutschland"
    },
    {
      "label": "Orbian Financial Services XXXIII Limited Zweigniederlassung Deutschland",
      "value": "Orbian Financial Services XXXIII Limited Zweigniederlassung Deutschland"
    },
    {
      "label": "Orbian Financial Services XXXV Limited Zweigniederlassung Deutschland",
      "value": "Orbian Financial Services XXXV Limited Zweigniederlassung Deutschland"
    },
    {
      "label": "ORCA Funds GmbH",
      "value": "ORCA Funds GmbH"
    },
    {
      "label": "ORGAPRO Finanz Consulting GmbH",
      "value": "ORGAPRO Finanz Consulting GmbH"
    },
    {
      "label": "Orlando Capital GmbH",
      "value": "Orlando Capital GmbH"
    },
    {
      "label": "Oropos GmbH",
      "value": "Oropos GmbH"
    },
    {
      "label": "ORTHEG eG Einkaufsgenossenschaft für Orthopädie-Technik",
      "value": "ORTHEG eG Einkaufsgenossenschaft für Orthopädie-Technik"
    },
    {
      "label": "OSTANGLER BRANDGILDE, Versicherungsverein auf Gegenseitigkeit (VVaG)",
      "value": "OSTANGLER BRANDGILDE, Versicherungsverein auf Gegenseitigkeit (VVaG)"
    },
    {
      "label": "OSTBEVERNER Versicherungsverein auf Gegenseitigkeit (VVaG)",
      "value": "OSTBEVERNER Versicherungsverein auf Gegenseitigkeit (VVaG)"
    },
    {
      "label": "Ostfriesische Volksbank eG",
      "value": "Ostfriesische Volksbank eG"
    },
    {
      "label": "OstseeSparkasse Rostock",
      "value": "OstseeSparkasse Rostock"
    },
    {
      "label": "Ostsächsische Sparkasse Dresden",
      "value": "Ostsächsische Sparkasse Dresden"
    },
    {
      "label": "OTP Bank Nyrt. Niederlassung Deutschland",
      "value": "OTP Bank Nyrt. Niederlassung Deutschland"
    },
    {
      "label": "Otto M. Schröder Bank Aktiengesellschaft",
      "value": "Otto M. Schröder Bank Aktiengesellschaft"
    },
    {
      "label": "OTTO Payments GmbH",
      "value": "OTTO Payments GmbH"
    },
    {
      "label": "ottonova Krankenversicherung AG",
      "value": "ottonova Krankenversicherung AG"
    },
    {
      "label": "OYAK ANKER Bank GmbH",
      "value": "OYAK ANKER Bank GmbH"
    },
    {
      "label": "Oyster Bay Management GmbH",
      "value": "Oyster Bay Management GmbH"
    },
    {
      "label": "P&S Vermögensberatungs AG Performance & Sicherheit",
      "value": "P&S Vermögensberatungs AG Performance & Sicherheit"
    },
    {
      "label": "P3A Ventures Management GmbH",
      "value": "P3A Ventures Management GmbH"
    },
    {
      "label": "PACCAR Financial Deutschland GmbH",
      "value": "PACCAR Financial Deutschland GmbH"
    },
    {
      "label": "Paladin Asset Management Investmentaktiengesellschaft mit veränderlichem Kapital und Teilgesellschaftsvermögen",
      "value": "Paladin Asset Management Investmentaktiengesellschaft mit veränderlichem Kapital und Teilgesellschaftsvermögen"
    },
    {
      "label": "Pall Mall Partners Ltd., Repräsentanz",
      "value": "Pall Mall Partners Ltd., Repräsentanz"
    },
    {
      "label": "Palladio Management GmbH",
      "value": "Palladio Management GmbH"
    },
    {
      "label": "Pallas Versicherung Aktiengesellschaft",
      "value": "Pallas Versicherung Aktiengesellschaft"
    },
    {
      "label": "PAMERA Real Estate Invest GmbH",
      "value": "PAMERA Real Estate Invest GmbH"
    },
    {
      "label": "Panthera AM GmbH",
      "value": "Panthera AM GmbH"
    },
    {
      "label": "Panthera Investment GmbH",
      "value": "Panthera Investment GmbH"
    },
    {
      "label": "PAR Capital Advisors GmbH",
      "value": "PAR Capital Advisors GmbH"
    },
    {
      "label": "Paradigm Capital AG",
      "value": "Paradigm Capital AG"
    },
    {
      "label": "Paradoxon CryptoInvest GmbH",
      "value": "Paradoxon CryptoInvest GmbH"
    },
    {
      "label": "Paragon Invest GmbH",
      "value": "Paragon Invest GmbH"
    },
    {
      "label": "Pareto Securities AS, Zweigniederlassung Frankfurt/M.",
      "value": "Pareto Securities AS, Zweigniederlassung Frankfurt/M."
    },
    {
      "label": "Paribus Kapitalverwaltungsgesellschaft mbH",
      "value": "Paribus Kapitalverwaltungsgesellschaft mbH"
    },
    {
      "label": "ParkProperty Capital GmbH",
      "value": "ParkProperty Capital GmbH"
    },
    {
      "label": "parmapharm Marktförderungs GmbH & Co.KG",
      "value": "parmapharm Marktförderungs GmbH & Co.KG"
    },
    {
      "label": "Partners Group (EU) GmbH",
      "value": "Partners Group (EU) GmbH"
    },
    {
      "label": "PARTNERS VermögensManagement AG",
      "value": "PARTNERS VermögensManagement AG"
    },
    {
      "label": "PAS Dr. Hammerl GmbH & Co. KG",
      "value": "PAS Dr. Hammerl GmbH & Co. KG"
    },
    {
      "label": "PATRIZIA Augsburg Kapitalverwaltungsgesellschaft mbH",
      "value": "PATRIZIA Augsburg Kapitalverwaltungsgesellschaft mbH"
    },
    {
      "label": "PATRIZIA Frankfurt Kapitalverwaltungsgesellschaft mbH",
      "value": "PATRIZIA Frankfurt Kapitalverwaltungsgesellschaft mbH"
    },
    {
      "label": "PATRIZIA GrundInvest Kapitalverwaltungsgesellschaft mbH",
      "value": "PATRIZIA GrundInvest Kapitalverwaltungsgesellschaft mbH"
    },
    {
      "label": "PATRIZIA Immobilien Kapitalverwaltungsgesellschaft mbH",
      "value": "PATRIZIA Immobilien Kapitalverwaltungsgesellschaft mbH"
    },
    {
      "label": "PATRIZIA Institutional Clients & Advisory GmbH",
      "value": "PATRIZIA Institutional Clients & Advisory GmbH"
    },
    {
      "label": "Paua Ventures Fonds 1 GmbH & Co. KG",
      "value": "Paua Ventures Fonds 1 GmbH & Co. KG"
    },
    {
      "label": "Paua Ventures Management GmbH",
      "value": "Paua Ventures Management GmbH"
    },
    {
      "label": "PAVIS Payments GmbH",
      "value": "PAVIS Payments GmbH"
    },
    {
      "label": "Pax-Bank eG",
      "value": "Pax-Bank eG"
    },
    {
      "label": "PayCenter GmbH",
      "value": "PayCenter GmbH"
    },
    {
      "label": "paydirekt GmbH",
      "value": "paydirekt GmbH"
    },
    {
      "label": "PAYONE GmbH",
      "value": "PAYONE GmbH"
    },
    {
      "label": "PB Factoring GmbH",
      "value": "PB Factoring GmbH"
    },
    {
      "label": "PB Lebensversicherung Aktiengesellschaft",
      "value": "PB Lebensversicherung Aktiengesellschaft"
    },
    {
      "label": "PB Versicherung Aktiengesellschaft",
      "value": "PB Versicherung Aktiengesellschaft"
    },
    {
      "label": "PCP Private Capital Partner Management GmbH",
      "value": "PCP Private Capital Partner Management GmbH"
    },
    {
      "label": "PEAC (Germany) GmbH",
      "value": "PEAC (Germany) GmbH"
    },
    {
      "label": "peach ventures management GmbH",
      "value": "peach ventures management GmbH"
    },
    {
      "label": "PeakZone Ventures 1 KG",
      "value": "PeakZone Ventures 1 KG"
    },
    {
      "label": "Pecunia GmbH",
      "value": "Pecunia GmbH"
    },
    {
      "label": "PECUNIA Kapitalverwaltungsgesellschaft mbH",
      "value": "PECUNIA Kapitalverwaltungsgesellschaft mbH"
    },
    {
      "label": "Peer Thomas Schwepcke Vermögensverwaltungs GmbH",
      "value": "Peer Thomas Schwepcke Vermögensverwaltungs GmbH"
    },
    {
      "label": "Pegasos Capital GmbH",
      "value": "Pegasos Capital GmbH"
    },
    {
      "label": "Pegasus Vermögensmanagement AG",
      "value": "Pegasus Vermögensmanagement AG"
    },
    {
      "label": "PEH Vermögensmanagement GmbH",
      "value": "PEH Vermögensmanagement GmbH"
    },
    {
      "label": "PEH Wertpapier AG",
      "value": "PEH Wertpapier AG"
    },
    {
      "label": "Pencuro Treuhand e.V.",
      "value": "Pencuro Treuhand e.V."
    },
    {
      "label": "Penguin Capital Management GmbH",
      "value": "Penguin Capital Management GmbH"
    },
    {
      "label": "Pensions-Sicherungs-Verein Versicherungsverein auf Gegenseitigkeit",
      "value": "Pensions-Sicherungs-Verein Versicherungsverein auf Gegenseitigkeit"
    },
    {
      "label": "Pensionskasse Baden-Badener Pensionskasse VVaG",
      "value": "Pensionskasse Baden-Badener Pensionskasse VVaG"
    },
    {
      "label": "Pensionskasse Berolina VVaG",
      "value": "Pensionskasse Berolina VVaG"
    },
    {
      "label": "Pensionskasse d. Angest. der I.G.Farbenindustrie AG Wolfen-Bitterf. VVaG i.L.c/o Hoechst",
      "value": "Pensionskasse d. Angest. der I.G.Farbenindustrie AG Wolfen-Bitterf. VVaG i.L.c/o Hoechst"
    },
    {
      "label": "Pensionskasse Degussa Versicherungsverein auf Gegenseitigkeit",
      "value": "Pensionskasse Degussa Versicherungsverein auf Gegenseitigkeit"
    },
    {
      "label": "Pensionskasse der Angestellten der ehemaligen Aschaffenburger Zellstoffwerke Aktiengesellschaft c/o WTS Steuerberatungsgesellschaft mbH i.L.",
      "value": "Pensionskasse der Angestellten der ehemaligen Aschaffenburger Zellstoffwerke Aktiengesellschaft c/o WTS Steuerberatungsgesellschaft mbH i.L."
    },
    {
      "label": "Pensionskasse der BERLIN-KÖLNISCHE Versicherungen",
      "value": "Pensionskasse der BERLIN-KÖLNISCHE Versicherungen"
    },
    {
      "label": "Pensionskasse der Betriebsangehörigen der Elektrizitätswerk Mittelbaden AG & Co.KG, Lahr/Schwarzwald, VVaG i.L.",
      "value": "Pensionskasse der Betriebsangehörigen der Elektrizitätswerk Mittelbaden AG & Co.KG, Lahr/Schwarzwald, VVaG i.L."
    },
    {
      "label": "Pensionskasse der Bewag",
      "value": "Pensionskasse der Bewag"
    },
    {
      "label": "Pensionskasse der BHW Bausparkasse",
      "value": "Pensionskasse der BHW Bausparkasse"
    },
    {
      "label": "Pensionskasse der BOGESTRA",
      "value": "Pensionskasse der BOGESTRA"
    },
    {
      "label": "Pensionskasse der Caritas VVaG",
      "value": "Pensionskasse der Caritas VVaG"
    },
    {
      "label": "PENSIONSKASSE DER CREOS UND ENOVOS DEUTSCHLAND VVAG",
      "value": "PENSIONSKASSE DER CREOS UND ENOVOS DEUTSCHLAND VVAG"
    },
    {
      "label": "Pensionskasse der EDEKA Organisation V.V.a.G.",
      "value": "Pensionskasse der EDEKA Organisation V.V.a.G."
    },
    {
      "label": "Pensionskasse der Frankfurter Sparkasse",
      "value": "Pensionskasse der Frankfurter Sparkasse"
    },
    {
      "label": "Pensionskasse der Genossenschaftsorganisation Versicherungsverein auf Gegenseitigkeit",
      "value": "Pensionskasse der Genossenschaftsorganisation Versicherungsverein auf Gegenseitigkeit"
    },
    {
      "label": "Pensionskasse der Gewerkschaft Eisenhütte Westfalia c/o Caterpillar Global Mining Europe GmbH",
      "value": "Pensionskasse der Gewerkschaft Eisenhütte Westfalia c/o Caterpillar Global Mining Europe GmbH"
    },
    {
      "label": "PENSIONSKASSE der Hamburger Hochbahn Aktiengesellschaft - VVaG -",
      "value": "PENSIONSKASSE der Hamburger Hochbahn Aktiengesellschaft - VVaG -"
    },
    {
      "label": "Pensionskasse der HELVETIA Schweizerische Versicherungsgesellschaft, Direktion für Deutschland",
      "value": "Pensionskasse der HELVETIA Schweizerische Versicherungsgesellschaft, Direktion für Deutschland"
    },
    {
      "label": "Pensionskasse der HypoVereinsbank",
      "value": "Pensionskasse der HypoVereinsbank"
    },
    {
      "label": "Pensionskasse der Lotsenbrüderschaft Elbe",
      "value": "Pensionskasse der Lotsenbrüderschaft Elbe"
    },
    {
      "label": "Pensionskasse der Mitarbeiter der ehemaligen Frankona Rückversicherungs-AG V.V.a.G.",
      "value": "Pensionskasse der Mitarbeiter der ehemaligen Frankona Rückversicherungs-AG V.V.a.G."
    },
    {
      "label": "Pensionskasse der Mitarbeiter der Hoechst-Gruppe VVaG",
      "value": "Pensionskasse der Mitarbeiter der Hoechst-Gruppe VVaG"
    },
    {
      "label": "Pensionskasse der Novartis Pharma GmbH in Nürnberg Versicherungsverein auf Gegenseitigkeit",
      "value": "Pensionskasse der Novartis Pharma GmbH in Nürnberg Versicherungsverein auf Gegenseitigkeit"
    },
    {
      "label": "Pensionskasse der Rechtsanwälte und Notare VVaG",
      "value": "Pensionskasse der Rechtsanwälte und Notare VVaG"
    },
    {
      "label": "Pensionskasse der Schülke & Mayr GmbH V.V.a.G. c/o Aon Solutions Germany GmbH",
      "value": "Pensionskasse der Schülke & Mayr GmbH V.V.a.G. c/o Aon Solutions Germany GmbH"
    },
    {
      "label": "Pensionskasse der SV SparkassenVersicherung Lebensversicherung Aktiengesellschaft Versicherungsverein a.G.",
      "value": "Pensionskasse der SV SparkassenVersicherung Lebensversicherung Aktiengesellschaft Versicherungsverein a.G."
    },
    {
      "label": "Pensionskasse der Vereinigten Hagelversicherung VVaG",
      "value": "Pensionskasse der Vereinigten Hagelversicherung VVaG"
    },
    {
      "label": "Pensionskasse der VHV-Versicherungen",
      "value": "Pensionskasse der VHV-Versicherungen"
    },
    {
      "label": "Pensionskasse der Wacker Chemie Versicherungsverein a.G.",
      "value": "Pensionskasse der Wacker Chemie Versicherungsverein a.G."
    },
    {
      "label": "Pensionskasse der Wasserwirtschaftlichen Verbände Essen VVaG",
      "value": "Pensionskasse der Wasserwirtschaftlichen Verbände Essen VVaG"
    },
    {
      "label": "Pensionskasse der Württembergischen",
      "value": "Pensionskasse der Württembergischen"
    },
    {
      "label": "Pensionskasse des BDH Bundesverband Rehabilitation, VVaG",
      "value": "Pensionskasse des BDH Bundesverband Rehabilitation, VVaG"
    },
    {
      "label": "Pensionskasse Deutscher Eisenbahnen und Straßenbahnen VVaG",
      "value": "Pensionskasse Deutscher Eisenbahnen und Straßenbahnen VVaG"
    },
    {
      "label": "PENSIONSKASSE Deutscher Genossenschaften VVaG",
      "value": "PENSIONSKASSE Deutscher Genossenschaften VVaG"
    },
    {
      "label": "Pensionskasse Dynamit Nobel Versicherungsverein auf Gegenseitigkeit",
      "value": "Pensionskasse Dynamit Nobel Versicherungsverein auf Gegenseitigkeit"
    },
    {
      "label": "Pensionskasse für Angestellte der Continental Aktiengesellschaft VVaG",
      "value": "Pensionskasse für Angestellte der Continental Aktiengesellschaft VVaG"
    },
    {
      "label": "Pensionskasse für die Angest. der BARMER Ersatzkasse (Versicherungsverein auf Gegenseitigkeit)",
      "value": "Pensionskasse für die Angest. der BARMER Ersatzkasse (Versicherungsverein auf Gegenseitigkeit)"
    },
    {
      "label": "Pensionskasse für die Arbeitnehmerinnen und Arbeitnehmer des ZDF Versicherungsverein auf Gegenseitigkeit",
      "value": "Pensionskasse für die Arbeitnehmerinnen und Arbeitnehmer des ZDF Versicherungsverein auf Gegenseitigkeit"
    },
    {
      "label": "Pensionskasse für die Deutsche Wirtschaft vormals Pensionskasse der chemischen Industrie Deutschlands",
      "value": "Pensionskasse für die Deutsche Wirtschaft vormals Pensionskasse der chemischen Industrie Deutschlands"
    },
    {
      "label": "Pensionskasse HT Troplast Versicherungsverein auf Gegenseitigkeit",
      "value": "Pensionskasse HT Troplast Versicherungsverein auf Gegenseitigkeit"
    },
    {
      "label": "Pensionskasse Konzern Versicherungskammer Bayern VVaG",
      "value": "Pensionskasse Konzern Versicherungskammer Bayern VVaG"
    },
    {
      "label": "Pensionskasse Maxhütte VVaG",
      "value": "Pensionskasse Maxhütte VVaG"
    },
    {
      "label": "PENSIONSKASSE PEUGEOT DEUTSCHLAND VVaG",
      "value": "PENSIONSKASSE PEUGEOT DEUTSCHLAND VVaG"
    },
    {
      "label": "Pensionskasse Rundfunk",
      "value": "Pensionskasse Rundfunk"
    },
    {
      "label": "Pensionskasse Schenker VVaG",
      "value": "Pensionskasse Schenker VVaG"
    },
    {
      "label": "Pensionskasse SIGNAL Versicherungen VVaG",
      "value": "Pensionskasse SIGNAL Versicherungen VVaG"
    },
    {
      "label": "Pensionskasse vom Deutschen Roten Kreuz VVaG",
      "value": "Pensionskasse vom Deutschen Roten Kreuz VVaG"
    },
    {
      "label": "People's Bank of China Repräsentanz",
      "value": "People's Bank of China Repräsentanz"
    },
    {
      "label": "Peppermint VenturePartners GmbH",
      "value": "Peppermint VenturePartners GmbH"
    },
    {
      "label": "Pepperstone GmbH",
      "value": "Pepperstone GmbH"
    },
    {
      "label": "Performance Asset Management AG",
      "value": "Performance Asset Management AG"
    },
    {
      "label": "Perpetual Growth Management GmbH",
      "value": "Perpetual Growth Management GmbH"
    },
    {
      "label": "Peruya Asset Management GmbH",
      "value": "Peruya Asset Management GmbH"
    },
    {
      "label": "Peter Doni Vermögensverwaltung GmbH",
      "value": "Peter Doni Vermögensverwaltung GmbH"
    },
    {
      "label": "Peter Ulrich Seemann Vermögensverwaltung GmbH",
      "value": "Peter Ulrich Seemann Vermögensverwaltung GmbH"
    },
    {
      "label": "Peter Unteutsch",
      "value": "Peter Unteutsch"
    },
    {
      "label": "PFP Advisory GmbH",
      "value": "PFP Advisory GmbH"
    },
    {
      "label": "PGIM Netherlands B.V. - Niederlassung Deutschland",
      "value": "PGIM Netherlands B.V. - Niederlassung Deutschland"
    },
    {
      "label": "PGIM Private Capital (Ireland) Limited (German Branch)",
      "value": "PGIM Private Capital (Ireland) Limited (German Branch)"
    },
    {
      "label": "PGIM Real Estate Germany AG",
      "value": "PGIM Real Estate Germany AG"
    },
    {
      "label": "Philippine National Bank Repräsentanz",
      "value": "Philippine National Bank Repräsentanz"
    },
    {
      "label": "Philips Medical Capital GmbH",
      "value": "Philips Medical Capital GmbH"
    },
    {
      "label": "Philips Pensionskasse (Versicherungsverein auf Gegenseitigkeit)",
      "value": "Philips Pensionskasse (Versicherungsverein auf Gegenseitigkeit)"
    },
    {
      "label": "Phoenix Pensionskasse von 1925 Versicherungsverein auf Gegenseitigkeit",
      "value": "Phoenix Pensionskasse von 1925 Versicherungsverein auf Gegenseitigkeit"
    },
    {
      "label": "PI Fondsmanagement GmbH & Co. KG",
      "value": "PI Fondsmanagement GmbH & Co. KG"
    },
    {
      "label": "PI Privatinvestor Kapitalanlage GmbH",
      "value": "PI Privatinvestor Kapitalanlage GmbH"
    },
    {
      "label": "Pictet & Cie (Europe) S.A., Niederlassung Deutschland",
      "value": "Pictet & Cie (Europe) S.A., Niederlassung Deutschland"
    },
    {
      "label": "Pictet Asset Management (Europe) S.A., Niederlassung Deutschland",
      "value": "Pictet Asset Management (Europe) S.A., Niederlassung Deutschland"
    },
    {
      "label": "PIMCO Europe GmbH",
      "value": "PIMCO Europe GmbH"
    },
    {
      "label": "Pine Investments GmbH",
      "value": "Pine Investments GmbH"
    },
    {
      "label": "PineBridge Investments Deutschland GmbH",
      "value": "PineBridge Investments Deutschland GmbH"
    },
    {
      "label": "Pinechip Capital GmbH",
      "value": "Pinechip Capital GmbH"
    },
    {
      "label": "Pinova Capital GmbH",
      "value": "Pinova Capital GmbH"
    },
    {
      "label": "PIRAEUS BANK A.E., Athen Zweigniederlassung Frankfurt am Main",
      "value": "PIRAEUS BANK A.E., Athen Zweigniederlassung Frankfurt am Main"
    },
    {
      "label": "PKO Bank Polski S.A. Niederlassung Deutschland",
      "value": "PKO Bank Polski S.A. Niederlassung Deutschland"
    },
    {
      "label": "Plan B Krypto Assets GmbH & Co. KG",
      "value": "Plan B Krypto Assets GmbH & Co. KG"
    },
    {
      "label": "Planet A GmbH",
      "value": "Planet A GmbH"
    },
    {
      "label": "Platoninvest GmbH",
      "value": "Platoninvest GmbH"
    },
    {
      "label": "Plutos Vermögensverwaltung AG",
      "value": "Plutos Vermögensverwaltung AG"
    },
    {
      "label": "PMF Factoring GmbH",
      "value": "PMF Factoring GmbH"
    },
    {
      "label": "PMG MittelstandsGruppe GmbH",
      "value": "PMG MittelstandsGruppe GmbH"
    },
    {
      "label": "PMP Vermögensmanagement Donner & Reuschel Luxemburg S.A. Zweigniederlassung Deutschland",
      "value": "PMP Vermögensmanagement Donner & Reuschel Luxemburg S.A. Zweigniederlassung Deutschland"
    },
    {
      "label": "Point Nine Annex GmbH & Co. KG",
      "value": "Point Nine Annex GmbH & Co. KG"
    },
    {
      "label": "Point Nine Capital Fund I GmbH & Co. KG",
      "value": "Point Nine Capital Fund I GmbH & Co. KG"
    },
    {
      "label": "Point Nine Capital Fund II GmbH & Co. KG",
      "value": "Point Nine Capital Fund II GmbH & Co. KG"
    },
    {
      "label": "Point Nine Capital Fund III GmbH & Co. KG",
      "value": "Point Nine Capital Fund III GmbH & Co. KG"
    },
    {
      "label": "Point Nine Capital Fund IV GmbH & Co. KG",
      "value": "Point Nine Capital Fund IV GmbH & Co. KG"
    },
    {
      "label": "Point Nine Capital Fund V GmbH & Co. KG",
      "value": "Point Nine Capital Fund V GmbH & Co. KG"
    },
    {
      "label": "Point Nine Management II GmbH",
      "value": "Point Nine Management II GmbH"
    },
    {
      "label": "Polar Capital (Europe), Germany Branch",
      "value": "Polar Capital (Europe), Germany Branch"
    },
    {
      "label": "Pommersche Volksbank eG",
      "value": "Pommersche Volksbank eG"
    },
    {
      "label": "Porsche Financial Services GmbH & Co. KG",
      "value": "Porsche Financial Services GmbH & Co. KG"
    },
    {
      "label": "Portfolio Concept Vermögensmanagement GmbH",
      "value": "Portfolio Concept Vermögensmanagement GmbH"
    },
    {
      "label": "Portfolio Financial Services GmbH",
      "value": "Portfolio Financial Services GmbH"
    },
    {
      "label": "Portfolioalpha Capital GmbH",
      "value": "Portfolioalpha Capital GmbH"
    },
    {
      "label": "Portigon AG",
      "value": "Portigon AG"
    },
    {
      "label": "Portland Deutschland GmbH",
      "value": "Portland Deutschland GmbH"
    },
    {
      "label": "Possible Ventures Management GmbH",
      "value": "Possible Ventures Management GmbH"
    },
    {
      "label": "Potsdamer Wohnungsgenossenschaft 1956 eG",
      "value": "Potsdamer Wohnungsgenossenschaft 1956 eG"
    },
    {
      "label": "PP-Asset Management GmbH",
      "value": "PP-Asset Management GmbH"
    },
    {
      "label": "Praeclarus Invest GmbH",
      "value": "Praeclarus Invest GmbH"
    },
    {
      "label": "praenatura Versicherungsverein auf Gegenseitigkeit (VVaG)",
      "value": "praenatura Versicherungsverein auf Gegenseitigkeit (VVaG)"
    },
    {
      "label": "PraxisLeasing GmbH",
      "value": "PraxisLeasing GmbH"
    },
    {
      "label": "Pre-IPO GmbH",
      "value": "Pre-IPO GmbH"
    },
    {
      "label": "PREMIUM Equity Partners GmbH",
      "value": "PREMIUM Equity Partners GmbH"
    },
    {
      "label": "Prequel Ventures Management GmbH",
      "value": "Prequel Ventures Management GmbH"
    },
    {
      "label": "PriAS GmbH",
      "value": "PriAS GmbH"
    },
    {
      "label": "Prime Capital AG",
      "value": "Prime Capital AG"
    },
    {
      "label": "Principal Global Investors (EU) Limited, Niederlassung Deutschland",
      "value": "Principal Global Investors (EU) Limited, Niederlassung Deutschland"
    },
    {
      "label": "Principal Real Estate Spezialfondsgesellschaft mbH",
      "value": "Principal Real Estate Spezialfondsgesellschaft mbH"
    },
    {
      "label": "PRISMA Investment GmbH",
      "value": "PRISMA Investment GmbH"
    },
    {
      "label": "Privacon Vermögensverwaltung GmbH",
      "value": "Privacon Vermögensverwaltung GmbH"
    },
    {
      "label": "PRIVALOR AG",
      "value": "PRIVALOR AG"
    },
    {
      "label": "Privas Private Abrechnungsstelle für Ärzte GmbH & Co. KG",
      "value": "Privas Private Abrechnungsstelle für Ärzte GmbH & Co. KG"
    },
    {
      "label": "Private Equity Thüringen GmbH & Co. Zweite Beteiligungen KG",
      "value": "Private Equity Thüringen GmbH & Co. Zweite Beteiligungen KG"
    },
    {
      "label": "PrivatVerrechnungsStelle der Ärzte in Niedersachsen",
      "value": "PrivatVerrechnungsStelle der Ärzte in Niedersachsen"
    },
    {
      "label": "Privatverrechnungsstelle der Ärzte und Zahnärzte Bremen e.V.",
      "value": "Privatverrechnungsstelle der Ärzte und Zahnärzte Bremen e.V."
    },
    {
      "label": "Privatärztliche Verrechnungsstelle Limburg/Lahn GmbH",
      "value": "Privatärztliche Verrechnungsstelle Limburg/Lahn GmbH"
    },
    {
      "label": "Privatärztliche Verrechnungsstelle Mosel-Saar GmbH",
      "value": "Privatärztliche Verrechnungsstelle Mosel-Saar GmbH"
    },
    {
      "label": "Privatärztliche Verrechnungsstelle Sachsen GmbH",
      "value": "Privatärztliche Verrechnungsstelle Sachsen GmbH"
    },
    {
      "label": "Privatärztliche Verrechnungsstelle Schleswig-Holstein/Hamburg rkV",
      "value": "Privatärztliche Verrechnungsstelle Schleswig-Holstein/Hamburg rkV"
    },
    {
      "label": "Privatärztliche Verrechnungsstelle Westfalen-Nord GmbH",
      "value": "Privatärztliche Verrechnungsstelle Westfalen-Nord GmbH"
    },
    {
      "label": "Privatärztliche Verrechnungstelle Westfalen-Süd rkV",
      "value": "Privatärztliche Verrechnungstelle Westfalen-Süd rkV"
    },
    {
      "label": "Pro bAV Pensionskasse AG",
      "value": "Pro bAV Pensionskasse AG"
    },
    {
      "label": "Pro-Factoring AG",
      "value": "Pro-Factoring AG"
    },
    {
      "label": "PROAKTIVA GmbH",
      "value": "PROAKTIVA GmbH"
    },
    {
      "label": "ProCredit Bank AG",
      "value": "ProCredit Bank AG"
    },
    {
      "label": "Profide Vermögensverwaltung GmbH",
      "value": "Profide Vermögensverwaltung GmbH"
    },
    {
      "label": "Profitabel Kapitalanlagen GmbH",
      "value": "Profitabel Kapitalanlagen GmbH"
    },
    {
      "label": "Project A Ventures Management GmbH",
      "value": "Project A Ventures Management GmbH"
    },
    {
      "label": "PROJECT Investment AG",
      "value": "PROJECT Investment AG"
    },
    {
      "label": "Promont AM AG",
      "value": "Promont AM AG"
    },
    {
      "label": "PRONOVI Fondsverwaltungs GmbH",
      "value": "PRONOVI Fondsverwaltungs GmbH"
    },
    {
      "label": "PRORÜCK Rückversicherungs-Aktiengesellschaft",
      "value": "PRORÜCK Rückversicherungs-Aktiengesellschaft"
    },
    {
      "label": "ProTect Versicherung AG",
      "value": "ProTect Versicherung AG"
    },
    {
      "label": "Protektor Lebensversicherungs-AG",
      "value": "Protektor Lebensversicherungs-AG"
    },
    {
      "label": "ProVidens Vermögensmanagement GmbH",
      "value": "ProVidens Vermögensmanagement GmbH"
    },
    {
      "label": "Provinzial Asset Management GmbH",
      "value": "Provinzial Asset Management GmbH"
    },
    {
      "label": "Provinzial Holding Aktiengesellschaft",
      "value": "Provinzial Holding Aktiengesellschaft"
    },
    {
      "label": "Provinzial Krankenversicherung Hannover AG",
      "value": "Provinzial Krankenversicherung Hannover AG"
    },
    {
      "label": "Provinzial Lebensversicherung Hannover",
      "value": "Provinzial Lebensversicherung Hannover"
    },
    {
      "label": "Provinzial Nord Brandkasse Aktiengesellschaft",
      "value": "Provinzial Nord Brandkasse Aktiengesellschaft"
    },
    {
      "label": "Provinzial NordWest Lebensversicherung Aktiengesellschaft",
      "value": "Provinzial NordWest Lebensversicherung Aktiengesellschaft"
    },
    {
      "label": "Provinzial Pensionskasse Hannover AG",
      "value": "Provinzial Pensionskasse Hannover AG"
    },
    {
      "label": "Provinzial Rheinland Lebensversicherung AG Die Versicherung der Sparkassen",
      "value": "Provinzial Rheinland Lebensversicherung AG Die Versicherung der Sparkassen"
    },
    {
      "label": "Provinzial Versicherung Aktiengesellschaft",
      "value": "Provinzial Versicherung Aktiengesellschaft"
    },
    {
      "label": "Proxalto Lebensversicherung Aktiengesellschaft c/o Viridium Group GmbH & Co. KG",
      "value": "Proxalto Lebensversicherung Aktiengesellschaft c/o Viridium Group GmbH & Co. KG"
    },
    {
      "label": "PRUDENTIA Pensionskasse AG",
      "value": "PRUDENTIA Pensionskasse AG"
    },
    {
      "label": "Pruschke & Kalm GmbH",
      "value": "Pruschke & Kalm GmbH"
    },
    {
      "label": "PS Leasing- und Finanz GmbH",
      "value": "PS Leasing- und Finanz GmbH"
    },
    {
      "label": "PSA Bank Deutschland GmbH",
      "value": "PSA Bank Deutschland GmbH"
    },
    {
      "label": "PSD Bank Berlin-Brandenburg eG",
      "value": "PSD Bank Berlin-Brandenburg eG"
    },
    {
      "label": "PSD Bank Braunschweig eG",
      "value": "PSD Bank Braunschweig eG"
    },
    {
      "label": "PSD Bank Hannover eG",
      "value": "PSD Bank Hannover eG"
    },
    {
      "label": "PSD Bank Hessen-Thüringen eG",
      "value": "PSD Bank Hessen-Thüringen eG"
    },
    {
      "label": "PSD Bank Karlsruhe-Neustadt eG",
      "value": "PSD Bank Karlsruhe-Neustadt eG"
    },
    {
      "label": "PSD Bank Kiel eG",
      "value": "PSD Bank Kiel eG"
    },
    {
      "label": "PSD Bank Koblenz eG",
      "value": "PSD Bank Koblenz eG"
    },
    {
      "label": "PSD Bank München eG",
      "value": "PSD Bank München eG"
    },
    {
      "label": "PSD Bank Nord eG",
      "value": "PSD Bank Nord eG"
    },
    {
      "label": "PSD Bank Nürnberg eG",
      "value": "PSD Bank Nürnberg eG"
    },
    {
      "label": "PSD Bank Rhein-Ruhr eG",
      "value": "PSD Bank Rhein-Ruhr eG"
    },
    {
      "label": "PSD Bank RheinNeckarSaar eG",
      "value": "PSD Bank RheinNeckarSaar eG"
    },
    {
      "label": "PSD Bank West eG",
      "value": "PSD Bank West eG"
    },
    {
      "label": "PSD Bank Westfalen-Lippe eG",
      "value": "PSD Bank Westfalen-Lippe eG"
    },
    {
      "label": "PSD Berlin Immobilien KVG GmbH",
      "value": "PSD Berlin Immobilien KVG GmbH"
    },
    {
      "label": "PSD Invest Kapitalverwaltungsgesellschaft mbH",
      "value": "PSD Invest Kapitalverwaltungsgesellschaft mbH"
    },
    {
      "label": "PSM Vermögensverwaltung GmbH Langen v.d. Goltz, Dr. Prinz & Partner",
      "value": "PSM Vermögensverwaltung GmbH Langen v.d. Goltz, Dr. Prinz & Partner"
    },
    {
      "label": "PT Asset Management GmbH",
      "value": "PT Asset Management GmbH"
    },
    {
      "label": "publity Performance GmbH",
      "value": "publity Performance GmbH"
    },
    {
      "label": "PVAG Polizeiversicherungs-Aktiengesellschaft",
      "value": "PVAG Polizeiversicherungs-Aktiengesellschaft"
    },
    {
      "label": "PVS bayern GmbH",
      "value": "PVS bayern GmbH"
    },
    {
      "label": "PVS berlin-brandenburg-hamburg GmbH & Co. KG",
      "value": "PVS berlin-brandenburg-hamburg GmbH & Co. KG"
    },
    {
      "label": "PVS dental GmbH",
      "value": "PVS dental GmbH"
    },
    {
      "label": "PVS pria GmbH",
      "value": "PVS pria GmbH"
    },
    {
      "label": "PVS Privatärztliche Verrechnungsstelle Südwest GmbH",
      "value": "PVS Privatärztliche Verrechnungsstelle Südwest GmbH"
    },
    {
      "label": "PVS ra GmbH",
      "value": "PVS ra GmbH"
    },
    {
      "label": "PVS Reiss GmbH",
      "value": "PVS Reiss GmbH"
    },
    {
      "label": "PVS rhein-ruhr GmbH",
      "value": "PVS rhein-ruhr GmbH"
    },
    {
      "label": "PVS-Finanzservice GmbH",
      "value": "PVS-Finanzservice GmbH"
    },
    {
      "label": "PVV AG",
      "value": "PVV AG"
    },
    {
      "label": "Q21 Capital InvAG mit TGV",
      "value": "Q21 Capital InvAG mit TGV"
    },
    {
      "label": "QBE Europe SA/NV Direktion für Deutschland",
      "value": "QBE Europe SA/NV Direktion für Deutschland"
    },
    {
      "label": "QBS Invest GmbH",
      "value": "QBS Invest GmbH"
    },
    {
      "label": "QC Partners GmbH",
      "value": "QC Partners GmbH"
    },
    {
      "label": "QCoon Kapitalverwaltungsgesellschaft mbH",
      "value": "QCoon Kapitalverwaltungsgesellschaft mbH"
    },
    {
      "label": "QTrade GmbH",
      "value": "QTrade GmbH"
    },
    {
      "label": "Quadoro Investment GmbH",
      "value": "Quadoro Investment GmbH"
    },
    {
      "label": "quantagon financial advisors GmbH",
      "value": "quantagon financial advisors GmbH"
    },
    {
      "label": "Quantec Capital GmbH",
      "value": "Quantec Capital GmbH"
    },
    {
      "label": "Quants Vermögensmanagement AG",
      "value": "Quants Vermögensmanagement AG"
    },
    {
      "label": "Quantum CapitalPartners GmbH",
      "value": "Quantum CapitalPartners GmbH"
    },
    {
      "label": "Quantum Immobilien Kapitalverwaltungsgesellschaft mbH",
      "value": "Quantum Immobilien Kapitalverwaltungsgesellschaft mbH"
    },
    {
      "label": "Quantumrock Capital GmbH",
      "value": "Quantumrock Capital GmbH"
    },
    {
      "label": "Quattro!Folio!GmbH",
      "value": "Quattro!Folio!GmbH"
    },
    {
      "label": "Quirin Privatbank AG",
      "value": "Quirin Privatbank AG"
    },
    {
      "label": "quirion AG",
      "value": "quirion AG"
    },
    {
      "label": "Quoniam Asset Management GmbH",
      "value": "Quoniam Asset Management GmbH"
    },
    {
      "label": "QVM Privatkapital GmbH",
      "value": "QVM Privatkapital GmbH"
    },
    {
      "label": "R & M Vermögensverwaltung GmbH",
      "value": "R & M Vermögensverwaltung GmbH"
    },
    {
      "label": "R + V Lebensversicherung a.G.",
      "value": "R + V Lebensversicherung a.G."
    },
    {
      "label": "R + V LEBENSVERSICHERUNG AKTIENGESELLSCHAFT",
      "value": "R + V LEBENSVERSICHERUNG AKTIENGESELLSCHAFT"
    },
    {
      "label": "R+V Allgemeine Versicherung Aktiengesellschaft",
      "value": "R+V Allgemeine Versicherung Aktiengesellschaft"
    },
    {
      "label": "R+V Direktversicherung AG",
      "value": "R+V Direktversicherung AG"
    },
    {
      "label": "R+V Krankenversicherung Aktiengesellschaft",
      "value": "R+V Krankenversicherung Aktiengesellschaft"
    },
    {
      "label": "R+V Pensionsfonds Aktiengesellschaft",
      "value": "R+V Pensionsfonds Aktiengesellschaft"
    },
    {
      "label": "R+V PENSIONSKASSE AKTIENGESELLSCHAFT",
      "value": "R+V PENSIONSKASSE AKTIENGESELLSCHAFT"
    },
    {
      "label": "R+V PENSIONSVERSICHERUNG a.G.",
      "value": "R+V PENSIONSVERSICHERUNG a.G."
    },
    {
      "label": "R+V VERSICHERUNG AG",
      "value": "R+V VERSICHERUNG AG"
    },
    {
      "label": "R.I. Vermögensbetreuung AG",
      "value": "R.I. Vermögensbetreuung AG"
    },
    {
      "label": "Raiffeisen - meine Bank eG",
      "value": "Raiffeisen - meine Bank eG"
    },
    {
      "label": "Raiffeisen Bank International AG, Zweigniederlassung Frankfurt",
      "value": "Raiffeisen Bank International AG, Zweigniederlassung Frankfurt"
    },
    {
      "label": "RAIFFEISEN Spar + Kreditbank eG",
      "value": "RAIFFEISEN Spar + Kreditbank eG"
    },
    {
      "label": "Raiffeisen-Bank Eschweiler eG",
      "value": "Raiffeisen-Bank Eschweiler eG"
    },
    {
      "label": "Raiffeisen-IMPULS Finance & Lease GmbH",
      "value": "Raiffeisen-IMPULS Finance & Lease GmbH"
    },
    {
      "label": "Raiffeisen-IMPULS Fuhrparkmanagement GmbH & Co. KG",
      "value": "Raiffeisen-IMPULS Fuhrparkmanagement GmbH & Co. KG"
    },
    {
      "label": "Raiffeisen-Volksbank Aschaffenburg eG",
      "value": "Raiffeisen-Volksbank Aschaffenburg eG"
    },
    {
      "label": "Raiffeisen-Volksbank Bad Staffelstein eG",
      "value": "Raiffeisen-Volksbank Bad Staffelstein eG"
    },
    {
      "label": "Raiffeisen-Volksbank Donauwörth eG",
      "value": "Raiffeisen-Volksbank Donauwörth eG"
    },
    {
      "label": "Raiffeisen-Volksbank Ebersberg eG",
      "value": "Raiffeisen-Volksbank Ebersberg eG"
    },
    {
      "label": "Raiffeisen-Volksbank eG",
      "value": "Raiffeisen-Volksbank eG"
    },
    {
      "label": "Raiffeisen-Volksbank Fresena eG",
      "value": "Raiffeisen-Volksbank Fresena eG"
    },
    {
      "label": "Raiffeisen-Volksbank Haßberge eG",
      "value": "Raiffeisen-Volksbank Haßberge eG"
    },
    {
      "label": "Raiffeisen-Volksbank Hermsdorfer Kreuz eG",
      "value": "Raiffeisen-Volksbank Hermsdorfer Kreuz eG"
    },
    {
      "label": "Raiffeisen-Volksbank Neustadt eG",
      "value": "Raiffeisen-Volksbank Neustadt eG"
    },
    {
      "label": "Raiffeisen-Volksbank Oder-Spree eG",
      "value": "Raiffeisen-Volksbank Oder-Spree eG"
    },
    {
      "label": "Raiffeisen-Volksbank Ries eG",
      "value": "Raiffeisen-Volksbank Ries eG"
    },
    {
      "label": "Raiffeisen-Volksbank Tüßling-Unterneukirchen eG",
      "value": "Raiffeisen-Volksbank Tüßling-Unterneukirchen eG"
    },
    {
      "label": "Raiffeisen-Volksbank Varel-Nordenham eG",
      "value": "Raiffeisen-Volksbank Varel-Nordenham eG"
    },
    {
      "label": "Raiffeisen-Volksbank Wemding eG",
      "value": "Raiffeisen-Volksbank Wemding eG"
    },
    {
      "label": "Raiffeisenbank \"Nahe\" eG",
      "value": "Raiffeisenbank \"Nahe\" eG"
    },
    {
      "label": "Raiffeisenbank Aichhalden-Hardt-Sulgen eG",
      "value": "Raiffeisenbank Aichhalden-Hardt-Sulgen eG"
    },
    {
      "label": "Raiffeisenbank Aidlingen eG",
      "value": "Raiffeisenbank Aidlingen eG"
    },
    {
      "label": "Raiffeisenbank Aindling eG",
      "value": "Raiffeisenbank Aindling eG"
    },
    {
      "label": "Raiffeisenbank Aitrang-Ruderatshofen eG",
      "value": "Raiffeisenbank Aitrang-Ruderatshofen eG"
    },
    {
      "label": "Raiffeisenbank Alteglofsheim-Hagelstadt eG",
      "value": "Raiffeisenbank Alteglofsheim-Hagelstadt eG"
    },
    {
      "label": "Raiffeisenbank Altschweier eG",
      "value": "Raiffeisenbank Altschweier eG"
    },
    {
      "label": "Raiffeisenbank am Dreisessel eG",
      "value": "Raiffeisenbank am Dreisessel eG"
    },
    {
      "label": "Raiffeisenbank Am Goldenen Steig eG",
      "value": "Raiffeisenbank Am Goldenen Steig eG"
    },
    {
      "label": "Raiffeisenbank am Kulm eG",
      "value": "Raiffeisenbank am Kulm eG"
    },
    {
      "label": "Raiffeisenbank Anger eG",
      "value": "Raiffeisenbank Anger eG"
    },
    {
      "label": "Raiffeisenbank Aresing-Gerolsbach eG",
      "value": "Raiffeisenbank Aresing-Gerolsbach eG"
    },
    {
      "label": "Raiffeisenbank Arnstorf eG",
      "value": "Raiffeisenbank Arnstorf eG"
    },
    {
      "label": "Raiffeisenbank Aschau-Samerberg eG",
      "value": "Raiffeisenbank Aschau-Samerberg eG"
    },
    {
      "label": "Raiffeisenbank Aschberg eG",
      "value": "Raiffeisenbank Aschberg eG"
    },
    {
      "label": "Raiffeisenbank Auerbach-Freihung eG",
      "value": "Raiffeisenbank Auerbach-Freihung eG"
    },
    {
      "label": "Raiffeisenbank Augsburger Land West eG",
      "value": "Raiffeisenbank Augsburger Land West eG"
    },
    {
      "label": "Raiffeisenbank Bad Kötzting eG",
      "value": "Raiffeisenbank Bad Kötzting eG"
    },
    {
      "label": "Raiffeisenbank Bad Saulgau eG",
      "value": "Raiffeisenbank Bad Saulgau eG"
    },
    {
      "label": "Raiffeisenbank Bad Schussenried - Aulendorf eG",
      "value": "Raiffeisenbank Bad Schussenried - Aulendorf eG"
    },
    {
      "label": "Raiffeisenbank Bad Windsheim eG",
      "value": "Raiffeisenbank Bad Windsheim eG"
    },
    {
      "label": "Raiffeisenbank Baiertal eG",
      "value": "Raiffeisenbank Baiertal eG"
    },
    {
      "label": "Raiffeisenbank Baisweil-Eggenthal-Friesenried eG",
      "value": "Raiffeisenbank Baisweil-Eggenthal-Friesenried eG"
    },
    {
      "label": "Raiffeisenbank Bechhofen eG",
      "value": "Raiffeisenbank Bechhofen eG"
    },
    {
      "label": "Raiffeisenbank Beilngries eG",
      "value": "Raiffeisenbank Beilngries eG"
    },
    {
      "label": "Raiffeisenbank Berghülen eG",
      "value": "Raiffeisenbank Berghülen eG"
    },
    {
      "label": "Raiffeisenbank Beuerberg-Eurasburg eG",
      "value": "Raiffeisenbank Beuerberg-Eurasburg eG"
    },
    {
      "label": "Raiffeisenbank Biberach eG",
      "value": "Raiffeisenbank Biberach eG"
    },
    {
      "label": "Raiffeisenbank Bibertgrund eG",
      "value": "Raiffeisenbank Bibertgrund eG"
    },
    {
      "label": "Raiffeisenbank Bidingen eG",
      "value": "Raiffeisenbank Bidingen eG"
    },
    {
      "label": "Raiffeisenbank Biebergrund-Petersberg eG",
      "value": "Raiffeisenbank Biebergrund-Petersberg eG"
    },
    {
      "label": "Raiffeisenbank Bissingen eG",
      "value": "Raiffeisenbank Bissingen eG"
    },
    {
      "label": "Raiffeisenbank Bobingen eG",
      "value": "Raiffeisenbank Bobingen eG"
    },
    {
      "label": "Raiffeisenbank Buch-Eching eG",
      "value": "Raiffeisenbank Buch-Eching eG"
    },
    {
      "label": "Raiffeisenbank Burgebrach - Stegaurach eG",
      "value": "Raiffeisenbank Burgebrach - Stegaurach eG"
    },
    {
      "label": "Raiffeisenbank Butjadingen-Abbehausen eG",
      "value": "Raiffeisenbank Butjadingen-Abbehausen eG"
    },
    {
      "label": "Raiffeisenbank Böllingertal eG",
      "value": "Raiffeisenbank Böllingertal eG"
    },
    {
      "label": "Raiffeisenbank Bühlertal eG",
      "value": "Raiffeisenbank Bühlertal eG"
    },
    {
      "label": "Raiffeisenbank Bütthard-Gaukönigshofen eG",
      "value": "Raiffeisenbank Bütthard-Gaukönigshofen eG"
    },
    {
      "label": "Raiffeisenbank Chamer Land eG",
      "value": "Raiffeisenbank Chamer Land eG"
    },
    {
      "label": "Raiffeisenbank Chiemgau - Nord - Obing eG",
      "value": "Raiffeisenbank Chiemgau - Nord - Obing eG"
    },
    {
      "label": "Raiffeisenbank Denzlingen-Sexau eG",
      "value": "Raiffeisenbank Denzlingen-Sexau eG"
    },
    {
      "label": "Raiffeisenbank Dietersheim und Umgebung eG",
      "value": "Raiffeisenbank Dietersheim und Umgebung eG"
    },
    {
      "label": "Raiffeisenbank Donau-Heuberg, eingetragene Genossenschaft",
      "value": "Raiffeisenbank Donau-Heuberg, eingetragene Genossenschaft"
    },
    {
      "label": "Raiffeisenbank DreiFranken eG",
      "value": "Raiffeisenbank DreiFranken eG"
    },
    {
      "label": "Raiffeisenbank eG",
      "value": "Raiffeisenbank eG"
    },
    {
      "label": "Raiffeisenbank eG",
      "value": "Raiffeisenbank eG"
    },
    {
      "label": "Raiffeisenbank eG",
      "value": "Raiffeisenbank eG"
    },
    {
      "label": "Raiffeisenbank eG",
      "value": "Raiffeisenbank eG"
    },
    {
      "label": "Raiffeisenbank eG",
      "value": "Raiffeisenbank eG"
    },
    {
      "label": "Raiffeisenbank eG",
      "value": "Raiffeisenbank eG"
    },
    {
      "label": "Raiffeisenbank eG",
      "value": "Raiffeisenbank eG"
    },
    {
      "label": "Raiffeisenbank eG",
      "value": "Raiffeisenbank eG"
    },
    {
      "label": "Raiffeisenbank eG",
      "value": "Raiffeisenbank eG"
    },
    {
      "label": "Raiffeisenbank eG",
      "value": "Raiffeisenbank eG"
    },
    {
      "label": "Raiffeisenbank eG",
      "value": "Raiffeisenbank eG"
    },
    {
      "label": "Raiffeisenbank eG Deggendorf-Plattling-Sonnenwald",
      "value": "Raiffeisenbank eG Deggendorf-Plattling-Sonnenwald"
    },
    {
      "label": "Raiffeisenbank eG Scharrel",
      "value": "Raiffeisenbank eG Scharrel"
    },
    {
      "label": "Raiffeisenbank eG Unterwesterwald",
      "value": "Raiffeisenbank eG Unterwesterwald"
    },
    {
      "label": "Raiffeisenbank Ehekirchen-Oberhausen eG",
      "value": "Raiffeisenbank Ehekirchen-Oberhausen eG"
    },
    {
      "label": "Raiffeisenbank Eichenbühl und Umgebung eG",
      "value": "Raiffeisenbank Eichenbühl und Umgebung eG"
    },
    {
      "label": "Raiffeisenbank Eifel eG",
      "value": "Raiffeisenbank Eifel eG"
    },
    {
      "label": "Raiffeisenbank Eifeltor eG",
      "value": "Raiffeisenbank Eifeltor eG"
    },
    {
      "label": "Raiffeisenbank Elbmarsch eG",
      "value": "Raiffeisenbank Elbmarsch eG"
    },
    {
      "label": "Raiffeisenbank Elsavatal eG",
      "value": "Raiffeisenbank Elsavatal eG"
    },
    {
      "label": "Raiffeisenbank Ems-Vechte eG",
      "value": "Raiffeisenbank Ems-Vechte eG"
    },
    {
      "label": "Raiffeisenbank Erding eG",
      "value": "Raiffeisenbank Erding eG"
    },
    {
      "label": "Raiffeisenbank Erlenbach eG",
      "value": "Raiffeisenbank Erlenbach eG"
    },
    {
      "label": "Raiffeisenbank Ersingen eG",
      "value": "Raiffeisenbank Ersingen eG"
    },
    {
      "label": "Raiffeisenbank Eschlkam-Lam-Lohberg-Neukirchen b. Hl. Blut eG",
      "value": "Raiffeisenbank Eschlkam-Lam-Lohberg-Neukirchen b. Hl. Blut eG"
    },
    {
      "label": "Raiffeisenbank Estenfeld-Bergtheim eG",
      "value": "Raiffeisenbank Estenfeld-Bergtheim eG"
    },
    {
      "label": "Raiffeisenbank Falkenstein-Wörth eG",
      "value": "Raiffeisenbank Falkenstein-Wörth eG"
    },
    {
      "label": "Raiffeisenbank Flachsmeer eG",
      "value": "Raiffeisenbank Flachsmeer eG"
    },
    {
      "label": "Raiffeisenbank Floß eG",
      "value": "Raiffeisenbank Floß eG"
    },
    {
      "label": "Raiffeisenbank Frankenhardt-Stimpfach eG",
      "value": "Raiffeisenbank Frankenhardt-Stimpfach eG"
    },
    {
      "label": "Raiffeisenbank Fränkische Schweiz eG",
      "value": "Raiffeisenbank Fränkische Schweiz eG"
    },
    {
      "label": "Raiffeisenbank Gammesfeld eingetragene Genossenschaft",
      "value": "Raiffeisenbank Gammesfeld eingetragene Genossenschaft"
    },
    {
      "label": "Raiffeisenbank Geiselhöring-Pfaffenberg eG",
      "value": "Raiffeisenbank Geiselhöring-Pfaffenberg eG"
    },
    {
      "label": "Raiffeisenbank Geislingen-Rosenfeld eG",
      "value": "Raiffeisenbank Geislingen-Rosenfeld eG"
    },
    {
      "label": "Raiffeisenbank Gilching eG",
      "value": "Raiffeisenbank Gilching eG"
    },
    {
      "label": "Raiffeisenbank Gmund am Tegernsee eG",
      "value": "Raiffeisenbank Gmund am Tegernsee eG"
    },
    {
      "label": "Raiffeisenbank Grainet eG",
      "value": "Raiffeisenbank Grainet eG"
    },
    {
      "label": "Raiffeisenbank Greding-Thalmässing eG",
      "value": "Raiffeisenbank Greding-Thalmässing eG"
    },
    {
      "label": "Raiffeisenbank Griesstätt - Halfing eG",
      "value": "Raiffeisenbank Griesstätt - Halfing eG"
    },
    {
      "label": "Raiffeisenbank Grimma eG",
      "value": "Raiffeisenbank Grimma eG"
    },
    {
      "label": "Raiffeisenbank Gruibingen eG.",
      "value": "Raiffeisenbank Gruibingen eG."
    },
    {
      "label": "Raiffeisenbank Grävenwiesbach eG",
      "value": "Raiffeisenbank Grävenwiesbach eG"
    },
    {
      "label": "Raiffeisenbank Gymnich eG",
      "value": "Raiffeisenbank Gymnich eG"
    },
    {
      "label": "Raiffeisenbank Haag-Gars-Maitenbeth eG",
      "value": "Raiffeisenbank Haag-Gars-Maitenbeth eG"
    },
    {
      "label": "Raiffeisenbank Hallertau eG",
      "value": "Raiffeisenbank Hallertau eG"
    },
    {
      "label": "RAIFFEISENBANK HARDT-BRUHRAIN eG",
      "value": "RAIFFEISENBANK HARDT-BRUHRAIN eG"
    },
    {
      "label": "Raiffeisenbank Heilsbronn-Windsbach eG",
      "value": "Raiffeisenbank Heilsbronn-Windsbach eG"
    },
    {
      "label": "Raiffeisenbank Hengersberg-Schöllnach eG",
      "value": "Raiffeisenbank Hengersberg-Schöllnach eG"
    },
    {
      "label": "Raiffeisenbank Heroldsbach eG",
      "value": "Raiffeisenbank Heroldsbach eG"
    },
    {
      "label": "Raiffeisenbank HessenNord eG",
      "value": "Raiffeisenbank HessenNord eG"
    },
    {
      "label": "Raiffeisenbank Hiltenfingen eG",
      "value": "Raiffeisenbank Hiltenfingen eG"
    },
    {
      "label": "Raiffeisenbank Hirschau eG",
      "value": "Raiffeisenbank Hirschau eG"
    },
    {
      "label": "Raiffeisenbank Hochfranken West eG",
      "value": "Raiffeisenbank Hochfranken West eG"
    },
    {
      "label": "Raiffeisenbank Hohenloher Land eG",
      "value": "Raiffeisenbank Hohenloher Land eG"
    },
    {
      "label": "Raiffeisenbank Holzkirchen-Otterfing eG",
      "value": "Raiffeisenbank Holzkirchen-Otterfing eG"
    },
    {
      "label": "Raiffeisenbank Höchberg eG",
      "value": "Raiffeisenbank Höchberg eG"
    },
    {
      "label": "Raiffeisenbank i. Lkrs. Passau-Nord eG",
      "value": "Raiffeisenbank i. Lkrs. Passau-Nord eG"
    },
    {
      "label": "Raiffeisenbank Ichenhausen eG",
      "value": "Raiffeisenbank Ichenhausen eG"
    },
    {
      "label": "Raiffeisenbank im Allgäuer Land eG",
      "value": "Raiffeisenbank im Allgäuer Land eG"
    },
    {
      "label": "Raiffeisenbank im Breisgau eG",
      "value": "Raiffeisenbank im Breisgau eG"
    },
    {
      "label": "Raiffeisenbank im Donautal eG",
      "value": "Raiffeisenbank im Donautal eG"
    },
    {
      "label": "Raiffeisenbank im Fuldaer Land eG",
      "value": "Raiffeisenbank im Fuldaer Land eG"
    },
    {
      "label": "Raiffeisenbank im Grabfeld eG",
      "value": "Raiffeisenbank im Grabfeld eG"
    },
    {
      "label": "Raiffeisenbank im Hochtaunus eG",
      "value": "Raiffeisenbank im Hochtaunus eG"
    },
    {
      "label": "Raiffeisenbank im Kreis Calw eG",
      "value": "Raiffeisenbank im Kreis Calw eG"
    },
    {
      "label": "Raiffeisenbank im Nürnberger Land eG",
      "value": "Raiffeisenbank im Nürnberger Land eG"
    },
    {
      "label": "Raiffeisenbank im Oberland eG",
      "value": "Raiffeisenbank im Oberland eG"
    },
    {
      "label": "Raiffeisenbank im Oberpfälzer Jura eG",
      "value": "Raiffeisenbank im Oberpfälzer Jura eG"
    },
    {
      "label": "Raiffeisenbank Isar-Loisachtal eG",
      "value": "Raiffeisenbank Isar-Loisachtal eG"
    },
    {
      "label": "Raiffeisenbank Kaarst eG",
      "value": "Raiffeisenbank Kaarst eG"
    },
    {
      "label": "Raiffeisenbank Kaiserstuhl eG",
      "value": "Raiffeisenbank Kaiserstuhl eG"
    },
    {
      "label": "Raiffeisenbank Kalbe-Bismark eG",
      "value": "Raiffeisenbank Kalbe-Bismark eG"
    },
    {
      "label": "Raiffeisenbank Kastellaun eG",
      "value": "Raiffeisenbank Kastellaun eG"
    },
    {
      "label": "Raiffeisenbank Kempten-Oberallgäu eG",
      "value": "Raiffeisenbank Kempten-Oberallgäu eG"
    },
    {
      "label": "Raiffeisenbank Kieselbronn eG",
      "value": "Raiffeisenbank Kieselbronn eG"
    },
    {
      "label": "Raiffeisenbank Kirchweihtal eG",
      "value": "Raiffeisenbank Kirchweihtal eG"
    },
    {
      "label": "Raiffeisenbank Kirtorf eG",
      "value": "Raiffeisenbank Kirtorf eG"
    },
    {
      "label": "Raiffeisenbank Knoblauchsland eG",
      "value": "Raiffeisenbank Knoblauchsland eG"
    },
    {
      "label": "Raiffeisenbank Kreis Kelheim eG",
      "value": "Raiffeisenbank Kreis Kelheim eG"
    },
    {
      "label": "Raiffeisenbank Küps-Mitwitz-Stockheim eG",
      "value": "Raiffeisenbank Küps-Mitwitz-Stockheim eG"
    },
    {
      "label": "Raiffeisenbank Landshuter Land eG",
      "value": "Raiffeisenbank Landshuter Land eG"
    },
    {
      "label": "Raiffeisenbank Lechrain eG",
      "value": "Raiffeisenbank Lechrain eG"
    },
    {
      "label": "Raiffeisenbank Lorup eG",
      "value": "Raiffeisenbank Lorup eG"
    },
    {
      "label": "Raiffeisenbank Main-Spessart eG",
      "value": "Raiffeisenbank Main-Spessart eG"
    },
    {
      "label": "Raiffeisenbank Mainschleife - Steigerwald eG",
      "value": "Raiffeisenbank Mainschleife - Steigerwald eG"
    },
    {
      "label": "Raiffeisenbank Maitis eG",
      "value": "Raiffeisenbank Maitis eG"
    },
    {
      "label": "Raiffeisenbank Maßbach eG",
      "value": "Raiffeisenbank Maßbach eG"
    },
    {
      "label": "Raiffeisenbank Mecklenburger Seenplatte eG",
      "value": "Raiffeisenbank Mecklenburger Seenplatte eG"
    },
    {
      "label": "Raiffeisenbank Mehring-Leiwen eG",
      "value": "Raiffeisenbank Mehring-Leiwen eG"
    },
    {
      "label": "Raiffeisenbank Mittelschwaben eG",
      "value": "Raiffeisenbank Mittelschwaben eG"
    },
    {
      "label": "Raiffeisenbank Mittenwald eG",
      "value": "Raiffeisenbank Mittenwald eG"
    },
    {
      "label": "Raiffeisenbank Moselkrampen eG",
      "value": "Raiffeisenbank Moselkrampen eG"
    },
    {
      "label": "Raiffeisenbank Mötzingen eG Mötzingen - Iselshausen",
      "value": "Raiffeisenbank Mötzingen eG Mötzingen - Iselshausen"
    },
    {
      "label": "Raiffeisenbank München-Nord eG",
      "value": "Raiffeisenbank München-Nord eG"
    },
    {
      "label": "Raiffeisenbank München-Süd eG",
      "value": "Raiffeisenbank München-Süd eG"
    },
    {
      "label": "Raiffeisenbank Neumarkt i.d.OPf. eG",
      "value": "Raiffeisenbank Neumarkt i.d.OPf. eG"
    },
    {
      "label": "Raiffeisenbank Neumarkt-St. Veit - Reischach eG",
      "value": "Raiffeisenbank Neumarkt-St. Veit - Reischach eG"
    },
    {
      "label": "Raiffeisenbank Neustadt eG",
      "value": "Raiffeisenbank Neustadt eG"
    },
    {
      "label": "Raiffeisenbank Neustadt-Vohenstrauß eG",
      "value": "Raiffeisenbank Neustadt-Vohenstrauß eG"
    },
    {
      "label": "Raiffeisenbank Niedere Alb eG",
      "value": "Raiffeisenbank Niedere Alb eG"
    },
    {
      "label": "Raiffeisenbank Nördliche Bergstraße eG",
      "value": "Raiffeisenbank Nördliche Bergstraße eG"
    },
    {
      "label": "Raiffeisenbank Nüdlingen eG",
      "value": "Raiffeisenbank Nüdlingen eG"
    },
    {
      "label": "Raiffeisenbank Oberaudorf eG",
      "value": "Raiffeisenbank Oberaudorf eG"
    },
    {
      "label": "Raiffeisenbank Oberes Gäu eG",
      "value": "Raiffeisenbank Oberes Gäu eG"
    },
    {
      "label": "Raiffeisenbank Oberferrieden-Burgthann eG",
      "value": "Raiffeisenbank Oberferrieden-Burgthann eG"
    },
    {
      "label": "Raiffeisenbank Oberland eG",
      "value": "Raiffeisenbank Oberland eG"
    },
    {
      "label": "Raiffeisenbank Obermain Nord eG",
      "value": "Raiffeisenbank Obermain Nord eG"
    },
    {
      "label": "Raiffeisenbank Oberpfalz NordWest eG",
      "value": "Raiffeisenbank Oberpfalz NordWest eG"
    },
    {
      "label": "Raiffeisenbank Oberpfalz Süd eG",
      "value": "Raiffeisenbank Oberpfalz Süd eG"
    },
    {
      "label": "Raiffeisenbank Oberteuringen-Meckenbeuren eG",
      "value": "Raiffeisenbank Oberteuringen-Meckenbeuren eG"
    },
    {
      "label": "Raiffeisenbank Ortenburg - Kirchberg v.W. eG",
      "value": "Raiffeisenbank Ortenburg - Kirchberg v.W. eG"
    },
    {
      "label": "Raiffeisenbank Ostprignitz-Ruppin eG",
      "value": "Raiffeisenbank Ostprignitz-Ruppin eG"
    },
    {
      "label": "Raiffeisenbank Ottenbach eG",
      "value": "Raiffeisenbank Ottenbach eG"
    },
    {
      "label": "Raiffeisenbank Parkstetten eG",
      "value": "Raiffeisenbank Parkstetten eG"
    },
    {
      "label": "Raiffeisenbank Pfaffenhausen eG",
      "value": "Raiffeisenbank Pfaffenhausen eG"
    },
    {
      "label": "Raiffeisenbank Pfaffenhofen a.d. Glonn eG",
      "value": "Raiffeisenbank Pfaffenhofen a.d. Glonn eG"
    },
    {
      "label": "Raiffeisenbank Pfaffenwinkel eG",
      "value": "Raiffeisenbank Pfaffenwinkel eG"
    },
    {
      "label": "Raiffeisenbank Plankstetten AG",
      "value": "Raiffeisenbank Plankstetten AG"
    },
    {
      "label": "Raiffeisenbank Raisting eG",
      "value": "Raiffeisenbank Raisting eG"
    },
    {
      "label": "Raiffeisenbank Rastede eG",
      "value": "Raiffeisenbank Rastede eG"
    },
    {
      "label": "Raiffeisenbank Rattiszell-Konzell eG",
      "value": "Raiffeisenbank Rattiszell-Konzell eG"
    },
    {
      "label": "Raiffeisenbank Regensburg-Wenzenbach eG",
      "value": "Raiffeisenbank Regensburg-Wenzenbach eG"
    },
    {
      "label": "Raiffeisenbank Regenstauf eG",
      "value": "Raiffeisenbank Regenstauf eG"
    },
    {
      "label": "Raiffeisenbank Rehling eG",
      "value": "Raiffeisenbank Rehling eG"
    },
    {
      "label": "Raiffeisenbank Reute-Gaisbeuren eG",
      "value": "Raiffeisenbank Reute-Gaisbeuren eG"
    },
    {
      "label": "Raiffeisenbank Ried eG",
      "value": "Raiffeisenbank Ried eG"
    },
    {
      "label": "Raiffeisenbank Rosenstein eG",
      "value": "Raiffeisenbank Rosenstein eG"
    },
    {
      "label": "Raiffeisenbank Rupertiwinkel eG",
      "value": "Raiffeisenbank Rupertiwinkel eG"
    },
    {
      "label": "Raiffeisenbank Schaafheim eG",
      "value": "Raiffeisenbank Schaafheim eG"
    },
    {
      "label": "Raiffeisenbank Schrobenhausener Land eG",
      "value": "Raiffeisenbank Schrobenhausener Land eG"
    },
    {
      "label": "Raiffeisenbank Schrozberg-Rot am See eG",
      "value": "Raiffeisenbank Schrozberg-Rot am See eG"
    },
    {
      "label": "Raiffeisenbank Schwaben Mitte eG",
      "value": "Raiffeisenbank Schwaben Mitte eG"
    },
    {
      "label": "Raiffeisenbank Schwabmünchen-Stauden eG",
      "value": "Raiffeisenbank Schwabmünchen-Stauden eG"
    },
    {
      "label": "Raiffeisenbank Singoldtal eG",
      "value": "Raiffeisenbank Singoldtal eG"
    },
    {
      "label": "Raiffeisenbank Sinzing eG",
      "value": "Raiffeisenbank Sinzing eG"
    },
    {
      "label": "Raiffeisenbank Sondelfingen eG",
      "value": "Raiffeisenbank Sondelfingen eG"
    },
    {
      "label": "Raiffeisenbank Steingaden eG",
      "value": "Raiffeisenbank Steingaden eG"
    },
    {
      "label": "Raiffeisenbank Steinheim eG",
      "value": "Raiffeisenbank Steinheim eG"
    },
    {
      "label": "Raiffeisenbank Straubing eG",
      "value": "Raiffeisenbank Straubing eG"
    },
    {
      "label": "Raiffeisenbank Strücklingen-Idafehn eG",
      "value": "Raiffeisenbank Strücklingen-Idafehn eG"
    },
    {
      "label": "Raiffeisenbank Südhardt eG",
      "value": "Raiffeisenbank Südhardt eG"
    },
    {
      "label": "Raiffeisenbank Südliches Ostallgäu eG",
      "value": "Raiffeisenbank Südliches Ostallgäu eG"
    },
    {
      "label": "Raiffeisenbank Südstormarn Mölln eG",
      "value": "Raiffeisenbank Südstormarn Mölln eG"
    },
    {
      "label": "Raiffeisenbank Taufkirchen-Oberneukirchen eG",
      "value": "Raiffeisenbank Taufkirchen-Oberneukirchen eG"
    },
    {
      "label": "Raiffeisenbank Thannhausen eG",
      "value": "Raiffeisenbank Thannhausen eG"
    },
    {
      "label": "Raiffeisenbank Thurnauer Land eG",
      "value": "Raiffeisenbank Thurnauer Land eG"
    },
    {
      "label": "Raiffeisenbank Tüngental eG",
      "value": "Raiffeisenbank Tüngental eG"
    },
    {
      "label": "Raiffeisenbank Türkheim eG",
      "value": "Raiffeisenbank Türkheim eG"
    },
    {
      "label": "Raiffeisenbank Uehlfeld-Dachsbach eG",
      "value": "Raiffeisenbank Uehlfeld-Dachsbach eG"
    },
    {
      "label": "Raiffeisenbank Unteres Inntal eG",
      "value": "Raiffeisenbank Unteres Inntal eG"
    },
    {
      "label": "Raiffeisenbank Unteres Vilstal eG",
      "value": "Raiffeisenbank Unteres Vilstal eG"
    },
    {
      "label": "Raiffeisenbank Unteres Zusamtal eG",
      "value": "Raiffeisenbank Unteres Zusamtal eG"
    },
    {
      "label": "Raiffeisenbank Voreifel eG",
      "value": "Raiffeisenbank Voreifel eG"
    },
    {
      "label": "Raiffeisenbank Wald-Görisried eG",
      "value": "Raiffeisenbank Wald-Görisried eG"
    },
    {
      "label": "Raiffeisenbank Waldaschaff-Heigenbrücken eG",
      "value": "Raiffeisenbank Waldaschaff-Heigenbrücken eG"
    },
    {
      "label": "Raiffeisenbank Wallgau-Krün eG",
      "value": "Raiffeisenbank Wallgau-Krün eG"
    },
    {
      "label": "Raiffeisenbank Wangen eG",
      "value": "Raiffeisenbank Wangen eG"
    },
    {
      "label": "Raiffeisenbank Wegscheid eG",
      "value": "Raiffeisenbank Wegscheid eG"
    },
    {
      "label": "Raiffeisenbank Weißenburg-Gunzenhausen eG",
      "value": "Raiffeisenbank Weißenburg-Gunzenhausen eG"
    },
    {
      "label": "RAIFFEISENBANK WELLING eG",
      "value": "RAIFFEISENBANK WELLING eG"
    },
    {
      "label": "Raiffeisenbank Werratal-Landeck eG",
      "value": "Raiffeisenbank Werratal-Landeck eG"
    },
    {
      "label": "Raiffeisenbank Wesermarsch-Süd eG",
      "value": "Raiffeisenbank Wesermarsch-Süd eG"
    },
    {
      "label": "Raiffeisenbank Westallgäu eG",
      "value": "Raiffeisenbank Westallgäu eG"
    },
    {
      "label": "Raiffeisenbank Westeifel eG",
      "value": "Raiffeisenbank Westeifel eG"
    },
    {
      "label": "Raiffeisenbank Westhausen eG",
      "value": "Raiffeisenbank Westhausen eG"
    },
    {
      "label": "Raiffeisenbank Westkreis Fürstenfeldbruck eG",
      "value": "Raiffeisenbank Westkreis Fürstenfeldbruck eG"
    },
    {
      "label": "Raiffeisenbank Wiesedermeer-Wiesede-Marcardsmoor eG",
      "value": "Raiffeisenbank Wiesedermeer-Wiesede-Marcardsmoor eG"
    },
    {
      "label": "Raiffeisenbank Wimsheim-Mönsheim eG",
      "value": "Raiffeisenbank Wimsheim-Mönsheim eG"
    },
    {
      "label": "Raiffeisenbank Wittelsbacher Land eG",
      "value": "Raiffeisenbank Wittelsbacher Land eG"
    },
    {
      "label": "Raiffeisenbank Wyhl eG",
      "value": "Raiffeisenbank Wyhl eG"
    },
    {
      "label": "Raiffeisenbank Wüstenselbitz eG",
      "value": "Raiffeisenbank Wüstenselbitz eG"
    },
    {
      "label": "Raiffeisenbank Zeller Land eG",
      "value": "Raiffeisenbank Zeller Land eG"
    },
    {
      "label": "Raiffeisenlandesbank Oberösterreich Aktiengesellschaft Zweigniederlassung Süddeutschland",
      "value": "Raiffeisenlandesbank Oberösterreich Aktiengesellschaft Zweigniederlassung Süddeutschland"
    },
    {
      "label": "Raiffeisenverband Salzburg eGen Repräsentanz",
      "value": "Raiffeisenverband Salzburg eGen Repräsentanz"
    },
    {
      "label": "Raisin Bank AG",
      "value": "Raisin Bank AG"
    },
    {
      "label": "Ralf Goetsch - Finanz-Management GmbH",
      "value": "Ralf Goetsch - Finanz-Management GmbH"
    },
    {
      "label": "Rantum Advisors GmbH",
      "value": "Rantum Advisors GmbH"
    },
    {
      "label": "Rantum Capital Management GmbH",
      "value": "Rantum Capital Management GmbH"
    },
    {
      "label": "Ratepay GmbH",
      "value": "Ratepay GmbH"
    },
    {
      "label": "Rational Leasing GmbH",
      "value": "Rational Leasing GmbH"
    },
    {
      "label": "Ratisbona Anlagenleasing GmbH & Co. KG",
      "value": "Ratisbona Anlagenleasing GmbH & Co. KG"
    },
    {
      "label": "Rauscher & Kind Vermögensbetreuung GmbH",
      "value": "Rauscher & Kind Vermögensbetreuung GmbH"
    },
    {
      "label": "RAYMOND JAMES CORPORATE FINANCE GmbH",
      "value": "RAYMOND JAMES CORPORATE FINANCE GmbH"
    },
    {
      "label": "RBC Capital Markets (Europe) GmbH",
      "value": "RBC Capital Markets (Europe) GmbH"
    },
    {
      "label": "RBV GmbH",
      "value": "RBV GmbH"
    },
    {
      "label": "RCI Banque S.A. Niederlassung Deutschland",
      "value": "RCI Banque S.A. Niederlassung Deutschland"
    },
    {
      "label": "RDS RettungsDienstlogistik und Service GmbH",
      "value": "RDS RettungsDienstlogistik und Service GmbH"
    },
    {
      "label": "Real Blue Kapitalverwaltungs-GmbH",
      "value": "Real Blue Kapitalverwaltungs-GmbH"
    },
    {
      "label": "Real Exchange AG",
      "value": "Real Exchange AG"
    },
    {
      "label": "Real Garant Versicherung Aktiengesellschaft",
      "value": "Real Garant Versicherung Aktiengesellschaft"
    },
    {
      "label": "Real I.S. AG Gesellschaft für Immobilien Assetmanagement",
      "value": "Real I.S. AG Gesellschaft für Immobilien Assetmanagement"
    },
    {
      "label": "Real I.S. Investment GmbH",
      "value": "Real I.S. Investment GmbH"
    },
    {
      "label": "realtrade GmbH",
      "value": "realtrade GmbH"
    },
    {
      "label": "Rechenmacher GmbH & Co. KG",
      "value": "Rechenmacher GmbH & Co. KG"
    },
    {
      "label": "Record Asset Management GmbH",
      "value": "Record Asset Management GmbH"
    },
    {
      "label": "Redstone Management GmbH",
      "value": "Redstone Management GmbH"
    },
    {
      "label": "ReGa Beteiligungs GmbH & Co. KG",
      "value": "ReGa Beteiligungs GmbH & Co. KG"
    },
    {
      "label": "REGEDA GmbH Autoleasing + Autovermietung + Autohandel",
      "value": "REGEDA GmbH Autoleasing + Autovermietung + Autohandel"
    },
    {
      "label": "Regulatory Services GmbH",
      "value": "Regulatory Services GmbH"
    },
    {
      "label": "rehaVital Gesundheitsservice GmbH",
      "value": "rehaVital Gesundheitsservice GmbH"
    },
    {
      "label": "Reich, Doeker & Kollegen AG",
      "value": "Reich, Doeker & Kollegen AG"
    },
    {
      "label": "Reichmuth & Co. Integrale Vermögensverwaltung AG",
      "value": "Reichmuth & Co. Integrale Vermögensverwaltung AG"
    },
    {
      "label": "Reimann Investors Asset Management GmbH",
      "value": "Reimann Investors Asset Management GmbH"
    },
    {
      "label": "Reimann Investors Venture Management GmbH",
      "value": "Reimann Investors Venture Management GmbH"
    },
    {
      "label": "Reimann Investors Vermögensbetreuung GmbH",
      "value": "Reimann Investors Vermögensbetreuung GmbH"
    },
    {
      "label": "Reisebank AG",
      "value": "Reisebank AG"
    },
    {
      "label": "Reitelshöfer Vermögensmanagement GmbH",
      "value": "Reitelshöfer Vermögensmanagement GmbH"
    },
    {
      "label": "REKODA Verwaltungs-GmbH",
      "value": "REKODA Verwaltungs-GmbH"
    },
    {
      "label": "REMOBIS Refund Service C.V. Niederlassung Deutschland",
      "value": "REMOBIS Refund Service C.V. Niederlassung Deutschland"
    },
    {
      "label": "Renell Wertpapierhandelsbank AG",
      "value": "Renell Wertpapierhandelsbank AG"
    },
    {
      "label": "rent2buy leasing GmbH",
      "value": "rent2buy leasing GmbH"
    },
    {
      "label": "Renta Leasing GmbH",
      "value": "Renta Leasing GmbH"
    },
    {
      "label": "Renten-Zuschuß-Kasse des Norddeutschen Lloyd",
      "value": "Renten-Zuschuß-Kasse des Norddeutschen Lloyd"
    },
    {
      "label": "Rentenzuschusskasse der N-ERGIE Aktiengesellschaft Nürnberg",
      "value": "Rentenzuschusskasse der N-ERGIE Aktiengesellschaft Nürnberg"
    },
    {
      "label": "Renz Portfolio GmbH",
      "value": "Renz Portfolio GmbH"
    },
    {
      "label": "Repräsentanz Banco Espirito Santo de Investimento S.A.",
      "value": "Repräsentanz Banco Espirito Santo de Investimento S.A."
    },
    {
      "label": "Repräsentanz der Export Development Canada",
      "value": "Repräsentanz der Export Development Canada"
    },
    {
      "label": "Repräsentanz der offenen Aktiengesellschaft Sberbank Russlands in der Bundesrepublik Deutschland",
      "value": "Repräsentanz der offenen Aktiengesellschaft Sberbank Russlands in der Bundesrepublik Deutschland"
    },
    {
      "label": "Repräsentanz Millennium bcp",
      "value": "Repräsentanz Millennium bcp"
    },
    {
      "label": "Resonanz Capital GmbH",
      "value": "Resonanz Capital GmbH"
    },
    {
      "label": "Rethink Manager GmbH",
      "value": "Rethink Manager GmbH"
    },
    {
      "label": "Reuss Private Bank für Wertpapierhandel AG",
      "value": "Reuss Private Bank für Wertpapierhandel AG"
    },
    {
      "label": "Revent Capital GmbH",
      "value": "Revent Capital GmbH"
    },
    {
      "label": "Revesta GmbH",
      "value": "Revesta GmbH"
    },
    {
      "label": "REVIUM Rückversicherung AG",
      "value": "REVIUM Rückversicherung AG"
    },
    {
      "label": "Revolut Bank UAB Zweigniederlassung Deutschland",
      "value": "Revolut Bank UAB Zweigniederlassung Deutschland"
    },
    {
      "label": "REWE-ZENTRALFINANZ eG",
      "value": "REWE-ZENTRALFINANZ eG"
    },
    {
      "label": "Rezeptprüfstelle Duderstadt GmbH",
      "value": "Rezeptprüfstelle Duderstadt GmbH"
    },
    {
      "label": "rfhm GmbH",
      "value": "rfhm GmbH"
    },
    {
      "label": "RGA International Reinsurance Company Designated Activity Company, Niederlassung für Deutschland",
      "value": "RGA International Reinsurance Company Designated Activity Company, Niederlassung für Deutschland"
    },
    {
      "label": "RHD Rechnungsstelle AG",
      "value": "RHD Rechnungsstelle AG"
    },
    {
      "label": "Rhein Asset Management (Lux) S.A. Niederlassung Düsseldorf",
      "value": "Rhein Asset Management (Lux) S.A. Niederlassung Düsseldorf"
    },
    {
      "label": "Rheinboldt and Hungerhoff New Fund Management GmbH",
      "value": "Rheinboldt and Hungerhoff New Fund Management GmbH"
    },
    {
      "label": "Rheingau Founders GmbH",
      "value": "Rheingau Founders GmbH"
    },
    {
      "label": "Rheingau Management GmbH",
      "value": "Rheingau Management GmbH"
    },
    {
      "label": "Rheingauer Volksbank eingetragene Genossenschaft",
      "value": "Rheingauer Volksbank eingetragene Genossenschaft"
    },
    {
      "label": "Rheinhessen Sparkasse",
      "value": "Rheinhessen Sparkasse"
    },
    {
      "label": "Rheinische Pensionskasse",
      "value": "Rheinische Pensionskasse"
    },
    {
      "label": "RheinLand Versicherungs Aktiengesellschaft",
      "value": "RheinLand Versicherungs Aktiengesellschaft"
    },
    {
      "label": "Rhema Leasing GmbH & Co. KG",
      "value": "Rhema Leasing GmbH & Co. KG"
    },
    {
      "label": "rhenag Rheinische Energie Aktiengesellschaft",
      "value": "rhenag Rheinische Energie Aktiengesellschaft"
    },
    {
      "label": "Rhion Versicherung Aktiengesellschaft",
      "value": "Rhion Versicherung Aktiengesellschaft"
    },
    {
      "label": "RHOTHAM Vermögensverwaltungsgesellschaft Deutschland mbH",
      "value": "RHOTHAM Vermögensverwaltungsgesellschaft Deutschland mbH"
    },
    {
      "label": "RIA Deutschland GmbH",
      "value": "RIA Deutschland GmbH"
    },
    {
      "label": "Ries Rechtsanwaltsgesellschaft mbH",
      "value": "Ries Rechtsanwaltsgesellschaft mbH"
    },
    {
      "label": "Rigeto Unternehmerkapital GmbH",
      "value": "Rigeto Unternehmerkapital GmbH"
    },
    {
      "label": "Ringelstein und Partner Vermögensbetreuung GmbH",
      "value": "Ringelstein und Partner Vermögensbetreuung GmbH"
    },
    {
      "label": "RISICOM Rückversicherung Aktiengesellschaft",
      "value": "RISICOM Rückversicherung Aktiengesellschaft"
    },
    {
      "label": "Ritterschaftliches Kreditinstitut Stade Aktiengesellschaft",
      "value": "Ritterschaftliches Kreditinstitut Stade Aktiengesellschaft"
    },
    {
      "label": "RiverBank S.A. - German Branch",
      "value": "RiverBank S.A. - German Branch"
    },
    {
      "label": "Riverty GmbH",
      "value": "Riverty GmbH"
    },
    {
      "label": "Roadrunner Service GmbH",
      "value": "Roadrunner Service GmbH"
    },
    {
      "label": "Robeco Deutschland, Zweigniederlassung der Robeco Institutional Asset Management B.V.",
      "value": "Robeco Deutschland, Zweigniederlassung der Robeco Institutional Asset Management B.V."
    },
    {
      "label": "Robert Beer Management GmbH",
      "value": "Robert Beer Management GmbH"
    },
    {
      "label": "Robert Elster Family Office & Vermögensverwaltung GmbH",
      "value": "Robert Elster Family Office & Vermögensverwaltung GmbH"
    },
    {
      "label": "Robert Wolf GmbH",
      "value": "Robert Wolf GmbH"
    },
    {
      "label": "RoboMarkets Deutschland GmbH",
      "value": "RoboMarkets Deutschland GmbH"
    },
    {
      "label": "Robus Capital Management GmbH",
      "value": "Robus Capital Management GmbH"
    },
    {
      "label": "Rohse Vermögensmanagement GmbH",
      "value": "Rohse Vermögensmanagement GmbH"
    },
    {
      "label": "ROLAND Rechtsschutz-Versicherungs-AG",
      "value": "ROLAND Rechtsschutz-Versicherungs-AG"
    },
    {
      "label": "ROLAND Schutzbrief-Versicherung Aktiengesellschaft",
      "value": "ROLAND Schutzbrief-Versicherung Aktiengesellschaft"
    },
    {
      "label": "Rosbacher Raiffeisenbank eingetragene Genossenschaft",
      "value": "Rosbacher Raiffeisenbank eingetragene Genossenschaft"
    },
    {
      "label": "ROSENBERGER, LANGER & Cie. CAPITAL MANAGEMENT GMBH",
      "value": "ROSENBERGER, LANGER & Cie. CAPITAL MANAGEMENT GMBH"
    },
    {
      "label": "Rothschild & Co Vermögensverwaltung GmbH",
      "value": "Rothschild & Co Vermögensverwaltung GmbH"
    },
    {
      "label": "Rottaler Raiffeisenbank eG",
      "value": "Rottaler Raiffeisenbank eG"
    },
    {
      "label": "Rouvier Associés S.A.S. Niederlassung Deutschland",
      "value": "Rouvier Associés S.A.S. Niederlassung Deutschland"
    },
    {
      "label": "RP Rheinische Portfolio Management GmbH",
      "value": "RP Rheinische Portfolio Management GmbH"
    },
    {
      "label": "RS Vermögensmanagement GmbH",
      "value": "RS Vermögensmanagement GmbH"
    },
    {
      "label": "RS2 Financial Services GmbH",
      "value": "RS2 Financial Services GmbH"
    },
    {
      "label": "RSA Luxembourg S.A. Niederlassung für Deutschland",
      "value": "RSA Luxembourg S.A. Niederlassung für Deutschland"
    },
    {
      "label": "RSB Geld- und Wertpapierhandels GmbH",
      "value": "RSB Geld- und Wertpapierhandels GmbH"
    },
    {
      "label": "RSB Retail + Service Bank GmbH",
      "value": "RSB Retail + Service Bank GmbH"
    },
    {
      "label": "Rudolf Geith",
      "value": "Rudolf Geith"
    },
    {
      "label": "Ruhegeld-, Witwen- und Waisenkasse d. Bergischen Elektrizitäts-Versorgungs-GmbH (VVaG)",
      "value": "Ruhegeld-, Witwen- und Waisenkasse d. Bergischen Elektrizitäts-Versorgungs-GmbH (VVaG)"
    },
    {
      "label": "Ruhegeldkasse der Bremer Straßenbahn (VVaG)",
      "value": "Ruhegeldkasse der Bremer Straßenbahn (VVaG)"
    },
    {
      "label": "Runte. Weil & Alt GmbH",
      "value": "Runte. Weil & Alt GmbH"
    },
    {
      "label": "Russmann Leasing GmbH & Co. KG",
      "value": "Russmann Leasing GmbH & Co. KG"
    },
    {
      "label": "RWB PrivateCapital Emissionshaus AG",
      "value": "RWB PrivateCapital Emissionshaus AG"
    },
    {
      "label": "RWE Pensionsfonds AG",
      "value": "RWE Pensionsfonds AG"
    },
    {
      "label": "RWE Trading Services GmbH",
      "value": "RWE Trading Services GmbH"
    },
    {
      "label": "RWE Venture Capital GmbH",
      "value": "RWE Venture Capital GmbH"
    },
    {
      "label": "RZH Rechenzentrum für Heilberufe GmbH",
      "value": "RZH Rechenzentrum für Heilberufe GmbH"
    },
    {
      "label": "Röchling Mannheim GmbH",
      "value": "Röchling Mannheim GmbH"
    },
    {
      "label": "S Broker AG & Co. KG",
      "value": "S Broker AG & Co. KG"
    },
    {
      "label": "S DirektVersicherung Aktiengesellschaft",
      "value": "S DirektVersicherung Aktiengesellschaft"
    },
    {
      "label": "S&F Süddeutsche Factoring GmbH & Co. KG",
      "value": "S&F Süddeutsche Factoring GmbH & Co. KG"
    },
    {
      "label": "S-Factoring GmbH",
      "value": "S-Factoring GmbH"
    },
    {
      "label": "S-International Baden-Württemberg Nord GmbH & Co. KG",
      "value": "S-International Baden-Württemberg Nord GmbH & Co. KG"
    },
    {
      "label": "S-International Business GmbH + Co. KG",
      "value": "S-International Business GmbH + Co. KG"
    },
    {
      "label": "S-International Nordbayern GmbH & Co. KG",
      "value": "S-International Nordbayern GmbH & Co. KG"
    },
    {
      "label": "S-International Schleswig-Holstein GmbH & Co. KG",
      "value": "S-International Schleswig-Holstein GmbH & Co. KG"
    },
    {
      "label": "S-Kreditpartner GmbH",
      "value": "S-Kreditpartner GmbH"
    },
    {
      "label": "S-UBG Aktiengesellschaft Unternehmensbeteiligungsgesellschaft für die Regionen Aachen, Krefeld und Mönchengladbach",
      "value": "S-UBG Aktiengesellschaft Unternehmensbeteiligungsgesellschaft für die Regionen Aachen, Krefeld und Mönchengladbach"
    },
    {
      "label": "S.D.L. Süddeutsche Leasing AG",
      "value": "S.D.L. Süddeutsche Leasing AG"
    },
    {
      "label": "Saalesparkasse",
      "value": "Saalesparkasse"
    },
    {
      "label": "SAARLAND Feuerversicherung Aktiengesellschaft",
      "value": "SAARLAND Feuerversicherung Aktiengesellschaft"
    },
    {
      "label": "Saarländische Investitionskreditbank Aktiengesellschaft",
      "value": "Saarländische Investitionskreditbank Aktiengesellschaft"
    },
    {
      "label": "Saarländische Vermögensverwaltungsgesellschaft mbH",
      "value": "Saarländische Vermögensverwaltungsgesellschaft mbH"
    },
    {
      "label": "Sabine Heinemann PSS Abrechnungsbüro für Ärzte",
      "value": "Sabine Heinemann PSS Abrechnungsbüro für Ärzte"
    },
    {
      "label": "SACHWERTE - ST. MARTHA KAPITALVERWALTUNGSGESELLSCHAFT GmbH & Co. KG",
      "value": "SACHWERTE - ST. MARTHA KAPITALVERWALTUNGSGESELLSCHAFT GmbH & Co. KG"
    },
    {
      "label": "Sachwerte-Wohnbau I Kapitalverwaltungsgesellschaft GmbH & Co. KG",
      "value": "Sachwerte-Wohnbau I Kapitalverwaltungsgesellschaft GmbH & Co. KG"
    },
    {
      "label": "SACHWERTE-WOHNBAU II KAPITALVERWALTUNGSGESELLSCHAFT GmbH & Co. KG",
      "value": "SACHWERTE-WOHNBAU II KAPITALVERWALTUNGSGESELLSCHAFT GmbH & Co. KG"
    },
    {
      "label": "SACHWERTE-WOHNBAU III KAPITALVERWALTUNGSGESELLSCHAFT GmbH & Co. KG",
      "value": "SACHWERTE-WOHNBAU III KAPITALVERWALTUNGSGESELLSCHAFT GmbH & Co. KG"
    },
    {
      "label": "SACHWERTE-WOHNBAU IV KAPITALVERWALTUNGSGESELLSCHAFT GmbH & Co. KG",
      "value": "SACHWERTE-WOHNBAU IV KAPITALVERWALTUNGSGESELLSCHAFT GmbH & Co. KG"
    },
    {
      "label": "SagaFlor AG",
      "value": "SagaFlor AG"
    },
    {
      "label": "Salm-Salm & Partner GmbH",
      "value": "Salm-Salm & Partner GmbH"
    },
    {
      "label": "Salutaris Capital Management AG",
      "value": "Salutaris Capital Management AG"
    },
    {
      "label": "SALytic Invest AG",
      "value": "SALytic Invest AG"
    },
    {
      "label": "Salzlandsparkasse",
      "value": "Salzlandsparkasse"
    },
    {
      "label": "Saman Bank Niederlassung Frankfurt",
      "value": "Saman Bank Niederlassung Frankfurt"
    },
    {
      "label": "Sand und Schott GmbH",
      "value": "Sand und Schott GmbH"
    },
    {
      "label": "Sanford C. Bernstein Ireland Limited (Frankfurt Branch)",
      "value": "Sanford C. Bernstein Ireland Limited (Frankfurt Branch)"
    },
    {
      "label": "SANTANDER ASSET MANAGEMENT, S.A. SGIIC, German Branch",
      "value": "SANTANDER ASSET MANAGEMENT, S.A. SGIIC, German Branch"
    },
    {
      "label": "Santander Consumer Bank Aktiengesellschaft",
      "value": "Santander Consumer Bank Aktiengesellschaft"
    },
    {
      "label": "Santander Consumer Leasing GmbH",
      "value": "Santander Consumer Leasing GmbH"
    },
    {
      "label": "SATURIA GmbH Kapitalverwaltungsgesellschaft",
      "value": "SATURIA GmbH Kapitalverwaltungsgesellschaft"
    },
    {
      "label": "Sauren Finanzdienstleistungen GmbH & Co. KG",
      "value": "Sauren Finanzdienstleistungen GmbH & Co. KG"
    },
    {
      "label": "SAVI Marketing GmbH",
      "value": "SAVI Marketing GmbH"
    },
    {
      "label": "Savills Fund Management GmbH",
      "value": "Savills Fund Management GmbH"
    },
    {
      "label": "Savills Investment Management KVG GmbH",
      "value": "Savills Investment Management KVG GmbH"
    },
    {
      "label": "Sberbank Europe AG Zweigniederlassung Deutschland",
      "value": "Sberbank Europe AG Zweigniederlassung Deutschland"
    },
    {
      "label": "SCA Portfoliomanagement GmbH",
      "value": "SCA Portfoliomanagement GmbH"
    },
    {
      "label": "Scalable Capital GmbH",
      "value": "Scalable Capital GmbH"
    },
    {
      "label": "SCALEHOUSE Capital Management GmbH",
      "value": "SCALEHOUSE Capital Management GmbH"
    },
    {
      "label": "Scania Finance Deutschland GmbH",
      "value": "Scania Finance Deutschland GmbH"
    },
    {
      "label": "Schaan Investment GmbH",
      "value": "Schaan Investment GmbH"
    },
    {
      "label": "Schaefer Invest GmbH",
      "value": "Schaefer Invest GmbH"
    },
    {
      "label": "Scharnhauser Bank eG",
      "value": "Scharnhauser Bank eG"
    },
    {
      "label": "Scheufelen-Versorgungskasse Versicherungsverein a.G., Papierfabrik Scheufelen",
      "value": "Scheufelen-Versorgungskasse Versicherungsverein a.G., Papierfabrik Scheufelen"
    },
    {
      "label": "Schick-Leasing GmbH",
      "value": "Schick-Leasing GmbH"
    },
    {
      "label": "SchiLo GmbH",
      "value": "SchiLo GmbH"
    },
    {
      "label": "Schleber Finanz-Consult GmbH",
      "value": "Schleber Finanz-Consult GmbH"
    },
    {
      "label": "Schleswiger Versicherungsverein auf Gegenseitigkeit",
      "value": "Schleswiger Versicherungsverein auf Gegenseitigkeit"
    },
    {
      "label": "Schlöter, Reidock & Herntrich GmbH",
      "value": "Schlöter, Reidock & Herntrich GmbH"
    },
    {
      "label": "Schneider, Walter & Kollegen Vermögensverwaltung AG",
      "value": "Schneider, Walter & Kollegen Vermögensverwaltung AG"
    },
    {
      "label": "Schneidereit Finance GmbH",
      "value": "Schneidereit Finance GmbH"
    },
    {
      "label": "SCHNEVERDINGER Versicherungsverein a.G.",
      "value": "SCHNEVERDINGER Versicherungsverein a.G."
    },
    {
      "label": "Schoeller Wealth Management GmbH",
      "value": "Schoeller Wealth Management GmbH"
    },
    {
      "label": "Scholz und Partner GmbH",
      "value": "Scholz und Partner GmbH"
    },
    {
      "label": "Schrobenhausener Bank eG",
      "value": "Schrobenhausener Bank eG"
    },
    {
      "label": "Schroder Investment Management (Europe) S.A., German Branch",
      "value": "Schroder Investment Management (Europe) S.A., German Branch"
    },
    {
      "label": "Schroder Real Estate Kapitalverwaltungsgesellschaft mbH",
      "value": "Schroder Real Estate Kapitalverwaltungsgesellschaft mbH"
    },
    {
      "label": "Schröder Equities GmbH",
      "value": "Schröder Equities GmbH"
    },
    {
      "label": "Schuhmann & Cie. GmbH",
      "value": "Schuhmann & Cie. GmbH"
    },
    {
      "label": "Schumpeter Ventures GmbH",
      "value": "Schumpeter Ventures GmbH"
    },
    {
      "label": "Schutzverein Deutscher Rheder V.a.G.",
      "value": "Schutzverein Deutscher Rheder V.a.G."
    },
    {
      "label": "Schäfer Regensburger Vermögensmanagement GmbH & Co. KG",
      "value": "Schäfer Regensburger Vermögensmanagement GmbH & Co. KG"
    },
    {
      "label": "Schön & Co GmbH",
      "value": "Schön & Co GmbH"
    },
    {
      "label": "SCOR Europe Deutschland, Niederlassung der SCOR Europe SE",
      "value": "SCOR Europe Deutschland, Niederlassung der SCOR Europe SE"
    },
    {
      "label": "SCOR Rückversicherung Deutschland, Niederlassung der SCOR SE",
      "value": "SCOR Rückversicherung Deutschland, Niederlassung der SCOR SE"
    },
    {
      "label": "Scottish Widows Europe S.A. Zweigniederlassung Deutschland",
      "value": "Scottish Widows Europe S.A. Zweigniederlassung Deutschland"
    },
    {
      "label": "scyfyn AG",
      "value": "scyfyn AG"
    },
    {
      "label": "Seafort Advisors GmbH",
      "value": "Seafort Advisors GmbH"
    },
    {
      "label": "SECB Swiss Euro Clearing Bank GmbH",
      "value": "SECB Swiss Euro Clearing Bank GmbH"
    },
    {
      "label": "Sechster BIMAG Fonds GmbH & Co. KG",
      "value": "Sechster BIMAG Fonds GmbH & Co. KG"
    },
    {
      "label": "SECUPAY AG",
      "value": "SECUPAY AG"
    },
    {
      "label": "Seedamm-Finanzberatungs GmbH",
      "value": "Seedamm-Finanzberatungs GmbH"
    },
    {
      "label": "SeedCapital Dortmund GmbH & Co. KG",
      "value": "SeedCapital Dortmund GmbH & Co. KG"
    },
    {
      "label": "SeedCapital Dortmund II GmbH & Co. KG",
      "value": "SeedCapital Dortmund II GmbH & Co. KG"
    },
    {
      "label": "SeedCapital Dortmund III GmbH & Co. KG",
      "value": "SeedCapital Dortmund III GmbH & Co. KG"
    },
    {
      "label": "Segenia Capital Management GmbH",
      "value": "Segenia Capital Management GmbH"
    },
    {
      "label": "Selbsthilfe-Bauverein eG",
      "value": "Selbsthilfe-Bauverein eG"
    },
    {
      "label": "Selection Asset Management GmbH",
      "value": "Selection Asset Management GmbH"
    },
    {
      "label": "Selinus Capital Advisors GmbH",
      "value": "Selinus Capital Advisors GmbH"
    },
    {
      "label": "Selinus Capital GmbH",
      "value": "Selinus Capital GmbH"
    },
    {
      "label": "SENCO Hydrogen Capital GmbH",
      "value": "SENCO Hydrogen Capital GmbH"
    },
    {
      "label": "Senovo Capital Management GmbH",
      "value": "Senovo Capital Management GmbH"
    },
    {
      "label": "sentix Asset Management GmbH",
      "value": "sentix Asset Management GmbH"
    },
    {
      "label": "Seppelfricke & Co. Family Office AG",
      "value": "Seppelfricke & Co. Family Office AG"
    },
    {
      "label": "Serafin Asset Management GmbH",
      "value": "Serafin Asset Management GmbH"
    },
    {
      "label": "ServiceInvest Kapitalverwaltungsgesellschaft mbH",
      "value": "ServiceInvest Kapitalverwaltungsgesellschaft mbH"
    },
    {
      "label": "Severins GmbH",
      "value": "Severins GmbH"
    },
    {
      "label": "Sevest Management GmbH",
      "value": "Sevest Management GmbH"
    },
    {
      "label": "SFF Financial Services München Repräsentanz",
      "value": "SFF Financial Services München Repräsentanz"
    },
    {
      "label": "SGI Investments GmbH",
      "value": "SGI Investments GmbH"
    },
    {
      "label": "SGV Factoring GmbH",
      "value": "SGV Factoring GmbH"
    },
    {
      "label": "SHAM-Société Hospitalière d`Assurances Mutuelles Niederlassung Deutschland",
      "value": "SHAM-Société Hospitalière d`Assurances Mutuelles Niederlassung Deutschland"
    },
    {
      "label": "SHB Allgemeine Versicherung VVaG",
      "value": "SHB Allgemeine Versicherung VVaG"
    },
    {
      "label": "SHINHAN BANK EUROPE GmbH",
      "value": "SHINHAN BANK EUROPE GmbH"
    },
    {
      "label": "SHK Einkaufs- und Vertriebs AG",
      "value": "SHK Einkaufs- und Vertriebs AG"
    },
    {
      "label": "SHK Verwaltungs AG & Co. KG",
      "value": "SHK Verwaltungs AG & Co. KG"
    },
    {
      "label": "Shortcut Ventures GmbH",
      "value": "Shortcut Ventures GmbH"
    },
    {
      "label": "SHS Gesellschaft für Beteiligungsmanagement mbH",
      "value": "SHS Gesellschaft für Beteiligungsmanagement mbH"
    },
    {
      "label": "SI Insurance (Europe), SA Zweigniederlassung Deutschland",
      "value": "SI Insurance (Europe), SA Zweigniederlassung Deutschland"
    },
    {
      "label": "Siebendächer Baugenossenschaft eG",
      "value": "Siebendächer Baugenossenschaft eG"
    },
    {
      "label": "SieMatic Möbelwerke GmbH & Co. KG",
      "value": "SieMatic Möbelwerke GmbH & Co. KG"
    },
    {
      "label": "Siemens Bank GmbH",
      "value": "Siemens Bank GmbH"
    },
    {
      "label": "Siemens Finance & Leasing GmbH",
      "value": "Siemens Finance & Leasing GmbH"
    },
    {
      "label": "Siemens Fonds Invest GmbH",
      "value": "Siemens Fonds Invest GmbH"
    },
    {
      "label": "Siemens Global Innovation Partners I GmbH & Co. KG",
      "value": "Siemens Global Innovation Partners I GmbH & Co. KG"
    },
    {
      "label": "Siemens Pensionsfonds Aktiengesellschaft",
      "value": "Siemens Pensionsfonds Aktiengesellschaft"
    },
    {
      "label": "SIGAVEST Vermögensverwaltung GmbH",
      "value": "SIGAVEST Vermögensverwaltung GmbH"
    },
    {
      "label": "SIGNAL IDUNA Allgemeine Versicherung Aktiengesellschaft",
      "value": "SIGNAL IDUNA Allgemeine Versicherung Aktiengesellschaft"
    },
    {
      "label": "SIGNAL IDUNA Asset Management GmbH",
      "value": "SIGNAL IDUNA Asset Management GmbH"
    },
    {
      "label": "SIGNAL IDUNA Bauspar Aktiengesellschaft",
      "value": "SIGNAL IDUNA Bauspar Aktiengesellschaft"
    },
    {
      "label": "SIGNAL IDUNA Krankenversicherung a.G.",
      "value": "SIGNAL IDUNA Krankenversicherung a.G."
    },
    {
      "label": "SIGNAL IDUNA Lebensversicherung a. G.",
      "value": "SIGNAL IDUNA Lebensversicherung a. G."
    },
    {
      "label": "SIGNAL IDUNA Lebensversicherung AG",
      "value": "SIGNAL IDUNA Lebensversicherung AG"
    },
    {
      "label": "SIGNAL IDUNA Pensionskasse Aktiengesellschaft",
      "value": "SIGNAL IDUNA Pensionskasse Aktiengesellschaft"
    },
    {
      "label": "Signal Iduna Sterbekasse VVaG",
      "value": "Signal Iduna Sterbekasse VVaG"
    },
    {
      "label": "SIGNAL IDUNA Unfallversicherung a. G.",
      "value": "SIGNAL IDUNA Unfallversicherung a. G."
    },
    {
      "label": "Signals Venture Capital Management GmbH",
      "value": "Signals Venture Capital Management GmbH"
    },
    {
      "label": "Signature Ventures GmbH",
      "value": "Signature Ventures GmbH"
    },
    {
      "label": "siko-bernau-vermögensverwaltung GmbH",
      "value": "siko-bernau-vermögensverwaltung GmbH"
    },
    {
      "label": "Silicon Valley Access Fund I GmbH & Co. KG",
      "value": "Silicon Valley Access Fund I GmbH & Co. KG"
    },
    {
      "label": "Silicon Valley Bank Germany Branch",
      "value": "Silicon Valley Bank Germany Branch"
    },
    {
      "label": "Silver Art Collection GmbH & Co. KG",
      "value": "Silver Art Collection GmbH & Co. KG"
    },
    {
      "label": "Silver Investment Partners GmbH & Co. KG",
      "value": "Silver Investment Partners GmbH & Co. KG"
    },
    {
      "label": "Silver Investment Partners II GmbH & Co. KG",
      "value": "Silver Investment Partners II GmbH & Co. KG"
    },
    {
      "label": "Silver Investment Partners III GmbH & Co. KG",
      "value": "Silver Investment Partners III GmbH & Co. KG"
    },
    {
      "label": "Silverton Asset Solutions GmbH",
      "value": "Silverton Asset Solutions GmbH"
    },
    {
      "label": "Sineus Financial Services GmbH",
      "value": "Sineus Financial Services GmbH"
    },
    {
      "label": "sino Aktiengesellschaft",
      "value": "sino Aktiengesellschaft"
    },
    {
      "label": "Sirius Venture Partners GmbH",
      "value": "Sirius Venture Partners GmbH"
    },
    {
      "label": "SK Vermögensverwaltung GmbH",
      "value": "SK Vermögensverwaltung GmbH"
    },
    {
      "label": "SKAGEN AS Branch Germany",
      "value": "SKAGEN AS Branch Germany"
    },
    {
      "label": "Skandia Lebensversicherung Aktiengesellschaft",
      "value": "Skandia Lebensversicherung Aktiengesellschaft"
    },
    {
      "label": "Skandia PortfolioManagement GmbH",
      "value": "Skandia PortfolioManagement GmbH"
    },
    {
      "label": "Skandinaviska Enskilda Banken AB (publ) Frankfurt Branch",
      "value": "Skandinaviska Enskilda Banken AB (publ) Frankfurt Branch"
    },
    {
      "label": "SKY Harbor Capital Management GmbH",
      "value": "SKY Harbor Capital Management GmbH"
    },
    {
      "label": "Sky View GmbH",
      "value": "Sky View GmbH"
    },
    {
      "label": "SL Mobilien-Leasing GmbH & Co. Enercon KG",
      "value": "SL Mobilien-Leasing GmbH & Co. Enercon KG"
    },
    {
      "label": "Sladek Vermögensberatung GmbH",
      "value": "Sladek Vermögensberatung GmbH"
    },
    {
      "label": "Slesvigsk Kreditforening e.G.",
      "value": "Slesvigsk Kreditforening e.G."
    },
    {
      "label": "SLN Maschinen Leasing GmbH & Co. OHG",
      "value": "SLN Maschinen Leasing GmbH & Co. OHG"
    },
    {
      "label": "SLP Mobilien-Leasing GmbH & Co. oHG",
      "value": "SLP Mobilien-Leasing GmbH & Co. oHG"
    },
    {
      "label": "Slyce Management GmbH",
      "value": "Slyce Management GmbH"
    },
    {
      "label": "Small & Mid Cap Investmentbank AG",
      "value": "Small & Mid Cap Investmentbank AG"
    },
    {
      "label": "SMAPE Capital Fund Management GmbH",
      "value": "SMAPE Capital Fund Management GmbH"
    },
    {
      "label": "Smart A Capital I GmbH & Co. KG",
      "value": "Smart A Capital I GmbH & Co. KG"
    },
    {
      "label": "Smart Infrastructure Ventures GmbH",
      "value": "Smart Infrastructure Ventures GmbH"
    },
    {
      "label": "smart-invest GmbH",
      "value": "smart-invest GmbH"
    },
    {
      "label": "Smartbroker AG",
      "value": "Smartbroker AG"
    },
    {
      "label": "Smavesto GmbH",
      "value": "Smavesto GmbH"
    },
    {
      "label": "SMBC Bank EU AG",
      "value": "SMBC Bank EU AG"
    },
    {
      "label": "SMBC Leasing (UK) Limited (Niederlassung Frankfurt)",
      "value": "SMBC Leasing (UK) Limited (Niederlassung Frankfurt)"
    },
    {
      "label": "SMS & Cie. Vermögensmanagement GmbH",
      "value": "SMS & Cie. Vermögensmanagement GmbH"
    },
    {
      "label": "Snap-on Finance UK Ltd, German Branch",
      "value": "Snap-on Finance UK Ltd, German Branch"
    },
    {
      "label": "Sobera Capital GmbH",
      "value": "Sobera Capital GmbH"
    },
    {
      "label": "Social Lease GmbH",
      "value": "Social Lease GmbH"
    },
    {
      "label": "Societas Vermögensverwaltung GmbH",
      "value": "Societas Vermögensverwaltung GmbH"
    },
    {
      "label": "Société Générale Luxembourg, Repräsentanz",
      "value": "Société Générale Luxembourg, Repräsentanz"
    },
    {
      "label": "Société Générale S.A.",
      "value": "Société Générale S.A."
    },
    {
      "label": "Société Générale Securities Services GmbH",
      "value": "Société Générale Securities Services GmbH"
    },
    {
      "label": "Soennecken eG",
      "value": "Soennecken eG"
    },
    {
      "label": "SOFORT GmbH",
      "value": "SOFORT GmbH"
    },
    {
      "label": "SOGECAP S.A. Deutsche Niederlassung",
      "value": "SOGECAP S.A. Deutsche Niederlassung"
    },
    {
      "label": "SOGESSUR S.A. Deutsche Niederlassung",
      "value": "SOGESSUR S.A. Deutsche Niederlassung"
    },
    {
      "label": "SOINI ASSET IMMOBILIEN Europa I Fonds GmbH",
      "value": "SOINI ASSET IMMOBILIEN Europa I Fonds GmbH"
    },
    {
      "label": "solarisBank AG",
      "value": "solarisBank AG"
    },
    {
      "label": "Solid Investment GmbH",
      "value": "Solid Investment GmbH"
    },
    {
      "label": "SOLIDAR Versicherungsgemeinschaft Sterbegeldversicherung VVaG",
      "value": "SOLIDAR Versicherungsgemeinschaft Sterbegeldversicherung VVaG"
    },
    {
      "label": "Solidaritätsverein der Arbeitnehmerinnen und Arbeitnehmer im Gemeinschaftsbetrieb der Hamburger Hafen und Logistik Aktiengesellschaft von 1950",
      "value": "Solidaritätsverein der Arbeitnehmerinnen und Arbeitnehmer im Gemeinschaftsbetrieb der Hamburger Hafen und Logistik Aktiengesellschaft von 1950"
    },
    {
      "label": "SoliPrax GmbH",
      "value": "SoliPrax GmbH"
    },
    {
      "label": "SOLUTIO Fund Management GmbH & Co. KG",
      "value": "SOLUTIO Fund Management GmbH & Co. KG"
    },
    {
      "label": "Sonanz Management GmbH",
      "value": "Sonanz Management GmbH"
    },
    {
      "label": "Sonderb. f.d. öff.-r. LV.Anst. Brandenburg, Pommern, OEVA-Dresden,Sachs.-Thür.-Anh.",
      "value": "Sonderb. f.d. öff.-r. LV.Anst. Brandenburg, Pommern, OEVA-Dresden,Sachs.-Thür.-Anh."
    },
    {
      "label": "Sonderb. f.d. öff.-r. LV.Anst. Brandenburg, Pommern, OEVA-Dresden,Sachs.-Thür.-Anh.",
      "value": "Sonderb. f.d. öff.-r. LV.Anst. Brandenburg, Pommern, OEVA-Dresden,Sachs.-Thür.-Anh."
    },
    {
      "label": "Sonderb. f.d. öff.-r. LV.Anst. Brandenburg, Pommern, OEVA-Dresden,Sachs.-Thür.-Anh.",
      "value": "Sonderb. f.d. öff.-r. LV.Anst. Brandenburg, Pommern, OEVA-Dresden,Sachs.-Thür.-Anh."
    },
    {
      "label": "Sonderb. f.d. öff.-r.LV.Anst. Brandenburg, Pommern, OEVA-Dresden,Sachs.-Thür.-Anh.",
      "value": "Sonderb. f.d. öff.-r.LV.Anst. Brandenburg, Pommern, OEVA-Dresden,Sachs.-Thür.-Anh."
    },
    {
      "label": "SONNTAG Vermögensbetreuung GmbH",
      "value": "SONNTAG Vermögensbetreuung GmbH"
    },
    {
      "label": "SONO Krankenversicherung a.G.",
      "value": "SONO Krankenversicherung a.G."
    },
    {
      "label": "SONO Sterbegeld VVaG",
      "value": "SONO Sterbegeld VVaG"
    },
    {
      "label": "Source For Alpha AG",
      "value": "Source For Alpha AG"
    },
    {
      "label": "Spacewalk Management GmbH",
      "value": "Spacewalk Management GmbH"
    },
    {
      "label": "SPAR Inkassokontor GmbH & Co. KG",
      "value": "SPAR Inkassokontor GmbH & Co. KG"
    },
    {
      "label": "Spar- und Bauverein eG",
      "value": "Spar- und Bauverein eG"
    },
    {
      "label": "SPAR- UND BAUVEREIN EG",
      "value": "SPAR- UND BAUVEREIN EG"
    },
    {
      "label": "Spar- und Bauverein Konstanz eG",
      "value": "Spar- und Bauverein Konstanz eG"
    },
    {
      "label": "Spar- und Bauverein Solingen eG Gemeinnützige Wohnungsgenossenschaft",
      "value": "Spar- und Bauverein Solingen eG Gemeinnützige Wohnungsgenossenschaft"
    },
    {
      "label": "Spar- und Darlehnskasse Bockum-Hövel e.G.",
      "value": "Spar- und Darlehnskasse Bockum-Hövel e.G."
    },
    {
      "label": "Spar- und Darlehnskasse Börde Lamstedt-Hechthausen eG",
      "value": "Spar- und Darlehnskasse Börde Lamstedt-Hechthausen eG"
    },
    {
      "label": "Spar- und Kreditbank Bühlertal eG",
      "value": "Spar- und Kreditbank Bühlertal eG"
    },
    {
      "label": "Spar- und Kreditbank des Bundes Freier evangelischer Gemeinden eG",
      "value": "Spar- und Kreditbank des Bundes Freier evangelischer Gemeinden eG"
    },
    {
      "label": "Spar- und Kreditbank eG",
      "value": "Spar- und Kreditbank eG"
    },
    {
      "label": "Spar- und Kreditbank Rheinstetten eG",
      "value": "Spar- und Kreditbank Rheinstetten eG"
    },
    {
      "label": "Spar-u.Kredit-Bank eG",
      "value": "Spar-u.Kredit-Bank eG"
    },
    {
      "label": "Sparbank \"BELARUSBANK AG\" Repräsentanz in der Bundesrepublik Deutschland",
      "value": "Sparbank \"BELARUSBANK AG\" Repräsentanz in der Bundesrepublik Deutschland"
    },
    {
      "label": "Sparda-Bank Augsburg eG",
      "value": "Sparda-Bank Augsburg eG"
    },
    {
      "label": "Sparda-Bank Baden-Württemberg eG",
      "value": "Sparda-Bank Baden-Württemberg eG"
    },
    {
      "label": "Sparda-Bank Berlin eG",
      "value": "Sparda-Bank Berlin eG"
    },
    {
      "label": "SPARDA-BANK HAMBURG eG",
      "value": "SPARDA-BANK HAMBURG eG"
    },
    {
      "label": "Sparda-Bank Hannover eingetragene Genossenschaft",
      "value": "Sparda-Bank Hannover eingetragene Genossenschaft"
    },
    {
      "label": "Sparda-Bank Hessen eG",
      "value": "Sparda-Bank Hessen eG"
    },
    {
      "label": "Sparda-Bank München eingetragene Genossenschaft",
      "value": "Sparda-Bank München eingetragene Genossenschaft"
    },
    {
      "label": "SPARDA-BANK NÜRNBERG eG",
      "value": "SPARDA-BANK NÜRNBERG eG"
    },
    {
      "label": "Sparda-Bank Ostbayern eG",
      "value": "Sparda-Bank Ostbayern eG"
    },
    {
      "label": "Sparda-Bank Südwest eG",
      "value": "Sparda-Bank Südwest eG"
    },
    {
      "label": "Sparda-Bank West eG",
      "value": "Sparda-Bank West eG"
    },
    {
      "label": "Sparkasse Aachen",
      "value": "Sparkasse Aachen"
    },
    {
      "label": "Sparkasse Aichach-Schrobenhausen",
      "value": "Sparkasse Aichach-Schrobenhausen"
    },
    {
      "label": "Sparkasse Allgäu",
      "value": "Sparkasse Allgäu"
    },
    {
      "label": "Sparkasse Altenburger Land",
      "value": "Sparkasse Altenburger Land"
    },
    {
      "label": "Sparkasse Altmark West",
      "value": "Sparkasse Altmark West"
    },
    {
      "label": "Sparkasse Altötting-Mühldorf",
      "value": "Sparkasse Altötting-Mühldorf"
    },
    {
      "label": "Sparkasse am Niederrhein - Sparkasse des Kreises Wesel und der Städte Moers, Neukirchen- Vluyn und Rheinberg -",
      "value": "Sparkasse am Niederrhein - Sparkasse des Kreises Wesel und der Städte Moers, Neukirchen- Vluyn und Rheinberg -"
    },
    {
      "label": "Sparkasse Amberg-Sulzbach",
      "value": "Sparkasse Amberg-Sulzbach"
    },
    {
      "label": "Sparkasse an der Lippe, Zweckverbandssparkasse der Städte Lünen, Selm und Werne",
      "value": "Sparkasse an der Lippe, Zweckverbandssparkasse der Städte Lünen, Selm und Werne"
    },
    {
      "label": "Sparkasse an Ennepe und Ruhr - Zweckverbandssparkasse der Städte Gevelsberg, Ennepetal, Wetter (Ruhr) und Breckerfeld",
      "value": "Sparkasse an Ennepe und Ruhr - Zweckverbandssparkasse der Städte Gevelsberg, Ennepetal, Wetter (Ruhr) und Breckerfeld"
    },
    {
      "label": "Sparkasse an Volme und Ruhr - Zweckverbandssparkasse der Städte Hagen, Halver, Herdecke und Lüdenscheid sowie der Gemeinden Schalksmühle und Herscheid",
      "value": "Sparkasse an Volme und Ruhr - Zweckverbandssparkasse der Städte Hagen, Halver, Herdecke und Lüdenscheid sowie der Gemeinden Schalksmühle und Herscheid"
    },
    {
      "label": "Sparkasse Ansbach",
      "value": "Sparkasse Ansbach"
    },
    {
      "label": "Sparkasse Arnsberg-Sundern",
      "value": "Sparkasse Arnsberg-Sundern"
    },
    {
      "label": "Sparkasse Arnstadt-Ilmenau",
      "value": "Sparkasse Arnstadt-Ilmenau"
    },
    {
      "label": "Sparkasse Aschaffenburg- Alzenau",
      "value": "Sparkasse Aschaffenburg- Alzenau"
    },
    {
      "label": "Sparkasse Attendorn-Lennestadt-Kirchhundem Zweckverbandssparkasse der Städte Attendorn und Lennestadt sowie der Gemeinde Kirchhundem",
      "value": "Sparkasse Attendorn-Lennestadt-Kirchhundem Zweckverbandssparkasse der Städte Attendorn und Lennestadt sowie der Gemeinde Kirchhundem"
    },
    {
      "label": "Sparkasse Aurich-Norden in Ostfriesland - Ostfriesische Sparkasse -",
      "value": "Sparkasse Aurich-Norden in Ostfriesland - Ostfriesische Sparkasse -"
    },
    {
      "label": "Sparkasse Bad Hersfeld-Rotenburg",
      "value": "Sparkasse Bad Hersfeld-Rotenburg"
    },
    {
      "label": "Sparkasse Bad Kissingen",
      "value": "Sparkasse Bad Kissingen"
    },
    {
      "label": "Sparkasse Bad Neustadt a.d. Saale",
      "value": "Sparkasse Bad Neustadt a.d. Saale"
    },
    {
      "label": "Sparkasse Bad Oeynhausen - Porta Westfalica, Zweckverbandssparkasse der Städte Bad Oeynhausen und Porta Westfalica",
      "value": "Sparkasse Bad Oeynhausen - Porta Westfalica, Zweckverbandssparkasse der Städte Bad Oeynhausen und Porta Westfalica"
    },
    {
      "label": "Sparkasse Bad Tölz-Wolfratshausen",
      "value": "Sparkasse Bad Tölz-Wolfratshausen"
    },
    {
      "label": "Sparkasse Baden-Baden Gaggenau",
      "value": "Sparkasse Baden-Baden Gaggenau"
    },
    {
      "label": "Sparkasse Bamberg",
      "value": "Sparkasse Bamberg"
    },
    {
      "label": "Sparkasse Barnim",
      "value": "Sparkasse Barnim"
    },
    {
      "label": "Sparkasse Battenberg",
      "value": "Sparkasse Battenberg"
    },
    {
      "label": "Sparkasse Bayreuth",
      "value": "Sparkasse Bayreuth"
    },
    {
      "label": "Sparkasse Beckum-Wadersloh -Zweckverbandssparkasse der Stadt Beckum und der Gemeinde Wadersloh-",
      "value": "Sparkasse Beckum-Wadersloh -Zweckverbandssparkasse der Stadt Beckum und der Gemeinde Wadersloh-"
    },
    {
      "label": "Sparkasse Bensheim",
      "value": "Sparkasse Bensheim"
    },
    {
      "label": "Sparkasse Berchtesgadener Land",
      "value": "Sparkasse Berchtesgadener Land"
    },
    {
      "label": "Sparkasse Bielefeld",
      "value": "Sparkasse Bielefeld"
    },
    {
      "label": "Sparkasse Bochum",
      "value": "Sparkasse Bochum"
    },
    {
      "label": "Sparkasse Bodensee",
      "value": "Sparkasse Bodensee"
    },
    {
      "label": "Sparkasse Bonndorf-Stühlingen",
      "value": "Sparkasse Bonndorf-Stühlingen"
    },
    {
      "label": "Sparkasse Burbach-Neunkirchen Zweckverbandssparkasse der Gemeinden Burbach und Neunkirchen",
      "value": "Sparkasse Burbach-Neunkirchen Zweckverbandssparkasse der Gemeinden Burbach und Neunkirchen"
    },
    {
      "label": "Sparkasse Burgenlandkreis",
      "value": "Sparkasse Burgenlandkreis"
    },
    {
      "label": "Sparkasse Bühl",
      "value": "Sparkasse Bühl"
    },
    {
      "label": "Sparkasse Celle-Gifhorn-Wolfsburg",
      "value": "Sparkasse Celle-Gifhorn-Wolfsburg"
    },
    {
      "label": "Sparkasse Chemnitz",
      "value": "Sparkasse Chemnitz"
    },
    {
      "label": "Sparkasse Coburg - Lichtenfels",
      "value": "Sparkasse Coburg - Lichtenfels"
    },
    {
      "label": "Sparkasse Dachau",
      "value": "Sparkasse Dachau"
    },
    {
      "label": "Sparkasse Deggendorf",
      "value": "Sparkasse Deggendorf"
    },
    {
      "label": "Sparkasse der Stadt Iserlohn",
      "value": "Sparkasse der Stadt Iserlohn"
    },
    {
      "label": "Sparkasse der Stadt Wilhelmshaven",
      "value": "Sparkasse der Stadt Wilhelmshaven"
    },
    {
      "label": "Sparkasse Dieburg - Zweckverbandssparkasse -",
      "value": "Sparkasse Dieburg - Zweckverbandssparkasse -"
    },
    {
      "label": "Sparkasse Dillenburg",
      "value": "Sparkasse Dillenburg"
    },
    {
      "label": "Sparkasse Dillingen - Nördlingen",
      "value": "Sparkasse Dillingen - Nördlingen"
    },
    {
      "label": "Sparkasse Donauwörth",
      "value": "Sparkasse Donauwörth"
    },
    {
      "label": "Sparkasse Donnersberg",
      "value": "Sparkasse Donnersberg"
    },
    {
      "label": "Sparkasse Dortmund, Zweckverbandssparkasse der Städte Dortmund und Schwerte",
      "value": "Sparkasse Dortmund, Zweckverbandssparkasse der Städte Dortmund und Schwerte"
    },
    {
      "label": "Sparkasse Duisburg",
      "value": "Sparkasse Duisburg"
    },
    {
      "label": "Sparkasse Düren",
      "value": "Sparkasse Düren"
    },
    {
      "label": "Sparkasse Einbeck",
      "value": "Sparkasse Einbeck"
    },
    {
      "label": "Sparkasse Elbe-Elster",
      "value": "Sparkasse Elbe-Elster"
    },
    {
      "label": "Sparkasse Elmshorn",
      "value": "Sparkasse Elmshorn"
    },
    {
      "label": "Sparkasse Emden",
      "value": "Sparkasse Emden"
    },
    {
      "label": "Sparkasse Emsland",
      "value": "Sparkasse Emsland"
    },
    {
      "label": "Sparkasse Engen-Gottmadingen",
      "value": "Sparkasse Engen-Gottmadingen"
    },
    {
      "label": "Sparkasse Forchheim",
      "value": "Sparkasse Forchheim"
    },
    {
      "label": "Sparkasse Freiburg - Nördlicher Breisgau",
      "value": "Sparkasse Freiburg - Nördlicher Breisgau"
    },
    {
      "label": "Sparkasse Freising Moosburg",
      "value": "Sparkasse Freising Moosburg"
    },
    {
      "label": "Sparkasse Freyung-Grafenau",
      "value": "Sparkasse Freyung-Grafenau"
    },
    {
      "label": "Sparkasse Fulda",
      "value": "Sparkasse Fulda"
    },
    {
      "label": "Sparkasse Fürstenfeldbruck",
      "value": "Sparkasse Fürstenfeldbruck"
    },
    {
      "label": "Sparkasse Fürth",
      "value": "Sparkasse Fürth"
    },
    {
      "label": "Sparkasse Gera-Greiz",
      "value": "Sparkasse Gera-Greiz"
    },
    {
      "label": "Sparkasse Geseke",
      "value": "Sparkasse Geseke"
    },
    {
      "label": "Sparkasse Gießen",
      "value": "Sparkasse Gießen"
    },
    {
      "label": "Sparkasse Grünberg",
      "value": "Sparkasse Grünberg"
    },
    {
      "label": "Sparkasse Gummersbach",
      "value": "Sparkasse Gummersbach"
    },
    {
      "label": "Sparkasse Göttingen",
      "value": "Sparkasse Göttingen"
    },
    {
      "label": "Sparkasse Günzburg-Krumbach",
      "value": "Sparkasse Günzburg-Krumbach"
    },
    {
      "label": "Sparkasse Gütersloh-Rietberg-Versmold, Zweckverbandssparkasse der Städte Gütersloh, Rietberg und Versmold sowie des Kreises Gütersloh",
      "value": "Sparkasse Gütersloh-Rietberg-Versmold, Zweckverbandssparkasse der Städte Gütersloh, Rietberg und Versmold sowie des Kreises Gütersloh"
    },
    {
      "label": "Sparkasse Hameln-Weserbergland",
      "value": "Sparkasse Hameln-Weserbergland"
    },
    {
      "label": "Sparkasse Hamm",
      "value": "Sparkasse Hamm"
    },
    {
      "label": "SPARKASSE HANAU",
      "value": "SPARKASSE HANAU"
    },
    {
      "label": "Sparkasse Hanauerland",
      "value": "Sparkasse Hanauerland"
    },
    {
      "label": "Sparkasse Hannover",
      "value": "Sparkasse Hannover"
    },
    {
      "label": "Sparkasse Harburg-Buxtehude",
      "value": "Sparkasse Harburg-Buxtehude"
    },
    {
      "label": "Sparkasse Hattingen",
      "value": "Sparkasse Hattingen"
    },
    {
      "label": "Sparkasse Hegau-Bodensee",
      "value": "Sparkasse Hegau-Bodensee"
    },
    {
      "label": "Sparkasse Heidelberg",
      "value": "Sparkasse Heidelberg"
    },
    {
      "label": "Sparkasse Hilden Ratingen Velbert",
      "value": "Sparkasse Hilden Ratingen Velbert"
    },
    {
      "label": "Sparkasse Hildesheim Goslar Peine",
      "value": "Sparkasse Hildesheim Goslar Peine"
    },
    {
      "label": "Sparkasse Hochfranken",
      "value": "Sparkasse Hochfranken"
    },
    {
      "label": "Sparkasse Hochrhein",
      "value": "Sparkasse Hochrhein"
    },
    {
      "label": "Sparkasse Hochsauerland",
      "value": "Sparkasse Hochsauerland"
    },
    {
      "label": "Sparkasse Hochschwarzwald",
      "value": "Sparkasse Hochschwarzwald"
    },
    {
      "label": "Sparkasse Hohenlohekreis",
      "value": "Sparkasse Hohenlohekreis"
    },
    {
      "label": "Sparkasse Holstein",
      "value": "Sparkasse Holstein"
    },
    {
      "label": "Sparkasse im Kreis Herford -Zweckverbandssparkasse des Kreises Herford und der Städte Bünde, Herford, Löhne und Vlotho-",
      "value": "Sparkasse im Kreis Herford -Zweckverbandssparkasse des Kreises Herford und der Städte Bünde, Herford, Löhne und Vlotho-"
    },
    {
      "label": "Sparkasse im Landkreis Cham",
      "value": "Sparkasse im Landkreis Cham"
    },
    {
      "label": "Sparkasse im Landkreis Neustadt a.d. Aisch-Bad Windsheim",
      "value": "Sparkasse im Landkreis Neustadt a.d. Aisch-Bad Windsheim"
    },
    {
      "label": "Sparkasse im Landkreis Schwandorf",
      "value": "Sparkasse im Landkreis Schwandorf"
    },
    {
      "label": "Sparkasse Ingolstadt Eichstätt",
      "value": "Sparkasse Ingolstadt Eichstätt"
    },
    {
      "label": "Sparkasse Jena-Saale-Holzland",
      "value": "Sparkasse Jena-Saale-Holzland"
    },
    {
      "label": "Sparkasse Kaiserslautern",
      "value": "Sparkasse Kaiserslautern"
    },
    {
      "label": "Sparkasse Karlsruhe",
      "value": "Sparkasse Karlsruhe"
    },
    {
      "label": "Sparkasse Kierspe-Meinerzhagen Zweckverbandssparkasse der Städte Kierspe und Meinerzhagen",
      "value": "Sparkasse Kierspe-Meinerzhagen Zweckverbandssparkasse der Städte Kierspe und Meinerzhagen"
    },
    {
      "label": "Sparkasse Kinzigtal",
      "value": "Sparkasse Kinzigtal"
    },
    {
      "label": "Sparkasse Koblenz",
      "value": "Sparkasse Koblenz"
    },
    {
      "label": "Sparkasse Kraichgau Bruchsal-Bretten-Sinsheim",
      "value": "Sparkasse Kraichgau Bruchsal-Bretten-Sinsheim"
    },
    {
      "label": "Sparkasse Krefeld - Zweckverbandssparkasse der Stadt Krefeld und des Kreises Viersen -",
      "value": "Sparkasse Krefeld - Zweckverbandssparkasse der Stadt Krefeld und des Kreises Viersen -"
    },
    {
      "label": "Sparkasse Kulmbach-Kronach",
      "value": "Sparkasse Kulmbach-Kronach"
    },
    {
      "label": "Sparkasse KölnBonn",
      "value": "Sparkasse KölnBonn"
    },
    {
      "label": "Sparkasse Landsberg-Dießen",
      "value": "Sparkasse Landsberg-Dießen"
    },
    {
      "label": "Sparkasse Landshut",
      "value": "Sparkasse Landshut"
    },
    {
      "label": "Sparkasse Langen-Seligenstadt",
      "value": "Sparkasse Langen-Seligenstadt"
    },
    {
      "label": "Sparkasse Laubach-Hungen",
      "value": "Sparkasse Laubach-Hungen"
    },
    {
      "label": "Sparkasse LeerWittmund",
      "value": "Sparkasse LeerWittmund"
    },
    {
      "label": "Sparkasse Lemgo",
      "value": "Sparkasse Lemgo"
    },
    {
      "label": "Sparkasse Leverkusen",
      "value": "Sparkasse Leverkusen"
    },
    {
      "label": "Sparkasse Lippstadt",
      "value": "Sparkasse Lippstadt"
    },
    {
      "label": "Sparkasse Lörrach-Rheinfelden",
      "value": "Sparkasse Lörrach-Rheinfelden"
    },
    {
      "label": "Sparkasse Lüneburg",
      "value": "Sparkasse Lüneburg"
    },
    {
      "label": "Sparkasse MagdeBurg",
      "value": "Sparkasse MagdeBurg"
    },
    {
      "label": "Sparkasse Mainfranken Würzburg",
      "value": "Sparkasse Mainfranken Würzburg"
    },
    {
      "label": "Sparkasse Mansfeld-Südharz",
      "value": "Sparkasse Mansfeld-Südharz"
    },
    {
      "label": "Sparkasse Marburg-Biedenkopf",
      "value": "Sparkasse Marburg-Biedenkopf"
    },
    {
      "label": "Sparkasse Markgräflerland",
      "value": "Sparkasse Markgräflerland"
    },
    {
      "label": "Sparkasse Mecklenburg-Nordwest",
      "value": "Sparkasse Mecklenburg-Nordwest"
    },
    {
      "label": "Sparkasse Mecklenburg-Schwerin",
      "value": "Sparkasse Mecklenburg-Schwerin"
    },
    {
      "label": "Sparkasse Mecklenburg-Strelitz",
      "value": "Sparkasse Mecklenburg-Strelitz"
    },
    {
      "label": "Sparkasse Meißen",
      "value": "Sparkasse Meißen"
    },
    {
      "label": "Sparkasse Merzig-Wadern",
      "value": "Sparkasse Merzig-Wadern"
    },
    {
      "label": "Sparkasse Miltenberg-Obernburg",
      "value": "Sparkasse Miltenberg-Obernburg"
    },
    {
      "label": "Sparkasse Minden-Lübbecke Zweckverbandssparkasse des Kreises Minden-Lübbecke und der Städte Minden und Petershagen",
      "value": "Sparkasse Minden-Lübbecke Zweckverbandssparkasse des Kreises Minden-Lübbecke und der Städte Minden und Petershagen"
    },
    {
      "label": "Sparkasse Mittelfranken-Süd",
      "value": "Sparkasse Mittelfranken-Süd"
    },
    {
      "label": "Sparkasse Mittelholstein Aktiengesellschaft",
      "value": "Sparkasse Mittelholstein Aktiengesellschaft"
    },
    {
      "label": "Sparkasse Mittelmosel - Eifel Mosel Hunsrück",
      "value": "Sparkasse Mittelmosel - Eifel Mosel Hunsrück"
    },
    {
      "label": "Sparkasse Mittelsachsen",
      "value": "Sparkasse Mittelsachsen"
    },
    {
      "label": "Sparkasse Mittelthüringen",
      "value": "Sparkasse Mittelthüringen"
    },
    {
      "label": "Sparkasse Mitten im Sauerland, Zweckverbandssparkasse der Städte Meschede und Schmallenberg sowie der Gemeinden Eslohe (Sauerland) und Finnentrop",
      "value": "Sparkasse Mitten im Sauerland, Zweckverbandssparkasse der Städte Meschede und Schmallenberg sowie der Gemeinden Eslohe (Sauerland) und Finnentrop"
    },
    {
      "label": "Sparkasse Muldental",
      "value": "Sparkasse Muldental"
    },
    {
      "label": "Sparkasse Märkisches Sauerland, Hemer - Menden",
      "value": "Sparkasse Märkisches Sauerland, Hemer - Menden"
    },
    {
      "label": "Sparkasse Mülheim an der Ruhr",
      "value": "Sparkasse Mülheim an der Ruhr"
    },
    {
      "label": "Sparkasse Münsterland Ost",
      "value": "Sparkasse Münsterland Ost"
    },
    {
      "label": "Sparkasse Neckartal-Odenwald",
      "value": "Sparkasse Neckartal-Odenwald"
    },
    {
      "label": "Sparkasse Neu-Ulm-Illertissen",
      "value": "Sparkasse Neu-Ulm-Illertissen"
    },
    {
      "label": "Sparkasse Neubrandenburg-Demmin",
      "value": "Sparkasse Neubrandenburg-Demmin"
    },
    {
      "label": "Sparkasse Neuburg-Rain",
      "value": "Sparkasse Neuburg-Rain"
    },
    {
      "label": "Sparkasse Neumarkt i.d. OPf.-Parsberg",
      "value": "Sparkasse Neumarkt i.d. OPf.-Parsberg"
    },
    {
      "label": "Sparkasse Neunkirchen",
      "value": "Sparkasse Neunkirchen"
    },
    {
      "label": "Sparkasse Neuss - Zweckverbandssparkasse des Rhein-Kreises Neuss, der Stadt Neuss, der Stadt Korschenbroich und der Stadt Kaarst",
      "value": "Sparkasse Neuss - Zweckverbandssparkasse des Rhein-Kreises Neuss, der Stadt Neuss, der Stadt Korschenbroich und der Stadt Kaarst"
    },
    {
      "label": "Sparkasse Neuwied",
      "value": "Sparkasse Neuwied"
    },
    {
      "label": "Sparkasse Niederbayern-Mitte",
      "value": "Sparkasse Niederbayern-Mitte"
    },
    {
      "label": "Sparkasse Niederlausitz mit Sitz in Senftenberg",
      "value": "Sparkasse Niederlausitz mit Sitz in Senftenberg"
    },
    {
      "label": "Sparkasse Nienburg",
      "value": "Sparkasse Nienburg"
    },
    {
      "label": "Sparkasse Nürnberg",
      "value": "Sparkasse Nürnberg"
    },
    {
      "label": "Sparkasse Oberhessen",
      "value": "Sparkasse Oberhessen"
    },
    {
      "label": "Sparkasse Oberland",
      "value": "Sparkasse Oberland"
    },
    {
      "label": "Sparkasse Oberlausitz-Niederschlesien",
      "value": "Sparkasse Oberlausitz-Niederschlesien"
    },
    {
      "label": "Sparkasse Oberpfalz Nord",
      "value": "Sparkasse Oberpfalz Nord"
    },
    {
      "label": "Sparkasse Odenwaldkreis in Erbach/Odenwald",
      "value": "Sparkasse Odenwaldkreis in Erbach/Odenwald"
    },
    {
      "label": "Sparkasse Oder-Spree",
      "value": "Sparkasse Oder-Spree"
    },
    {
      "label": "Sparkasse Offenburg/Ortenau",
      "value": "Sparkasse Offenburg/Ortenau"
    },
    {
      "label": "Sparkasse Olpe-Drolshagen-Wenden",
      "value": "Sparkasse Olpe-Drolshagen-Wenden"
    },
    {
      "label": "Sparkasse Osnabrück",
      "value": "Sparkasse Osnabrück"
    },
    {
      "label": "Sparkasse Osterode am Harz",
      "value": "Sparkasse Osterode am Harz"
    },
    {
      "label": "Sparkasse Ostprignitz-Ruppin",
      "value": "Sparkasse Ostprignitz-Ruppin"
    },
    {
      "label": "Sparkasse Paderborn-Detmold (Lippische Spar- und Leihekasse)",
      "value": "Sparkasse Paderborn-Detmold (Lippische Spar- und Leihekasse)"
    },
    {
      "label": "Sparkasse Passau",
      "value": "Sparkasse Passau"
    },
    {
      "label": "Sparkasse Pfaffenhofen",
      "value": "Sparkasse Pfaffenhofen"
    },
    {
      "label": "Sparkasse Pforzheim Calw",
      "value": "Sparkasse Pforzheim Calw"
    },
    {
      "label": "Sparkasse Pfullendorf-Meßkirch",
      "value": "Sparkasse Pfullendorf-Meßkirch"
    },
    {
      "label": "Sparkasse Prignitz",
      "value": "Sparkasse Prignitz"
    },
    {
      "label": "Sparkasse Radevormwald-Hückeswagen",
      "value": "Sparkasse Radevormwald-Hückeswagen"
    },
    {
      "label": "Sparkasse Rastatt-Gernsbach",
      "value": "Sparkasse Rastatt-Gernsbach"
    },
    {
      "label": "Sparkasse Regen-Viechtach",
      "value": "Sparkasse Regen-Viechtach"
    },
    {
      "label": "Sparkasse Regensburg",
      "value": "Sparkasse Regensburg"
    },
    {
      "label": "Sparkasse Rhein Neckar Nord",
      "value": "Sparkasse Rhein Neckar Nord"
    },
    {
      "label": "Sparkasse Rhein-Haardt",
      "value": "Sparkasse Rhein-Haardt"
    },
    {
      "label": "Sparkasse Rhein-Maas",
      "value": "Sparkasse Rhein-Maas"
    },
    {
      "label": "Sparkasse Rhein-Nahe",
      "value": "Sparkasse Rhein-Nahe"
    },
    {
      "label": "Sparkasse Rosenheim-Bad Aibling",
      "value": "Sparkasse Rosenheim-Bad Aibling"
    },
    {
      "label": "Sparkasse Rotenburg Osterholz",
      "value": "Sparkasse Rotenburg Osterholz"
    },
    {
      "label": "Sparkasse Rottal-Inn",
      "value": "Sparkasse Rottal-Inn"
    },
    {
      "label": "Sparkasse Saarbrücken",
      "value": "Sparkasse Saarbrücken"
    },
    {
      "label": "Sparkasse Salem-Heiligenberg",
      "value": "Sparkasse Salem-Heiligenberg"
    },
    {
      "label": "Sparkasse Schaumburg",
      "value": "Sparkasse Schaumburg"
    },
    {
      "label": "Sparkasse Scheeßel - Zweckverbandssparkasse -",
      "value": "Sparkasse Scheeßel - Zweckverbandssparkasse -"
    },
    {
      "label": "Sparkasse Schwaben-Bodensee",
      "value": "Sparkasse Schwaben-Bodensee"
    },
    {
      "label": "Sparkasse Schwarzwald-Baar",
      "value": "Sparkasse Schwarzwald-Baar"
    },
    {
      "label": "Sparkasse Schweinfurt-Haßberge",
      "value": "Sparkasse Schweinfurt-Haßberge"
    },
    {
      "label": "Sparkasse Schwelm-Sprockhövel - Zweckverbandssparkasse der Städte Schwelm und Sprockhövel",
      "value": "Sparkasse Schwelm-Sprockhövel - Zweckverbandssparkasse der Städte Schwelm und Sprockhövel"
    },
    {
      "label": "Sparkasse Schwäbisch Hall-Crailsheim",
      "value": "Sparkasse Schwäbisch Hall-Crailsheim"
    },
    {
      "label": "Sparkasse Siegen, Zweckverbandssparkasse der Städte Siegen, Freudenberg, Hilchenbach, Kreuztal, Netphen und der Gemeinde Wilnsdorf",
      "value": "Sparkasse Siegen, Zweckverbandssparkasse der Städte Siegen, Freudenberg, Hilchenbach, Kreuztal, Netphen und der Gemeinde Wilnsdorf"
    },
    {
      "label": "Sparkasse SoestWerl",
      "value": "Sparkasse SoestWerl"
    },
    {
      "label": "Sparkasse Sonneberg",
      "value": "Sparkasse Sonneberg"
    },
    {
      "label": "Sparkasse Spree-Neiße",
      "value": "Sparkasse Spree-Neiße"
    },
    {
      "label": "Sparkasse St. Blasien",
      "value": "Sparkasse St. Blasien"
    },
    {
      "label": "Sparkasse Stade-Altes Land",
      "value": "Sparkasse Stade-Altes Land"
    },
    {
      "label": "Sparkasse Starkenburg",
      "value": "Sparkasse Starkenburg"
    },
    {
      "label": "Sparkasse Staufen - Breisach",
      "value": "Sparkasse Staufen - Breisach"
    },
    {
      "label": "Sparkasse Südholstein",
      "value": "Sparkasse Südholstein"
    },
    {
      "label": "Sparkasse Südpfalz",
      "value": "Sparkasse Südpfalz"
    },
    {
      "label": "Sparkasse Südwestpfalz",
      "value": "Sparkasse Südwestpfalz"
    },
    {
      "label": "Sparkasse Tauberfranken",
      "value": "Sparkasse Tauberfranken"
    },
    {
      "label": "Sparkasse Trier",
      "value": "Sparkasse Trier"
    },
    {
      "label": "Sparkasse Uckermark",
      "value": "Sparkasse Uckermark"
    },
    {
      "label": "Sparkasse Uecker-Randow",
      "value": "Sparkasse Uecker-Randow"
    },
    {
      "label": "Sparkasse Uelzen/Lüchow-Dannenberg",
      "value": "Sparkasse Uelzen/Lüchow-Dannenberg"
    },
    {
      "label": "Sparkasse Ulm",
      "value": "Sparkasse Ulm"
    },
    {
      "label": "Sparkasse Unstrut-Hainich",
      "value": "Sparkasse Unstrut-Hainich"
    },
    {
      "label": "Sparkasse Vest Recklinghausen",
      "value": "Sparkasse Vest Recklinghausen"
    },
    {
      "label": "Sparkasse Vogtland",
      "value": "Sparkasse Vogtland"
    },
    {
      "label": "Sparkasse Vorderpfalz",
      "value": "Sparkasse Vorderpfalz"
    },
    {
      "label": "Sparkasse Vorpommern",
      "value": "Sparkasse Vorpommern"
    },
    {
      "label": "Sparkasse Waldeck-Frankenberg",
      "value": "Sparkasse Waldeck-Frankenberg"
    },
    {
      "label": "Sparkasse Werra-Meißner",
      "value": "Sparkasse Werra-Meißner"
    },
    {
      "label": "Sparkasse Westerwald-Sieg",
      "value": "Sparkasse Westerwald-Sieg"
    },
    {
      "label": "Sparkasse Westholstein",
      "value": "Sparkasse Westholstein"
    },
    {
      "label": "Sparkasse Westmünsterland",
      "value": "Sparkasse Westmünsterland"
    },
    {
      "label": "Sparkasse Wetzlar",
      "value": "Sparkasse Wetzlar"
    },
    {
      "label": "Sparkasse Wiesental",
      "value": "Sparkasse Wiesental"
    },
    {
      "label": "Sparkasse Witten",
      "value": "Sparkasse Witten"
    },
    {
      "label": "Sparkasse Wittenberg",
      "value": "Sparkasse Wittenberg"
    },
    {
      "label": "Sparkasse Wittgenstein",
      "value": "Sparkasse Wittgenstein"
    },
    {
      "label": "Sparkasse Wolfach",
      "value": "Sparkasse Wolfach"
    },
    {
      "label": "Sparkasse Zollernalb",
      "value": "Sparkasse Zollernalb"
    },
    {
      "label": "Sparkasse zu Lübeck Aktiengesellschaft",
      "value": "Sparkasse zu Lübeck Aktiengesellschaft"
    },
    {
      "label": "Sparkasse Zwickau",
      "value": "Sparkasse Zwickau"
    },
    {
      "label": "Sparkassen Pensionsfonds AG",
      "value": "Sparkassen Pensionsfonds AG"
    },
    {
      "label": "Sparkassen Pensionskasse AG",
      "value": "Sparkassen Pensionskasse AG"
    },
    {
      "label": "Sparkassen-Versicherung Sachsen Allgemeine Versicherung Aktiengesellschaft",
      "value": "Sparkassen-Versicherung Sachsen Allgemeine Versicherung Aktiengesellschaft"
    },
    {
      "label": "Sparkassen-Versicherung Sachsen Lebensversicherung Aktiengesellschaft",
      "value": "Sparkassen-Versicherung Sachsen Lebensversicherung Aktiengesellschaft"
    },
    {
      "label": "Spectrum Capital Partners GmbH",
      "value": "Spectrum Capital Partners GmbH"
    },
    {
      "label": "Spectrum MTF Operator GmbH",
      "value": "Spectrum MTF Operator GmbH"
    },
    {
      "label": "Spiekermann & CO Aktiengesellschaft",
      "value": "Spiekermann & CO Aktiengesellschaft"
    },
    {
      "label": "Spinone Verwaltungs GmbH",
      "value": "Spinone Verwaltungs GmbH"
    },
    {
      "label": "Spinoza Capital GmbH",
      "value": "Spinoza Capital GmbH"
    },
    {
      "label": "Spitzlei GmbH",
      "value": "Spitzlei GmbH"
    },
    {
      "label": "SpreeGas Gesellschaft für Gasversorgung und Energiedienstleistung mbH",
      "value": "SpreeGas Gesellschaft für Gasversorgung und Energiedienstleistung mbH"
    },
    {
      "label": "Spreewaldbank eG",
      "value": "Spreewaldbank eG"
    },
    {
      "label": "SPSW Capital GmbH",
      "value": "SPSW Capital GmbH"
    },
    {
      "label": "SPSW Capital Investment-AG TGV",
      "value": "SPSW Capital Investment-AG TGV"
    },
    {
      "label": "SSW-Trading GmbH",
      "value": "SSW-Trading GmbH"
    },
    {
      "label": "St. Martinus Priesterverein der Diözese Rottenburg-Stuttgart- Kranken- und Sterbekasse (KSK) - Versicherungsverein auf Gegenseitigkeit (VVaG)",
      "value": "St. Martinus Priesterverein der Diözese Rottenburg-Stuttgart- Kranken- und Sterbekasse (KSK) - Versicherungsverein auf Gegenseitigkeit (VVaG)"
    },
    {
      "label": "St.Galler Kantonalbank Deutschland AG",
      "value": "St.Galler Kantonalbank Deutschland AG"
    },
    {
      "label": "Stadt- und Kreis-Sparkasse Darmstadt",
      "value": "Stadt- und Kreis-Sparkasse Darmstadt"
    },
    {
      "label": "Stadt- und Kreissparkasse Erlangen Höchstadt Herzogenaurach",
      "value": "Stadt- und Kreissparkasse Erlangen Höchstadt Herzogenaurach"
    },
    {
      "label": "Stadt- und Kreissparkasse Leipzig",
      "value": "Stadt- und Kreissparkasse Leipzig"
    },
    {
      "label": "Stadt-Sparkasse Gelsenkirchen",
      "value": "Stadt-Sparkasse Gelsenkirchen"
    },
    {
      "label": "Stadt-Sparkasse Haan (Rheinl.)",
      "value": "Stadt-Sparkasse Haan (Rheinl.)"
    },
    {
      "label": "Stadt-Sparkasse Langenfeld (Rhld.)",
      "value": "Stadt-Sparkasse Langenfeld (Rhld.)"
    },
    {
      "label": "Stadt-Sparkasse Solingen",
      "value": "Stadt-Sparkasse Solingen"
    },
    {
      "label": "Stadtsparkasse Augsburg",
      "value": "Stadtsparkasse Augsburg"
    },
    {
      "label": "Stadtsparkasse Bad Pyrmont",
      "value": "Stadtsparkasse Bad Pyrmont"
    },
    {
      "label": "Stadtsparkasse Barsinghausen",
      "value": "Stadtsparkasse Barsinghausen"
    },
    {
      "label": "Stadtsparkasse Bocholt",
      "value": "Stadtsparkasse Bocholt"
    },
    {
      "label": "Stadtsparkasse Borken (Hessen)",
      "value": "Stadtsparkasse Borken (Hessen)"
    },
    {
      "label": "Stadtsparkasse Bottrop",
      "value": "Stadtsparkasse Bottrop"
    },
    {
      "label": "Stadtsparkasse Burgdorf",
      "value": "Stadtsparkasse Burgdorf"
    },
    {
      "label": "Stadtsparkasse Cuxhaven",
      "value": "Stadtsparkasse Cuxhaven"
    },
    {
      "label": "Stadtsparkasse Delbrück",
      "value": "Stadtsparkasse Delbrück"
    },
    {
      "label": "Stadtsparkasse Dessau",
      "value": "Stadtsparkasse Dessau"
    },
    {
      "label": "Stadtsparkasse Düsseldorf",
      "value": "Stadtsparkasse Düsseldorf"
    },
    {
      "label": "Stadtsparkasse Essen",
      "value": "Stadtsparkasse Essen"
    },
    {
      "label": "Stadtsparkasse Gladbeck",
      "value": "Stadtsparkasse Gladbeck"
    },
    {
      "label": "Stadtsparkasse Grebenstein",
      "value": "Stadtsparkasse Grebenstein"
    },
    {
      "label": "Stadtsparkasse Haltern am See",
      "value": "Stadtsparkasse Haltern am See"
    },
    {
      "label": "Stadtsparkasse Lengerich (Westfalen)",
      "value": "Stadtsparkasse Lengerich (Westfalen)"
    },
    {
      "label": "Stadtsparkasse Mönchengladbach",
      "value": "Stadtsparkasse Mönchengladbach"
    },
    {
      "label": "Stadtsparkasse München",
      "value": "Stadtsparkasse München"
    },
    {
      "label": "Stadtsparkasse Oberhausen",
      "value": "Stadtsparkasse Oberhausen"
    },
    {
      "label": "Stadtsparkasse Rahden",
      "value": "Stadtsparkasse Rahden"
    },
    {
      "label": "Stadtsparkasse Remscheid",
      "value": "Stadtsparkasse Remscheid"
    },
    {
      "label": "Stadtsparkasse Rheine",
      "value": "Stadtsparkasse Rheine"
    },
    {
      "label": "Stadtsparkasse Schwalmstadt",
      "value": "Stadtsparkasse Schwalmstadt"
    },
    {
      "label": "Stadtsparkasse Schwedt",
      "value": "Stadtsparkasse Schwedt"
    },
    {
      "label": "Stadtsparkasse Wedel",
      "value": "Stadtsparkasse Wedel"
    },
    {
      "label": "Stadtsparkasse Wermelskirchen",
      "value": "Stadtsparkasse Wermelskirchen"
    },
    {
      "label": "Stadtsparkasse Wunstorf",
      "value": "Stadtsparkasse Wunstorf"
    },
    {
      "label": "Stadtsparkasse Wuppertal",
      "value": "Stadtsparkasse Wuppertal"
    },
    {
      "label": "Stadtwerke Augsburg",
      "value": "Stadtwerke Augsburg"
    },
    {
      "label": "Stadtwerke Bad Bramstedt GmbH",
      "value": "Stadtwerke Bad Bramstedt GmbH"
    },
    {
      "label": "Stadtwerke Bad Hersfeld GmbH",
      "value": "Stadtwerke Bad Hersfeld GmbH"
    },
    {
      "label": "Stadtwerke Bramsche GmbH",
      "value": "Stadtwerke Bramsche GmbH"
    },
    {
      "label": "Stadtwerke Bremerhaven AG",
      "value": "Stadtwerke Bremerhaven AG"
    },
    {
      "label": "Stadtwerke Buchholz GmbH",
      "value": "Stadtwerke Buchholz GmbH"
    },
    {
      "label": "Stadtwerke Buxtehude GmbH",
      "value": "Stadtwerke Buxtehude GmbH"
    },
    {
      "label": "Stadtwerke Düsseldorf Aktiengesellschaft",
      "value": "Stadtwerke Düsseldorf Aktiengesellschaft"
    },
    {
      "label": "Stadtwerke Elmshorn",
      "value": "Stadtwerke Elmshorn"
    },
    {
      "label": "Stadtwerke Geesthacht GmbH",
      "value": "Stadtwerke Geesthacht GmbH"
    },
    {
      "label": "Stadtwerke Haldensleben GmbH",
      "value": "Stadtwerke Haldensleben GmbH"
    },
    {
      "label": "Stadtwerke Hattingen GmbH",
      "value": "Stadtwerke Hattingen GmbH"
    },
    {
      "label": "Stadtwerke Heide GmbH",
      "value": "Stadtwerke Heide GmbH"
    },
    {
      "label": "Stadtwerke Helmstedt GmbH",
      "value": "Stadtwerke Helmstedt GmbH"
    },
    {
      "label": "Stadtwerke Kiel Aktiengesellschaft",
      "value": "Stadtwerke Kiel Aktiengesellschaft"
    },
    {
      "label": "Stadtwerke Königslutter GmbH",
      "value": "Stadtwerke Königslutter GmbH"
    },
    {
      "label": "Stadtwerke Pinneberg",
      "value": "Stadtwerke Pinneberg"
    },
    {
      "label": "Stadtwerke Rendsburg GmbH",
      "value": "Stadtwerke Rendsburg GmbH"
    },
    {
      "label": "Stadtwerke Schleswig",
      "value": "Stadtwerke Schleswig"
    },
    {
      "label": "Stadtwerke Winsen (Luhe) GmbH",
      "value": "Stadtwerke Winsen (Luhe) GmbH"
    },
    {
      "label": "Stadtwerke Wolfenbüttel GmbH",
      "value": "Stadtwerke Wolfenbüttel GmbH"
    },
    {
      "label": "Standard Chartered Bank AG",
      "value": "Standard Chartered Bank AG"
    },
    {
      "label": "Standard Life Versicherung, Zweigniederlassung Deutschland der Standard Life International Designated Activity Company",
      "value": "Standard Life Versicherung, Zweigniederlassung Deutschland der Standard Life International Designated Activity Company"
    },
    {
      "label": "Stansch Vermögensverwaltungs GmbH",
      "value": "Stansch Vermögensverwaltungs GmbH"
    },
    {
      "label": "Starr Europe Insurance Limited, Germany",
      "value": "Starr Europe Insurance Limited, Germany"
    },
    {
      "label": "Start-up BW Innovation Fonds GmbH & Co. KG",
      "value": "Start-up BW Innovation Fonds GmbH & Co. KG"
    },
    {
      "label": "start:bausparkasse AG",
      "value": "start:bausparkasse AG"
    },
    {
      "label": "State Bank of India (Indische Staatsbank) Zweigniederlassung Frankfurt am Main",
      "value": "State Bank of India (Indische Staatsbank) Zweigniederlassung Frankfurt am Main"
    },
    {
      "label": "State Street Bank International GmbH",
      "value": "State Street Bank International GmbH"
    },
    {
      "label": "State Street Global Advisors Europe Limited Zweigniederlassung Deutschland",
      "value": "State Street Global Advisors Europe Limited Zweigniederlassung Deutschland"
    },
    {
      "label": "Steinbeis & Häcker Vermögensverwaltung GmbH",
      "value": "Steinbeis & Häcker Vermögensverwaltung GmbH"
    },
    {
      "label": "Steinhart & Stahl Vermögensverwaltung GmbH",
      "value": "Steinhart & Stahl Vermögensverwaltung GmbH"
    },
    {
      "label": "Sterbe-Unterstützungs-Vereinigung der Beschäftigten der Stadt München",
      "value": "Sterbe-Unterstützungs-Vereinigung der Beschäftigten der Stadt München"
    },
    {
      "label": "Sterbekasse der Bediensteten der Stadtverwaltung Dortmund",
      "value": "Sterbekasse der Bediensteten der Stadtverwaltung Dortmund"
    },
    {
      "label": "Sterbekasse der Belegschaft der Saarstahl AG Völklingen, Werk Völklingen und Werk Burbach",
      "value": "Sterbekasse der Belegschaft der Saarstahl AG Völklingen, Werk Völklingen und Werk Burbach"
    },
    {
      "label": "Sterbekasse der Betriebsangehörigen der BVG (ehem. Sterbekasse der U-Bahn) i.L.",
      "value": "Sterbekasse der Betriebsangehörigen der BVG (ehem. Sterbekasse der U-Bahn) i.L."
    },
    {
      "label": "Sterbekasse der Feuerwehren, Versicherungsverein auf Gegenseitigkeit",
      "value": "Sterbekasse der Feuerwehren, Versicherungsverein auf Gegenseitigkeit"
    },
    {
      "label": "Sterbekasse der Saarbergleute VVaG",
      "value": "Sterbekasse der Saarbergleute VVaG"
    },
    {
      "label": "Sterbekasse Evangelischer Freikirchen VVaG",
      "value": "Sterbekasse Evangelischer Freikirchen VVaG"
    },
    {
      "label": "Sterbekasse für die Angestellten der Deutsche Bank Gruppe",
      "value": "Sterbekasse für die Angestellten der Deutsche Bank Gruppe"
    },
    {
      "label": "Sterbekasse Sozialversicherung- gegr. in der LVA Rheinprovinz - Düsseldorf",
      "value": "Sterbekasse Sozialversicherung- gegr. in der LVA Rheinprovinz - Düsseldorf"
    },
    {
      "label": "Steyler Bank GmbH",
      "value": "Steyler Bank GmbH"
    },
    {
      "label": "Stifel Europe Bank AG",
      "value": "Stifel Europe Bank AG"
    },
    {
      "label": "Stiftung \"Resozialisierungsfonds Dr. Traugott Bender\" beim Ministerium der Justiz und für Europa Baden-Württemberg",
      "value": "Stiftung \"Resozialisierungsfonds Dr. Traugott Bender\" beim Ministerium der Justiz und für Europa Baden-Württemberg"
    },
    {
      "label": "Stiftung \"Resozialisierungsfonds für Straffällige",
      "value": "Stiftung \"Resozialisierungsfonds für Straffällige"
    },
    {
      "label": "Stiftung Familie in Not",
      "value": "Stiftung Familie in Not"
    },
    {
      "label": "Stiftung Notgemeinschaft Studiendank c/o Studentenwerk Mainz Anstalt des öffentlichen Rechts",
      "value": "Stiftung Notgemeinschaft Studiendank c/o Studentenwerk Mainz Anstalt des öffentlichen Rechts"
    },
    {
      "label": "Stiftung Resozialisierungsfonds beim Niedersächsischen Justizministerium",
      "value": "Stiftung Resozialisierungsfonds beim Niedersächsischen Justizministerium"
    },
    {
      "label": "Stiftung Straffälligenhilfe Schleswig-Holstein",
      "value": "Stiftung Straffälligenhilfe Schleswig-Holstein"
    },
    {
      "label": "Stiftung Vertriebenes Landvolk e.V.",
      "value": "Stiftung Vertriebenes Landvolk e.V."
    },
    {
      "label": "STILL Financial Services GmbH",
      "value": "STILL Financial Services GmbH"
    },
    {
      "label": "Stonex Financial Europe S.A., Niederlassung Deutschland",
      "value": "Stonex Financial Europe S.A., Niederlassung Deutschland"
    },
    {
      "label": "StoneX Financial GmbH",
      "value": "StoneX Financial GmbH"
    },
    {
      "label": "STRATAV Vermögensverwaltung GmbH",
      "value": "STRATAV Vermögensverwaltung GmbH"
    },
    {
      "label": "Streit Büroausstattung und Leasing GmbH & Co. KG",
      "value": "Streit Büroausstattung und Leasing GmbH & Co. KG"
    },
    {
      "label": "STS Co-Investment Fund Management GmbH",
      "value": "STS Co-Investment Fund Management GmbH"
    },
    {
      "label": "Studentenwerk Frankfurt a.M.",
      "value": "Studentenwerk Frankfurt a.M."
    },
    {
      "label": "Studentische Darlehnskasse e.V.",
      "value": "Studentische Darlehnskasse e.V."
    },
    {
      "label": "Studierendenwerk Hamburg AöR",
      "value": "Studierendenwerk Hamburg AöR"
    },
    {
      "label": "Stuttgarter Lebensversicherung a.G.",
      "value": "Stuttgarter Lebensversicherung a.G."
    },
    {
      "label": "Stuttgarter Versicherung Aktiengesellschaft",
      "value": "Stuttgarter Versicherung Aktiengesellschaft"
    },
    {
      "label": "Städtische Sparkasse Offenbach a.M.",
      "value": "Städtische Sparkasse Offenbach a.M."
    },
    {
      "label": "Stüfe & Partner Vermögensverwaltung ( OHG )",
      "value": "Stüfe & Partner Vermögensverwaltung ( OHG )"
    },
    {
      "label": "Sumitomo Mitsui Banking Corporation Fil. Düsseldorf Zweigniederlassung der Sumitomo Mitsui Banking Corporation mit Sitz in Tokio",
      "value": "Sumitomo Mitsui Banking Corporation Fil. Düsseldorf Zweigniederlassung der Sumitomo Mitsui Banking Corporation mit Sitz in Tokio"
    },
    {
      "label": "Sunfish Management GmbH",
      "value": "Sunfish Management GmbH"
    },
    {
      "label": "Sunnybay Capital Management GmbH",
      "value": "Sunnybay Capital Management GmbH"
    },
    {
      "label": "Super Global GmbH",
      "value": "Super Global GmbH"
    },
    {
      "label": "Sustainable & Invest GmbH",
      "value": "Sustainable & Invest GmbH"
    },
    {
      "label": "SV SparkassenVersicherung Gebäudeversicherung Aktiengesellschaft",
      "value": "SV SparkassenVersicherung Gebäudeversicherung Aktiengesellschaft"
    },
    {
      "label": "SV SparkassenVersicherung Holding Aktiengesellschaft",
      "value": "SV SparkassenVersicherung Holding Aktiengesellschaft"
    },
    {
      "label": "SV SparkassenVersicherung Lebensversicherung Aktiengesellschaft",
      "value": "SV SparkassenVersicherung Lebensversicherung Aktiengesellschaft"
    },
    {
      "label": "SV SparkassenVersicherung Pensionsfonds AG",
      "value": "SV SparkassenVersicherung Pensionsfonds AG"
    },
    {
      "label": "SVA Vermögensverwaltung Stuttgart AG",
      "value": "SVA Vermögensverwaltung Stuttgart AG"
    },
    {
      "label": "SVG Abrechnungs-Service GmbH",
      "value": "SVG Abrechnungs-Service GmbH"
    },
    {
      "label": "Swift Capital Partners GmbH",
      "value": "Swift Capital Partners GmbH"
    },
    {
      "label": "SWISS ALPHA GmbH Representative Office Germany",
      "value": "SWISS ALPHA GmbH Representative Office Germany"
    },
    {
      "label": "Swiss Life AG, Niederlassung für Deutschland",
      "value": "Swiss Life AG, Niederlassung für Deutschland"
    },
    {
      "label": "Swiss Life Asset Managers Luxembourg Niederlassung Deutschland",
      "value": "Swiss Life Asset Managers Luxembourg Niederlassung Deutschland"
    },
    {
      "label": "Swiss Life Kapitalverwaltungsgesellschaft mbH",
      "value": "Swiss Life Kapitalverwaltungsgesellschaft mbH"
    },
    {
      "label": "Swiss Life Pensionsfonds Aktiengesellschaft",
      "value": "Swiss Life Pensionsfonds Aktiengesellschaft"
    },
    {
      "label": "Swiss Life Pensionskasse Aktiengesellschaft",
      "value": "Swiss Life Pensionskasse Aktiengesellschaft"
    },
    {
      "label": "Swiss Life Products (Luxembourg) S.A., Niederlassung für Deutschland",
      "value": "Swiss Life Products (Luxembourg) S.A., Niederlassung für Deutschland"
    },
    {
      "label": "Swiss Re Europe S.A.",
      "value": "Swiss Re Europe S.A."
    },
    {
      "label": "Swiss Re International SE Niederlassung Deutschland",
      "value": "Swiss Re International SE Niederlassung Deutschland"
    },
    {
      "label": "Swisscanto Asset Management International S.A., Zweigniederlassung Frankfurt am Main",
      "value": "Swisscanto Asset Management International S.A., Zweigniederlassung Frankfurt am Main"
    },
    {
      "label": "SWN Stadtwerke Neumünster GmbH",
      "value": "SWN Stadtwerke Neumünster GmbH"
    },
    {
      "label": "SWP Seniorenwohnbauprojekte GmbH",
      "value": "SWP Seniorenwohnbauprojekte GmbH"
    },
    {
      "label": "SX-Capital AG",
      "value": "SX-Capital AG"
    },
    {
      "label": "Sydbank A/S Filiale Flensburg",
      "value": "Sydbank A/S Filiale Flensburg"
    },
    {
      "label": "Sylter Bank eG",
      "value": "Sylter Bank eG"
    },
    {
      "label": "System.One Management GmbH & Co. KG",
      "value": "System.One Management GmbH & Co. KG"
    },
    {
      "label": "Systempartner INTERSPORT GmbH",
      "value": "Systempartner INTERSPORT GmbH"
    },
    {
      "label": "Sächsische Aufbaubank - Förderbank -",
      "value": "Sächsische Aufbaubank - Förderbank -"
    },
    {
      "label": "Süd-West-Kreditbank Finanzierung GmbH",
      "value": "Süd-West-Kreditbank Finanzierung GmbH"
    },
    {
      "label": "Südbund Einkaufsverband für Heimtextilien eG",
      "value": "Südbund Einkaufsverband für Heimtextilien eG"
    },
    {
      "label": "Süddeutsche Allgemeine Versicherung a.G.",
      "value": "Süddeutsche Allgemeine Versicherung a.G."
    },
    {
      "label": "Süddeutsche Krankenversicherung a.G.",
      "value": "Süddeutsche Krankenversicherung a.G."
    },
    {
      "label": "Süddeutsche Lebensversicherung a.G.",
      "value": "Süddeutsche Lebensversicherung a.G."
    },
    {
      "label": "SüdFactoring GmbH",
      "value": "SüdFactoring GmbH"
    },
    {
      "label": "SüdLeasing Agrar GmbH",
      "value": "SüdLeasing Agrar GmbH"
    },
    {
      "label": "SüdLeasing GmbH",
      "value": "SüdLeasing GmbH"
    },
    {
      "label": "Südtiroler Sparkasse AG Niederlassung München - Cassa di Risparmio di Bolzano S.p.A.",
      "value": "Südtiroler Sparkasse AG Niederlassung München - Cassa di Risparmio di Bolzano S.p.A."
    },
    {
      "label": "SÜDWESTBANK - BAWAG AG Niederlassung Deutschland",
      "value": "SÜDWESTBANK - BAWAG AG Niederlassung Deutschland"
    },
    {
      "label": "SÜMO eG",
      "value": "SÜMO eG"
    },
    {
      "label": "T. Rowe Price (Luxembourg) Management S.á.r.l., Zweigniederlassung Frankfurt",
      "value": "T. Rowe Price (Luxembourg) Management S.á.r.l., Zweigniederlassung Frankfurt"
    },
    {
      "label": "TA Leasing GmbH",
      "value": "TA Leasing GmbH"
    },
    {
      "label": "TAGROKO GmbH & Co.KG",
      "value": "TAGROKO GmbH & Co.KG"
    },
    {
      "label": "Tajdo Advisors GmbH",
      "value": "Tajdo Advisors GmbH"
    },
    {
      "label": "Talanx Aktiengesellschaft",
      "value": "Talanx Aktiengesellschaft"
    },
    {
      "label": "TAM AG",
      "value": "TAM AG"
    },
    {
      "label": "TAMAC GmbH",
      "value": "TAMAC GmbH"
    },
    {
      "label": "Tane Fund Vermögens GmbH & Co. KG",
      "value": "Tane Fund Vermögens GmbH & Co. KG"
    },
    {
      "label": "Tangany GmbH",
      "value": "Tangany GmbH"
    },
    {
      "label": "Target Fintech Solutions GmbH & Co. KG",
      "value": "Target Fintech Solutions GmbH & Co. KG"
    },
    {
      "label": "Target Global Early Stage I Parallel Executive Fund GmbH & Co. KG",
      "value": "Target Global Early Stage I Parallel Executive Fund GmbH & Co. KG"
    },
    {
      "label": "Target Global Early Stage I Parallel Fund GmbH & Co. KG",
      "value": "Target Global Early Stage I Parallel Fund GmbH & Co. KG"
    },
    {
      "label": "Target Global Early Stage II Parallel Fund GmbH & Co. KG",
      "value": "Target Global Early Stage II Parallel Fund GmbH & Co. KG"
    },
    {
      "label": "Target Partners Adjunct Fund I GmbH & Co. KG",
      "value": "Target Partners Adjunct Fund I GmbH & Co. KG"
    },
    {
      "label": "Target Partners Fund II GmbH & Co. KG",
      "value": "Target Partners Fund II GmbH & Co. KG"
    },
    {
      "label": "Target Partners Fund III GmbH & Co. KG",
      "value": "Target Partners Fund III GmbH & Co. KG"
    },
    {
      "label": "Target Partners Opportunities Fund I GmbH & Co. KG",
      "value": "Target Partners Opportunities Fund I GmbH & Co. KG"
    },
    {
      "label": "TARGO Factoring GmbH",
      "value": "TARGO Factoring GmbH"
    },
    {
      "label": "TARGO Leasing GmbH",
      "value": "TARGO Leasing GmbH"
    },
    {
      "label": "TARGO Lebensversicherung AG",
      "value": "TARGO Lebensversicherung AG"
    },
    {
      "label": "TARGO Versicherung AG",
      "value": "TARGO Versicherung AG"
    },
    {
      "label": "TARGOBANK AG",
      "value": "TARGOBANK AG"
    },
    {
      "label": "Taunus Capital Management AG",
      "value": "Taunus Capital Management AG"
    },
    {
      "label": "Taunus Investments GmbH",
      "value": "Taunus Investments GmbH"
    },
    {
      "label": "Taunus Trust GmbH",
      "value": "Taunus Trust GmbH"
    },
    {
      "label": "Taunus-Sparkasse",
      "value": "Taunus-Sparkasse"
    },
    {
      "label": "Taxi-Auto-Zentrale und Einkaufsgenossenschaft des Stuttgarter Taxigewerbes eG",
      "value": "Taxi-Auto-Zentrale und Einkaufsgenossenschaft des Stuttgarter Taxigewerbes eG"
    },
    {
      "label": "TBF Global Asset Management GmbH",
      "value": "TBF Global Asset Management GmbH"
    },
    {
      "label": "TeamBank AG Nürnberg",
      "value": "TeamBank AG Nürnberg"
    },
    {
      "label": "TeamFaktor NW GmbH",
      "value": "TeamFaktor NW GmbH"
    },
    {
      "label": "TEBA Kreditbank GmbH & Co. KG",
      "value": "TEBA Kreditbank GmbH & Co. KG"
    },
    {
      "label": "TecFactor GmbH",
      "value": "TecFactor GmbH"
    },
    {
      "label": "Technologiegründerfonds Sachsen Management GmbH & Co. KG",
      "value": "Technologiegründerfonds Sachsen Management GmbH & Co. KG"
    },
    {
      "label": "Techstars Berlin City Fund GmbH & Co. KG",
      "value": "Techstars Berlin City Fund GmbH & Co. KG"
    },
    {
      "label": "Tecta Invest GmbH",
      "value": "Tecta Invest GmbH"
    },
    {
      "label": "Tectum Private Equity III - G GmbH & Co. KG",
      "value": "Tectum Private Equity III - G GmbH & Co. KG"
    },
    {
      "label": "Tectum Private Equity III - V GmbH & Co. KG",
      "value": "Tectum Private Equity III - V GmbH & Co. KG"
    },
    {
      "label": "teemfactor GmbH",
      "value": "teemfactor GmbH"
    },
    {
      "label": "Tegeran Vermögensverwaltung GmbH",
      "value": "Tegeran Vermögensverwaltung GmbH"
    },
    {
      "label": "TELEFONICA SEGUROS Y REASEGUROS COMPAÑÍA ASEGURADORA SAU, Niederlassung Deutschland",
      "value": "TELEFONICA SEGUROS Y REASEGUROS COMPAÑÍA ASEGURADORA SAU, Niederlassung Deutschland"
    },
    {
      "label": "Telekom-Pensionsfonds a.G.",
      "value": "Telekom-Pensionsfonds a.G."
    },
    {
      "label": "Tempus Capital Beteiligungs GmbH Co. KG",
      "value": "Tempus Capital Beteiligungs GmbH Co. KG"
    },
    {
      "label": "TEN31 Bank AG",
      "value": "TEN31 Bank AG"
    },
    {
      "label": "TenderCapital Ltd.",
      "value": "TenderCapital Ltd."
    },
    {
      "label": "Terra Kapitalverwaltung AG",
      "value": "Terra Kapitalverwaltung AG"
    },
    {
      "label": "Tesla Financial Services GmbH",
      "value": "Tesla Financial Services GmbH"
    },
    {
      "label": "Tesla Insurance Ltd (Germany Branch)",
      "value": "Tesla Insurance Ltd (Germany Branch)"
    },
    {
      "label": "Texas Atlantic Partners GmbH",
      "value": "Texas Atlantic Partners GmbH"
    },
    {
      "label": "Textilviertel Augsburg I Kapitalverwaltungsgesellschaft GmbH & Co. KG",
      "value": "Textilviertel Augsburg I Kapitalverwaltungsgesellschaft GmbH & Co. KG"
    },
    {
      "label": "Textilviertel Augsburg II Kapitalverwaltungsgesellschaft GmbH & Co. KG",
      "value": "Textilviertel Augsburg II Kapitalverwaltungsgesellschaft GmbH & Co. KG"
    },
    {
      "label": "thallos Vermögensverwaltung AG",
      "value": "thallos Vermögensverwaltung AG"
    },
    {
      "label": "The Bank of Japan Repräsentanz",
      "value": "The Bank of Japan Repräsentanz"
    },
    {
      "label": "The Bank of Korea Repräsentanz",
      "value": "The Bank of Korea Repräsentanz"
    },
    {
      "label": "The Bank of New York Mellon Filiale Frankfurt am Main",
      "value": "The Bank of New York Mellon Filiale Frankfurt am Main"
    },
    {
      "label": "The Bank of New York Mellon SA/NV, Asset Servicing, Niederlassung Frankfurt am Main",
      "value": "The Bank of New York Mellon SA/NV, Asset Servicing, Niederlassung Frankfurt am Main"
    },
    {
      "label": "The Change Group Wechselstuben GmbH - Zweigniederlassung Deutschland",
      "value": "The Change Group Wechselstuben GmbH - Zweigniederlassung Deutschland"
    },
    {
      "label": "The Korea Development Bank Frankfurt Representative Office",
      "value": "The Korea Development Bank Frankfurt Representative Office"
    },
    {
      "label": "The Paragon Fund I GmbH & Co. KG",
      "value": "The Paragon Fund I GmbH & Co. KG"
    },
    {
      "label": "The Paragon Fund II GmbH & Co. KG",
      "value": "The Paragon Fund II GmbH & Co. KG"
    },
    {
      "label": "The Paragon Fund IV GmbH & Co. KG",
      "value": "The Paragon Fund IV GmbH & Co. KG"
    },
    {
      "label": "Thomas Freiberger Vermögensverwaltung GmbH",
      "value": "Thomas Freiberger Vermögensverwaltung GmbH"
    },
    {
      "label": "Threadneedle Management Luxembourg S.A., (Germany Branch)",
      "value": "Threadneedle Management Luxembourg S.A., (Germany Branch)"
    },
    {
      "label": "Thüga Schadenausgleichskasse München VVaG",
      "value": "Thüga Schadenausgleichskasse München VVaG"
    },
    {
      "label": "Thüringer Aufbaubank, Anstalt des öffentlichen Rechts",
      "value": "Thüringer Aufbaubank, Anstalt des öffentlichen Rechts"
    },
    {
      "label": "Tierärztliche Verrechnungsstelle Heide r.V.",
      "value": "Tierärztliche Verrechnungsstelle Heide r.V."
    },
    {
      "label": "TIFA eG",
      "value": "TIFA eG"
    },
    {
      "label": "Tikehau Investment Management: Germany Branch",
      "value": "Tikehau Investment Management: Germany Branch"
    },
    {
      "label": "Timberland Capital Management GmbH",
      "value": "Timberland Capital Management GmbH"
    },
    {
      "label": "Tink Germany GmbH",
      "value": "Tink Germany GmbH"
    },
    {
      "label": "Tishman Speyer Investment Management GmbH",
      "value": "Tishman Speyer Investment Management GmbH"
    },
    {
      "label": "TK Pensionsfonds AG",
      "value": "TK Pensionsfonds AG"
    },
    {
      "label": "Tobias Hörl Vermögensverwaltung GmbH",
      "value": "Tobias Hörl Vermögensverwaltung GmbH"
    },
    {
      "label": "Token GmbH",
      "value": "Token GmbH"
    },
    {
      "label": "Tokio Marine Europe S.A. - Zweigniederlassung für Deutschland",
      "value": "Tokio Marine Europe S.A. - Zweigniederlassung für Deutschland"
    },
    {
      "label": "Top Ten Investment Consulting GmbH",
      "value": "Top Ten Investment Consulting GmbH"
    },
    {
      "label": "TOP Vermögen AG",
      "value": "TOP Vermögen AG"
    },
    {
      "label": "TOP Vermögensverwaltung AG",
      "value": "TOP Vermögensverwaltung AG"
    },
    {
      "label": "TOYOTA Kreditbank GmbH",
      "value": "TOYOTA Kreditbank GmbH"
    },
    {
      "label": "Toyota Material Handling Commercial Finance AB, Deutschland",
      "value": "Toyota Material Handling Commercial Finance AB, Deutschland"
    },
    {
      "label": "TP ICAP (Europe) S.A., Frankfurt Branch",
      "value": "TP ICAP (Europe) S.A., Frankfurt Branch"
    },
    {
      "label": "Trade Republic Bank GmbH",
      "value": "Trade Republic Bank GmbH"
    },
    {
      "label": "TradeCom Anlageberatung und Anlagevermittlung GmbH",
      "value": "TradeCom Anlageberatung und Anlagevermittlung GmbH"
    },
    {
      "label": "Tradegate AG Wertpapierhandelsbank",
      "value": "Tradegate AG Wertpapierhandelsbank"
    },
    {
      "label": "Tradewind GmbH",
      "value": "Tradewind GmbH"
    },
    {
      "label": "trading-house Broker GmbH",
      "value": "trading-house Broker GmbH"
    },
    {
      "label": "Tradition Financial Services GmbH",
      "value": "Tradition Financial Services GmbH"
    },
    {
      "label": "Trail PY2 GmbH & Co. KG",
      "value": "Trail PY2 GmbH & Co. KG"
    },
    {
      "label": "transact Elektronische Zahlungssysteme GmbH",
      "value": "transact Elektronische Zahlungssysteme GmbH"
    },
    {
      "label": "transmed Transport GmbH",
      "value": "transmed Transport GmbH"
    },
    {
      "label": "TransRe Europe S.A., Munich Branch",
      "value": "TransRe Europe S.A., Munich Branch"
    },
    {
      "label": "Travelex (Deutschland) GmbH",
      "value": "Travelex (Deutschland) GmbH"
    },
    {
      "label": "Trend - Kairos - Capital GmbH",
      "value": "Trend - Kairos - Capital GmbH"
    },
    {
      "label": "TrendConcept Vermögensverwaltung GmbH",
      "value": "TrendConcept Vermögensverwaltung GmbH"
    },
    {
      "label": "Tresides Asset Management GmbH",
      "value": "Tresides Asset Management GmbH"
    },
    {
      "label": "TRESONO Family Office AG",
      "value": "TRESONO Family Office AG"
    },
    {
      "label": "TREVA Investment GmbH",
      "value": "TREVA Investment GmbH"
    },
    {
      "label": "TRIAS Versicherung Aktiengesellschaft",
      "value": "TRIAS Versicherung Aktiengesellschaft"
    },
    {
      "label": "Triodos Bank N.V. Deutschland",
      "value": "Triodos Bank N.V. Deutschland"
    },
    {
      "label": "Trisl GmbH",
      "value": "Trisl GmbH"
    },
    {
      "label": "Triton Investments Management SARL, German branch",
      "value": "Triton Investments Management SARL, German branch"
    },
    {
      "label": "Trive Financial Services Germany Branch",
      "value": "Trive Financial Services Germany Branch"
    },
    {
      "label": "TRS - Technology Refresh Services GmbH",
      "value": "TRS - Technology Refresh Services GmbH"
    },
    {
      "label": "TRUMPF Financial Services GmbH",
      "value": "TRUMPF Financial Services GmbH"
    },
    {
      "label": "Tryg Deutschland, Niederlassung der Tryg Forsikring A/S",
      "value": "Tryg Deutschland, Niederlassung der Tryg Forsikring A/S"
    },
    {
      "label": "Tullius Walden Asset Management AG",
      "value": "Tullius Walden Asset Management AG"
    },
    {
      "label": "Tungsten Capital Management GmbH",
      "value": "Tungsten Capital Management GmbH"
    },
    {
      "label": "TVM Life Science Ventures VI GmbH & Co. KG",
      "value": "TVM Life Science Ventures VI GmbH & Co. KG"
    },
    {
      "label": "Twelve Capital (DE) GmbH",
      "value": "Twelve Capital (DE) GmbH"
    },
    {
      "label": "Türkiye Cumhuriyet Merkez Bankasi Repräsentanz",
      "value": "Türkiye Cumhuriyet Merkez Bankasi Repräsentanz"
    },
    {
      "label": "UBP Asset Management (Europe) S.A., Niederlassung Frankfurt am Main",
      "value": "UBP Asset Management (Europe) S.A., Niederlassung Frankfurt am Main"
    },
    {
      "label": "UBS Asset Management (Deutschland) GmbH",
      "value": "UBS Asset Management (Deutschland) GmbH"
    },
    {
      "label": "UBS Europe SE",
      "value": "UBS Europe SE"
    },
    {
      "label": "UBS Real Estate GmbH",
      "value": "UBS Real Estate GmbH"
    },
    {
      "label": "Ucambio Exchange & Money Transfer GmbH",
      "value": "Ucambio Exchange & Money Transfer GmbH"
    },
    {
      "label": "UCF Schmidt Consult GmbH",
      "value": "UCF Schmidt Consult GmbH"
    },
    {
      "label": "Uelzener Allgemeine Versicherungs-Gesellschaft a.G.",
      "value": "Uelzener Allgemeine Versicherungs-Gesellschaft a.G."
    },
    {
      "label": "Ulf Peters Sonderb. f.d. öff.-r.LV.Anst. der Sudetenländer i.L.",
      "value": "Ulf Peters Sonderb. f.d. öff.-r.LV.Anst. der Sudetenländer i.L."
    },
    {
      "label": "ulmer heimstätte eG",
      "value": "ulmer heimstätte eG"
    },
    {
      "label": "UmweltBank Aktiengesellschaft",
      "value": "UmweltBank Aktiengesellschaft"
    },
    {
      "label": "Umweltfinanz Wertpapierhandelshaus GmbH",
      "value": "Umweltfinanz Wertpapierhandelshaus GmbH"
    },
    {
      "label": "Uncapped Management GmbH",
      "value": "Uncapped Management GmbH"
    },
    {
      "label": "UniCredit Bank AG",
      "value": "UniCredit Bank AG"
    },
    {
      "label": "UniCredit Leasing Aviation GmbH",
      "value": "UniCredit Leasing Aviation GmbH"
    },
    {
      "label": "UniCredit Leasing Finance GmbH",
      "value": "UniCredit Leasing Finance GmbH"
    },
    {
      "label": "UniCredit Leasing GmbH",
      "value": "UniCredit Leasing GmbH"
    },
    {
      "label": "UniCredit S.p.A. Zweigniederlassung München",
      "value": "UniCredit S.p.A. Zweigniederlassung München"
    },
    {
      "label": "UNIFACT GmbH",
      "value": "UNIFACT GmbH"
    },
    {
      "label": "Unifier Ventures Management GmbH",
      "value": "Unifier Ventures Management GmbH"
    },
    {
      "label": "UNIGESTION ASSET MANAGEMENT (Düsseldorf) SA",
      "value": "UNIGESTION ASSET MANAGEMENT (Düsseldorf) SA"
    },
    {
      "label": "UNIKAT Gesellschaft für Finanz-Management und Vermögensverwaltung mbH",
      "value": "UNIKAT Gesellschaft für Finanz-Management und Vermögensverwaltung mbH"
    },
    {
      "label": "Union - Bank, Aktiengesellschaft",
      "value": "Union - Bank, Aktiengesellschaft"
    },
    {
      "label": "Union Investment Institutional GmbH",
      "value": "Union Investment Institutional GmbH"
    },
    {
      "label": "Union Investment Institutional Property GmbH",
      "value": "Union Investment Institutional Property GmbH"
    },
    {
      "label": "Union Investment Privatfonds GmbH",
      "value": "Union Investment Privatfonds GmbH"
    },
    {
      "label": "Union Investment Real Estate GmbH",
      "value": "Union Investment Real Estate GmbH"
    },
    {
      "label": "Union Investment Service Bank AG",
      "value": "Union Investment Service Bank AG"
    },
    {
      "label": "UNION KRANKENVERSICHERUNG AKTIENGESELLSCHAFT",
      "value": "UNION KRANKENVERSICHERUNG AKTIENGESELLSCHAFT"
    },
    {
      "label": "Union Reiseversicherung Aktiengesellschaft",
      "value": "Union Reiseversicherung Aktiengesellschaft"
    },
    {
      "label": "Uniper Market Solutions GmbH",
      "value": "Uniper Market Solutions GmbH"
    },
    {
      "label": "UNIQA Österreich Versicherungen AG, Wien",
      "value": "UNIQA Österreich Versicherungen AG, Wien"
    },
    {
      "label": "UNISONO Asset Management GmbH",
      "value": "UNISONO Asset Management GmbH"
    },
    {
      "label": "UnitedPensions Deutschland AG",
      "value": "UnitedPensions Deutschland AG"
    },
    {
      "label": "uniVersa Allgemeine Versicherung AG",
      "value": "uniVersa Allgemeine Versicherung AG"
    },
    {
      "label": "uniVersa Krankenversicherung a.G.",
      "value": "uniVersa Krankenversicherung a.G."
    },
    {
      "label": "uniVersa Lebensversicherung a.G.",
      "value": "uniVersa Lebensversicherung a.G."
    },
    {
      "label": "Universal-Investment-Gesellschaft mit beschränkter Haftung",
      "value": "Universal-Investment-Gesellschaft mit beschränkter Haftung"
    },
    {
      "label": "Universal-Investment-Luxembourg S.A. Zweigniederlassung Frankfurt",
      "value": "Universal-Investment-Luxembourg S.A. Zweigniederlassung Frankfurt"
    },
    {
      "label": "Unternehmertum Venture Capital Partners GmbH",
      "value": "Unternehmertum Venture Capital Partners GmbH"
    },
    {
      "label": "Unzer E-Com GmbH",
      "value": "Unzer E-Com GmbH"
    },
    {
      "label": "Upside Beteiligungs-AG",
      "value": "Upside Beteiligungs-AG"
    },
    {
      "label": "Upside Co-Invest 2019 GmbH",
      "value": "Upside Co-Invest 2019 GmbH"
    },
    {
      "label": "Upvest GmbH",
      "value": "Upvest GmbH"
    },
    {
      "label": "Upvest Securities GmbH",
      "value": "Upvest Securities GmbH"
    },
    {
      "label": "USAA S.A. Frankfurt Claims Branch",
      "value": "USAA S.A. Frankfurt Claims Branch"
    },
    {
      "label": "USP Network Verwaltungs GmbH",
      "value": "USP Network Verwaltungs GmbH"
    },
    {
      "label": "Ute Schaefer Wealth GmbH",
      "value": "Ute Schaefer Wealth GmbH"
    },
    {
      "label": "UV Richard Bäcker e.K.",
      "value": "UV Richard Bäcker e.K."
    },
    {
      "label": "UVW Leasing GmbH - Mobilienleasing",
      "value": "UVW Leasing GmbH - Mobilienleasing"
    },
    {
      "label": "V & W Vermögen & Werte GmbH",
      "value": "V & W Vermögen & Werte GmbH"
    },
    {
      "label": "V-Bank AG",
      "value": "V-Bank AG"
    },
    {
      "label": "V.M.Z. Vermögensverwaltungsgesellschaft Dr. Markus C. Zschaber mbH",
      "value": "V.M.Z. Vermögensverwaltungsgesellschaft Dr. Markus C. Zschaber mbH"
    },
    {
      "label": "VA-Leasing GmbH & Co. KG",
      "value": "VA-Leasing GmbH & Co. KG"
    },
    {
      "label": "VakifBank International AG, Wien, Zweigniederlassung Deutschland",
      "value": "VakifBank International AG, Wien, Zweigniederlassung Deutschland"
    },
    {
      "label": "Vakuutusosakeyhtiö Bothnia international Zweigniederlassung Deutschland c/o Compre Services (Germany) GmbH",
      "value": "Vakuutusosakeyhtiö Bothnia international Zweigniederlassung Deutschland c/o Compre Services (Germany) GmbH"
    },
    {
      "label": "VALORA EFFEKTEN HANDEL AG",
      "value": "VALORA EFFEKTEN HANDEL AG"
    },
    {
      "label": "Valorvest Vermögensverwaltungsgesellschaft mbH & Co. KG",
      "value": "Valorvest Vermögensverwaltungsgesellschaft mbH & Co. KG"
    },
    {
      "label": "Value Experts Vermögensverwaltungs AG",
      "value": "Value Experts Vermögensverwaltungs AG"
    },
    {
      "label": "Value Intelligence Advisors GmbH",
      "value": "Value Intelligence Advisors GmbH"
    },
    {
      "label": "Value-Holdings Capital Partners AG",
      "value": "Value-Holdings Capital Partners AG"
    },
    {
      "label": "VALUES. Institutional Invest GmbH",
      "value": "VALUES. Institutional Invest GmbH"
    },
    {
      "label": "Valuta Factoringgesellschaft mbH",
      "value": "Valuta Factoringgesellschaft mbH"
    },
    {
      "label": "Valuta Leasing GmbH",
      "value": "Valuta Leasing GmbH"
    },
    {
      "label": "VanEck (Europe) GmbH",
      "value": "VanEck (Europe) GmbH"
    },
    {
      "label": "Vanguard Group (Ireland) Limited (Frankfurt Branch)",
      "value": "Vanguard Group (Ireland) Limited (Frankfurt Branch)"
    },
    {
      "label": "Vanguard Group Europe GmbH",
      "value": "Vanguard Group Europe GmbH"
    },
    {
      "label": "Varengold Bank AG",
      "value": "Varengold Bank AG"
    },
    {
      "label": "Vattenfall Europe Power Management GmbH",
      "value": "Vattenfall Europe Power Management GmbH"
    },
    {
      "label": "VBU Volksbank im Unterland eG",
      "value": "VBU Volksbank im Unterland eG"
    },
    {
      "label": "VC Germany Management GmbH",
      "value": "VC Germany Management GmbH"
    },
    {
      "label": "VC-GO Management GmbH",
      "value": "VC-GO Management GmbH"
    },
    {
      "label": "VCDE Venture Partners GmbH & Co. KG",
      "value": "VCDE Venture Partners GmbH & Co. KG"
    },
    {
      "label": "VdW Pensionsfonds AG",
      "value": "VdW Pensionsfonds AG"
    },
    {
      "label": "Veermaster Asset Management GmbH",
      "value": "Veermaster Asset Management GmbH"
    },
    {
      "label": "Venture Stars Kapitalverwaltungsgesellschaft mbH",
      "value": "Venture Stars Kapitalverwaltungsgesellschaft mbH"
    },
    {
      "label": "VerbundSparkasse Emsdetten Ochtrup",
      "value": "VerbundSparkasse Emsdetten Ochtrup"
    },
    {
      "label": "VerbundVolksbank OWL eG",
      "value": "VerbundVolksbank OWL eG"
    },
    {
      "label": "Vereinigte Hagelversicherung VVaG",
      "value": "Vereinigte Hagelversicherung VVaG"
    },
    {
      "label": "VEREINIGTE POSTVERSICHERUNG VVaG",
      "value": "VEREINIGTE POSTVERSICHERUNG VVaG"
    },
    {
      "label": "Vereinigte Raiffeisenbank Burgstädt eG",
      "value": "Vereinigte Raiffeisenbank Burgstädt eG"
    },
    {
      "label": "Vereinigte Schiffs-Versicherung V. a. G.",
      "value": "Vereinigte Schiffs-Versicherung V. a. G."
    },
    {
      "label": "Vereinigte Sparkasse im Märkischen Kreis, Zweckverbandssparkasse der Städte Altena, Balve, Neuenrade, Plettenberg und Werdohl sowie der Gemeinde Nachrodt-Wiblingwerde",
      "value": "Vereinigte Sparkasse im Märkischen Kreis, Zweckverbandssparkasse der Städte Altena, Balve, Neuenrade, Plettenberg und Werdohl sowie der Gemeinde Nachrodt-Wiblingwerde"
    },
    {
      "label": "Vereinigte Sparkassen Eschenbach i.d.OPf. Neustadt a.d.Waldnaab Vohenstrauß",
      "value": "Vereinigte Sparkassen Eschenbach i.d.OPf. Neustadt a.d.Waldnaab Vohenstrauß"
    },
    {
      "label": "Vereinigte Sparkassen Gunzenhausen",
      "value": "Vereinigte Sparkassen Gunzenhausen"
    },
    {
      "label": "Vereinigte Tierversicherung, Gesellschaft auf Gegenseitigkeit",
      "value": "Vereinigte Tierversicherung, Gesellschaft auf Gegenseitigkeit"
    },
    {
      "label": "Vereinigte Volksbank eG",
      "value": "Vereinigte Volksbank eG"
    },
    {
      "label": "Vereinigte Volksbank eG Bramgau Osnabrück Wittlage",
      "value": "Vereinigte Volksbank eG Bramgau Osnabrück Wittlage"
    },
    {
      "label": "Vereinigte Volksbank eG Ganderkesee-Hude-Bookholzberg-Lemwerder",
      "value": "Vereinigte Volksbank eG Ganderkesee-Hude-Bookholzberg-Lemwerder"
    },
    {
      "label": "Vereinigte Volksbank eG Saarlouis - Losheim am See - Sulzbach/Saar",
      "value": "Vereinigte Volksbank eG Saarlouis - Losheim am See - Sulzbach/Saar"
    },
    {
      "label": "Vereinigte Volksbank Raiffeisenbank eG",
      "value": "Vereinigte Volksbank Raiffeisenbank eG"
    },
    {
      "label": "Vereinigte Volksbank Raiffeisenbank eG",
      "value": "Vereinigte Volksbank Raiffeisenbank eG"
    },
    {
      "label": "Vereinigte Volksbanken eG",
      "value": "Vereinigte Volksbanken eG"
    },
    {
      "label": "Vereinigte VR Bank eG",
      "value": "Vereinigte VR Bank eG"
    },
    {
      "label": "Vereinigte VR Bank Kur- und Rheinpfalz eG",
      "value": "Vereinigte VR Bank Kur- und Rheinpfalz eG"
    },
    {
      "label": "Vereinte Volksbank eG",
      "value": "Vereinte Volksbank eG"
    },
    {
      "label": "VERIANOS Capital Partners GmbH",
      "value": "VERIANOS Capital Partners GmbH"
    },
    {
      "label": "Verida Asset Management GmbH",
      "value": "Verida Asset Management GmbH"
    },
    {
      "label": "Verimi GmbH",
      "value": "Verimi GmbH"
    },
    {
      "label": "VERKA PK Kirchliche Pensionskasse AG",
      "value": "VERKA PK Kirchliche Pensionskasse AG"
    },
    {
      "label": "VERKA VK Kirchliche Vorsorge VVaG",
      "value": "VERKA VK Kirchliche Vorsorge VVaG"
    },
    {
      "label": "Vermögensanlage AltBayern AG",
      "value": "Vermögensanlage AltBayern AG"
    },
    {
      "label": "Vermögensbutler AG",
      "value": "Vermögensbutler AG"
    },
    {
      "label": "Vermögenskultur AG Gesellschaft für Familienvermögen und Stiftungen",
      "value": "Vermögenskultur AG Gesellschaft für Familienvermögen und Stiftungen"
    },
    {
      "label": "Vermögensmanagement EuroSwitch! GmbH",
      "value": "Vermögensmanagement EuroSwitch! GmbH"
    },
    {
      "label": "Vermögensverwalter Richter & Steinberger GmbH",
      "value": "Vermögensverwalter Richter & Steinberger GmbH"
    },
    {
      "label": "Versicherer im Raum der Kirchen Krankenversicherung AG",
      "value": "Versicherer im Raum der Kirchen Krankenversicherung AG"
    },
    {
      "label": "Versicherer im Raum der Kirchen Lebensversicherung AG",
      "value": "Versicherer im Raum der Kirchen Lebensversicherung AG"
    },
    {
      "label": "Versicherer im Raum der Kirchen Sachversicherung AG",
      "value": "Versicherer im Raum der Kirchen Sachversicherung AG"
    },
    {
      "label": "Versicherungskammer Bayern Konzern-Rückversicherung Aktiengesellschaft",
      "value": "Versicherungskammer Bayern Konzern-Rückversicherung Aktiengesellschaft"
    },
    {
      "label": "Versicherungskammer Bayern Pensionskasse Aktiengesellschaft",
      "value": "Versicherungskammer Bayern Pensionskasse Aktiengesellschaft"
    },
    {
      "label": "Versicherungskammer Bayern Versicherungsanstalt des öffentlichen Rechts",
      "value": "Versicherungskammer Bayern Versicherungsanstalt des öffentlichen Rechts"
    },
    {
      "label": "Versicherungsverband Deutscher Eisenbahnen-Versicherungsverein auf Gegenseitigkeit",
      "value": "Versicherungsverband Deutscher Eisenbahnen-Versicherungsverein auf Gegenseitigkeit"
    },
    {
      "label": "Versicherungsverein \"Kurhessische Poststerbekasse",
      "value": "Versicherungsverein \"Kurhessische Poststerbekasse"
    },
    {
      "label": "Versicherungsverein Rasselstein",
      "value": "Versicherungsverein Rasselstein"
    },
    {
      "label": "Versorgungsanstalt des Bundes und der Länder",
      "value": "Versorgungsanstalt des Bundes und der Länder"
    },
    {
      "label": "Versorgungsausgleichskasse Pensionskasse VVaG",
      "value": "Versorgungsausgleichskasse Pensionskasse VVaG"
    },
    {
      "label": "Versorgungskasse der Angestellten der GEA Group Aktiengesellschaft VVaG",
      "value": "Versorgungskasse der Angestellten der GEA Group Aktiengesellschaft VVaG"
    },
    {
      "label": "Versorgungskasse der Angestellten der Norddeutschen Affinerie",
      "value": "Versorgungskasse der Angestellten der Norddeutschen Affinerie"
    },
    {
      "label": "Versorgungskasse der Arbeiter und Angestellten der ehemaligen Großkraftwerk Franken AG",
      "value": "Versorgungskasse der Arbeiter und Angestellten der ehemaligen Großkraftwerk Franken AG"
    },
    {
      "label": "Versorgungskasse der Bayerischen Milchindustrie Landshut eG Nürnberg VVaG i.L.",
      "value": "Versorgungskasse der Bayerischen Milchindustrie Landshut eG Nürnberg VVaG i.L."
    },
    {
      "label": "Versorgungskasse der Deutscher Herold Versicherungsgesellschaften,Versicherungsverein a.G., Bonn",
      "value": "Versorgungskasse der Deutscher Herold Versicherungsgesellschaften,Versicherungsverein a.G., Bonn"
    },
    {
      "label": "Versorgungskasse der ehemaligen Bayernwerk AG, Versicherungsverein auf Gegenseitigkeit",
      "value": "Versorgungskasse der ehemaligen Bayernwerk AG, Versicherungsverein auf Gegenseitigkeit"
    },
    {
      "label": "Versorgungskasse der Firma M. DuMont Schauberg Expedition der Kölnischen Zeitung",
      "value": "Versorgungskasse der Firma M. DuMont Schauberg Expedition der Kölnischen Zeitung"
    },
    {
      "label": "Versorgungskasse der Volksfürsorge VVaG",
      "value": "Versorgungskasse der Volksfürsorge VVaG"
    },
    {
      "label": "Versorgungskasse des Norddeutschen Lloyd i.L.",
      "value": "Versorgungskasse des Norddeutschen Lloyd i.L."
    },
    {
      "label": "Versorgungskasse Deutscher Unternehmen, Versicherungsverein auf Gegenseitigkeit",
      "value": "Versorgungskasse Deutscher Unternehmen, Versicherungsverein auf Gegenseitigkeit"
    },
    {
      "label": "Versorgungskasse Energie Versicherungsverein auf Gegenseitigkeit i.L.",
      "value": "Versorgungskasse Energie Versicherungsverein auf Gegenseitigkeit i.L."
    },
    {
      "label": "Versorgungskasse f. d. Angest. d. AachenMünchener Versicherung AG u.d. Generali Deutschland AG",
      "value": "Versorgungskasse f. d. Angest. d. AachenMünchener Versicherung AG u.d. Generali Deutschland AG"
    },
    {
      "label": "Versorgungskasse Gothaer Versicherungsbank VVaG",
      "value": "Versorgungskasse Gothaer Versicherungsbank VVaG"
    },
    {
      "label": "Versorgungskasse Radio Bremen",
      "value": "Versorgungskasse Radio Bremen"
    },
    {
      "label": "Versorgungslasten-Ausgleichskasse des Genossenschaftsverbandes-Verband der Regionen e.V.- VVaG - Hannover",
      "value": "Versorgungslasten-Ausgleichskasse des Genossenschaftsverbandes-Verband der Regionen e.V.- VVaG - Hannover"
    },
    {
      "label": "Verti Versicherung AG",
      "value": "Verti Versicherung AG"
    },
    {
      "label": "VET-Leasing GmbH",
      "value": "VET-Leasing GmbH"
    },
    {
      "label": "VetDirekt Leasing GmbH & Co. KG",
      "value": "VetDirekt Leasing GmbH & Co. KG"
    },
    {
      "label": "VfH Verrechnungsstelle für Heilberufe GmbH",
      "value": "VfH Verrechnungsstelle für Heilberufe GmbH"
    },
    {
      "label": "VHV Allgemeine Versicherung AG",
      "value": "VHV Allgemeine Versicherung AG"
    },
    {
      "label": "VHV Vereinigte Hannoversche Versicherung a.G.",
      "value": "VHV Vereinigte Hannoversche Versicherung a.G."
    },
    {
      "label": "Victoria Lebensversicherung Aktiengesellschaft",
      "value": "Victoria Lebensversicherung Aktiengesellschaft"
    },
    {
      "label": "Vidia GmbH",
      "value": "Vidia GmbH"
    },
    {
      "label": "Vie Finance A.E.P.E.Y. S.A. Zweigniederlassung Deutschland",
      "value": "Vie Finance A.E.P.E.Y. S.A. Zweigniederlassung Deutschland"
    },
    {
      "label": "Vietnam Joint Stock Commercial Bank for Industry and Trade (VietinBank), Filiale Deutschland",
      "value": "Vietnam Joint Stock Commercial Bank for Industry and Trade (VietinBank), Filiale Deutschland"
    },
    {
      "label": "VIFA Pensionsfonds AG",
      "value": "VIFA Pensionsfonds AG"
    },
    {
      "label": "VIG RE zajiovna, a.s., Niederlassung Deutschland",
      "value": "VIG RE zajiovna, a.s., Niederlassung Deutschland"
    },
    {
      "label": "vigo Krankenversicherung VVaG",
      "value": "vigo Krankenversicherung VVaG"
    },
    {
      "label": "Villa Vitalia Gesundheit & Pflege AG",
      "value": "Villa Vitalia Gesundheit & Pflege AG"
    },
    {
      "label": "Vireo Ventures Management GmbH",
      "value": "Vireo Ventures Management GmbH"
    },
    {
      "label": "Viridium Rückversicherung AG",
      "value": "Viridium Rückversicherung AG"
    },
    {
      "label": "Visionaries Club GmbH",
      "value": "Visionaries Club GmbH"
    },
    {
      "label": "VisualVest GmbH",
      "value": "VisualVest GmbH"
    },
    {
      "label": "VIVUM GmbH",
      "value": "VIVUM GmbH"
    },
    {
      "label": "VM Vermögens-Management GmbH",
      "value": "VM Vermögens-Management GmbH"
    },
    {
      "label": "Vnesheconombank (Bank für Entwicklung und Außenwirtschaft) Repräsentanz in der Bundesrepublik Deutschland",
      "value": "Vnesheconombank (Bank für Entwicklung und Außenwirtschaft) Repräsentanz in der Bundesrepublik Deutschland"
    },
    {
      "label": "Volando Asset Management OHG",
      "value": "Volando Asset Management OHG"
    },
    {
      "label": "Volks- Bau- und Sparverein Frankfurt am Main eingetragene Genossenschaft",
      "value": "Volks- Bau- und Sparverein Frankfurt am Main eingetragene Genossenschaft"
    },
    {
      "label": "Volks- und Raiffeisenbank Fürstenwalde Seelow Wriezen eG",
      "value": "Volks- und Raiffeisenbank Fürstenwalde Seelow Wriezen eG"
    },
    {
      "label": "Volks- und Raiffeisenbank Muldental eG",
      "value": "Volks- und Raiffeisenbank Muldental eG"
    },
    {
      "label": "Volks- und Raiffeisenbank Prignitz eG",
      "value": "Volks- und Raiffeisenbank Prignitz eG"
    },
    {
      "label": "Volks- und Raiffeisenbank Saale-Unstrut eG",
      "value": "Volks- und Raiffeisenbank Saale-Unstrut eG"
    },
    {
      "label": "Volksbank - Raiffeisenbank Vilshofen eG",
      "value": "Volksbank - Raiffeisenbank Vilshofen eG"
    },
    {
      "label": "Volksbank Alb eG",
      "value": "Volksbank Alb eG"
    },
    {
      "label": "Volksbank Albstadt eG",
      "value": "Volksbank Albstadt eG"
    },
    {
      "label": "Volksbank Allgäu-Oberschwaben eG",
      "value": "Volksbank Allgäu-Oberschwaben eG"
    },
    {
      "label": "Volksbank Altshausen eG",
      "value": "Volksbank Altshausen eG"
    },
    {
      "label": "Volksbank Alzey-Worms eG",
      "value": "Volksbank Alzey-Worms eG"
    },
    {
      "label": "Volksbank am Württemberg eG",
      "value": "Volksbank am Württemberg eG"
    },
    {
      "label": "Volksbank Ammerbuch eG",
      "value": "Volksbank Ammerbuch eG"
    },
    {
      "label": "Volksbank an der Niers eG",
      "value": "Volksbank an der Niers eG"
    },
    {
      "label": "Volksbank Anröchte e. G.",
      "value": "Volksbank Anröchte e. G."
    },
    {
      "label": "Volksbank Ascheberg - Herbern eG",
      "value": "Volksbank Ascheberg - Herbern eG"
    },
    {
      "label": "Volksbank Backnang eG",
      "value": "Volksbank Backnang eG"
    },
    {
      "label": "Volksbank Bad Salzuflen eG",
      "value": "Volksbank Bad Salzuflen eG"
    },
    {
      "label": "Volksbank Bad Saulgau eG",
      "value": "Volksbank Bad Saulgau eG"
    },
    {
      "label": "Volksbank Baumberge eG",
      "value": "Volksbank Baumberge eG"
    },
    {
      "label": "Volksbank Beckum-Lippstadt eG",
      "value": "Volksbank Beckum-Lippstadt eG"
    },
    {
      "label": "Volksbank Beilstein-Ilsfeld-Abstatt eG",
      "value": "Volksbank Beilstein-Ilsfeld-Abstatt eG"
    },
    {
      "label": "Volksbank Berg eG",
      "value": "Volksbank Berg eG"
    },
    {
      "label": "Volksbank Bielefeld-Gütersloh eG",
      "value": "Volksbank Bielefeld-Gütersloh eG"
    },
    {
      "label": "Volksbank Bocholt eG",
      "value": "Volksbank Bocholt eG"
    },
    {
      "label": "Volksbank Bochum Witten eG",
      "value": "Volksbank Bochum Witten eG"
    },
    {
      "label": "Volksbank Brandoberndorf eG",
      "value": "Volksbank Brandoberndorf eG"
    },
    {
      "label": "Volksbank Braunlage e.G.",
      "value": "Volksbank Braunlage e.G."
    },
    {
      "label": "Volksbank Breisgau Nord eG",
      "value": "Volksbank Breisgau Nord eG"
    },
    {
      "label": "Volksbank Breisgau-Markgräflerland eG",
      "value": "Volksbank Breisgau-Markgräflerland eG"
    },
    {
      "label": "Volksbank Bremen-Nord eG",
      "value": "Volksbank Bremen-Nord eG"
    },
    {
      "label": "Volksbank Brenztal eG",
      "value": "Volksbank Brenztal eG"
    },
    {
      "label": "Volksbank Bruchsal-Bretten eG",
      "value": "Volksbank Bruchsal-Bretten eG"
    },
    {
      "label": "Volksbank Butzbach eG",
      "value": "Volksbank Butzbach eG"
    },
    {
      "label": "Volksbank Bönen e.G.",
      "value": "Volksbank Bönen e.G."
    },
    {
      "label": "Volksbank Börde-Bernburg eG",
      "value": "Volksbank Börde-Bernburg eG"
    },
    {
      "label": "Volksbank Börßum-Hornburg eG",
      "value": "Volksbank Börßum-Hornburg eG"
    },
    {
      "label": "Volksbank Bühl eG",
      "value": "Volksbank Bühl eG"
    },
    {
      "label": "Volksbank Chemnitz eG",
      "value": "Volksbank Chemnitz eG"
    },
    {
      "label": "Volksbank Daaden eG",
      "value": "Volksbank Daaden eG"
    },
    {
      "label": "Volksbank Dammer Berge eG",
      "value": "Volksbank Dammer Berge eG"
    },
    {
      "label": "Volksbank Darmstadt-Südhessen eG",
      "value": "Volksbank Darmstadt-Südhessen eG"
    },
    {
      "label": "VOLKSBANK DEISSLINGEN eG",
      "value": "VOLKSBANK DEISSLINGEN eG"
    },
    {
      "label": "Volksbank Delbrück-Hövelhof eG",
      "value": "Volksbank Delbrück-Hövelhof eG"
    },
    {
      "label": "Volksbank Delitzsch eG",
      "value": "Volksbank Delitzsch eG"
    },
    {
      "label": "Volksbank Demmin eG",
      "value": "Volksbank Demmin eG"
    },
    {
      "label": "Volksbank Dessau-Anhalt eG",
      "value": "Volksbank Dessau-Anhalt eG"
    },
    {
      "label": "Volksbank Dettenhausen eG",
      "value": "Volksbank Dettenhausen eG"
    },
    {
      "label": "Volksbank Dortmund-Nordwest eG.",
      "value": "Volksbank Dortmund-Nordwest eG."
    },
    {
      "label": "Volksbank Dreiländereck eG",
      "value": "Volksbank Dreiländereck eG"
    },
    {
      "label": "Volksbank Dresden-Bautzen eG",
      "value": "Volksbank Dresden-Bautzen eG"
    },
    {
      "label": "Volksbank Dünnwald-Holweide eG",
      "value": "Volksbank Dünnwald-Holweide eG"
    },
    {
      "label": "Volksbank Düsseldorf Neuss eG",
      "value": "Volksbank Düsseldorf Neuss eG"
    },
    {
      "label": "Volksbank eG",
      "value": "Volksbank eG"
    },
    {
      "label": "Volksbank eG",
      "value": "Volksbank eG"
    },
    {
      "label": "Volksbank eG",
      "value": "Volksbank eG"
    },
    {
      "label": "Volksbank eG",
      "value": "Volksbank eG"
    },
    {
      "label": "Volksbank eG",
      "value": "Volksbank eG"
    },
    {
      "label": "Volksbank eG",
      "value": "Volksbank eG"
    },
    {
      "label": "Volksbank eG",
      "value": "Volksbank eG"
    },
    {
      "label": "Volksbank eG",
      "value": "Volksbank eG"
    },
    {
      "label": "Volksbank eG",
      "value": "Volksbank eG"
    },
    {
      "label": "Volksbank eG",
      "value": "Volksbank eG"
    },
    {
      "label": "Volksbank eG",
      "value": "Volksbank eG"
    },
    {
      "label": "Volksbank eG",
      "value": "Volksbank eG"
    },
    {
      "label": "Volksbank eG",
      "value": "Volksbank eG"
    },
    {
      "label": "Volksbank eG",
      "value": "Volksbank eG"
    },
    {
      "label": "Volksbank eG",
      "value": "Volksbank eG"
    },
    {
      "label": "Volksbank eG",
      "value": "Volksbank eG"
    },
    {
      "label": "Volksbank eG",
      "value": "Volksbank eG"
    },
    {
      "label": "Volksbank eG",
      "value": "Volksbank eG"
    },
    {
      "label": "Volksbank eG",
      "value": "Volksbank eG"
    },
    {
      "label": "Volksbank eG Bad Laer-Borgloh-Hilter-Melle",
      "value": "Volksbank eG Bad Laer-Borgloh-Hilter-Melle"
    },
    {
      "label": "Volksbank eG Braunschweig Wolfsburg",
      "value": "Volksbank eG Braunschweig Wolfsburg"
    },
    {
      "label": "Volksbank eG Bremerhaven-Cuxland",
      "value": "Volksbank eG Bremerhaven-Cuxland"
    },
    {
      "label": "Volksbank eG Delmenhorst Schierbrok",
      "value": "Volksbank eG Delmenhorst Schierbrok"
    },
    {
      "label": "Volksbank eG Gera . Jena . Rudolstadt",
      "value": "Volksbank eG Gera . Jena . Rudolstadt"
    },
    {
      "label": "Volksbank eG im Kreis Freudenstadt",
      "value": "Volksbank eG im Kreis Freudenstadt"
    },
    {
      "label": "Volksbank eG Mosbach",
      "value": "Volksbank eG Mosbach"
    },
    {
      "label": "Volksbank eG Südheide - Isenhagener Land - Altmark",
      "value": "Volksbank eG Südheide - Isenhagener Land - Altmark"
    },
    {
      "label": "Volksbank eG Westrhauderfehn",
      "value": "Volksbank eG Westrhauderfehn"
    },
    {
      "label": "Volksbank Eifel eG",
      "value": "Volksbank Eifel eG"
    },
    {
      "label": "Volksbank Eisenberg eG",
      "value": "Volksbank Eisenberg eG"
    },
    {
      "label": "Volksbank Elsen-Wewer-Borchen eG",
      "value": "Volksbank Elsen-Wewer-Borchen eG"
    },
    {
      "label": "Volksbank Emmerich-Rees eG",
      "value": "Volksbank Emmerich-Rees eG"
    },
    {
      "label": "Volksbank Emstal eG",
      "value": "Volksbank Emstal eG"
    },
    {
      "label": "Volksbank Emstek eG",
      "value": "Volksbank Emstek eG"
    },
    {
      "label": "Volksbank Enniger-Ostenfelde-Westkirchen eG",
      "value": "Volksbank Enniger-Ostenfelde-Westkirchen eG"
    },
    {
      "label": "Volksbank Erft eG",
      "value": "Volksbank Erft eG"
    },
    {
      "label": "Volksbank Ermstal-Alb eG",
      "value": "Volksbank Ermstal-Alb eG"
    },
    {
      "label": "Volksbank Esens eG",
      "value": "Volksbank Esens eG"
    },
    {
      "label": "Volksbank Essen-Cappeln eG",
      "value": "Volksbank Essen-Cappeln eG"
    },
    {
      "label": "Volksbank Ettlingen eG",
      "value": "Volksbank Ettlingen eG"
    },
    {
      "label": "Volksbank Euskirchen eG",
      "value": "Volksbank Euskirchen eG"
    },
    {
      "label": "Volksbank Eutin Raiffeisenbank eingetragene Genossenschaft",
      "value": "Volksbank Eutin Raiffeisenbank eingetragene Genossenschaft"
    },
    {
      "label": "Volksbank Feldatal eG",
      "value": "Volksbank Feldatal eG"
    },
    {
      "label": "Volksbank Filder eG",
      "value": "Volksbank Filder eG"
    },
    {
      "label": "Volksbank Flein-Talheim eG",
      "value": "Volksbank Flein-Talheim eG"
    },
    {
      "label": "Volksbank Franken eG",
      "value": "Volksbank Franken eG"
    },
    {
      "label": "Volksbank Freiburg eG",
      "value": "Volksbank Freiburg eG"
    },
    {
      "label": "Volksbank Friedrichshafen-Tettnang eG",
      "value": "Volksbank Friedrichshafen-Tettnang eG"
    },
    {
      "label": "Volksbank Gebhardshain eG",
      "value": "Volksbank Gebhardshain eG"
    },
    {
      "label": "Volksbank Geest eG",
      "value": "Volksbank Geest eG"
    },
    {
      "label": "Volksbank Geeste-Nord eG",
      "value": "Volksbank Geeste-Nord eG"
    },
    {
      "label": "Volksbank Gemen eG",
      "value": "Volksbank Gemen eG"
    },
    {
      "label": "Volksbank Gescher eG",
      "value": "Volksbank Gescher eG"
    },
    {
      "label": "Volksbank Glan-Münchweiler eingetragene Genossenschaft",
      "value": "Volksbank Glan-Münchweiler eingetragene Genossenschaft"
    },
    {
      "label": "Volksbank GMHütte-Hagen-Bissendorf eG (GHB)",
      "value": "Volksbank GMHütte-Hagen-Bissendorf eG (GHB)"
    },
    {
      "label": "Volksbank Gronau-Ahaus eG",
      "value": "Volksbank Gronau-Ahaus eG"
    },
    {
      "label": "Volksbank Göppingen eG",
      "value": "Volksbank Göppingen eG"
    },
    {
      "label": "Volksbank Haaren e.G.",
      "value": "Volksbank Haaren e.G."
    },
    {
      "label": "Volksbank Halle (Saale) eG",
      "value": "Volksbank Halle (Saale) eG"
    },
    {
      "label": "Volksbank Halle/Westf. eG",
      "value": "Volksbank Halle/Westf. eG"
    },
    {
      "label": "Volksbank Hameln-Stadthagen eG",
      "value": "Volksbank Hameln-Stadthagen eG"
    },
    {
      "label": "Volksbank Hamm/Sieg eingetragene Genossenschaft",
      "value": "Volksbank Hamm/Sieg eingetragene Genossenschaft"
    },
    {
      "label": "Volksbank Haselünne eG",
      "value": "Volksbank Haselünne eG"
    },
    {
      "label": "Volksbank Heiden eG",
      "value": "Volksbank Heiden eG"
    },
    {
      "label": "Volksbank Heimbach eG",
      "value": "Volksbank Heimbach eG"
    },
    {
      "label": "Volksbank Heinsberg eG",
      "value": "Volksbank Heinsberg eG"
    },
    {
      "label": "Volksbank Hellweg eG",
      "value": "Volksbank Hellweg eG"
    },
    {
      "label": "Volksbank Herford-Mindener Land eG",
      "value": "Volksbank Herford-Mindener Land eG"
    },
    {
      "label": "Volksbank Heuchelheim eG",
      "value": "Volksbank Heuchelheim eG"
    },
    {
      "label": "Volksbank Hochrhein eG",
      "value": "Volksbank Hochrhein eG"
    },
    {
      "label": "Volksbank Hohenlimburg eG",
      "value": "Volksbank Hohenlimburg eG"
    },
    {
      "label": "Volksbank Hohenlohe eG",
      "value": "Volksbank Hohenlohe eG"
    },
    {
      "label": "Volksbank Hohenzollern-Balingen eG",
      "value": "Volksbank Hohenzollern-Balingen eG"
    },
    {
      "label": "Volksbank im Bergischen Land eG",
      "value": "Volksbank im Bergischen Land eG"
    },
    {
      "label": "Volksbank im Harz eG",
      "value": "Volksbank im Harz eG"
    },
    {
      "label": "Volksbank im Hochsauerland eG",
      "value": "Volksbank im Hochsauerland eG"
    },
    {
      "label": "Volksbank im Wesertal eG",
      "value": "Volksbank im Wesertal eG"
    },
    {
      "label": "VOLKSBANK IMMENSTADT eG",
      "value": "VOLKSBANK IMMENSTADT eG"
    },
    {
      "label": "Volksbank in der Hohen Mark eG",
      "value": "Volksbank in der Hohen Mark eG"
    },
    {
      "label": "Volksbank in der Region eG",
      "value": "Volksbank in der Region eG"
    },
    {
      "label": "Volksbank in Südwestfalen eG",
      "value": "Volksbank in Südwestfalen eG"
    },
    {
      "label": "Volksbank Jerichower Land eG",
      "value": "Volksbank Jerichower Land eG"
    },
    {
      "label": "Volksbank Jever eG",
      "value": "Volksbank Jever eG"
    },
    {
      "label": "Volksbank Kaiserslautern eG",
      "value": "Volksbank Kaiserslautern eG"
    },
    {
      "label": "Volksbank Kassel Göttingen eG",
      "value": "Volksbank Kassel Göttingen eG"
    },
    {
      "label": "Volksbank Kempen-Grefrath eG",
      "value": "Volksbank Kempen-Grefrath eG"
    },
    {
      "label": "Volksbank Kierspe eG",
      "value": "Volksbank Kierspe eG"
    },
    {
      "label": "Volksbank Kirnau eG",
      "value": "Volksbank Kirnau eG"
    },
    {
      "label": "Volksbank Klettgau-Wutöschingen eG",
      "value": "Volksbank Klettgau-Wutöschingen eG"
    },
    {
      "label": "Volksbank Kleverland eG",
      "value": "Volksbank Kleverland eG"
    },
    {
      "label": "Volksbank Kraichgau eG",
      "value": "Volksbank Kraichgau eG"
    },
    {
      "label": "Volksbank Krautheim eG",
      "value": "Volksbank Krautheim eG"
    },
    {
      "label": "Volksbank Krefeld eG",
      "value": "Volksbank Krefeld eG"
    },
    {
      "label": "Volksbank Kurpfalz eG",
      "value": "Volksbank Kurpfalz eG"
    },
    {
      "label": "Volksbank Köln Bonn eG",
      "value": "Volksbank Köln Bonn eG"
    },
    {
      "label": "Volksbank Lahr eG",
      "value": "Volksbank Lahr eG"
    },
    {
      "label": "Volksbank Langendernbach eG",
      "value": "Volksbank Langendernbach eG"
    },
    {
      "label": "Volksbank Lastrup e.G.",
      "value": "Volksbank Lastrup e.G."
    },
    {
      "label": "Volksbank Lauterbach-Schlitz e.G.",
      "value": "Volksbank Lauterbach-Schlitz e.G."
    },
    {
      "label": "Volksbank Lauterecken eG",
      "value": "Volksbank Lauterecken eG"
    },
    {
      "label": "Volksbank Leonberg-Strohgäu eG",
      "value": "Volksbank Leonberg-Strohgäu eG"
    },
    {
      "label": "Volksbank Limbach eG",
      "value": "Volksbank Limbach eG"
    },
    {
      "label": "Volksbank Lindenberg eG",
      "value": "Volksbank Lindenberg eG"
    },
    {
      "label": "Volksbank Lohne-Mühlen eG",
      "value": "Volksbank Lohne-Mühlen eG"
    },
    {
      "label": "Volksbank Löbau-Zittau eG",
      "value": "Volksbank Löbau-Zittau eG"
    },
    {
      "label": "Volksbank Lübbecker Land eG",
      "value": "Volksbank Lübbecker Land eG"
    },
    {
      "label": "Volksbank Lübeck eG",
      "value": "Volksbank Lübeck eG"
    },
    {
      "label": "Volksbank Lüneburger Heide eG",
      "value": "Volksbank Lüneburger Heide eG"
    },
    {
      "label": "Volksbank Magdeburg eG",
      "value": "Volksbank Magdeburg eG"
    },
    {
      "label": "Volksbank Main-Tauber eG",
      "value": "Volksbank Main-Tauber eG"
    },
    {
      "label": "Volksbank Mainspitze eG",
      "value": "Volksbank Mainspitze eG"
    },
    {
      "label": "Volksbank Marl-Recklinghausen eG.",
      "value": "Volksbank Marl-Recklinghausen eG."
    },
    {
      "label": "Volksbank Meßkirch eG Raiffeisenbank",
      "value": "Volksbank Meßkirch eG Raiffeisenbank"
    },
    {
      "label": "Volksbank Mittelhessen eG",
      "value": "Volksbank Mittelhessen eG"
    },
    {
      "label": "Volksbank Mittlerer Neckar eG",
      "value": "Volksbank Mittlerer Neckar eG"
    },
    {
      "label": "Volksbank Mittlerer Schwarzwald eG",
      "value": "Volksbank Mittlerer Schwarzwald eG"
    },
    {
      "label": "Volksbank Mittleres Erzgebirge eG",
      "value": "Volksbank Mittleres Erzgebirge eG"
    },
    {
      "label": "Volksbank Mittweida eG",
      "value": "Volksbank Mittweida eG"
    },
    {
      "label": "Volksbank Möckmühl eG",
      "value": "Volksbank Möckmühl eG"
    },
    {
      "label": "Volksbank Mönchengladbach eG",
      "value": "Volksbank Mönchengladbach eG"
    },
    {
      "label": "Volksbank Münsingen eG",
      "value": "Volksbank Münsingen eG"
    },
    {
      "label": "Volksbank Münsterland Nord eG",
      "value": "Volksbank Münsterland Nord eG"
    },
    {
      "label": "Volksbank Neckartal eG",
      "value": "Volksbank Neckartal eG"
    },
    {
      "label": "Volksbank Niedergrafschaft eG",
      "value": "Volksbank Niedergrafschaft eG"
    },
    {
      "label": "Volksbank Niederrhein eG",
      "value": "Volksbank Niederrhein eG"
    },
    {
      "label": "Volksbank Niedersachsen-Mitte eG",
      "value": "Volksbank Niedersachsen-Mitte eG"
    },
    {
      "label": "Volksbank Nordharz eG",
      "value": "Volksbank Nordharz eG"
    },
    {
      "label": "Volksbank Nordhümmling eG",
      "value": "Volksbank Nordhümmling eG"
    },
    {
      "label": "Volksbank Nordschwarzwald eG",
      "value": "Volksbank Nordschwarzwald eG"
    },
    {
      "label": "Volksbank Nottuln eG",
      "value": "Volksbank Nottuln eG"
    },
    {
      "label": "Volksbank Ober-Mörlen eG",
      "value": "Volksbank Ober-Mörlen eG"
    },
    {
      "label": "Volksbank Oberberg eG",
      "value": "Volksbank Oberberg eG"
    },
    {
      "label": "Volksbank Ochtrup-Laer eG",
      "value": "Volksbank Ochtrup-Laer eG"
    },
    {
      "label": "Volksbank Olpe-Wenden-Drolshagen eG",
      "value": "Volksbank Olpe-Wenden-Drolshagen eG"
    },
    {
      "label": "Volksbank Ostlippe eG",
      "value": "Volksbank Ostlippe eG"
    },
    {
      "label": "Volksbank Oyten e.G.",
      "value": "Volksbank Oyten e.G."
    },
    {
      "label": "Volksbank Pfullendorf eG",
      "value": "Volksbank Pfullendorf eG"
    },
    {
      "label": "Volksbank Pirna eG",
      "value": "Volksbank Pirna eG"
    },
    {
      "label": "Volksbank Plochingen eG",
      "value": "Volksbank Plochingen eG"
    },
    {
      "label": "Volksbank pur eG",
      "value": "Volksbank pur eG"
    },
    {
      "label": "Volksbank Raesfeld und Erle e.G.",
      "value": "Volksbank Raesfeld und Erle e.G."
    },
    {
      "label": "Volksbank Raiffeisenbank Bad Kissingen eG",
      "value": "Volksbank Raiffeisenbank Bad Kissingen eG"
    },
    {
      "label": "Volksbank Raiffeisenbank Bayern Mitte eG",
      "value": "Volksbank Raiffeisenbank Bayern Mitte eG"
    },
    {
      "label": "Volksbank Raiffeisenbank Dachau eG",
      "value": "Volksbank Raiffeisenbank Dachau eG"
    },
    {
      "label": "Volksbank Raiffeisenbank eG",
      "value": "Volksbank Raiffeisenbank eG"
    },
    {
      "label": "Volksbank Raiffeisenbank Fürstenfeldbruck eG",
      "value": "Volksbank Raiffeisenbank Fürstenfeldbruck eG"
    },
    {
      "label": "Volksbank Raiffeisenbank Laupheim-Illertal eG",
      "value": "Volksbank Raiffeisenbank Laupheim-Illertal eG"
    },
    {
      "label": "Volksbank Raiffeisenbank Meißen Großenhain eG",
      "value": "Volksbank Raiffeisenbank Meißen Großenhain eG"
    },
    {
      "label": "Volksbank Raiffeisenbank Niederschlesien eG",
      "value": "Volksbank Raiffeisenbank Niederschlesien eG"
    },
    {
      "label": "Volksbank Raiffeisenbank Nordoberpfalz eG",
      "value": "Volksbank Raiffeisenbank Nordoberpfalz eG"
    },
    {
      "label": "Volksbank Raiffeisenbank Oberbayern Südost eG",
      "value": "Volksbank Raiffeisenbank Oberbayern Südost eG"
    },
    {
      "label": "Volksbank Raiffeisenbank Regensburg-Schwandorf eG",
      "value": "Volksbank Raiffeisenbank Regensburg-Schwandorf eG"
    },
    {
      "label": "Volksbank Raiffeisenbank Starnberg-Herrsching-Landsberg eG",
      "value": "Volksbank Raiffeisenbank Starnberg-Herrsching-Landsberg eG"
    },
    {
      "label": "Volksbank Raiffeisenbank Würzburg eG",
      "value": "Volksbank Raiffeisenbank Würzburg eG"
    },
    {
      "label": "Volksbank Rathenow eG",
      "value": "Volksbank Rathenow eG"
    },
    {
      "label": "Volksbank Remseck eG",
      "value": "Volksbank Remseck eG"
    },
    {
      "label": "Volksbank Rhede eG",
      "value": "Volksbank Rhede eG"
    },
    {
      "label": "Volksbank Rhein-Erft-Köln eG",
      "value": "Volksbank Rhein-Erft-Köln eG"
    },
    {
      "label": "Volksbank Rhein-Lahn-Limburg eG",
      "value": "Volksbank Rhein-Lahn-Limburg eG"
    },
    {
      "label": "Volksbank Rhein-Lippe eG",
      "value": "Volksbank Rhein-Lippe eG"
    },
    {
      "label": "Volksbank Rhein-Nahe-Hunsrück eG",
      "value": "Volksbank Rhein-Nahe-Hunsrück eG"
    },
    {
      "label": "Volksbank Rhein-Ruhr eG",
      "value": "Volksbank Rhein-Ruhr eG"
    },
    {
      "label": "Volksbank Rhein-Wehra eG",
      "value": "Volksbank Rhein-Wehra eG"
    },
    {
      "label": "Volksbank RheinAhrEifel eG",
      "value": "Volksbank RheinAhrEifel eG"
    },
    {
      "label": "Volksbank Rheinböllen eG",
      "value": "Volksbank Rheinböllen eG"
    },
    {
      "label": "Volksbank Riesa eG",
      "value": "Volksbank Riesa eG"
    },
    {
      "label": "Volksbank Rietberg eG",
      "value": "Volksbank Rietberg eG"
    },
    {
      "label": "Volksbank Rot eG",
      "value": "Volksbank Rot eG"
    },
    {
      "label": "Volksbank Rottweil eG",
      "value": "Volksbank Rottweil eG"
    },
    {
      "label": "Volksbank Ruhr Mitte eG",
      "value": "Volksbank Ruhr Mitte eG"
    },
    {
      "label": "Volksbank Sandhofen eG",
      "value": "Volksbank Sandhofen eG"
    },
    {
      "label": "Volksbank Sauerland eG",
      "value": "Volksbank Sauerland eG"
    },
    {
      "label": "Volksbank Schermbeck e.G.",
      "value": "Volksbank Schermbeck e.G."
    },
    {
      "label": "Volksbank Schlangen e.G.",
      "value": "Volksbank Schlangen e.G."
    },
    {
      "label": "Volksbank Schnathorst eG",
      "value": "Volksbank Schnathorst eG"
    },
    {
      "label": "Volksbank Schupbach eG",
      "value": "Volksbank Schupbach eG"
    },
    {
      "label": "Volksbank Schwanewede eG",
      "value": "Volksbank Schwanewede eG"
    },
    {
      "label": "Volksbank Schwarzwald-Donau-Neckar eG",
      "value": "Volksbank Schwarzwald-Donau-Neckar eG"
    },
    {
      "label": "VOLKSBANK SELIGENSTADT EG",
      "value": "VOLKSBANK SELIGENSTADT EG"
    },
    {
      "label": "Volksbank Selm-Bork eG",
      "value": "Volksbank Selm-Bork eG"
    },
    {
      "label": "Volksbank Senden eG",
      "value": "Volksbank Senden eG"
    },
    {
      "label": "Volksbank Solling eG",
      "value": "Volksbank Solling eG"
    },
    {
      "label": "Volksbank Spree-Neiße eG",
      "value": "Volksbank Spree-Neiße eG"
    },
    {
      "label": "Volksbank Sprockhövel eG.",
      "value": "Volksbank Sprockhövel eG."
    },
    {
      "label": "Volksbank Stade-Cuxhaven eG",
      "value": "Volksbank Stade-Cuxhaven eG"
    },
    {
      "label": "Volksbank Staufen eG",
      "value": "Volksbank Staufen eG"
    },
    {
      "label": "Volksbank Stendal eG",
      "value": "Volksbank Stendal eG"
    },
    {
      "label": "Volksbank Stuttgart eG",
      "value": "Volksbank Stuttgart eG"
    },
    {
      "label": "Volksbank Störmede-Hörste eG",
      "value": "Volksbank Störmede-Hörste eG"
    },
    {
      "label": "Volksbank Sulmtal eG",
      "value": "Volksbank Sulmtal eG"
    },
    {
      "label": "Volksbank Süd-Emsland eG",
      "value": "Volksbank Süd-Emsland eG"
    },
    {
      "label": "Volksbank Südkirchen - Capelle - Nordkirchen eG",
      "value": "Volksbank Südkirchen - Capelle - Nordkirchen eG"
    },
    {
      "label": "Volksbank Südmünsterland-Mitte eG",
      "value": "Volksbank Südmünsterland-Mitte eG"
    },
    {
      "label": "Volksbank Thüringen Mitte eG",
      "value": "Volksbank Thüringen Mitte eG"
    },
    {
      "label": "Volksbank Trier eG",
      "value": "Volksbank Trier eG"
    },
    {
      "label": "Volksbank Trossingen eG",
      "value": "Volksbank Trossingen eG"
    },
    {
      "label": "Volksbank Uelzen-Salzwedel eG",
      "value": "Volksbank Uelzen-Salzwedel eG"
    },
    {
      "label": "Volksbank Ulm-Biberach eG",
      "value": "Volksbank Ulm-Biberach eG"
    },
    {
      "label": "Volksbank Ulrichstein eG",
      "value": "Volksbank Ulrichstein eG"
    },
    {
      "label": "Volksbank Vechta eG",
      "value": "Volksbank Vechta eG"
    },
    {
      "label": "Volksbank Versmold e.G.",
      "value": "Volksbank Versmold e.G."
    },
    {
      "label": "Volksbank Viersen e G",
      "value": "Volksbank Viersen e G"
    },
    {
      "label": "Volksbank Visbek eG",
      "value": "Volksbank Visbek eG"
    },
    {
      "label": "Volksbank Vogtland-Saale-Orla eG",
      "value": "Volksbank Vogtland-Saale-Orla eG"
    },
    {
      "label": "Volksbank Vorpommern eG",
      "value": "Volksbank Vorpommern eG"
    },
    {
      "label": "Volksbank Weschnitztal eG",
      "value": "Volksbank Weschnitztal eG"
    },
    {
      "label": "Volksbank Westenholz eG",
      "value": "Volksbank Westenholz eG"
    },
    {
      "label": "Volksbank Westerkappeln-Saerbeck eG",
      "value": "Volksbank Westerkappeln-Saerbeck eG"
    },
    {
      "label": "Volksbank Westerstede eG",
      "value": "Volksbank Westerstede eG"
    },
    {
      "label": "Volksbank Wickede (Ruhr) eG",
      "value": "Volksbank Wickede (Ruhr) eG"
    },
    {
      "label": "Volksbank Wilhelmshaven e.G.",
      "value": "Volksbank Wilhelmshaven e.G."
    },
    {
      "label": "Volksbank Winsener Marsch eG",
      "value": "Volksbank Winsener Marsch eG"
    },
    {
      "label": "Volksbank Wittenberg eG",
      "value": "Volksbank Wittenberg eG"
    },
    {
      "label": "Volksbank Wittgenstein eG",
      "value": "Volksbank Wittgenstein eG"
    },
    {
      "label": "Volksbank Wißmar eG",
      "value": "Volksbank Wißmar eG"
    },
    {
      "label": "Volksbank Worpswede eG",
      "value": "Volksbank Worpswede eG"
    },
    {
      "label": "Volksbank Zuffenhausen eG",
      "value": "Volksbank Zuffenhausen eG"
    },
    {
      "label": "Volksbank Zwickau eG",
      "value": "Volksbank Zwickau eG"
    },
    {
      "label": "Volksbank Überherrn e.G.",
      "value": "Volksbank Überherrn e.G."
    },
    {
      "label": "Volksbank Überwald-Gorxheimertal eG",
      "value": "Volksbank Überwald-Gorxheimertal eG"
    },
    {
      "label": "Volksbank-Raiffeisenbank Glauchau eG",
      "value": "Volksbank-Raiffeisenbank Glauchau eG"
    },
    {
      "label": "Volkswagen Autoversicherung AG",
      "value": "Volkswagen Autoversicherung AG"
    },
    {
      "label": "Volkswagen Bank Gesellschaft mit beschränkter Haftung",
      "value": "Volkswagen Bank Gesellschaft mit beschränkter Haftung"
    },
    {
      "label": "Volkswagen Leasing Gesellschaft mit beschränkter Haftung",
      "value": "Volkswagen Leasing Gesellschaft mit beschränkter Haftung"
    },
    {
      "label": "Volkswagen Versicherung Aktiengesellschaft",
      "value": "Volkswagen Versicherung Aktiengesellschaft"
    },
    {
      "label": "Volkswohl-Bund Lebensversicherung a.G.",
      "value": "Volkswohl-Bund Lebensversicherung a.G."
    },
    {
      "label": "VOLKSWOHL-BUND SACHVERSICHERUNG AKTIENGESELLSCHAFT",
      "value": "VOLKSWOHL-BUND SACHVERSICHERUNG AKTIENGESELLSCHAFT"
    },
    {
      "label": "Volvo Financial Services GmbH",
      "value": "Volvo Financial Services GmbH"
    },
    {
      "label": "von der Heydt & Co. AG",
      "value": "von der Heydt & Co. AG"
    },
    {
      "label": "von Plettenberg, Conradt & Cie. Family Office AG",
      "value": "von Plettenberg, Conradt & Cie. Family Office AG"
    },
    {
      "label": "Vontobel Asset Management S.A. Niederlassung München",
      "value": "Vontobel Asset Management S.A. Niederlassung München"
    },
    {
      "label": "Vorsorgekasse der Commerzbank Versicherungsverein a.G.",
      "value": "Vorsorgekasse der Commerzbank Versicherungsverein a.G."
    },
    {
      "label": "Vorsorgekasse Hoesch Dortmund Sterbegeldversicherung VVaG",
      "value": "Vorsorgekasse Hoesch Dortmund Sterbegeldversicherung VVaG"
    },
    {
      "label": "Vossloh Familiengemeinschaft GmbH & Co. KG",
      "value": "Vossloh Familiengemeinschaft GmbH & Co. KG"
    },
    {
      "label": "VPL Leasing GmbH",
      "value": "VPL Leasing GmbH"
    },
    {
      "label": "VPV Allgemeine Versicherungs-AG",
      "value": "VPV Allgemeine Versicherungs-AG"
    },
    {
      "label": "VPV Lebensversicherungs-Aktiengesellschaft",
      "value": "VPV Lebensversicherungs-Aktiengesellschaft"
    },
    {
      "label": "VR - Bank Bad Salzungen Schmalkalden eG",
      "value": "VR - Bank Bad Salzungen Schmalkalden eG"
    },
    {
      "label": "VR Bank Alzey-Land-Schwabenheim eG",
      "value": "VR Bank Alzey-Land-Schwabenheim eG"
    },
    {
      "label": "VR Bank Amberg-Sulzbach eG",
      "value": "VR Bank Amberg-Sulzbach eG"
    },
    {
      "label": "VR Bank Augsburg-Ostallgäu eG",
      "value": "VR Bank Augsburg-Ostallgäu eG"
    },
    {
      "label": "VR Bank Bad Orb-Gelnhausen eG",
      "value": "VR Bank Bad Orb-Gelnhausen eG"
    },
    {
      "label": "VR Bank Bamberg-Forchheim eG Volks-Raiffeisenbank",
      "value": "VR Bank Bamberg-Forchheim eG Volks-Raiffeisenbank"
    },
    {
      "label": "VR Bank Bayreuth-Hof eG",
      "value": "VR Bank Bayreuth-Hof eG"
    },
    {
      "label": "VR Bank Dreieich - Offenbach eG",
      "value": "VR Bank Dreieich - Offenbach eG"
    },
    {
      "label": "VR Bank eG",
      "value": "VR Bank eG"
    },
    {
      "label": "VR Bank eG",
      "value": "VR Bank eG"
    },
    {
      "label": "VR Bank eG Bergisch Gladbach-Leverkusen",
      "value": "VR Bank eG Bergisch Gladbach-Leverkusen"
    },
    {
      "label": "VR Bank eG Heuberg-Winterlingen",
      "value": "VR Bank eG Heuberg-Winterlingen"
    },
    {
      "label": "VR Bank Fulda eG",
      "value": "VR Bank Fulda eG"
    },
    {
      "label": "VR Bank Heilbronn Schwäbisch Hall eG",
      "value": "VR Bank Heilbronn Schwäbisch Hall eG"
    },
    {
      "label": "VR Bank HessenLand eG",
      "value": "VR Bank HessenLand eG"
    },
    {
      "label": "VR Bank Hohenneuffen-Teck eG",
      "value": "VR Bank Hohenneuffen-Teck eG"
    },
    {
      "label": "VR Bank Ihre Heimatbank eG",
      "value": "VR Bank Ihre Heimatbank eG"
    },
    {
      "label": "VR Bank in Holstein eG",
      "value": "VR Bank in Holstein eG"
    },
    {
      "label": "VR Bank Kitzingen eG",
      "value": "VR Bank Kitzingen eG"
    },
    {
      "label": "VR Bank Lahn-Dill eG",
      "value": "VR Bank Lahn-Dill eG"
    },
    {
      "label": "VR Bank Lausitz eG",
      "value": "VR Bank Lausitz eG"
    },
    {
      "label": "VR Bank Main-Kinzig-Büdingen eG",
      "value": "VR Bank Main-Kinzig-Büdingen eG"
    },
    {
      "label": "VR Bank Mecklenburg eG",
      "value": "VR Bank Mecklenburg eG"
    },
    {
      "label": "VR Bank Metropolregion Nürnberg eG",
      "value": "VR Bank Metropolregion Nürnberg eG"
    },
    {
      "label": "VR Bank Mittelhaardt eG",
      "value": "VR Bank Mittelhaardt eG"
    },
    {
      "label": "VR Bank Mittlere Oberpfalz eG",
      "value": "VR Bank Mittlere Oberpfalz eG"
    },
    {
      "label": "VR Bank München Land eG",
      "value": "VR Bank München Land eG"
    },
    {
      "label": "VR Bank Neuburg-Rain eG",
      "value": "VR Bank Neuburg-Rain eG"
    },
    {
      "label": "VR Bank Niederbayern-Oberpfalz eG",
      "value": "VR Bank Niederbayern-Oberpfalz eG"
    },
    {
      "label": "VR Bank Nord eG",
      "value": "VR Bank Nord eG"
    },
    {
      "label": "VR Bank Oberfranken Mitte eG",
      "value": "VR Bank Oberfranken Mitte eG"
    },
    {
      "label": "VR Bank Oldenburg Land eG",
      "value": "VR Bank Oldenburg Land eG"
    },
    {
      "label": "VR Bank Ravensburg-Weingarten eG",
      "value": "VR Bank Ravensburg-Weingarten eG"
    },
    {
      "label": "VR Bank Rhein-Mosel eG",
      "value": "VR Bank Rhein-Mosel eG"
    },
    {
      "label": "VR Bank Rhein-Neckar eG",
      "value": "VR Bank Rhein-Neckar eG"
    },
    {
      "label": "VR Bank Riedlingen-Federsee eG",
      "value": "VR Bank Riedlingen-Federsee eG"
    },
    {
      "label": "VR Bank Schleswig-Mittelholstein eG",
      "value": "VR Bank Schleswig-Mittelholstein eG"
    },
    {
      "label": "VR Bank Schwäbischer Wald eG",
      "value": "VR Bank Schwäbischer Wald eG"
    },
    {
      "label": "VR Bank Südliche Weinstraße-Wasgau eG",
      "value": "VR Bank Südliche Weinstraße-Wasgau eG"
    },
    {
      "label": "VR Bank Südpfalz eG",
      "value": "VR Bank Südpfalz eG"
    },
    {
      "label": "VR Bank Weimar eG",
      "value": "VR Bank Weimar eG"
    },
    {
      "label": "VR Bank Westküste eG",
      "value": "VR Bank Westküste eG"
    },
    {
      "label": "VR Bank Westthüringen eG",
      "value": "VR Bank Westthüringen eG"
    },
    {
      "label": "VR Bank zwischen den Meeren eG",
      "value": "VR Bank zwischen den Meeren eG"
    },
    {
      "label": "VR Factoring GmbH",
      "value": "VR Factoring GmbH"
    },
    {
      "label": "VR GenoBank DonauWald eG",
      "value": "VR GenoBank DonauWald eG"
    },
    {
      "label": "VR PartnerBank eG Chattengau-Schwalm-Eder",
      "value": "VR PartnerBank eG Chattengau-Schwalm-Eder"
    },
    {
      "label": "VR Payment GmbH",
      "value": "VR Payment GmbH"
    },
    {
      "label": "VR PLUS Altmark-Wendland eG",
      "value": "VR PLUS Altmark-Wendland eG"
    },
    {
      "label": "VR Smart Finanz AG",
      "value": "VR Smart Finanz AG"
    },
    {
      "label": "VR Smart Finanz Bank GmbH",
      "value": "VR Smart Finanz Bank GmbH"
    },
    {
      "label": "VR Ventures Management GmbH",
      "value": "VR Ventures Management GmbH"
    },
    {
      "label": "VR-Bank Alb-Blau-Donau eG",
      "value": "VR-Bank Alb-Blau-Donau eG"
    },
    {
      "label": "VR-Bank Altenburger Land eG",
      "value": "VR-Bank Altenburger Land eG"
    },
    {
      "label": "VR-Bank Bonn Rhein-Sieg eG",
      "value": "VR-Bank Bonn Rhein-Sieg eG"
    },
    {
      "label": "VR-Bank Coburg eG",
      "value": "VR-Bank Coburg eG"
    },
    {
      "label": "VR-BANK Dinklage-Steinfeld eG",
      "value": "VR-BANK Dinklage-Steinfeld eG"
    },
    {
      "label": "VR-Bank Donau-Mindel eG",
      "value": "VR-Bank Donau-Mindel eG"
    },
    {
      "label": "VR-Bank Dornstetten-Horb eG",
      "value": "VR-Bank Dornstetten-Horb eG"
    },
    {
      "label": "VR-Bank eG",
      "value": "VR-Bank eG"
    },
    {
      "label": "VR-Bank eG Magstadt-Weissach",
      "value": "VR-Bank eG Magstadt-Weissach"
    },
    {
      "label": "VR-Bank eG Osnabrücker Nordland",
      "value": "VR-Bank eG Osnabrücker Nordland"
    },
    {
      "label": "VR-Bank Ehningen-Nufringen eG",
      "value": "VR-Bank Ehningen-Nufringen eG"
    },
    {
      "label": "VR-Bank Ellwangen eG",
      "value": "VR-Bank Ellwangen eG"
    },
    {
      "label": "VR-Bank Erding eG",
      "value": "VR-Bank Erding eG"
    },
    {
      "label": "VR-Bank Feuchtwangen-Dinkelsbühl eG",
      "value": "VR-Bank Feuchtwangen-Dinkelsbühl eG"
    },
    {
      "label": "VR-Bank Fichtelgebirge-Frankenwald eG",
      "value": "VR-Bank Fichtelgebirge-Frankenwald eG"
    },
    {
      "label": "VR-Bank Fläming-Elsterland eG",
      "value": "VR-Bank Fläming-Elsterland eG"
    },
    {
      "label": "VR-Bank Freudenberg-Niederfischbach eG",
      "value": "VR-Bank Freudenberg-Niederfischbach eG"
    },
    {
      "label": "VR-Bank Gerolzhofen eG",
      "value": "VR-Bank Gerolzhofen eG"
    },
    {
      "label": "VR-Bank Handels- und Gewerbebank eG",
      "value": "VR-Bank Handels- und Gewerbebank eG"
    },
    {
      "label": "VR-Bank Hunsrück-Mosel eG",
      "value": "VR-Bank Hunsrück-Mosel eG"
    },
    {
      "label": "VR-Bank in Mittelbaden eG",
      "value": "VR-Bank in Mittelbaden eG"
    },
    {
      "label": "VR-Bank in Südniedersachsen eG",
      "value": "VR-Bank in Südniedersachsen eG"
    },
    {
      "label": "VR-Bank in Südoldenburg eG",
      "value": "VR-Bank in Südoldenburg eG"
    },
    {
      "label": "VR-Bank Isar-Vils eG",
      "value": "VR-Bank Isar-Vils eG"
    },
    {
      "label": "VR-Bank Ismaning Hallbergmoos Neufahrn eG",
      "value": "VR-Bank Ismaning Hallbergmoos Neufahrn eG"
    },
    {
      "label": "VR-Bank Landau-Mengkofen eG",
      "value": "VR-Bank Landau-Mengkofen eG"
    },
    {
      "label": "VR-Bank Landsberg-Ammersee eG",
      "value": "VR-Bank Landsberg-Ammersee eG"
    },
    {
      "label": "VR-Bank Landshut eG",
      "value": "VR-Bank Landshut eG"
    },
    {
      "label": "VR-Bank Lichtenfels-Ebern eG",
      "value": "VR-Bank Lichtenfels-Ebern eG"
    },
    {
      "label": "VR-Bank Ludwigsburg eG",
      "value": "VR-Bank Ludwigsburg eG"
    },
    {
      "label": "VR-Bank Main-Rhön eG",
      "value": "VR-Bank Main-Rhön eG"
    },
    {
      "label": "VR-Bank Memmingen eG",
      "value": "VR-Bank Memmingen eG"
    },
    {
      "label": "VR-Bank Mitte eG",
      "value": "VR-Bank Mitte eG"
    },
    {
      "label": "VR-Bank Mittelfranken Mitte eG",
      "value": "VR-Bank Mittelfranken Mitte eG"
    },
    {
      "label": "VR-Bank Mittelsachsen eG",
      "value": "VR-Bank Mittelsachsen eG"
    },
    {
      "label": "VR-Bank Neu-Ulm eG",
      "value": "VR-Bank Neu-Ulm eG"
    },
    {
      "label": "VR-Bank Nordeifel eG",
      "value": "VR-Bank Nordeifel eG"
    },
    {
      "label": "VR-Bank NordRhön eG",
      "value": "VR-Bank NordRhön eG"
    },
    {
      "label": "VR-Bank Ostalb eG",
      "value": "VR-Bank Ostalb eG"
    },
    {
      "label": "VR-Bank Ostbayern-Mitte eG",
      "value": "VR-Bank Ostbayern-Mitte eG"
    },
    {
      "label": "VR-Bank Passau eG",
      "value": "VR-Bank Passau eG"
    },
    {
      "label": "VR-Bank Rottal-Inn eG",
      "value": "VR-Bank Rottal-Inn eG"
    },
    {
      "label": "VR-Bank Spangenberg - Morschen eG",
      "value": "VR-Bank Spangenberg - Morschen eG"
    },
    {
      "label": "VR-Bank Südwestpfalz eG Pirmasens - Zweibrücken",
      "value": "VR-Bank Südwestpfalz eG Pirmasens - Zweibrücken"
    },
    {
      "label": "VR-Bank Taufkirchen-Dorfen eG",
      "value": "VR-Bank Taufkirchen-Dorfen eG"
    },
    {
      "label": "VR-Bank Uckermark-Randow eG",
      "value": "VR-Bank Uckermark-Randow eG"
    },
    {
      "label": "VR-Bank Werdenfels eG",
      "value": "VR-Bank Werdenfels eG"
    },
    {
      "label": "VR-Bank Westmünsterland eG",
      "value": "VR-Bank Westmünsterland eG"
    },
    {
      "label": "VR-Bankverein Bad Hersfeld-Rotenburg eG",
      "value": "VR-Bankverein Bad Hersfeld-Rotenburg eG"
    },
    {
      "label": "VRK Versicherungsverein auf Gegenseitigkeit im Raum der Kirchen",
      "value": "VRK Versicherungsverein auf Gegenseitigkeit im Raum der Kirchen"
    },
    {
      "label": "Vsquared Ventures Management GmbH",
      "value": "Vsquared Ventures Management GmbH"
    },
    {
      "label": "VTB Bank (Europe) SE",
      "value": "VTB Bank (Europe) SE"
    },
    {
      "label": "VTC Europe (German branch)",
      "value": "VTC Europe (German branch)"
    },
    {
      "label": "VVB Vermögensverwaltung Braunenberg GmbH",
      "value": "VVB Vermögensverwaltung Braunenberg GmbH"
    },
    {
      "label": "vwd TransactionSolutions AG",
      "value": "vwd TransactionSolutions AG"
    },
    {
      "label": "VZ VermögensZentrum Bank AG",
      "value": "VZ VermögensZentrum Bank AG"
    },
    {
      "label": "VÖB-ZVD Processing GmbH",
      "value": "VÖB-ZVD Processing GmbH"
    },
    {
      "label": "VöV Rückversicherung KöR",
      "value": "VöV Rückversicherung KöR"
    },
    {
      "label": "W & S Portfoliomanagement GmbH",
      "value": "W & S Portfoliomanagement GmbH"
    },
    {
      "label": "W & W Asset Management GmbH",
      "value": "W & W Asset Management GmbH"
    },
    {
      "label": "W&W Interaction Solutions GmbH",
      "value": "W&W Interaction Solutions GmbH"
    },
    {
      "label": "W. R. Berkley Europe AG Niederlassung für Deutschland",
      "value": "W. R. Berkley Europe AG Niederlassung für Deutschland"
    },
    {
      "label": "Wachstum und Value Finanzportfolioverwaltung GmbH",
      "value": "Wachstum und Value Finanzportfolioverwaltung GmbH"
    },
    {
      "label": "Wachstumsfonds Mittelstand Sachsen Management GmbH & Co. KG",
      "value": "Wachstumsfonds Mittelstand Sachsen Management GmbH & Co. KG"
    },
    {
      "label": "Wagner & Florack Vermögensverwaltung Aktiengesellschaft",
      "value": "Wagner & Florack Vermögensverwaltung Aktiengesellschaft"
    },
    {
      "label": "Waldecker Bank eG",
      "value": "Waldecker Bank eG"
    },
    {
      "label": "Waldenburger Versicherung Aktiengesellschaft",
      "value": "Waldenburger Versicherung Aktiengesellschaft"
    },
    {
      "label": "Wallis GmbH",
      "value": "Wallis GmbH"
    },
    {
      "label": "Wallrich Asset Management AG",
      "value": "Wallrich Asset Management AG"
    },
    {
      "label": "Walter Ludwig GmbH Wertpapierhandelsbank",
      "value": "Walter Ludwig GmbH Wertpapierhandelsbank"
    },
    {
      "label": "WAP Invest GmbH",
      "value": "WAP Invest GmbH"
    },
    {
      "label": "Warburg Invest AG",
      "value": "Warburg Invest AG"
    },
    {
      "label": "WARBURG INVEST KAPITALANLAGEGESELLSCHAFT MBH",
      "value": "WARBURG INVEST KAPITALANLAGEGESELLSCHAFT MBH"
    },
    {
      "label": "Wartburg-Sparkasse",
      "value": "Wartburg-Sparkasse"
    },
    {
      "label": "WAVE Kapitalverwaltungsgesellschaft mbH",
      "value": "WAVE Kapitalverwaltungsgesellschaft mbH"
    },
    {
      "label": "WAVE Management AG",
      "value": "WAVE Management AG"
    },
    {
      "label": "WaveTrack International GmbH",
      "value": "WaveTrack International GmbH"
    },
    {
      "label": "Waystone Investment Management (IE) Limited, German Branch",
      "value": "Waystone Investment Management (IE) Limited, German Branch"
    },
    {
      "label": "WBS Hünicke Vermögensverwaltung GmbH",
      "value": "WBS Hünicke Vermögensverwaltung GmbH"
    },
    {
      "label": "WealthCap Kapitalverwaltungsgesellschaft mbH",
      "value": "WealthCap Kapitalverwaltungsgesellschaft mbH"
    },
    {
      "label": "WealthCap Leasing 1 GmbH & Co. KG",
      "value": "WealthCap Leasing 1 GmbH & Co. KG"
    },
    {
      "label": "WealthCap Leasing 2 GmbH & Co. KG",
      "value": "WealthCap Leasing 2 GmbH & Co. KG"
    },
    {
      "label": "WealthCap Leasing 3 GmbH & Co. KG",
      "value": "WealthCap Leasing 3 GmbH & Co. KG"
    },
    {
      "label": "WealthCap Leasing 4 GmbH & Co. KG",
      "value": "WealthCap Leasing 4 GmbH & Co. KG"
    },
    {
      "label": "WealthKonzept Vermögensverwaltung AG",
      "value": "WealthKonzept Vermögensverwaltung AG"
    },
    {
      "label": "WEAT Electronic Datenservice GmbH",
      "value": "WEAT Electronic Datenservice GmbH"
    },
    {
      "label": "Weberbank Actiengesellschaft",
      "value": "Weberbank Actiengesellschaft"
    },
    {
      "label": "wefox Insurance AG Niederlassung Deutschland",
      "value": "wefox Insurance AG Niederlassung Deutschland"
    },
    {
      "label": "WEGA Invest GmbH",
      "value": "WEGA Invest GmbH"
    },
    {
      "label": "Weiler & Eberhardt Depotverwaltung AG",
      "value": "Weiler & Eberhardt Depotverwaltung AG"
    },
    {
      "label": "Wellington Management Europe GmbH",
      "value": "Wellington Management Europe GmbH"
    },
    {
      "label": "Wellington Partners Life Science Venture Capital Management GmbH",
      "value": "Wellington Partners Life Science Venture Capital Management GmbH"
    },
    {
      "label": "Wells Fargo Bank International Unlimited Company Niederlassung Frankfurt",
      "value": "Wells Fargo Bank International Unlimited Company Niederlassung Frankfurt"
    },
    {
      "label": "Wermuth Asset Management GmbH",
      "value": "Wermuth Asset Management GmbH"
    },
    {
      "label": "Wermuth Investment Management GmbH",
      "value": "Wermuth Investment Management GmbH"
    },
    {
      "label": "Werte Invest Vermögensverwaltung GmbH",
      "value": "Werte Invest Vermögensverwaltung GmbH"
    },
    {
      "label": "WERTGARANTIE SE",
      "value": "WERTGARANTIE SE"
    },
    {
      "label": "Werther und Ernst Vermögensverwalter GmbH",
      "value": "Werther und Ernst Vermögensverwalter GmbH"
    },
    {
      "label": "Weser-Elbe Sparkasse",
      "value": "Weser-Elbe Sparkasse"
    },
    {
      "label": "Westend Bank AG",
      "value": "Westend Bank AG"
    },
    {
      "label": "Western Union International Bank GmbH Niederlassung Deutschland",
      "value": "Western Union International Bank GmbH Niederlassung Deutschland"
    },
    {
      "label": "Westerwald Bank eG Volks- und Raiffeisenbank",
      "value": "Westerwald Bank eG Volks- und Raiffeisenbank"
    },
    {
      "label": "WestInvest Gesellschaft für Investmentfonds mbH",
      "value": "WestInvest Gesellschaft für Investmentfonds mbH"
    },
    {
      "label": "Westpac Europe GmbH",
      "value": "Westpac Europe GmbH"
    },
    {
      "label": "wevest Vermögensverwaltung AG",
      "value": "wevest Vermögensverwaltung AG"
    },
    {
      "label": "WF World Fund Management GmbH",
      "value": "WF World Fund Management GmbH"
    },
    {
      "label": "WGV-Lebensversicherung AG",
      "value": "WGV-Lebensversicherung AG"
    },
    {
      "label": "WGV-Versicherung AG",
      "value": "WGV-Versicherung AG"
    },
    {
      "label": "WH Selfinvest S.A. Zweigniederlassung Frankfurt am Main",
      "value": "WH Selfinvest S.A. Zweigniederlassung Frankfurt am Main"
    },
    {
      "label": "WHI Warenhandels GmbH & Co. Leasing KG",
      "value": "WHI Warenhandels GmbH & Co. Leasing KG"
    },
    {
      "label": "Whitebox GmbH",
      "value": "Whitebox GmbH"
    },
    {
      "label": "Whitelake Capital GmbH",
      "value": "Whitelake Capital GmbH"
    },
    {
      "label": "Wichmann & Groth Vermögensverwalter GmbH",
      "value": "Wichmann & Groth Vermögensverwalter GmbH"
    },
    {
      "label": "Wickenkamp Consulting GmbH",
      "value": "Wickenkamp Consulting GmbH"
    },
    {
      "label": "WIDe Wertimmobilien Deutschland Fondsmanagement GmbH",
      "value": "WIDe Wertimmobilien Deutschland Fondsmanagement GmbH"
    },
    {
      "label": "Wiederaufbaukasse der rheinland-pfälzischen Weinbaugebiete - Anstalt des öffentlichen Rechts -",
      "value": "Wiederaufbaukasse der rheinland-pfälzischen Weinbaugebiete - Anstalt des öffentlichen Rechts -"
    },
    {
      "label": "Wiening & Schnierle Immobilien II GmbH",
      "value": "Wiening & Schnierle Immobilien II GmbH"
    },
    {
      "label": "Wiesbadener Volksbank eG",
      "value": "Wiesbadener Volksbank eG"
    },
    {
      "label": "WIFA Wirtschaftliches Automobil- und Anlageleasing e.K.",
      "value": "WIFA Wirtschaftliches Automobil- und Anlageleasing e.K."
    },
    {
      "label": "Willis Towers Watson Investments GmbH",
      "value": "Willis Towers Watson Investments GmbH"
    },
    {
      "label": "Willis Towers Watson Pensionsfonds AG",
      "value": "Willis Towers Watson Pensionsfonds AG"
    },
    {
      "label": "WINBRIDGE Asset Management GmbH",
      "value": "WINBRIDGE Asset Management GmbH"
    },
    {
      "label": "Windward GmbH",
      "value": "Windward GmbH"
    },
    {
      "label": "winIT Continuation GmbH & Co. KG",
      "value": "winIT Continuation GmbH & Co. KG"
    },
    {
      "label": "Winterbacher Bank eG",
      "value": "Winterbacher Bank eG"
    },
    {
      "label": "Winterberg & Seelmeyer Vermögensverwaltung GmbH",
      "value": "Winterberg & Seelmeyer Vermögensverwaltung GmbH"
    },
    {
      "label": "Winterberg Advisory GmbH",
      "value": "Winterberg Advisory GmbH"
    },
    {
      "label": "winwin Finance GmbH",
      "value": "winwin Finance GmbH"
    },
    {
      "label": "WIR FÜR SIE Parfümerie- Gesellschaft mbH",
      "value": "WIR FÜR SIE Parfümerie- Gesellschaft mbH"
    },
    {
      "label": "Witt u. Co. Wertpapiervermittlungs GmbH",
      "value": "Witt u. Co. Wertpapiervermittlungs GmbH"
    },
    {
      "label": "wiwi Fondsmanagement GmbH",
      "value": "wiwi Fondsmanagement GmbH"
    },
    {
      "label": "WM Mobilien Leasing GmbH",
      "value": "WM Mobilien Leasing GmbH"
    },
    {
      "label": "WMK Finanz OHG",
      "value": "WMK Finanz OHG"
    },
    {
      "label": "Wohnpark Elbaue GmbH & Co. Immobilienfonds KG",
      "value": "Wohnpark Elbaue GmbH & Co. Immobilienfonds KG"
    },
    {
      "label": "WohnSelect Kapitalverwaltungsgesellschaft mbH",
      "value": "WohnSelect Kapitalverwaltungsgesellschaft mbH"
    },
    {
      "label": "Wohnungsbaugenossenschaft Chemnitz West eG",
      "value": "Wohnungsbaugenossenschaft Chemnitz West eG"
    },
    {
      "label": "Wohnungsbaugenossenschaft Einheit eG",
      "value": "Wohnungsbaugenossenschaft Einheit eG"
    },
    {
      "label": "Wohnungsgenossenschaft \"Carl Zeiss\" eG",
      "value": "Wohnungsgenossenschaft \"Carl Zeiss\" eG"
    },
    {
      "label": "Wohnungsgenossenschaft \"Lipsia\" eG",
      "value": "Wohnungsgenossenschaft \"Lipsia\" eG"
    },
    {
      "label": "Wohnungsgenossenschaft eG Göttingen",
      "value": "Wohnungsgenossenschaft eG Göttingen"
    },
    {
      "label": "Wohnungsgenossenschaft Heimkehr e.G.",
      "value": "Wohnungsgenossenschaft Heimkehr e.G."
    },
    {
      "label": "Wolffkran Finance GmbH",
      "value": "Wolffkran Finance GmbH"
    },
    {
      "label": "Wolfgang Steubing AG Wertpapierdienstleister",
      "value": "Wolfgang Steubing AG Wertpapierdienstleister"
    },
    {
      "label": "Woori Bank Europe GmbH",
      "value": "Woori Bank Europe GmbH"
    },
    {
      "label": "World of Leasing Gesellschaft mit beschränkter Haftung",
      "value": "World of Leasing Gesellschaft mit beschränkter Haftung"
    },
    {
      "label": "wSw PortfolioManagement AG",
      "value": "wSw PortfolioManagement AG"
    },
    {
      "label": "WT 80 Kapitalverwaltungsgesellschaft GmbH",
      "value": "WT 80 Kapitalverwaltungsgesellschaft GmbH"
    },
    {
      "label": "Wuppertaler Pensionskasse VVaG",
      "value": "Wuppertaler Pensionskasse VVaG"
    },
    {
      "label": "WWK Allgemeine Versicherung Aktiengesellschaft",
      "value": "WWK Allgemeine Versicherung Aktiengesellschaft"
    },
    {
      "label": "WWK Lebensversicherung auf Gegenseitigkeit",
      "value": "WWK Lebensversicherung auf Gegenseitigkeit"
    },
    {
      "label": "WWK Pensionsfonds Aktiengesellschaft",
      "value": "WWK Pensionsfonds Aktiengesellschaft"
    },
    {
      "label": "Wydler Asset Management (Deutschland) GmbH",
      "value": "Wydler Asset Management (Deutschland) GmbH"
    },
    {
      "label": "Würth Leasing GmbH & Co. KG",
      "value": "Würth Leasing GmbH & Co. KG"
    },
    {
      "label": "Württembergische Gemeinde-Versicherung auf Gegenseitigkeit",
      "value": "Württembergische Gemeinde-Versicherung auf Gegenseitigkeit"
    },
    {
      "label": "Württembergische Krankenversicherung Aktiengesellschaft",
      "value": "Württembergische Krankenversicherung Aktiengesellschaft"
    },
    {
      "label": "Württembergische Lebensversicherung Aktiengesellschaft",
      "value": "Württembergische Lebensversicherung Aktiengesellschaft"
    },
    {
      "label": "Württembergische Versicherung Aktiengesellschaft",
      "value": "Württembergische Versicherung Aktiengesellschaft"
    },
    {
      "label": "Würzburger Versicherungs-AG",
      "value": "Würzburger Versicherungs-AG"
    },
    {
      "label": "Wüstenrot & Württembergische AG",
      "value": "Wüstenrot & Württembergische AG"
    },
    {
      "label": "Wüstenrot Bausparkasse Aktiengesellschaft",
      "value": "Wüstenrot Bausparkasse Aktiengesellschaft"
    },
    {
      "label": "XAIA Investment GmbH",
      "value": "XAIA Investment GmbH"
    },
    {
      "label": "XDECK Management GmbH",
      "value": "XDECK Management GmbH"
    },
    {
      "label": "Xerox Leasing Deutschland GmbH",
      "value": "Xerox Leasing Deutschland GmbH"
    },
    {
      "label": "XL Insurance Company SE Direktion für Deutschland",
      "value": "XL Insurance Company SE Direktion für Deutschland"
    },
    {
      "label": "XTB S.A. German Branch",
      "value": "XTB S.A. German Branch"
    },
    {
      "label": "XY ERS Deutschland GmbH",
      "value": "XY ERS Deutschland GmbH"
    },
    {
      "label": "yabeo Advisors GmbH",
      "value": "yabeo Advisors GmbH"
    },
    {
      "label": "YIELCO Investments AG",
      "value": "YIELCO Investments AG"
    },
    {
      "label": "YIELCO Special Situations Europe GmbH & Co. KG",
      "value": "YIELCO Special Situations Europe GmbH & Co. KG"
    },
    {
      "label": "YIELCO Special Situations GmbH & Co. KG",
      "value": "YIELCO Special Situations GmbH & Co. KG"
    },
    {
      "label": "Younited S.A. Niederlassung Deutschland",
      "value": "Younited S.A. Niederlassung Deutschland"
    },
    {
      "label": "YZR Capital GmbH",
      "value": "YZR Capital GmbH"
    },
    {
      "label": "ZA Zahnärztliche Abrechnungsgesellschaft Düsseldorf Aktiengesellschaft",
      "value": "ZA Zahnärztliche Abrechnungsgesellschaft Düsseldorf Aktiengesellschaft"
    },
    {
      "label": "ZAB Abrechnungsgesellschaft mbH",
      "value": "ZAB Abrechnungsgesellschaft mbH"
    },
    {
      "label": "ZAD Zentraler Abrechnungs-Dienst GmbH",
      "value": "ZAD Zentraler Abrechnungs-Dienst GmbH"
    },
    {
      "label": "Zalando Payments GmbH",
      "value": "Zalando Payments GmbH"
    },
    {
      "label": "Zantke & Cie. Asset Management GmbH",
      "value": "Zantke & Cie. Asset Management GmbH"
    },
    {
      "label": "ZBI Fondsmanagement GmbH",
      "value": "ZBI Fondsmanagement GmbH"
    },
    {
      "label": "ZEDACH eG Zentralgenossenschaft des Dachdeckerhandwerks & Co. Kommanditgesellschaft",
      "value": "ZEDACH eG Zentralgenossenschaft des Dachdeckerhandwerks & Co. Kommanditgesellschaft"
    },
    {
      "label": "ZEG Zweirad-Einkaufsgenossenschaft eG",
      "value": "ZEG Zweirad-Einkaufsgenossenschaft eG"
    },
    {
      "label": "Zenon Investments GmbH",
      "value": "Zenon Investments GmbH"
    },
    {
      "label": "ZENTRAG Zentralgenossenschaft des deutschen Fleischergewerbes eG",
      "value": "ZENTRAG Zentralgenossenschaft des deutschen Fleischergewerbes eG"
    },
    {
      "label": "Zentrales Versorgungswerk für das Dachdeckerhandwerk VVaG",
      "value": "Zentrales Versorgungswerk für das Dachdeckerhandwerk VVaG"
    },
    {
      "label": "Zentralverband Europäischer Lederhändler eG",
      "value": "Zentralverband Europäischer Lederhändler eG"
    },
    {
      "label": "Zevener Volksbank eG",
      "value": "Zevener Volksbank eG"
    },
    {
      "label": "ZIEMANN VALOR GmbH",
      "value": "ZIEMANN VALOR GmbH"
    },
    {
      "label": "Zikkurat Grundstücksverwaltungsgesellschaft mbH & Co. Vermietungs KG",
      "value": "Zikkurat Grundstücksverwaltungsgesellschaft mbH & Co. Vermietungs KG"
    },
    {
      "label": "Zimmer Direkt-Leasing GmbH",
      "value": "Zimmer Direkt-Leasing GmbH"
    },
    {
      "label": "Zindstein Vermögensverwaltung GmbH",
      "value": "Zindstein Vermögensverwaltung GmbH"
    },
    {
      "label": "Zintinus GmbH",
      "value": "Zintinus GmbH"
    },
    {
      "label": "ZIRAAT BANK INTERNATIONAL AKTIENGESELLSCHAFT",
      "value": "ZIRAAT BANK INTERNATIONAL AKTIENGESELLSCHAFT"
    },
    {
      "label": "ZMF Munich GmbH",
      "value": "ZMF Munich GmbH"
    },
    {
      "label": "Zobel Values GmbH",
      "value": "Zobel Values GmbH"
    },
    {
      "label": "Zurich Deutscher Herold Lebensversicherung Aktiengesellschaft",
      "value": "Zurich Deutscher Herold Lebensversicherung Aktiengesellschaft"
    },
    {
      "label": "ZURICH Insurance plc Niederlassung für Deutschland",
      "value": "ZURICH Insurance plc Niederlassung für Deutschland"
    },
    {
      "label": "Zusatzversorgungskasse der Steine- und Erden-Industrie u. des Betonsteinhandwerks VVaG Die Bayerische Pensionskasse",
      "value": "Zusatzversorgungskasse der Steine- und Erden-Industrie u. des Betonsteinhandwerks VVaG Die Bayerische Pensionskasse"
    },
    {
      "label": "Zusatzversorgungskasse des Baugewerbes AG",
      "value": "Zusatzversorgungskasse des Baugewerbes AG"
    },
    {
      "label": "Zusatzversorgungskasse des Dachdeckerhandwerks VVaG",
      "value": "Zusatzversorgungskasse des Dachdeckerhandwerks VVaG"
    },
    {
      "label": "Zusatzversorgungskasse des Gerüstbaugewerbes VVaG",
      "value": "Zusatzversorgungskasse des Gerüstbaugewerbes VVaG"
    },
    {
      "label": "Zusatzversorgungskasse des Maler- und Lackiererhandwerks VVaG",
      "value": "Zusatzversorgungskasse des Maler- und Lackiererhandwerks VVaG"
    },
    {
      "label": "Zusatzversorgungskasse des Steinmetz- und Steinbildhauerhandwerks VVaG",
      "value": "Zusatzversorgungskasse des Steinmetz- und Steinbildhauerhandwerks VVaG"
    },
    {
      "label": "Zusatzversorgungskasse für die Beschäftigten der Deutschen Brot- und Backwarenindustrie VVaG",
      "value": "Zusatzversorgungskasse für die Beschäftigten der Deutschen Brot- und Backwarenindustrie VVaG"
    },
    {
      "label": "Zusatzversorgungskasse für die Beschäftigten des Deutschen Bäckerhandwerks VVaG",
      "value": "Zusatzversorgungskasse für die Beschäftigten des Deutschen Bäckerhandwerks VVaG"
    },
    {
      "label": "Zusatzversorgungswerk für Arbeitnehmer in der Land- und Forstwirtschaft - ZLF VVaG",
      "value": "Zusatzversorgungswerk für Arbeitnehmer in der Land- und Forstwirtschaft - ZLF VVaG"
    },
    {
      "label": "Zuse Partners GmbH",
      "value": "Zuse Partners GmbH"
    },
    {
      "label": "Zweckverbandssparkasse der Stadt Bergkamen und der Gemeinde Bönen (Sparkasse Bergkamen-Bönen)",
      "value": "Zweckverbandssparkasse der Stadt Bergkamen und der Gemeinde Bönen (Sparkasse Bergkamen-Bönen)"
    },
    {
      "label": "Zweckverbandssparkasse Duderstadt",
      "value": "Zweckverbandssparkasse Duderstadt"
    },
    {
      "label": "Zweckverbandssparkasse Höxter",
      "value": "Zweckverbandssparkasse Höxter"
    },
    {
      "label": "Zweckverbandssparkasse Rhön-Rennsteig",
      "value": "Zweckverbandssparkasse Rhön-Rennsteig"
    },
    {
      "label": "zwei.7 Management GmbH",
      "value": "zwei.7 Management GmbH"
    },
    {
      "label": "Ärztliche Verrechnungsstelle Büdingen GmbH Ärztliche Gemeinschaftseinrichtung",
      "value": "Ärztliche Verrechnungsstelle Büdingen GmbH Ärztliche Gemeinschaftseinrichtung"
    },
    {
      "label": "Öffentliche Feuerversicherung Sachsen-Anhalt",
      "value": "Öffentliche Feuerversicherung Sachsen-Anhalt"
    },
    {
      "label": "Öffentliche Lebensversicherung Sachsen-Anhalt",
      "value": "Öffentliche Lebensversicherung Sachsen-Anhalt"
    },
    {
      "label": "Ökovation Ventures GmbH & Co. KG",
      "value": "Ökovation Ventures GmbH & Co. KG"
    },
    {
      "label": "ÖRAG Rechtsschutzversicherungs-Aktiengesellschaft",
      "value": "ÖRAG Rechtsschutzversicherungs-Aktiengesellschaft"
    }
  ];