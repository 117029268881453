import React, { useState, useEffect, useContext } from "react";
import Header from "../Header/Header";
import Spinner from "../Bausteine/Spinner/Spinner";

import CardSub from "../Card/CardSub";

import { Container, Row, Col, Button, Modal } from "react-bootstrap";

import style from "./Verbesserungen.module.css";

import axios from 'axios';

import Swal from "sweetalert2";
import { UserContext } from "../../store/user-context";
import VINButton from "../../VINButton";
import { AuthContext } from "../../store/auth-context";

function Verbesserungen() {

  const { user } = useContext(UserContext);
  const { currentUser } = useContext(AuthContext);

  //const [uhrzeiten] = useState(["08:00-13:00 Uhr", "13:00-17:00 Uhr", "17:00-19:00 Uhr"]);

  const [beschreibung, setBeschreibung] = useState("");
  //const [erreichbar, setErreichbar] = useState("");

  //const [morgens, setMorgens] = useState(false);
  //const [mittags, setMittags] = useState(false);
  //const [abends, setAbends] = useState(false);

  const [datenschutz, setDatenschutz] = useState(false);

  const [spinner, setSpinner] = useState(false);

  /*
  useEffect(() => {

    if(morgens && !mittags && !abends)
      setErreichbar("morgens ("+uhrzeiten[0]+")");
    else if(!morgens && mittags && !abends)
      setErreichbar("mittags ("+uhrzeiten[1]+")");
    else if(!morgens && !mittags && abends)
      setErreichbar("abends ("+uhrzeiten[2]+")");
    else if(morgens && mittags && !abends)
      setErreichbar("morgens ("+uhrzeiten[0]+"), mittags ("+uhrzeiten[1]+")");
    else if(morgens && !mittags && abends)
      setErreichbar("morgens ("+uhrzeiten[0]+"), abends ("+uhrzeiten[2]+")");
    else if(!morgens && mittags && abends)
      setErreichbar("mittags ("+uhrzeiten[1]+"), abends ("+uhrzeiten[2]+")");
    else if(morgens && mittags && abends)
      setErreichbar("ganztägig");

  }, [morgens, mittags, abends, uhrzeiten])
  */


  const handleSubmit = async () => {

    if (beschreibung === "" || datenschutz === false) {
      Swal.fire({
        title: "Pflichtfelder",
        html: "Bitte fülle zunächst alle Felder aus und sende das Formular erneut ab.",
        icon: "info",
      });
      return;
    }

    if (user.kundennummer === undefined || user.kundennummer === "") {
      Swal.fire({
        title: "Kundennummer",
        html: "Damit du dieses Formular absenden kannst, benötigst du eine Kundennummer. Wenn du dich nicht gerade registriert hast, wende dich an den Support.",
        icon: "info",
      });
      return;
    }


      setSpinner(true);

      //Create Task CRM FERTIG MACHEN IM BACKEND!!!
      await axios({
        method: 'post',
        headers: {
          'Authorization': 'Bearer ' + currentUser.uid
        },
        url: process.env.REACT_APP_BACKEND+"/my-vin1/verbesserungen",
        data: {
          kdnr: user.kundennummer,
          beschreibung: beschreibung
        }
      })
      .then(data => {
        Swal.fire({
          title: "Erfolg",
          html: "Deine Daten wurden erfolgreich an uns übermittelt.<br />Vielen Dank für deine Mitarbeit.",
          icon: "success",
        });

      })
      .catch(err => {
          console.log(err);

          Swal.fire({
            title: "Fehler",
            html: "Es ist ein Fehler bei der Übertragung aufgetreten. Versuche es später erneut.",
            icon: "error"
          });
      });



    //Clean Felder
    setSpinner(false);
  
    setBeschreibung("");
    setDatenschutz(false);


  }; //handleSubmit Ende


  return (
    <>
      <Header />
      <Container style={{ maxWidth: "800px" }}>
        <Row style={{ justifyContent: "center" }}>
          <Col style={{ textAlign: "center" }}>
            <img
              src={require("../../img/Verbesserungen.png")}
              alt=""
              style={{ maxWidth: "160px", margin: "40px 0px" }}
            />

            <h5>VIN1 Verbesserungsvorschläge</h5>
            <br />
            <p>Unser Team schaut sich jeden Vorschlag von dir an. Vorschläge die sich umsetzen lassen werden belohnt.
             <br />Solltest du Kritik äußern wollen, versuche dies sachlich und emotionsfrei deine schreibe uns bitte genau, was dich stört, damit wir deine VIN-Erlebnis verbessern können.
            </p>
          </Col>
        </Row>
          

        <Row style={{ justifyContent: "center" }}>
          <Col style={{ textAlign: "center" }}>

          <div className={style.formInputCustom} style={{display: "flex", flexDirection: "column", justifyContent: "flex-start", textAlign: "left"}}>
            <label htmlFor="beschreibung" className="form-label">Deine Anregung, Kritik, Verbesserungsvorschlag ({beschreibung.length}/1.000 Zeichen)</label>
            <textarea
              style={{border: "none", margin: "0px 12px"}}
              value={beschreibung}
              placeholder={"Beschreibe uns deine Idee"}
              cols={40}
              rows={10}
              maxLength={1000}
              onChange={(e) => setBeschreibung(e.target.value)}
            />
            
            
          </div>

          </Col>
        </Row>
  

        <Row className="justify-content-center mt-5">
          <Col style={{ textAlign: "center" }}>

            <label className="form-check-label" style={{ marginTop: "20px" }}>
              <input
                id="cbDatenschutz"
                className="form-check-input"
                type="checkbox"
                checked={datenschutz}
                onChange={(e) => setDatenschutz(e.currentTarget.checked)}
              />
              <span style={{ paddingLeft: "10px" }}>
                Ich stimme den{" "}
                <a href="/datenschutz" target="_blank">
                  Datenschutzbedingungen
                </a>{" "}
                zu.
              </span>
            </label>

            <br />
            <br />
            <br />

            {/*<div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                style={{
                  background: "#520da7",
                  padding: "15px",
                  width: "auto",
                  color: "white",
                  fontWeight: "bold",
                  borderRadius: "8px",
                  cursor: "pointer",
                }}
                disabled={spinner}
                onClick={!spinner ? handleSubmit : null}
              >
                AbsendenF
              </Button>
            </div>*/}


            <div className="pointer" onClick={!spinner ? handleSubmit : null}>
              <VINButton text={"Absenden"} />
            </div>


          </Col>
        </Row>

        <br />
        <br />
        <br />

      {spinner &&
        <Spinner
          message={"Bitte warten. Daten werden verarbeitet."}
        />
      }


        
      </Container>

    </>
  );
}

export default Verbesserungen;
