import React from 'react'
import { Col } from 'react-bootstrap'
import CardSmall from '../Card/CardSmall'


function TarifTaxiCard(props) {
  return (
    <Col
        xs={6}
        md={3}
        style={{ marginTop: "20px" }}
        onClick={() => props.handleCategory(props.category)}
    >
        {props.category == props.selectedCategory && 
            <CardSmall
                img={props.img}
                title={props.title}
                selected={1}
                color={props.ColorTheme}
            />
        }
        {props.category != props.selectedCategory && <CardSmall img={props.img} title={props.title} />}
    </Col>
  )
}

export default TarifTaxiCard

/*

  {category == "Abgelehnt" &&
    <Col key={"abgelehnt"} xs={12} md={5} style={{marginTop: "20px"}} onClick={() => handleCategory("Abgelehnt")}>
        <CardSub title={"Abgelehnte Vorgänge"} selected={1} color={ColorTheme} />
    </Col>
  }

  {category != "Abgelehnt" &&
    <Col key={"abgelehnt"} xs={12} md={5} style={{marginTop: "20px"}} onClick={() => handleCategory("Abgelehnt")}>
        <CardSub title={"Abgelehnte Vorgänge"} color={ColorTheme} />
    </Col>
  }
*/