import React, { createContext, useState, useContext, useEffect } from 'react';

import { AuthContext } from './auth-context';
import { db } from "../firebase";
import { collection, doc, getDocs, getDoc } from "firebase/firestore";

// Create the AuthContext
export const UserContext = createContext({
    user: {},
    unreadDocs: [],
});

// Create the AuthContextProvider component
export const UserProvider = ({ children }) => {

  const { currentUser } = useContext(AuthContext);

  const [user, setUser] = useState({});
  const [unreadDocs, setUnreadDocs] = useState([]);


  const updateUser = (newUserData) => {
    setUser(newUserData);
  };



  const updateUnreadDocs = (newArr) => {
    setUnreadDocs(newArr);
  };

  useEffect(() => {

    
    async function getUserData() {
       
       const docRef = doc(db, "Users", currentUser.uid);
       const userData = await getDoc(docRef);
 
       if (userData.data()) {
         setUser(userData.data());
       }
    }


    // ungelesene Dokumente
    async function getUnreadDocs() {
      
      const colRef = collection(db, "Users", currentUser.uid, "Unread");
      const unreadDocuments = await getDocs(colRef);

      let unreadDocumentsArr = [];
      unreadDocuments.forEach((doc) => {
        //console.log(doc.data());
        if (doc.data()) {
          unreadDocumentsArr.push({id: doc.id, ...doc.data()});
        }
      });

      setUnreadDocs(unreadDocumentsArr);
    }

    if(currentUser?.uid) {
      getUnreadDocs();
      getUserData();
    }

  }, [currentUser]);


  // Value object to be provided by the context
  const userContextValue = {
    user,
    updateUser,
    unreadDocs,
    updateUnreadDocs,
  };


  return (
    <UserContext.Provider value={userContextValue}>
      {children}
    </UserContext.Provider>
  );
};