import React, { Fragment, useState } from 'react'
import Header from '../Header/Header'
import { useNavigate, useLocation } from 'react-router-dom';

import WebViewModal from "../Bausteine/WebViewModal/WebViewModal";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import style from './Partnerprogramm.module.css'

function Partnerprogramm() {

  let navigate = useNavigate();
  const location = useLocation();

  const [showModal, setShowModal] = useState(false);
  const [modalUrl, setModalUrl] = useState(false);

  const handleExternalURL = (url) => {

    setShowModal(false);
    window.scrollTo({
      top: 0,
      behavior: "instant"
    });
    setModalUrl(url);
    setShowModal(true);
  }

  return (
    <Fragment>
      <Header />
      <Container style={{maxWidth: "800px"}}>
      <Row style={{ justifyContent: "center" }}>
              <Col className="text-center">
                <img
                  src={require("../../img/PartnerProgramm.png")}
                  alt=""
                  style={{ maxWidth: "120px", marginTop: "50px" }}
                />
                <h1 style={{ color: "grey" }}>VIN1 Partnerprogramm</h1>
                <h5 style={{ }}>Wir haben garantiert das richtige Partnermodell für Dich!</h5>
                <br />
                <p>Als VIN1-Partner kannst Du deinen Kunden und Kontakten eine Vielzahl von exklusiven VIN1-Vorteilen bieten und baust dir gleichzeitig ein stabiles, langfristiges und nachhaltiges Einkommen auf.
                <br />Mit uns erlebst keinen “Schiffbruch” – denn auch den können wir garantiert versichern.</p>
          </Col>
        </Row>

        <br />
        <br />

        <Row className={style.Row}>
          <Col xs={{span: 12, order: 2}} md={{span: 5, order: 1}} className={[style.textCard, "shadow p-3 bg-body"]}>
            <h5 className="text-center" style={{color: "#983365"}}>Exklusiv Makler</h5>
            <p className="text-center">
            Wir bieten Dir attraktive Verdienst­möglich­keiten, sowie ausge­zeichnete Karriere- und Aufstiegs­chancen. Kunden-, Gebiets- und Bestandsschutz sind für uns selbstverständlich.
            </p>
            {/*<button className={style.Btn} onClick={() => handleExternalURL("https://vin1.eu/partnerprogramme/exklusiv-makler/")}>Mehr Informationen &gt;</button>*/}
            <button className={style.Btn} onClick={() => navigate("/beratung", { state: {category: "Partner", subcategory: "Exklusiv Makler"}})}>Jetzt Kontakt aufnehmen &gt;</button>
          </Col>
          <Col xs={{span: 12, order: 1}} md={{span: 5, order: 2}} style={{textAlign: 'center'}}>
            <img src={require("../../img/Exklusiv_Makler.png")} alt="" className={style.ColImg} />
          </Col>
        </Row>

        <Row className={style.Row}>
          <Col xs={{span: 12, order: 1}} md={{span: 5, order: 2}} style={{textAlign: 'center'}}>
            <img src={require("../../img/Tippgeber_Influencer.png")} alt="" className={style.ColImg} />
          </Col>
            <Col xs={{span: 12, order: 1}} md={{span: 5, order: 2}} className={[style.textCard, "shadow p-3 bg-body"]}>
            <h5 className="text-center" style={{color: "#983365"}}>Tippgeber | Influencer</h5>
            <p className="text-center">
              Deine Empfehlungen und sozialen Fingerabdrücke sind Geld wert.
              Verschenke nicht die Möglichkeiten auf ein selbstbestimmtes Leben.
            </p>
            {/*<button className={style.Btn} onClick={() => handleExternalURL("https://vin1.eu/partnerprogramme/")}>Mehr Informationen &gt;</button>*/}
            <button className={style.Btn} onClick={() => navigate("/beratung", { state: {category: "Partner", subcategory: "Tippgeber / Influencer"}})}>Jetzt Kontakt aufnehmen &gt;</button>
          </Col>
        </Row>



        <Row className={style.Row}>
          <Col xs={{span: 12, order: 2}} md={{span: 5, order: 1}} className={[style.textCard, "shadow p-3 bg-body"]}>
            <h5 className="text-center" style={{color: "#983365"}}>Female Business</h5>
            <p className="text-center">
           VIN1 Female-Business richtet sich an all die Frauen, die nicht nur wissen, dass ihre Arbeit gut ist, sondern auch daran arbeiten erfolgreich zu werden.
            </p>
            {/*<button className={style.Btn} onClick={() => handleExternalURL("https://vin1.eu/partnerprogramme/female-business/")}>Mehr Informationen &gt;</button>*/}
            <button className={style.Btn} onClick={() => navigate("/beratung", { state: {category: "Partner", subcategory: "Female Business"}})}>Jetzt Kontakt aufnehmen &gt;</button>   
          </Col>
          <Col xs={{span: 12, order: 1}} md={{span: 5, order: 2}} style={{textAlign: 'center'}}>
            <img src={require("../../img/Female_Business.png")} alt="" className={style.ColImg} />
          </Col>
        </Row>

        <Row className={style.Row}>
          <Col xs={{span: 12, order: 1}} md={{span: 5, order: 2}} style={{textAlign: 'center'}}>
            <img src={require("../../img/Schüler.png")} alt="" className={style.ColImg} />
          </Col>
          <Col xs={{span: 12, order: 1}} md={{span: 5, order: 2}} className={[style.textCard, "shadow p-3 bg-body"]}>
            <h5 className="text-center" style={{color: "#983365"}}>Schüler? | Unter 18?</h5>
            <p className="text-center">
              Du kannst dir besseres vorstellen als Zeitungen auszutragen oder Regale im Supermarkt einzuräumen?<br />
              Dann haben wir die Lösung für dich!
            </p>
            {/*<button className={style.Btn} onClick={() => handleExternalURL("https://vin1.eu/partnerprogramme/")}>Mehr Informationen &gt;</button>*/}
            <button className={style.Btn} onClick={() => navigate("/beratung", { state: {category: "Partner", subcategory: "Schülerjob"}})}>Jetzt Kontakt aufnehmen &gt;</button>   
          </Col>
        </Row>


        <Row className="justify-content-center" style={{marginBottom: "160px"}}>
          <div className={style.BottomCard}>
            <h4 className="text-center" style={{fontWeight: "bold"}}><span style={{color: "#983365"}}>VIN</span>de Dein <span style={{color: "#983365"}}>VIN</span>ding</h4>
            <br />
            {/*<button className={style.BtnNormal} onClick={() => handleExternalURL("https://vin1.eu/partnerprogramme/")}>Hier mehr erfahren &gt;</button>*/}
            <button className={style.BtnNormal} onClick={() => navigate("/beratung", { state: {category: "Partner"} })}>Jetzt Kontakt aufnehmen &gt;</button>   
          </div>
        </Row>


   {/*
        <Row>
          <div className={style.BottomCard}>
            <h4 className="text-center fw-bold"><span style={{color: "#983365"}}></span>Du hast dich bereits entschieden?</h4>
            <h5>Super!</h5>
            <br />
            <button className={style.BtnNormal} onClick={() => navigate("/cashback", { state: location.state}) }>Direkt verifizieren &gt;</button>
          </div>
        </Row>
        <br />
        <br />
        <br />
  */}

        {showModal && (
          <WebViewModal
            url={modalUrl}
            hideModal={setShowModal}
          />
        )}


      </Container>
    </Fragment>
  )
}

export default Partnerprogramm