import React, { useState, useEffect } from 'react'
import Header from '../Header/Header'

import { useNavigate, useParams } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import axios from "axios"

import NewsletterCard from './NewsletterCard';

function Neuigkeiten(props) {

  let navigate = useNavigate();

  const { id } = useParams();
  
  const [newsletter, setNewsletter] = useState({});

  useEffect(() => {
    loadNewsletter();
  }, [])

  async function loadNewsletter() {
      const response = await axios.get("https://api.vin1.eu/newsletterById.php?AUTH=bv8FnNYM6JU6Uk7QE8ujvM6S6WMVpg&ID="+id);

      const newsObj = {
          id: response.data.ID,
          title: response.data.TITEL,
          datum: response.data.DATUM,
          image: response.data.IMG_B64 ? response.data.IMG_B64 : null,
          newstext: response.data.NACHRICHT,
          url: response.data.URL,
          pdf: response.data.PDF_B64,
      };
    setNewsletter(newsObj);
  }




  const handleExternalURL = (url) => {

    // iFrames machen haftbar in eigener Seite, daher als neuer Tab.
    window.open(url, '_blank', 'noopener,noreferrer');
    /*
    setShowModal(false);
    window.scrollTo({
      top: 0,
      behavior: "instant"
    });
    setModalUrl(url);
    setShowModal(true);
    */
  } 

  function downloadPDF(base64pdf) {
    const linkSource = base64pdf;
    const downloadLink = document.createElement("a");
    const fileName = "VIN1-Newsletter.pdf";

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  } 
  


  return (
    <>
      <Header />
      <Container style={{maxWidth: "800px"}}>

        <Row style={{justifyContent: "center"}}>

          <Col
            xs={12}
            className="text-center"
          >
            <img
              src={newsletter.image}
              alt="image-newsletter"
              style={{ maxWidth: "120px", marginTop: "40px"}}
            />
            <h1>{newsletter.title}</h1>
          </Col>

          <Col
            xs={12}
            className="text-center mt-3"
          >
            {newsletter.pdf && <Button className="fw-bold" style={{background: "rgb(83, 178, 83)", border: "none", marginRight: "10px"}} onClick={() => downloadPDF(newsletter.pdf)}>PDF Download</Button>}
            {newsletter.url && <Button className="fw-bold" style={{background: "#520da7"}} onClick={()=>handleExternalURL(newsletter.url)}>Mehr Infos</Button>}         
          </Col>

          <Col
            xs={12}
            className="mt-5"
          >
            <p>{newsletter.newstext}</p>
          </Col>

        </Row>



        {/*newsletter.map((news) =>   
          <NewsletterCard key={news.id} data={news} />
        )*/}

      <Row style={{marginBottom:"80px"}}></Row>
      
      </Container>
    </>
  )
}

export default Neuigkeiten