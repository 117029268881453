import React, {
  useState,
  useEffect,
  useContext,
} from "react";

import Footer from "../Footer/Footer";

import { useNavigate } from "react-router-dom";
//import Header from '../Header/Header';

//import Card from "../Card/Card";
import CardDoppelt from "../Card/CardDoppelt";

import WebViewModal from "../Bausteine/WebViewModal/WebViewModal";
import PDFViewerModal from "../Bausteine/PDFViewerModal/PDFViewerModal";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserAlt,
  faSignOutAlt,
  faHeadset,
} from "@fortawesome/free-solid-svg-icons";

import style from "./Home.module.css";

import { db } from "../../firebase";
import { getAuth, signOut } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
//import { serverTimestamp } from "firebase/firestore";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Swal from "sweetalert2";
import { AuthContext } from "../../store/auth-context";
import { UserContext } from "../../store/user-context";

function Home(props) {
  //let userCtx = useContext(UserContext);
  //const vorname = userCtx.user.vorname;
  //const vorname = userCtx.user.vorname;

  let navigate = useNavigate();

  const { currentUser } = useContext(AuthContext);
  const { user, unreadDocs } = useContext(UserContext);


  

  const [showModal, setShowModal] = useState(false);
  const [modalUrl, setModalUrl] = useState(false);

  const handleExternalURL = (url) => {
    setShowModal(false);
    window.scrollTo({
      top: 0,
      behavior: "instant"
    });
    setModalUrl(url);
    setShowModal(true);
  };

  function handleSignOut() {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        props.setUser("");
        navigate("/login");
      })
      .catch((error) => {
        Swal.fire({
          title: "Fehler beim Logout",
          text: "Klicke erneut auf Logout um dich abzumelden.",
          icon: "error",
          confirmButtonText: "Ok",
        });
      });
  }

  return (
    <>
      <Container className={style.ContainerHeader}>
        <Row className={style.iconheaderRow}>
          <div
            className={style.iconHeaderContainer}
            onClick={handleSignOut}
          >
            <span style={{ marginRight: "8px" }}>Logout</span>
            <FontAwesomeIcon
              className={style.icon}
              icon={faSignOutAlt}
              size="xl"
            />
          </div>
        </Row>
        <Row className={style.iconheaderRow}>      
          <div
            className={style.iconHeaderContainer}
            onClick={() => navigate("/kundendaten", { state: { props: user } })}
          >
            <span style={{ marginRight: "8px" }}>Kundendaten</span>
            <FontAwesomeIcon
              className={style.icon}
              icon={faUserAlt}
              size="xl"
            />
          </div>
        </Row>
        <Row className={style.iconheaderRow}>
          <div
            className={style.iconHeaderContainer}
            onClick={() => navigate("/beratung", { state: user })}
          >
            <span style={{ marginRight: "8px" }}>Jetzt beraten lassen</span>
            <FontAwesomeIcon
              className={style.icon}
              icon={faHeadset}
              size="xl"
            />
          </div>
        </Row>
      </Container>
      <Container className={style.containerKacheln} style={{ padding: 0 }}>
        <Row className={style.rowContainer}>
          <Col>
            <p className={style.slogan}>smart. digital. nachhaltig.</p>
            <img
              className={style.vinlogo}
              src={require("../../img/VIN1_Logo_Lila.png")}
              alt=""
            />
            <br />
            <img
              className={style.vinding}
              src={require("../../img/Mach_dein_VIN_Ding.png")}
              alt=""
            />
          </Col>
        </Row>
        <Row>
          <Col style={{ textAlign: "center" }}>
            <p className={style.boldText}>
              Hallo{user.vorname ? " " + user.vorname + "," : ", "} vintastisch
              dich hier zu sehen!
            </p>
          </Col>
        </Row>



        {/*
        <Row className="mt-5">
          <Col style={{ textAlign: "center"}}>
            <h5>&#45; Connecte dich mit der Welt &#45;</h5>
          </Col>
        </Row>
        */}


        <Row className="justify-content-center">
          <Col xs={12} md={12} style={{ marginTop: "20px", cursor: "pointer"}}>
            <CardDoppelt
              style={{cursor: "pointer"}}
              url={"/news"}
              img={"NeueDokumente.png"}
              title={"Mit einem Klick alles im Blick"}
              shake={unreadDocs.length > 0 ? true : false}
              text={
                <p>
                  {unreadDocs.length === 0 && (
                    <>
                      Für dich liegen aktuell <b>keine neuen Informationen</b> vor!
                    </>
                  )}
                  {unreadDocs.length === 1 && (
                    <>
                      Für dich liegt <b>eine neue Information</b> vor!
                    </>
                  )}
                  {unreadDocs.length > 1 && (
                    <>
                      Für dich liegen <b>{unreadDocs.length} neue Informationen</b> vor!
                    </>
                  )}
                  <br />
                  Hier findest du deine Dokumente und die neuesten Informationen auf einen Blick.
                  <br />
                  <b>Schaue dir jetzt deine persönlichen Informationen an!</b>
                </p>
              }
            />
          </Col>
        </Row>





        <Row style={{ marginTop: "16px", justifyContent: "center" }}>
          <Col
            xs={12}
            md={12}
            style={{ marginTop: "20px" }}
            onClick={() => navigate("/connect", { state: user })}
          >
            <CardDoppelt
              url={""}
              externalURL={""}
              img={"Connect.png"}
              title={"Connect"}
              text={
                <p>
                  Hier kannst du deine externen Verträge zusammenführen, Dateien an deinen Berater senden oder Kontakt zu uns aufnehmen.
                  <br />
                  <b>Jetzt deinen digitalen Makler nutzen!</b>
                </p>
              }
            />
          </Col>
        </Row>


        {/*
        <Row className="mt-5">
          <Col style={{ textAlign: "center" }}>
            <h5>&#45; VIN1 Vorteile &#45;</h5>
          </Col>
        </Row>
        */}

        <Row style={{ marginTop: "16px", justifyContent: "center" }}>
          <Col
            xs={12}
            md={12}
            style={{ marginTop: "20px" }}
            onClick={() => navigate("/informationen", { state: user })}
          >
            <CardDoppelt
              url={"informationen"}
              img={"Informationen.png"}
              title={"Informationen"}
              text={
                <p>
                  VINde hier Informationen zu den Themen
                  <br />
                  Versicherungen, Finanzen, Energie, Immobilien und mehr.
                  <br />
                  <b>Jetzt informieren!</b>
                </p>
              }
            />
          </Col>
        </Row>

      

        {/*<Row style={{ marginTop: "16px", justifyContent: "center" }}>
          <Col
            xs={12}
            md={12}
            style={{ marginTop: "20px" }}
            onClick={() => navigate("/tarif-taxi", { state: user })}
          >
            <CardDoppelt
              url={""}
              img={"TarifTaxi.jpg"}
              title={"Tarif Taxi"}
              text={
                <p>
                  Dein Tuning-Taxi durch den Tarif-Dschungel.
                  <br />
                  <b>Jetzt Angebote sichern!</b>
                </p>
              }
            />
          </Col>
        </Row>*/}

        <Row style={{ marginTop: "16px", justifyContent: "center" }}>
          <Col
            xs={12}
            md={12}
            style={{ marginTop: "20px" }}
            onClick={() => navigate("/cashback", { state: user })}
          >
            <CardDoppelt
              url={""}
              img={"Cashback.png"}
              title={"Cashback"}
              text={
                <p>
                  Deine Empfehlungen und sozialen Fingerabdrücke sind Geld wert.{" "}
                  <br />
                  <b>Jetzt dein Cashback sichern!</b>
                </p>
              }
            />
          </Col>
        </Row>


        <Row className="justify-content-center" style={{ marginTop: "16px"}}>
          <Col
            xs={12}
            md={12}
            style={{ marginTop: "20px" }}
            onClick={() => navigate("/schaden", { state: user })}
          >
            <CardDoppelt
              url={""}
              img={"Schaden.png"}
              title={"Schaden melden"}
              text={
                <p>
                  Wir kämpfen für dein Recht. Wir kümmern uns nicht nur um neue
                  Verträge, sondern lösen auch deine Probleme im Schadenfall.
                  Bleib entspannt, du hast jetzt uns!
                  <br />
                  <b>Jetzt Schaden melden!</b>
                </p>
              }
            />
          </Col>
        </Row>


        <Row style={{ marginTop: "16px", justifyContent: "center" }}>
          <Col
            xs={12}
            md={12}
            style={{ marginTop: "20px" }}
            onClick={() => navigate("/partnerprogramm", { state: user })}
          >
            <CardDoppelt
              url={""}
              img={"PartnerProgramm.png"}
              title={"VIN1 Partnerprogramm"}
              text={
                <p>
                  Wir haben garantiert das richtige Partnerprogramm für dich!
                  <br />
                  <b>Jetzt dein VINding VINden!</b>
                </p>
              }
            />
          </Col>
        </Row>

        <Row style={{ marginTop: "16px", justifyContent: "center" }}>
          <Col
            xs={12}
            md={12}
            style={{ marginTop: "20px" }}
            onClick={() => navigate("/gewinnspiel", { state: user })}
          >
            <CardDoppelt
              url={""}
              img={"Gewinnspiel.png"}
              title={"Gewinnspiele"}
              text={
                <p>Hier VINdest du alle unsere Gewinnspiele an denen du kostenlos teilnehmen kannst.
                  <br />
                  <b>Jetzt Gewinne abstauben!</b>
                </p>
              }
            />
          </Col>
        </Row>

       


        <Row style={{ marginTop: "16px", justifyContent: "center" }}>
          <Col
            xs={12}
            md={12}
            style={{ marginTop: "20px" }}
            onClick={() => navigate("/vorteilspartner", { state: { props: user } }) }
          >
            <CardDoppelt
              url={""}
              img={"Vorteilspartner.png"}
              title={"VIN1 Vorteilsprogramm"}
              text={
                <p>
                  Als VIN1 Kunde erhältst du Zugang zu Einsparpotentialen der
                  Extraklasse.
                  <br />
                  <b>Jetzt Vorteil nutzen!</b>
                </p>
              }
            />
          </Col>
        </Row>


        <Row style={{ marginTop: "16px", justifyContent: "center" }}>
          <Col
            xs={12}
            md={12}
            style={{ marginTop: "20px" }}
            onClick={() => navigate("/verbesserungen")}
          >
            <CardDoppelt
              url={""}
              img={"Verbesserungen.png"}
              title={"VIN1 Verbesserungsvorschläge"}
              text={
                <p>
                  Dein Feedback ist uns wichtig! Hier kannst du Anregungen, Kritik und Verbesserungen an uns übermitteln.
                  <br />
                  <b>Jetzt mitgestalten!</b>
                </p>
              }
            />
          </Col>
        </Row>

      

      </Container>

      <br />

      {showModal == "aefdsf" && <WebViewModal url={modalUrl} hideModal={setShowModal} />}
      {!showModal == "wdfsdg" && <PDFViewerModal filename={"sample.pdf"} hideModal={setShowModal} />}

      <Footer />
    </>
  );
}

export default Home;
