import React, { useState } from 'react'
import Header from './Header/Header'

import { useNavigate } from 'react-router-dom';

import Card from "./Card/Card"
import CardSub from './Card/CardSub';
import CardDoppelt from "./Card/CardDoppelt"

import { Container, Row, Col, Form, Button, Modal } from 'react-bootstrap';

import SelectInput from './Bausteine/SelectInput/SelectInput';

import WeiterleitungPartnerSelection from './WeiterleitungPartnerSelection'


function DokumenteWeiterleitung() {

  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);
  const [showPartnerSelect, setShowPartnerSelect] = useState(false);

  const [kategorieInput, setKategorieInput] = useState("Alle Partner");
  const [partnerInput, setPartnerInput] = useState("");
  

  // Connectete Partner
  const [partnerListeModal, setPartnerListeModal] = useState([]);

  const [kategorieListe, setKategorieListe] = useState([
    {
      label: "Alle Partner",
      value: "Alle Partner"
    },{
      label: "Architekten",
      value: "Architekten"
    },{
      label: "Auto",
      value: "Auto"
    }, {
      label: "Multimedia",
      value: "Multimedia"
    }
  ]);



  const handlePartnerChangeModal = (data) => {
    //console.log(data.value);
    setPartnerInput(data.value);

    // VIN1 CRM Backend => PartnerCollection => Partner Object in subscribers hinterlegen

    // In Firebase User Doc Daten subscriptions Array Feld den "unique" partner_name pushen

    // Partner in aktuelle Liste direkt aufnehmen!
  }

  const handleKategorieChange = (data) => {
    //console.log(data.value);
    setKategorieInput(data.value);

    // Axios set Partner in DB

    // Partner in aktuelle Liste direkt aufnehmen!
  }



  return (
    <>
      <Header url={"/news"}/>

      <Container style={{maxWidth: "800px"}}>

        <Row className="justify-content-center">
          <Col style={{ textAlign: "center" }}>
            <img src={require("../img/Dokumente.png")} alt="" style={{maxWidth: "160px", marginTop: "40px"}} />
            <h1 style={{color: "#520da7"}}>Deine Dokumente sicher im Griff</h1><br />
            <p className="text-center">
              Hier kannst du deine Dokumente und Verträge einsehen oder deine eigenen Dateien hochladen.
            </p>
          </Col>
        </Row>

        
        <Row className="justify-content-center">
          <Col xs={12} md={10} style={{marginTop: "20px"}}> 
            <CardDoppelt
                url={"privat"}
                img={"PrivateDokumente.png"}
                title={"Private Dokumente"}
                text={"Hier kannst du deine wichtigsten Unterlagen digitalisieren und sie vor Verlust schützen."}
              />
          </Col>
          {/*
              <Col xs={12} md={4} style={{marginTop: "20px"}}>
                <Card url={"/neue-dokumente"} img={"briefkasten.png"} title={"Letzte neue Dokumente"} />
              </Col>
            */}
        </Row>


        <Row className="justify-content-center">
          <Col xs={12} md={10} style={{marginTop: "20px"}}
            onClick={() => navigate("/partner/vin1", {state: { category: "Dokumente" }})}
          > 
            <CardDoppelt
                //url={{"/partner/vin1", state: { category: "Dokumente" } }}
                img={"VIN1Dokumente.png"}
                title={"VIN1 Dokumente"}
                text={"Alle Vertragsunterlagen von VIN1."}
              />
          </Col>
        </Row>


        <Row className="justify-content-center">
          <Col xs={12} md={10} style={{marginTop: "20px"}}
            onClick={() => navigate("/partner/vin1", {state: { category: "Dokumente" }})}
          > 
            <Col
              key={"Partner"}
              xs={12} md={3}
              style={{marginBottom: "20px"}}
              onClick={() => setShowPartnerSelect(!showPartnerSelect)}
            >
              <CardSub key={"Partner"} title={"Partner"} />
              <Button >Partner Connect</Button>
              <Button >Parnter auswählen</Button>
            </Col>
          </Col>
        </Row>


        {/*
          <Row className="justify-content-center mt-4" >
            <h5 className="text-center">Dokumente von einem Partner anzeigen - Wähle aus:</h5>
          </Row>

          <WeiterleitungPartnerSelection navigateTo={"Dokumente"} />
        */}

        {showPartnerSelect &&
          <Modal show={showModal} onHide={() => setShowModal(!showModal)}>
            <Modal.Header closeButton>
                <Modal.Title>Connecte dich mit einem Partner</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Wähle eine Kategorie, um die Auswahl zu verkleinern oder suche im unteren Feld direkt nach einem Partner</p>
                <SelectInput placeholder={"Wähle eine Kategorie"} noOptionsMessage={"Keine Kategorie gefunden."} options={kategorieListe} handleSelectChange={handleKategorieChange} />

                <p>Wähle im folgenden Dropdown einen Partner aus. Du kannst durch Eingaben in dem Feld nach Partnern suchen.</p>
                <SelectInput placeholder={"Suche nach einem Partner..."} noOptionsMessage={"Kein Partner gefunden."} options={partnerListeModal} handleSelectChange={handlePartnerChangeModal} />


                <Form.Check
                  type={'checkbox'}
                  label={"Mit dem Abonnieren des Partners stimmst du zu, dass deine persönlichen Kundeninformationen (Anschrift, Geburtsdatum, Kontaktmöglichkeiten) für den abonnierten Partner sichtbar werden und dieser Dokumente in deine Dateiablage einspielen kann. Der Partner hat keine Einsicht auf deine anderen Dokumente."}
                  id="cbZustimmung"
                />
            </Modal.Body>
            <Modal.Footer>
                <p
                  style={{ fontSize: "100%", display: "flex", justifyContent: "flex-end", width: "auto !important", background: "#656565", padding: "15px", color: "white", fontWeight: "bold", borderRadius: "8px", cursor: "pointer" }}
                  onClick={() => setShowModal(!showModal)}
                >
                  Schließen
                </p>
                <p
                  style={{ fontSize: "100%", display: "flex", justifyContent: "flex-end", width: "auto !important", background: "#520da7", padding: "15px", color: "white", fontWeight: "bold", borderRadius: "8px", cursor: "pointer" }}
                  onClick={() => setShowModal(!showModal)}
                >
                  Mit Partner connecten
                </p>
            </Modal.Footer>
          </Modal>
        }
      
      </Container>

    </>
  );
}

export default DokumenteWeiterleitung